<div
  #container
  class="info-container d-flex w-100 h-100 justify-content-center align-items-center"
>
  <div
    class="info-panel bg-white position-relative py-5 d-flex flex-column justify-content-center align-items-center"
  >
    <img
      class="mb-5 coin"
      *ngIf="info.useCoinImage"
      src="/assets/images/coin-wings.svg"
      alt=""
    />
    <h1 class="mb-5 wonky">{{ info.header }}</h1>
    <span class="mb-5 fw-bold">{{ info.text }}</span>
    <div
      class="extra-html text-start"
      *ngIf="info.extraHtml"
      [innerHtml]="info.extraHtml"
    ></div>
    <button class="teal-btn fs-3" (click)="performAction()">
      {{ info.buttonText }}
    </button>
    <img
      (click)="performAction()"
      tabindex="0"
      (keydown.enter)="performAction()"
      role="button"
      class="position-absolute close-icon cursor-pointer"
      src="/assets/icons/close.svg"
      alt="close tip"
    />
  </div>
</div>

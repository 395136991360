import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../shared/users.service';
import { ClassCodeStudent } from '../shared/interfaces';
import { User } from 'firebase/auth';
import { AppRole } from 'functions/src/shared';
import { ProgressService } from '../shared/progress.service';

enum signInFlow {
  signIn,
  classCode,
  enroll
}

@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html',
  styleUrls: ['./student-login.component.scss']
})
export class StudentLoginComponent {
  username = '';
  password = '';
  classCode = '';
  enrollPassword = '';
  confirmEnrollPassword = '';
  selectedStudent: ClassCodeStudent | null = null;
  showStudentDropdown: boolean = false;
  displayError: boolean = false;
  signInFlow = signInFlow;
  private _currentFlow = signInFlow.signIn;
  initialLogin = false;
  isSubmitting = false;
  students: ClassCodeStudent[] = [];

  constructor(
    private usersService: UsersService,
    private router: Router,
    private progressService: ProgressService
  ) {}

  set currentFlow(targetState: signInFlow) {
    this._currentFlow = targetState;
    this.displayError = false;
    this.password = '';
    this.username = '';
    this.enrollPassword = '';
    this.confirmEnrollPassword = '';
    this.selectedStudent = null;
    this.isSubmitting = false;
  }

  get currentFlow() {
    return this._currentFlow;
  }

  arePasswordsMatching(): boolean {
    return this.enrollPassword === this.confirmEnrollPassword;
  }

  toggleStudentDropdown() {
    this.showStudentDropdown = !this.showStudentDropdown;
  }

  onStudentSelect(i: number) {
    this.selectedStudent = this.students[i];
    this.showStudentDropdown = false;
  }

  submitClassCodeForm() {
    this.isSubmitting = true;
    this.usersService.fetchClassByCode(this.classCode).subscribe({
      next: (students) => {
        if (!students.length) {
          this.displayError = true;
          this.isSubmitting = false;
        } else {
          this.students = students;
          this.currentFlow = signInFlow.enroll;
        }
      },
      error: () => {
        this.displayError = true;
        this.isSubmitting = false;
      }
    });
  }

  submitEnrollForm() {
    this.isSubmitting = true;
    this.selectedStudent &&
      this.usersService
        .registerStudent({
          id: this.selectedStudent.id,
          username: this.selectedStudent.username,
          classCode: this.classCode,
          password: this.enrollPassword
        })
        .subscribe({
          complete: async () => {
            await this.usersService.signInUser(
              `${this.selectedStudent?.username}@student.dollarsdecisions.com`,
              this.enrollPassword
            );
            this.router.navigate(['/main']);
          },
          error: () => {
            this.displayError = true;
            this.isSubmitting = false;
          }
        });
  }

  async submitLoginForm() {
    this.isSubmitting = true;
    this.displayError = false;

    this.usersService
      .signInUser(
        `${this.username}@student.dollarsdecisions.com`,
        this.password
      )
      .then(async (loginResult) => {
        if (
          loginResult.user &&
          (await this.usersService.getUserRole(loginResult.user as User)) ===
            AppRole.STUDENT
        ) {
          await this.progressService.restoreProgress();
          this.router.navigate(['/main']);
        } else {
          await this.usersService.logout(false);
          this.displayError = true;
        }
      })
      .catch(() => {
        this.displayError = true;
        this.isSubmitting = false;
      });
  }
}

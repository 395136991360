import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RestartCourse } from '../ngrx/actions/progress.actions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UsersService } from '../users.service';
import { User } from 'firebase/auth';
import { AppRole } from '../interfaces';
import { TeacherViewService } from '../teacher-view.service';
import { TeacherDashboardView } from 'src/app/teacher-dashboard/teacher-dashboard.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() useReportLogo = false;
  menuOpen = false;
  loggedIn = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedIn.asObservable();
  currentRoute: string;

  constructor(
    private store: Store,
    private auth: AngularFireAuth,
    private router: Router,
    private usersService: UsersService,
    private teacherViewService: TeacherViewService
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn.next(true);
      } else {
        this.loggedIn.next(false);
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRoute = this.router.url;
      });

    this.currentRoute = this.router.url;
  }

  restartCourse() {
    this.store.dispatch(RestartCourse({ toAssessment: true }));
  }
  getLogoText(): string {
    switch (this.router.url) {
      case '/teacher':
        return 'Teach';
      case '/admin' || '/sign-in':
        return 'Report';
      default:
        return '';
    }
  }
  async logout() {
    await this.usersService.logout();
  }
  async onLogoClick() {
    try {
      const user = await this.usersService.getCurrentUser();
      if (user) {
        const role = await this.usersService.getUserRole(user as User);
        role === AppRole.TEACHER
        ? this.teacherViewService.changeView(TeacherDashboardView.home)
        : location.reload();
      }else{
          this.router.navigate(['/']);
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  }
}

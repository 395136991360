<mat-select
  [disabled]="disabled"
  class="cursor-pointer"
  [ngClass]="{ form: formStyles }"
  [panelClass]="formStyles ? 'form' : ''"
  [value]="valueFunction(selectedItem)"
  [placeholder]="placeholder"
  (selectionChange)="dropdownChange.emit($event.value)"
  disableOptionCentering="true"
  shouldLabelFloat="false"
  [style.--width]="width"
  appMatOptionHeight
>
  <mat-option *ngFor="let item of dropdownItems" [value]="valueFunction(item)">
    {{ displayFunction(item) }}
  </mat-option>
</mat-select>

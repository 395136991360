<div class="top-banner bg-image no-print">
  <div class="container ml-0">
    <div class="row pt-5 d-flex justify-content-between align-items-center">
      <div class="col-md-6 mt-5">
        <div
          class="greeting-box px-3 py-2"
          [ngClass]="{ shift: currentView !== teacherDashboardView.home }"
        >
          <div *ngIf="currentView === teacherDashboardView.home">
            <div class="greeting h2 fw-bolder">Hi, {{ teacherName }}</div>
            <div class="date h5 fw-bolder">Today is {{ todayDate }}</div>
          </div>
          <div *ngIf="currentView === teacherDashboardView.createClass">
            <div class="greeting h2 fw-bolder">Create a new class</div>
          </div>
          <div
            *ngIf="
              currentView === teacherDashboardView.students ||
              currentView === teacherDashboardView.editClass
            "
          >
            <div class="greeting h2 fw-bolder">Students</div>
          </div>
          <div
            class="d-flex align-items-center py-1"
            *ngIf="currentView === teacherDashboardView.courseCompletion"
          >
            <div class="greeting h2 fw-bolder me-2 mb-1">Course Completion</div>
            <app-tooltip-bubble
              id="course-completion-tooltip"
              [showBeneath]="true"
              [forAttribute]="'header'"
              [icon]="'question'"
              [text]="
                'The Completion section displays how many of your students have completed Dollars & Decisions, how many are in progress, and how many have not started. The percent displayed indicates the percentage of your students that have successfully completed Dollars & Decisions.'
              "
            ></app-tooltip-bubble>
          </div>
          <div
            class="d-flex align-items-center py-1"
            *ngIf="currentView === teacherDashboardView.assessmentResults"
          >
            <div class="greeting h2 fw-bolder">Assessment Results</div>
            <app-tooltip-bubble
              id="course-completion-tooltip"
              [showBeneath]="true"
              [forAttribute]="'header'"
              [icon]="'question'"
              [text]="
                'The Assessment section displays your students average score for both Pre-Game Assessment and Post-Game Assessment.'
              "
            ></app-tooltip-bubble>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          currentView === teacherDashboardView.courseCompletion ||
          currentView === teacherDashboardView.assessmentResults
        "
        class="greeting-box header-class-switch px-3 py-2"
      >
        <span class="karla fs-6 fw-bolder me-3">CLASS</span>
        <app-dropdown
          [dropdownItems]="classes"
          [selectedItem]="selectedClass"
          [displayFunction]="getClassName"
          [valueFunction]="getClassCode"
          [width]="'142px'"
          [disabled]="!classes.length || !classes[0].classCode"
          (dropdownChange)="onClassSelect($event)"
        ></app-dropdown>
      </div>
    </div>
  </div>
</div>

<app-teacher-dashboard-home
  *ngIf="currentView === teacherDashboardView.home"
  [classes]="classes"
  [selectedClass]="selectedClass"
  [todayDate]="todayDate"
  (switchClass)="onClassSelect($event)"
  (changeView)="onViewChange($event)"
></app-teacher-dashboard-home>
<app-create-class
  *ngIf="
    currentView === teacherDashboardView.createClass ||
    currentView === teacherDashboardView.editClass
  "
  [classData]="selectedClass"
  [editMode]="currentView === teacherDashboardView.editClass"
  (changeView)="onViewChange($event)"
  (refetchClasses)="fetchClasses($event)"
></app-create-class>
<app-students
  *ngIf="currentView === teacherDashboardView.students"
  [selectedClass]="selectedClass"
  (changeView)="onViewChange($event)"
  (refetchClasses)="fetchClasses($event)"
></app-students>
<app-course-completion
  *ngIf="currentView === teacherDashboardView.courseCompletion"
  [selectedClass]="selectedClass"
  (changeView)="onViewChange($event)"
></app-course-completion>
<app-assessment-results
  *ngIf="currentView === teacherDashboardView.assessmentResults"
  [selectedClass]="selectedClass"
  (changeView)="onViewChange($event)"
></app-assessment-results>

<div
  [ngClass]="{ solid: currentRoute === '/admin' }"
  class="header position-relative px-4 d-flex justify-content-between align-items-center w-100"
>
  <div class="logo-and-teach-container">
    <img (click)="onLogoClick()" [src]="'/assets/images/logo.svg'" alt="dollars and decisions logo" class="logo"/>
    <div *ngIf="currentRoute" class="logo-divider">
      <div *ngIf="getLogoText()" class="vertical-line"></div>
      <span class="h4 mt-2 fw-bolder">{{ getLogoText() }}</span>
    </div>
  </div>
  <img
    role="button"
    aria-expanded="false"
    aria-controls="header-menu"
    (click)="menuOpen = true"
    (keydown.enter)="menuOpen = true"
    tabindex="0"
    class="cursor-pointer"
    *ngIf="!menuOpen"
    src="/assets/icons/menu.svg"
    alt="open menu"
  />
  <img
    role="button"
    aria-expanded="true"
    aria-controls="header-menu"
    (click)="menuOpen = false"
    (keydown.enter)="menuOpen = false"
    tabindex="0"
    class="cursor-pointer"
    *ngIf="menuOpen"
    src="/assets/icons/menu-x.svg"
    alt="close menu"
  />
  <div
    id="header-menu"
    *ngIf="menuOpen"
    class="menu position-absolute bg-white"
  >
    <ul role="listbox" class="d-flex flex-column justify-content-between">
      <li
        *ngIf="currentRoute === '/main'"
        (click)="menuOpen = false; restartCourse()"
      >
        <span
          role="button"
          (keydown.enter)="menuOpen = false; restartCourse()"
          tabindex="0"
          class="fw-bold"
        >
          Restart the Course
        </span>
      </li>
      <a
        tabindex="0"
        [routerLink]="['/main']"
        class="fw-bold"
        (click)="menuOpen = false"
        *ngIf="currentRoute !== '/main'"
      >
        <li>
          <span role="button"> Back to Course </span>
        </li>
      </a>
      <li *ngIf="loggedIn$ | async" (click)="menuOpen = false; logout()">
        <span
          role="button"
          (keydown.enter)="menuOpen = false; logout()"
          tabindex="0"
          class="fw-bold"
        >
          Logout
        </span>
      </li>
    </ul>
  </div>
</div>

<div class="path">
  <span
    class="dashboard-text"
    (click)="changeView.emit(teacherDashboardView.home)"
    >Dashboard</span
  >
  <img
    class="arrow-right"
    src="../../assets/icons/arrow-right.svg"
    alt="right-arrow-icon"
  />
  <span class="fw-bolder" *ngIf="!editMode">Create a New Class</span>
  <span class="fw-bolder" *ngIf="editMode">Edit Class</span>
</div>
<form
  *ngIf="!showConfirmationScreen"
  [formGroup]="classForm"
  (ngSubmit)="onSubmit()"
  class="main-content"
>
  <div class="class-name-section">
    <span class="fw-bolder">Class Name</span>
    <div
      *ngIf="
        classForm.get('className')?.touched &&
        classForm.get('className')?.hasError('required')
      "
      class="error"
    >
      Class name is required.
    </div>
    <div
      *ngIf="
        classForm.get('className')?.touched &&
        classForm.get('className')?.hasError('pattern')
      "
      class="error"
    >
      Class name must contain only letters and numbers.
    </div>
    <input
      type="text"
      formControlName="className"
      class="input"
      placeholder="Enter a class name"
    />
  </div>
  <div formArrayName="students" class="students-box">
    <div class="header-section fw-bolder">Student Name</div>
    <div
      class="student-rows"
      *ngFor="let student of students.controls; let i = index"
    >
      <div
        *ngIf="
          student?.get('name')?.touched &&
          student?.get('name')?.hasError('required')
        "
        class="error student-error"
      >
        Student name is required.
      </div>
      <div
        *ngIf="
          student?.get('name')?.touched &&
          student?.get('name')?.hasError('pattern')
        "
        class="error student-error"
      >
        Student name must contain only letters.
      </div>
      <div
        [formGroupName]="i"
        class="student-row"
        [class.grey-bg]="i % 2 === 1"
      >
        <span class="row-number">{{ i + 1 }}.</span>
        <input
          type="text"
          class="input"
          formControlName="name"
          placeholder="Enter Student Name"
        />
        <img
          class="icon-remove"
          tabindex="0"
          src="../../assets/icons/menu-x.svg"
          alt="Remove Student"
          (click)="removeStudent(i)"
          (keyup.enter)="removeStudent(i)"
        />
      </div>
    </div>
    <div
      class="footer-section"
      tabindex="0"
      (click)="addStudent()"
      (keyup.enter)="addStudent()"
    >
      <img
        class="icon-add"
        src="../../assets/icons/plus.svg"
        alt="Add Student"
      />
      <span class="add-student-text fw-bolder">ADD NEW STUDENT</span>
    </div>
  </div>
  <div *ngIf="students.length === 0" class="error">
    You must add at least one student.
  </div>
  <div class="button-container" (click)="displayErrors()">
    <button
      [disabled]="students.length === 0 || !classForm.valid || isSubmitting"
      type="submit"
      class="teal-btn text-uppercase"
      *ngIf="!editMode"
    >
      Create Class
    </button>
    <div *ngIf="students.length === 0 || !classForm.valid || isSubmitting" class="overlay"></div>
  </div>
  <button
    [disabled]="students.length === 0 || !classForm.valid || isSubmitting"
    type="submit"
    class="teal-btn text-uppercase"
    *ngIf="editMode"
  >
    Save Changes
  </button>
  <span
    *ngIf="displayError"
    aria-live="polite"
    class="karla fst-italic text-danger"
  >
    Error creating class.
  </span>
</form>
<div *ngIf="showConfirmationScreen" class="main-content confirmation-screen">
  <p class="success-text">You have successfully created a class.</p>
  <div class="confirmation-code">
    <span>Your class code is: </span>
    <span class="code">{{ classCode }}</span>
    <img
      src="../../assets/icons/copy.svg"
      alt="Copy Icon"
      class="copy-icon"
      (click)="copyToClipboard()"
      tabindex="0"
      (keyup.enter)="copyToClipboard()"
    />
  </div>
  <button
    class="back-to-dashboard-btn teal-btn text-uppercase"
    (click)="changeView.emit(teacherDashboardView.home)"
  >
    Back to Dashboard
  </button>
  <button
    (click)="changeView.emit(teacherDashboardView.students)"
    class="view-class-btn text-uppercase"
  >
    View Class
  </button>
</div>

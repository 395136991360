<div
  #tutorialContainer
  class="position-absolute top-0 w-100 h-100 tutorial-container"
  *ngrxLet="tutorialSequence$ as currentTip"
  [ngClass]="{ 'pe-none': !currentTip }"
>
  <div
    #tutorialButton
    class="position-absolute onboarding d-flex flex-column align-items-end pe-auto"
  >
    <button
      [attr.aria-expanded]="showPrompt"
      (click)="showPrompt = canStartTutorial && !showPrompt"
      class="toggle position-relative"
    >
      <img src="/assets/icons/onboarding-icon.svg" alt="open tutorial" />
    </button>
    <div #tutorialPrompt class="bubble d-flex flex-column" *ngIf="showPrompt">
      <h5>How to Use</h5>
      <p *ngFor="let text of description.split('\\n')">{{ text }}</p>
      <button
        class="align-self-start"
        (click)="proceed$.next(); showPrompt = false"
      >
        Step-by-step guide<img
          class="ms-2"
          src="/assets/icons/arrow-left-long.svg"
          alt="next"
        />
      </button>
    </div>
  </div>
  <ng-container *ngIf="currentTip">
    <div
      #currentTutorialTip
      *ngIf="currentTip.computePosition(tutorialContainer) as position"
      (keydown.escape)="exit$.next()"
      class="modal-bubble position-absolute d-flex flex-column"
      [style.bottom]="position.bottom"
      [style.left]="position.left"
    >
      <div class="d-flex justify-content-between content">
        <p>{{ currentTip.text }}</p>
        <button
          (click)="exit$.next()"
          class="align-self-start d-flex close-button"
        >
          <img src="/assets/icons/close.svg" alt="close" />
        </button>
      </div>
      <div class="d-flex justify-content-end">
        <button
          (click)="proceed$.next()"
          class="text-uppercase text-center proceed-button"
        >
          {{ currentTip.buttonLabel }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
<ng-content> </ng-content>

import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { ApiClient } from './api-client.service';

export interface CreateClassDto {
  className: string;
  students: { name: string; id?: string }[];
}

@Injectable({
  providedIn: 'root'
})
export class ClassesService {
  constructor(
    private usersService: UsersService,
    private apiClient: ApiClient
  ) {}

  async fetchTeacherClasses() {
    const user = await this.usersService.getCurrentUser();
    const token = await user?.getIdToken();
    return this.apiClient.fetchTeacherClasses(token as string);
  }

  async createClass(formBody: CreateClassDto) {
    const user = await this.usersService.getCurrentUser();
    const token = await user?.getIdToken();
    return this.apiClient.createClass(formBody, token as string);
  }

  async editClass(formBody: CreateClassDto, classCode: string) {
    const user = await this.usersService.getCurrentUser();
    const token = await user?.getIdToken();
    return this.apiClient.editClass(formBody, classCode, token as string);
  }

  async deleteClass(classCode: string) {
    const user = await this.usersService.getCurrentUser();
    const token = await user?.getIdToken();
    return this.apiClient.deleteClass(classCode, token as string);
  }

}

<div class="chart d-flex align-items-center justify-content-center flex-column">
  <div class="content">
    <div class="legends">
      <span class="fs-6 legend-text" *ngFor="let item of sortedData">
        <div class="fw-bolder fs-5">{{ item.text.split(":")[0] }}:</div>
        <div class="fs-6">{{ item.text.split(":")[1].trim() }}</div>
      </span>
    </div>

    <div class="vertical-line"></div>

    <div class="bar-content">
      <div *ngFor="let item of sortedData" class="d-flex">
        <div class="bar" [style.width]="item.size"></div>
        <span class="fs-3 fw-bolder">{{ item.count }}</span>
      </div>
    </div>
  </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProgressState } from './ngrx/reducers/progress.reducer';
import { AssessmentStats, ClassCodeStudent, ClassDataDto } from './interfaces';
import {
  CreateCredentialTeacherDto,
  CreateGoogleTeacherDto,
  RegisterStudentDto
} from './users.service';
import { CreateClassDto } from './classes.service';

@Injectable({
  providedIn: 'root'
})
export class ApiClient {
  constructor(private http: HttpClient) {}

  sendAnalytics(type: string, body: any, idToken: string) {
    if (idToken) {
      const headers = new HttpHeaders({ idToken });
      return this.http.post(`${environment.apiBase}/analytics/${type}`, body, {
        headers
      });
    }

    return this.http.post(`${environment.apiBase}/analytics/${type}`, body);
  }

  fetchAnalytics(token: string) {
    const headers = new HttpHeaders({ idToken: token });
    return this.http.get(`${environment.apiBase}/analytics`, { headers });
  }

  fetchLocation() {
    return this.http.get('https://geolocation-db.com/json/');
  }

  saveStudentProgress(token: string, savedProgress: ProgressState) {
    const headers = new HttpHeaders({
      idToken: token,
      'content-type': 'application/json'
    });
    return this.http.put(
      `${environment.apiBase}/students/progress`,
      savedProgress,
      { headers }
    );
  }

  fetchStudentProgress(token: string) {
    const headers = new HttpHeaders({
      idToken: token,
      'content-type': 'application/json'
    });
    return this.http.get<ProgressState>(
      `${environment.apiBase}/students/progress`,
      { headers }
    );
  }

  saveStudentAssessmentData(
    token: string,
    type: string,
    stats: AssessmentStats
  ) {
    const headers = new HttpHeaders({
      idToken: token,
      'content-type': 'application/json'
    });
    return this.http.put(
      `${environment.apiBase}/students/assessmentData?type=${type}`,
      stats,
      { headers }
    );
  }

  createTeacher(formBody: CreateCredentialTeacherDto) {
    return this.http.post(`${environment.apiBase}/teachers`, formBody);
  }

  createGoogleTeacher(formBody: CreateGoogleTeacherDto) {
    return this.http.post(`${environment.apiBase}/teachers/google`, formBody);
  }

  registerStudent(formBody: RegisterStudentDto) {
    return this.http.post(`${environment.apiBase}/students`, formBody);
  }

  fetchTeacherClasses(token: string) {
    const headers = new HttpHeaders({ idToken: token as string });
    return this.http.get<ClassDataDto[]>(
      `${environment.apiBase}/teachers/classes`,
      { headers }
    );
  }

  fetchClassByCode(code: string) {
    return this.http.get<ClassCodeStudent[]>(
      `${environment.apiBase}/classes/${code}`
    );
  }

  createClass(formBody: CreateClassDto, token: string) {
    const headers = new HttpHeaders({
      idToken: token,
      'content-type': 'application/json'
    });
    return this.http.post<{ classCode: string }>(
      `${environment.apiBase}/classes`,
      formBody,
      { headers }
    );
  }

  editClass(formBody: CreateClassDto, classCode: string, token: string) {
    const headers = new HttpHeaders({
      idToken: token,
      'content-type': 'application/json'
    });
    return this.http.put<void>(
      `${environment.apiBase}/classes/${classCode}`,
      formBody,
      { headers }
    );
  }
  deleteClass(classCode: string, token: string) {
    const headers = new HttpHeaders({
      idToken: token,
      'content-type': 'application/json'
    });
    return this.http.delete(`${environment.apiBase}/classes/${classCode}`, {
      headers
    });
  }

  deleteStudent(id: string, token: string) {
    const headers = new HttpHeaders({
      idToken: token,
      'content-type': 'application/json'
    });
    return this.http.delete(`${environment.apiBase}/students/${id}`, {
      headers
    });
  }

  updateStudentPassword(id: string, password: string, token: string) {
    const headers = new HttpHeaders({
      idToken: token,
      'content-type': 'application/json'
    });
    return this.http.put(
      `${environment.apiBase}/students/${id}/password`,
      { password },
      { headers }
    );
  }

  getTeacher(teacherId: string) {
    return this.http.get<{ id?: string }>(
      `${environment.apiBase}/teachers/${teacherId}`
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { ReportsComponent } from './reports/reports.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { RoleGuard } from './role.guard';
import { AppRole } from './shared/interfaces';
import { TeacherLoginComponent } from './teacher-login/teacher-login.component';
import { StudentLoginComponent } from './student-login/student-login.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';

const routes: Routes = [
  {
    path: 'admin',
    component: ReportsComponent,
    canActivate: [RoleGuard],
    data: { permittedRole: AppRole.ADMIN }
  },
  {
    path: 'teacher',
    component: TeacherDashboardComponent,
    canActivate: [RoleGuard],
    data: { permittedRole: AppRole.TEACHER }
  },
  {
    path: 'sign-in',
    component: SignInComponent
  },
  {
    path: 'teacher-login',
    component: TeacherLoginComponent
  },
  {
    path: 'student-login',
    component: StudentLoginComponent
  },
  {
    path: 'main',
    component: MainComponent
  },
  {
    path: '**',
    component: LandingPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  providers: [RoleGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {}

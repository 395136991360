<div
  class="circle-graph position-relative"
  [style.height]="sizeString"
  [style.width]="sizeString"
>
  <div
    *ngIf="average >= 0"
    class="position-absolute d-flex justify-content-center align-items-center flex-column"
    [style.height]="sizeString"
    [style.width]="sizeString"
  >
    <span *ngIf="!notApplicable" class="fs-1 fw-bold mb-0 lh-1">
      {{
        ((average ? average : 0) % 1 === 0 ? average : average.toFixed(1)) +
          (isPercentage ? "%" : "/10")
      }}
    </span>
    <span *ngIf="notApplicable" class="fs-1 fw-bold mb-0">N/A</span>
    <span *ngIf="text" class="fs-6 fw-normal mb-0">{{ text }}</span>
  </div>
  <svg
    aria-hidden="true"
    [ngClass]="{ flip: !average }"
    [style.height]="sizeString"
    [style.width]="sizeString"
  >
    <g>
      <circle
        [attr.r]="size * 0.5 * 0.7"
        [attr.cx]="size * 0.5"
        [attr.cy]="size * 0.5"
        fill="transparent"
        [style.stroke]="fgColor"
        [style.strokeWidth]="size * 0.5 * 0.2"
        [style.strokeDasharray]="0"
        [style.strokeDashoffset]="0"
        strokeLinecap="round"
      />
      <circle
        [attr.r]="size * 0.5 * 0.7"
        [attr.cx]="size * 0.5"
        [attr.cy]="size * 0.5"
        fill="transparent"
        [style.stroke]="
          percentComplete && strokePercent !== circumference ? bgColor : ''
        "
        [style.strokeWidth]="size * 0.5 * 0.2"
        [style.strokeDasharray]="circumference"
        [style.strokeDashoffset]="strokeDashoffset"
        strokeLinecap="round"
      />
    </g>
  </svg>
</div>

<div
  class="img-bg main-display d-flex justify-content-center align-items-center"
>
  <ng-container [ngSwitch]="currentFlow">
    <form
      (submit)="submitLoginForm()"
      #loginForm="ngForm"
      *ngSwitchCase="signInFlow.signIn"
    >
      <p class="small-text mb-0">Teacher Account</p>
      <hr />
      <h1 class="fs-2 fw-bolder mb-2">Welcome!</h1>
      <span class="karla fw-bolder fs-5">Please Sign In</span>

      <button
        type="button"
        class="google-box mx-5 bg-white"
        (click)="handleGoogleLogin()"
      >
        <img src="../../assets/images/google-logo.svg" alt="Google Logo" />
        <span class="mt-0">Continue with Google</span>
      </button>

      <p
        *ngIf="displayGoogleError"
        aria-live="polite"
        class="mt-3 mb-0 karla instructions fst-italic text-danger"
      >
        Unable to find Google account.
      </p>

      <div class="or-separator">
        <div class="line"></div>
        <p>OR</p>
        <div class="line"></div>
      </div>
      <span class="prompt fst-italic"
        >Enter your email address and password to sign in.</span
      >
      <div class="input-section">
        <p
          *ngIf="displayError"
          aria-live="polite"
          class="karla instructions fst-italic text-danger"
        >
          Your email address or password is incorrect.
        </p>
        <ng-template [ngTemplateOutlet]="emailContainer"></ng-template>
        <div class="mb-3 password-control">
          <label for="password" class="form-label text-uppercase">
            <app-alert-icon
              [condition]="passwordModel.invalid && passwordModel.touched"
            ></app-alert-icon>
            Password</label
          >
          <input
            type="password"
            placeholder="Enter password"
            minlength="6"
            required
            [(ngModel)]="password"
            id="password"
            class="form-control fst-italic"
            name="password"
            #passwordModel="ngModel"
            #passwordInput
            [ngClass]="{
              'border-danger':
                displayError ||
                (!passwordModel.pristine && !passwordModel.valid)
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              passwordInput.type =
                passwordInput.type === 'password' ? 'text' : 'password'
            "
            [ngSwitch]="passwordInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
      </div>
      <a
        class="karla fst-italic forgot-password"
        tabindex="0"
        (keydown.enter)="switchToForgotPassword()"
        (click)="switchToForgotPassword()"
        >Forgot your password?</a
      >
      <button
        type="submit"
        class="teal-btn karla"
        [class.disabled]="!loginForm.valid || !isEmailValid"
        [disabled]="!loginForm.valid || !isEmailValid"
      >
        SIGN IN
      </button>
      <span>
        Don't have an account?
        <a
          class="karla fst-italic"
          tabindex="0"
          (click)="currentFlow = signInFlow.register"
          >Sign Up</a
        ></span
      >
    </form>
    <form
      #resetForm="ngForm"
      *ngSwitchCase="signInFlow.request"
      (ngSubmit)="requestReset()"
    >
      <p class="small-text">Teacher Account</p>
      <hr />
      <h1 class="wonky text-uppercase mt-5">forgot password?</h1>
      <span class="prompt fst-italic"
        >Enter your email address to reset your password.</span
      >
      <div class="input-section">
        <p
          *ngIf="resetSent"
          aria-live="polite"
          class="fst-italic instructions text-success"
        >
          An email has been sent to reset your password.
        </p>

        <ng-template [ngTemplateOutlet]="emailContainer"></ng-template>
      </div>
      <button
        type="submit"
        class="teal-btn wonky"
        [class.disabled]="!isEmailValid"
        [disabled]="!isEmailValid"
      >
        reset password
      </button>
      <a
        class="karla fst-italic"
        tabindex="0"
        (keydown.enter)="currentFlow = signInFlow.signIn"
        (click)="currentFlow = signInFlow.signIn"
        >Sign in</a
      >
    </form>
    <form
      #resetForm="ngForm"
      *ngSwitchCase="signInFlow.reset"
      (ngSubmit)="completeReset()"
    >
      <p class="small-text">Teacher Account</p>
      <hr />
      <h1 class="wonky text-uppercase mt-5">Reset password</h1>
      <span class="prompt fst-italic">Enter your new password.</span>
      <div class="input-section">
        <p
          *ngIf="displayError"
          aria-live="polite"
          class="karla instructions text-danger"
        >
          Passwords don't match
        </p>
        <div class="mb-3 password-control">
          <label for="password" class="form-label text-uppercase"
            >new password</label
          >
          <input
            type="password"
            placeholder="Enter new password"
            minlength="6"
            required
            [(ngModel)]="password"
            id="password"
            class="form-control"
            name="password"
            #passwordModel="ngModel"
            #passwordInput
            [ngClass]="{
              'border-danger':
                displayError ||
                (!passwordModel.pristine && !passwordModel.valid)
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              passwordInput.type =
                passwordInput.type === 'password' ? 'text' : 'password'
            "
            [ngSwitch]="passwordInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
        <div class="mb-3 password-control">
          <label for="passwordConfirmation" class="form-label text-uppercase"
            >confirm new password</label
          >
          <input
            type="password"
            placeholder="Enter new password"
            minlength="6"
            required
            [(ngModel)]="passwordConfirmation"
            id="passwordConfirmation"
            class="form-control"
            name="passwordConfirmation"
            #passwordConfirmationModel="ngModel"
            #passwordConfirmationInput
            [ngClass]="{
              'border-danger':
                displayError ||
                (!passwordConfirmationModel.pristine &&
                  (!passwordConfirmationModel.valid ||
                    passwordConfirmation !== password))
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              passwordConfirmationInput.type =
                passwordConfirmationInput.type === 'password'
                  ? 'text'
                  : 'password'
            "
            [ngSwitch]="passwordConfirmationInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
      </div>
      <button
        type="submit"
        class="teal-btn wonky"
        [class.disabled]="!resetForm.valid || password !== passwordConfirmation"
        [disabled]="!resetForm.valid || password !== passwordConfirmation"
      >
        Done
      </button>
    </form>
    <!--Register Form-->
    <app-teacher-registration-form
      (changeView)="currentFlow = $event"
      *ngSwitchCase="signInFlow.register"
      [emailContainer]="emailContainer"
    ></app-teacher-registration-form>

    <div
      *ngIf="currentFlow === signInFlow.alreadyRegistered"
      class="already-registered"
    >
      <p class="small-text mb-0">Teacher Account</p>
      <hr />
      <p class="fs-2 fw-bolder mb-0">Create An Account</p>
      <p class="fs-5 my-4">
        We already found an account with this email address.
      </p>
      <button
        (click)="currentFlow = signInFlow.signIn"
        class="teal-btn karla fs-4 fw-bolder my-2"
      >
        SIGN IN
      </button>
      <p class="fs-7 mt-3">
        <button
          class="bg-white border-0 p-0 fs-6"
          (click)="currentFlow = signInFlow.register"
        >
          <span class="fst-italic text-decoration-underline">Sign up</span>
        </button>
        with a new email
      </p>
    </div>
  </ng-container>
</div>

<ng-template #emailContainer>
  <div class="mb-3">
    <label for="email" class="form-label text-uppercase">
      <app-alert-icon
        [condition]="emailField.invalid && emailField.touched"
      ></app-alert-icon>
      email</label
    >
    <input
      type="email"
      required
      [(ngModel)]="email"
      placeholder="Enter email address"
      id="email"
      class="form-control fst-italic"
      name="email"
      (ngModelChange)="checkEmailValidity(emailField)"
      #emailField="ngModel"
      [ngClass]="{
        'border-danger':
          displayError || (!emailField.pristine && !emailField.valid)
      }"
    />
  </div>
</ng-template>

import { createReducer, on } from '@ngrx/store';
import { ToolTip } from '../../interfaces';
import {
  HideFullScreenInfo,
  ShowFullScreenInfo,
  HideNarrativeChoice,
  ShowNarrativeChoice,
  ShowToolTip,
  UpdateSubtitle,
  HideToolTip
} from '../actions/overlays.actions';

export interface OverlaysState {
  NarrativeChoiceKey: string | null;
  SubtitleText: string[];
  ToolTip: ToolTip | null;
  SmallToolTip: ToolTip | null;
  FullScreenInfoKey: string;
}
export const initialState: OverlaysState = {
  NarrativeChoiceKey: null,
  SubtitleText: [],
  ToolTip: null,
  SmallToolTip: null,
  FullScreenInfoKey: ''
};
export const OverlaysReducer = createReducer(
  initialState,
  on(
    ShowNarrativeChoice,
    (state, { key }): OverlaysState => ({ ...state, NarrativeChoiceKey: key })
  ),
  on(
    HideNarrativeChoice,
    (state): OverlaysState => ({ ...state, NarrativeChoiceKey: null })
  ),
  on(
    ShowFullScreenInfo,
    (state, { key }): OverlaysState => ({ ...state, FullScreenInfoKey: key })
  ),
  on(
    HideFullScreenInfo,
    (state): OverlaysState => ({ ...state, FullScreenInfoKey: '' })
  ),
  on(
    UpdateSubtitle,
    (state, { lines }): OverlaysState => ({ ...state, SubtitleText: lines })
  ),
  on(
    ShowToolTip,
    (state, { options }): OverlaysState => ({
      ...state,
      [options.type === 'large' ? 'ToolTip' : 'SmallToolTip']: options
    })
  ),
  on(
    HideToolTip,
    (state, { size }): OverlaysState => ({
      ...state,
      [size === 'large' ? 'ToolTip' : 'SmallToolTip']: null
    })
  )
);

import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Store } from '@ngrx/store';
import { ChapterRestarted } from '../shared/ngrx/actions/progress.actions';
import {
  UpdateSubtitle,
  FreezeAnimation,
  UnfreezeAnimation,
  DelayAnimation,
  AnimationResumed,
  JumpToAnimationEvent,
  PlaySound,
  SkipAnimationEvent
} from '../shared/ngrx/actions/overlays.actions';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { AnimationData, Subtitle } from '../shared/interfaces';
import { TimerService } from '../shared/timer.service';
import { AssetManagerService } from '../shared/asset-manager.service';
import { Howl } from 'howler';
import { selectSubtitles } from '../shared/ngrx/selectors/overlays.selectors';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent implements OnChanges, OnInit, OnDestroy {
  @Input() animationData?: AnimationData;
  options: AnimationOptions = {};
  animationItem: AnimationItem = {} as AnimationItem;
  events: any[] = [];
  eventIndex = 0;
  audio!: Howl;
  subtitles!: Subtitle[];
  showSubtitles = false;
  animationHeight!: string;
  animationWidth!: string;
  sounds: HTMLAudioElement[] = [];
  subtitleInterval!: number;
  subtitle = this.store.select(selectSubtitles);
  @Output() finishAnimationPreload: EventEmitter<void> = new EventEmitter();

  private restartEffect = this.actions
    .pipe(
      ofType(ChapterRestarted),
      tap(() => this.loadAnimation())
    )
    .subscribe();
  private resumeEffect = this.actions
    .pipe(
      ofType(AnimationResumed),
      tap(() => this.resumeAnimation())
    )
    .subscribe();
  private jumpToEventEffect = this.actions
    .pipe(
      ofType(JumpToAnimationEvent),
      tap(({ index }) => {
        if (this.animationData) this.events = [...this.animationData.events];
        this.eventIndex = index > 0 ? index : this.events.length + index;
        this.resumeAnimation();
      })
    )
    .subscribe();
  private freezeEffect = this.actions
    .pipe(
      ofType(FreezeAnimation),
      tap(() => {
        this.timerService.pause();
        this.animationItem.pause();
        this.audio?.pause();
      })
    )
    .subscribe();
  private unfreezeEffect = this.actions
    .pipe(
      ofType(UnfreezeAnimation),
      tap(() => {
        if (this.timerService.timerActive) {
          this.timerService.resume();
        } else {
          this.animationItem.play();
        }
        this.audio?.play();
      })
    )
    .subscribe();
  private playSoundEffect = this.actions
    .pipe(
      ofType(PlaySound),
      tap(({ src }) => {
        if (this.audio && this.audio.playing()) {
          this.audio.stop();
        }
        this.audio = this.assetManager.sounds[src];
        this.subtitles =
          this.assetManager.subtitles[
            src.replace('sounds', 'subtitles').replace('mp3', 'srt')
          ];
        this.addSubtitleEvents();
        this.audio.play();
      })
    )
    .subscribe();
  private delayAnimationEffect = this.actions
    .pipe(
      ofType(DelayAnimation),
      tap(({ delayTime }) => {
        this.timerService.startTimer(() => this.resumeAnimation(), delayTime);
      })
    )
    .subscribe();
  private skipEffect = this.actions
    .pipe(
      ofType(SkipAnimationEvent),
      tap(() => {
        if (this.animationItem && this.events.length > this.eventIndex + 1) {
          this.animationItem.stop();
          this.audio?.pause();
          this.store.dispatch(this.events[this.eventIndex]);
          this.eventIndex++;
          this.resumeAnimation();
        }
      })
    )
    .subscribe();

  constructor(
    private store: Store,
    private actions: Actions,
    private ngZone: NgZone,
    private timerService: TimerService,
    private assetManager: AssetManagerService
  ) {}

  ngOnDestroy(): void {
    this.resumeEffect.unsubscribe();
    this.jumpToEventEffect.unsubscribe();
    this.restartEffect.unsubscribe();
    this.freezeEffect.unsubscribe();
    this.unfreezeEffect.unsubscribe();
    this.delayAnimationEffect.unsubscribe();
    this.playSoundEffect.unsubscribe();
    this.skipEffect.unsubscribe();
  }

  ngOnInit(): void {
    this.resize();
    this.loadAnimation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['animationData'].previousValue) {
      changes['animationData'].previousValue.dispose();
    }
    this.loadAnimation();
  }

  loadAnimation(): void {
    if (this.animationData) {
      this.audio?.stop();
      this.events = [...this.animationData.events];

      this.options = {
        path: this.animationData.layerPaths[0],
        autoplay: false,
        loop: false,
        initialSegment: this.events[0].segment
      };
      this.eventIndex = 0;
    }
  }

  onComplete(): void {
    this.ngZone.run(async () => {
      const event = (this.events[this.eventIndex] = this.eventIsFunction()
        ? await this.events[this.eventIndex]()
        : this.events[this.eventIndex]);
      this.store.dispatch(event);

      const pause = event.pause;
      this.eventIndex++;

      if (
        pause &&
        this.audio?.playing() &&
        event.type !== '[Animation] Delay Animation'
      )
        this.audio.pause();
      if (!pause && this.events.length > this.eventIndex) {
        this.resumeAnimation();
      }
    });
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.ngZone.runOutsideAngular(() => {
      animationItem.addEventListener('data_ready', () =>
        this.finishAnimationPreload.emit()
      );
    });
  }

  playSegment(segment: any, noAudio: boolean): void {
    if (!noAudio && this.eventIndex > 0 && this.audio && !this.audio.playing())
      this.audio.play();

    this.animationItem.playSegments(segment, true);
  }

  eventIsFunction(): boolean {
    return typeof this.events[this.eventIndex] === 'function';
  }

  async resumeAnimation(): Promise<void> {
    const event = (this.events[this.eventIndex] = this.eventIsFunction()
      ? await this.events[this.eventIndex]()
      : this.events[this.eventIndex]);
    this.playSegment(event.segment, event.noAudio);
  }

  addSubtitleEvents() {
    this.audio.on('play', () => {
      this.subtitleInterval = window.setInterval(() => {
        let timestamp = this.audio.seek();
        let currentSub = this.subtitles.find(
          (sub) => timestamp >= sub.startSeconds && timestamp <= sub.endSeconds
        );
        if (currentSub) {
          this.store.dispatch(UpdateSubtitle({ lines: [currentSub.text] }));
        }
      }, 500);
    });

    this.audio.on('end', () => {
      clearInterval(this.subtitleInterval);
    });

    this.audio.on('pause', () => {
      clearInterval(this.subtitleInterval);
    });
  }

  resize() {
    let width = window.innerWidth;
    let height = window.innerHeight;
    if (height / width >= 0.711) {
      this.animationWidth = `${width}px`;
      this.animationHeight = `${width * 0.711}px`;
    } else {
      this.animationHeight = `${height}px`;
      this.animationWidth = `${height * 1.40625}px`;
    }
  }
}

<div class="students-by-states-container">
  <div aria-hidden="true" #mapContainer class="map-container"></div>
  <div aria-hidden="true" class="report-tooltip">
    <p>
      {{ currentState }}<br /><span class="value">{{
        stateData[currentState] || "N/A"
      }}</span>
    </p>
  </div>
  <div class="statesTable" *ngrxLet="selectedSortMethod$ as sortMethod">
    <div class="position-relative sort-container">
      <button
        role="button"
        [attr.aria-expanded]="dropdownOpen"
        #toggle
        (click)="toggleDropdown()"
        (keydown.escape)="dropdownOpen ? toggleDropdown() : ''"
        class="sort-toggle"
      >
        <span>sort by</span>
        <img
          [ngClass]="{ open: dropdownOpen }"
          src="/assets/icons/arrow-down.svg"
          alt=""
        />
      </button>
      <div
        role="listbox"
        *ngIf="dropdownOpen"
        class="sort-options position-absolute"
      >
        <span
          [attr.aria-selected]="sortMethod === 'Alphabetical'"
          role="option"
          tabindex="0"
          (keydown.enter)="selectedSortMethod$.next('Alphabetical')"
          (click)="selectedSortMethod$.next('Alphabetical')"
          [ngClass]="{ active: sortMethod === 'Alphabetical' }"
        >
          Alphabetical
        </span>
        <span
          [attr.aria-selected]="sortMethod === 'Ascending'"
          role="option"
          tabindex="0"
          (keydown.enter)="selectedSortMethod$.next('Ascending')"
          (click)="selectedSortMethod$.next('Ascending')"
          [ngClass]="{ active: sortMethod === 'Ascending' }"
        >
          Ascending
        </span>
        <span
          [attr.aria-selected]="sortMethod === 'Descending'"
          role="option"
          tabindex="0"
          (keydown.enter)="selectedSortMethod$.next('Descending')"
          (click)="selectedSortMethod$.next('Descending')"
          [ngClass]="{ active: sortMethod === 'Descending' }"
        >
          Descending
        </span>
      </div>
    </div>
    <table tabindex="0" class="table table-striped">
      <caption>
        Students by state table
      </caption>
      <thead class="position-sticky">
        <tr>
          <th scope="col">State</th>
          <th scope="col">Number of Students</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of stateData | sortStates: sortMethod">
          <td>{{ entry[0] }}</td>
          <td>{{ entry[1] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { defer, Observable, of } from 'rxjs';
//@ts-ignore
import focusLock from 'dom-focus-lock';
import { SubmitNarrativeChoice } from 'src/app/shared/ngrx/actions/overlays.actions';
import {
  AsyncNarrativeChoiceOption,
  NarrativeChoiceOption
} from '../interfaces';

@Component({
  selector: 'app-narrative-choice',
  templateUrl: './narrative-choice.component.html',
  styleUrls: ['./narrative-choice.component.scss']
})
export class NarrativeChoiceComponent implements OnChanges, AfterViewInit {
  @Input() choices!: (NarrativeChoiceOption | AsyncNarrativeChoiceOption)[];
  @Input() header?: string = '';
  @Output() finish = new EventEmitter<void>();
  @ViewChild('container') container!: ElementRef;
  choiceData$!: Observable<NarrativeChoiceOption>[];
  headerChoiceCountText = ['one', 'two', 'three', 'four'];

  constructor(public store: Store) {}
  ngAfterViewInit(): void {
    focusLock.on(this.container.nativeElement);
  }

  ngOnChanges(): void {
    this.choiceData$ = this.choices.map((entry) =>
      defer(() =>
        typeof entry === 'function'
          ? (<AsyncNarrativeChoiceOption>entry)(this.store)
          : of(entry)
      )
    );
  }

  applyChoice(choice: NarrativeChoiceOption) {
    focusLock.off(this.container.nativeElement);
    this.store.dispatch(SubmitNarrativeChoice({ choice }));
    this.finish.emit();
  }
}

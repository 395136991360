export const questions: { [type: string]: any } = {
  pre: [
    {
      query:
        'True or False: A budget is a plan for using your money. Having a budget can help you better understand how much money you have in order to avoid debt and unnecessary spending.',
      correctChoice: true,
      correctExplanation:
        'Great work! You’re on your way to becoming an expert.',
      incorrectExplanation:
        'Oops! That’s not quite right. But don’t worry, you’ll be learning all about budgets next!'
    },
    {
      query:
        'It’s important to factor your regular monthly expenses into your personal budget. Which of the following is NOT an example of an expense?',
      choices: ['Rent', 'Groceries', 'Salary', 'Internet Service'],
      correctChoice: 2,
      correctExplanation: 'Correct! You’ve got this figured out.',
      incorrectExplanation:
        'Nope! That’s not the correct choice. Let’s try the next question.'
    },
    {
      query: 'Which type of bank account is best to use for daily expenses?',
      choices: ['Checking', 'Savings', 'Retirement', 'Investment'],
      correctChoice: 0,
      correctExplanation: 'Yes! You’re doing a great job so far.',
      incorrectExplanation:
        'Oops! That’s not the right answer. You’ll get it right after learning more soon!'
    },
    {
      query:
        'True or False: Chandra earns $18 per hour. She worked 10 hours this week. Her take-home pay for the week will be $180.',
      correctChoice: false,
      correctExplanation: 'You got it!',
      incorrectExplanation:
        'Nope, that’s not it! Taxes change your take-home pay. But don’t worry: You’ll learn more in a bit!'
    },
    {
      query:
        'Why is your paycheck less than the hourly wage or salary number you actually receive?',
      choices: [
        'Rent is deducted from your paycheck.',
        'Taxes are deducted from your paycheck.',
        'General company expenses are deducted from your paycheck.',
        'None of the above.'
      ],
      correctChoice: 1,
      correctExplanation: 'Correct! Look at you go.',
      incorrectExplanation:
        'That’s not quite right. Let’s try the next question!'
    },
    {
      query: 'What is personal credit?',
      choices: [
        'A plastic card that you can use for making purchases.',
        'The ability to borrow money, earned by demonstrating that you can and will pay it back.',
        'The amount of money you can spend without going into debt.',
        'A number that corresponds to how wealthy you are.'
      ],
      correctChoice: 1,
      correctExplanation:
        'You got it! Seems like you know about credit already!',
      incorrectExplanation:
        'Oops! That’s not the correct answer. You’ll learn more about credit soon.'
    },
    {
      query:
        'True or False: When you use a credit card to make a purchase, money is taken directly out of a checking account.',
      correctChoice: false,
      correctExplanation: 'Wow! You know a lot already. Good work.',
      incorrectExplanation:
        'Nope! I’m sure you’ll get it after playing the game.'
    },
    {
      query:
        'You have $100 in a savings account with an annual interest rate of 2%. How much would you have in the account in three years if you left it to grow?',
      choices: ['$102', 'Less than $102', 'More than $102'],
      correctChoice: 2,
      correctExplanation: 'Yup! You got it.',
      incorrectExplanation:
        'Oops! That’s not it. You’ll have a chance to try again later.'
    },
    {
      query:
        'True or False: A retirement account will automatically be set up for you when you start a job.',
      correctChoice: false,
      correctExplanation: "That's correct!",
      incorrectExplanation:
        "That's not correct. You'll learn more about retirement in a bit!"
    },
    {
      query:
        'Jaime has just been hired at his first full-time job. He knows that it is important to save. Which of the following should he save for first?',
      choices: [
        'Dream vacation',
        'An emergency fund',
        'Down payment for a home',
        'New car'
      ],
      correctChoice: 1,
      correctExplanation:
        'You got it! Having an emergency fund is super important.',
      incorrectExplanation:
        'Nope, that’s not the best choice. You’ll learn more in just a minute!'
    }
  ],
  post: [
    {
      query: 'Why is a budget useful?',
      choices: [
        'It helps avoid getting into debt.',
        'It helps you reach your savings goals.',
        'It minimizes unnecessary spending and purchases.',
        'All of the above.'
      ],
      correctChoice: 3,
      correctExplanation:
        'You got it! Having a budget is super important and useful for a variety of reasons.',
      incorrectExplanation:
        'That’s not quite right! A budget is useful for a variety of reasons, including avoiding debt, reaching savings goals, and minimizing unnecessary spending. That’s why having a budget is so important!'
    },
    {
      query:
        'True or False: You should factor your monthly expenses like rent and groceries into your personal budget.',
      correctChoice: true,
      correctExplanation:
        'That’s correct! Monthly expenses always need to be factored into your budget to be sure you can purchase the necessities.',
      incorrectExplanation:
        'Oops! That’s not correct. Monthly expenses always need to be factored into your budget to be sure you can purchase the necessities.'
    },
    {
      query:
        'True or False: You should always keep all of the money you earn in your checking account.',
      correctChoice: false,
      correctExplanation:
        'You got it correct! You should consider putting extra earnings in savings, retirement, or investment accounts.',
      incorrectExplanation:
        'Oops! That’s not the right answer. You should consider putting extra earnings in savings, retirement, or investment accounts instead of keeping it in your checking account.'
    },
    {
      query:
        'True or False: If Maria multiplies her hourly pay by the number of hours she worked this week, that will be the exact amount on the paycheck she receives.',
      correctChoice: false,
      correctExplanation:
        'You picked the correct answer! Maria’s paycheck is less because of taxes that are deducted.',
      incorrectExplanation:
        'That’s not quite right. Maria’s paycheck will be less because of taxes that are deducted.'
    },
    {
      query:
        'Sean earns $15 per hour and worked 20 hours this week. Why will his take-home pay be less than $300?',
      choices: [
        "Company expenses are deducted from Sean's paycheck.",
        "Taxes are deducted from Sean's paycheck.",
        "Rent is deducted from Sean's paycheck.",
        'None of the above.'
      ],
      correctChoice: 1,
      correctExplanation:
        "You got it! Taxes are deducted from Sean's paycheck, which will reduce his take-home pay.",
      incorrectExplanation:
        "Nope, that’s not it! Taxes are deducted from Sean's paycheck, which will reduce his take-home pay."
    },
    {
      query:
        'True or False: Personal credit is a card you can use to make any kind of purchase.',
      correctChoice: false,
      correctExplanation:
        'You got it! Personal credit is the ability to borrow money, earned by demonstrating that you can and will pay it back.',
      incorrectExplanation:
        'Oops! That’s not the correct answer. Personal credit is the ability to borrow money, earned by demonstrating that you can and will pay it back.'
    },
    {
      query: 'What’s the difference between a debit card and a credit card?',
      choices: [
        'Nothing, they are the same.',
        'When you spend money on a debit card, it comes directly out of your bank account. When you spend money on a credit card, you are borrowing that amount and will need to pay it back plus interest.',
        'You can make bigger purchases on a credit card.',
        'When you use your debit card, you are borrowing money. When you use a credit card, you are taking money from your savings account.'
      ],
      correctChoice: 1,
      correctExplanation:
        'Wow! Good work. When you spend money on a debit card, it comes directly out of your bank account. When you spend money on a credit card, you are borrowing that amount and will need to pay it back plus interest.',
      incorrectExplanation:
        'Nope! When you spend money on a debit card, it comes directly out of your bank account. When you spend money on a credit card, you are borrowing that amount and will need to pay it back plus interest.'
    },
    {
      query:
        'Aidan puts $200 in a savings account where he earns an annual interest rate of 1%. Why will Aidan end up with more than $200 after keeping the money in the account for 1 year?',
      choices: [
        "Leaving money in the account and earning interest will increase Aidan's balance.",
        'Aidan will only have more funds in the account if he deposits more money.',
        'Neither of the above.'
      ],
      correctChoice: 0,
      correctExplanation:
        'Yup! You got it. Aidan will earn extra money in his account from the annual interest.',
      incorrectExplanation:
        'Oops! That’s not it. Aidan will earn extra money in his account from the annual interest.'
    },
    {
      query:
        'True or False: A retirement account will automatically be set up for you when you start a full-time job.',
      correctChoice: false,
      correctExplanation:
        'You picked the correct answer! A retirement account will not automatically be set up. You are responsible for proactively saving for retirement. Find out if your employer offers a retirement plan and how to start an account.',
      incorrectExplanation:
        'That’s not correct. A retirement account will not automatically be set up. You are responsible for proactively saving for retirement. Find out if your employer offers a retirement plan and how to start an account.'
    },
    {
      query:
        'You’ve just been hired at your first full-time job and you’re so excited to be earning more money. What is the smartest thing to save for first?',
      choices: [
        'Going on an exciting trip.',
        'Purchasing a car.',
        'Building an emergency fund.',
        'Saving for a house.'
      ],
      correctChoice: 2,
      correctExplanation:
        'You got it! Having an emergency fund is super important in case something happens unexpectedly. You should always have money set aside in case.',
      incorrectExplanation:
        'Nope, that’s not the best choice. You should first set aside money for an emergency fund. Having an emergency fund is super important in case something happens unexpectedly.'
    }
  ]
};

import { firstValueFrom } from 'rxjs';
import { Screen } from '../interfaces';
import {
  BudgetModalLaunched,
  RetirementModalLaunched
} from '../ngrx/actions/modals/modals.actions';
import {
  DelayAnimation,
  HideToolTip,
  PlaySound,
  ScrollToRetirement,
  ShowFullScreenInfo,
  ShowNarrativeChoice,
  ShowToolTip
} from '../ngrx/actions/overlays.actions';
import { AdvanceScreen, UpdateBalance } from '../ngrx/actions/progress.actions';
import {
  selectBudgetValue,
  selectCharacter
} from '../ngrx/selectors/progress.selectors';
export const retirementScreen: Screen = {
  component: 'game',
  module: {
    title: {
      chapter: 'Module 4',
      text: 'saving for goals and retirement planning',
      titleBackgroundImage: '/assets/images/apartment-bg.png'
    },
    animation: async (store, assetManager) => {
      const character = await firstValueFrom(store.select(selectCharacter));
      let resolveLineUrl = (
        sceneNum: number,
        lineNum: number,
        alias?: string
      ) =>
        `/assets/sounds/scene${sceneNum}/S${sceneNum}L${lineNum}${
          alias ?? ''
        }.mp3`;
      let sounds = [
        [9, 1],
        [9, 2],
        [9, 3],
        [9, 4],
        [9, 5],
        [10, 1],
        [10, 2],
        [10, 3]
      ].map(([sceneNum, lineNum]) =>
        resolveLineUrl(
          sceneNum,
          lineNum,
          sceneNum === 9 && lineNum % 2 === 0 ? character.soundAlias : undefined
        )
      );
      await assetManager.loadAssets([...sounds]);
      return {
        layerPaths: ['/assets/lottie/scene9-10.json'],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...ShowToolTip({
              options: {
                text: 'Your Grandma has sent you <span class="fw-bold">$50</span> as a late birthday gift! This has been deposited into your account.',
                type: 'medium',
                mood: 'positive'
              }
            }),
            segment: [0, 1],
            pause: false
          },
          {
            ...DelayAnimation({ delayTime: 5000 }),
            segment: [1, 1],
            pause: true
          },
          {
            ...HideToolTip({ size: 'medium' }),
            segment: [1, 1],
            pause: false
          },
          {
            ...UpdateBalance({ value: 50, skipNotification: true }),
            segment: [1, 1],
            pause: false
          },
          {
            ...PlaySound({ src: sounds.shift()! }),
            segment: [0, 0],
            pause: false
          },
          {
            ...PlaySound({ src: sounds.shift()! }),
            segment: [0, 58],
            pause: false
          },
          {
            ...PlaySound({ src: sounds.shift()! }),
            segment: [58, 330],
            pause: false
          },
          {
            ...PlaySound({ src: sounds.shift()! }),
            segment: [330, 579],
            pause: false
          },
          {
            ...PlaySound({ src: sounds.shift()! }),
            segment: [579, 634],
            pause: false
          },
          {
            ...PlaySound({ src: sounds.shift()! }),
            segment: [634, 1485],
            pause: false
          },
          {
            ...RetirementModalLaunched(),
            segment: [1485, 3230],
            pause: true
          },
          {
            ...ShowNarrativeChoice({ key: 'retirement' }),
            segment: [3230, 3326],
            pause: true
          },
          {
            ...PlaySound({ src: sounds.shift()! }),
            segment: [3326, 3326],
            pause: false
          },
          {
            ...BudgetModalLaunched({ fromButton: true }),
            segment: [3326, 3448],
            pause: false,
            noAudio: true
          },
          {
            ...ScrollToRetirement(),
            segment: [3448, 3448],
            pause: true,
            noAudio: true
          },
          async () => {
            let retirementValue = await firstValueFrom(
              store.select(selectBudgetValue('retirement'))
            );

            return {
              ...UpdateBalance({ value: -retirementValue }),
              segment: [3448, 3448],
              pause: false,
              noAudio: true
            };
          },
          {
            ...DelayAnimation({ delayTime: 2000 }),
            segment: [3448, 3448],
            pause: true,
            noAudio: true
          },
          {
            ...ShowFullScreenInfo({ key: 'finish' }),
            segment: [3448, 3448],
            pause: true,
            noAudio: true
          },
          {
            ...PlaySound({ src: '/assets/sounds/scene10/S10L3.mp3' }),
            segment: [3448, 3448],
            pause: false
          },
          {
            ...ShowFullScreenInfo({ key: 'finish' }),
            segment: [3448, 3570],
            pause: true
          }
        ]
      };
    },
    infoScreens: {
      finish: {
        header: 'Great!',
        text: 'You learned about saving for retirement!',
        textCenter: true,
        buttonText: 'NEXT',
        action: AdvanceScreen({ moduleCompleted: 4 })
      }
    }
  }
};

import {
  OnChanges,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

export interface ChartData {
  text: string;
  count: number;
  size: string;
}

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnChanges {
  @ViewChild('barChart') private chartContainer!: ElementRef;
  @Input() data!: { [item: string]: number };
  sortedData!: ChartData[];

  constructor() {}

  ngOnChanges(): void {
    this.generateChart();
  }

  generateChart() {
    const sortedKeys = Object.keys(this.data)
      .sort((a, b) => this.data[b] - this.data[a])
      .slice(0, 5);
    const maxValue = this.data[sortedKeys[0]];

    this.sortedData = sortedKeys.map((d) => {
      return {
        text: d,
        count: this.data[d],
        size: `${(this.data[d] * 100) / maxValue}%`
      };
    });
  }
}

import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { ModalClosed } from '../shared/ngrx/actions/modals/modals.actions';
import { ModifyBudget } from '../shared/ngrx/actions/progress.actions';
import { selectSavingsGoal } from '../shared/ngrx/selectors/progress.selectors';

@Component({
  selector: 'app-savings-modal',
  templateUrl: './savings-modal.component.html',
  styleUrls: ['./savings-modal.component.scss']
})
export class SavingsModalComponent implements OnInit {
  monthlyCost: number | undefined;
  goals: { [key: string]: number } = {
    Vacation: 1500,
    Tablet: 750,
    Bicycle: 500
  };
  selectedGoal: string = '';
  duration: number | undefined;
  @HostBinding('role') role = 'main';
  constructor(
    public activeModal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit() {
    this.store
      .select(selectSavingsGoal)
      .pipe(take(1))
      .subscribe((goal) => {
        this.selectedGoal = goal;
        this.setSavingsTime(3);
      });
  }

  handleSelectingGoal(e: Event) {
    e.preventDefault();
    this.selectedGoal = (<HTMLSelectElement>e.target).value;
    this.evalCost();
  }

  setSavingsTime(value: number) {
    this.duration = value;
    this.evalCost();
  }

  evalCost() {
    this.monthlyCost = this.goals[this.selectedGoal] / this.duration!;
    this.changeDetector.detectChanges();
  }

  submit() {
    this.store.dispatch(
      ModalClosed({
        result: ModifyBudget({
          budget: { savingsCost: Math.round(this.monthlyCost!) },
          skipNotification: true
        }),
        resumeAnimation: true
      })
    );
  }
}

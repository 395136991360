import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { HideToolTip } from 'src/app/shared/ngrx/actions/overlays.actions';
import { ToolTip } from '../interfaces';
//@ts-ignore
import focusLock from 'dom-focus-lock';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class ToolTipComponent implements OnChanges, AfterViewInit {
  @Input() options!: ToolTip;
  @Output() finish = new EventEmitter<void>();
  @ViewChild('container') container!: ElementRef;

  toolTipClass: string = '';
  constructor(private store: Store) {}
  ngAfterViewInit(): void {
    if (this.options.type !== 'small' && this.container?.nativeElement)
      focusLock.on(this.container.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let { type, mood, job } = changes['options'].currentValue;
    this.toolTipClass = `${type} ${mood} ${job ? 'has-job' : ''}`;
  }

  handleFinish() {
    focusLock.off(this.container.nativeElement);
    this.store.dispatch(HideToolTip({ size: this.options.type }));
    this.finish.emit();
  }
}

<app-tutorial
  #tutorial
  [showAfterInit]="true"
  description="This budget sheet is here to help you plan how much money you’re going to spend each month.\nUse the toggle and sliders to adjust your expenses and savings in different categories."
>
  <div
    #modalContainer
    class="modal-container d-flex flex-column align-items-center pb-5 px-3"
    appCoverOnDelayed
    (showTutorial)="showTutorial()"
  >
    <div class="budget-header d-flex justify-content-center align-items-center">
      <img src="/assets/images/coin.svg" alt="" />
      <h1 id="title" class="ms-3 mb-0 pt-3 wonky">Your Budget</h1>
    </div>
    <div
      class="monthly-income d-flex justify-content-center align-items-center p-2 mt-4"
    >
      <span class="me-4 fs-4 fw-bold">Your Monthly Income</span>
      <span class="income fw-bold fs-4 d-flex justify-content-center"
        >$ {{ ((job | async).salary / 12) * 0.7 | number }}</span
      >
    </div>
    <div class="section-header">
      <span>Fixed Expenses</span>
      <app-tooltip-bubble
        [forAttribute]="'fixed expenses'"
        [text]="
          'Fixed expenses are regular amounts that generally don’t change much.'
        "
      ></app-tooltip-bubble>
    </div>
    <div
      class="fixed d-flex flex-column justify-content-center mt-2 section-items"
    >
      <div class="d-flex justify-content-between mb-3 section-item">
        <span class="fs-5 fw-bold me-5">Rent</span>
        <span
          class="expense-cost"
          appTutorialTip
          text="Predetermined values, like your rent and utilities, are non-adjustable."
          >$ 600</span
        >
      </div>
      <div class="d-flex justify-content-between section-item">
        <span class="fs-5 fw-bold me-5">Utilities</span>
        <span class="expense-cost">$ 25</span>
      </div>
    </div>
    <div class="section-header">
      <span>Flexible Expenses</span>
      <app-tooltip-bubble
        [forAttribute]="'flexible expenses'"
        [text]="
          'Flexible expenses happen on a regular basis and are also for necessities. But, with flexible expenses, you have more control over how much you spend.'
        "
      ></app-tooltip-bubble>
    </div>
    <div class="section-items">
      <div class="section-item gap-5">
        <span class="fs-5 fw-bold">Groceries</span>
        <div
          class="groceries"
          appTutorialTip
          text="Click on the toggle bar to select your monthly grocery budget."
        >
          <div
            role="button"
            tabindex="0"
            (click)="budget.groceries = 300"
            (keydown.enter)="budget.groceries = 300"
            class="grocery-option"
            [ngClass]="{ selected: budget.groceries === 300 }"
          >
            $300
          </div>
          <div
            role="button"
            tabindex="0"
            (click)="budget.groceries = 450"
            (keydown.enter)="budget.groceries = 450"
            class="grocery-option"
            [ngClass]="{ selected: budget.groceries === 450 }"
          >
            $450
          </div>
        </div>
      </div>
      <div class="section-item gap-5">
        <span class="fs-5 fw-bold">Transportation</span>
        <div
          class="custom-slider"
          appTutorialTip
          text="Drag or click on the slider to adjust the amount of money you want to spend in a specific category."
        >
          <ngx-slider
            (keydown.arrowleft)="
              budget.transportation = budget.transportation - 5
            "
            (keydown.arrowright)="
              budget.transportation = budget.transportation + 5
            "
            tabindex="0"
            [attr.aria-valuenow]="budget.transportation"
            role="slider"
            [(value)]="budget.transportation"
            [options]="{
              keyboardSupport: false,
              floor: 25,
              ceil: 75,
              step: 5,
              hideLimitLabels: true,
              translate: dollarLabel
            }"
          ></ngx-slider>
        </div>
      </div>
      <div class="section-item gap-5">
        <span class="fs-5 fw-bold">Laundry</span>
        <div class="custom-slider">
          <ngx-slider
            (keydown.arrowleft)="budget.laundry = budget.laundry - 5"
            (keydown.arrowright)="budget.laundry = budget.laundry + 5"
            tabindex="0"
            [attr.aria-valuenow]="budget.laundry"
            role="slider"
            [(value)]="budget.laundry"
            [options]="{
              keyboardSupport: false,
              floor: 10,
              ceil: 40,
              step: 5,
              hideLimitLabels: true,
              translate: dollarLabel
            }"
          ></ngx-slider>
        </div>
      </div>
    </div>
    <div class="section-header">
      <span>Discretionary Expenses</span>
      <app-tooltip-bubble
        [forAttribute]="'discretionary expenses'"
        [text]="
          'This is the money you choose to spend, but don’t necessarily have to spend, on things like clothes, movies, and going out to eat.'
        "
      ></app-tooltip-bubble>
    </div>
    <div class="section-items gap-5">
      <div class="section-item">
        <span class="fs-5 fw-bold">All Other Expenses</span>
        <div class="custom-slider">
          <ngx-slider
            (keydown.arrowleft)="
              budget.discretionary = budget.discretionary - 1
            "
            (keydown.arrowright)="
              budget.discretionary = budget.discretionary + 1
            "
            tabindex="0"
            [attr.aria-valuenow]="budget.discretionary"
            role="slider"
            [(value)]="budget.discretionary"
            [options]="{
              keyboardSupport: false,
              floor: 0,
              ceil: 300,
              hideLimitLabels: true,
              translate: dollarLabel
            }"
          ></ngx-slider>
        </div>
      </div>
    </div>
    <div class="section-header" *ngIf="enableRetirement">
      <span>Savings</span>
      <app-tooltip-bubble
        [forAttribute]="'savings'"
        [text]="
          'Savings are also considered a discretionary expense, but it’s up to you to decide how much of your money you’re going to set aside for your future.'
        "
      ></app-tooltip-bubble>
    </div>
    <div class="section-items" *ngIf="enableRetirement">
      <div class="section-item">
        <span class="fs-5 fw-bold me-5">Retirement Savings</span>
        <div
          class="custom-slider position-relative"
          appTutorialTip
          text="Drag or click on the slider to adjust the amount of money you want to spend in a specific category."
          buttonLabel="DONE"
        >
          <div
            [style.left]="(budget.retirement / 150) * 92 + '%'"
            class="upper-label minor position-absolute"
          >
            {{ "$" + budget.retirement }}
          </div>
          <ng-container *ngTemplateOutlet="retirementSlider"></ng-container>
        </div>
      </div>
      <ng-template #retirementSlider>
        <ngx-slider
          (keydown.arrowleft)="budget.retirement = budget.retirement - 1"
          (keydown.arrowright)="budget.retirement = budget.retirement + 1"
          tabindex="0"
          [attr.aria-valuenow]="budget.retirement"
          role="slider"
          [(value)]="budget.retirement"
          [options]="{
            keyboardSupport: false,
            floor: 0,
            ceil: 150,
            hideLimitLabels: true,
            disabled: !enableRetirement,
            translate: dualValueLabel.bind(this)
          }"
        ></ngx-slider>
      </ng-template>
    </div>
    <button #done class="teal-btn my-4 fs-2" (click)="saveBudget()">
      DONE
    </button>
  </div>
</app-tutorial>

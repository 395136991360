<div
  [ngClass]="{ opaque: currentQuestion >= 0 }"
  class="display-panel d-flex img-bg align-items-center justify-content-center"
>
  <section
    *ngIf="type === 'pre' && currentQuestion === -1"
    class="assessment-intro p-5 d-flex flex-column align-items-center justify-content-between"
  >
    <h1>How money smart are you?</h1>
    <p class="text-center">
      How much do you know about finances? Take this quick quiz before you play
      the game!
    </p>
    <button
      type="button"
      class="teal-btn fs-3"
      (click)="sendAnalytics(); moveToNextQuestion()"
    >
      Let's Go!
    </button>
  </section>
  <section
    *ngIf="type === 'post' && currentQuestion === -1"
    class="assessment-intro p-5 d-flex flex-column align-items-center justify-content-between"
  >
    <h1>Test your money smarts</h1>
    <p class="text-center">
      Let’s see how much you learned! Take this final quiz to see how much of a
      finance expert you’ve become.
    </p>
    <button type="button" class="teal-btn fs-3" (click)="moveToNextQuestion()">
      Let's Go
    </button>
  </section>
  <section
    *ngIf="currentQuestion > -1 && questions[type][currentQuestion] as question"
    class="assessment-container h-75 p-5 d-flex position-relative"
  >
    <div
      class="w-100 d-flex justify-content-center position-absolute q-status-container"
    >
      <span
        aria-live="polite"
        [attr.aria-hidden]="selections[currentQuestion] === undefined"
        class="q-status d-flex align-items-center align-self-center justify-content-center position-absolute"
        [ngClass]="{
          show: selections[currentQuestion] !== undefined,
          green: selections[currentQuestion] === question.correctChoice,
          red: selections[currentQuestion] !== question.correctChoice
        }"
      >
        {{
          selections[currentQuestion] === question.correctChoice
            ? "🎉   That is correct!"
            : "Not quite right!"
        }}
      </span>
    </div>
    <div
      class="d-flex align-items-start h-100 me-2 w-25 progress-container position-relative align-items-start"
    >
      <span class="fw-bold"
        >{{ currentQuestion + 1 }}/{{ questions[type].length }}</span
      >
      <div
        class="progress ms-4"
        [style.--innerHeight.%]="
          ((currentQuestion + 1) / questions[type].length) * 100
        "
      >
        <div class="progress-bar"></div>
      </div>
    </div>
    <div class="question-container d-flex flex-column align-items-start">
      <div
        class="question-box pt-3 h-75 mb-5 d-flex flex-column position-relative justify-content-start align-items-start"
      >
        <span
          aria-live="polite"
          [attr.aria-hidden]="selections[currentQuestion] === undefined"
          class="explanation"
          [ngClass]="{
            green: selections[currentQuestion] === question.correctChoice,
            red: selections[currentQuestion] !== question.correctChoice
          }"
          [ngStyle]="{
            opacity: selections[currentQuestion] === undefined ? '0' : '1'
          }"
          ><em>{{
            selections[currentQuestion] === question.correctChoice
              ? question.correctExplanation
              : question.incorrectExplanation
          }}</em></span
        >
        <div class="d-flex">
          <p class="query-number fw-bold position-relative">
            {{ currentQuestion + 1 }}.
          </p>
          <p class="query fw-bold">{{ question.query }}</p>
        </div>
        <div
          class="answers w-100"
          [ngClass]="{ 'align-self-center': !isMultipleChoice(question) }"
        >
          <div
            *ngIf="isMultipleChoice(question)"
            class="multi-choice d-flex flex-column"
          >
            <div
              (click)="
                selections[currentQuestion] === undefined &&
                  updateSelection(index)
              "
              *ngFor="let choice of question.choices; let index = index"
              [ngClass]="{
                'enabled cursor-pointer':
                  selections[currentQuestion] === undefined,
                selected: currentQuestionSelection === index,
                relevant:
                  selections[currentQuestion] !== undefined &&
                  (selections[currentQuestion] === index ||
                    question.correctChoice === index)
              }"
              class="multi-choice-answer w-100 mb-2 d-flex align-items-center px-3"
            >
              <div>
                <input
                  type="radio"
                  #questionContainer
                  class="form-check-input d-block me-2"
                  [ngClass]="{
                    'cursor-pointer': selections[currentQuestion] === undefined,
                    correct:
                      selections[currentQuestion] !== undefined &&
                      question.correctChoice === index,
                    incorrect:
                      selections[currentQuestion] === index &&
                      question.correctChoice !== index
                  }"
                  name="question"
                  [value]="index"
                  (change)="updateSelection(index)"
                  [disabled]="
                    selections[currentQuestion] !== undefined &&
                    selections[currentQuestion] !== index &&
                    question.correctChoice !== index
                  "
                  [(ngModel)]="currentQuestionSelection"
                  [id]="'choice-' + ['A', 'B', 'C', 'D'][index]"
                />
              </div>
              <label
                [for]="'choice-' + ['A', 'B', 'C', 'D'][index]"
                [ngClass]="{
                  'cursor-pointer': selections[currentQuestion] === undefined
                }"
                class="form-check-label ms-1 d-block"
              >
                <span class="fw-bold">{{
                  ["A. ", "B. ", "C. ", "D. "][index]
                }}</span
                >{{ choice }}
              </label>
            </div>
          </div>
          <div
            *ngIf="!isMultipleChoice(question)"
            class="binary d-flex justify-content-center"
          >
            <button
              #questionContainer
              type="button"
              class="btn btn-lg mt-3 me-3 d-flex justify-content-center align-items-center"
              [ngClass]="{
                'btn-selected': currentQuestionSelection === true,
                'btn-danger':
                  selections[currentQuestion] !== undefined &&
                  !question.correctChoice &&
                  selections[currentQuestion],
                'btn-primary':
                  selections[currentQuestion] !== undefined &&
                  question.correctChoice &&
                  selections[currentQuestion]
              }"
              (click)="updateSelection(true)"
              [disabled]="selections[currentQuestion] !== undefined"
            >
              <img
                class="me-2"
                *ngIf="selections[currentQuestion] && question.correctChoice"
                alt="check"
                src="/assets/icons/check.svg"
              />
              <img
                class="me-2"
                *ngIf="selections[currentQuestion] && !question.correctChoice"
                alt="x"
                src="/assets/icons/x.svg"
              />
              <span>True</span>
            </button>
            <button
              type="button"
              class="btn btn-lg mt-3 d-flex justify-content-center align-items-center"
              [ngClass]="{
                'btn-selected': currentQuestionSelection === false,
                'btn-danger':
                  selections[currentQuestion] !== undefined &&
                  question.correctChoice &&
                  !selections[currentQuestion],
                'btn-primary':
                  selections[currentQuestion] !== undefined &&
                  !question.correctChoice &&
                  !selections[currentQuestion]
              }"
              (click)="updateSelection(false)"
              [disabled]="selections[currentQuestion] !== undefined"
            >
              <img
                class="me-2"
                *ngIf="
                  selections[currentQuestion] === false &&
                  !question.correctChoice
                "
                alt="check"
                src="/assets/icons/check.svg"
              />
              <img
                class="me-2"
                *ngIf="
                  selections[currentQuestion] === false &&
                  question.correctChoice
                "
                alt="x"
                src="/assets/icons/x.svg"
              />
              <span>False</span>
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="mt-5 teal-btn align-self-center fs-2"
        (click)="submitSelection(currentQuestion)"
        *ngIf="selections[currentQuestion] === undefined"
        [disabled]="currentQuestionSelection === undefined"
      >
        Done
      </button>
      <button
        type="button"
        class="mt-5 teal-btn align-self-center fs-2"
        #nextButton
        (click)="moveToNextQuestion()"
        *ngIf="selections[currentQuestion] !== undefined"
      >
        Next
      </button>
    </div>
  </section>
  <section
    class="assessment-container results d-flex flex-column align-items-center justify-content-center"
    *ngIf="currentQuestion >= questions[type].length"
  >
    <h1 class="wonky">Your score</h1>
    <div
      class="blue-pill d-flex align-items-center justify-content-center mb-4"
    >
      <span class="wonky score">{{ getStats().numCorrect }}</span>
      <span class="total">/ 10</span>
    </div>
    <div
      *ngIf="type === 'post'"
      class="white-pill d-flex justify-content-center align-items-center mb-4"
    >
      <span class="me-2 fw-bold">Previous Score</span>
      <div
        class="blue-pill small fw-bold d-flex justify-content-center align-items-center"
      >
        {{ preAssessmentScore | async }} / 10
      </div>
    </div>
    <ng-container *ngIf="type === 'pre' && getStats() as stats">
      <ng-container [ngSwitch]="true">
        <span
          *ngSwitchCase="stats.numCorrect >= 0 && stats.numCorrect <= 4"
          class="text-center result-text"
          >This is pretty new to you, and that’s OK!</span
        >
        <span
          *ngSwitchCase="stats.numCorrect >= 5 && stats.numCorrect <= 7"
          class="text-center result-text"
          >You know a thing or two about money.</span
        >
        <span
          *ngSwitchCase="stats.numCorrect >= 8 && stats.numCorrect <= 10"
          class="text-center result-text"
          >You’re off to a great start!</span
        >
      </ng-container>
    </ng-container>
    <span class="text-center result-text">{{
      type === "pre"
        ? "Let's jump into the game to learn even more."
        : "Thanks for playing Dollars & Decisions!"
    }}</span>
    <button
      *ngIf="type === 'pre'"
      type="button"
      class="mt-5 teal-btn align-self-center fs-2"
      (click)="advanceScreen()"
    >
      Let's Go!
    </button>
    <button
      *ngIf="type === 'post'"
      type="button"
      class="mt-5 teal-btn align-self-center fs-2"
      (click)="restartCourse(false)"
    >
      Play Again?
    </button>
    <button
      *ngIf="type === 'post'"
      type="button"
      class="mt-4 medium-teal-btn d-flex justify-content-center align-items-center align-self-center fs-2"
      (click)="restartCourse(true)"
    >
      Done
    </button>
  </section>
</div>

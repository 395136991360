import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ClassDataDto } from '../shared/interfaces';
import { TeacherDashboardView } from '../teacher-dashboard/teacher-dashboard.component';
import { BehaviorSubject } from 'rxjs';
import html2PDF from 'jspdf-html2canvas';

const INITIAL_STUDENT_DROPDOWN_STATE = {
  completed: false,
  inProgress: false,
  notStarted: false
};
type CourseCompletionStudent = {
  name: string;
  percentageComplete: number;
  currentModule: string;
};
type SortMethod =
  | 'studentNameAsc'
  | 'completionStatusAsc'
  | 'studentNameDesc'
  | 'completionStatusDesc';

@Component({
  selector: 'app-course-completion',
  templateUrl: './course-completion.component.html',
  styleUrls: ['./course-completion.component.scss']
})
export class CourseCompletionComponent implements OnInit, OnChanges {
  teacherDashboardView = TeacherDashboardView;
  @Input() selectedClass!: ClassDataDto;
  @Output() changeView = new EventEmitter<TeacherDashboardView>();
  showStudentDropdowns: { [key: string]: boolean } =
    INITIAL_STUDENT_DROPDOWN_STATE;
  sortDropdownOpen = false;
  selectedSortMethod$ = new BehaviorSubject<SortMethod>('studentNameAsc');
  sortedStudents: CourseCompletionStudent[] = [];
  @ViewChild('printDiv', { static: false }) printDiv!: ElementRef;

  ngOnInit() {
    this.applySort('studentNameAsc');
  }

  ngOnChanges() {
    this.applySort('studentNameAsc');
  }

  applySort(sortMethod: SortMethod) {
    this.sortedStudents = this.selectedClass.courseCompletion.byStudent
      .slice()
      .sort((a, b) => {
        switch (sortMethod) {
          case 'studentNameAsc':
            return a.name.localeCompare(b.name);
          case 'studentNameDesc':
            return b.name.localeCompare(a.name);
          case 'completionStatusAsc':
            return a.percentageComplete === b.percentageComplete
              ? a.name.localeCompare(b.name)
              : this.percentageSortFn(a, b);
          case 'completionStatusDesc':
            return a.percentageComplete === b.percentageComplete
              ? a.name.localeCompare(b.name)
              : this.percentageSortFn(b, a);
        }
      });

    this.selectedSortMethod$.next(sortMethod);
    this.sortDropdownOpen = false;
  }

  percentageSortFn(
    studentOne: CourseCompletionStudent,
    studentTwo: CourseCompletionStudent
  ) {
    return studentOne.percentageComplete > studentTwo.percentageComplete
      ? 1
      : -1;
  }

  toggleStudentDropdown(key: string) {
    this.showStudentDropdowns = {
      ...INITIAL_STUDENT_DROPDOWN_STATE,
      [key]: !this.showStudentDropdowns[key]
    };
  }

  print() {
    window.print();
  }

  public downloadAsPDF() {
    const printDivHTML = this.printDiv.nativeElement;
    printDivHTML.querySelector('#print-div').classList.add('print');

    html2PDF(printDivHTML, {
      jsPDF: { format: 'letter', unit: 'pt' },
      success: (pdf) => {
        pdf.save(`${this.selectedClass.className}: Course Completion.pdf`);
      }
    });

    printDivHTML.querySelector('#print-div').classList.remove('print');
  }
}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthCredential, GoogleAuthProvider, User } from 'firebase/auth';
import { AppRole, AssessmentStats, TeacherRole } from './interfaces';
import { ApiClient } from './api-client.service';
import { Store } from '@ngrx/store';
import { RestoreProgress } from './ngrx/actions/progress.actions';
import { initialProgress } from './ngrx/reducers/progress.reducer';

export interface CreateTeacherDto {
  firstName: string;
  lastName: string;
  state: string;
  city: string;
  district: string;
  school: string;
  role: TeacherRole;
  email: string;
}

export interface CreateCredentialTeacherDto extends CreateTeacherDto {
  password: string;
}

export interface CreateGoogleTeacherDto extends CreateTeacherDto {
  uid: string;
}

export interface RegisterStudentDto {
  id: string;
  classCode: string;
  password: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  provider = new GoogleAuthProvider();

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private apiClient: ApiClient,
    private store: Store
  ) {}

  logout(navigateHome = true) {
    navigateHome && this.router.navigate(['/']);
    this.store.dispatch(RestoreProgress({ newState: initialProgress }));
    return this.auth.signOut();
  }

  async getCurrentUser() {
    return await this.auth.currentUser;
  }

  async getUserRole(user: User) {
    return (await user.getIdTokenResult())?.claims['appRole'];
  }

  async saveStudentAssessmentData(type: string, stats: AssessmentStats) {
    const user = await this.getCurrentUser();
    const role = user && (await this.getUserRole(user as User));

    if (role === AppRole.STUDENT) {
      const token = await user?.getIdToken();
      this.apiClient
        .saveStudentAssessmentData(token as string, type, stats)
        .subscribe();
    }
  }

  registerTeacher(formBody: CreateCredentialTeacherDto) {
    return this.apiClient.createTeacher(formBody);
  }

  async registerGoogleTeacher(formBody: CreateGoogleTeacherDto) {
    return this.apiClient.createGoogleTeacher(formBody);
  }

  registerStudent(formBody: RegisterStudentDto) {
    return this.apiClient.registerStudent(formBody);
  }

  signInUser(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  signInWithCredential(token: AuthCredential) {
    return this.auth.signInWithCredential(token);
  }

  fetchClassByCode(code: string) {
    return this.apiClient.fetchClassByCode(code);
  }

  async deleteStudent(id: string) {
    const user = await this.getCurrentUser();
    const token = await user?.getIdToken();
    return this.apiClient.deleteStudent(id, token as string);
  }

  async updateStudentPassword(id: string, password: string) {
    const user = await this.getCurrentUser();
    const token = await user?.getIdToken();
    return this.apiClient.updateStudentPassword(id, password, token as string);
  }

  openGoogleLogin() {
    return this.auth.signInWithPopup(this.provider);
  }

  getTeacher(teacherId: string) {
    return this.apiClient.getTeacher(teacherId);
  }
}

import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appTutorialTip]'
})
export class TutorialTipDirective {
  @Input() text!: string;
  @Input() buttonLabel?: string = 'next';
  constructor(private target: ElementRef) {}
  public computePosition(relativeTo: HTMLDivElement): {
    left: string;
    bottom: string;
  } {
    let pos1: { left: number; top: number } =
      this.target.nativeElement.getBoundingClientRect();
    let pos2: { left: number; top: number } =
      relativeTo.getBoundingClientRect();
    return {
      left: `${pos1.left - pos2.left}px`,
      bottom: `calc(100% - ${pos1.top - pos2.top}px + 12px)`
    };
  }
  public scrollIntoView(relativeTo: HTMLElement) {
    let difference =
      this.target.nativeElement.getBoundingClientRect().bottom -
      relativeTo.getBoundingClientRect().bottom;
    if (difference > 0) relativeTo.scrollBy(0, difference);
  }
}

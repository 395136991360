import { Action, createAction, props } from '@ngrx/store';

export const SavingsModalLaunched = createAction(
  '[Game Screen] Savings Modal Launched'
);
export const BudgetModalLaunched = createAction(
  '[Game Screen] Budget Modal Launched',
  props<{ fromButton?: boolean }>()
);
export const CreditCardModalLaunched = createAction(
  '[Game Screen] Credit Card Modal Launched'
);
export const RetirementModalLaunched = createAction(
  '[Game Screen] 401k Modal Launched'
);
export const ModalClosed = createAction(
  '[Modal] Close Modal with result',
  props<{ result?: Action; resumeAnimation: boolean }>()
);
export const ModalClosedAndAnimationResumed = createAction(
  '[Modal Effect] Resume Animation'
);

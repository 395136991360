import { Screen } from '../interfaces';
import {
  selectCharacter,
  selectCredit
} from '../ngrx/selectors/progress.selectors';
import { firstValueFrom } from 'rxjs';
import { CreditCardModalLaunched } from '../ngrx/actions/modals/modals.actions';
import {
  DelayAnimation,
  PlaySound,
  JumpToAnimationEvent,
  HideToolTip,
  ShowToolTip,
  ShowFullScreenInfo,
  ShowNarrativeChoice
} from '../ngrx/actions/overlays.actions';
import { UpdateBalance, AdvanceScreen } from '../ngrx/actions/progress.actions';

export const credit: Screen = {
  component: 'game',
  module: {
    title: {
      chapter: 'Module 5',
      text: 'Credit',
      titleBackgroundImage: '/assets/images/apartment-bg.png'
    },
    animation: async (store, assetManager) => {
      const character = await firstValueFrom(store.select(selectCharacter));
      const sounds = [
        `/assets/sounds/scene11/S11L1.mp3`,
        `/assets/sounds/scene11/S11L2${character.soundAlias}.mp3`,
        `/assets/sounds/scene11/S11L3.mp3`,
        `/assets/sounds/scene11/S11L4${character.soundAlias}.mp3`,
        `/assets/sounds/scene11/S11L5.mp3`,
        `/assets/sounds/scene11/S11L6${character.soundAlias}.mp3`,
        `/assets/sounds/scene11/S11L7.mp3`,
        `/assets/sounds/scene11/S11L8.mp3`,
        `/assets/sounds/scene11/S11L9.mp3`
      ];
      const lineTwoFrame = { MC1: 110, MC2: 100, MC3: 100 }[
        character.soundAlias as string
      ] as number;
      const lineThreeFrame = { MC1: 380, MC2: 335, MC3: 315 }[
        character.soundAlias as string
      ] as number;
      const lineFourFrame = { MC1: 1785, MC2: 1745, MC3: 1725 }[
        character.soundAlias as string
      ] as number;
      const lineFiveFrame = { MC1: 2115, MC2: 2025, MC3: 2000 }[
        character.soundAlias as string
      ] as number;
      const lineSevenFrame = { MC1: 4040, MC2: 3940, MC3: 3915 }[
        character.soundAlias as string
      ] as number;
      const endingFrame = { MC1: 6426, MC2: 6325, MC3: 6301 }[
        character.soundAlias as string
      ] as number;
      await assetManager.loadAssets(sounds);
      return {
        layerPaths: [
          `/assets/lottie/scene11/scene11${character.soundAlias}.json`
        ],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...ShowToolTip({
              options: {
                text: 'The clothes you were selling online finally found a buyer! An extra <span class="fw-bold">$75</span> has been deposited into your account.',
                type: 'medium',
                mood: 'positive'
              }
            }),
            segment: [0, 1],
            pause: false
          },
          {
            ...DelayAnimation({ delayTime: 5000 }),
            segment: [1, 1],
            pause: true,
            noAudio: true
          },
          {
            ...HideToolTip({ size: 'medium' }),
            segment: [1, 1],
            pause: false
          },
          {
            ...UpdateBalance({ value: 75, skipNotification: true }),
            segment: [1, 1],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene11/S11L1.mp3` }),
            segment: [0, 0],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene11/S11L2${character.soundAlias}.mp3`
            }),
            segment: [0, lineTwoFrame],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene11/S11L3.mp3` }),
            segment: [lineTwoFrame, lineThreeFrame],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene11/S11L4${character.soundAlias}.mp3`
            }),
            segment: [lineThreeFrame, lineFourFrame],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene11/S11L5.mp3` }),
            segment: [lineFourFrame, lineFiveFrame],
            pause: false
          },
          {
            ...ShowToolTip({
              options: {
                text: '<b>Keep your credit card debt low.</b> It’s easy to get in over your head with credit card debt. Make sure the amount you owe each month is no more than 10% of your monthly income. You want to keep your balance below 70% of your credit limit. This will help you build credit and leave enough available in case of emergency.',
                type: 'large',
                mood: 'neutral'
              }
            }),
            segment: [lineFiveFrame, lineFiveFrame + 1185],
            pause: true
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene11/S11L6${character.soundAlias}.mp3`
            }),
            segment: [lineFiveFrame + 1185, lineFiveFrame + 1875],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene11/S11L7.mp3` }),
            segment: [lineFiveFrame + 1875, lineSevenFrame],
            pause: false
          },
          {
            ...ShowNarrativeChoice({ key: 'credit-card' }),
            segment: [lineSevenFrame, lineSevenFrame + 1333],
            pause: true
          },
          async () => {
            let credit = await firstValueFrom(store.select(selectCredit));
            return {
              ...JumpToAnimationEvent({
                index: credit.card === 'none' ? -11 : -8
              }),
              segment: [lineSevenFrame + 1333, lineSevenFrame + 1333],
              pause: true
            };
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene11/S11L8.mp3` }),
            segment: [lineSevenFrame + 1333, lineSevenFrame + 1333],
            pause: false
          },
          {
            ...DelayAnimation({ delayTime: 2000 }),
            segment: [lineSevenFrame + 1333, lineSevenFrame + 1900],
            pause: true
          },
          {
            ...AdvanceScreen({ moduleCompleted: 5 }),
            segment: [lineSevenFrame + 1900, lineSevenFrame + 1900],
            pause: true,
            noAudio: true
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene11/S11L9.mp3` }),
            segment: [lineSevenFrame + 1900, lineSevenFrame + 1900],
            pause: false
          },
          {
            ...ShowToolTip({
              options: {
                type: 'large',
                mood: 'neutral',
                text: 'Be careful! It’s easy to purchase more than you planned to when you have a credit card. It’s important to keep your budget in mind when you’re making purchases.',
                avatar: 'roommate',
                delayed: true
              }
            }),
            segment: [lineSevenFrame + 1900, endingFrame - 280],
            pause: false
          },
          {
            ...CreditCardModalLaunched(),
            segment: [endingFrame - 280, endingFrame - 280],
            pause: true
          },
          {
            ...DelayAnimation({ delayTime: 1000 }),
            segment: [endingFrame - 280, endingFrame - 280],
            pause: true,
            noAudio: true
          },
          {
            ...ShowFullScreenInfo({ key: 'creditSuccess' }),
            segment: [endingFrame - 280, endingFrame - 280],
            pause: true,
            noAudio: true
          },
          {
            ...DelayAnimation({ delayTime: 2000 }),
            segment: [endingFrame - 280, endingFrame],
            pause: true
          },
          {
            ...AdvanceScreen({ moduleCompleted: 5 }),
            segment: [endingFrame, endingFrame],
            pause: true,
            noAudio: true
          }
        ]
      };
    },
    infoScreens: {
      creditSuccess: {
        header: 'Yay!',
        text: 'With healthy habits, a credit card can be a great tool for spending and saving. Remember, it’s easy to lose control of your debt if you overspend or let late fees accumulate.',
        buttonText: 'Next',
        action: JumpToAnimationEvent({ index: -2 })
      }
    }
  }
};

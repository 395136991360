import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { ModalClosed } from '../shared/ngrx/actions/modals/modals.actions';
import { selectJob } from '../shared/ngrx/selectors/progress.selectors';

@Component({
  selector: 'app-retirement-modal',
  templateUrl: './retirement-modal.component.html',
  styleUrls: ['./retirement-modal.component.scss']
})
export class RetirementModalComponent implements OnInit {
  @HostBinding('role') role = 'main';
  job$ = this.store.select(selectJob);
  cost!: number;
  contribution: number = 5;
  matching: number = 3;
  salary!: number;
  growthRate: number = 0.05;
  finalGrowth!: number;
  totalContributed = 0;
  futureValue = 0;
  timeTillRetirement: number = 30;
  @ViewChild('content', { read: ElementRef })
  contentDiv!: ElementRef<HTMLDivElement>;

  constructor(private store: Store) {
    this.job$.pipe(take(1)).subscribe(({ salary, key }) => {
      this.salary = salary;
      this.matching = key === 'coder' ? this.matching : 0;
    });
  }

  ngOnInit(): void {
    this.updateCompoundValue();
    this.updateFinalValue();
  }

  updateCompoundValue() {
    let monthlyRate = this.growthRate / 12;
    let periods = this.timeTillRetirement * 12;
    this.finalGrowth = ((1 + monthlyRate) ** periods - 1) / monthlyRate;
  }

  updateFinalValue() {
    let actualEmployerContribution = Math.min(this.contribution, this.matching);
    let monthlyContribution =
      (this.salary / 12) *
      ((this.contribution + actualEmployerContribution) / 100);
    this.totalContributed =
      this.salary * (this.contribution / 100) * this.timeTillRetirement;
    this.futureValue = monthlyContribution * this.finalGrowth;
  }
  handleClose() {
    this.store.dispatch(ModalClosed({ resumeAnimation: true }));
  }
  dualValueLabel(value: number): string {
    return `${value}%`;
  }
  yearsLabel(value: number): string {
    return `${value} YRS`;
  }

  lastY?: number = undefined;
  @HostListener('touchmove', ['$event'])
  @HostListener('wheel', ['$event'])
  handleScroll(event: WheelEvent | TouchEvent) {
    if (!this.contentDiv.nativeElement.contains(<Node>event.target)) {
      event.preventDefault();
      if (event instanceof TouchEvent) {
        this.contentDiv.nativeElement.scrollBy(
          0,
          (this.lastY ?? 0) - event.touches[0].clientY
        );
        this.lastY = event.touches[0].clientY;
      } else {
        this.contentDiv.nativeElement.scrollBy(0, event.deltaY / 2);
      }
    }
  }
  @HostListener('touchend')
  @HostListener('touchstart', ['$event'])
  resetScrollTracker(event?: any) {
    this.lastY = event;
  }
}

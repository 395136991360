import { Component, OnInit } from '@angular/core';
import { ProgressService } from './shared/progress.service';
import { AnalyticsService } from './shared/analytics.service';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsType } from './shared/interfaces';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'dollars-and-decisions';
  currentRoute: string = '';

  constructor(
    private progressService: ProgressService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.progressService.restoreProgress();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentRoute = e.url;
        if (e.url === '/')
          this.analyticsService.sendAnalytics(AnalyticsType.PAGEVIEW);
      }
    });
  }
  displayFooter(route: string) {
    return (
      route.includes('sign-in') ||
      route.includes('admin') ||
      route.includes('teacher-login') ||
      route.includes('teacher')
    );
  }
}

export const statesData = {
  type: 'FeatureCollection',
  name: 'states',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::4269' } },
  features: [
    {
      type: 'Feature',
      properties: { id: '01', name: 'Alabama' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.331023390633902, 30.235387028960282],
              [-88.130022927029273, 30.262017879368791],
              [-88.126433633036328, 30.283494371633708],
              [-88.076183517135163, 30.249131984009836],
              [-88.108487163071629, 30.225078312673119],
              [-88.144380103001026, 30.238823267722672],
              [-88.208987394873944, 30.237105148341477],
              [-88.280773274732738, 30.222501133601334],
              [-88.331023390633902, 30.235387028960282]
            ]
          ],
          [
            [
              [-88.201808806888067, 34.995436774557739],
              [-88.201808806888067, 35.008322669916694],
              [-87.663414707947069, 35.003168311773109],
              [-87.437289186391865, 35.003168311773109],
              [-86.86300214752147, 34.992000535795349],
              [-86.529197806178061, 34.990282416414161],
              [-86.396393928439281, 34.992000535795349],
              [-85.825696183561831, 34.988564297032966],
              [-85.60674924999249, 34.984268998579978],
              [-85.552909840098394, 34.708510837898373],
              [-85.470356078260778, 34.327947394963942],
              [-85.405748786387861, 34.002363772227717],
              [-85.359087964479642, 33.749800223192224],
              [-85.305248554585546, 33.482632659416588],
              [-85.186801852818519, 32.870123100020997],
              [-85.154498206882067, 32.842633189921891],
              [-85.16885538285382, 32.811707041060401],
              [-85.122194560945601, 32.773049354983542],
              [-85.104248090980903, 32.645049461084604],
              [-85.068355151051506, 32.583197163361625],
              [-85.007337153171534, 32.523922044710439],
              [-84.996569271192712, 32.453479150081492],
              [-84.964265625256246, 32.42255300122001],
              [-85.000158565185643, 32.322043017420171],
              [-84.935551273312726, 32.297989346083455],
              [-84.892479745397452, 32.263626958459582],
              [-84.928372685326849, 32.21895585454854],
              [-84.967854919249191, 32.21895585454854],
              [-84.960676331263315, 32.198338421974213],
              [-85.010926447164465, 32.18029816847168],
              [-85.057587269072684, 32.135627064560637],
              [-85.046819387093862, 32.087519721887212],
              [-85.053997975079753, 32.013640588495882],
              [-85.079123033030328, 31.939761455104545],
              [-85.129373148931492, 31.877909157381566],
              [-85.1401410309103, 31.839251471304706],
              [-85.11860526695267, 31.732728069670692],
              [-85.125783854938547, 31.69492944328443],
              [-85.057587269072684, 31.620191250202495],
              [-85.046819387093862, 31.517104087330871],
              [-85.071944445044451, 31.468137684966841],
              [-85.064765857058561, 31.431198118271176],
              [-85.093480209002081, 31.362473343023424],
              [-85.08989091500915, 31.29460762746627],
              [-85.115015972959725, 31.276567373963736],
              [-85.097069502995026, 31.232755329743291],
              [-85.107837384973848, 31.186366106451061],
              [-85.036051505115054, 31.108191674606744],
              [-85.010926447164465, 31.053211854408538],
              [-85.003747859178588, 31.000809213282125],
              [-85.24423055670556, 31.000809213282125],
              [-85.499070430204299, 30.996513914829144],
              [-85.893892769427694, 30.993077676066754],
              [-86.518429924199239, 30.993077676066754],
              [-87.002984613246127, 30.999091093900937],
              [-87.598807416074152, 30.997372974519742],
              [-87.588039534095344, 30.964728706277057],
              [-87.634700356003563, 30.865936841858414],
              [-87.627521768017672, 30.846178468974685],
              [-87.544968006180056, 30.778312753417531],
              [-87.534200124201234, 30.743091306103054],
              [-87.404985540455399, 30.674366530855302],
              [-87.394217658476578, 30.615091412204116],
              [-87.448057068370687, 30.527467323763233],
              [-87.437289186391865, 30.480219040780405],
              [-87.365503306533057, 30.43640699655996],
              [-87.430110598405975, 30.406339907389068],
              [-87.458824950349495, 30.335897012760121],
              [-87.491128596285961, 30.323011117401165],
              [-87.466003538335386, 30.302393684826846],
              [-87.519842948229481, 30.280058132871325],
              [-87.656236119961193, 30.249991043700433],
              [-87.799807879678795, 30.229373611126107],
              [-88.000808343283424, 30.225937372363717],
              [-87.936201051410507, 30.261158819678194],
              [-87.893129523495233, 30.238823267722672],
              [-87.767504233742329, 30.262017879368791],
              [-87.756736351763507, 30.291225908849086],
              [-87.810575761657617, 30.332460773997738],
              [-87.835700819608192, 30.369400340693403],
              [-87.907486699466986, 30.409776146151458],
              [-87.932611757417575, 30.487091518305178],
              [-87.90030811148111, 30.550661935409352],
              [-87.911075993459932, 30.615950471894713],
              [-87.936201051410507, 30.657185337043366],
              [-88.007986931269315, 30.684675247142465],
              [-88.06182634116341, 30.645158501375008],
              [-88.054647753177534, 30.612514233132323],
              [-88.086951399113985, 30.5635478307683],
              [-88.083362105121054, 30.52832638345383],
              [-88.104897869078684, 30.500836473354731],
              [-88.108487163071629, 30.377131877908774],
              [-88.13720151501515, 30.32043393832938],
              [-88.191040924909245, 30.31699769956699],
              [-88.1874516309163, 30.347923848428479],
              [-88.259237510775108, 30.382286236052359],
              [-88.313076920669204, 30.368541281002805],
              [-88.331023390633902, 30.388299653886534],
              [-88.39563068250682, 30.369400340693403],
              [-88.406398564485642, 30.560111592005917],
              [-88.417166446464464, 30.866795901549011],
              [-88.438702210422107, 31.230178150671499],
              [-88.474595150351504, 31.894231291502912],
              [-88.442291504415039, 32.137345183941832],
              [-88.366916330563299, 32.747277564265637],
              [-88.348969860598601, 32.929398218672183],
              [-88.230523158831588, 33.891545072140715],
              [-88.208987394873944, 34.028994622636219],
              [-88.140790809008081, 34.581370003690033],
              [-88.097719281092807, 34.892349611686107],
              [-88.155147984979848, 34.922416700856999],
              [-88.201808806888067, 34.995436774557739]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '02', name: 'Alaska' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-150.242755474854732, 61.137482219112186],
              [-150.221219710897088, 61.170126487354864],
              [-150.156612419024185, 61.160676830758291],
              [-150.228398298882979, 61.123737264062626],
              [-150.242755474854732, 61.137482219112186]
            ]
          ],
          [
            [
              [-150.773970985809854, 59.332597809168078],
              [-150.669881460014579, 59.409913181321798],
              [-150.616042050120484, 59.395309166581654],
              [-150.619631344113429, 59.355792420814197],
              [-150.720131575915758, 59.292222003710023],
              [-150.773970985809854, 59.308544137831376],
              [-150.773970985809854, 59.332597809168078]
            ]
          ],
          [
            [
              [-151.527722724327248, 59.147899975689754],
              [-151.434401080510781, 59.135873140021388],
              [-151.445168962489618, 59.104946991159906],
              [-151.527722724327248, 59.147899975689754]
            ]
          ],
          [
            [
              [-151.717955305953041, 59.132436901258998],
              [-151.638990838108384, 59.117832886518855],
              [-151.664115896058945, 59.089483916729165],
              [-151.714366011960124, 59.101510752397516],
              [-151.717955305953041, 59.132436901258998]
            ]
          ],
          [
            [
              [-151.861527065670657, 59.165940229192287],
              [-151.789741185811835, 59.164222109811092],
              [-151.807687655776533, 59.135014080330791],
              [-151.861527065670657, 59.165940229192287]
            ]
          ],
          [
            [
              [-151.890241417614163, 58.219256450154489],
              [-151.861527065670657, 58.257914136231349],
              [-151.789741185811835, 58.246746360253596],
              [-151.818455537755369, 58.18403500284002],
              [-151.872294947649465, 58.169430988099876],
              [-151.890241417614163, 58.219256450154489]
            ]
          ],
          [
            [
              [-152.073295411254094, 58.924544456134555],
              [-152.030223883338834, 58.948598127471271],
              [-151.954848709487095, 58.914235739847385],
              [-152.073295411254094, 58.91251762046619],
              [-152.073295411254094, 58.924544456134555]
            ]
          ],
          [
            [
              [-152.08406329323293, 60.346288244072426],
              [-151.979973767437656, 60.414153959629587],
              [-151.95125941549415, 60.510368644976438],
              [-151.839991301713013, 60.485455913949124],
              [-151.940491533515313, 60.430476093750926],
              [-151.972795179451794, 60.388382168911676],
              [-151.954848709487095, 60.36776473633735],
              [-152.08406329323293, 60.346288244072426]
            ]
          ],
          [
            [
              [-152.356849636696353, 58.915094799537982],
              [-152.306599520795203, 58.962343082520817],
              [-152.241992228922271, 58.938289411184101],
              [-152.159438467084669, 58.934853172421711],
              [-152.277885168851668, 58.906504202632021],
              [-152.356849636696353, 58.915094799537982]
            ]
          ],
          [
            [
              [-152.640403862138612, 60.165026649356477],
              [-152.579385864258626, 60.170181007500062],
              [-152.561439394293927, 60.094583754727537],
              [-152.640403862138612, 60.165026649356477]
            ]
          ],
          [
            [
              [-153.419280658606567, 58.063766646156452],
              [-153.315191132811321, 58.140222958619574],
              [-153.261351722917226, 58.14537731676316],
              [-153.168030079100788, 58.088679377183766],
              [-153.15008360913609, 58.105860570995702],
              [-153.225458782987829, 58.161699450884498],
              [-153.203923019030185, 58.208088674176736],
              [-153.171619373093733, 58.216679271082697],
              [-153.07470843528435, 58.195202778817773],
              [-152.999333261432611, 58.211524912939126],
              [-153.103422787227856, 58.257914136231349],
              [-153.045994083340815, 58.306021478904782],
              [-152.94190455754557, 58.279390628496273],
              [-152.888065147651474, 58.282826867258663],
              [-152.938315263552624, 58.330934209932096],
              [-152.895243735637337, 58.345538224672239],
              [-152.823457855778543, 58.328357030860303],
              [-152.773207739877392, 58.367014716937163],
              [-152.837815031750296, 58.372169075080748],
              [-152.884475853658529, 58.400518044870438],
              [-152.812689973799735, 58.403095223942231],
              [-152.73372550595505, 58.460652223212222],
              [-152.611689510195106, 58.476115297642963],
              [-152.654761038110365, 58.506182386813862],
              [-152.665528920089201, 58.56459844577445],
              [-152.568617982279818, 58.621296385353844],
              [-152.543492924329229, 58.594665534945335],
              [-152.353260342703408, 58.63847757916578],
              [-152.328135284752847, 58.632464161331598],
              [-152.371206812668106, 58.531095117841176],
              [-152.417867634576339, 58.51563204341042],
              [-152.511189278392777, 58.427148895278947],
              [-152.493242808428079, 58.354987881268798],
              [-152.364028224682244, 58.364437537865371],
              [-152.356849636696353, 58.423712656516557],
              [-152.277885168851668, 58.415981119301179],
              [-152.202509994999929, 58.354987881268798],
              [-152.130724115141135, 58.39622274641745],
              [-152.087652587225875, 58.367873776627761],
              [-152.148670585105833, 58.267363792827922],
              [-152.116366939169382, 58.248464479634791],
              [-152.08406329323293, 58.31031677735777],
              [-151.987152355423547, 58.350692582815824],
              [-151.962027297472957, 58.329216090550901],
              [-151.972795179451794, 58.230424226132257],
              [-152.080473999239985, 58.153967913669121],
              [-152.195331407014066, 58.173726286552849],
              [-152.234813640936409, 58.243310121491206],
              [-152.310188814788148, 58.220974569535684],
              [-152.263527992879915, 58.135927660166587],
              [-152.3424924607246, 58.118746466354651],
              [-152.482474926449243, 58.129914242332418],
              [-152.539903630336283, 58.083525019040181],
              [-152.568617982279818, 58.114451167901663],
              [-152.561439394293927, 58.178021585005837],
              [-152.597332334223324, 58.179739704387032],
              [-152.629635980159776, 58.081806899658986],
              [-152.658350332103311, 58.06118946708466],
              [-152.769618445884447, 58.046585452344516],
              [-152.762439857898556, 58.014800243792422],
              [-152.819868561785597, 57.995900930599291],
              [-152.981386791467912, 57.984733154621537],
              [-153.290066074860732, 58.050021691106906],
              [-153.365441248712472, 58.038853915129138],
              [-153.419280658606567, 58.063766646156452]
            ]
          ],
          [
            [
              [-153.559263124331238, 59.391013868128667],
              [-153.412102070620705, 59.415067539465383],
              [-153.347494778747773, 59.378127972769718],
              [-153.365441248712472, 59.336893107621066],
              [-153.412102070620705, 59.32314815257152],
              [-153.51619159641595, 59.320570973499727],
              [-153.573620300302991, 59.371255495244938],
              [-153.559263124331238, 59.391013868128667]
            ]
          ],
          [
            [
              [-154.158675221152208, 56.691848320273195],
              [-154.047407107371072, 56.730506006350055],
              [-154.018692755427537, 56.689271141201402],
              [-154.151496633166317, 56.681539603986025],
              [-154.158675221152208, 56.691848320273195]
            ]
          ],
          [
            [
              [-154.363264978749783, 56.542371934109326],
              [-154.291479098890989, 56.595633634926344],
              [-154.223282513025111, 56.61281482873828],
              [-154.183800279102769, 56.603365172141707],
              [-154.094067929279277, 56.617969186881865],
              [-154.076121459314578, 56.583606799257979],
              [-154.007924873448729, 56.551821590705899],
              [-153.878710289702894, 56.565566545755445],
              [-153.885888877688757, 56.533781337203365],
              [-153.954085463554634, 56.507150486794856],
              [-154.119192987229866, 56.501996128651271],
              [-154.234050395003948, 56.490828352673518],
              [-154.348907802778001, 56.512304844938441],
              [-154.363264978749783, 56.542371934109326]
            ]
          ],
          [
            [
              [-154.779623081930822, 57.366210177391764],
              [-154.707837202072, 57.380814192131908],
              [-154.693480026100247, 57.446102728617277],
              [-154.628872734227343, 57.510532205412048],
              [-154.539140384403822, 57.539740234892335],
              [-154.521193914439124, 57.577538861278597],
              [-154.467354504545028, 57.570666383753831],
              [-154.345318508785084, 57.630800562095615],
              [-154.226871807018057, 57.661726710957097],
              [-154.086889341293386, 57.648840815598149],
              [-153.98279981549814, 57.649699875288746],
              [-153.98279981549814, 57.553485189941895],
              [-153.889478171681702, 57.504518787577865],
              [-153.875120995709949, 57.543176473654725],
              [-153.814102997829963, 57.588706637256365],
              [-153.878710289702894, 57.629941502405018],
              [-153.857174525745251, 57.651417994669941],
              [-153.666941944119429, 57.639391159001576],
              [-153.677709826098237, 57.669458248172475],
              [-153.864353113731113, 57.707256874558738],
              [-153.93254969959699, 57.703820635796347],
              [-153.936138993589935, 57.812921216502161],
              [-153.824870879808799, 57.865323857628567],
              [-153.720781354013525, 57.890236588655881],
              [-153.648995474154731, 57.879927872368711],
              [-153.570031006310046, 57.831820529695293],
              [-153.548495242352402, 57.717565590845894],
              [-153.494655832458307, 57.728733366823661],
              [-153.462352186521855, 57.794880962999628],
              [-153.480298656486553, 57.842129245982449],
              [-153.354673366733664, 57.809484977739771],
              [-153.329548308783075, 57.849860783197826],
              [-153.462352186521855, 57.880786932059308],
              [-153.537727360373594, 57.930612394113936],
              [-153.512602302423005, 57.968411020500199],
              [-153.451584304543019, 57.963256662356613],
              [-153.268530310903088, 57.888518469274686],
              [-153.236226664966637, 57.891954708037076],
              [-153.128547845178446, 57.856733260722592],
              [-153.089065611256103, 57.865323857628567],
              [-153.20033372503724, 57.929753334423339],
              [-153.272119604896034, 57.958961363903626],
              [-153.297244662846623, 57.996759990289888],
              [-153.236226664966637, 57.996759990289888],
              [-153.128547845178446, 57.946934528235275],
              [-153.024458319383172, 57.957243244522431],
              [-152.877297265672638, 57.932330513495131],
              [-152.852172207722077, 57.974424438334381],
              [-152.722957623976242, 57.98731033369333],
              [-152.751671975919749, 57.933189573185729],
              [-152.891654441644391, 57.842129245982449],
              [-152.916779499594981, 57.770827291662911],
              [-152.848582913729132, 57.739901142801415],
              [-152.848582913729132, 57.821511813408122],
              [-152.791154209842091, 57.858451380103787],
              [-152.73372550595505, 57.816357455264537],
              [-152.715779035990352, 57.855874201031995],
              [-152.654761038110365, 57.883364111131101],
              [-152.636814568145667, 57.918585558445571],
              [-152.586564452244517, 57.927176155351546],
              [-152.55067151231512, 57.900545304943037],
              [-152.471707044470435, 57.962397602666016],
              [-152.410689046590448, 57.969270080190796],
              [-152.42504622256223, 57.930612394113936],
              [-152.324545990759901, 57.916867439064376],
              [-152.364028224682244, 57.884223170821699],
              [-152.403510458604586, 57.901404364633635],
              [-152.468117750477489, 57.888518469274686],
              [-152.399921164611641, 57.85759232041319],
              [-152.417867634576339, 57.81549839557394],
              [-152.349671048710491, 57.834397708767085],
              [-152.310188814788148, 57.79058566454664],
              [-152.213277876978765, 57.791444724237238],
              [-152.299420932809312, 57.745914560635597],
              [-152.356849636696353, 57.774263530425301],
              [-152.4429926925269, 57.775981649806482],
              [-152.496832102421024, 57.734746784657844],
              [-152.439403398533983, 57.727015247442466],
              [-152.468117750477489, 57.681485083840826],
              [-152.374796106661051, 57.67461260631606],
              [-152.468117750477489, 57.598156293852924],
              [-152.385563988639888, 57.612760308593082],
              [-152.338903166731654, 57.653136114051136],
              [-152.320956696766956, 57.623069024880238],
              [-152.152259879098779, 57.619632786117847],
              [-152.163027761077615, 57.584411338803378],
              [-152.292242344823421, 57.517404682936814],
              [-152.338903166731654, 57.422908116971158],
              [-152.47529633846338, 57.434075892948925],
              [-152.482474926449243, 57.4675792208822],
              [-152.51836786637864, 57.43235777356773],
              [-152.60092162821627, 57.468438280572798],
              [-152.661939626096256, 57.463283922429213],
              [-152.744493387933858, 57.505377847268463],
              [-152.798332797827982, 57.494210071290709],
              [-152.884475853658529, 57.510532205412048],
              [-152.913190205602035, 57.47101545964459],
              [-152.816279267792652, 57.471874519335188],
              [-152.762439857898556, 57.45727050459503],
              [-152.60092162821627, 57.382532311513103],
              [-152.629635980159776, 57.322398133171319],
              [-152.712189741997406, 57.277727029260276],
              [-152.751671975919749, 57.308653178121773],
              [-152.819868561785597, 57.265700193591925],
              [-152.888065147651474, 57.291471984309837],
              [-152.909600911609118, 57.324116252552514],
              [-152.984976085460858, 57.339579326983255],
              [-153.056761965319652, 57.329270610696099],
              [-153.117779963199609, 57.297485402144019],
              [-153.013690437404364, 57.2992035215252],
              [-152.94190455754557, 57.257109596685964],
              [-153.078297729277267, 57.211579433084324],
              [-153.20033372503724, 57.22188814937148],
              [-153.214690901008993, 57.204706955559544],
              [-153.1249585511855, 57.175498926079257],
              [-153.07470843528435, 57.186666702057011],
              [-152.949083145531432, 57.187525761747608],
              [-152.870118677686776, 57.150586195051943],
              [-152.909600911609118, 57.126532523715227],
              [-152.995743967439665, 57.119660046190447],
              [-153.117779963199609, 57.091311076400757],
              [-153.146494315143144, 57.100760732997315],
              [-153.214690901008993, 57.075848001970016],
              [-153.203923019030185, 57.033754077130766],
              [-153.300833956839568, 56.990801092600918],
              [-153.347494778747773, 57.007982286412854],
              [-153.322369720797212, 57.037190315893156],
              [-153.404923482634814, 57.080143300422989],
              [-153.379798424684225, 57.120519105881044],
              [-153.28288748687487, 57.173780806698062],
              [-153.311601838818376, 57.194398239272388],
              [-153.365441248712472, 57.176357985769855],
              [-153.487477244472444, 57.086156718257172],
              [-153.498245126451252, 57.065539285682846],
              [-153.577209594295937, 57.093029195781952],
              [-153.656174062140622, 57.084438598875977],
              [-153.67412053210532, 57.054371509705092],
              [-153.580798888288882, 57.049217151561507],
              [-153.544905948359485, 56.995096391053906],
              [-153.602334652246498, 56.942693749927486],
              [-153.692067002070019, 56.92551255611555],
              [-153.688477708077073, 56.865378377773766],
              [-153.778210057900566, 56.830156930459296],
              [-153.821281585815854, 56.841324706437049],
              [-153.903835347653455, 56.77088181180811],
              [-153.972031933519332, 56.745110021090198],
              [-154.036639225392236, 56.763150274592732],
              [-154.147907339173372, 56.745969080780796],
              [-154.068942871328716, 56.845620004890037],
              [-154.029460637406373, 56.85506966148661],
              [-153.936138993589935, 56.916062899518991],
              [-153.84999593775936, 56.942693749927486],
              [-153.857174525745251, 56.97447895847958],
              [-153.975621227512249, 56.954720585595851],
              [-153.964853345533442, 56.999391689506879],
              [-153.885888877688757, 57.087015777947769],
              [-153.781799351893511, 57.131686881858812],
              [-153.8069244098441, 57.157458672576723],
              [-153.860763819738196, 57.118800986499849],
              [-153.98279981549814, 57.066398345373443],
              [-154.076121459314578, 56.970183660026592],
              [-154.162264515145125, 56.919499138281367],
              [-154.212514631046304, 56.910049481684808],
              [-154.230461101011002, 56.872250855298546],
              [-154.298657686876851, 56.846479064580635],
              [-154.313014862848604, 56.918640078590784],
              [-154.40633650666507, 56.968465540645397],
              [-154.524783208432069, 56.991660152291516],
              [-154.514015326453261, 57.077566121351211],
              [-154.528372502425015, 57.168626448554477],
              [-154.575033324333219, 57.239928402874014],
              [-154.614515558255562, 57.26741831297312],
              [-154.697069320093192, 57.284599506785057],
              [-154.793980257902575, 57.288894805238044],
              [-154.743730142001397, 57.314666595955956],
              [-154.797569551895492, 57.346451804508035],
              [-154.779623081930822, 57.366210177391764]
            ]
          ],
          [
            [
              [-154.804748139881383, 56.434130413094124],
              [-154.707837202072, 56.520895441844416],
              [-154.524783208432069, 56.604224231832305],
              [-154.44940803458033, 56.600787993069915],
              [-154.391979330693289, 56.554398769777691],
              [-154.435050858608577, 56.534640396893963],
              [-154.63246202822026, 56.471929039480386],
              [-154.736551554015534, 56.404063323923225],
              [-154.804748139881383, 56.434130413094124]
            ]
          ],
          [
            [
              [-155.748732460024598, 55.825916152151507],
              [-155.65541081620816, 55.861137599465991],
              [-155.637464346243462, 55.893781867708668],
              [-155.590803524335229, 55.912681180901799],
              [-155.562089172391723, 55.885191270802693],
              [-155.583624936349338, 55.848251704107028],
              [-155.565678466384668, 55.789835645146439],
              [-155.590803524335229, 55.76148667535675],
              [-155.720018108081064, 55.772654451334503],
              [-155.748732460024598, 55.825916152151507]
            ]
          ],
          [
            [
              [-156.750145484054826, 56.037244836038354],
              [-156.732199014090128, 56.077620641496409],
              [-156.681948898188978, 56.099097133761333],
              [-156.635288076280744, 56.048412612016108],
              [-156.681948898188978, 55.994291851508507],
              [-156.750145484054826, 56.037244836038354]
            ]
          ],
          [
            [
              [-156.803984893948922, 56.197029938489379],
              [-156.77168124801247, 56.227097027660264],
              [-156.760913366033662, 56.156654133031324],
              [-156.796806305963059, 56.155795073340727],
              [-156.803984893948922, 56.197029938489379]
            ]
          ],
          [
            [
              [-157.324432522925207, 56.540653814728145],
              [-157.249057349073468, 56.581888679876783],
              [-157.076771237412373, 56.579311500805005],
              [-156.987038887588852, 56.532922277512768],
              [-157.11266417734177, 56.552680650396496],
              [-157.170092881228811, 56.530345098440975],
              [-157.324432522925207, 56.52519074029739],
              [-157.324432522925207, 56.540653814728145]
            ]
          ],
          [
            [
              [-157.902308855788533, 56.348224444034429],
              [-157.834112269922684, 56.369700936299353],
              [-157.794630036000342, 56.320734533935337],
              [-157.826933681936794, 56.311284877338764],
              [-157.902308855788533, 56.348224444034429]
            ]
          ],
          [
            [
              [-158.896543291832899, 55.825916152151507],
              [-158.867828939889392, 55.88089597234972],
              [-158.799632354023515, 55.891204688636876],
              [-158.70272141621416, 55.841379226582262],
              [-158.731435768157667, 55.825916152151507],
              [-158.806810942009406, 55.857701360703601],
              [-158.896543291832899, 55.825916152151507]
            ]
          ],
          [
            [
              [-159.144204577345761, 55.876600673896732],
              [-159.08677587345872, 55.834506749057482],
              [-159.183686811268103, 55.844815465344638],
              [-159.144204577345761, 55.876600673896732]
            ]
          ],
          [
            [
              [-159.320079982999829, 54.897272626616257],
              [-159.323669276992746, 54.928198775477739],
              [-159.277008455084541, 54.948816208052065],
              [-159.205222575225747, 54.927339715787156],
              [-159.237526221162199, 54.87665519404193],
              [-159.305722807028047, 54.863769298682982],
              [-159.320079982999829, 54.897272626616257]
            ]
          ],
          [
            [
              [-159.384687274872732, 55.749459839688384],
              [-159.355972922929226, 55.807016838958376],
              [-159.320079982999829, 55.815607435864351],
              [-159.280597749077486, 55.762345735047347],
              [-159.384687274872732, 55.749459839688384]
            ]
          ],
          [
            [
              [-159.460062448724472, 54.948816208052065],
              [-159.416990920809184, 55.021836281752812],
              [-159.456473154731526, 55.061353027520269],
              [-159.34520504095039, 55.059634908139074],
              [-159.330847864978637, 54.974587998769977],
              [-159.42775880278802, 54.941943730527299],
              [-159.460062448724472, 54.948816208052065]
            ]
          ],
          [
            [
              [-159.603634208442088, 54.813943836628354],
              [-159.567741268512663, 54.825111612606122],
              [-159.510312564625622, 54.78903110560104],
              [-159.513901858618567, 54.759823076120753],
              [-159.596455620456197, 54.756386837358363],
              [-159.603634208442088, 54.813943836628354]
            ]
          ],
          [
            [
              [-159.661062912329101, 55.190211981109798],
              [-159.600044914449143, 55.172171727607264],
              [-159.582098444484444, 55.214265652446514],
              [-159.521080446604458, 55.253782398213971],
              [-159.503133976639759, 55.23488308502084],
              [-159.531848328583266, 55.185057622966227],
              [-159.499544682646814, 55.173889846988459],
              [-159.495955388653869, 55.136091220602196],
              [-159.531848328583266, 55.08368857947579],
              [-159.485187506675061, 55.057916788757879],
              [-159.510312564625622, 55.04159465463654],
              [-159.571330562505608, 55.060493967829672],
              [-159.578509150491499, 55.042453714327138],
              [-159.650295030350293, 55.049326191851904],
              [-159.661062912329101, 55.190211981109798]
            ]
          ],
          [
            [
              [-159.81899184801847, 54.820816314153134],
              [-159.743616674166731, 54.842292806418058],
              [-159.704134440244388, 54.816521015700147],
              [-159.775920320103182, 54.794185463744626],
              [-159.81899184801847, 54.820816314153134]
            ]
          ],
          [
            [
              [-159.847706199961976, 55.055339609686087],
              [-159.815402554025525, 55.088842937619361],
              [-159.732848792187895, 55.124064384933845],
              [-159.714902322223224, 55.093138236072349],
              [-159.797456084060826, 55.060493967829672],
              [-159.829759729997278, 55.010668505775044],
              [-159.847706199961976, 55.055339609686087]
            ]
          ],
          [
            [
              [-160.113313955439537, 55.333674949439484],
              [-160.063063839538387, 55.343124606036056],
              [-160.045117369573688, 55.307044099030975],
              [-160.113313955439537, 55.333674949439484]
            ]
          ],
          [
            [
              [-160.249707127171263, 54.929916894858934],
              [-160.131260425404236, 55.014104744537434],
              [-160.07742101551014, 55.037299356183553],
              [-160.095367485474839, 55.052762430614294],
              [-160.174331953319523, 55.052762430614294],
              [-160.188689129291276, 55.118050967099663],
              [-160.109724661446592, 55.16100395162951],
              [-160.012813723637237, 55.123205325243248],
              [-160.016403017630154, 55.155849593485925],
              [-160.063063839538387, 55.200520697396968],
              [-159.955385019750196, 55.1893529214192],
              [-159.890777727877264, 55.287285726147246],
              [-159.847706199961976, 55.267527353263517],
              [-159.854884787947867, 55.230587786567853],
              [-159.887188433884319, 55.228869667186657],
              [-159.905134903849017, 55.1644401903919],
              [-159.862063375933758, 55.148977115961145],
              [-159.862063375933758, 55.177326085750849],
              [-159.815402554025525, 55.178185145441446],
              [-159.829759729997278, 55.126641564005638],
              [-159.86924196391962, 55.094856355453544],
              [-159.948206431764305, 55.105165071740714],
              [-159.95179572575725, 55.066507385663854],
              [-160.052295957559579, 55.005514147631473],
              [-160.116903249432482, 54.984896715057147],
              [-160.228171363213619, 54.863769298682982],
              [-160.249707127171263, 54.929916894858934]
            ]
          ],
          [
            [
              [-160.317903713037111, 58.694316459054576],
              [-160.299957243072413, 58.72953790636906],
              [-160.249707127171263, 58.658235952049509],
              [-160.274832185121852, 58.636759459784585],
              [-160.317903713037111, 58.694316459054576]
            ]
          ],
          [
            [
              [-160.346618064980646, 55.426453396023945],
              [-160.267653597135961, 55.462533903029026],
              [-160.138439013390126, 55.450507067360661],
              [-160.142028307383072, 55.383500411494111],
              [-160.321493007030057, 55.393809127781267],
              [-160.346618064980646, 55.426453396023945]
            ]
          ],
          [
            [
              [-160.436350414804139, 58.68400774276742],
              [-160.407636062860604, 58.750155338943387],
              [-160.389689592895905, 58.701188936579356],
              [-160.436350414804139, 58.68400774276742]
            ]
          ],
          [
            [
              [-160.856297811978095, 55.318211875008743],
              [-160.798869108091083, 55.381782292112916],
              [-160.709136758267562, 55.40325878437784],
              [-160.644529466394658, 55.383500411494111],
              [-160.651708054380521, 55.343983665726654],
              [-160.615815114451124, 55.348278964179627],
              [-160.572743586535864, 55.388654769637682],
              [-160.51890417664174, 55.36116485953859],
              [-160.425582532825331, 55.338829307583069],
              [-160.332260889008865, 55.360305799847993],
              [-160.307135831058304, 55.303607860268599],
              [-160.339439476994755, 55.252064278832776],
              [-160.389689592895905, 55.286426666456663],
              [-160.46865406074059, 55.289003845528441],
              [-160.51890417664174, 55.30876221841217],
              [-160.529672058620577, 55.343124606036056],
              [-160.565564998549974, 55.332815889748886],
              [-160.565564998549974, 55.2735407710977],
              [-160.526082764627631, 55.256359577285764],
              [-160.486600530705289, 55.181621384203837],
              [-160.526082764627631, 55.130077802768014],
              [-160.655297348373466, 55.160144891938913],
              [-160.691190288302863, 55.210829413684124],
              [-160.755797580175795, 55.195366339253383],
              [-160.820404872048698, 55.118050967099663],
              [-160.806047696076945, 55.167876429154276],
              [-160.841940636006342, 55.204815995849955],
              [-160.841940636006342, 55.294158203672026],
              [-160.856297811978095, 55.318211875008743]
            ]
          ],
          [
            [
              [-161.082423333533313, 58.589511176801764],
              [-161.057298275582752, 58.702047996269954],
              [-160.917315809858081, 58.746719100180997],
              [-160.841940636006342, 58.751873458324567],
              [-160.701958170281699, 58.817161994809936],
              [-160.684011700317001, 58.815443875428741],
              [-160.881422869928684, 58.580920579895789],
              [-160.960387337773369, 58.553430669796683],
              [-161.075244745547451, 58.549994431034307],
              [-161.082423333533313, 58.589511176801764]
            ]
          ],
          [
            [
              [-161.426995556855559, 55.216842831518306],
              [-161.330084619046175, 55.219420010590099],
              [-161.344441795017929, 55.158426772557718],
              [-161.437763438834367, 55.198802578015773],
              [-161.426995556855559, 55.216842831518306]
            ]
          ],
          [
            [
              [-161.692603312333119, 55.217701891208904],
              [-161.656710372403722, 55.244332741617413],
              [-161.527495788657859, 55.251205219142179],
              [-161.559799434594339, 55.207393174921734],
              [-161.692603312333119, 55.198802578015773],
              [-161.692603312333119, 55.217701891208904]
            ]
          ],
          [
            [
              [-161.904371657916556, 55.117191907409065],
              [-161.890014481944803, 55.16100395162951],
              [-161.828996484064817, 55.178185145441446],
              [-161.739264134241324, 55.161863011320108],
              [-161.696192606326065, 55.136950280292794],
              [-161.570567316573147, 55.100869773287727],
              [-161.549031552615503, 55.065648325973257],
              [-161.656710372403722, 55.103446952359519],
              [-161.739264134241324, 55.054480549995489],
              [-161.839764366043653, 55.118050967099663],
              [-161.879246599965995, 55.085406698856985],
              [-161.904371657916556, 55.117191907409065]
            ]
          ],
          [
            [
              [-162.435587168871677, 54.929057835168337],
              [-162.327908349083486, 54.985755774747744],
              [-162.234586705267049, 54.962561163101626],
              [-162.234586705267049, 54.891259208782074],
              [-162.274068939189391, 54.845729045180448],
              [-162.320729761097596, 54.827688791677915],
              [-162.417640698906979, 54.877514253732528],
              [-162.435587168871677, 54.929057835168337]
            ]
          ],
          [
            [
              [-162.68324845438454, 63.584084217932173],
              [-162.615051868518663, 63.621882844318435],
              [-162.539676694666923, 63.635627799367981],
              [-162.453533638836376, 63.621023784627837],
              [-162.374569170991691, 63.626178142771423],
              [-162.34226552505524, 63.594392934219329],
              [-162.345854819048185, 63.551439949689481],
              [-162.615051868518663, 63.540272173711728],
              [-162.679659160391594, 63.556594307833066],
              [-162.68324845438454, 63.584084217932173]
            ]
          ],
          [
            [
              [-162.833998802088018, 54.45056158750586],
              [-162.830409508095073, 54.494373631726305],
              [-162.586337516575156, 54.447984408434081],
              [-162.518140930709308, 54.414481080500792],
              [-162.424819286892841, 54.414481080500792],
              [-162.360211995019938, 54.39042740916409],
              [-162.431997874878732, 54.370669036280347],
              [-162.467890814808129, 54.342320066490657],
              [-162.546855282652814, 54.37668245411453],
              [-162.6078732805328, 54.368950916899166],
              [-162.719141394313937, 54.400736125451246],
              [-162.78374868618684, 54.375823394423932],
              [-162.862713154031525, 54.424789796787962],
              [-162.833998802088018, 54.45056158750586]
            ]
          ],
          [
            [
              [-163.171392437424373, 55.429030575095737],
              [-163.121142321523195, 55.42817151540514],
              [-163.139088791487893, 55.395527247162462],
              [-163.171392437424373, 55.429030575095737]
            ]
          ],
          [
            [
              [-164.865539202092009, 54.206588635376349],
              [-164.822467674176721, 54.22548794856948],
              [-164.76503897028968, 54.210024874138739],
              [-164.84041414414142, 54.178239665586645],
              [-164.865539202092009, 54.206588635376349]
            ]
          ],
          [
            [
              [-165.220879307393062, 54.101783353123523],
              [-165.141914839548377, 54.130991382603824],
              [-165.023468137781379, 54.121541726007251],
              [-164.937325081950803, 54.137004800437992],
              [-164.919378611986104, 54.116387367863666],
              [-164.951682257922556, 54.077729681786806],
              [-165.045003901738994, 54.066561905809053],
              [-165.213700719407171, 54.089756517455172],
              [-165.220879307393062, 54.101783353123523]
            ]
          ],
          [
            [
              [-165.482897768877677, 54.075152502715014],
              [-165.267540129301267, 54.09576993528934],
              [-165.235236483364815, 54.065702846118455],
              [-165.289075893258911, 54.038212936019349],
              [-165.335736715167144, 54.069998144571443],
              [-165.457772710927088, 54.066561905809053],
              [-165.482897768877677, 54.075152502715014]
            ]
          ],
          [
            [
              [-165.576219412694115, 54.041649174781739],
              [-165.515201414814129, 54.066561905809053],
              [-165.490076356863568, 54.035635756947556],
              [-165.554683648736471, 54.023608921279205],
              [-165.576219412694115, 54.041649174781739]
            ]
          ],
          [
            [
              [-165.683898232482306, 54.249541619906196],
              [-165.637237410574102, 54.297648962579615],
              [-165.586987294672923, 54.284763067220666],
              [-165.52238000280002, 54.300226141651407],
              [-165.479308474884732, 54.282185888148874],
              [-165.558272942729417, 54.25383691835917],
              [-165.511612120821184, 54.212602053210517],
              [-165.403933301032993, 54.212602053210517],
              [-165.400344007040047, 54.177380605896047],
              [-165.482897768877677, 54.17995778496784],
              [-165.547505060750609, 54.112092069410679],
              [-165.630058822588211, 54.132709501985005],
              [-165.637237410574102, 54.199716157851569],
              [-165.579808706687061, 54.229783247022468],
              [-165.612112352623512, 54.246964440834404],
              [-165.669541056510553, 54.22892418733187],
              [-165.683898232482306, 54.249541619906196]
            ]
          ],
          [
            [
              [-166.100256335663346, 54.143877277962773],
              [-166.082309865698647, 54.174803426824255],
              [-165.981809633896319, 54.221192650116492],
              [-165.874130814108128, 54.216038291972907],
              [-165.86336293212932, 54.166212829918294],
              [-165.791577052270497, 54.171367188061879],
              [-165.741326936369347, 54.158481292702916],
              [-165.712612584425841, 54.120682666316654],
              [-165.665951762517608, 54.131850442294407],
              [-165.673130350503499, 54.096628994979937],
              [-165.766451994319937, 54.065702846118455],
              [-165.809523522235224, 54.075152502715014],
              [-165.874130814108128, 54.036494816638154],
              [-165.895666578065772, 54.06054848797487],
              [-165.985398927889264, 54.061407547665468],
              [-166.04641692576925, 54.044226353853531],
              [-166.111024217642154, 54.122400785697849],
              [-166.100256335663346, 54.143877277962773]
            ]
          ],
          [
            [
              [-166.197167273472729, 53.984951235202345],
              [-166.17204221552214, 53.997837130561294],
              [-166.075131277712757, 53.969488160771604],
              [-166.189988685486838, 53.960038504175031],
              [-166.197167273472729, 53.984951235202345]
            ]
          ],
          [
            [
              [-167.442652289022874, 60.20969775326752],
              [-167.317026999269984, 60.231174245532443],
              [-167.11243724167241, 60.231174245532443],
              [-167.033472773827725, 60.213993051720507],
              [-166.950919011990095, 60.222583648626482],
              [-166.918615366053643, 60.201966216052156],
              [-166.843240192201904, 60.210556812958117],
              [-166.807347252272507, 60.236328603676029],
              [-166.836061604216042, 60.268972871918706],
              [-166.714025608456069, 60.327388930879295],
              [-166.581221730717289, 60.317939274282736],
              [-166.581221730717289, 60.349724482834816],
              [-166.491489380893796, 60.389241228602273],
              [-166.373042679126769, 60.354878840978401],
              [-166.286899623296222, 60.382368751077507],
              [-166.204345861458592, 60.390100288292871],
              [-166.150506451564496, 60.436489511585108],
              [-166.082309865698647, 60.323093632426321],
              [-165.985398927889264, 60.314503035520346],
              [-165.881309402094018, 60.343711065000647],
              [-165.777219876298744, 60.326529871188697],
              [-165.680308938489361, 60.292167483564825],
              [-165.723380466404649, 60.236328603676029],
              [-165.683898232482306, 60.198529977289766],
              [-165.723380466404649, 60.164167589665894],
              [-165.676719644496444, 60.154717933069321],
              [-165.662362468524663, 60.098879053180525],
              [-165.709023290432896, 60.066234784937834],
              [-165.633648116581156, 60.01984556164561],
              [-165.626469528595266, 59.970020099590982],
              [-165.551094354743526, 59.978610696496958],
              [-165.590576588665868, 59.949402667016656],
              [-165.583398000680006, 59.908167801868004],
              [-165.838237874178731, 59.89012754836547],
              [-165.859773638136375, 59.860919518885183],
              [-165.931559517995169, 59.877241653006521],
              [-166.024881161811606, 59.86177857857578],
              [-166.125381393613935, 59.811953116521153],
              [-166.096667041670401, 59.756973296322954],
              [-166.189988685486838, 59.750100818798174],
              [-166.272542447324469, 59.811094056830555],
              [-166.408935619056166, 59.85146986228861],
              [-166.61352537665374, 59.84803362352622],
              [-166.663775492554919, 59.878100712697119],
              [-166.764275724357219, 59.89356378712786],
              [-166.861186662166602, 59.943389249182488],
              [-166.986811951919492, 59.979469756187555],
              [-167.130383711637108, 59.995791890308894],
              [-167.24524111941119, 60.057644188031873],
              [-167.334973469234683, 60.066234784937834],
              [-167.342152057220574, 60.126368963279617],
              [-167.442652289022874, 60.20969775326752]
            ]
          ],
          [
            [
              [-167.851831804218023, 53.315743736227347],
              [-167.790813806338065, 53.335502109111076],
              [-167.71184933849338, 53.381032272712716],
              [-167.622116988669859, 53.385327571165703],
              [-167.489313110931107, 53.419689958789576],
              [-167.457009464994627, 53.442884570435695],
              [-167.356509233192327, 53.424844316933161],
              [-167.306259117291148, 53.441166451054499],
              [-167.277544765347642, 53.478965077440762],
              [-167.198580297502957, 53.462642943319423],
              [-167.187812415524149, 53.524495241042402],
              [-167.101669359693574, 53.515045584445829],
              [-167.16268735757356, 53.606105911649109],
              [-167.090901477714766, 53.633595821748216],
              [-167.062187125771231, 53.619850866698656],
              [-167.008347715877136, 53.635313941129397],
              [-167.072955007750068, 53.665381030300296],
              [-167.058597831778314, 53.69888435823357],
              [-166.997579833898328, 53.718642731117299],
              [-166.893490308103082, 53.717783671426702],
              [-166.861186662166602, 53.659367612466113],
              [-166.807347252272507, 53.665381030300296],
              [-166.832472310223096, 53.708334014830143],
              [-166.785811488314863, 53.734964865238638],
              [-166.976044069940684, 53.775340670696693],
              [-167.026294185841834, 53.757300417194159],
              [-167.141151593615916, 53.826884252132515],
              [-167.141151593615916, 53.866400997899973],
              [-167.02988347983478, 53.945434489434888],
              [-166.879133132131301, 53.988387473964735],
              [-166.749918548385466, 54.015877384063828],
              [-166.728382784427822, 54.002991488704879],
              [-166.64582902259022, 54.014159264682633],
              [-166.588400318703179, 53.960038504175031],
              [-166.64582902259022, 53.923957997169964],
              [-166.620703964639631, 53.893890907999065],
              [-166.559685966759645, 53.878427833568324],
              [-166.509435850858495, 53.923957997169964],
              [-166.487900086900851, 53.89560902738026],
              [-166.434060677006755, 53.920521758407574],
              [-166.437649970999701, 53.955743205722044],
              [-166.373042679126769, 54.009863966229659],
              [-166.319203269232673, 53.960038504175031],
              [-166.279721035310331, 53.982374056130553],
              [-166.207935155451537, 53.929112355313549],
              [-166.236649507395072, 53.881005012640117],
              [-166.319203269232673, 53.869837236662363],
              [-166.405346325063249, 53.809703058320579],
              [-166.548918084780837, 53.749568879978796],
              [-166.541739496794946, 53.716065552045507],
              [-166.419703501035002, 53.762454775337744],
              [-166.211524449444482, 53.817434595535943],
              [-166.197167273472729, 53.836333908729074],
              [-166.118202805628044, 53.855233221922205],
              [-166.093077747677455, 53.838911087800867],
              [-166.111024217642154, 53.777058790077888],
              [-166.168452921529195, 53.733246745857457],
              [-166.261774565345632, 53.704038716377156],
              [-166.283310329303276, 53.684280343493427],
              [-166.444828558985591, 53.640468299272982],
              [-166.552507378773782, 53.623287105461046],
              [-166.509435850858495, 53.583770359693588],
              [-166.566864554745536, 53.582911300002991],
              [-166.581221730717289, 53.530508658876585],
              [-166.63865043460433, 53.545112673616728],
              [-166.663775492554919, 53.484978495274945],
              [-166.735561372413713, 53.506454987539868],
              [-166.749918548385466, 53.441166451054499],
              [-166.879133132131301, 53.429998675076746],
              [-166.958097599975986, 53.455770465794643],
              [-166.993990539905383, 53.429139615386148],
              [-167.037062067820671, 53.448897988269877],
              [-167.11243724167241, 53.417112779717783],
              [-167.133973005630054, 53.426562436314356],
              [-167.291901941319395, 53.36385107890078],
              [-167.302669823298231, 53.337220228492271],
              [-167.385223585135833, 53.340656467254661],
              [-167.442652289022874, 53.320898094370932],
              [-167.489313110931107, 53.269354512935124],
              [-167.589813342733407, 53.289112885818852],
              [-167.622116988669859, 53.250455199741992],
              [-167.797992394323927, 53.284817587365865],
              [-167.851831804218023, 53.315743736227347]
            ]
          ],
          [
            [
              [-168.128207441674391, 65.65613619165191],
              [-168.10308238372383, 65.685344221132198],
              [-167.981046387963858, 65.728297205662045],
              [-167.539563226832257, 65.821075652246506],
              [-167.313437705277039, 65.88464606935068],
              [-167.141151593615916, 65.948216486454854],
              [-166.97963336393363, 65.996323829128286],
              [-166.828883016230151, 66.051303649326485],
              [-166.527382320823193, 66.141504916839153],
              [-166.32997115121151, 66.189612259512586],
              [-165.805934228242279, 66.333075227842272],
              [-165.188575661456611, 66.465370420194191],
              [-164.711199560395585, 66.54268579234791],
              [-164.398930983009819, 66.58134347842477],
              [-163.914376293962931, 66.594229373783733],
              [-163.727733006330055, 66.58477971718716],
              [-163.605697010570083, 66.558148866778652],
              [-163.752858064280645, 66.548699210182093],
              [-163.727733006330055, 66.516054941939416],
              [-163.763625946259452, 66.455061703907035],
              [-163.874894060040589, 66.388914107731068],
              [-163.871304766047643, 66.328779929389285],
              [-163.828233238132356, 66.280672586715852],
              [-163.903608411984095, 66.229988064970641],
              [-164.079483817638163, 66.201639095180937],
              [-164.093840993609916, 66.184457901369001],
              [-163.917965587955877, 66.190471319203183],
              [-163.767215240252398, 66.060753305923043],
              [-163.623643480534781, 66.058176126851265],
              [-163.494428896788946, 66.085666036950357],
              [-163.343678549085467, 66.08480697725976],
              [-163.167803143431428, 66.059035186541848],
              [-162.99910632576325, 66.077075440044396],
              [-162.751445040250388, 66.089961335403345],
              [-162.636587632476306, 66.040135873348717],
              [-162.457122932829321, 66.058176126851265],
              [-162.39251564095639, 66.028968097370964],
              [-162.205872353323514, 66.05645800747007],
              [-162.141265061450611, 66.078793559425577],
              [-161.839764366043653, 66.022954679536781],
              [-161.775157074170721, 66.073639201282006],
              [-161.681835430354283, 66.111437827668269],
              [-161.549031552615503, 66.240296781257797],
              [-161.4844242607426, 66.262632333213318],
              [-161.340852501024983, 66.254900795997955],
              [-161.312138149081477, 66.221397468064666],
              [-161.197280741307395, 66.21108875177751],
              [-161.086012627526259, 66.234283363423629],
              [-160.992690983709821, 66.234283363423629],
              [-161.107548391483903, 66.328779929389285],
              [-161.362388264982627, 66.37602821237212],
              [-161.523906494664942, 66.396645644946446],
              [-161.696192606326065, 66.395786585255848],
              [-161.911550245902447, 66.344243003820026],
              [-161.864889423994214, 66.440457689166877],
              [-161.87565730597305, 66.511759643486428],
              [-162.098193533535323, 66.609692448214474],
              [-162.177158001380008, 66.687866880058792],
              [-162.270479645196446, 66.717933969229676],
              [-162.482247990779911, 66.729960804898042],
              [-162.510962342723417, 66.77806814757146],
              [-162.625819750497499, 66.85538351972518],
              [-162.600694692546909, 66.898336504255028],
              [-162.503783754737526, 66.913799578685783],
              [-162.467890814808129, 66.950739145381448],
              [-162.410462110921088, 66.918953936829354],
              [-162.324319055090541, 66.94300760816607],
              [-162.227408117281158, 66.866551295702948],
              [-162.098193533535323, 66.78837686385863],
              [-162.012050477704776, 66.779786266952655],
              [-162.080247063570624, 66.693021238202377],
              [-162.069479181591817, 66.645772955219542],
              [-161.968978949789488, 66.602819970689694],
              [-161.764389192191913, 66.49715562874627],
              [-161.574156610566092, 66.438739569785682],
              [-161.43417414484145, 66.454202644216437],
              [-161.32649532505323, 66.478256315553139],
              [-161.294191679116778, 66.520350240392389],
              [-161.455709908799065, 66.522068359773584],
              [-161.491602848728462, 66.560726045850444],
              [-161.692603312333119, 66.620860224192228],
              [-161.882835893958941, 66.717074909539079],
              [-161.846942954029544, 66.761746013450121],
              [-161.846942954029544, 66.813289594885944],
              [-161.796692838128365, 66.833047967769673],
              [-161.79310354413542, 66.882014370133689],
              [-161.674656842368421, 66.961906921359201],
              [-161.566978022580201, 66.934417011260109],
              [-161.488013554735545, 66.936994190331887],
              [-161.4844242607426, 66.961047861668604],
              [-161.620817432474325, 67.008296144651439],
              [-161.710549782297818, 67.001423667126659],
              [-161.811050014100118, 67.050390069490689],
              [-162.123318591485912, 67.025477338463375],
              [-162.238175999259965, 66.993692129911295],
              [-162.360211995019938, 66.993692129911295],
              [-162.500194460744581, 66.977369995789942],
              [-162.622230456504553, 66.9919740105301],
              [-162.694016336363347, 67.028913577225765],
              [-162.755034334243334, 67.015168622176205],
              [-162.830409508095073, 67.034926995059934],
              [-162.844766684066826, 66.991114950839503],
              [-162.973981267812661, 67.024618278772778],
              [-163.300607021170208, 67.060698785777845],
              [-163.494428896788946, 67.087329636186354],
              [-163.666715008450069, 67.100215531545302],
              [-163.745679476294754, 67.125987322263214],
              [-163.742090182301808, 67.210175171941714],
              [-163.821054650146493, 67.35020190150901],
              [-164.007697937779369, 67.535758794677932],
              [-164.079483817638163, 67.58558425673256],
              [-164.208698401383998, 67.638845957549563],
              [-164.535324154741545, 67.725610986299856],
              [-165.05577178371783, 67.921476595755948],
              [-165.13114695756957, 67.942094028330274],
              [-165.3142009512095, 68.016832221412201],
              [-165.493665650856485, 68.058926146251451],
              [-165.687487526475252, 68.090711354803531],
              [-165.870541520115182, 68.11046972768726],
              [-165.974631045910456, 68.140536816858159],
              [-166.042827631776305, 68.200670995199943],
              [-166.236649507395072, 68.272832009210077],
              [-166.398167737077358, 68.307194396833964],
              [-166.68890055050548, 68.342415844148434],
              [-166.839650898208959, 68.337261486004849],
              [-166.706847020470207, 68.371623873628721],
              [-166.376631973119714, 68.422308395373946],
              [-166.30484609326092, 68.461825141141404],
              [-166.294078211282113, 68.51079154350542],
              [-166.225881625416235, 68.576080079990788],
              [-166.186399391493893, 68.778818166971661],
              [-166.215113743437428, 68.879328150771499],
              [-165.57263011870117, 68.852697300362991],
              [-165.328558127181253, 68.857851658506576],
              [-164.811699792197913, 68.893932165511643],
              [-164.528145566755654, 68.917985836848359],
              [-164.251769929299286, 68.930871732207308],
              [-163.975394291842917, 68.984992492714923],
              [-163.828233238132356, 69.040831372603719],
              [-163.72414371233711, 69.066603163321616],
              [-163.533911130711289, 69.141341356403558],
              [-163.243178317283167, 69.306280816998154],
              [-163.167803143431428, 69.385314308533069],
              [-163.142678085480838, 69.45318002409023],
              [-163.149856673466729, 69.612965126541255],
              [-163.009874207742058, 69.813126034450335],
              [-162.7083735123351, 69.970333957829567],
              [-162.47506940279402, 70.124105642446409],
              [-162.34226552505524, 70.190253238622375],
              [-161.879246599965995, 70.329420908499074],
              [-161.53467437664375, 70.299353819328189],
              [-161.287013091130888, 70.296776640256397],
              [-161.042941099610971, 70.331139027880269],
              [-160.813226284062836, 70.376669191481909],
              [-160.479421942719426, 70.46601139930398],
              [-160.213814187241866, 70.558789845888441],
              [-160.055885251552496, 70.632668979279785],
              [-159.646705736357347, 70.794172201112005],
              [-159.528259034590349, 70.8208030515205],
              [-159.172918929289267, 70.874923812028115],
              [-159.147793871338706, 70.83454800657006],
              [-158.950382701726994, 70.796749380183797],
              [-158.656060594305927, 70.786440663896627],
              [-158.41198860278601, 70.799326559255576],
              [-158.365327780877806, 70.819943991829902],
              [-158.250470373103724, 70.81736681275811],
              [-158.031523439534396, 70.831970827498267],
              [-157.769504978049781, 70.875782871718712],
              [-157.50389722257222, 70.948802945419445],
              [-157.249057349073468, 71.052749167981673],
              [-157.119842765327633, 71.128346420754198],
              [-156.811163481934813, 71.287272463514626],
              [-156.567091490414896, 71.352561],
              [-156.524019962499608, 71.294144941039406],
              [-156.355323144831431, 71.261500672796714],
              [-156.215340679106788, 71.259782553415519],
              [-156.075358213382117, 71.242601359603583],
              [-156.017929509495076, 71.172158464974643],
              [-155.921018571685693, 71.207379912289113],
              [-155.565678466384668, 71.165285987449863],
              [-155.511839056490544, 71.081098137771363],
              [-155.705660932109311, 71.020104899738982],
              [-155.8312862218622, 70.965984139231381],
              [-155.978447275572734, 70.962547900468991],
              [-155.935375747657474, 70.837125185641852],
              [-155.644642934229324, 70.82423929028289],
              [-155.486713998539983, 70.874923812028115],
              [-155.511839056490544, 70.940212348513469],
              [-155.454410352603503, 70.94708482603825],
              [-155.343142238822367, 71.004641825308241],
              [-155.260588476984765, 71.014950541595411],
              [-155.192391891118888, 70.973715676446758],
              [-155.178034715147135, 71.02783643695436],
              [-155.274945652956518, 71.064776003650024],
              [-155.149320363203628, 71.11202428663286],
              [-155.073945189351889, 71.071648481174805],
              [-155.084713071330697, 71.127487361063601],
              [-155.030873661436601, 71.146386674256732],
              [-154.776033787937877, 71.082816257152558],
              [-154.614515558255562, 71.026118317573165],
              [-154.567854736347357, 70.990037810568097],
              [-154.607336970269699, 70.961688840778393],
              [-154.603747676276754, 70.868051334503335],
              [-154.571444030340302, 70.825957409664085],
              [-154.352497096770946, 70.83454800657006],
              [-154.226871807018057, 70.802762798017966],
              [-154.169443103131016, 70.768400410394094],
              [-153.997156991469893, 70.821662111211097],
              [-153.889478171681702, 70.886091588005868],
              [-153.487477244472444, 70.886091588005868],
              [-153.239815958959582, 70.922172095010936],
              [-153.049583377333761, 70.912722438414377],
              [-152.906011617616173, 70.883514408934076],
              [-152.604510922209215, 70.881796289552881],
              [-152.385563988639888, 70.854306379453789],
              [-152.224045758957573, 70.82423929028289],
              [-152.191742113021121, 70.795031260802602],
              [-152.285063756837559, 70.78558160420603],
              [-152.378385400653997, 70.714279649886493],
              [-152.471707044470435, 70.688507859168581],
              [-152.421456928569285, 70.608615307943069],
              [-152.331724578745764, 70.612910606396056],
              [-152.263527992879915, 70.59229317382173],
              [-152.102009763197628, 70.587138815678145],
              [-152.066116823268231, 70.563085144341429],
              [-151.818455537755369, 70.545903950529492],
              [-151.700008835988342, 70.55363548774487],
              [-151.761026833868328, 70.516695921049205],
              [-151.728723187931877, 70.495219428784281],
              [-151.91895576955767, 70.47288387682876],
              [-151.947670121501204, 70.452266444254434],
              [-151.87588424164241, 70.43078995198951],
              [-151.556437076270754, 70.435944310133095],
              [-151.298007908779084, 70.400722862818611],
              [-151.247757792877906, 70.372373893028922],
              [-151.147257561075605, 70.376669191481909],
              [-150.989328625386236, 70.450548324873239],
              [-150.784738867788661, 70.463434220232187],
              [-150.698595811958114, 70.447971145801446],
              [-150.662702872028717, 70.477179175281748],
              [-150.519131112311101, 70.483192593115916],
              [-150.415041586515855, 70.459997981469797],
              [-150.361202176621759, 70.409313459724586],
              [-150.210451828918281, 70.432508071370705],
              [-150.074058657186555, 70.439380548895471],
              [-149.865879605596035, 70.510682503215023],
              [-149.81921878368783, 70.491783190021891],
              [-149.657700554005544, 70.508964383833828],
              [-149.582325380153804, 70.496078488474879],
              [-149.460289384393832, 70.5184140404304],
              [-149.417217856478544, 70.492642249712489],
              [-149.180324452944518, 70.485769772187709],
              [-148.957788225382245, 70.42391747446473],
              [-148.857287993579916, 70.423058414774133],
              [-148.789091407714068, 70.402440982199806],
              [-148.69576976389763, 70.429071832608315],
              [-148.609626708067083, 70.422199355083535],
              [-148.476822830328302, 70.359487997669959],
              [-148.476822830328302, 70.320830311593099],
              [-148.351197540575384, 70.30450817747176],
              [-148.204036486864851, 70.348320221692205],
              [-148.200447192871906, 70.294199461184604],
              [-148.107125549055468, 70.34316586354862],
              [-147.816392735627346, 70.277018267372668],
              [-147.766142619726196, 70.219461268102677],
              [-147.67999956389562, 70.199702895218948],
              [-147.504124158241581, 70.200561954909531],
              [-147.349784516545157, 70.187676059550583],
              [-147.245694990749882, 70.218602208412079],
              [-147.163141228912281, 70.155890850998503],
              [-146.990855117251158, 70.147300254092528],
              [-146.886765591455912, 70.185957940169388],
              [-146.732425949759488, 70.175649223882232],
              [-146.509889722197215, 70.185957940169388],
              [-146.272996318663161, 70.177367343263427],
              [-146.097120913009121, 70.144723075020735],
              [-145.917656213362136, 70.142145895948943],
              [-145.85663821548215, 70.166199567285659],
              [-145.623334105941041, 70.084588896678952],
              [-145.52283387413874, 70.077716419154186],
              [-145.407976466364659, 70.031327195861948],
              [-145.246458236682372, 70.017582240812402],
              [-145.17467235682355, 69.991810450094491],
              [-145.013154127141263, 69.98150173380732],
              [-144.952136129261277, 69.959166181851813],
              [-144.855225191451893, 69.986656091950906],
              [-144.618331787917867, 69.969474898138969],
              [-144.456813558235581, 70.035622494314936],
              [-144.284527446574458, 70.040776852458521],
              [-144.252223800638006, 70.057958046270457],
              [-143.950723105231049, 70.100051971109707],
              [-143.886115813358117, 70.120669403684019],
              [-143.577436529965297, 70.14644119440193],
              [-143.426686182261818, 70.124964702137007],
              [-143.254400070600695, 70.152454612236113],
              [-143.222096424664244, 70.136991537805372],
              [-142.938542199221985, 70.074280180391796],
              [-142.748309617596163, 70.042494971839702],
              [-142.497059038090356, 69.973770196591957],
              [-142.403737394273946, 69.916213197321966],
              [-142.270933516535138, 69.906763540725393],
              [-141.958664939149372, 69.823434750737491],
              [-141.714592947629455, 69.789072363113618],
              [-141.52794965999658, 69.735810662296615],
              [-141.431038722187225, 69.69543485683856],
              [-141.212091788617869, 69.684267080860792],
              [-141.003912737027349, 69.645609394783932],
              [-141.003912737027349, 61.903763463124619],
              [-140.996734149041487, 61.069616503555025],
              [-141.000323443034404, 60.305912438614371],
              [-140.533715223952242, 60.224301768007678],
              [-140.472697226072256, 60.310207737067358],
              [-139.988142537025368, 60.185644081930803],
              [-139.697409723597218, 60.340274826238257],
              [-139.08722974479744, 60.357456020050194],
              [-139.083640450804495, 60.323952692116919],
              [-139.202087152571522, 60.09028845627455],
              [-139.033390334903345, 59.994073770927699],
              [-138.79649693136929, 59.92878523444233],
              [-138.703175287552881, 59.909885921249199],
              [-138.663693053630539, 59.810234997139958],
              [-138.559603527835264, 59.741510221892213],
              [-137.604851325713241, 59.243255601346007],
              [-137.49717250592505, 58.986396753857534],
              [-137.525886857868556, 58.906504202632021],
              [-137.4469223900239, 58.909940441394411],
              [-137.263868396383941, 59.002718887978872],
              [-136.865456763167629, 59.138450319093181],
              [-136.825974529245286, 59.15820869197691],
              [-136.581902537725369, 59.16508116950169],
              [-136.467045129951288, 59.28449046649466],
              [-136.474223717937178, 59.464033941829413],
              [-136.366544898148959, 59.447711807708075],
              [-136.301937606276056, 59.464033941829413],
              [-136.233741020410207, 59.525027179861794],
              [-136.237330314403152, 59.558530507795069],
              [-136.352187722177206, 59.598906313253124],
              [-136.190669492494919, 59.640141178401777],
              [-135.946597500975003, 59.664194849738493],
              [-135.476399987899867, 59.799926280852802],
              [-135.253863760337595, 59.701134416434158],
              [-135.153363528535266, 59.625537163661633],
              [-135.113881294612952, 59.623819044280438],
              [-135.027738238782376, 59.563684865938654],
              [-135.027738238782376, 59.474342658116569],
              [-135.070809766697664, 59.453725225542243],
              [-135.067220472704719, 59.421940016990163],
              [-135.009791768817678, 59.381564211532108],
              [-135.027738238782376, 59.345483704527041],
              [-134.963130946909473, 59.280195168041672],
              [-134.701112485424858, 59.247550899798995],
              [-134.683166015460131, 59.190852960219587],
              [-134.568308607686077, 59.128141602806025],
              [-134.482165551855502, 59.128141602806025],
              [-134.378076026060256, 59.03536315622155],
              [-134.3996117900179, 58.976088037570364],
              [-134.306290146201462, 58.958906843758427],
              [-134.327825910159106, 58.91939009799097],
              [-134.248861442314421, 58.858396859958589],
              [-133.839681927119273, 58.727819786987865],
              [-133.724824519345191, 58.625591683806832],
              [-133.55971699566993, 58.5225045209352],
              [-133.380252296022945, 58.428007954969544],
              [-133.462806057860575, 58.385914030130294],
              [-133.344359356093548, 58.270800031590312],
              [-133.175662538425371, 58.150531674906745],
              [-133.075162306623042, 57.999337169361681],
              [-132.870572549025496, 57.842988305673046],
              [-132.755715141251414, 57.704679695486945],
              [-132.648036321463223, 57.609324069830691],
              [-132.558303971639702, 57.503659727887268],
              [-132.368071390013881, 57.349028983579828],
              [-132.253213982239799, 57.215874731537312],
              [-132.371660684006827, 57.095606374853745],
              [-132.05221351863517, 57.050935270942702],
              [-132.12758869248691, 56.874828034370339],
              [-131.872748818988185, 56.805244199431982],
              [-131.901463170931692, 56.752841558305576],
              [-131.861980937009378, 56.703875155941546],
              [-131.836855879058788, 56.601647052760512],
              [-131.582016005560035, 56.613673888428877],
              [-131.463569303793037, 56.547526292252911],
              [-131.169247196371941, 56.448734427834268],
              [-131.08669343453434, 56.407499562685615],
              [-130.781603445134436, 56.36712375722756],
              [-130.623674509445095, 56.268331892808916],
              [-130.465745573755726, 56.239982923019227],
              [-130.426263339833383, 56.140331998909986],
              [-130.343709577995782, 56.127446103551023],
              [-130.246798640186398, 56.096519954689541],
              [-130.10322688046881, 56.116278327573269],
              [-130.002726648666481, 55.993432791817909],
              [-130.013494530645289, 55.916117419664189],
              [-130.085280410504083, 55.824198032770312],
              [-130.149887702377015, 55.766641033500321],
              [-130.146298408384069, 55.715097452064512],
              [-130.110405468454672, 55.682453183821835],
              [-130.128351938419371, 55.581084140331399],
              [-130.085280410504083, 55.491741932509314],
              [-130.045798176581741, 55.452225186741856],
              [-130.024262412624125, 55.337970247892471],
              [-129.981190884708838, 55.28384948738487],
              [-130.096048292482919, 55.197943518325175],
              [-130.182191348313467, 55.093138236072349],
              [-130.185780642306412, 55.064789266282659],
              [-130.257566522165206, 54.987473894128939],
              [-130.340120284002836, 54.921326297952973],
              [-130.472924161741616, 54.837997507965071],
              [-130.569835099550971, 54.790749224982235],
              [-130.638031685416848, 54.778722389313884],
              [-130.627263803438041, 54.739205643546427],
              [-130.695460389303889, 54.719447270662698],
              [-130.738531917219177, 54.753809658286571],
              [-130.742121211212094, 54.801917000960003],
              [-130.788782033120327, 54.822534433534329],
              [-130.792371327113273, 54.784735807148067],
              [-130.835442855028532, 54.765836493954936],
              [-130.900050146901464, 54.781299568385677],
              [-130.946710968809668, 54.825970672296719],
              [-130.96106814478145, 54.91874911888118],
              [-130.950300262802614, 54.967715521245211],
              [-131.0113182606826, 54.9960644910349],
              [-130.982603908739065, 55.084547639166388],
              [-131.014907554675545, 55.089701997309959],
              [-131.072336258562586, 55.137809339983392],
              [-131.093872022520202, 55.19278916018159],
              [-130.986193202732011, 55.246909920689191],
              [-130.925175204852053, 55.301030681196806],
              [-130.871335794957929, 55.294158203672026],
              [-130.882103676936765, 55.358587680466798],
              [-130.921585910859108, 55.429030575095737],
              [-130.871335794957929, 55.533835857348564],
              [-130.903639440894409, 55.697057198561978],
              [-130.939532380823806, 55.75461419783197],
              [-131.093872022520202, 55.895499987089863],
              [-131.172836490364887, 55.942748270072698],
              [-131.215908018280174, 55.983983135221337],
              [-131.241033076230764, 55.954775105741049],
              [-131.158479314393134, 55.901513404924046],
              [-131.068746964569641, 55.828493331223299],
              [-131.043621906619052, 55.766641033500321],
              [-130.964657438774395, 55.689325661346601],
              [-130.92876449884497, 55.576788841878411],
              [-130.986193202732011, 55.539849275182746],
              [-130.993371790717902, 55.471983559625585],
              [-130.968246732767312, 55.39295006809067],
              [-130.92876449884497, 55.339688367273666],
              [-130.96106814478145, 55.31563469593695],
              [-131.000550378703792, 55.398104426234255],
              [-131.029264730647299, 55.408413142521411],
              [-131.018496848668462, 55.348278964179627],
              [-131.032854024640244, 55.284708547075468],
              [-131.162068608386079, 55.197084458634578],
              [-131.212318724287229, 55.19278916018159],
              [-131.298461780117805, 55.23488308502084],
              [-131.230265194251928, 55.297594442434416],
              [-131.190782960329585, 55.360305799847993],
              [-131.201550842308421, 55.39295006809067],
              [-131.291283192131914, 55.384359471184709],
              [-131.255390252202517, 55.32250717346173],
              [-131.284104604146023, 55.286426666456663],
              [-131.40255130591305, 55.23831932378323],
              [-131.42767636386364, 55.239178383473828],
              [-131.510230125701241, 55.331956830058289],
              [-131.549712359623584, 55.293299143981429],
              [-131.639444709447076, 55.339688367273666],
              [-131.700462707327063, 55.355151441704407],
              [-131.74353423524235, 55.398104426234255],
              [-131.844034467044651, 55.456520485194844],
              [-131.844034467044651, 55.521809021680212],
              [-131.732766353263514, 55.548439872088707],
              [-131.653801885418829, 55.592251916309152],
              [-131.725587765277652, 55.632627721767207],
              [-131.700462707327063, 55.697057198561978],
              [-131.732766353263514, 55.730560526495253],
              [-131.650212591425912, 55.768359152881516],
              [-131.639444709447076, 55.788976585455842],
              [-131.704052001320008, 55.789835645146439],
              [-131.714819883298816, 55.853406062250613],
              [-131.689694825348255, 55.890345628946278],
              [-131.836855879058788, 55.875741614206134],
              [-131.815320115101144, 55.837083928129275],
              [-131.775837881178802, 55.823338973079728],
              [-131.779427175171747, 55.791553764527634],
              [-131.826087997079952, 55.718533690826902],
              [-131.829677291072898, 55.666990109391079],
              [-131.897873876938775, 55.604278751977517],
              [-131.962481168811678, 55.615446527955271],
              [-131.937356110861117, 55.535553976729759],
              [-131.973249050790514, 55.498614410034094],
              [-132.059392106621061, 55.543285513945136],
              [-132.149124456444554, 55.562184827138267],
              [-132.181428102381005, 55.587956617856165],
              [-132.224499630296293, 55.701352497014966],
              [-132.281928334183334, 55.76148667535675],
              [-132.206553160331595, 55.736573944329436],
              [-132.181428102381005, 55.801003421124207],
              [-132.08451716457165, 55.832788629676287],
              [-132.041445636656363, 55.959070404194037],
              [-132.070159988599869, 56.046694492634913],
              [-132.034267048670472, 56.095660894998943],
              [-132.012731284712828, 56.077620641496409],
              [-131.969659756797569, 56.113701148501477],
              [-131.944534698846979, 56.192734640036392],
              [-132.019909872698719, 56.183284983439819],
              [-132.034267048670472, 56.133459521385205],
              [-132.102463634536321, 56.107687730667294],
              [-132.17783880838806, 56.055285089540888],
              [-132.131177986479855, 55.958211344503439],
              [-132.15989233842339, 55.922130837498372],
              [-132.278339040190389, 55.92470801657015],
              [-132.321410568105676, 55.887768449874486],
              [-132.310642686126869, 55.861996659156588],
              [-132.371660684006827, 55.849969823488223],
              [-132.396785741957416, 55.879177852968525],
              [-132.382428565985663, 55.936734852238516],
              [-132.450625151851511, 55.956493225122244],
              [-132.493696679766799, 56.066452865518642],
              [-132.608554087540881, 56.051848850778498],
              [-132.608554087540881, 56.010613985629846],
              [-132.709054319343181, 56.111983029120282],
              [-132.719822201322017, 56.217647371063705],
              [-132.691107849378483, 56.239123863328629],
              [-132.60137549955499, 56.239982923019227],
              [-132.576250441604401, 56.29668086259862],
              [-132.529589619696196, 56.33877478743787],
              [-132.421910799908005, 56.349083503725026],
              [-132.364482096020936, 56.287231206002048],
              [-132.339357038070375, 56.341351966509663],
              [-132.393196447964471, 56.485673994529932],
              [-132.360892802028019, 56.488251173601725],
              [-132.253213982239799, 56.449593487524865],
              [-132.238856806268046, 56.398908965779654],
              [-132.199374572345732, 56.371419055680548],
              [-132.17783880838806, 56.391177428564276],
              [-132.21014245432454, 56.457325024740243],
              [-132.289106922169196, 56.487392113911127],
              [-132.357303508035073, 56.52862697905978],
              [-132.368071390013881, 56.57415714266142],
              [-132.407553623936224, 56.585324918639174],
              [-132.450625151851511, 56.56384842637425],
              [-132.486518091780908, 56.6059423512135],
              [-132.569071853618539, 56.633432261312606],
              [-132.587018323583237, 56.682398663676622],
              [-132.543946795667949, 56.702157036560351],
              [-132.558303971639702, 56.757136856758564],
              [-132.637268439484387, 56.781190528095266],
              [-132.770072317223168, 56.837888467674674],
              [-132.816733139131372, 56.89716358632586],
              [-132.870572549025496, 56.92551255611555],
              [-132.917233370933701, 56.993378271672711],
              [-132.813143845138427, 57.030317838368376],
              [-132.852626079060769, 57.080143300422989],
              [-132.917233370933701, 57.039767494964934],
              [-132.98542995679955, 57.05523056939569],
              [-132.99260854478544, 57.032035957749571],
              [-133.075162306623042, 57.081861419804184],
              [-133.186430420404179, 57.088733897328964],
              [-133.225912654326521, 57.136841240002397],
              [-133.322823592135904, 57.112787568665681],
              [-133.516645467754671, 57.17807610515105],
              [-133.545359819698206, 57.242505581945807],
              [-133.491520409804082, 57.305216939359383],
              [-133.426913117931178, 57.286317626166252],
              [-133.308466416164151, 57.289753864928642],
              [-133.2761627702277, 57.330988730077294],
              [-133.355127238072384, 57.333565909149087],
              [-133.469984645846466, 57.364492058010569],
              [-133.462806057860575, 57.394559147181468],
              [-133.527413349733479, 57.501941608506073],
              [-133.480752527825274, 57.571525443444429],
              [-133.566895583655821, 57.562934846538454],
              [-133.620734993549917, 57.579256980659792],
              [-133.678163697436958, 57.624787144261433],
              [-133.653038639486397, 57.71327029239292],
              [-133.581252759627603, 57.714988411774115],
              [-133.523824055740533, 57.684062262912619],
              [-133.405377353973535, 57.663444830338292],
              [-133.179251832418316, 57.58698851787517],
              [-133.161305362453618, 57.599874413234119],
              [-133.25103771227711, 57.649699875288746],
              [-133.322823592135904, 57.666022009410085],
              [-133.55971699566993, 57.777699769187677],
              [-133.570484877648767, 57.859310439794385],
              [-133.631502875528753, 57.846424544435436],
              [-133.638681463514615, 57.79058566454664],
              [-133.710467343373409, 57.793162843618433],
              [-133.85044980909808, 57.935766752257507],
              [-134.047860978709764, 58.062048526775257],
              [-134.087343212632106, 58.182316883458824],
              [-134.055039566695655, 58.238155763347621],
              [-134.062218154681545, 58.282826867258663],
              [-133.965307216872162, 58.318048314573133],
              [-134.011968038780367, 58.403954283632828],
              [-134.040682390723902, 58.402236164251633],
              [-134.072986036660353, 58.329216090550901],
              [-134.144771916519147, 58.303444299832989],
              [-134.144771916519147, 58.267363792827922],
              [-134.101700388603888, 58.248464479634791],
              [-134.148361210512093, 58.199498077270761],
              [-134.18425415044149, 58.208088674176736],
              [-134.263218618286174, 58.1909074803648],
              [-134.352950968109667, 58.200357136961358],
              [-134.482165551855502, 58.22956516644166],
              [-134.507290609806091, 58.216679271082697],
              [-134.632915899558981, 58.247605419944193],
              [-134.776487659276597, 58.397081806108048],
              [-134.79084483524835, 58.491578372073718],
              [-134.941595182951829, 58.611846728757271],
              [-134.988256004860034, 58.679712444314433],
              [-134.920059418994185, 58.68057150400503],
              [-134.959541652916528, 58.830047890168899],
              [-135.027738238782376, 58.781940547495466],
              [-135.024148944789431, 58.732115085440839],
              [-135.149774234542349, 58.84551096459964],
              [-135.178488586485855, 58.997564529835287],
              [-135.207202938429361, 59.076598021370202],
              [-135.282578112281101, 59.192571079600782],
              [-135.397435520055183, 59.292222003710023],
              [-135.526650103801046, 59.324007212262117],
              [-135.544596573765716, 59.310262257212557],
              [-135.444096341963416, 59.27761798896988],
              [-135.429739165991634, 59.24239654165541],
              [-135.368721168111676, 59.208893213722135],
              [-135.296935288252882, 59.096356394253931],
              [-135.350774698146978, 59.105806050850504],
              [-135.472810693906922, 59.226074407534071],
              [-135.551775161751607, 59.257000556395553],
              [-135.630739629596292, 59.265591153301528],
              [-135.483578575885758, 59.186557661766614],
              [-135.436917753977525, 59.113537588065867],
              [-135.375899756097567, 59.088624857038567],
              [-135.401024814048128, 59.005296067050665],
              [-135.368721168111676, 58.92969881427814],
              [-135.322060346203443, 58.909940441394411],
              [-135.34000681616817, 58.889323008820085],
              [-135.243095878358758, 58.782799607186064],
              [-135.232327996379951, 58.735551324203229],
              [-135.142595646556458, 58.616142027210259],
              [-135.135417058570567, 58.588652117111167],
              [-135.185667174471746, 58.598101773707725],
              [-135.088756236662363, 58.422853596825959],
              [-135.056452590725883, 58.349833523125227],
              [-135.052863296732966, 58.290558404474041],
              [-135.095934824648225, 58.297430881998807],
              [-135.09952411864117, 58.245028240872401],
              [-135.056452590725883, 58.190048420674202],
              [-135.160542116521157, 58.209806793557931],
              [-135.228738702387005, 58.237296703657023],
              [-135.278988818288184, 58.233860464894633],
              [-135.343596110161087, 58.270800031590312],
              [-135.397435520055183, 58.327497971169706],
              [-135.43332845998458, 58.39965898517984],
              [-135.512292927829264, 58.385914030130294],
              [-135.630739629596292, 58.428867014660142],
              [-135.727650567405675, 58.397081806108048],
              [-135.90711526705266, 58.380759671986709],
              [-135.896347385073852, 58.450343506925066],
              [-135.986079734897345, 58.464088461974612],
              [-136.000436910869098, 58.48041059609595],
              [-135.892758091080907, 58.513913924029225],
              [-135.928651031010304, 58.573189042680411],
              [-135.910704561045605, 58.618719206282051],
              [-136.011204792847934, 58.71235671255711],
              [-136.011204792847934, 58.743282861418606],
              [-136.090169260692591, 58.815443875428741],
              [-136.043508438784386, 58.836061308003067],
              [-136.050687026770248, 58.913376680156787],
              [-136.118883612636125, 58.968356500355],
              [-136.161955140551413, 58.976947097260961],
              [-136.108115730657289, 58.864410277792771],
              [-136.151187258572577, 58.757027816468153],
              [-136.24809819638196, 58.752732518015165],
              [-136.398848544085439, 58.812866696356949],
              [-136.492170187901877, 58.83863848707486],
              [-136.546009597795972, 58.967497440664403],
              [-136.585491831718315, 58.909081381703814],
              [-136.63215265362652, 58.890182068510683],
              [-136.790081589315889, 58.936571291802906],
              [-136.840331705217039, 58.91939009799097],
              [-136.876224645146436, 58.962343082520817],
              [-136.919296173061724, 58.946880008090076],
              [-136.930064055040532, 58.900490784797839],
              [-136.768545825358245, 58.870423695626954],
              [-136.743420767407656, 58.876437113461122],
              [-136.581902537725369, 58.83863848707486],
              [-136.463455835958342, 58.781940547495466],
              [-136.355777016170151, 58.692598339673381],
              [-136.395259250092494, 58.654799713287119],
              [-136.481402305923041, 58.617001086900856],
              [-136.459866541965425, 58.607551430304298],
              [-136.341419840198398, 58.64535005669056],
              [-136.316294782247809, 58.671121847408472],
              [-136.219383844438426, 58.671980907099055],
              [-136.194258786487865, 58.581779639586387],
              [-136.100937142671427, 58.514772983719823],
              [-136.043508438784386, 58.379900612296112],
              [-136.111705024650234, 58.342101985909849],
              [-136.266044666346659, 58.314612075810743],
              [-136.305526900269001, 58.323202672716718],
              [-136.273223254332549, 58.363578478174773],
              [-136.337830546205453, 58.377323433224319],
              [-136.380902074120741, 58.362719418484176],
              [-136.355777016170151, 58.329216090550901],
              [-136.391669956099548, 58.297430881998807],
              [-136.546009597795972, 58.316330195191938],
              [-136.585491831718315, 58.296571822308209],
              [-136.567545361753616, 58.245028240872401],
              [-136.596259713697123, 58.214961151701502],
              [-136.700349239492368, 58.219256450154489],
              [-136.729063591435903, 58.286263106021053],
              [-136.858278175181738, 58.316330195191938],
              [-136.84751029320293, 58.329216090550901],
              [-136.94801052500523, 58.39278650765506],
              [-137.009028522885217, 58.409108641776413],
              [-137.113118048680462, 58.39278650765506],
              [-137.23874333843338, 58.452920685996844],
              [-137.296172042320421, 58.465806581355807],
              [-137.49717250592505, 58.55772596824967],
              [-137.680226499564981, 58.622155445044442],
              [-137.687405087550871, 58.665108429574289],
              [-137.795083907339063, 58.725242607916073],
              [-137.927887785077843, 58.780222428114271],
              [-137.945834255042541, 58.804276099450988],
              [-137.931477079070788, 58.868705576245759],
              [-137.985316488964884, 58.909940441394411],
              [-138.182727658576567, 59.013886663956626],
              [-138.290406478364787, 59.054262469414681],
              [-138.634978701687004, 59.130718781877803],
              [-138.764193285432839, 59.191712019910184],
              [-138.918532927129263, 59.248409959489592],
              [-139.270283738437371, 59.337752167311663],
              [-139.42103408614085, 59.379846092150913],
              [-139.747659839498397, 59.503550687596871],
              [-139.855338659286588, 59.537054015530146],
              [-139.837392189321889, 59.561966746557459],
              [-139.726124075540753, 59.638423059020582],
              [-139.639981019710177, 59.626396223352231],
              [-139.672284665646657, 59.603201611706112],
              [-139.586141609816082, 59.605778790777904],
              [-139.586141609816082, 59.642718357473569],
              [-139.510766435964342, 59.702852535815353],
              [-139.589730903809027, 59.708865953649521],
              [-139.582552315823136, 59.775013549825488],
              [-139.632802431724315, 59.874664473934729],
              [-139.607677373773726, 59.904731563105628],
              [-139.528712905929041, 59.939953010420098],
              [-139.546659375893739, 59.969161039900385],
              [-139.489230672006727, 59.994932830618296],
              [-139.535891493914932, 60.043040173291729],
              [-139.60408807978078, 59.999228129071284],
              [-139.611266667766671, 59.948543607326059],
              [-139.700999017590163, 59.930503353823525],
              [-139.776374191441903, 59.868651056100546],
              [-139.779963485434848, 59.826557131261296],
              [-140.164017942679408, 59.740651162201615],
              [-140.207089470594696, 59.716597490864899],
              [-140.307589702397024, 59.692543819528183],
              [-140.648572631726324, 59.711443132721314],
              [-140.889055329253267, 59.74236928158281],
              [-140.982376973069734, 59.769859191681903],
              [-141.423860134201334, 59.877241653006521],
              [-141.48487813208132, 59.925348995679954],
              [-141.420270840208389, 59.916758398773979],
              [-141.298234844448444, 59.937375831348305],
              [-141.258752610526102, 59.998369069380686],
              [-141.377199312293101, 60.110905888848876],
              [-141.366431430314293, 60.146986395853943],
              [-141.438217310173087, 60.1323823811138],
              [-141.549485423954224, 60.170181007500062],
              [-141.52794965999658, 60.127228022970215],
              [-141.366431430314293, 60.024140860098598],
              [-141.40232437024369, 60.00610060659605],
              [-141.596146245862457, 59.962288562375619],
              [-141.736128711587099, 59.962288562375619],
              [-141.912004117241167, 60.00953684535844],
              [-142.130951050810495, 60.030154277932766],
              [-142.245808458584577, 60.049912650816495],
              [-142.536541272012698, 60.084275038440381],
              [-142.698059501695013, 60.09372469503694],
              [-142.90982784727845, 60.09028845627455],
              [-143.135953368833668, 60.06193948648486],
              [-143.412329006290065, 60.05163077019769],
              [-143.699472525725241, 60.027577098860974],
              [-143.893294401344008, 59.986342233712321],
              [-144.033276867068651, 60.01984556164561],
              [-144.194795096750966, 59.997510009690089],
              [-144.424509912299129, 59.894422846818458],
              [-144.589617435974333, 59.795630982399814],
              [-144.568081672016717, 59.85146986228861],
              [-144.438867088270882, 59.939953010420098],
              [-144.216330860708581, 60.040462994219936],
              [-144.051223337033377, 60.042181113601131],
              [-144.10865204092039, 60.098879053180525],
              [-144.284527446574458, 60.140113918329178],
              [-144.313241798517964, 60.161590410594101],
              [-144.34913473844739, 60.091147515965147],
              [-144.442456382263799, 60.163308529975296],
              [-144.535778026080266, 60.189080320693193],
              [-144.596796023960223, 60.18134878347783],
              [-144.654224727847264, 60.204543395123949],
              [-144.909064601346017, 60.220865529245287],
              [-144.876760955409537, 60.24491920058199],
              [-144.740367783677812, 60.264677573465718],
              [-144.89111813138129, 60.293026543255422],
              [-144.959314717247167, 60.287872185111837],
              [-144.96649330523303, 60.309348677376761],
              [-144.833689427494278, 60.443361989109889],
              [-144.887528837388373, 60.456247884468837],
              [-144.934189659296578, 60.425321735607341],
              [-145.038279185091824, 60.406422422414209],
              [-145.041868479084769, 60.357456020050194],
              [-145.113654358943563, 60.3007580804708],
              [-145.253636824668234, 60.311066796757956],
              [-145.504887404174042, 60.394395586745858],
              [-145.501298110181096, 60.421026437154367],
              [-145.594619753997534, 60.45195258601585],
              [-145.734602219722177, 60.474288137971371],
              [-145.881763273432739, 60.444221048800472],
              [-145.960727741277395, 60.467415660446591],
              [-145.914066919369191, 60.492328391473905],
              [-145.802798805588054, 60.519818301573011],
              [-145.713066455764533, 60.58338871867717],
              [-145.763316571665712, 60.591979315583146],
              [-145.827923863538615, 60.549885390743896],
              [-146.111478088980874, 60.469992839518383],
              [-146.215567614776148, 60.450234466634654],
              [-146.351960786507846, 60.454529765087642],
              [-146.3483714925149, 60.408140541795404],
              [-146.133013852938518, 60.431335153441523],
              [-146.093531619016176, 60.410717720867197],
              [-146.086353031030285, 60.365187557265557],
              [-146.147371028910271, 60.366905676646752],
              [-146.2478712607126, 60.335120468094672],
              [-146.312478552585503, 60.340274826238257],
              [-146.459639606296037, 60.307630557995566],
              [-146.606800660006598, 60.241482961819614],
              [-146.649872187921858, 60.243201081200809],
              [-146.692943715837146, 60.284435946349447],
              [-146.524246898168968, 60.350583542525413],
              [-146.657050775907749, 60.340274826238257],
              [-146.718068773787735, 60.349724482834816],
              [-146.721658067780652, 60.396113706127053],
              [-146.63910430594305, 60.467415660446591],
              [-146.592443484034845, 60.490610272092709],
              [-146.527836192161914, 60.492328391473905],
              [-146.45605031230312, 60.465697541065396],
              [-146.369907256472544, 60.480301555805553],
              [-146.290942788627888, 60.515523003120023],
              [-146.154549616896162, 60.526690779097777],
              [-145.949959859298588, 60.576516241152405],
              [-145.799209511595109, 60.593697434964341],
              [-145.83151315753156, 60.615173927229264],
              [-145.899709743397409, 60.613455807848069],
              [-145.896120449404492, 60.674449045880451],
              [-145.935602683326806, 60.632355121041201],
              [-146.003799269192683, 60.615173927229264],
              [-146.000209975199738, 60.640945717947176],
              [-145.910477625376245, 60.696784597835972],
              [-145.9068883313833, 60.714824851338506],
              [-146.025335033150327, 60.664999389283878],
              [-146.186853262832614, 60.624623583825823],
              [-146.255049848698491, 60.622046404754045],
              [-146.269407024670244, 60.647818195471942],
              [-146.043281503115026, 60.744032880818793],
              [-146.064817267072669, 60.776677149061484],
              [-146.168906792867915, 60.725133567625662],
              [-146.305299964599641, 60.713106731957311],
              [-146.3483714925149, 60.735442283912832],
              [-146.402210902409024, 60.693348359073582],
              [-146.473996782267818, 60.681321523405231],
              [-146.667818657886585, 60.692489299382984],
              [-146.703711597815982, 60.742314761437598],
              [-146.606800660006598, 60.758636895558951],
              [-146.567318426084256, 60.750905358343573],
              [-146.502711134211324, 60.772381850608497],
              [-146.359139374493736, 60.786126805658043],
              [-146.255049848698491, 60.810180476994759],
              [-146.17249608686086, 60.866019356883555],
              [-146.262228436684353, 60.86773747626475],
              [-146.312478552585503, 60.828220730497293],
              [-146.395032314423133, 60.811898596375954],
              [-146.55655054410542, 60.810180476994759],
              [-146.621157835978352, 60.868596535955348],
              [-146.664229363893639, 60.87117371502714],
              [-146.725247361773597, 60.811898596375954],
              [-146.800622535625337, 60.805026118851174],
              [-146.814979711597118, 60.855710640596399],
              [-146.757551007710049, 60.878046192551921],
              [-146.696533009830091, 60.872891834408335],
              [-146.736015243752433, 60.910690460794598],
              [-146.746783125731241, 60.957938743777433],
              [-146.700122303823036, 60.98714677325772],
              [-146.606800660006598, 61.085079577985766],
              [-146.262228436684353, 61.090233936129351],
              [-146.287353494634942, 61.122878204372029],
              [-146.613979247992461, 61.118582905919055],
              [-146.6893544218442, 61.06446214541144],
              [-146.786265359653584, 61.042126593455919],
              [-146.861640533505323, 60.975978997279967],
              [-146.972908647286459, 60.934744132131314],
              [-147.05546240912409, 60.94505284841847],
              [-146.983676529265267, 60.975978997279967],
              [-147.019569469194664, 61.073052742317415],
              [-147.062640997109952, 61.118582905919055],
              [-146.998033705237049, 61.144354696636952],
              [-147.076998173081734, 61.152945293542928],
              [-147.127248288982884, 61.133186920659199],
              [-147.130837582975829, 61.089374876438754],
              [-147.087766055060541, 61.035254115931153],
              [-147.138016170961691, 60.981133355423552],
              [-147.134426876968746, 60.945911908109068],
              [-147.181087698876979, 60.933026012750119],
              [-147.274409342693417, 60.974260877898772],
              [-147.281587930679308, 60.91670387862878],
              [-147.378498868488663, 60.878046192551921],
              [-147.453874042340402, 60.896945505745052],
              [-147.453874042340402, 60.94161660965608],
              [-147.493356276262745, 60.957938743777433],
              [-147.518481334213334, 60.895227386363857],
              [-147.550784980149785, 60.908113281722805],
              [-147.536427804178032, 61.018931981809814],
              [-147.504124158241581, 61.072193682626818],
              [-147.557963568135676, 61.081643339223376],
              [-147.618981566015634, 60.969965579445784],
              [-147.586677920079183, 60.87460995378953],
              [-147.601035096050964, 60.849697222762217],
              [-147.669231681916813, 60.841965685546839],
              [-147.665642387923867, 60.884059610386089],
              [-147.762553325733251, 60.91326763986639],
              [-147.78767838368384, 60.873750894098933],
              [-147.730249679796799, 60.817912014210137],
              [-147.776910501705004, 60.811039536685357],
              [-147.913303673436729, 60.824784491734903],
              [-148.035339669196674, 60.78354962658625],
              [-148.135839900999002, 60.791281163801628],
              [-148.153786370963701, 60.817912014210137],
              [-148.103536255062551, 60.899522684816844],
              [-147.952785907359072, 61.02924069809697],
              [-147.949196613366126, 61.07477086169861],
              [-147.773321207712058, 61.18043520364202],
              [-147.715892503825017, 61.250019038580376],
              [-147.737428267782661, 61.265482113011117],
              [-147.999446729267277, 61.084220518295169],
              [-148.003036023260222, 61.054153429124284],
              [-148.067643315133125, 61.004327967069656],
              [-148.09635766707666, 61.011200444594436],
              [-148.125072019020195, 61.070475563245623],
              [-148.168143546935454, 61.069616503555025],
              [-148.17891142891429, 61.000032668616683],
              [-148.218393662836604, 60.953643445324445],
              [-148.283000954709536, 60.917562938319378],
              [-148.293768836688344, 60.862583118121165],
              [-148.351197540575384, 60.804167059160577],
              [-148.426572714427124, 60.827361670806695],
              [-148.451697772377713, 60.789563044420433],
              [-148.365554716547166, 60.765509373083717],
              [-148.383501186511864, 60.687334941239399],
              [-148.347608246582467, 60.680462463714633],
              [-148.27941166071659, 60.753482537415366],
              [-148.14660778297781, 60.758636895558951],
              [-148.107125549055468, 60.739737582365819],
              [-148.092768373083715, 60.661563150521502],
              [-148.143018488984865, 60.622905464444628],
              [-148.254286602766001, 60.595415554345536],
              [-148.333251070610686, 60.530127017860167],
              [-148.329661776617741, 60.476006257352566],
              [-148.254286602766001, 60.493187451164502],
              [-148.193268604886043, 60.557616927959273],
              [-148.085589785097852, 60.595415554345536],
              [-147.977910965309633, 60.518959241882413],
              [-147.977910965309633, 60.481160615496151],
              [-147.942018025380236, 60.444221048800472],
              [-148.024571787217866, 60.279281588205876],
              [-148.117893431034304, 60.279281588205876],
              [-148.150197076970755, 60.323952692116919],
              [-148.218393662836604, 60.331684229332282],
              [-148.214804368843687, 60.3007580804708],
              [-148.31171530665307, 60.262959454084537],
              [-148.31171530665307, 60.24835543934438],
              [-148.211215074850742, 60.260382275012745],
              [-148.128661313013112, 60.237187663366626],
              [-147.909714379443784, 60.130664261732605],
              [-147.816392735627346, 60.057644188031873],
              [-147.870232145521442, 59.991496591855906],
              [-147.916892967429675, 59.969161039900385],
              [-148.038928963189619, 59.9390939507295],
              [-148.099946961069605, 59.952838905779046],
              [-148.150197076970755, 59.937375831348305],
              [-148.254286602766001, 59.93222147320472],
              [-148.221982956829549, 59.976033517425165],
              [-148.315304600645987, 60.033590516695156],
              [-148.283000954709536, 60.085134098130979],
              [-148.344018952589522, 60.13925485863858],
              [-148.401447656476563, 60.031013337623364],
              [-148.401447656476563, 59.97775163680636],
              [-148.444519184391822, 59.94253018949189],
              [-148.512715770257699, 60.001805308143076],
              [-148.545019416194151, 59.994932830618296],
              [-148.57014447414474, 59.944248308873085],
              [-148.634751766017644, 59.915899339083381],
              [-148.756787761777616, 59.958852323613229],
              [-148.860877287572862, 59.924489935989357],
              [-148.914716697466957, 59.969161039900385],
              [-149.011627635276341, 59.951120786397851],
              [-149.090592103121026, 59.955416084850839],
              [-149.122895749057477, 59.979469756187555],
              [-149.03675269322693, 60.043040173291729],
              [-149.069056339163382, 60.05506700896008],
              [-149.165967276972765, 60.022422740717403],
              [-149.270056802768011, 59.872087294862936],
              [-149.377735622556202, 59.836006787857869],
              [-149.410039268492682, 59.85146986228861],
              [-149.359789152591503, 59.898718145271445],
              [-149.313128330683298, 59.97087915928158],
              [-149.366967740577394, 60.118637426064254],
              [-149.424396444464435, 60.122932724517241],
              [-149.442342914429133, 60.098879053180525],
              [-149.438753620436188, 60.029295218242169],
              [-149.392092798527983, 59.983765054640543],
              [-149.42798573845738, 59.980328815878153],
              [-149.463878678386777, 59.918476518155174],
              [-149.524896676266764, 59.927926174751732],
              [-149.55361102821027, 59.910744980939796],
              [-149.571557498174968, 59.852328921979208],
              [-149.611039732097311, 59.837724907239064],
              [-149.596682556125558, 59.797349101781009],
              [-149.506950206302065, 59.7707182513725],
              [-149.52848597025968, 59.707147834268326],
              [-149.625396908069064, 59.734637744367433],
              [-149.668468435984352, 59.843738325073247],
              [-149.639754084040817, 59.875523533625326],
              [-149.679236317963159, 59.94253018949189],
              [-149.740254315843146, 59.947684547635461],
              [-149.718718551885502, 59.903013443724433],
              [-149.747432903829036, 59.860919518885183],
              [-149.729486433864338, 59.692543819528183],
              [-149.747432903829036, 59.637563999329984],
              [-149.84434384163842, 59.701134416434158],
              [-149.847933135631337, 59.738073983129823],
              [-149.905361839518378, 59.762127654466539],
              [-150.02739783527835, 59.788758504875034],
              [-150.016629953299514, 59.754396117251162],
              [-149.930486897468967, 59.723469968389679],
              [-149.934076191461912, 59.669349207882064],
              [-150.181737476974774, 59.533617776767755],
              [-150.296594884748828, 59.455443344923438],
              [-150.296594884748828, 59.424517196061956],
              [-150.354023588635869, 59.415926599155981],
              [-150.38632723457232, 59.342047465764651],
              [-150.429398762487608, 59.343765585145846],
              [-150.440166644466444, 59.40389976348763],
              [-150.318130648706472, 59.585161358203578],
              [-150.318130648706472, 59.610074089230878],
              [-150.41145229252291, 59.554235209342082],
              [-150.479648878388787, 59.458879583685828],
              [-150.522720406304046, 59.468329240282387],
              [-150.555024052240526, 59.590315716347149],
              [-150.601684874148731, 59.545644612436121],
              [-150.641167108071073, 59.547362731817316],
              [-150.583738404184032, 59.494101031000298],
              [-150.601684874148731, 59.425376255752553],
              [-150.741667339873402, 59.425376255752553],
              [-150.770381691816908, 59.372973614626133],
              [-150.834988983689811, 59.352356182051807],
              [-150.913953451534496, 59.305107899068986],
              [-150.888828393583935, 59.268168332373321],
              [-150.942667803478031, 59.232946885058837],
              [-151.000096507365072, 59.224356288152876],
              [-151.000096507365072, 59.270745511445099],
              [-151.057525211252113, 59.301671660306596],
              [-151.104186033160317, 59.263013974229736],
              [-151.107775327153263, 59.217483810628096],
              [-151.186739794997948, 59.202879795887952],
              [-151.272882850828495, 59.229510646296447],
              [-151.305186496764946, 59.209752273412718],
              [-151.380561670616686, 59.24239654165541],
              [-151.405686728567275, 59.279336108351075],
              [-151.448758256482563, 59.248409959489592],
              [-151.524133430334302, 59.225215347843474],
              [-151.502597666376658, 59.195148258672575],
              [-151.574383546235453, 59.195148258672575],
              [-151.592330016200151, 59.1616449307393],
              [-151.739491069910684, 59.155631512905117],
              [-151.761026833868328, 59.220920049390486],
              [-151.839991301713013, 59.208893213722135],
              [-151.915366475564753, 59.227792526915266],
              [-151.904598593585916, 59.246691840108397],
              [-151.979973767437656, 59.253564317633163],
              [-151.990741649416492, 59.313698495974947],
              [-151.962027297472957, 59.344624644836443],
              [-151.904598593585916, 59.360087719267185],
              [-151.886652123621218, 59.421080957299566],
              [-151.771794715847136, 59.447711807708075],
              [-151.739491069910684, 59.438262151111502],
              [-151.635401544115439, 59.482074195331947],
              [-151.516954842348412, 59.48465137440374],
              [-151.516954842348412, 59.523309060480599],
              [-151.398508140581384, 59.541349313983133],
              [-151.27647214482144, 59.594611014800137],
              [-151.165204031040304, 59.586879477584773],
              [-151.204686264962646, 59.646154596235959],
              [-151.125721797117961, 59.668490148191466],
              [-151.114953915139154, 59.695980058290573],
              [-150.982150037400373, 59.783604146731463],
              [-151.028810859308578, 59.796490042090412],
              [-151.114953915139154, 59.77759072889728],
              [-151.330311554715536, 59.683953222622222],
              [-151.437990374503727, 59.666772028810286],
              [-151.502597666376658, 59.633268700876997],
              [-151.642580132101301, 59.647013655926557],
              [-151.796919773797725, 59.704570655196548],
              [-151.850759183691821, 59.73893304282042],
              [-151.868705653656519, 59.778449788587878],
              [-151.804098361783616, 59.878959772387716],
              [-151.757437539875383, 59.917617458464576],
              [-151.703598129981287, 60.031872397313961],
              [-151.606687192171904, 60.099738112871123],
              [-151.545669194291918, 60.128087082660812],
              [-151.420043904539028, 60.21313399202991],
              [-151.380561670616686, 60.29732184170841],
              [-151.380561670616686, 60.358315079740791],
              [-151.298007908779084, 60.385804989839883],
              [-151.280061438814386, 60.519818301573011],
              [-151.265704262842632, 60.54301291321913],
              [-151.330311554715536, 60.580811539605392],
              [-151.409276022560221, 60.720838269172688],
              [-151.269293556835549, 60.750905358343573],
              [-151.251347086870851, 60.774099969989692],
              [-151.025221565315633, 60.797294581635811],
              [-150.705774399943977, 60.938180370893704],
              [-150.375559352593513, 61.03954941438414],
              [-150.192505358953582, 60.901240804198039],
              [-150.045344305243049, 60.887495849148479],
              [-149.998683483334815, 60.86430123750236],
              [-149.876647487574871, 60.960515922849225],
              [-149.76896866778668, 60.967388400373991],
              [-149.639754084040817, 60.928730714297131],
              [-149.571557498174968, 60.937321311203107],
              [-149.34543197661975, 60.896086446054454],
              [-149.112127867078669, 60.878905252242518],
              [-149.00444904729045, 60.831656969259683],
              [-149.008038341283395, 60.854851580905802],
              [-149.08700280912808, 60.908972341413403],
              [-149.191092334923326, 60.939898490274899],
              [-149.327485506655051, 60.927871654606534],
              [-149.510539500294982, 60.981992415114135],
              [-149.596682556125558, 60.981133355423552],
              [-149.740254315843146, 61.017213862428619],
              [-149.829986665666638, 61.075629921389208],
              [-149.969969131391309, 61.132327860968601],
              [-150.074058657186555, 61.156381532305318],
              [-150.009451365313652, 61.203629815288139],
              [-149.923308309483076, 61.211361352503516],
              [-149.81204019570194, 61.317884754137538],
              [-149.729486433864338, 61.333347828568279],
              [-149.700772081920803, 61.384891410004087],
              [-149.535664558245571, 61.408086021650206],
              [-149.42798573845738, 61.446743707727066],
              [-149.542843146231462, 61.489696692256913],
              [-149.614629026090256, 61.493132931019304],
              [-149.76896866778668, 61.438153110821105],
              [-149.808450901708994, 61.396059185981855],
              [-149.876647487574871, 61.38403235031349],
              [-149.919719015490159, 61.323898171971706],
              [-149.919719015490159, 61.263763993629922],
              [-149.984326307363062, 61.237133143221428],
              [-150.074058657186555, 61.253455277342766],
              [-150.20327324093239, 61.259468695176949],
              [-150.468880996409951, 61.244864680436791],
              [-150.562202640226388, 61.281804247132456],
              [-150.662702872028717, 61.295549202182016],
              [-150.712952987929867, 61.250878098270974],
              [-151.025221565315633, 61.178717084260839],
              [-151.165204031040304, 61.046421891908906],
              [-151.294418614786139, 61.03611317562175],
              [-151.362615200651987, 61.009482325213241],
              [-151.481061902419015, 61.011200444594436],
              [-151.621044368143657, 60.957079684086835],
              [-151.79333047980478, 60.863442177811763],
              [-151.796919773797725, 60.838529446784463],
              [-151.703598129981287, 60.732006045150442],
              [-151.710776717967178, 60.712247672266713],
              [-151.847169889698876, 60.733724164531637],
              [-151.915366475564753, 60.718261090100896],
              [-152.023045295352944, 60.673589986189853],
              [-152.098420469204683, 60.594556494654938],
              [-152.195331407014066, 60.569643763627624],
              [-152.310188814788148, 60.506073346523451],
              [-152.331724578745764, 60.443361989109889],
              [-152.303010226802257, 60.414153959629587],
              [-152.234813640936409, 60.393536527055261],
              [-152.378385400653997, 60.345429184381828],
              [-152.410689046590448, 60.287872185111837],
              [-152.457349868498682, 60.284435946349447],
              [-152.557850100300982, 60.224301768007678],
              [-152.626046686166859, 60.218288350173495],
              [-152.658350332103311, 60.242342021510211],
              [-152.748082681926803, 60.232892364913639],
              [-152.73372550595505, 60.17447630595305],
              [-152.676296802068009, 60.163308529975296],
              [-152.687064684046817, 60.137536739257385],
              [-152.597332334223324, 60.101456232252318],
              [-152.568617982279818, 60.071389143081419],
              [-152.611689510195106, 60.008677785667842],
              [-152.679886096060955, 59.968301980209787],
              [-152.705011154011515, 59.915040279392784],
              [-152.794743503835036, 59.89700002589025],
              [-152.809100679806789, 59.878100712697119],
              [-152.949083145531432, 59.876382593315924],
              [-153.002922555425528, 59.88669130960308],
              [-153.146494315143144, 59.860060459194585],
              [-153.211101607016047, 59.862637638266378],
              [-153.279298192881924, 59.811094056830555],
              [-153.196744431044294, 59.824839011880115],
              [-153.142905021150199, 59.807657818068165],
              [-153.089065611256103, 59.833429608786076],
              [-153.020869025390255, 59.834288668476674],
              [-152.995743967439665, 59.791335683946826],
              [-153.053172671326706, 59.691684759837585],
              [-153.121369257192555, 59.677939804788039],
              [-153.214690901008993, 59.634127760567594],
              [-153.315191132811321, 59.665912969119688],
              [-153.297244662846623, 59.636704939639387],
              [-153.343905484754828, 59.621241865208646],
              [-153.408512776627759, 59.636704939639387],
              [-153.38338771867717, 59.666772028810286],
              [-153.376209130691308, 59.731201505605043],
              [-153.455173598535964, 59.792194743637424],
              [-153.444405716557156, 59.68996664045639],
              [-153.476709362493608, 59.642718357473569],
              [-153.54131665436654, 59.629832462114607],
              [-153.587977476274745, 59.65130895437953],
              [-153.609513240232388, 59.621241865208646],
              [-153.552084536345347, 59.597188193871929],
              [-153.584388182281799, 59.551658030270289],
              [-153.760263587935867, 59.543067433364328],
              [-153.699245590055881, 59.463174882138816],
              [-153.727959941999416, 59.435684972039709],
              [-153.824870879808799, 59.417644718537176],
              [-153.860763819738196, 59.424517196061956],
              [-153.946906875568743, 59.385859509985096],
              [-153.989978403484031, 59.397027285962849],
              [-154.119192987229866, 59.36524207741077],
              [-154.101246517265167, 59.342906525455248],
              [-154.029460637406373, 59.327443451024507],
              [-154.112014399243975, 59.299953540925401],
              [-154.137139457194564, 59.263013974229736],
              [-154.129960869208674, 59.210611333103316],
              [-154.173032397123961, 59.172812706717053],
              [-154.259175452954509, 59.142745617546169],
              [-154.173032397123961, 59.120410065590647],
              [-154.194568161081605, 59.069725543845422],
              [-154.158675221152208, 59.018181962409614],
              [-154.061764283342825, 59.072302722917215],
              [-153.84999593775936, 59.052544350033486],
              [-153.792567233872319, 59.071443663226617],
              [-153.749495705957059, 59.052544350033486],
              [-153.695656296062964, 59.07402084229841],
              [-153.616691828218279, 59.00701418643186],
              [-153.548495242352402, 58.983819574785741],
              [-153.480298656486553, 58.994987350763495],
              [-153.397744894648923, 58.965779321283208],
              [-153.33313760277602, 58.920249157681567],
              [-153.304423250832485, 58.874718994079927],
              [-153.25058384093839, 58.855819680886796],
              [-153.351084072740719, 58.843792845218445],
              [-153.401334188641869, 58.742423801728009],
              [-153.444405716557156, 58.708920473794734],
              [-153.552084536345347, 58.68744398152981],
              [-153.59156677026769, 58.640195698546975],
              [-153.677709826098237, 58.611846728757271],
              [-153.84999593775936, 58.611846728757271],
              [-153.903835347653455, 58.597242714017128],
              [-153.918192523625237, 58.516491103101018],
              [-153.961264051540496, 58.488142133311328],
              [-154.05817498934988, 58.489001193001926],
              [-154.076121459314578, 58.471819999189989],
              [-154.040228519385181, 58.403954283632828],
              [-154.000746285462839, 58.376464373533722],
              [-154.097657223272222, 58.345538224672239],
              [-154.169443103131016, 58.358424120031188],
              [-154.176621691116907, 58.319766433954328],
              [-154.104835811258113, 58.280249688186871],
              [-154.144318045180455, 58.210665853248528],
              [-154.219693219032195, 58.184894062530617],
              [-154.24122898298981, 58.156545092740913],
              [-154.208925337053358, 58.135927660166587],
              [-154.291479098890989, 58.135927660166587],
              [-154.341729214792139, 58.091256556255559],
              [-154.435050858608577, 58.147954495834952],
              [-154.467354504545028, 58.141941078000769],
              [-154.44940803458033, 58.092974675636754],
              [-154.463765210552083, 58.058612288012867],
              [-154.539140384403822, 58.056035108941074],
              [-154.58221191231911, 58.01909554224541],
              [-154.643229910199096, 58.03284049729497],
              [-154.643229910199096, 58.056035108941074],
              [-154.71501579005789, 58.055176049250477],
              [-154.729372966029644, 58.021672721317202],
              [-154.808337433874328, 58.001055288742876],
              [-154.890891195711959, 58.027686139151385],
              [-154.991391427514259, 58.013082124411241],
              [-155.117016717267177, 57.953807005760055],
              [-155.063177307373053, 57.909135901849012],
              [-155.095480953309533, 57.865323857628567],
              [-155.239052713027121, 57.827525231242305],
              [-155.33955294482945, 57.82580711186111],
              [-155.335963650836504, 57.78027694825947],
              [-155.285713534935326, 57.758800455994546],
              [-155.30724929889297, 57.724438068370674],
              [-155.379035178751764, 57.710693113321128],
              [-155.508249762497599, 57.761377635066339],
              [-155.544142702427024, 57.78714942578425],
              [-155.615928582285818, 57.769968231972314],
              [-155.633875052250517, 57.715847471464699],
              [-155.630285758257571, 57.656572352813512],
              [-155.723607402074009, 57.633377741167408],
              [-155.734375284052817, 57.550048951179505],
              [-155.967679393593926, 57.544035533345323],
              [-156.043054567445665, 57.564652965919649],
              [-156.050233155431556, 57.501082548815475],
              [-156.021518803488021, 57.440089310783094],
              [-156.093304683346815, 57.440089310783094],
              [-156.136376211262103, 57.471874519335188],
              [-156.211751385113843, 57.47445169840698],
              [-156.226108561085596, 57.440948370473691],
              [-156.340965968859678, 57.417753758827573],
              [-156.48094843458432, 57.338720267292658],
              [-156.534787844478444, 57.328411551005502],
              [-156.552734314443143, 57.290612924619239],
              [-156.50607349253491, 57.281163268022667],
              [-156.337376674866732, 57.336143088220879],
              [-156.323019498894979, 57.293190103691032],
              [-156.344555262852623, 57.247659940089392],
              [-156.398394672746718, 57.241646522255209],
              [-156.398394672746718, 57.215015671846714],
              [-156.333787380873787, 57.182371403604023],
              [-156.369680320803184, 57.149727135361346],
              [-156.434287612676115, 57.127391583405824],
              [-156.441466200662006, 57.085297658566574],
              [-156.534787844478444, 57.047499032180312],
              [-156.56350219642195, 57.020009122081206],
              [-156.55632360843606, 56.983928615076138],
              [-156.671181016210141, 56.995955450744503],
              [-156.782449129991278, 56.971901779407787],
              [-156.796806305963059, 56.911767601066003],
              [-156.839877833878319, 56.901458884778833],
              [-156.908074419744196, 56.965888361573604],
              [-156.936788771687702, 56.920358197971964],
              [-157.033699709497085, 56.884277690966897],
              [-157.073181943419428, 56.838747527365271],
              [-157.159324999249975, 56.833593169221686],
              [-157.141378529285276, 56.790640184691838],
              [-157.202396527165263, 56.76744557304572],
              [-157.292128876988755, 56.804385139741385],
              [-157.378271932819302, 56.861942139011376],
              [-157.457236400663987, 56.84819718396183],
              [-157.457236400663987, 56.804385139741385],
              [-157.417754166741645, 56.800089841288397],
              [-157.414164872748728, 56.769163692426915],
              [-157.518254398543974, 56.76057309552094],
              [-157.564915220452178, 56.703016096250948],
              [-157.543379456494563, 56.675526186151856],
              [-157.478772164621631, 56.670371828008271],
              [-157.460825694656933, 56.625700724097229],
              [-157.590040278402768, 56.622264485334838],
              [-157.676183334233315, 56.60937858997589],
              [-157.737201332113301, 56.675526186151856],
              [-157.92025532575326, 56.642881917909165],
              [-157.984862617626163, 56.599069873688734],
              [-158.042291321513204, 56.596492694616941],
              [-158.074594967449656, 56.554398769777691],
              [-158.006398381583807, 56.553539710087094],
              [-157.909487443774424, 56.575016202352018],
              [-157.83770156391563, 56.560412187611874],
              [-157.816165799957986, 56.514022964319636],
              [-157.873594503845027, 56.473647158861581],
              [-158.027934145541451, 56.511445785247844],
              [-158.110487907379053, 56.520895441844416],
              [-158.128434377343751, 56.460761263502633],
              [-158.246881079110779, 56.465915621646204],
              [-158.329434840948409, 56.483955875148737],
              [-158.401220720807203, 56.455606905359048],
              [-158.498131658616586, 56.380009652586523],
              [-158.490953070630695, 56.342211026200246],
              [-158.322256252962518, 56.32502983238831],
              [-158.207398845188436, 56.294103683526828],
              [-158.218166727167272, 56.269190952499514],
              [-158.333024134941326, 56.233110445494447],
              [-158.329434840948409, 56.213352072610718],
              [-158.264827549075477, 56.209915833848328],
              [-158.175095199251984, 56.236546684256837],
              [-158.117666495364944, 56.230533266422654],
              [-158.315077664976627, 56.16352661055609],
              [-158.397631426814257, 56.109405850048489],
              [-158.394042132821312, 56.064734746137447],
              [-158.440702954729545, 56.107687730667294],
              [-158.476595894658942, 56.09308371592715],
              [-158.419167190771901, 56.036385776347757],
              [-158.440702954729545, 55.993432791817909],
              [-158.465828012680106, 56.026936119751184],
              [-158.501720952609503, 56.025218000369989],
              [-158.508899540595394, 55.979687836768363],
              [-158.598631890418886, 56.048412612016108],
              [-158.598631890418886, 56.130023282622815],
              [-158.666828476284763, 56.078479701187007],
              [-158.638114124341229, 56.015768343773431],
              [-158.652471300312982, 55.958211344503439],
              [-158.738614356143557, 55.953056986359854],
              [-158.727846474164721, 56.002882448414482],
              [-158.853471763917639, 56.003741508105065],
              [-158.910900467804652, 55.935016732857321],
              [-159.000632817628173, 55.927285195641943],
              [-159.26982986709865, 55.890345628946278],
              [-159.373919392893924, 55.871446315753147],
              [-159.399044450844485, 55.852547002560016],
              [-159.409812332823321, 55.788976585455842],
              [-159.470830330703279, 55.828493331223299],
              [-159.452883860738609, 55.897218106471058],
              [-159.535437622576211, 55.88089597234972],
              [-159.499544682646814, 55.855124181631808],
              [-159.495955388653869, 55.765781973809723],
              [-159.553384092540909, 55.704788735777342],
              [-159.531848328583266, 55.665271990009884],
              [-159.617991384413841, 55.595688155071542],
              [-159.732848792187895, 55.569916364353631],
              [-159.725670204202032, 55.614587468264673],
              [-159.639527148371485, 55.618023707027064],
              [-159.643116442364402, 55.652386094650936],
              [-159.679009382293799, 55.655822333413326],
              [-159.671830794307937, 55.75117795906958],
              [-159.607223502435005, 55.813030256792558],
              [-159.711313028230279, 55.846533584725833],
              [-159.811813260032579, 55.856842301013003],
              [-159.858474081940813, 55.841379226582262],
              [-159.847706199961976, 55.802721540505402],
              [-159.890777727877264, 55.784681287002854],
              [-159.937438549785497, 55.803580600196],
              [-160.02717089960899, 55.792412824218232],
              [-160.059474545545442, 55.721969929589292],
              [-160.185099835298331, 55.658399512485119],
              [-160.278421479114769, 55.641218318673182],
              [-160.411225356853549, 55.665271990009884],
              [-160.432761120811193, 55.640359258982585],
              [-160.393278886888851, 55.602560632596322],
              [-160.465064766747645, 55.532976797657966],
              [-160.461475472754728, 55.506345947249457],
              [-160.536850646606467, 55.474560738697377],
              [-160.579922174521727, 55.56476200621006],
              [-160.615815114451124, 55.575929782187814],
              [-160.666065230352302, 55.544144573635734],
              [-160.705547464274616, 55.556171409304085],
              [-160.770154756147548, 55.529540558895576],
              [-160.658886642366411, 55.518372782917822],
              [-160.666065230352302, 55.459956723957234],
              [-160.780922638126356, 55.451366127051259],
              [-160.838351342013397, 55.472842619316182],
              [-160.820404872048698, 55.506345947249457],
              [-160.867065693956931, 55.526963379823783],
              [-160.946030161801616, 55.508064066630652],
              [-161.014226747667465, 55.430748694476932],
              [-161.233173681236792, 55.3577286207762],
              [-161.32649532505323, 55.359446740157395],
              [-161.365977558975572, 55.384359471184709],
              [-161.45929920279201, 55.359446740157395],
              [-161.505960024700244, 55.362882978919785],
              [-161.470067084770847, 55.496896290652899],
              [-161.365977558975572, 55.579366020950204],
              [-161.362388264982627, 55.618882766717661],
              [-161.416227674876723, 55.632627721767207],
              [-161.527495788657859, 55.630909602386012],
              [-161.613638844488435, 55.605996871358698],
              [-161.699781900318982, 55.51923184260842],
              [-161.685424724347229, 55.408413142521411],
              [-161.828996484064817, 55.288144785837844],
              [-161.854121542015406, 55.277836069550688],
              [-161.904371657916556, 55.204815995849955],
              [-161.979746831768296, 55.235742144711438],
              [-162.047943417634173, 55.225433428424282],
              [-162.001282595725939, 55.169594548535471],
              [-161.965389655796542, 55.154990533795328],
              [-161.961800361803597, 55.106883191121909],
              [-162.05153271162709, 55.074238922879218],
              [-162.119729297492967, 55.102587892668922],
              [-162.119729297492967, 55.141245578745782],
              [-162.177158001380008, 55.15413147410473],
              [-162.223818823288212, 55.102587892668922],
              [-162.187925883358815, 55.060493967829672],
              [-162.248943881238802, 55.020118162371617],
              [-162.299193997139952, 55.043312774017735],
              [-162.414051404914034, 55.036440296492955],
              [-162.471480108801074, 55.051903370923696],
              [-162.521730224702225, 55.11547378802787],
              [-162.406872816928171, 55.120628146171455],
              [-162.482247990779911, 55.16100395162951],
              [-162.510962342723417, 55.250346159451581],
              [-162.586337516575156, 55.298453502125014],
              [-162.625819750497499, 55.304466919959197],
              [-162.68324845438454, 55.276117950169493],
              [-162.719141394313937, 55.220279070280696],
              [-162.694016336363347, 55.197084458634578],
              [-162.643766220462197, 55.197084458634578],
              [-162.579158928589266, 55.136950280292794],
              [-162.618641162511608, 55.097433534525337],
              [-162.568391046610458, 55.015822863918629],
              [-162.568391046610458, 54.971151760007587],
              [-162.647355514455143, 54.996923550725498],
              [-162.719141394313937, 54.980601416604159],
              [-162.7083735123351, 54.958265864648638],
              [-162.769391510215087, 54.932494073930727],
              [-162.833998802088018, 54.926480656096558],
              [-162.912963269932675, 54.95053432743326],
              [-162.970391973819716, 55.001218849178485],
              [-162.956034797847963, 55.032144998039968],
              [-163.002695619756196, 55.0802523407134],
              [-163.078070793607935, 55.11203754926548],
              [-163.189338907389072, 55.090561057000556],
              [-163.225231847318469, 55.042453714327138],
              [-163.049356441664401, 54.969433640626391],
              [-163.034999265692647, 54.942802790217897],
              [-163.214463965339633, 54.847447164561643],
              [-163.300607021170208, 54.829406911059095],
              [-163.372392901029002, 54.801057941269406],
              [-163.314964197141961, 54.752950598595973],
              [-163.218053259332578, 54.754668717977168],
              [-163.185749613396126, 54.775286150551494],
              [-163.106785145551441, 54.732333166021647],
              [-163.060124323643208, 54.66103121170211],
              [-163.153445967459675, 54.666185569845695],
              [-163.12473161551614, 54.695393599325982],
              [-163.185749613396126, 54.700547957469567],
              [-163.221642553325523, 54.676494286132851],
              [-163.279071257212564, 54.695393599325982],
              [-163.340089255092551, 54.748655300143],
              [-163.429821604916043, 54.715151972209711],
              [-163.422643016930152, 54.655876853558524],
              [-163.487250308803084, 54.655017793867927],
              [-163.587750540605384, 54.612064809338079],
              [-163.670304302443014, 54.627527883768835],
              [-163.803108180181795, 54.636118480674796],
              [-163.964626409864081, 54.631823182221808],
              [-164.086662405624054, 54.619796346553457],
              [-164.233823459334587, 54.585433958929585],
              [-164.337912985129833, 54.524440720897204],
              [-164.352270161101586, 54.465165602246017],
              [-164.45635968689686, 54.419635438644377],
              [-164.639413680536791, 54.391286468854673],
              [-164.743503206332036, 54.393863647926466],
              [-164.844003438134365, 54.417917319263182],
              [-164.876307084070817, 54.443689109981094],
              [-164.908610730007297, 54.507259527085267],
              [-164.944503669936694, 54.533031317803164],
              [-164.948092963929639, 54.580279600786],
              [-164.919378611986104, 54.605192331813313],
              [-164.761449676296763, 54.640413779127783],
              [-164.711199560395585, 54.661890271392707],
              [-164.628645798557983, 54.756386837358363],
              [-164.560449212692106, 54.850883403324019],
              [-164.564038506685051, 54.875796134351333],
              [-164.485074038840366, 54.922185357643571],
              [-164.434823922939216, 54.933353133621324],
              [-164.345091573115724, 54.893836387853867],
              [-164.208698401383998, 54.927339715787156],
              [-164.118966051560506, 54.969433640626391],
              [-164.029233701737013, 54.969433640626391],
              [-163.896429823998233, 55.039017475564748],
              [-163.77439382823826, 55.056198669376684],
              [-163.645179244492425, 55.044171833708333],
              [-163.533911130711289, 55.048467132161306],
              [-163.530321836718343, 55.016681923609227],
              [-163.429821604916043, 54.954829625886248],
              [-163.397517958979563, 54.902426984759842],
              [-163.41546442894429, 54.859474000229994],
              [-163.318553491134907, 54.880091432804321],
              [-163.347267843078413, 54.924762536715363],
              [-163.340089255092551, 54.956547745267443],
              [-163.232410435304331, 54.930775954549532],
              [-163.286249845198427, 54.966856461554613],
              [-163.279071257212564, 55.033004057730565],
              [-163.314964197141961, 55.126641564005638],
              [-163.200106789367879, 55.166158309773095],
              [-163.106785145551441, 55.184198563275629],
              [-162.956034797847963, 55.171312667916666],
              [-162.880659623996223, 55.183339503585032],
              [-162.844766684066826, 55.242614622236218],
              [-162.902195387953867, 55.246050860998608],
              [-162.887838211982114, 55.27010453233531],
              [-162.823230920109182, 55.278695129241285],
              [-162.798105862158621, 55.311339397483962],
              [-162.73349857028569, 55.307903158721572],
              [-162.640176926469252, 55.39295006809067],
              [-162.629409044490444, 55.365460157991578],
              [-162.57556963459632, 55.348278964179627],
              [-162.500194460744581, 55.38951382932828],
              [-162.518140930709308, 55.460815783647831],
              [-162.589926810568102, 55.453084246432454],
              [-162.493015872758718, 55.511500305393042],
              [-162.263301057210555, 55.688466601656003],
              [-162.144854355443556, 55.733137705567046],
              [-162.062300593605926, 55.786399406384049],
              [-161.994104007740077, 55.797567182361817],
              [-161.897193069930694, 55.833647689366884],
              [-161.807460720107201, 55.892063748327473],
              [-161.710549782297818, 55.904090583995824],
              [-161.584924492544928, 55.937593911929113],
              [-161.452120614806148, 55.954775105741049],
              [-161.132673449434492, 56.014909284082833],
              [-161.024994629646272, 56.007177746867455],
              [-160.989101689716875, 56.022640821298211],
              [-160.827583460034589, 56.026936119751184],
              [-160.791690520105192, 56.018345522845223],
              [-160.863476399963986, 55.995150911199104],
              [-160.867065693956931, 55.954775105741049],
              [-160.816815578055753, 55.953056986359854],
              [-160.827583460034589, 55.910963061520604],
              [-160.931672985829834, 55.895499987089863],
              [-160.942440867808671, 55.822479913389131],
              [-160.806047696076945, 55.738292063710631],
              [-160.766565462154603, 55.757191376903762],
              [-160.669654524345219, 55.723688048970487],
              [-160.655297348373466, 55.740010183091826],
              [-160.75938687416874, 55.785540346693452],
              [-160.770154756147548, 55.858560420394198],
              [-160.791690520105192, 55.886050330493291],
              [-160.698368876288754, 55.861996659156588],
              [-160.49377911869118, 55.864573838228367],
              [-160.46865406074059, 55.790694704837037],
              [-160.386100298902988, 55.79670812267122],
              [-160.292778655086551, 55.765781973809723],
              [-160.267653597135961, 55.784681287002854],
              [-160.317903713037111, 55.819043674626741],
              [-160.220992775227728, 55.831929569985689],
              [-160.421993238832385, 55.908385882448812],
              [-160.536850646606467, 55.939312031310308],
              [-160.533261352613522, 55.989137493364922],
              [-160.590690056500563, 55.983124075530753],
              [-160.450707590775892, 56.125727984169828],
              [-160.396868180881796, 56.231392326113252],
              [-160.357385946959454, 56.279499668786684],
              [-160.274832185121852, 56.317298295172947],
              [-160.145617601375989, 56.399768025470252],
              [-160.081010309503085, 56.410935801448005],
              [-159.937438549785497, 56.474506218552179],
              [-159.829759729997278, 56.544090053490521],
              [-159.535437622576211, 56.626559783787826],
              [-159.388276568865678, 56.690130200892],
              [-159.262651279112788, 56.723633528825275],
              [-159.018579287592871, 56.814693856028555],
              [-158.957561289712885, 56.850774363033622],
              [-158.853471763917639, 56.792358304073034],
              [-158.785275178051762, 56.780331468404668],
              [-158.674007064270626, 56.796653602526021],
              [-158.645292712327119, 56.847338124271232],
              [-158.691953534235324, 56.887713929729287],
              [-158.699132122221215, 56.927230675496745],
              [-158.645292712327119, 57.032035957749571],
              [-158.541203186531845, 57.124814404334032],
              [-158.394042132821312, 57.236492164111638],
              [-158.307899076990765, 57.289753864928642],
              [-158.22893460914608, 57.321539073480722],
              [-158.085362849428492, 57.356760520795206],
              [-158.009987675576753, 57.401431624706234],
              [-157.931023207732068, 57.476169817788175],
              [-157.880773091830918, 57.489055713147124],
              [-157.78745144801448, 57.542317413964128],
              [-157.67977262822626, 57.563793906229051],
              [-157.651058276282754, 57.500223489124878],
              [-157.586450984409822, 57.487337593765929],
              [-157.572093808438069, 57.522559041080399],
              [-157.607986748367466, 57.612760308593082],
              [-157.690540510205096, 57.611042189211886],
              [-157.71207627416274, 57.640250218692174],
              [-157.704897686176849, 57.721860889298881],
              [-157.672594040240398, 57.772545411044106],
              [-157.582861690416905, 58.123900824498236],
              [-157.532611574515727, 58.159981331503303],
              [-157.396218402784029, 58.173726286552849],
              [-157.353146874868742, 58.219256450154489],
              [-157.424932754727536, 58.211524912939126],
              [-157.546968750487508, 58.277672509115078],
              [-157.557736632466316, 58.330934209932096],
              [-157.525432986529864, 58.414262999919984],
              [-157.453647106671042, 58.505323327123264],
              [-157.356736168861687, 58.533672296912954],
              [-157.252646643066413, 58.620437325663246],
              [-157.076771237412373, 58.708061414104137],
              [-157.062414061440592, 58.757027816468153],
              [-156.994217475574743, 58.836920367693665],
              [-157.012163945539442, 58.875578053770525],
              [-156.965503123631237, 58.903927023560229],
              [-156.976271005610045, 58.940866590255894],
              [-156.929610183701811, 58.973510858498571],
              [-157.001396063560634, 58.973510858498571],
              [-157.040878297482948, 58.946020948399479],
              [-157.040878297482948, 58.913376680156787],
              [-157.116253471334687, 58.867846516555161],
              [-157.216753703137016, 58.841215666146653],
              [-157.353146874868742, 58.818021054500534],
              [-157.428522048720481, 58.791390204092025],
              [-157.532611574515727, 58.772490890898894],
              [-157.550558044480425, 58.75445063739636],
              [-157.697719098190959, 58.730396966059658],
              [-157.855648033880328, 58.677994324933238],
              [-158.139202259322587, 58.615282967519661],
              [-158.232523903139025, 58.619578265972649],
              [-158.333024134941326, 58.665108429574289],
              [-158.350970604906024, 58.727819786987865],
              [-158.422756484764847, 58.769913711827115],
              [-158.566328244482435, 58.802557980069793],
              [-158.559149656496544, 58.84207472583725],
              [-158.519667422574202, 58.856678740577394],
              [-158.48736377663775, 59.00014170890708],
              [-158.530435304553038, 58.997564529835287],
              [-158.62016765437653, 58.910799501084995],
              [-158.767328708087064, 58.864410277792771],
              [-158.788864472044708, 58.814584815738144],
              [-158.770918002080009, 58.76475935368353],
              [-158.799632354023515, 58.732974145131436],
              [-158.849882469924694, 58.72266542884428],
              [-158.860650351903502, 58.695175518745174],
              [-158.82834670596705, 58.626450743497429],
              [-158.770918002080009, 58.548276311653112],
              [-158.70272141621416, 58.482987775167743],
              [-158.796043060030598, 58.408249582085816],
              [-158.896543291832899, 58.390209328583282],
              [-159.047293639536377, 58.417699238682374],
              [-159.187276105261049, 58.556007848868475],
              [-159.355972922929226, 58.734692264512631],
              [-159.449294566745664, 58.797403621926208],
              [-159.531848328583266, 58.833484128931275],
              [-159.643116442364402, 58.844651904909043],
              [-159.585687738477361, 58.900490784797839],
              [-159.643116442364402, 58.938289411184101],
              [-159.732848792187895, 58.930557873968723],
              [-159.754384556145538, 58.855819680886796],
              [-159.808223966039634, 58.861833098720979],
              [-159.804634672046717, 58.805994218832183],
              [-159.908724197841963, 58.780222428114271],
              [-159.99486725367251, 58.848088143671433],
              [-159.984099371693702, 58.870423695626954],
              [-160.052295957559579, 58.886745829748293],
              [-160.091778191481893, 58.860974039030381],
              [-160.149206895368934, 58.866128397173966],
              [-160.152796189361879, 58.916812918919177],
              [-160.231760657206564, 58.901349844488436],
              [-160.321493007030057, 58.953752485614842],
              [-160.256885715157125, 58.994128291072897],
              [-160.317903713037111, 59.07058460353602],
              [-160.475832648726481, 59.025913499624991],
              [-160.640940172401713, 58.964061201902012],
              [-160.752208286182849, 58.910799501084995],
              [-160.823994166041643, 58.829188830478301],
              [-160.870654987949877, 58.878155232842317],
              [-160.999869571695712, 58.849806263052628],
              [-161.337263207032066, 58.743282861418606],
              [-161.373156146961463, 58.708061414104137],
              [-161.373156146961463, 58.665967489264887],
              [-161.520317200671997, 58.633323221022195],
              [-161.549031552615503, 58.610987669066688],
              [-161.627996020460188, 58.602397072160713],
              [-161.681835430354283, 58.56459844577445],
              [-161.753621310213077, 58.551712550415502],
              [-161.767978486184859, 58.598960833398323],
              [-161.872068011980105, 58.637618519475183],
              [-162.065889887598871, 58.620437325663246],
              [-162.169979413394117, 58.64878629545295],
              [-161.994104007740077, 58.689162100911005],
              [-161.940264597845953, 58.655658772977716],
              [-161.87565730597305, 58.665967489264887],
              [-161.857710836008351, 58.708920473794734],
              [-161.767978486184859, 58.775068069970686],
              [-161.757210604206023, 58.826611651406509],
              [-161.785924956149557, 58.899631725107241],
              [-161.782335662156612, 58.969215560045598],
              [-161.839764366043653, 59.032785977149757],
              [-161.821817896078954, 59.050826230652291],
              [-161.879246599965995, 59.068007424464241],
              [-161.983336125761241, 59.15133621445213],
              [-162.055122005620035, 59.270745511445099],
              [-161.951032479824789, 59.380705151841511],
              [-161.87565730597305, 59.400463524725239],
              [-161.789514250142474, 59.468329240282387],
              [-161.703371194311927, 59.490664792237908],
              [-161.757210604206023, 59.556812388413874],
              [-161.821817896078954, 59.594611014800137],
              [-161.87565730597305, 59.649590834998335],
              [-161.911550245902447, 59.741510221892213],
              [-162.091014945549432, 59.880677891768912],
              [-162.101782827528268, 59.944248308873085],
              [-162.191515177351761, 60.001805308143076],
              [-162.227408117281158, 60.055926068650678],
              [-162.335086937069349, 60.12894614235141],
              [-162.370979876998746, 60.166744768737672],
              [-162.449944344843431, 60.17447630595305],
              [-162.489426578765773, 60.14269109740097],
              [-162.496605166751664, 60.079120680296796],
              [-162.47506940279402, 60.047335471744702],
              [-162.503783754737526, 59.999228129071284],
              [-162.546855282652814, 59.98118787556875],
              [-162.643766220462197, 59.972597278662775],
              [-162.7083735123351, 59.990637532165309],
              [-162.776570098200978, 59.944248308873085],
              [-162.905784681946812, 59.919335577845771],
              [-163.085249381593798, 59.860919518885183],
              [-163.347267843078413, 59.81968465373653],
              [-163.663125714457124, 59.795630982399814],
              [-163.93232276392763, 59.804221579305789],
              [-164.11537675756756, 59.836865847548466],
              [-164.162037579475793, 59.864355757647573],
              [-164.208698401383998, 59.949402667016656],
              [-164.126144639546396, 59.964865741447412],
              [-164.1907519314193, 60.024140860098598],
              [-164.384573807038066, 60.077402560915601],
              [-164.499431214812148, 60.170181007500062],
              [-164.517377684776847, 60.199389036980364],
              [-164.635824386543845, 60.243201081200809],
              [-164.700431678416777, 60.296462782017812],
              [-164.851182026120256, 60.303335259542592],
              [-164.962450139901392, 60.33941576654766],
              [-165.13114695756957, 60.433912332513316],
              [-165.048593195731939, 60.461402242612422],
              [-164.962450139901392, 60.508650525595243],
              [-164.969628727887255, 60.53957667445674],
              [-165.05577178371783, 60.544731032600311],
              [-165.192164955449527, 60.498341809308087],
              [-165.36445106711065, 60.506932406214048],
              [-165.421879770997691, 60.550744450434493],
              [-165.382397537075349, 60.5782343605336],
              [-165.267540129301267, 60.579093420224197],
              [-165.062950371703693, 60.689053060620594],
              [-165.027057431774296, 60.685616821858204],
              [-164.966039433894338, 60.724274507935064],
              [-165.023468137781379, 60.753482537415366],
              [-165.037825313753132, 60.788703984729835],
              [-164.92296790597905, 60.809321417304162],
              [-165.019878843788433, 60.815334835138344],
              [-165.001932373823735, 60.875469013480128],
              [-164.905021436014351, 60.942475669346678],
              [-164.966039433894338, 60.94505284841847],
              [-165.030646725767241, 60.903817983269818],
              [-165.174218485484829, 60.939898490274899],
              [-165.195754249442473, 60.974260877898772],
              [-165.116789781597816, 61.016354802738022],
              [-165.019878843788433, 61.011200444594436],
              [-164.951682257922556, 61.030958817478165],
              [-164.951682257922556, 61.069616503555025],
              [-165.012700255802542, 61.051576250052491],
              [-165.120379075590733, 61.079066160151598],
              [-165.177807779477774, 61.125455383443821],
              [-165.170629191491912, 61.14521375632755],
              [-165.242415071350706, 61.151227174161733],
              [-165.289075893258911, 61.181294263332617],
              [-165.324968833188308, 61.169267427664266],
              [-165.368040361103596, 61.084220518295169],
              [-165.418290477004746, 61.079066160151598],
              [-165.554683648736471, 61.092811115201144],
              [-165.590576588665868, 61.110851368703678],
              [-165.626469528595266, 61.167549308283071],
              [-165.62288023460232, 61.27836800837008],
              [-165.662362468524663, 61.294690142491419],
              [-165.816702110221087, 61.30328073939738],
              [-165.881309402094018, 61.335065947949474],
              [-165.917202342023415, 61.41925379762796],
              [-165.766451994319937, 61.457052424014236],
              [-165.744916230362293, 61.489696692256913],
              [-165.805934228242279, 61.529213438024371],
              [-165.91361304803047, 61.55584428843288],
              [-166.075131277712757, 61.532649676786761],
              [-166.075131277712757, 61.493132931019304],
              [-166.150506451564496, 61.512891303903032],
              [-166.182810097500976, 61.588488556675557],
              [-166.154095745557441, 61.714770331193307],
              [-166.132559981599798, 61.724219987789866],
              [-166.139738569585688, 61.632300600896002],
              [-165.809523522235224, 61.672676406354057],
              [-165.809523522235224, 61.688998540475396],
              [-165.935148811988114, 61.706179734287332],
              [-166.006934691846908, 61.730233405624048],
              [-166.093077747677455, 61.800676300252988],
              [-166.085899159691593, 61.816139374683743],
              [-165.956684575945758, 61.832461508805082],
              [-165.759273406334046, 61.825589031280302],
              [-165.640826704567019, 61.847065523545226],
              [-165.744916230362293, 61.962179522085208],
              [-165.759273406334046, 62.001696267852665],
              [-165.748505524355238, 62.073857281862814],
              [-165.673130350503499, 62.140004878038766],
              [-165.310611657216555, 62.378823472024706],
              [-165.296254481244802, 62.40373620305202],
              [-165.199343543435418, 62.469883799227986],
              [-165.095254017640173, 62.522286440354392],
              [-165.077307547675474, 62.582420618696176],
              [-165.012700255802542, 62.639977617966167],
              [-164.937325081950803, 62.669185647446469],
              [-164.869128496084954, 62.665749408684079],
              [-164.836824850148503, 62.685507781567807],
              [-164.876307084070817, 62.784299645986451],
              [-164.782985440254379, 62.945802867818671],
              [-164.718378148381476, 63.005937046160454],
              [-164.58198497664975, 63.05833968728686],
              [-164.646592268522681, 63.078957119861187],
              [-164.6322350925509, 63.097856433054318],
              [-164.424056040960409, 63.212111371903703],
              [-164.208698401383998, 63.25162811767116],
              [-164.036412289722875, 63.261077774267733],
              [-163.885661942019397, 63.222420088190873],
              [-163.731322300323001, 63.2129704315943],
              [-163.616464892548919, 63.140809417584165],
              [-163.530321836718343, 63.13565505944058],
              [-163.508786072760728, 63.113319507485059],
              [-163.314964197141961, 63.037722254712534],
              [-163.052945735657346, 63.05833968728686],
              [-162.833998802088018, 63.164863088920882],
              [-162.819641626116237, 63.205238894378937],
              [-162.661712690426896, 63.230151625406251],
              [-162.435587168871677, 63.37790989218891],
              [-162.353033407034047, 63.454366204652032],
              [-162.270479645196446, 63.487869532585322],
              [-162.302783291132897, 63.537694994639935],
              [-162.252533175231747, 63.541990293092923],
              [-162.152032943429418, 63.517936621756206],
              [-162.073068475584734, 63.513641323303219],
              [-162.026407653676529, 63.447493727127267],
              [-161.839764366043653, 63.447493727127267],
              [-161.678246136361338, 63.464674920939203],
              [-161.584924492544928, 63.447493727127267],
              [-161.308548855088532, 63.471547398463969],
              [-161.136262743427437, 63.50419166670666],
              [-161.103959097490957, 63.548003710927105],
              [-160.902958633886328, 63.657963351323502],
              [-160.766565462154603, 63.773936409554082],
              [-160.766565462154603, 63.834929647586463],
              [-160.80963699006989, 63.90451348252482],
              [-160.892190751907492, 63.986124153131527],
              [-160.953208749787478, 64.090070375693742],
              [-160.956798043780424, 64.191439419184178],
              [-160.974744513745122, 64.236110523095221],
              [-161.229584387243847, 64.370982894518932],
              [-161.261888033180327, 64.398472804618038],
              [-161.40904908689086, 64.422526475954754],
              [-161.505960024700244, 64.423385535645352],
              [-161.470067084770847, 64.50671432563324],
              [-161.373156146961463, 64.535063295422944],
              [-161.322906031060313, 64.51358680315802],
              [-161.197280741307395, 64.496405609346084],
              [-161.014226747667465, 64.502419027180267],
              [-161.050119687596862, 64.541076713257127],
              [-160.938851573815725, 64.549667310163088],
              [-160.791690520105192, 64.619251145101444],
              [-160.784511932119301, 64.71718394982949],
              [-160.93526227982278, 64.821989232082316],
              [-161.103959097490957, 64.880405291042905],
              [-161.19369144731445, 64.921640156191557],
              [-161.197280741307395, 64.894150246092451],
              [-161.294191679116778, 64.852915380943799],
              [-161.365977558975572, 64.779036247552469],
              [-161.516727906679051, 64.753264456834557],
              [-161.66747825438253, 64.789344963839625],
              [-161.879246599965995, 64.709452412614112],
              [-162.169979413394117, 64.680244383133825],
              [-162.234586705267049, 64.619251145101444],
              [-162.539676694666923, 64.530767996969956],
              [-162.615051868518663, 64.470633818628173],
              [-162.632998338483361, 64.385586909259089],
              [-162.790927274172731, 64.325452730917306],
              [-162.805284450144484, 64.405345282142818],
              [-162.859123860038579, 64.499841848108474],
              [-162.94167762187621, 64.542794832638322],
              [-163.042177853678538, 64.540217653566529],
              [-163.027820677706757, 64.569425683046816],
              [-163.142678085480838, 64.608942428814274],
              [-163.17498173141729, 64.63986857767577],
              [-163.311374903149016, 64.588324996239947],
              [-163.253946199261975, 64.549667310163088],
              [-163.178571025410236, 64.543653892328919],
              [-163.121142321523195, 64.51015056439563],
              [-163.034999265692647, 64.519600220992203],
              [-163.027820677706757, 64.47836535584355],
              [-163.106785145551441, 64.408781520905194],
              [-163.17498173141729, 64.399331864308635],
              [-163.253946199261975, 64.469774758937575],
              [-163.451357368873687, 64.535063295422944],
              [-163.59851842258422, 64.563412265212648],
              [-163.828233238132356, 64.574580041190401],
              [-163.975394291842917, 64.551385429544283],
              [-164.072305229652272, 64.561694145831453],
              [-164.258948517285148, 64.564271324903245],
              [-164.420466746967463, 64.5453720117101],
              [-164.808110498204968, 64.449157326363249],
              [-165.016289549795488, 64.434553311623105],
              [-165.414701183011829, 64.498123728727279],
              [-165.752094818348183, 64.536781414804139],
              [-165.920791636016361, 64.54880825047249],
              [-166.236649507395072, 64.583170638096377],
              [-166.412524913049111, 64.651036353653524],
              [-166.484310792907905, 64.733506083950829],
              [-166.480721498914988, 64.797076501055002],
              [-166.408935619056166, 64.828002649916485],
              [-166.43047138301381, 64.882982470114698],
              [-166.530971614816139, 64.937103230622299],
              [-166.692489844498425, 64.985210573295717],
              [-166.69607913849137, 65.035895095040942],
              [-166.861186662166602, 65.090874915239141],
              [-166.897079602095999, 65.138982257912573],
              [-166.757097136371357, 65.11063328812287],
              [-166.63865043460433, 65.113210467194662],
              [-166.606346788667878, 65.136405078840781],
              [-166.520203732837302, 65.149290974199729],
              [-166.46277502895029, 65.176780884298836],
              [-166.473542910929098, 65.224029167281657],
              [-166.347917621176208, 65.276431808408077],
              [-166.484310792907905, 65.330552568915678],
              [-166.552507378773782, 65.338284106131056],
              [-166.656596904569028, 65.324539151081495],
              [-166.796579370293699, 65.337425046440458],
              [-167.026294185841834, 65.382096150351487],
              [-167.399580761107586, 65.400136403854034],
              [-167.575456166761654, 65.444807507765063],
              [-167.71184933849338, 65.498928268272678],
              [-167.909260508105064, 65.550471849708487],
              [-168.074368031780295, 65.576243640426398],
              [-168.128207441674391, 65.65613619165191]
            ]
          ],
          [
            [
              [-169.054245291852908, 52.863019279282781],
              [-168.957334354043525, 52.8862138909289],
              [-168.96092364803647, 52.936898412674125],
              [-168.896316356163538, 52.936898412674125],
              [-168.860423416234141, 52.967824561535608],
              [-168.860423416234141, 53.016790963899624],
              [-168.785048242382402, 53.045139933689327],
              [-168.767101772417703, 53.094106336053358],
              [-168.8029947123471, 53.107851291102904],
              [-168.763512478424758, 53.182589484184831],
              [-168.598404954749526, 53.273649811388111],
              [-168.540976250862485, 53.25131425943259],
              [-168.44406531305313, 53.265059214482136],
              [-168.343565081250802, 53.262482035410343],
              [-168.375868727187253, 53.31832091529914],
              [-168.418940255102541, 53.322616213752127],
              [-168.386636609166089, 53.388763809928093],
              [-168.408172373123705, 53.411099361883615],
              [-168.343565081250802, 53.476387898368969],
              [-168.199993321533213, 53.533944897638975],
              [-168.006171445914447, 53.565730106191054],
              [-167.902081920119201, 53.520199942589414],
              [-167.790813806338065, 53.521059002280012],
              [-167.783635218352174, 53.501300629396283],
              [-167.859010392203913, 53.437730212292109],
              [-167.841063922239215, 53.386186630856301],
              [-168.038475091850898, 53.304575960249593],
              [-168.271779201392008, 53.242723662526615],
              [-168.339975787257856, 53.186025722947221],
              [-168.343565081250802, 53.155099574085739],
              [-168.44406531305313, 53.085515739147382],
              [-168.497904722947226, 53.035690277092769],
              [-168.587637072770718, 53.027099680186794],
              [-168.688137304573047, 52.966106442154413],
              [-168.741976714467143, 52.951502427414269],
              [-168.756333890438896, 52.907690383193824],
              [-168.810173300332991, 52.925730636696358],
              [-168.907084238142374, 52.883636711857108],
              [-169.100906113761113, 52.824361593205921],
              [-169.054245291852908, 52.863019279282781]
            ]
          ],
          [
            [
              [-169.26960293142929, 52.772818011770113],
              [-169.165513405634044, 52.814911936609363],
              [-169.16910269962699, 52.776254250532503],
              [-169.262424343443428, 52.754777758267579],
              [-169.26960293142929, 52.772818011770113]
            ]
          ],
          [
            [
              [-169.761336208462069, 52.978133277822764],
              [-169.746979032490316, 53.021086262352611],
              [-169.678782446624439, 53.034831217402171],
              [-169.664425270652686, 52.997032591015895],
              [-169.696728916589166, 52.958374904939035],
              [-169.739800444504425, 52.951502427414269],
              [-169.761336208462069, 52.978133277822764]
            ]
          ],
          [
            [
              [-169.790050560405604, 56.617969186881865],
              [-169.61058586075859, 56.606801410904097],
              [-169.506496334963344, 56.611096709357085],
              [-169.470603395033947, 56.599069873688734],
              [-169.567514332843302, 56.540653814728145],
              [-169.650068094680933, 56.544090053490521],
              [-169.682371740617384, 56.583606799257979],
              [-169.754157620476178, 56.592197396163954],
              [-169.790050560405604, 56.617969186881865]
            ]
          ],
          [
            [
              [-169.775693384433822, 53.0795023213132],
              [-169.746979032490316, 53.045998993379925],
              [-169.800818442384411, 53.057166769357679],
              [-169.775693384433822, 53.0795023213132]
            ]
          ],
          [
            [
              [-170.005408199981986, 52.846697145161443],
              [-169.944390202101999, 52.861301159901586],
              [-169.861836440264398, 52.858723980829794],
              [-169.86901502825026, 52.875046114951147],
              [-169.772104090440905, 52.894804487834875],
              [-169.703907504575028, 52.8862138909289],
              [-169.668014564645631, 52.864737398663976],
              [-169.729032562525617, 52.774536131151308],
              [-169.836711382313808, 52.817489115681155],
              [-169.926443732137301, 52.792576384653842],
              [-169.994640318003178, 52.804603220322193],
              [-170.005408199981986, 52.846697145161443]
            ]
          ],
          [
            [
              [-170.113087019770177, 52.901676965359641],
              [-170.084372667826671, 52.924012517315163],
              [-170.00181890598904, 52.910267562265616],
              [-169.998229611996095, 52.883636711857108],
              [-170.048479727897274, 52.857005861448613],
              [-170.095140549805478, 52.870750816498159],
              [-170.113087019770177, 52.901676965359641]
            ]
          ],
          [
            [
              [-170.184872899628971, 52.722992549715485],
              [-170.170515723657218, 52.784844847438464],
              [-170.052069021890219, 52.769381773007723],
              [-170.07719407984078, 52.720415370643693],
              [-170.184872899628971, 52.722992549715485]
            ]
          ],
          [
            [
              [-170.421766303163025, 57.169485508245074],
              [-170.389462657226545, 57.206425074940739],
              [-170.310498189381889, 57.219310970299688],
              [-170.267426661466601, 57.210720373393727],
              [-170.163337135671355, 57.229619686586858],
              [-170.170515723657218, 57.181512343913425],
              [-170.285373131431299, 57.128250643096422],
              [-170.303319601395998, 57.154881493504931],
              [-170.421766303163025, 57.169485508245074]
            ]
          ],
          [
            [
              [-170.834535112351119, 52.600147013960125],
              [-170.816588642386421, 52.636227520965207],
              [-170.673016882668804, 52.698079818688171],
              [-170.561748768887668, 52.674885207042067],
              [-170.558159474894723, 52.651690595395948],
              [-170.604820296802956, 52.60186513334132],
              [-170.673016882668804, 52.603583252722515],
              [-170.734034880548791, 52.581247700766994],
              [-170.787874290442886, 52.540012835618342],
              [-170.841713700336982, 52.558053089120889],
              [-170.834535112351119, 52.600147013960125]
            ]
          ],
          [
            [
              [-171.15039298372983, 52.584683939529384],
              [-171.118089337793378, 52.557194029430292],
              [-171.175518041680391, 52.558912148811473],
              [-171.15039298372983, 52.584683939529384]
            ]
          ],
          [
            [
              [-171.311911213412117, 52.493623612326118],
              [-171.25089321553213, 52.529704119331186],
              [-171.193464511645089, 52.497918910779106],
              [-171.236536039560377, 52.450670627796271],
              [-171.304732625426254, 52.449811568105673],
              [-171.311911213412117, 52.493623612326118]
            ]
          ],
          [
            [
              [-171.839537430374293, 63.548003710927105],
              [-171.832358842388402, 63.579788919479185],
              [-171.742626492564909, 63.654527112561112],
              [-171.753394374543745, 63.718956589355884],
              [-171.724680022600211, 63.734419663786625],
              [-171.742626492564909, 63.783386066150655],
              [-171.66725131871317, 63.785963245222447],
              [-171.638536966769664, 63.749882738217366],
              [-171.652894142741417, 63.708647873068728],
              [-171.609822614826129, 63.679439843588426],
              [-171.380107799277994, 63.630473441224396],
              [-171.103732161821597, 63.589238576075758],
              [-170.906320992209913, 63.572057382263807],
              [-170.698141940619394, 63.646795575345749],
              [-170.489962889028874, 63.696621037400362],
              [-170.281783837438354, 63.684594201732011],
              [-170.177694311643108, 63.625319083080825],
              [-170.095140549805478, 63.612433187721862],
              [-170.041301139911383, 63.523090979899791],
              [-170.048479727897274, 63.4904467116571],
              [-169.858247146271452, 63.442339368983681],
              [-169.657246682666823, 63.430312533315316],
              [-169.578282214822138, 63.402822623216224],
              [-169.545978568885687, 63.372755534045325],
              [-169.416763985139823, 63.355574340233389],
              [-169.100906113761113, 63.338393146421453],
              [-169.050655997859963, 63.343547504565038],
              [-168.684548010580102, 63.296299221582203],
              [-168.781458948389457, 63.183762402114013],
              [-168.860423416234141, 63.146822835418348],
              [-169.043477409874072, 63.176889924589233],
              [-169.197817051570496, 63.176030864898635],
              [-169.37728175121751, 63.151118133871336],
              [-169.535210686906851, 63.074661821408199],
              [-169.571103626836248, 63.022259180281793],
              [-169.567514332843302, 62.976729016680153],
              [-169.639300212702125, 62.937212270912696],
              [-169.746979032490316, 62.956111584105827],
              [-169.73621115051148, 62.976729016680153],
              [-169.829532794327918, 63.078957119861187],
              [-169.944390202101999, 63.13221882067819],
              [-170.123854901749013, 63.183762402114013],
              [-170.263837367473656, 63.179467103661025],
              [-170.303319601395998, 63.238742222312212],
              [-170.364337599275984, 63.285990505295047],
              [-170.558159474894723, 63.354715280542791],
              [-170.866838758287571, 63.413990399193978],
              [-171.100142867828652, 63.42344005579055],
              [-171.268839685496829, 63.385641429404288],
              [-171.333446977369761, 63.33581596734966],
              [-171.462661561115596, 63.306607937869373],
              [-171.563161792917924, 63.334956907659063],
              [-171.739037198571964, 63.365883056520559],
              [-171.82518025440254, 63.437185010840096],
              [-171.8503053123531, 63.485292353513529],
              [-171.839537430374293, 63.548003710927105]
            ]
          ],
          [
            [
              [-172.622003520835193, 52.298617062560623],
              [-172.568164110941098, 52.349301584305834],
              [-172.44971740917407, 52.391395509145084],
              [-172.406645881258811, 52.389677389763889],
              [-172.309734943449428, 52.356174061830615],
              [-172.313324237442373, 52.320952614516131],
              [-172.413824469244673, 52.2771405702957],
              [-172.528681877018755, 52.253945958649581],
              [-172.60764634486344, 52.253086898958983],
              [-172.622003520835193, 52.298617062560623]
            ]
          ],
          [
            [
              [-173.063486681966793, 60.502637107761075],
              [-173.041950918009178, 60.561912226412261],
              [-172.952218568185657, 60.606583330323289],
              [-172.912736334263343, 60.604006151251497],
              [-172.927093510235096, 60.554180689196883],
              [-172.884021982319808, 60.507791465904646],
              [-172.765575280552781, 60.447657287562862],
              [-172.593289168891687, 60.393536527055261],
              [-172.514324701047002, 60.38150969138691],
              [-172.424592351223509, 60.392677467364663],
              [-172.220002593625935, 60.311925856448553],
              [-172.295377767477675, 60.295603722327215],
              [-172.327681413414126, 60.327388930879295],
              [-172.510735407054057, 60.334261408404075],
              [-172.604057050870495, 60.319657393663931],
              [-172.722503752637522, 60.359174139431389],
              [-172.930682804228042, 60.476006257352566],
              [-173.063486681966793, 60.502637107761075]
            ]
          ],
          [
            [
              [-173.092201033910328, 60.699361776907764],
              [-173.063486681966793, 60.695066478454777],
              [-173.045540212002095, 60.622905464444628],
              [-173.117326091860917, 60.658985971449709],
              [-173.092201033910328, 60.699361776907764]
            ]
          ],
          [
            [
              [-174.046953236032351, 52.132818542275416],
              [-173.921327946279462, 52.132818542275416],
              [-173.802881244512434, 52.106187691866907],
              [-173.770577598575983, 52.131959482584818],
              [-173.720327482674804, 52.130241363203623],
              [-173.601880780907806, 52.153435974849742],
              [-173.512148431084285, 52.152576915159145],
              [-173.533684195041928, 52.128523243822428],
              [-173.497791255112531, 52.103610512795115],
              [-173.469076903169025, 52.116496408154077],
              [-173.357808789387889, 52.095878975579751],
              [-173.275255027550259, 52.109623930629297],
              [-173.239362087620862, 52.100174274032739],
              [-173.12450467984678, 52.109623930629297],
              [-173.106558209882081, 52.099315214342141],
              [-172.959397156171548, 52.093301796507959],
              [-173.04912950599504, 52.07354342362423],
              [-173.106558209882081, 52.078697781767815],
              [-173.156808325783231, 52.059798468574684],
              [-173.314737261472601, 52.058939408884086],
              [-173.393701729317286, 52.028872319713187],
              [-173.422416081260792, 52.042617274762733],
              [-173.479844785147833, 52.026295140641395],
              [-173.54804137101371, 52.029731379403785],
              [-173.612648662886613, 52.051207871668709],
              [-173.799291950519489, 52.053785050740501],
              [-173.831595596455941, 52.040899155381553],
              [-173.935685122251215, 52.057221289502891],
              [-173.971578062180612, 52.099315214342141],
              [-174.011060296102954, 52.098456154651544],
              [-174.046953236032351, 52.132818542275416]
            ]
          ],
          [
            [
              [-175.328331191511893, 52.027154200331992],
              [-175.130920021900209, 52.058939408884086],
              [-175.091437787977867, 52.03488573754737],
              [-175.001705438154374, 52.077838722077217],
              [-174.990937556175538, 52.058939408884086],
              [-174.92274097030969, 52.091583677126764],
              [-174.904794500344991, 52.116496408154077],
              [-174.840187208472059, 52.091583677126764],
              [-174.743276270662705, 52.098456154651544],
              [-174.71456191871917, 52.127664184131831],
              [-174.693026154761526, 52.107046751557505],
              [-174.567400865008636, 52.138831960109599],
              [-174.563811571015691, 52.174912467114666],
              [-174.40588263532635, 52.183503064020627],
              [-174.452543457234555, 52.218724511335111],
              [-174.29820381553813, 52.214429212882123],
              [-174.251542993629926, 52.243637242362411],
              [-174.255132287622871, 52.274563391223907],
              [-174.369989695396953, 52.280576809058076],
              [-174.452543457234555, 52.305489540085389],
              [-174.434596987269856, 52.327825092040911],
              [-174.359221813418117, 52.314080136991365],
              [-174.316150285502829, 52.345006285852847],
              [-174.32332887348872, 52.378509613786136],
              [-174.186935701756994, 52.418026359553593],
              [-174.068488999989995, 52.390536449454487],
              [-173.98952453214531, 52.325247912969118],
              [-173.985935238152365, 52.298617062560623],
              [-174.061310412004104, 52.225596988859877],
              [-174.183346407764077, 52.232469466384657],
              [-174.19770358373583, 52.195529899688992],
              [-174.079256881968803, 52.127664184131831],
              [-174.090024763947639, 52.107046751557505],
              [-174.143864173841735, 52.125087005060038],
              [-174.204882171721692, 52.116496408154077],
              [-174.219239347693474, 52.093301796507959],
              [-174.276668051580515, 52.089865557745568],
              [-174.348453931439309, 52.109623930629297],
              [-174.416650517305158, 52.02801326002259],
              [-174.50638286712865, 52.054644110431099],
              [-174.736097682676814, 52.007395827448264],
              [-174.782758504585018, 52.032308558475577],
              [-174.886848030380293, 52.042617274762733],
              [-174.969401792217923, 52.037462916619162],
              [-175.016062614126128, 52.007395827448264],
              [-175.095027081970812, 52.000523349923498],
              [-175.213473783737811, 52.005677708067068],
              [-175.328331191511893, 52.027154200331992]
            ]
          ],
          [
            [
              [-175.511385185151823, 51.98248309642095],
              [-175.450367187271866, 51.997087111161107],
              [-175.425242129321276, 51.972174380133794],
              [-175.504206597165961, 51.968738141371404],
              [-175.511385185151823, 51.98248309642095]
            ]
          ],
          [
            [
              [-175.540099537095358, 52.183503064020627],
              [-175.482670833208317, 52.167180929899288],
              [-175.52215306713066, 52.156872213612132],
              [-175.540099537095358, 52.183503064020627]
            ]
          ],
          [
            [
              [-175.741100000699987, 51.967020021990209],
              [-175.723153530735289, 51.974751559205586],
              [-175.5795817710177, 51.972174380133794],
              [-175.575992477024755, 51.949838828178272],
              [-175.644189062890604, 51.952416007250065],
              [-175.723153530735289, 51.931798574675739],
              [-175.741100000699987, 51.967020021990209]
            ]
          ],
          [
            [
              [-175.952868346283452, 51.983342156111547],
              [-175.91338611236111, 51.995368991779912],
              [-175.812885880558781, 51.994509932089315],
              [-175.759046470664686, 51.917194559935595],
              [-175.816475174551726, 51.921489858388568],
              [-175.877493172431713, 51.963583783227818],
              [-175.952868346283452, 51.983342156111547]
            ]
          ],
          [
            [
              [-176.200529631796314, 52.078697781767815],
              [-176.150279515895136, 52.117355467844675],
              [-176.056957872078698, 52.109623930629297],
              [-175.974404110241096, 52.040899155381553],
              [-176.04618999009989, 52.008254887138861],
              [-175.992350580205795, 51.960147544465443],
              [-176.039011402113999, 51.930939514985141],
              [-176.103618693986931, 51.929221395603946],
              [-176.168225985859834, 51.948120708797077],
              [-176.157458103881027, 51.997087111161107],
              [-176.182583161831616, 51.998805230542303],
              [-176.211297513775122, 52.064952826718255],
              [-176.200529631796314, 52.078697781767815]
            ]
          ],
          [
            [
              [-176.236422571725711, 51.826134232732315],
              [-176.218476101761013, 51.874241575405748],
              [-176.175404573845725, 51.882832172311709],
              [-176.139511633916328, 51.859637560665604],
              [-176.071315048050479, 51.868228157571565],
              [-176.114386575965739, 51.887127470764696],
              [-175.992350580205795, 51.912899261482607],
              [-175.956457640276398, 51.893140888598879],
              [-175.96363622826226, 51.845892605616044],
              [-176.04618999009989, 51.845892605616044],
              [-175.999529168191657, 51.801221501705015],
              [-176.103618693986931, 51.826134232732315],
              [-176.09644010600104, 51.791771845108443],
              [-176.139511633916328, 51.802080561395599],
              [-176.157458103881027, 51.768577233462324],
              [-176.182583161831616, 51.807234919539184],
              [-176.236422571725711, 51.826134232732315]
            ]
          ],
          [
            [
              [-176.990174310243077, 51.629409563585625],
              [-176.950692076320763, 51.686966562855616],
              [-176.896852666426639, 51.700711517905177],
              [-176.871727608476078, 51.731637666766659],
              [-176.918388430384283, 51.788335606346052],
              [-176.90403125441253, 51.811530217992171],
              [-176.789173846638448, 51.817543635826354],
              [-176.764048788687859, 51.868228157571565],
              [-176.810709610596092, 51.927503276222751],
              [-176.774816670666695, 51.966160962299611],
              [-176.720977260772599, 51.969597201062001],
              [-176.656369968899668, 51.952416007250065],
              [-176.588173383033819, 52.003100528995276],
              [-176.555869737097368, 51.991073693326925],
              [-176.545101855118531, 51.927503276222751],
              [-176.616887734977325, 51.902590545195437],
              [-176.624066322963216, 51.859637560665604],
              [-176.577405501054983, 51.842456366853654],
              [-176.509208915189134, 51.845892605616044],
              [-176.397940801407998, 51.868228157571565],
              [-176.290261981619807, 51.872523456024553],
              [-176.265136923669218, 51.817543635826354],
              [-176.290261981619807, 51.741946383053829],
              [-176.344101391513902, 51.731637666766659],
              [-176.473315975259737, 51.7471007411974],
              [-176.519976797167971, 51.702429637286372],
              [-176.584584089040874, 51.692120920999201],
              [-176.631244910949107, 51.656899473684732],
              [-176.713798672786709, 51.683530324093226],
              [-176.735334436744353, 51.662053831828317],
              [-176.713798672786709, 51.620818966679664],
              [-176.753280906709051, 51.635422981419808],
              [-176.799941728617284, 51.613087429464287],
              [-176.814298904589037, 51.660335712447122],
              [-176.864549020490188, 51.685248443474421],
              [-176.932745606356036, 51.59246999688996],
              [-176.982995722257215, 51.601919653486533],
              [-176.990174310243077, 51.629409563585625]
            ]
          ],
          [
            [
              [-177.463961117311158, 51.913758321173205],
              [-177.417300295402953, 51.921489858388568],
              [-177.410121707417062, 51.899154306433061],
              [-177.460371823318212, 51.884550291692904],
              [-177.463961117311158, 51.913758321173205]
            ]
          ],
          [
            [
              [-177.704443814838129, 51.707583995429943],
              [-177.639836522965226, 51.735932965219646],
              [-177.55369346713465, 51.721328950479503],
              [-177.460371823318212, 51.75053697995979],
              [-177.280907123671227, 51.784040307893065],
              [-177.205531949819488, 51.820979874588744],
              [-177.198353361833597, 51.930939514985141],
              [-177.155281833918337, 51.944684470034687],
              [-177.097853130031297, 51.936093873128726],
              [-177.044013720137201, 51.898295246742464],
              [-177.137335363953639, 51.814107397063964],
              [-177.119388893988912, 51.740228263672634],
              [-177.144513951939501, 51.707583995429943],
              [-177.262960653706529, 51.680953145021448],
              [-177.392175237452363, 51.733355786147854],
              [-177.489086175261747, 51.70500681635815],
              [-177.632657934979335, 51.696416219452189],
              [-177.63624722897228, 51.659476652756524],
              [-177.672140168901677, 51.663771951209498],
              [-177.704443814838129, 51.707583995429943]
            ]
          ],
          [
            [
              [-178.224891443814414, 51.88025499323993],
              [-178.196177091870908, 51.90516772426723],
              [-178.092087566075634, 51.919771739007388],
              [-177.952105100350991, 51.9154764405544],
              [-177.912622866428649, 51.879395933549333],
              [-177.919801454414539, 51.853624142831421],
              [-177.851604868548662, 51.826134232732315],
              [-177.797765458654567, 51.840738247472473],
              [-177.733158166781664, 51.826134232732315],
              [-177.614711465014636, 51.855342262212616],
              [-177.650604404944033, 51.820979874588744],
              [-177.797765458654567, 51.793489964489638],
              [-177.840836986569855, 51.732496726457256],
              [-177.826479810598101, 51.705865876048748],
              [-177.869551338513361, 51.679235025640253],
              [-177.898265690456896, 51.692979980689799],
              [-177.930569336393347, 51.655181354303537],
              [-177.901854984449841, 51.606214951939506],
              [-177.930569336393347, 51.601919653486533],
              [-177.962872982329799, 51.650026996159951],
              [-178.013123098230977, 51.629409563585625],
              [-178.070551802118018, 51.670644428734278],
              [-178.117212624026223, 51.677516906259058],
              [-178.10285544805447, 51.701570577595774],
              [-177.955694394343936, 51.723047069860684],
              [-177.966462276322744, 51.778026890058896],
              [-178.038248156181538, 51.778885949749494],
              [-178.084908978089771, 51.808093979229781],
              [-178.224891443814414, 51.864791918809175],
              [-178.224891443814414, 51.88025499323993]
            ]
          ],
          [
            [
              [-178.680731780917796, 51.608792131011299],
              [-178.565874373143714, 51.598483414724143],
              [-178.57664225512255, 51.583020340293388],
              [-178.673553192931905, 51.58903375812757],
              [-178.680731780917796, 51.608792131011299]
            ]
          ],
          [
            [
              [-178.856607186571864, 51.577006922459219],
              [-178.759696248762481, 51.562402907719076],
              [-178.734571190811891, 51.542644534835347],
              [-178.824303540635384, 51.54693983328832],
              [-178.856607186571864, 51.577006922459219]
            ]
          ],
          [
            [
              [-178.870964362543617, 51.795208083870833],
              [-178.820714246642439, 51.839879187781875],
              [-178.748928366783645, 51.809812098610976],
              [-178.748928366783645, 51.75740945748457],
              [-178.817124952649522, 51.748818860578595],
              [-178.870964362543617, 51.795208083870833]
            ]
          ],
          [
            [
              [-178.993000358303561, 51.381141313003127],
              [-178.964286006360055, 51.402617805268051],
              [-178.928393066430658, 51.383718492074919],
              [-178.91044659646596, 51.340765507545072],
              [-178.953518124381219, 51.339047388163877],
              [-178.989411064310616, 51.311557478064771],
              [-178.993000358303561, 51.381141313003127]
            ]
          ],
          [
            [
              [-179.136572118021178, 51.285785687346859],
              [-179.09350059010589, 51.301248761777615],
              [-179.071964826148246, 51.250564240032389],
              [-179.136572118021178, 51.229087747767466],
              [-179.136572118021178, 51.285785687346859]
            ]
          ],
          [
            [
              [179.484147892578903, 51.98248309642095],
              [179.527219420494191, 51.980764977039769],
              [179.570290948409479, 52.010832066210654],
              [179.663612592225888, 52.022858901879005],
              [179.774880706007025, 51.970456260752599],
              [179.742577060070573, 51.91204020179201],
              [179.656434004239998, 51.875959694786943],
              [179.61336247632471, 51.871664396333955],
              [179.523630126501246, 51.896577127361269],
              [179.484147892578903, 51.921489858388568],
              [179.484147892578903, 51.98248309642095]
            ]
          ],
          [
            [
              [178.626306628266263, 51.637141100801003],
              [178.644253098230934, 51.657758533375329],
              [178.7375747420474, 51.632845802348015],
              [178.805771327913249, 51.635422981419808],
              [178.902682265722603, 51.614805548845482],
              [178.917039441694385, 51.588174698436973],
              [179.003182497524961, 51.552094191431905],
              [179.100093435334315, 51.485087535565341],
              [179.189825785157836, 51.462751983609834],
              [179.229308019080179, 51.413785581245804],
              [179.387236954769492, 51.404335924649232],
              [179.462612128621231, 51.375986954859542],
              [179.398004836748328, 51.359664820738203],
              [179.365701190811876, 51.371691656406554],
              [179.26161166501663, 51.357946701357008],
              [179.207772255122507, 51.393168148671478],
              [179.060611201411973, 51.455020446394457],
              [178.952932381623782, 51.541785475144749],
              [178.845253561835591, 51.578725041840414],
              [178.784235563955633, 51.571852564315634],
              [178.705271096110948, 51.593329056580558],
              [178.626306628266263, 51.637141100801003]
            ]
          ],
          [
            [
              [178.446841928619278, 51.978187797967976],
              [178.47914557455573, 51.987637454564535],
              [178.550931454414524, 51.973892499514989],
              [178.590413688336866, 51.945543529725285],
              [178.540163572435688, 51.903449604886035],
              [178.504270632506291, 51.900013366123659],
              [178.446841928619278, 51.978187797967976]
            ]
          ],
          [
            [
              [178.23866287702873, 51.82785235211351],
              [178.306859462894579, 51.821838934279342],
              [178.382234636746318, 51.764281935009336],
              [178.303270168901633, 51.777167830368299],
              [178.23866287702873, 51.82785235211351]
            ]
          ],
          [
            [
              [178.095091117311142, 52.033167618166175],
              [178.120216175261703, 52.052066931359306],
              [178.181234173141718, 52.033167618166175],
              [178.192002055120497, 52.003959588685873],
              [178.130984057240539, 51.986778394873937],
              [178.095091117311142, 52.033167618166175]
            ]
          ],
          [
            [
              [177.204946207062051, 51.896577127361269],
              [177.291089262892626, 51.919771739007388],
              [177.344928672786693, 51.962724723537221],
              [177.484911138511336, 51.985060275492742],
              [177.506446902469008, 52.039181036000357],
              [177.563875606356021, 52.121650766297648],
              [177.603357840278363, 52.137113840728404],
              [177.675143720137157, 52.092442736817361],
              [177.632072192221869, 52.064952826718255],
              [177.603357840278363, 52.015986424354239],
              [177.531571960419569, 51.970456260752599],
              [177.606947134271309, 51.954993186321857],
              [177.606947134271309, 51.924926097150959],
              [177.560286312363075, 51.916335500244998],
              [177.409535964659597, 51.930939514985141],
              [177.348517966779639, 51.904308664576632],
              [177.334160790807857, 51.844174486234849],
              [177.294678556885515, 51.845892605616044],
              [177.204946207062051, 51.88025499323993],
              [177.204946207062051, 51.896577127361269]
            ]
          ],
          [
            [
              [175.873318135681302, 52.370778076570758],
              [175.966639779497768, 52.35961030059299],
              [175.902032487624865, 52.336415688946886],
              [175.873318135681302, 52.370778076570758]
            ]
          ],
          [
            [
              [174.067903257232558, 52.73501938538385],
              [174.146867725077243, 52.72814690785907],
              [174.157635607056022, 52.705811355903549],
              [174.071492551225504, 52.718697251262498],
              [174.067903257232558, 52.73501938538385]
            ]
          ],
          [
            [
              [173.953045849458476, 52.751341519505189],
              [174.003295965359598, 52.744469041980409],
              [174.003295965359598, 52.719556310953095],
              [173.953045849458476, 52.751341519505189]
            ]
          ],
          [
            [
              [173.866902793627901, 52.775395190841905],
              [173.938688673486695, 52.752200579195787],
              [173.895617145571407, 52.750482459814592],
              [173.866902793627901, 52.775395190841905]
            ]
          ],
          [
            [
              [173.357223046630452, 52.405140464194631],
              [173.378758810588067, 52.431771314603139],
              [173.446955396453916, 52.454965926249258],
              [173.529509158291546, 52.449811568105673],
              [173.622830802108012, 52.507368567375664],
              [173.769991855818546, 52.511663865828652],
              [173.708973857938531, 52.47730147820478],
              [173.691027387973861, 52.445516269652686],
              [173.726920327903258, 52.356174061830615],
              [173.651545154051519, 52.356174061830615],
              [173.590527156171561, 52.400845165741643],
              [173.464901866418643, 52.384523031620304],
              [173.357223046630452, 52.405140464194631]
            ]
          ],
          [
            [
              [172.45989954839547, 52.927448756077553],
              [172.628596366063618, 53.001327889468882],
              [172.747043067830646, 53.010777546065455],
              [173.105972467124616, 52.993596352253519],
              [173.210061992919918, 52.939475591745904],
              [173.296205048750437, 52.926589696386955],
              [173.421830338503355, 52.845838085470845],
              [173.429008926489246, 52.830375011040104],
              [173.303383636736328, 52.823502533515324],
              [173.228008462884588, 52.856146801758015],
              [173.166990465004631, 52.795153563725634],
              [173.134686819068179, 52.783985787747866],
              [172.998293647336425, 52.796871683106829],
              [172.904972003520015, 52.761650235792345],
              [172.808061065710604, 52.789140145891452],
              [172.764989537795373, 52.823502533515324],
              [172.754221655816536, 52.877623294022925],
              [172.639364248042455, 52.925730636696358],
              [172.513738958289537, 52.905113204122031],
              [172.45989954839547, 52.927448756077553]
            ]
          ],
          [
            [
              [-131.241033076230764, 55.092279176381751],
              [-131.208729430294284, 55.11203754926548],
              [-131.172836490364887, 55.087983877928764],
              [-131.190782960329585, 55.043312774017735],
              [-131.248211664216626, 55.072520803498023],
              [-131.241033076230764, 55.092279176381751]
            ]
          ],
          [
            [
              [-131.485105067750681, 54.95053432743326],
              [-131.266158134181325, 54.998641670106693],
              [-131.241033076230764, 54.929916894858934],
              [-131.194372254322531, 54.917890059190583],
              [-131.266158134181325, 54.859474000229994],
              [-131.3523011900119, 54.858614940539397],
              [-131.470747891778899, 54.912735701046998],
              [-131.485105067750681, 54.95053432743326]
            ]
          ],
          [
            [
              [-131.621498239482378, 54.946239028980287],
              [-131.52817659566594, 55.002077908869083],
              [-131.499462243722434, 54.980601416604159],
              [-131.592783887538872, 54.930775954549532],
              [-131.621498239482378, 54.946239028980287]
            ]
          ],
          [
            [
              [-131.646623297432967, 55.035581236802358],
              [-131.589194593545926, 55.088842937619361],
              [-131.596373181531817, 55.12578250431504],
              [-131.549712359623584, 55.142963698126977],
              [-131.599962475524762, 55.179903264822642],
              [-131.607141063510625, 55.240037443164425],
              [-131.589194593545926, 55.2735407710977],
              [-131.549712359623584, 55.281272308313078],
              [-131.481515773757735, 55.253782398213971],
              [-131.463569303793037, 55.223715309043087],
              [-131.416908481884803, 55.217701891208904],
              [-131.355890484004817, 55.183339503585032],
              [-131.355890484004817, 55.04159465463654],
              [-131.388194129941297, 55.012386625156239],
              [-131.485105067750681, 55.010668505775044],
              [-131.531765889658885, 55.03815841587415],
              [-131.614319651496515, 55.007232267012668],
              [-131.646623297432967, 55.035581236802358]
            ]
          ],
          [
            [
              [-131.86915952499524, 55.36460109830098],
              [-131.854802349023487, 55.421299037880374],
              [-131.833266585065843, 55.422158097570971],
              [-131.646623297432967, 55.306185039340392],
              [-131.689694825348255, 55.282131368003675],
              [-131.693284119341172, 55.223715309043087],
              [-131.747123529235296, 55.128359683386819],
              [-131.829677291072898, 55.191930100490993],
              [-131.861980937009378, 55.289003845528441],
              [-131.86915952499524, 55.36460109830098]
            ]
          ],
          [
            [
              [-132.490107385773854, 56.503714248032466],
              [-132.475750209802072, 56.544949113181119],
              [-132.443446563865621, 56.519177322463221],
              [-132.490107385773854, 56.503714248032466]
            ]
          ],
          [
            [
              [-132.561893265632648, 56.379150592895925],
              [-132.554714677646757, 56.404063323923225],
              [-132.479339503795018, 56.438425711547112],
              [-132.403964329943278, 56.40578144330442],
              [-132.407553623936224, 56.386882130111289],
              [-132.493696679766799, 56.355096921559209],
              [-132.561893265632648, 56.379150592895925]
            ]
          ],
          [
            [
              [-132.65162561545614, 56.48309681545814],
              [-132.579839735597346, 56.511445785247844],
              [-132.51882173771736, 56.556116889158886],
              [-132.536768207682059, 56.513163904629039],
              [-132.60137549955499, 56.448734427834268],
              [-132.65162561545614, 56.48309681545814]
            ]
          ],
          [
            [
              [-132.888519018990166, 54.974587998769977],
              [-132.823911727117263, 54.979742356913562],
              [-132.838268903089016, 54.934212193311922],
              [-132.888519018990166, 54.974587998769977]
            ]
          ],
          [
            [
              [-133.024912190721892, 56.229674206732057],
              [-132.999787132771303, 56.275204370333697],
              [-132.963894192841906, 56.25458693775937],
              [-132.884929724997249, 56.171258147771468],
              [-132.849036785067852, 56.112842088810879],
              [-132.895697606976057, 56.099956193451931],
              [-132.971072780827797, 56.152358834578337],
              [-133.003376426764248, 56.155795073340727],
              [-133.024912190721892, 56.229674206732057]
            ]
          ],
          [
            [
              [-133.06798371863718, 56.356815040940404],
              [-133.003376426764248, 56.430694174331734],
              [-132.928001252912509, 56.456465965049645],
              [-132.820322433124318, 56.439284771237709],
              [-132.734179377293771, 56.45818408443084],
              [-132.633679145491442, 56.422103577425759],
              [-132.619321969519689, 56.391177428564276],
              [-132.68392926139262, 56.345647264962636],
              [-132.662393497434948, 56.275204370333697],
              [-132.719822201322017, 56.258882236212358],
              [-132.877751137011359, 56.239982923019227],
              [-132.960304898848989, 56.295821802908023],
              [-133.046447954679536, 56.320734533935337],
              [-133.06798371863718, 56.356815040940404]
            ]
          ],
          [
            [
              [-133.319234298142987, 55.426453396023945],
              [-133.243859124291248, 55.449648007670064],
              [-133.25103771227711, 55.410131261902606],
              [-133.308466416164151, 55.400681605306048],
              [-133.319234298142987, 55.426453396023945]
            ]
          ],
          [
            [
              [-133.563306289662876, 54.868923656826553],
              [-133.531002643726424, 54.868923656826553],
              [-133.495109703797027, 54.758964016430156],
              [-133.548949113691123, 54.783876747457469],
              [-133.563306289662876, 54.868923656826553]
            ]
          ],
          [
            [
              [-133.656627933479342, 55.625755244242427],
              [-133.624324287542862, 55.645513617126156],
              [-133.620734993549917, 55.697916258252576],
              [-133.548949113691123, 55.672144467534665],
              [-133.545359819698206, 55.644654557435572],
              [-133.624324287542862, 55.582802259712594],
              [-133.656627933479342, 55.625755244242427]
            ]
          ],
          [
            [
              [-133.710467343373409, 55.674721646606457],
              [-133.66739581545815, 55.682453183821835],
              [-133.670985109451095, 55.651527034960338],
              [-133.710467343373409, 55.674721646606457]
            ]
          ],
          [
            [
              [-133.789431811218094, 55.458238604576039],
              [-133.760717459274588, 55.537272096110954],
              [-133.732003107331082, 55.558748588375877],
              [-133.617145699557, 55.5510170511605],
              [-133.581252759627603, 55.537272096110954],
              [-133.588431347613465, 55.507205006940055],
              [-133.645860051500506, 55.471124499934987],
              [-133.617145699557, 55.457379544885441],
              [-133.545359819698206, 55.491741932509314],
              [-133.527413349733479, 55.528681499204978],
              [-133.480752527825274, 55.51235936508364],
              [-133.412555941959397, 55.570775424044228],
              [-133.344359356093548, 55.569057304663033],
              [-133.286930652206507, 55.535553976729759],
              [-133.304877122171206, 55.48401039529395],
              [-133.358716532065301, 55.453943306123051],
              [-133.426913117931178, 55.466829201482],
              [-133.473573939839383, 55.436762112311115],
              [-133.412555941959397, 55.417862799117984],
              [-133.419734529945288, 55.38607759056589],
              [-133.498698997789973, 55.368037337063356],
              [-133.577663465634657, 55.325084352533523],
              [-133.609967111571109, 55.24175556254562],
              [-133.692520873408739, 55.30876221841217],
              [-133.631502875528753, 55.36116485953859],
              [-133.63509216952167, 55.42817151540514],
              [-133.739181695316944, 55.471983559625585],
              [-133.789431811218094, 55.458238604576039]
            ]
          ],
          [
            [
              [-133.818146163161629, 55.964224762337608],
              [-133.778663929239286, 55.983124075530753],
              [-133.692520873408739, 56.070748163971629],
              [-133.59202064160641, 56.079338760877604],
              [-133.552538407684068, 56.096519954689541],
              [-133.631502875528753, 56.107687730667294],
              [-133.63509216952167, 56.148063536125349],
              [-133.581252759627603, 56.129164222932218],
              [-133.548949113691123, 56.158372252412519],
              [-133.678163697436958, 56.211633953229523],
              [-133.627913581535807, 56.236546684256837],
              [-133.663806521465204, 56.310425817648166],
              [-133.581252759627603, 56.352519742487416],
              [-133.419734529945288, 56.33190230991309],
              [-133.164894656446563, 56.317298295172947],
              [-133.078751600615988, 56.246855400543993],
              [-133.039269366693645, 56.184144043130416],
              [-133.057215836658372, 56.12486892447923],
              [-133.006965720757194, 56.119714566335659],
              [-132.960304898848989, 56.061298507375071],
              [-132.899286900969003, 56.020063642226418],
              [-132.838268903089016, 56.024358940679392],
              [-132.633679145491442, 55.922130837498372],
              [-132.590607617576154, 55.87745973358733],
              [-132.472160915809155, 55.782104107931076],
              [-132.461393033830319, 55.67386258691586],
              [-132.357303508035073, 55.648949855888546],
              [-132.328589156091539, 55.578506961259606],
              [-132.299874804148033, 55.5510170511605],
              [-132.188606690366896, 55.508064066630652],
              [-132.141945868458663, 55.458238604576039],
              [-132.17783880838806, 55.453084246432454],
              [-132.246035394253937, 55.492600992199911],
              [-132.515232443724415, 55.576788841878411],
              [-132.526000325703251, 55.550157991469902],
              [-132.608554087540881, 55.486587574365728],
              [-132.569071853618539, 55.479715096840962],
              [-132.51882173771736, 55.520090902299017],
              [-132.490107385773854, 55.503768768177679],
              [-132.407553623936224, 55.51235936508364],
              [-132.389607153971525, 55.48057415653156],
              [-132.317821274112731, 55.469406380553792],
              [-132.281928334183334, 55.444493649526493],
              [-132.41832150591506, 55.432466813858127],
              [-132.389607153971525, 55.401540664996645],
              [-132.274749746197443, 55.419580918499179],
              [-132.224499630296293, 55.374909814588136],
              [-132.167070926409252, 55.362882978919785],
              [-132.12758869248691, 55.288144785837844],
              [-132.098874340543404, 55.28041324862248],
              [-132.141945868458663, 55.23831932378323],
              [-132.213731748317457, 55.246050860998608],
              [-132.206553160331595, 55.218560950899501],
              [-132.12758869248691, 55.19966163770637],
              [-132.088106458564567, 55.20653411523115],
              [-132.07733857658576, 55.246050860998608],
              [-132.027088460684581, 55.27697700986009],
              [-131.99478481474813, 55.258936756357556],
              [-131.976838344783431, 55.180762324513239],
              [-132.030677754677527, 55.151554295032938],
              [-132.016320578705773, 55.120628146171455],
              [-132.095285046550458, 55.039876535255345],
              [-132.199374572345732, 55.005514147631473],
              [-132.134767280472801, 54.985755774747744],
              [-132.037856342663417, 55.036440296492955],
              [-131.984016932769322, 55.02784969958698],
              [-131.984016932769322, 54.853460582395812],
              [-131.958891874818733, 54.791608284672833],
              [-132.030677754677527, 54.701407017160165],
              [-132.163481632416307, 54.693675479944787],
              [-132.256803276232745, 54.734910345093439],
              [-132.307053392133923, 54.718588210972101],
              [-132.368071390013881, 54.751232479214778],
              [-132.371660684006827, 54.783017687766872],
              [-132.508053855738552, 54.781299568385677],
              [-132.447035857858566, 54.826829731987317],
              [-132.350124920049183, 54.818239135081342],
              [-132.346535626056266, 54.851742463014617],
              [-132.414732211922114, 54.887822970019698],
              [-132.482928797787963, 54.898990745997452],
              [-132.608554087540881, 54.965997401864016],
              [-132.576250441604401, 54.998641670106693],
              [-132.540357501675004, 55.097433534525337],
              [-132.594196911569099, 55.105165071740714],
              [-132.633679145491442, 55.055339609686087],
              [-132.608554087540881, 55.142104638436379],
              [-132.579839735597346, 55.166158309773095],
              [-132.619321969519689, 55.19966163770637],
              [-132.637268439484387, 55.152413354723535],
              [-132.734179377293771, 55.134373101221001],
              [-132.68392926139262, 55.045889953089528],
              [-132.748536553265524, 54.9960644910349],
              [-132.917233370933701, 55.044171833708333],
              [-132.931590546905454, 55.074238922879218],
              [-132.870572549025496, 55.124064384933845],
              [-132.9351798408984, 55.209970353993526],
              [-133.006965720757194, 55.205675055540553],
              [-133.042858660686591, 55.248628040070386],
              [-133.11823383453833, 55.251205219142179],
              [-133.075162306623042, 55.184198563275629],
              [-133.010555014750139, 55.128359683386819],
              [-132.989019250792495, 55.066507385663854],
              [-133.014144308743084, 55.035581236802358],
              [-132.960304898848989, 55.020977222062214],
              [-132.888519018990166, 54.896413566925659],
              [-132.802375963159619, 54.874078014970138],
              [-132.816733139131372, 54.923903477024766],
              [-132.730590083300825, 54.939366551455507],
              [-132.630089851498497, 54.883527671566711],
              [-132.615732675526743, 54.777004269932689],
              [-132.665982791427894, 54.763259314883143],
              [-132.730590083300825, 54.826829731987317],
              [-132.755715141251414, 54.820816314153134],
              [-132.673161379413784, 54.674776166751656],
              [-132.77725090520903, 54.674776166751656],
              [-132.870572549025496, 54.702266076850762],
              [-132.877751137011359, 54.753809658286571],
              [-132.949537016870153, 54.788172045910457],
              [-133.125412422524221, 54.940225611146104],
              [-133.161305362453618, 54.959124924339235],
              [-133.168483950439509, 55.03128593834937],
              [-133.211555478354768, 55.039876535255345],
              [-133.240269830298303, 55.092279176381751],
              [-133.136180304503029, 55.099151653906532],
              [-133.233091242312412, 55.127500623696221],
              [-133.222323360333604, 55.229728726877255],
              [-133.340770062100603, 55.205675055540553],
              [-133.37666300203, 55.22801060749606],
              [-133.441270293902932, 55.211688473374721],
              [-133.473573939839383, 55.247768980379789],
              [-133.452038175881739, 55.319929994389938],
              [-133.333591474114741, 55.345701785107849],
              [-133.294109240192398, 55.291581024600234],
              [-133.233091242312412, 55.30876221841217],
              [-133.279752064220645, 55.333674949439484],
              [-133.258216300263001, 55.367178277372759],
              [-133.207966184361851, 55.384359471184709],
              [-133.157716068460672, 55.372332635516344],
              [-133.078751600615988, 55.410131261902606],
              [-133.182841126411262, 55.492600992199911],
              [-133.132591010510112, 55.502909708487081],
              [-133.190019714397124, 55.530399618586173],
              [-133.175662538425371, 55.587097558165567],
              [-133.261805594255918, 55.569057304663033],
              [-133.437680999909986, 55.643795497744975],
              [-133.452038175881739, 55.667849169081677],
              [-133.394609471994727, 55.666990109391079],
              [-133.426913117931178, 55.709943093920927],
              [-133.416145235952342, 55.740010183091826],
              [-133.513056173761726, 55.755473257522567],
              [-133.491520409804082, 55.706506855158537],
              [-133.527413349733479, 55.695339079180783],
              [-133.642270757507561, 55.728842407114058],
              [-133.699699461394601, 55.778667869168686],
              [-133.656627933479342, 55.823338973079728],
              [-133.556127701677013, 55.83536580874808],
              [-133.423323823938233, 55.788976585455842],
              [-133.322823592135904, 55.819043674626741],
              [-133.383841590015891, 55.876600673896732],
              [-133.448448881888794, 55.890345628946278],
              [-133.491520409804082, 55.873164435134342],
              [-133.523824055740533, 55.887768449874486],
              [-133.495109703797027, 55.913540240592397],
              [-133.502288291782918, 55.949620747597464],
              [-133.477163233832329, 56.003741508105065],
              [-133.495109703797027, 56.017486463154626],
              [-133.54177052570526, 55.977110657696571],
              [-133.638681463514615, 55.921271777807775],
              [-133.7176459313593, 55.892922808018071],
              [-133.80019969319693, 55.925567076260748],
              [-133.818146163161629, 55.964224762337608]
            ]
          ],
          [
            [
              [-133.882753455034532, 57.300921640906395],
              [-133.828914045140436, 57.322398133171319],
              [-133.778663929239286, 57.294049163381629],
              [-133.87557486704867, 57.26741831297312],
              [-133.882753455034532, 57.300921640906395]
            ]
          ],
          [
            [
              [-133.943771452914518, 55.912681180901799],
              [-133.893521337013368, 55.936734852238516],
              [-133.854039103091026, 55.931580494094931],
              [-133.839681927119273, 55.886909390183888],
              [-133.868396279062779, 55.845674525035236],
              [-133.904289218992176, 55.848251704107028],
              [-133.943771452914518, 55.912681180901799]
            ]
          ],
          [
            [
              [-134.367308144081449, 55.910963061520604],
              [-134.26680791227912, 55.929862374713736],
              [-134.263218618286174, 55.892922808018071],
              [-134.151950504505038, 55.921271777807775],
              [-134.123236152561503, 55.985701254602532],
              [-134.098111094610942, 55.983983135221337],
              [-134.130414740547394, 55.897218106471058],
              [-134.209379208392079, 55.876600673896732],
              [-134.281165088250873, 55.828493331223299],
              [-134.345772380123776, 55.843097345963457],
              [-134.31705802818027, 55.868869136681354],
              [-134.367308144081449, 55.910963061520604]
            ]
          ],
          [
            [
              [-134.413968965989653, 56.84819718396183],
              [-134.352950968109667, 56.892868287872872],
              [-134.295522264222626, 56.907472302613016],
              [-134.270397206272037, 56.93582127240272],
              [-134.151950504505038, 56.934962212712122],
              [-134.148361210512093, 56.957297764667643],
              [-134.047860978709764, 56.922935377043757],
              [-134.008378744787422, 56.85163342272422],
              [-133.943771452914518, 56.805244199431982],
              [-133.832503339133382, 56.803526080050787],
              [-133.80019969319693, 56.785485826548253],
              [-133.764306753267533, 56.807821378503775],
              [-133.868396279062779, 56.845620004890037],
              [-133.915057100971012, 56.914344780137796],
              [-133.922235688956874, 56.961593063120617],
              [-134.047860978709764, 57.029458778677778],
              [-134.008378744787422, 57.074988942279418],
              [-133.886342749027477, 57.09732449423494],
              [-133.703288755387547, 57.065539285682846],
              [-133.613556405564054, 57.057807748467482],
              [-133.444859587895877, 57.020009122081206],
              [-133.333591474114741, 57.002827928269269],
              [-133.308466416164151, 57.014854763937635],
              [-133.103876658566577, 57.005405107341062],
              [-132.996197838778386, 56.941834690236888],
              [-132.945947722877236, 56.871391795607948],
              [-132.9351798408984, 56.823284452934516],
              [-132.902876194961948, 56.803526080050787],
              [-132.820322433124318, 56.794076423454229],
              [-132.762893729237277, 56.752841558305576],
              [-132.744947259272578, 56.713324812538119],
              [-132.626500557505551, 56.668653708627076],
              [-132.594196911569099, 56.613673888428877],
              [-132.540357501675004, 56.584465858948576],
              [-132.65162561545614, 56.551821590705899],
              [-132.723411495314934, 56.515741083700831],
              [-132.816733139131372, 56.495123651126505],
              [-132.874161843018413, 56.508868606176051],
              [-132.98542995679955, 56.512304844938441],
              [-133.139769598495974, 56.532922277512768],
              [-133.121823128531275, 56.491687412364115],
              [-133.204376890368906, 56.44787536814367],
              [-133.347948650086494, 56.469351860408594],
              [-133.416145235952342, 56.494264591435908],
              [-133.45921676386763, 56.453888785977853],
              [-133.513056173761726, 56.436707592165916],
              [-133.602788523585218, 56.434989472784721],
              [-133.663806521465204, 56.44787536814367],
              [-133.674574403444012, 56.522613561225597],
              [-133.714056637366355, 56.550103471324704],
              [-133.821735457154546, 56.495123651126505],
              [-133.775074635246341, 56.470210920099191],
              [-133.782253223232232, 56.392895547945471],
              [-133.864806985069833, 56.390318368873679],
              [-133.871985573055724, 56.36368751846517],
              [-133.836092633126327, 56.319875474244739],
              [-133.87557486704867, 56.276063430024294],
              [-133.882753455034532, 56.222801729207276],
              [-133.943771452914518, 56.179848744677443],
              [-133.929414276942765, 56.146345416744154],
              [-133.961717922879217, 56.091365596545955],
              [-133.997610862808614, 56.081915939949397],
              [-134.087343212632106, 56.094801835308346],
              [-134.137593328533285, 56.006318687176858],
              [-134.19143273842738, 56.022640821298211],
              [-134.23450426634264, 56.067311925209239],
              [-134.259629324293229, 56.121432685716854],
              [-134.263218618286174, 56.215070191991913],
              [-134.299111558215571, 56.28980838507384],
              [-134.291932970229709, 56.354237861868612],
              [-134.241682854328531, 56.395472727017264],
              [-134.252450736307367, 56.44443912938128],
              [-134.198611326413243, 56.531204158131573],
              [-134.241682854328531, 56.555257829468289],
              [-134.320647322173215, 56.554398769777691],
              [-134.302700852208517, 56.620546365953658],
              [-134.374486732067311, 56.668653708627076],
              [-134.403201084010846, 56.733083185421847],
              [-134.396022496024955, 56.770022752117512],
              [-134.413968965989653, 56.84819718396183]
            ]
          ],
          [
            [
              [-134.959541652916528, 58.403954283632828],
              [-134.805202011220103, 58.321484553335523],
              [-134.780076953269514, 58.281108747877468],
              [-134.729826837368364, 58.27337721066209],
              [-134.701112485424858, 58.161699450884498],
              [-134.632915899558981, 58.162558510575096],
              [-134.557540725707241, 58.195202778817773],
              [-134.518058491784899, 58.175444405934044],
              [-134.446272611926105, 58.173726286552849],
              [-134.370897438074365, 58.14881355552555],
              [-134.177075562455627, 58.159981331503303],
              [-134.187843444434435, 58.083525019040181],
              [-134.098111094610942, 58.01909554224541],
              [-134.090932506625052, 57.974424438334381],
              [-134.015557332773312, 57.929753334423339],
              [-133.965307216872162, 57.855015141341411],
              [-133.904289218992176, 57.807766858358576],
              [-133.897110631006285, 57.685780382293814],
              [-133.821735457154546, 57.634236800858005],
              [-133.807378281182793, 57.578397920969195],
              [-133.839681927119273, 57.576679801588],
              [-133.857628397083971, 57.617055607046055],
              [-133.994021568815697, 57.720142769917686],
              [-134.029914508745094, 57.81893463433633],
              [-134.098111094610942, 57.850719842888424],
              [-134.130414740547394, 57.89625000649005],
              [-134.205789914399134, 57.89625000649005],
              [-134.112468270582696, 57.809484977739771],
              [-134.098111094610942, 57.78027694825947],
              [-134.144771916519147, 57.760518575375741],
              [-134.019146626766258, 57.656572352813512],
              [-133.936592864928627, 57.614478427974277],
              [-133.943771452914518, 57.561216727157259],
              [-133.857628397083971, 57.46414298211981],
              [-133.92582498294982, 57.469297340263395],
              [-133.871985573055724, 57.381673251822505],
              [-133.871985573055724, 57.358478640176386],
              [-133.961717922879217, 57.305216939359383],
              [-134.033503802738011, 57.327552491314904],
              [-134.094521800617997, 57.326693431624307],
              [-134.080164624646244, 57.297485402144019],
              [-134.155539798497983, 57.208143194321934],
              [-134.291932970229709, 57.136841240002397],
              [-134.378076026060256, 57.115364747737473],
              [-134.385254614046119, 57.087015777947769],
              [-134.496522727827255, 57.031176898058973],
              [-134.600612253622529, 57.033754077130766],
              [-134.632915899558981, 57.110210389593888],
              [-134.647273075530734, 57.226183447824468],
              [-134.571897901678994, 57.294908223072227],
              [-134.518058491784899, 57.314666595955956],
              [-134.57548719567194, 57.34129744636445],
              [-134.579076489664885, 57.400572565015636],
              [-134.528826373763735, 57.405726923159222],
              [-134.485754845848447, 57.372223595225947],
              [-134.464219081890803, 57.391981968109675],
              [-134.607790841608391, 57.51310938448384],
              [-134.611380135601337, 57.562934846538454],
              [-134.675987427474269, 57.614478427974277],
              [-134.693933897438967, 57.684921322603216],
              [-134.733416131361309, 57.721860889298881],
              [-134.704701779417775, 57.8292433506235],
              [-134.747773307333063, 57.898827185561842],
              [-134.75854118931187, 57.98043785616855],
              [-134.798023423234213, 58.058612288012867],
              [-134.78366624726246, 58.096410914399129],
              [-134.855452127121254, 58.176303465624642],
              [-134.91647012500124, 58.214961151701502],
              [-134.948773770937692, 58.281108747877468],
              [-134.970309534895335, 58.367873776627761],
              [-134.959541652916528, 58.403954283632828]
            ]
          ],
          [
            [
              [-135.027738238782376, 58.519927341863408],
              [-134.902112949029487, 58.481269655786548],
              [-134.805202011220103, 58.373887194461929],
              [-134.841094951149501, 58.369591896008956],
              [-134.920059418994185, 58.468383760427599],
              [-135.002613180831815, 58.484705894548938],
              [-135.027738238782376, 58.519927341863408]
            ]
          ],
          [
            [
              [-135.350774698146978, 59.019900081790809],
              [-135.296935288252882, 58.958906843758427],
              [-135.296935288252882, 58.91939009799097],
              [-135.332828228182279, 58.944302829018284],
              [-135.350774698146978, 59.019900081790809]
            ]
          ],
          [
            [
              [-135.695346921469195, 57.362773938629374],
              [-135.637918217582182, 57.372223595225947],
              [-135.634328923589237, 57.394559147181468],
              [-135.533828691786908, 57.450398027070264],
              [-135.548185867758662, 57.508814086030853],
              [-135.418971284012827, 57.563793906229051],
              [-135.354363992139895, 57.553485189941895],
              [-135.264631642316431, 57.480465116241149],
              [-135.210792232422307, 57.489055713147124],
              [-135.146184940549404, 57.439230251092496],
              [-135.063631178711773, 57.41861281851817],
              [-134.927238006980076, 57.42204905728056],
              [-134.848273539135391, 57.410022221612209],
              [-134.805202011220103, 57.342156506055048],
              [-134.812380599205994, 57.300062581215798],
              [-134.884166479064788, 57.330129670386697],
              [-134.952363064930637, 57.328411551005502],
              [-134.955952358923582, 57.30951223781237],
              [-134.880577185071843, 57.288035745547447],
              [-134.841094951149501, 57.248518999779989],
              [-134.737005425354255, 57.00970040579405],
              [-134.7405947193472, 56.948707167761668],
              [-134.697523191431912, 56.900599825088236],
              [-134.697523191431912, 56.850774363033622],
              [-134.632915899558981, 56.72878788696886],
              [-134.614969429594282, 56.636868500074996],
              [-134.632915899558981, 56.540653814728145],
              [-134.672398133481323, 56.541512874418729],
              [-134.643683781537788, 56.471929039480386],
              [-134.632915899558981, 56.265754713737124],
              [-134.665219545495461, 56.17039908808087],
              [-134.704701779417775, 56.175553446224455],
              [-134.805202011220103, 56.235687624566239],
              [-134.841094951149501, 56.309566757957569],
              [-134.91647012500124, 56.360251279702794],
              [-135.060041884718828, 56.542371934109326],
              [-135.045684708747075, 56.583606799257979],
              [-134.96672024090239, 56.603365172141707],
              [-135.031327532775322, 56.629136962859619],
              [-135.12464917659176, 56.60250611245111],
              [-135.17489929249291, 56.678103365223649],
              [-135.214381526415252, 56.665217469864686],
              [-135.275399524295239, 56.701297976869753],
              [-135.318471052210498, 56.748546259852588],
              [-135.311292464224636, 56.77775428933289],
              [-135.361542580125786, 56.758854976139759],
              [-135.397435520055183, 56.779472408714085],
              [-135.505114339843374, 56.781190528095266],
              [-135.573310925709251, 56.863660258392571],
              [-135.505114339843374, 56.866237437464363],
              [-135.469221399913977, 56.841324706437049],
              [-135.43332845998458, 56.852492482414817],
              [-135.444096341963416, 56.942693749927486],
              [-135.375899756097567, 56.988223913529126],
              [-135.354363992139895, 57.020868181771803],
              [-135.494346457864566, 57.094747315163147],
              [-135.408203402034019, 57.148868075670748],
              [-135.422560578005772, 57.167767388863879],
              [-135.372310462104622, 57.197834478034778],
              [-135.35795328613284, 57.243364641636404],
              [-135.476399987899867, 57.257109596685964],
              [-135.551775161751607, 57.233055925349248],
              [-135.602025277652757, 57.300921640906395],
              [-135.695346921469195, 57.362773938629374]
            ]
          ],
          [
            [
              [-135.727650567405675, 58.365296597555968],
              [-135.670221863518634, 58.380759671986709],
              [-135.602025277652757, 58.374746254152527],
              [-135.544596573765716, 58.330075150241498],
              [-135.64868609956099, 58.324920792097913],
              [-135.727650567405675, 58.365296597555968]
            ]
          ],
          [
            [
              [-135.853275857158565, 57.240787462564612],
              [-135.838918681186811, 57.282022327713264],
              [-135.849686563165619, 57.338720267292658],
              [-135.759954213342127, 57.346451804508035],
              [-135.637918217582182, 57.293190103691032],
              [-135.584078807688059, 57.229619686586858],
              [-135.541007279772771, 57.213297552465519],
              [-135.479989281892813, 57.239069343183417],
              [-135.397435520055183, 57.230478746277456],
              [-135.454864223942224, 57.160035851648502],
              [-135.541007279772771, 57.148009015980151],
              [-135.637918217582182, 57.00970040579405],
              [-135.680989745497442, 57.014854763937635],
              [-135.824561505215058, 56.989082973219723],
              [-135.85686515115151, 56.995955450744503],
              [-135.846097269172674, 57.083579539185379],
              [-135.756364919349181, 57.123955344643434],
              [-135.752775625356236, 57.166908329173282],
              [-135.831740093200921, 57.170344567935672],
              [-135.824561505215058, 57.195257298962986],
              [-135.871222327123263, 57.22188814937148],
              [-135.853275857158565, 57.240787462564612]
            ]
          ],
          [
            [
              [-136.154776552565522, 58.597242714017128],
              [-136.122472906629071, 58.609269549685493],
              [-136.097347848678481, 58.558585027940268],
              [-136.154776552565522, 58.597242714017128]
            ]
          ],
          [
            [
              [-136.154776552565522, 58.283685926949261],
              [-136.047097732777331, 58.318907374263731],
              [-136.036329850798495, 58.27337721066209],
              [-136.093758554685536, 58.257055076540752],
              [-136.154776552565522, 58.283685926949261]
            ]
          ],
          [
            [
              [-136.574723949739479, 57.927176155351546],
              [-136.55677747977478, 58.076652541515401],
              [-136.499348775887739, 58.105001511305105],
              [-136.470634423944233, 58.097269974089727],
              [-136.366544898148959, 58.14881355552555],
              [-136.355777016170151, 58.192625599745995],
              [-136.398848544085439, 58.27165909128091],
              [-136.283991136311357, 58.269940971899715],
              [-136.283991136311357, 58.223551748607477],
              [-136.240919608396069, 58.172008167171668],
              [-136.140419376593741, 58.224410808298074],
              [-136.036329850798495, 58.219256450154489],
              [-135.975311852918537, 58.202075256342553],
              [-135.914293855038551, 58.244169181181803],
              [-135.785079271292716, 58.287122165711651],
              [-135.734829155391537, 58.239873882728816],
              [-135.587668101681004, 58.214102092010904],
              [-135.497935751857511, 58.168571928409278],
              [-135.541007279772771, 58.101565272542715],
              [-135.652275393553936, 58.036276736057346],
              [-135.637918217582182, 57.99418281121811],
              [-135.594846689666895, 57.989887512765122],
              [-135.56613233772336, 58.041431094200931],
              [-135.451274929949307, 58.134209540785392],
              [-135.397435520055183, 58.144518257072562],
              [-135.275399524295239, 58.097269974089727],
              [-135.110292000620007, 58.088679377183766],
              [-135.070809766697664, 58.06118946708466],
              [-134.96672024090239, 58.047444512035113],
              [-134.912880831008295, 57.979578796477952],
              [-134.927238006980076, 57.922021797207961],
              [-135.006202474824732, 57.884223170821699],
              [-135.139006352563513, 57.926317095660949],
              [-135.17489929249291, 57.919444618136168],
              [-135.131827764577622, 57.885082230512296],
              [-135.067220472704719, 57.869619156081555],
              [-134.948773770937692, 57.805189679286784],
              [-135.024148944789431, 57.78027694825947],
              [-135.113881294612952, 57.778558828878275],
              [-135.09952411864117, 57.754505157541573],
              [-135.024148944789431, 57.743337381563805],
              [-134.941595182951829, 57.763954814138131],
              [-134.91647012500124, 57.682344143531424],
              [-134.86622000910009, 57.623069024880238],
              [-134.823148481184802, 57.500223489124878],
              [-134.873398597085952, 57.46414298211981],
              [-135.024148944789431, 57.454693325523252],
              [-135.085166942669417, 57.465002041810408],
              [-135.243095878358758, 57.546612712417115],
              [-135.476399987899867, 57.631659621786213],
              [-135.573310925709251, 57.67461260631606],
              [-135.652275393553936, 57.652277054360539],
              [-135.684579039490387, 57.677189785387839],
              [-135.69893621546214, 57.649699875288746],
              [-135.591257395673949, 57.602451592305911],
              [-135.512292927829264, 57.595579114781145],
              [-135.587668101681004, 57.568948264372636],
              [-135.56613233772336, 57.547471772107713],
              [-135.56613233772336, 57.485619474384734],
              [-135.59843598365984, 57.442666489854886],
              [-135.709704097440977, 57.367928296772959],
              [-135.892758091080907, 57.408304102231014],
              [-135.896347385073852, 57.440948370473691],
              [-136.047097732777331, 57.513968444174438],
              [-136.118883612636125, 57.591283816328158],
              [-136.161955140551413, 57.558639548085466],
              [-136.230151726417262, 57.595579114781145],
              [-136.266044666346659, 57.663444830338292],
              [-136.251687490374877, 57.684921322603216],
              [-136.316294782247809, 57.758800455994546],
              [-136.309116194261946, 57.779417888568872],
              [-136.366544898148959, 57.828384290932902],
              [-136.459866541965425, 57.854156081650814],
              [-136.484991599915986, 57.89625000649005],
              [-136.574723949739479, 57.927176155351546]
            ]
          ],
          [
            [
              [-145.655637751877521, 60.36776473633735],
              [-145.555137520075192, 60.355737900668998],
              [-145.512065992159904, 60.317939274282736],
              [-145.608976929969288, 60.310207737067358],
              [-145.655637751877521, 60.36776473633735]
            ]
          ],
          [
            [
              [-146.010977857178545, 60.396113706127053],
              [-145.953549153291533, 60.408140541795404],
              [-145.745370101701013, 60.385804989839883],
              [-145.756137983679821, 60.359174139431389],
              [-145.83151315753156, 60.350583542525413],
              [-146.010977857178545, 60.396113706127053]
            ]
          ],
          [
            [
              [-147.321070164601622, 60.880623371623713],
              [-147.227748520785212, 60.910690460794598],
              [-147.130837582975829, 60.91326763986639],
              [-147.076998173081734, 60.899522684816844],
              [-147.141605464954637, 60.853992521215204],
              [-147.321070164601622, 60.880623371623713]
            ]
          ],
          [
            [
              [-147.500534864248635, 60.653831613306124],
              [-147.486177688276882, 60.727710746697454],
              [-147.385677456474554, 60.741455701747014],
              [-147.310302282622814, 60.664999389283878],
              [-147.349784516545157, 60.627200762897616],
              [-147.482588394283937, 60.618610165991655],
              [-147.500534864248635, 60.653831613306124]
            ]
          ],
          [
            [
              [-147.504124158241581, 60.253509797487965],
              [-147.49694557025569, 60.265536633156316],
              [-147.342605928559266, 60.305912438614371],
              [-147.339016634566349, 60.274986289752889],
              [-147.482588394283937, 60.224301768007678],
              [-147.504124158241581, 60.253509797487965]
            ]
          ],
          [
            [
              [-147.927660849408483, 59.783604146731463],
              [-147.898946497464976, 59.801644400233997],
              [-147.88817861548614, 59.862637638266378],
              [-147.80562485364851, 59.872946354553534],
              [-147.816392735627346, 59.909026861558601],
              [-147.741017561775607, 59.955416084850839],
              [-147.683588857888566, 59.961429502685021],
              [-147.694356739867374, 59.996650949999491],
              [-147.586677920079183, 60.025858979479779],
              [-147.554374274142731, 60.05163077019769],
              [-147.400034632446307, 60.116060246992461],
              [-147.321070164601622, 60.217429290482897],
              [-147.224159226792267, 60.25522791686916],
              [-147.234927108771075, 60.289590304493032],
              [-147.199034168841678, 60.350583542525413],
              [-147.102123231032294, 60.379791572005715],
              [-147.087766055060541, 60.34285200531005],
              [-147.001622999229994, 60.344570124691231],
              [-147.062640997109952, 60.3007580804708],
              [-147.0303373511735, 60.286154065730642],
              [-146.944194295342953, 60.310207737067358],
              [-146.915479943399419, 60.291308423874227],
              [-146.954962177321761, 60.259523215322147],
              [-147.177498404884034, 60.158154171831711],
              [-147.353373810538102, 60.047335471744702],
              [-147.382088162481608, 59.978610696496958],
              [-147.500534864248635, 59.927067115061135],
              [-147.392856044460444, 59.878100712697119],
              [-147.461052630326293, 59.842020205692052],
              [-147.543606392163923, 59.835147728167271],
              [-147.62257086000858, 59.84803362352622],
              [-147.683588857888566, 59.799067221162204],
              [-147.715892503825017, 59.804221579305789],
              [-147.877410733507332, 59.763845773847734],
              [-147.927660849408483, 59.783604146731463]
            ]
          ],
          [
            [
              [-147.949196613366126, 60.244060140891406],
              [-147.791267677676757, 60.476006257352566],
              [-147.751785443754414, 60.440784810038096],
              [-147.708713915839155, 60.45195258601585],
              [-147.723071091810908, 60.508650525595243],
              [-147.565142156121539, 60.580811539605392],
              [-147.565142156121539, 60.534422316313155],
              [-147.608213684036826, 60.506932406214048],
              [-147.618981566015634, 60.436489511585108],
              [-147.690767445874457, 60.404704303033014],
              [-147.618981566015634, 60.368623796027947],
              [-147.70512462184621, 60.285295006040045],
              [-147.697946033860319, 60.245778260272587],
              [-147.758964031740305, 60.156436052450516],
              [-147.845107087570852, 60.195093738527376],
              [-147.855874969549689, 60.2165702307923],
              [-147.945607319373181, 60.222583648626482],
              [-147.949196613366126, 60.244060140891406]
            ]
          ],
          [
            [
              [-148.020982493224921, 60.725133567625662],
              [-147.967143083330825, 60.75176441803417],
              [-147.906125085450839, 60.735442283912832],
              [-147.845107087570852, 60.694207418764179],
              [-147.931250143401428, 60.655549732687319],
              [-148.020982493224921, 60.725133567625662]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '04', name: 'Arizona' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.719512826728263, 32.71892859447594],
            [-114.701566356763564, 32.745559444884442],
            [-114.615423300933003, 32.734391668906682],
            [-114.52569095110951, 32.756727220862203],
            [-114.532869539095387, 32.791089608486082],
            [-114.468262247222469, 32.845210368993683],
            [-114.464672953229524, 32.913076084550838],
            [-114.518512363123619, 33.029908202472022],
            [-114.57594106701066, 33.036780679996795],
            [-114.60106612496125, 33.025612904019034],
            [-114.647726946869469, 33.047089396283958],
            [-114.658494828848276, 33.032485381543808],
            [-114.708744944749441, 33.090901440504396],
            [-114.683619886798866, 33.148458439774394],
            [-114.676441298812975, 33.27044491583915],
            [-114.730280708707085, 33.305666363153627],
            [-114.697977062770619, 33.361505243042423],
            [-114.726691414714139, 33.405317287262868],
            [-114.658494828848276, 33.413048824478238],
            [-114.557994597045962, 33.531599061780611],
            [-114.52569095110951, 33.552216494354937],
            [-114.540048127081263, 33.591733240122394],
            [-114.52569095110951, 33.686229806088058],
            [-114.49697659916599, 33.696538522375221],
            [-114.504155187151866, 33.760108939479387],
            [-114.529280245102441, 33.815088759677593],
            [-114.52569095110951, 33.858900803898031],
            [-114.504155187151866, 33.864055162041616],
            [-114.511333775137743, 33.911303445024444],
            [-114.536458833088318, 33.92848463883638],
            [-114.435958601286003, 34.028135562945621],
            [-114.435958601286003, 34.087410681596808],
            [-114.407244249342483, 34.111464352933524],
            [-114.324690487504867, 34.136377083960831],
            [-114.28879754757547, 34.17073947158471],
            [-114.227779549695484, 34.188779725087244],
            [-114.224190255702553, 34.20510185920859],
            [-114.134457905879046, 34.260940739097386],
            [-114.138047199871991, 34.303034663936636],
            [-114.177529433794334, 34.349423887228866],
            [-114.227779549695484, 34.365746021350205],
            [-114.339047663476634, 34.4516519904099],
            [-114.385708485384853, 34.457665408244075],
            [-114.378529897398963, 34.507490870298696],
            [-114.471851541215401, 34.712806136351361],
            [-114.554405303053031, 34.766926896858962],
            [-114.579530361003606, 34.826202015510148],
            [-114.636959064890647, 34.875168417874171],
            [-114.633369770897701, 35.001450192391921],
            [-114.611834006940057, 35.083060862998622],
            [-114.647726946869469, 35.101960176191753],
            [-114.572351773017729, 35.138899742887425],
            [-114.572351773017729, 35.200752040610404],
            [-114.604655418954181, 35.353664665536648],
            [-114.626191182911825, 35.409503545425451],
            [-114.665673416834167, 35.449879350883499],
            [-114.68003059280592, 35.49970481293812],
            [-114.658494828848276, 35.530630961799609],
            [-114.658494828848276, 35.61911410993109],
            [-114.690798474784742, 35.651758378173774],
            [-114.68003059280592, 35.685261706107056],
            [-114.705155650756495, 35.711892556515558],
            [-114.694387768777688, 35.755704600736003],
            [-114.712334238742386, 35.806389122481221],
            [-114.697977062770619, 35.854496465154647],
            [-114.665673416834167, 35.875113897728973],
            [-114.701566356763564, 35.901744748137475],
            [-114.744637884678838, 35.985073538125377],
            [-114.730280708707085, 36.022013104821042],
            [-114.75540576665766, 36.090737880068794],
            [-114.665673416834167, 36.117368730477295],
            [-114.633369770897701, 36.142281461504609],
            [-114.615423300933003, 36.130254625836251],
            [-114.572351773017729, 36.151731118101175],
            [-114.511333775137743, 36.150872058410577],
            [-114.504155187151866, 36.129395566145654],
            [-114.418012131321305, 36.145717700266999],
            [-114.371351309413086, 36.143140521195207],
            [-114.306744017540169, 36.082147283162826],
            [-114.313922605526045, 36.058093611826109],
            [-114.238547431674306, 36.014281567605671],
            [-114.152404375843759, 36.023731224202237],
            [-114.066261320013197, 36.180939147581469],
            [-114.048314850048499, 36.193825042940425],
            [-114.044725556055553, 36.391408771777712],
            [-114.05190414404143, 36.625073007620067],
            [-114.05190414404143, 37.00048209241092],
            [-113.334045345453447, 37.00048209241092],
            [-112.537222079020779, 37.00048209241092],
            [-111.413773059230593, 37.001341152101517],
            [-111.065611541915416, 37.002200211792115],
            [-110.491324503045021, 37.003918331173303],
            [-110.469788739087392, 36.997904913339127],
            [-109.626304650746505, 36.997904913339127],
            [-109.044839023890233, 36.998763973029725],
            [-109.044839023890233, 35.546094036230357],
            [-109.048428317883179, 35.342496889558888],
            [-109.044839023890233, 35.316725098840983],
            [-109.044839023890233, 34.522094885038847],
            [-109.048428317883179, 33.999786593155925],
            [-109.044839023890233, 33.875222938019377],
            [-109.048428317883179, 33.778149192981921],
            [-109.048428317883179, 31.332406253852533],
            [-109.278143133431328, 31.334124373233728],
            [-109.999591226012257, 31.33326531354313],
            [-110.45902085710857, 31.332406253852533],
            [-111.076379423894238, 31.332406253852533],
            [-112.364935967359671, 31.74131866657666],
            [-113.125866293862927, 31.972405723347229],
            [-113.491974281142802, 32.089237841268407],
            [-114.812834470544701, 32.493854955539547],
            [-114.794888000580002, 32.550552895118948],
            [-114.812834470544701, 32.565156909859091],
            [-114.809245176551755, 32.616700491294907],
            [-114.780530824608235, 32.63044544634446],
            [-114.719512826728263, 32.71892859447594]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '08', name: 'Colorado' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.048428317883179, 41.000264011830112],
            [-108.179819171591717, 41.000264011830112],
            [-107.627067896678966, 41.001982131211307],
            [-106.453368760987601, 41.001982131211307],
            [-106.216475357453575, 40.997686832758319],
            [-105.724742080420796, 40.996827773067722],
            [-104.856132934129334, 40.997686832758319],
            [-104.831007876178759, 40.999404952139514],
            [-104.05213107971079, 41.001123071520709],
            [-103.039950173701726, 41.001982131211307],
            [-102.555395484654838, 41.001982131211307],
            [-102.052894325643251, 41.001982131211307],
            [-102.052894325643251, 40.002895711047103],
            [-102.049305031650306, 39.675593968929682],
            [-102.049305031650306, 39.302762063210622],
            [-102.045715737657375, 39.136963542925422],
            [-102.045715737657375, 38.045957735867354],
            [-102.042126443664429, 37.928266558255579],
            [-102.042126443664429, 36.992750555195549],
            [-102.69896724437244, 36.995327734267335],
            [-102.777931712217111, 36.999623032720322],
            [-103.000467939779398, 37.00048209241092],
            [-103.732683914339134, 36.997904913339127],
            [-104.33927459914598, 36.99360961488614],
            [-104.999704693846937, 36.99360961488614],
            [-105.121740689606895, 36.995327734267335],
            [-106.202118181481808, 36.994468674576737],
            [-106.869726864168641, 36.992750555195549],
            [-106.876905452154517, 37.00048209241092],
            [-107.422478139081392, 36.999623032720322],
            [-108.248015757457566, 36.998763973029725],
            [-109.044839023890233, 36.998763973029725],
            [-109.044839023890233, 37.499595772647723],
            [-109.041249729897288, 37.530521921509212],
            [-109.041249729897288, 38.164507973169727],
            [-109.059196199861987, 38.275326673256728],
            [-109.059196199861987, 38.719460533295326],
            [-109.05201761187611, 38.905017426464255],
            [-109.05201761187611, 40.500291271902711],
            [-109.048428317883179, 40.539808017670168],
            [-109.048428317883179, 41.000264011830112]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '12', name: 'Florida' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-80.750434477544772, 24.857673365823651],
              [-80.700184361643608, 24.909216947259466],
              [-80.682237891678909, 24.880867977469769],
              [-80.732488007580073, 24.843069351083507],
              [-80.750434477544772, 24.857673365823651]
            ]
          ],
          [
            [
              [-80.850934709347086, 24.80355260531605],
              [-80.818631063410635, 24.837055933249331],
              [-80.779148829488292, 24.840492172011714],
              [-80.797095299452991, 24.812143202222018],
              [-80.850934709347086, 24.80355260531605]
            ]
          ],
          [
            [
              [-81.123721052810524, 24.707337919969198],
              [-80.937077765177648, 24.784653292122918],
              [-80.915542001220004, 24.758881501405007],
              [-81.077060230902305, 24.690156726157255],
              [-81.123721052810524, 24.707337919969198]
            ]
          ],
          [
            [
              [-81.812865499454986, 24.56902930978309],
              [-81.744668913589138, 24.660089636986363],
              [-81.672883033730329, 24.699606382753821],
              [-81.583150683906837, 24.736545949449493],
              [-81.572382801928015, 24.756304322333222],
              [-81.44316821818218, 24.813002261912615],
              [-81.306775046450468, 24.755445262642624],
              [-81.292417870478701, 24.710774158731581],
              [-81.242167754577537, 24.673834592035917],
              [-81.256524930549304, 24.664384935439351],
              [-81.400096690266906, 24.623150070290698],
              [-81.410864572245714, 24.646344681936817],
              [-81.507775510055097, 24.64204938348383],
              [-81.518543392033919, 24.621431950909503],
              [-81.683650915709151, 24.558720593495927],
              [-81.812865499454986, 24.545834698136979],
              [-81.812865499454986, 24.56902930978309]
            ]
          ],
          [
            [
              [-82.013865963059629, 24.543257519065186],
              [-81.873883497334973, 24.563874951639512],
              [-81.90977643726437, 24.526935384943847],
              [-81.970794435144342, 24.514908549275489],
              [-82.017455257052561, 24.521781026800262],
              [-82.013865963059629, 24.543257519065186]
            ]
          ],
          [
            [
              [-82.164616310763108, 24.563015891948915],
              [-82.143080546805464, 24.593082981119807],
              [-82.100009018890191, 24.584492384213839],
              [-82.117955488854889, 24.549270936899362],
              [-82.164616310763108, 24.563015891948915]
            ]
          ],
          [
            [
              [-82.265116542565423, 26.698638282772826],
              [-82.247170072600724, 26.683175208342078],
              [-82.178973486734861, 26.50191361362613],
              [-82.150259134791341, 26.47785994228942],
              [-82.089241136911369, 26.455524390333899],
              [-82.064116078960794, 26.470128405074043],
              [-82.013865963059629, 26.452088151571509],
              [-82.064116078960794, 26.425457301163007],
              [-82.096419724897245, 26.42459824147241],
              [-82.171794898748985, 26.467551226002257],
              [-82.19691995669956, 26.491604897338966],
              [-82.243580778607779, 26.59039676175761],
              [-82.265116542565423, 26.655685298242979],
              [-82.265116542565423, 26.698638282772826]
            ]
          ],
          [
            [
              [-82.932725225252256, 24.632599726887264],
              [-82.878885815358146, 24.643767502865025],
              [-82.864528639386393, 24.624009129981296],
              [-82.936314519245187, 24.615418533075328],
              [-82.932725225252256, 24.632599726887264]
            ]
          ],
          [
            [
              [-84.669943517835179, 29.794689407684075],
              [-84.565853992039919, 29.835065213142123],
              [-84.565853992039919, 29.810152482114816],
              [-84.62328269592696, 29.778367273562729],
              [-84.662764929849288, 29.773212915419151],
              [-84.669943517835179, 29.794689407684075]
            ]
          ],
          [
            [
              [-85.003747859178588, 31.000809213282125],
              [-84.982212095220945, 30.93294349772497],
              [-84.942729861298616, 30.888272393813935],
              [-84.935551273312726, 30.816970439494391],
              [-84.914015509355096, 30.752540962699619],
              [-84.863765393453932, 30.711306097550967],
              [-84.375621410414098, 30.68982960528605],
              [-83.438815678256773, 30.641722262612618],
              [-82.699421115711161, 30.59791021839218],
              [-82.214866426664258, 30.568702188911885],
              [-82.232812896628957, 30.556675353243527],
              [-82.22563430864308, 30.507708950879504],
              [-82.200509250692505, 30.485373398923983],
              [-82.211277132671327, 30.424380160891602],
              [-82.171794898748985, 30.35909162440624],
              [-82.038991021010204, 30.370259400384001],
              [-82.038991021010204, 30.434688877178765],
              [-82.017455257052561, 30.47506468263682],
              [-82.006687375073753, 30.577292785817853],
              [-82.049758902989026, 30.655467217662171],
              [-82.035401727017273, 30.707010799097986],
              [-82.038991021010204, 30.749104723937236],
              [-82.010276669066684, 30.762849678986782],
              [-82.024633845038451, 30.783467111561109],
              [-81.981562317123164, 30.776594634036336],
              [-81.949258671186712, 30.827279155781554],
              [-81.895419261292616, 30.821265737947371],
              [-81.870294203342027, 30.792916768157674],
              [-81.809276205462055, 30.790339589085889],
              [-81.744668913589138, 30.766285917749173],
              [-81.719543855638548, 30.744809425484249],
              [-81.601097153871535, 30.72848729136291],
              [-81.540079155991563, 30.713024216932162],
              [-81.489829040090399, 30.725910112291118],
              [-81.44316821818218, 30.709587978169779],
              [-81.428811042210413, 30.698420202192018],
              [-81.44316821818218, 30.60134645715457],
              [-81.432400336203358, 30.522312965619648],
              [-81.446757512175125, 30.503413652426516],
              [-81.410864572245714, 30.4819371601616],
              [-81.396507396273961, 30.400326489554892],
              [-81.392918102281016, 30.303252744517437],
              [-81.3103643404434, 29.969078524875243],
              [-81.263703518535181, 29.858259824788242],
              [-81.263703518535181, 29.814447780567804],
              [-81.163203286732866, 29.555011754007538],
              [-81.048345878958784, 29.307602563115623],
              [-80.944256353163524, 29.110877893968933],
              [-80.71095224362243, 28.756945301443011],
              [-80.574559071890718, 28.585133363323628],
              [-80.560201895918951, 28.53101260281602],
              [-80.524308955989554, 28.459710648496483],
              [-80.588916247862471, 28.41074424613246],
              [-80.603273423834239, 28.364355022840222],
              [-80.60686271782717, 28.289616829758295],
              [-80.588916247862471, 28.177939069980695],
              [-80.567380483904842, 28.09546933968339],
              [-80.509951780017801, 27.970905684546842],
              [-80.384326490264897, 27.739818627776273],
              [-80.330487080370801, 27.597214719137185],
              [-80.312540610406103, 27.52505370512705],
              [-80.294594140441404, 27.500140974099736],
              [-80.255111906519062, 27.379872617416169],
              [-80.19409390863909, 27.249295544445438],
              [-80.161790262702624, 27.192597604866044],
              [-80.14025449874498, 27.111845993949935],
              [-80.093593676836761, 27.018208487674869],
              [-80.032575678956789, 26.796571087500872],
              [-80.03616497294972, 26.594692060210598],
              [-80.061290030900309, 26.444356614356138],
              [-80.079236500865008, 26.263954079330787],
              [-80.09000438284383, 26.2321688707787],
              [-80.11871873478735, 25.986477799267988],
              [-80.11871873478735, 25.841296711557113],
              [-80.129486616766158, 25.772571936309362],
              [-80.154611674716747, 25.702988101371012],
              [-80.154611674716747, 25.665189474984743],
              [-80.176147438674377, 25.684947847868472],
              [-80.165379556695569, 25.728759892088917],
              [-80.183326026660268, 25.745941085900853],
              [-80.240754730547309, 25.724464593635929],
              [-80.276647670476706, 25.636840505195046],
              [-80.298183434434335, 25.622236490454902],
              [-80.301772728427281, 25.567256670256697],
              [-80.330487080370801, 25.532894282632824],
              [-80.337665668356678, 25.465887626766261],
              [-80.319719198391979, 25.437538656976564],
              [-80.326897786377856, 25.398021911209106],
              [-80.305362022420226, 25.38427695615956],
              [-80.334076374363747, 25.33874679255792],
              [-80.362790726307267, 25.32757901658016],
              [-80.366380020300198, 25.28548509174091],
              [-80.291004846448459, 25.318988419674191],
              [-80.201272496624966, 25.485645999649989],
              [-80.176147438674377, 25.520867446964466],
              [-80.176147438674377, 25.485645999649989],
              [-80.204861790617898, 25.414344045330452],
              [-80.326897786377856, 25.223632794017938],
              [-80.359201432314322, 25.153189899388991],
              [-80.448933782137814, 25.073297348163479],
              [-80.570969777897773, 24.953888051170509],
              [-80.657112833728334, 24.897190111591108],
              [-80.667880715707156, 24.907498827878271],
              [-80.545844719947198, 25.012304110131097],
              [-80.495594604046033, 25.04666649775497],
              [-80.470469546095458, 25.09219666135661],
              [-80.459701664116636, 25.161780496294959],
              [-80.441755194151938, 25.189270406394058],
              [-80.463290958109582, 25.209028779277787],
              [-80.495594604046033, 25.199579122681222],
              [-80.520719661996623, 25.221914674636743],
              [-80.542255425954252, 25.206451600205995],
              [-80.664291421714211, 25.187552287012863],
              [-80.714541537615375, 25.152330839698394],
              [-80.746845183551827, 25.147176481554808],
              [-80.811452475424758, 25.185834167631668],
              [-80.876059767297676, 25.174666391653915],
              [-80.901184825248251, 25.139444944339438],
              [-80.998095763057634, 25.123981869908697],
              [-81.08064952489525, 25.118827511765112],
              [-81.141667522775222, 25.163498615676154],
              [-81.170381874718743, 25.221914674636743],
              [-81.163203286732866, 25.289780390193897],
              [-81.141667522775222, 25.341323971629713],
              [-81.120131758817578, 25.33874679255792],
              [-81.127310346803469, 25.38084071739717],
              [-81.170381874718743, 25.464169507385073],
              [-81.209864108641085, 25.504545312843121],
              [-81.202685520655209, 25.533753342323422],
              [-81.231399872598729, 25.587015043140426],
              [-81.270882106521057, 25.614504953239525],
              [-81.288828576485756, 25.687525026940264],
              [-81.3103643404434, 25.702988101371012],
              [-81.349846574365742, 25.690102206012057],
              [-81.382150220302194, 25.776867234762342],
              [-81.468293276132755, 25.803498085170844],
              [-81.4718825701257, 25.817243040220397],
              [-81.54725774397744, 25.849887308463082],
              [-81.622632917829179, 25.89713559144591],
              [-81.662115151751522, 25.885967815468149],
              [-81.690829503695028, 25.852464487534874],
              [-81.748258207582069, 25.960706008550083],
              [-81.802097617476178, 26.087846842758424],
              [-81.820044087440877, 26.236464169231688],
              [-81.845169145391452, 26.330101675506754],
              [-81.870294203342027, 26.379068077870777],
              [-81.970794435144342, 26.476141822908225],
              [-82.010276669066684, 26.483873360123596],
              [-82.013865963059629, 26.528544464034638],
              [-82.056937490974903, 26.548302836918367],
              [-82.056937490974903, 26.493323016720161],
              [-82.107187606876067, 26.483873360123596],
              [-82.110776900869013, 26.540571299702989],
              [-82.139491252812519, 26.637645044740445],
              [-82.182562780727807, 26.681457088960883],
              [-82.171794898748985, 26.702074521535209],
              [-82.125134076840766, 26.699497342463417],
              [-82.0928304309043, 26.665994014530142],
              [-82.053348196981972, 26.802584505335048],
              [-82.060526784967848, 26.876463638726385],
              [-82.089241136911369, 26.888490474394736],
              [-82.089241136911369, 26.922852862018615],
              [-82.060526784967848, 26.931443458924583],
              [-82.074883960939601, 26.958074309333085],
              [-82.117955488854889, 26.954638070570702],
              [-82.135901958819588, 26.926289100781005],
              [-82.17538419274193, 26.91683944418444],
              [-82.14666984079841, 26.789698609976092],
              [-82.178973486734861, 26.772517416164156],
              [-82.25075936659367, 26.763067759567591],
              [-82.268705836558368, 26.720114775037743],
              [-82.268705836558368, 26.784544251832514],
              [-82.290241600515998, 26.827497236362362],
              [-82.351259598395984, 26.908248847278465],
              [-82.444581242212422, 27.060302412514119],
              [-82.476884888148874, 27.141054023430229],
              [-82.545081474014737, 27.261322380113796],
              [-82.64199241182412, 27.389322274012734],
              [-82.69224252772527, 27.43742961668616],
              [-82.706599703697037, 27.48725507874078],
              [-82.742492643626434, 27.539657719867193],
              [-82.706599703697037, 27.523335585745855],
              [-82.703010409704092, 27.497563795027943],
              [-82.649170999809996, 27.523335585745855],
              [-82.613278059880599, 27.585187883468826],
              [-82.570206531965312, 27.609241554805543],
              [-82.523545710057093, 27.693429404484043],
              [-82.476884888148874, 27.722637433964337],
              [-82.484063476134764, 27.742395806848066],
              [-82.4338133602336, 27.76473135880358],
              [-82.394331126311258, 27.836892372813722],
              [-82.412277596275956, 27.901321849608493],
              [-82.491242064120641, 27.919362103111027],
              [-82.473295594155942, 27.822288358073578],
              [-82.552260062000613, 27.848060148791483],
              [-82.530724298042983, 27.877268178271777],
              [-82.534313592035915, 27.93310705816058],
              [-82.552260062000613, 27.966610386093855],
              [-82.688653233732339, 28.027603624126236],
              [-82.685063939739393, 27.97176474423744],
              [-82.724546173661736, 27.947711072900724],
              [-82.685063939739393, 27.915925864348637],
              [-82.627635235852352, 27.910771506205059],
              [-82.58815300193001, 27.816274940239396],
              [-82.624045941859421, 27.780194433234328],
              [-82.624045941859421, 27.726932732417318],
              [-82.638403117831174, 27.703738120771206],
              [-82.713778291682914, 27.698583762627621],
              [-82.724546173661736, 27.671093852528521],
              [-82.699421115711161, 27.638449584285837],
              [-82.731724761647612, 27.612677793567933],
              [-82.74608193761938, 27.647040181191805],
              [-82.738903349633489, 27.71834213551135],
              [-82.789153465534653, 27.791362209212089],
              [-82.821457111471105, 27.81369776116761],
              [-82.850171463414625, 27.863523223222231],
              [-82.828635699456996, 28.019872086910866],
              [-82.846582169421694, 28.076570026490259],
              [-82.860939345393447, 28.217455815748153],
              [-82.828635699456996, 28.21831487543875],
              [-82.828635699456996, 28.138422324213238],
              [-82.814278523485228, 28.061966011750116],
              [-82.781974877548777, 28.055952593915933],
              [-82.79633205352053, 28.187388726577261],
              [-82.764028407584078, 28.219173935129348],
              [-82.760439113591133, 28.254395382443818],
              [-82.731724761647612, 28.29219400883008],
              [-82.731724761647612, 28.324838277072764],
              [-82.706599703697037, 28.367791261602612],
              [-82.706599703697037, 28.401294589535887],
              [-82.674296057760571, 28.441670394993942],
              [-82.67070676376764, 28.519844826838266],
              [-82.652760293802942, 28.591146781157804],
              [-82.674296057760571, 28.647844720737204],
              [-82.667117469774695, 28.69595206341063],
              [-82.713778291682914, 28.720864794437936],
              [-82.699421115711161, 28.756945301443011],
              [-82.731724761647612, 28.850582807718069],
              [-82.688653233732339, 28.905562627916275],
              [-82.764028407584078, 29.000059193881931],
              [-82.753260525605256, 29.02669004429044],
              [-82.781974877548777, 29.073938327273268],
              [-82.817867817478174, 29.076515506345061],
              [-82.799921347513475, 29.104864476134757],
              [-82.803510641506406, 29.146958400974007],
              [-82.828635699456996, 29.158126176951761],
              [-82.997332517125173, 29.17788454983549],
              [-83.029636163061625, 29.134072505615052],
              [-83.054761221012214, 29.130636266852662],
              [-83.087064866948666, 29.216542235912357],
              [-83.076296984969844, 29.255199921989217],
              [-83.126547100871008, 29.282689832088316],
              [-83.169618628786282, 29.290421369303687],
              [-83.176797216772158, 29.344542129811295],
              [-83.201922274722747, 29.394367591865915],
              [-83.24140450864509, 29.433025277942775],
              [-83.295243918539185, 29.438179636086353],
              [-83.313190388503884, 29.475978262472623],
              [-83.399333444334445, 29.517213127621268],
              [-83.399333444334445, 29.612568753277529],
              [-83.413690620306198, 29.67012575254752],
              [-83.456762148221486, 29.676139170381695],
              [-83.492655088150883, 29.70878343862438],
              [-83.539315910059102, 29.723387453364531],
              [-83.585976731967321, 29.775790094490937],
              [-83.585976731967321, 29.811870601496011],
              [-83.618280377903773, 29.841937690666903],
              [-83.636226847868471, 29.885749734887341],
              [-83.686476963769636, 29.92354836127361],
              [-83.78697719557195, 29.976810062090614],
              [-83.930548955289552, 30.038662359813593],
              [-84.02387059910599, 30.103091836608364],
              [-84.063352833028333, 30.101373717227169],
              [-84.156674476844771, 30.073024747437472],
              [-84.267942590625907, 30.097937478464779],
              [-84.271531884618838, 30.067870389293887],
              [-84.364853528435276, 30.008595270642701],
              [-84.332549882498824, 29.92354836127361],
              [-84.343317764477646, 29.899494689936894],
              [-84.379210704407043, 29.893481272102719],
              [-84.433050114301139, 29.906367167461667],
              [-84.450996584265837, 29.928702719417188],
              [-84.537139640096399, 29.909803406224057],
              [-84.648407753877535, 29.847092048810481],
              [-84.88171186341863, 29.733696169651694],
              [-84.903247627376274, 29.735414289032882],
              [-84.878122569425685, 29.773212915419151],
              [-84.914015509355096, 29.783521631706314],
              [-84.939140567305671, 29.750018303773032],
              [-84.992979977199766, 29.714796856458563],
              [-85.122194560945601, 29.715655916149153],
              [-85.075533739037382, 29.67356199130991],
              [-85.068355151051506, 29.640917723067226],
              [-85.010926447164465, 29.622018409874094],
              [-84.903247627376274, 29.667548573475727],
              [-84.799158101581014, 29.701910961099607],
              [-84.702247163771631, 29.774071975109749],
              [-84.691479281792809, 29.762904199131988],
              [-84.77762233762337, 29.692461304503041],
              [-84.878122569425685, 29.655521737807376],
              [-85.003747859178588, 29.598823798227976],
              [-85.046819387093862, 29.586796962559617],
              [-85.082712327023273, 29.615145932349321],
              [-85.1401410309103, 29.634045245542453],
              [-85.22269479274793, 29.677857289762891],
              [-85.290891378613779, 29.683870707597073],
              [-85.351909376493765, 29.659817036260357],
              [-85.398570198401984, 29.740568647176467],
              [-85.416516668366683, 29.8427967503575],
              [-85.387802316423162, 29.878877257362568],
              [-85.387802316423162, 29.788675989849892],
              [-85.366266552465518, 29.710501558005575],
              [-85.341141494514943, 29.691602244812444],
              [-85.305248554585546, 29.725105572745726],
              [-85.301659260592601, 29.808434362733621],
              [-85.362677258472587, 29.898635630246297],
              [-85.427284550345504, 29.950179211682112],
              [-85.488302548225477, 29.961346987659873],
              [-85.542141958119572, 29.995709375283745],
              [-85.599570662006613, 30.056702613316126],
              [-85.696481599815996, 30.097078418774181],
              [-85.775446067660667, 30.156353537425368],
              [-85.922607121371215, 30.237964208032075],
              [-86.091303939039392, 30.303252744517437],
              [-86.299482990629897, 30.36338692285922],
              [-86.410751104411034, 30.380568116671164],
              [-86.633287331973321, 30.396031191101905],
              [-86.751734033740334, 30.391735892648924],
              [-86.920430851408511, 30.370259400384001],
              [-87.153734960949606, 30.328165475544751],
              [-87.268592368723688, 30.315279580185795],
              [-87.293717426674263, 30.323870177091763],
              [-87.519842948229481, 30.280058132871325],
              [-87.466003538335386, 30.302393684826846],
              [-87.491128596285961, 30.323011117401165],
              [-87.458824950349495, 30.335897012760121],
              [-87.430110598405975, 30.406339907389068],
              [-87.365503306533057, 30.43640699655996],
              [-87.437289186391865, 30.480219040780405],
              [-87.448057068370687, 30.527467323763233],
              [-87.394217658476578, 30.615091412204116],
              [-87.404985540455399, 30.674366530855302],
              [-87.534200124201234, 30.743091306103054],
              [-87.544968006180056, 30.778312753417531],
              [-87.627521768017672, 30.846178468974685],
              [-87.634700356003563, 30.865936841858414],
              [-87.588039534095344, 30.964728706277057],
              [-87.598807416074152, 30.997372974519742],
              [-87.002984613246127, 30.999091093900937],
              [-86.518429924199239, 30.993077676066754],
              [-85.893892769427694, 30.993077676066754],
              [-85.499070430204299, 30.996513914829144],
              [-85.24423055670556, 31.000809213282125],
              [-85.003747859178588, 31.000809213282125]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '13', name: 'Georgia' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.60674924999249, 34.984268998579978],
            [-85.466766784267833, 34.982550879198783],
            [-84.978622801228013, 34.987705237342368],
            [-84.321782000520002, 34.988564297032966],
            [-83.618280377903773, 34.986846177651771],
            [-83.621869671896718, 34.992000535795349],
            [-83.108600630906309, 35.000591132701324],
            [-83.119368512885131, 34.938738834978345],
            [-83.230636626666268, 34.880322776017756],
            [-83.323958270482706, 34.790121508505081],
            [-83.32036897648976, 34.759195359643591],
            [-83.349083328433281, 34.73685980768807],
            [-83.338315446454459, 34.681879987489872],
            [-83.230636626666268, 34.611437092860925],
            [-83.169618628786282, 34.605423675026742],
            [-83.087064866948666, 34.516081467204664],
            [-83.00451110511105, 34.472269422984226],
            [-82.904010873308735, 34.486014378033772],
            [-82.875296521365215, 34.475705661746609],
            [-82.846582169421694, 34.420725841548411],
            [-82.835814287442872, 34.365746021350205],
            [-82.792742759527599, 34.339974230632301],
            [-82.781974877548777, 34.297021246102453],
            [-82.742492643626434, 34.252350142191418],
            [-82.742492643626434, 34.208538097970973],
            [-82.717367585675859, 34.150122039010384],
            [-82.674296057760571, 34.129504606436058],
            [-82.645581705817051, 34.071947607166067],
            [-82.595331589915901, 34.030712742017414],
            [-82.555849355993558, 33.943947713267129],
            [-82.523545710057093, 33.943088653576531],
            [-82.455349124191244, 33.881236355853552],
            [-82.351259598395984, 33.836565251942517],
            [-82.247170072600724, 33.752377402264017],
            [-82.232812896628957, 33.699974761137604],
            [-82.200509250692505, 33.664753313823134],
            [-82.19691995669956, 33.630390926199254],
            [-82.150259134791341, 33.597746657956577],
            [-82.107187606876067, 33.596028538575382],
            [-82.046169608996081, 33.564243330023295],
            [-81.981562317123164, 33.484350778797783],
            [-81.927722907229068, 33.462015226842261],
            [-81.913365731257315, 33.441397794267935],
            [-81.93849078920789, 33.40445822757227],
            [-81.924133613236123, 33.370954899638988],
            [-81.93849078920789, 33.343464989539889],
            [-81.837990557405575, 33.273022094910942],
            [-81.852347733377329, 33.247250304193038],
            [-81.80568691146911, 33.211169797187964],
            [-81.766204677546767, 33.215465095640951],
            [-81.773383265532658, 33.180243648326474],
            [-81.744668913589138, 33.141585962249614],
            [-81.647757975779754, 33.094337679266786],
            [-81.615454329843303, 33.095196738957384],
            [-81.558025625956262, 33.045371276902763],
            [-81.489829040090399, 33.008431710207098],
            [-81.507775510055097, 32.950874710937107],
            [-81.464703982139824, 32.895894890738901],
            [-81.457525394153933, 32.853800965899651],
            [-81.425221748217481, 32.840915070540703],
            [-81.425221748217481, 32.767894996839964],
            [-81.410864572245714, 32.740405086740864],
            [-81.428811042210413, 32.701747400664004],
            [-81.407275278252783, 32.694874923139224],
            [-81.392918102281016, 32.653640057990572],
            [-81.410864572245714, 32.618418610676102],
            [-81.36779304433044, 32.577183745527449],
            [-81.278060694506948, 32.554848193571928],
            [-81.278060694506948, 32.535089820688199],
            [-81.195506932669318, 32.46550598574985],
            [-81.209864108641085, 32.436297956269556],
            [-81.15602469874699, 32.34609668875688],
            [-81.141667522775222, 32.34953292751927],
            [-81.120131758817578, 32.284244391033901],
            [-81.15602469874699, 32.246445764647639],
            [-81.116542464824647, 32.188888765377648],
            [-81.1308996407964, 32.166553213422127],
            [-81.112953170831702, 32.113291512605123],
            [-81.037577996979962, 32.084083483124829],
            [-80.998095763057634, 32.098687497864972],
            [-80.919131295212949, 32.037694259832591],
            [-80.886827649276483, 32.034258021070208],
            [-80.84375612136121, 32.023949304783045],
            [-80.861702591325908, 31.968969484584839],
            [-80.911952707227073, 31.944056753557533],
            [-80.94784564715647, 31.956942648916481],
            [-80.969381411114114, 31.915707783767829],
            [-80.933488471184702, 31.908835306243056],
            [-80.990917175071743, 31.85729172480724],
            [-81.066292348923483, 31.877050097690969],
            [-81.077060230902305, 31.828942755017543],
            [-81.037577996979962, 31.819493098420978],
            [-81.069881642916428, 31.76880857667576],
            [-81.1308996407964, 31.722419353383529],
            [-81.191917638676387, 31.73358712936129],
            [-81.202685520655209, 31.719842174311736],
            [-81.159613992739921, 31.69149320452204],
            [-81.1308996407964, 31.695788502975027],
            [-81.134488934789346, 31.623627488964885],
            [-81.159613992739921, 31.570365788147875],
            [-81.260114224542249, 31.548030236192353],
            [-81.260114224542249, 31.529130922999222],
            [-81.199096226662263, 31.53772151990519],
            [-81.177560462704619, 31.517104087330871],
            [-81.260114224542249, 31.404567267862674],
            [-81.281649988499879, 31.326392836018357],
            [-81.256524930549304, 31.315225060040596],
            [-81.274471400514003, 31.289453269322685],
            [-81.292417870478701, 31.20612447933479],
            [-81.33907869238692, 31.187225166141658],
            [-81.36779304433044, 31.13654064439644],
            [-81.400096690266906, 31.133963465324648],
            [-81.400096690266906, 31.072970227292267],
            [-81.425221748217481, 31.01369510864108],
            [-81.450346806168056, 31.015413228022275],
            [-81.493418334083344, 30.977614601636013],
            [-81.446757512175125, 30.956997169061687],
            [-81.407275278252783, 30.977614601636013],
            [-81.403685984259837, 30.908030766697664],
            [-81.461114688146878, 30.769722156511563],
            [-81.44316821818218, 30.709587978169779],
            [-81.489829040090399, 30.725910112291118],
            [-81.540079155991563, 30.713024216932162],
            [-81.601097153871535, 30.72848729136291],
            [-81.719543855638548, 30.744809425484249],
            [-81.744668913589138, 30.766285917749173],
            [-81.809276205462055, 30.790339589085889],
            [-81.870294203342027, 30.792916768157674],
            [-81.895419261292616, 30.821265737947371],
            [-81.949258671186712, 30.827279155781554],
            [-81.981562317123164, 30.776594634036336],
            [-82.024633845038451, 30.783467111561109],
            [-82.010276669066684, 30.762849678986782],
            [-82.038991021010204, 30.749104723937236],
            [-82.035401727017273, 30.707010799097986],
            [-82.049758902989026, 30.655467217662171],
            [-82.006687375073753, 30.577292785817853],
            [-82.017455257052561, 30.47506468263682],
            [-82.038991021010204, 30.434688877178765],
            [-82.038991021010204, 30.370259400384001],
            [-82.171794898748985, 30.35909162440624],
            [-82.211277132671327, 30.424380160891602],
            [-82.200509250692505, 30.485373398923983],
            [-82.22563430864308, 30.507708950879504],
            [-82.232812896628957, 30.556675353243527],
            [-82.214866426664258, 30.568702188911885],
            [-82.699421115711161, 30.59791021839218],
            [-83.438815678256773, 30.641722262612618],
            [-84.375621410414098, 30.68982960528605],
            [-84.863765393453932, 30.711306097550967],
            [-84.914015509355096, 30.752540962699619],
            [-84.935551273312726, 30.816970439494391],
            [-84.942729861298616, 30.888272393813935],
            [-84.982212095220945, 30.93294349772497],
            [-85.003747859178588, 31.000809213282125],
            [-85.010926447164465, 31.053211854408538],
            [-85.036051505115054, 31.108191674606744],
            [-85.107837384973848, 31.186366106451061],
            [-85.097069502995026, 31.232755329743291],
            [-85.115015972959725, 31.276567373963736],
            [-85.08989091500915, 31.29460762746627],
            [-85.093480209002081, 31.362473343023424],
            [-85.064765857058561, 31.431198118271176],
            [-85.071944445044451, 31.468137684966841],
            [-85.046819387093862, 31.517104087330871],
            [-85.057587269072684, 31.620191250202495],
            [-85.125783854938547, 31.69492944328443],
            [-85.11860526695267, 31.732728069670692],
            [-85.1401410309103, 31.839251471304706],
            [-85.129373148931492, 31.877909157381566],
            [-85.079123033030328, 31.939761455104545],
            [-85.053997975079753, 32.013640588495882],
            [-85.046819387093862, 32.087519721887212],
            [-85.057587269072684, 32.135627064560637],
            [-85.010926447164465, 32.18029816847168],
            [-84.960676331263315, 32.198338421974213],
            [-84.967854919249191, 32.21895585454854],
            [-84.928372685326849, 32.21895585454854],
            [-84.892479745397452, 32.263626958459582],
            [-84.935551273312726, 32.297989346083455],
            [-85.000158565185643, 32.322043017420171],
            [-84.964265625256246, 32.42255300122001],
            [-84.996569271192712, 32.453479150081492],
            [-85.007337153171534, 32.523922044710439],
            [-85.068355151051506, 32.583197163361625],
            [-85.104248090980903, 32.645049461084604],
            [-85.122194560945601, 32.773049354983542],
            [-85.16885538285382, 32.811707041060401],
            [-85.154498206882067, 32.842633189921891],
            [-85.186801852818519, 32.870123100020997],
            [-85.305248554585546, 33.482632659416588],
            [-85.359087964479642, 33.749800223192224],
            [-85.405748786387861, 34.002363772227717],
            [-85.470356078260778, 34.327947394963942],
            [-85.552909840098394, 34.708510837898373],
            [-85.60674924999249, 34.984268998579978]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '18', name: 'Indiana' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.523432242222412, 41.708129196881963],
            [-87.469592832328317, 41.672907749567486],
            [-87.426521304413043, 41.690088943379429],
            [-87.401396246462454, 41.677203048020473],
            [-87.422932010420098, 41.642840660396594],
            [-87.326021072610729, 41.623082287512865],
            [-87.221931546815469, 41.623941347203463],
            [-87.12143131501314, 41.645417839468386],
            [-86.934788027380264, 41.709847316263158],
            [-86.823519913599128, 41.760531838008376],
            [-86.500483454234541, 41.759672778317778],
            [-85.789803243632434, 41.758813718627181],
            [-85.11860526695267, 41.759672778317778],
            [-84.806336689566891, 41.760531838008376],
            [-84.806336689566891, 41.696102361213605],
            [-84.802747395573959, 41.488209916089154],
            [-84.802747395573959, 40.302707543065424],
            [-84.813515277552767, 39.927298458274578],
            [-84.813515277552767, 39.548453134721342],
            [-84.820693865538658, 39.309634540735402],
            [-84.820693865538658, 39.105178334373335],
            [-84.888890451404507, 39.066520648296475],
            [-84.896069039390397, 39.049339454484539],
            [-84.838640335503356, 38.988346216452157],
            [-84.831461747517466, 38.961715366043656],
            [-84.870943981439808, 38.929071097800971],
            [-84.860176099460986, 38.897285889248884],
            [-84.799158101581014, 38.891272471414709],
            [-84.784800925609247, 38.869795979149785],
            [-84.831461747517466, 38.830279233382328],
            [-84.809925983559836, 38.792480606996065],
            [-84.888890451404507, 38.795057786067851],
            [-84.946319155291548, 38.775299413184122],
            [-84.978622801228013, 38.77959471163711],
            [-85.104248090980903, 38.725473951129501],
            [-85.154498206882067, 38.691970623196227],
            [-85.22269479274793, 38.700561220102195],
            [-85.258587732677327, 38.73750078679786],
            [-85.351909376493765, 38.731487368963684],
            [-85.409338080380806, 38.73750078679786],
            [-85.45240960829608, 38.709151817008163],
            [-85.438052432324326, 38.601769355683551],
            [-85.416516668366683, 38.54077611765117],
            [-85.473945372253723, 38.50641373002729],
            [-85.499070430204299, 38.468615103641028],
            [-85.60674924999249, 38.439407074160734],
            [-85.674945835858352, 38.301098463974633],
            [-85.743142421724215, 38.267595136041351],
            [-85.79339253762538, 38.288212568615677],
            [-85.829285477554777, 38.277044792637923],
            [-85.850821241512406, 38.222924032130315],
            [-85.908249945399447, 38.161071734407336],
            [-85.904660651406516, 38.086333541325409],
            [-85.922607121371215, 38.026199362983625],
            [-85.951321473314735, 38.005581930409299],
            [-86.033875235152351, 37.990118855978551],
            [-86.048232411124104, 37.959192707117062],
            [-86.094893233032323, 38.009018169171682],
            [-86.173857700877008, 38.00987722886228],
            [-86.267179344693446, 38.057125511845115],
            [-86.270768638686377, 38.137877122761218],
            [-86.346143812538116, 38.195434122031216],
            [-86.374858164481637, 38.193716002650021],
            [-86.371268870488706, 38.164507973169727],
            [-86.324608048580487, 38.154199256882563],
            [-86.328197342573418, 38.13272276461764],
            [-86.374858164481637, 38.131004645236445],
            [-86.403572516425157, 38.106091914209138],
            [-86.432286868368678, 38.125850287092867],
            [-86.464590514305144, 38.100937556065553],
            [-86.432286868368678, 38.086333541325409],
            [-86.453822632326322, 38.050253034320335],
            [-86.518429924199239, 38.042521497104964],
            [-86.525608512185116, 37.961769886188854],
            [-86.507662042220417, 37.92912561794617],
            [-86.590215804058033, 37.921394080730799],
            [-86.597394392043924, 37.867273320223198],
            [-86.636876625966252, 37.842360589195884],
            [-86.662001683916841, 37.862978021770211],
            [-86.644055213952143, 37.906790065990656],
            [-86.67994815388154, 37.915380662896624],
            [-86.715841093810937, 37.8939041706317],
            [-86.751734033740334, 37.912803483824831],
            [-86.794805561655608, 37.989259796287953],
            [-86.855823559535594, 37.987541676906766],
            [-86.906073675436744, 37.942870572995723],
            [-87.010163201232004, 37.919675961349604],
            [-87.046056141161415, 37.8939041706317],
            [-87.064002611126114, 37.810575380643797],
            [-87.089127669076689, 37.787380768997686],
            [-87.128609902999031, 37.784803589925893],
            [-87.157324254942552, 37.838065290742904],
            [-87.221931546815469, 37.849233066720657],
            [-87.30089601466014, 37.898199469084688],
            [-87.379860482504824, 37.93599809547095],
            [-87.451646362363618, 37.941152453614528],
            [-87.501896478264783, 37.909367245062441],
            [-87.552146594165933, 37.925689379183787],
            [-87.573682358123577, 37.967783304023037],
            [-87.602396710067097, 37.972937662166615],
            [-87.627521768017672, 37.916239722587221],
            [-87.588039534095344, 37.861259902389016],
            [-87.634700356003563, 37.826897514765143],
            [-87.674182589925891, 37.829474693836929],
            [-87.663414707947069, 37.877582036510361],
            [-87.681361177911782, 37.903353827228266],
            [-87.832111525615247, 37.876722976819764],
            [-87.871593759537589, 37.921394080730799],
            [-87.903897405474055, 37.924830319493189],
            [-87.939790345403452, 37.89046793186931],
            [-87.903897405474055, 37.812293500024992],
            [-87.946968933389329, 37.771917694566937],
            [-88.029522695226945, 37.799407604666037],
            [-88.069004929149287, 37.801125724047232],
            [-88.083362105121054, 37.830333753527526],
            [-88.040290577205766, 37.822602216312156],
            [-88.097719281092807, 37.90163570784707],
            [-88.011576225262246, 37.894763230322297],
            [-88.065415635156342, 37.919675961349604],
            [-88.011576225262246, 37.977232960619602],
            [-88.007986931269315, 38.028776542055411],
            [-88.036701283212835, 38.051112094010932],
            [-87.968504697346972, 38.067434228132278],
            [-87.961326109361096, 38.100078496374955],
            [-88.004397637276369, 38.083756362253617],
            [-87.925433169431685, 38.146467719667193],
            [-87.975683285332849, 38.198011301103001],
            [-87.990040461304616, 38.248695822848219],
            [-87.95773681536815, 38.240105225942251],
            [-87.925433169431685, 38.29852128490284],
            [-87.907486699466986, 38.268454195731948],
            [-87.882361641516411, 38.303675643046425],
            [-87.853647289572891, 38.275326673256728],
            [-87.806986467664672, 38.362950761697611],
            [-87.778272115721151, 38.370682298912982],
            [-87.731611293812932, 38.442843312923124],
            [-87.738789881798809, 38.475487581165808],
            [-87.652646825968262, 38.511568088170876],
            [-87.652646825968262, 38.568266027750269],
            [-87.620343180031796, 38.639567982069813],
            [-87.595218122081221, 38.667057892168913],
            [-87.544968006180056, 38.677366608456076],
            [-87.494717890278906, 38.742655144941445],
            [-87.498307184271837, 38.778735651946512],
            [-87.552146594165933, 38.859487262862622],
            [-87.512664360243605, 38.954842888518876],
            [-87.577271652116522, 38.989205276142755],
            [-87.573682358123577, 39.057070991699909],
            [-87.631111062010618, 39.104319274682737],
            [-87.64187894398944, 39.167030632096314],
            [-87.573682358123577, 39.218574213532129],
            [-87.605986004060043, 39.261527198061977],
            [-87.598807416074152, 39.313070779497785],
            [-87.577271652116522, 39.340560689596892],
            [-87.530610830208303, 39.348292226812262],
            [-87.530610830208303, 39.646385939449388],
            [-87.534200124201234, 39.646385939449388],
            [-87.534200124201234, 39.987432636616361],
            [-87.530610830208303, 39.997741352903525],
            [-87.530610830208303, 40.25030490193901],
            [-87.527021536215358, 40.462492645516448],
            [-87.527021536215358, 41.489928035470349],
            [-87.523432242222412, 41.708129196881963]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '20', name: 'Kansas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.052894325643251, 40.002895711047103],
            [-101.130445769457694, 40.002036651356505],
            [-100.757159194191942, 40.002036651356505],
            [-99.813174874048741, 40.001177591665908],
            [-98.833297613976129, 40.002036651356505],
            [-98.248242693126926, 40.002036651356505],
            [-97.246829669096684, 40.001177591665908],
            [-96.302845348953483, 40.00031853197531],
            [-95.785987013970129, 40.00031853197531],
            [-95.308610912909131, 40.00031853197531],
            [-95.25118220902209, 39.948774950539502],
            [-95.204521387113871, 39.939325293942936],
            [-95.200932093120926, 39.902385727247264],
            [-95.154271271212707, 39.907540085390849],
            [-95.136324801248008, 39.87661393652936],
            [-95.043003157431571, 39.864587100861002],
            [-95.01428880548805, 39.899808548175479],
            [-94.928145749657489, 39.888640772197718],
            [-94.942502925629256, 39.864587100861002],
            [-94.917377867678667, 39.836238131071305],
            [-94.877895633756339, 39.826788474474739],
            [-94.892252809728092, 39.790707967469665],
            [-94.924556455664558, 39.789848907779074],
            [-94.913788573685736, 39.758922758917585],
            [-94.870717045770448, 39.772667713967131],
            [-94.874306339763393, 39.730573789127888],
            [-94.91019927969279, 39.725419430984303],
            [-94.956860101601009, 39.746036863558629],
            [-94.974806571565708, 39.681607386763858],
            [-95.028645981459803, 39.665285252642519],
            [-95.053771039410393, 39.630063805328049],
            [-95.046592451424516, 39.594842358013572],
            [-95.107610449304488, 39.574224925439246],
            [-95.104021155311543, 39.532990060290594],
            [-95.050181745417447, 39.497768612976124],
            [-95.046592451424516, 39.47285588194881],
            [-94.989163747537475, 39.446225031540308],
            [-94.946092219622187, 39.393822390413895],
            [-94.895842103721037, 39.393822390413895],
            [-94.877895633756339, 39.375782136911361],
            [-94.91019927969279, 39.354305644646438],
            [-94.888663515735146, 39.286439929089283],
            [-94.824056223862229, 39.241768825178248],
            [-94.834824105841051, 39.217715153841532],
            [-94.780984695946955, 39.206547377863771],
            [-94.763038225982257, 39.179916527455269],
            [-94.712788110081092, 39.170466870858704],
            [-94.680484464144641, 39.18421182590825],
            [-94.662537994179942, 39.157580975499748],
            [-94.590752114321134, 39.155003796427955],
            [-94.608698584285833, 39.119782349113486],
            [-94.608698584285833, 38.738359846488457],
            [-94.612287878278778, 38.635272683616833],
            [-94.612287878278778, 38.037367138961386],
            [-94.615877172271723, 37.992696035050344],
            [-94.615877172271723, 37.323488536075352],
            [-94.619466466264655, 37.240159746087457],
            [-94.619466466264655, 36.998763973029725],
            [-95.513200670506706, 36.999623032720322],
            [-96.216702293122921, 36.998763973029725],
            [-96.823292977929768, 36.998763973029725],
            [-97.605759068390682, 36.998763973029725],
            [-98.352332218922186, 36.997904913339127],
            [-99.278370069100689, 36.999623032720322],
            [-99.648067350373495, 36.999623032720322],
            [-100.200818625286246, 37.002200211792115],
            [-100.735623430234298, 36.998763973029725],
            [-101.485785874758747, 36.995327734267335],
            [-102.042126443664429, 36.992750555195549],
            [-102.042126443664429, 37.928266558255579],
            [-102.045715737657375, 38.045957735867354],
            [-102.045715737657375, 39.136963542925422],
            [-102.049305031650306, 39.302762063210622],
            [-102.049305031650306, 39.675593968929682],
            [-102.052894325643251, 40.002895711047103]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '23', name: 'Maine' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.370959495894951, 44.193388881778809],
              [-68.320709379993801, 44.199402299612991],
              [-68.345834437944376, 44.1693352104421],
              [-68.370959495894951, 44.193388881778809]
            ]
          ],
          [
            [
              [-68.5001740796408, 44.159885553845534],
              [-68.475049021690211, 44.231187508165071],
              [-68.453513257732581, 44.201979478684777],
              [-68.385316671866718, 44.154731195701949],
              [-68.439156081760814, 44.116073509625089],
              [-68.457102551725512, 44.145281539105383],
              [-68.5001740796408, 44.159885553845534]
            ]
          ],
          [
            [
              [-68.528888431584321, 44.344583387323866],
              [-68.518120549605499, 44.380663894328933],
              [-68.478638315683156, 44.319670656296559],
              [-68.528888431584321, 44.344583387323866]
            ]
          ],
          [
            [
              [-68.841157008970086, 44.236341866308656],
              [-68.790906893068936, 44.238059985689851],
              [-68.780139011090114, 44.202838538375374],
              [-68.841157008970086, 44.236341866308656]
            ]
          ],
          [
            [
              [-68.91294288882888, 43.853201244302433],
              [-68.884228536885374, 43.88498645285452],
              [-68.880639242892428, 43.820556976059756],
              [-68.91294288882888, 43.853201244302433]
            ]
          ],
          [
            [
              [-68.945246534765346, 44.112637270862699],
              [-68.916532182821825, 44.147858718177176],
              [-68.826799832998333, 44.186516404254036],
              [-68.78731759907599, 44.143563419724188],
              [-68.819621245012442, 44.136690942199415],
              [-68.769371129111292, 44.069684286332858],
              [-68.808853363033634, 44.036180958399576],
              [-68.873460654906552, 44.025013182421816],
              [-68.916532182821825, 44.045630614996142],
              [-68.898585712857127, 44.067107107261066],
              [-68.909353594835949, 44.110060091790913],
              [-68.945246534765346, 44.112637270862699]
            ]
          ],
          [
            [
              [-68.96678229872299, 44.251804940739397],
              [-68.920121476814771, 44.310220999699993],
              [-68.91294288882888, 44.365200819898192],
              [-68.880639242892428, 44.386677312163116],
              [-68.859103478934784, 44.364341760207594],
              [-68.89140712487125, 44.334274671036702],
              [-68.887817830878305, 44.303348522175213],
              [-68.916532182821825, 44.243214343833429],
              [-68.952425122751222, 44.218301612806123],
              [-68.96678229872299, 44.251804940739397]
            ]
          ],
          [
            [
              [-70.704000591305913, 43.059430090190894],
              [-70.829625881058803, 43.129013925129243],
              [-70.829625881058803, 43.188289043780429],
              [-70.808090117101173, 43.224369550785504],
              [-70.955251170811707, 43.334329191181908],
              [-70.987554816748172, 43.379859354783541],
              [-70.958840464804652, 43.466624383533826],
              [-70.973197640776405, 43.475214980439794],
              [-70.951661876818761, 43.550812233212326],
              [-70.973197640776405, 43.570570606096055],
              [-70.991144110741104, 43.839456289252887],
              [-70.991144110741104, 43.915912601716009],
              [-71.030626344663446, 44.655562995319947],
              [-71.084465754557542, 45.305871181101807],
              [-71.009090580705802, 45.318757076460756],
              [-71.009090580705802, 45.347106046250452],
              [-70.91935823088231, 45.311884598935983],
              [-70.922947524875241, 45.279240330693298],
              [-70.897822466924666, 45.242300763997633],
              [-70.858340233002323, 45.229414868638678],
              [-70.811679411094104, 45.302434942339417],
              [-70.804500823108228, 45.376314075730747],
              [-70.826036587065872, 45.400367747067463],
              [-70.782965059150584, 45.431293895928953],
              [-70.754250707207063, 45.42871671685716],
              [-70.71117917929179, 45.390918090470898],
              [-70.63580400544005, 45.383186553255527],
              [-70.632214711447119, 45.416689881188802],
              [-70.718357767277666, 45.487991835508346],
              [-70.721947061270612, 45.515481745607445],
              [-70.642982593425927, 45.607401132501316],
              [-70.592732477524777, 45.630595744147435],
              [-70.553250243602434, 45.6675353108431],
              [-70.524535891658914, 45.666676251152502],
              [-70.470696481764818, 45.701897698466979],
              [-70.384553425934257, 45.734541966709656],
              [-70.416857071870723, 45.795535204742038],
              [-70.341481898018984, 45.852233144321438],
              [-70.258928136181353, 45.890890830398298],
              [-70.240981666216655, 45.938998173071724],
              [-70.266106724167244, 45.963051844408433],
              [-70.316356840068394, 45.963051844408433],
              [-70.316356840068394, 46.019749783987834],
              [-70.280463900138997, 46.052394052230518],
              [-70.309178252082518, 46.064420887898869],
              [-70.255338842188422, 46.108232932119314],
              [-70.237392372223724, 46.147749677886772],
              [-70.291231782117819, 46.185548304273034],
              [-70.233803078230778, 46.284340168691678],
              [-70.205088726287258, 46.299803243122426],
              [-70.208678020280203, 46.331588451674506],
              [-70.147660022400217, 46.359078361773612],
              [-70.097409906499067, 46.40976288351883],
              [-70.057927672576724, 46.416635361043603],
              [-69.996909674696752, 46.694970700797001],
              [-69.22521146621466, 47.459533825428245],
              [-69.178550644306441, 47.456956646356453],
              [-69.081639706497057, 47.424312378113775],
              [-69.042157472574729, 47.427748616876158],
              [-69.052925354553551, 47.377923154821538],
              [-69.049336060560606, 47.25679573844738],
              [-69.034978884588838, 47.241332664016632],
              [-68.902175006850072, 47.178621306603056],
              [-68.812442657026565, 47.215560873298728],
              [-68.719121013210128, 47.241332664016632],
              [-68.618620781407813, 47.243050783397827],
              [-68.579138547485471, 47.287721887308862],
              [-68.47145972769728, 47.297171543905428],
              [-68.381727377873773, 47.286862827618272],
              [-68.381727377873773, 47.340124528435275],
              [-68.324298673986732, 47.359882901319004],
              [-68.234566324163239, 47.354728543175426],
              [-68.152012562325623, 47.323802394313937],
              [-68.137655386353856, 47.296312484214837],
              [-68.019208684586843, 47.237896425254242],
              [-67.889994100841008, 47.124500546095454],
              [-67.889994100841008, 47.111614650736499],
              [-67.789493869038694, 47.067802606516061],
              [-67.789493869038694, 46.599615075140747],
              [-67.778725987059872, 46.004286709557086],
              [-67.782315281052803, 45.943293471524704],
              [-67.750011635116351, 45.9175216808068],
              [-67.803851045010447, 45.884018352873518],
              [-67.753600929109297, 45.823884174531734],
              [-67.807440339003392, 45.79467614505144],
              [-67.803851045010447, 45.677844027130263],
              [-67.721297283172831, 45.662380952699522],
              [-67.710529401194009, 45.679562146511458],
              [-67.674636461264612, 45.630595744147435],
              [-67.606439875398749, 45.606542072810718],
              [-67.498761055610558, 45.58678369992699],
              [-67.448510939709394, 45.603105834048336],
              [-67.419796587765873, 45.549844133231325],
              [-67.416207293772942, 45.501736790557899],
              [-67.462868115681161, 45.508609268082672],
              [-67.502350349603489, 45.489709954889541],
              [-67.477225291652914, 45.431293895928953],
              [-67.419796587765873, 45.377173135421344],
              [-67.452100233702339, 45.314461778007775],
              [-67.487993173631736, 45.279240330693298],
              [-67.40543941179412, 45.179589406584057],
              [-67.40543941179412, 45.159831033700328],
              [-67.340832119921203, 45.125468646076456],
              [-67.294171298012984, 45.149522317413165],
              [-67.290582004020038, 45.189039063180623],
              [-67.225974712147121, 45.163267272462718],
              [-67.157778126281258, 45.160690093390926],
              [-67.111117304373039, 45.1125827507175],
              [-67.089581540415409, 45.068770706497055],
              [-67.118295892358915, 45.056743870828704],
              [-67.082402952429518, 45.029253960729598],
              [-67.039331424514245, 44.945066111051105],
              [-66.985492014620149, 44.912421842808421],
              [-66.981902720627204, 44.811052799317984],
              [-67.024974248542478, 44.768099814788144],
              [-67.060867188471889, 44.769817934169332],
              [-67.075224364443642, 44.742328024070233],
              [-67.190081772217724, 44.645254279032784],
              [-67.276224828048285, 44.62377778676786],
              [-67.261867652076518, 44.604878473574729],
              [-67.315707061970613, 44.598005996049956],
              [-67.294171298012984, 44.634086503055023],
              [-67.308528473984737, 44.707106576755763],
              [-67.40543941179412, 44.681334786037851],
              [-67.362367883878832, 44.631509323983231],
              [-67.40543941179412, 44.594569757287566],
              [-67.444921645716462, 44.605737533265327],
              [-67.491582467624681, 44.555912071210706],
              [-67.5382432895329, 44.571375145641447],
              [-67.574136229462297, 44.561066429354284],
              [-67.566957641476407, 44.530999340183392],
              [-67.50952893758938, 44.49663695255952],
              [-67.58849340543405, 44.447670550195497],
              [-67.635154227342269, 44.487187295962954],
              [-67.656689991299913, 44.536153698326977],
              [-67.703350813208132, 44.527563101421009],
              [-67.714118695186954, 44.494918833178325],
              [-67.742833047130475, 44.497496012250117],
              [-67.76795810508105, 44.548180533995335],
              [-67.793083163031625, 44.494918833178325],
              [-67.825386808968091, 44.482891997509967],
              [-67.854101160911611, 44.4193215804058],
              [-67.879226218862186, 44.435643714527139],
              [-67.90076198281983, 44.394408849378486],
              [-67.915119158791583, 44.430489356383553],
              [-67.986905038650391, 44.386677312163116],
              [-68.00485150861509, 44.409871923809234],
              [-68.047923036530364, 44.330838432274312],
              [-68.105351740417404, 44.364341760207594],
              [-68.105351740417404, 44.395267909069084],
              [-68.141244680346801, 44.37722765556655],
              [-68.180726914269144, 44.38495919278192],
              [-68.173548326283267, 44.328261253202527],
              [-68.230977030170294, 44.267268015170146],
              [-68.191494796247966, 44.238919045380449],
              [-68.317120086000855, 44.225174090330896],
              [-68.399673847838471, 44.252664000429995],
              [-68.457102551725512, 44.344583387323866],
              [-68.435566787767883, 44.369496118351179],
              [-68.356602319923198, 44.392690729997291],
              [-68.388905965859664, 44.431348416074151],
              [-68.435566787767883, 44.401281326903259],
              [-68.428388199781992, 44.439079953289529],
              [-68.464281139711389, 44.436502774217736],
              [-68.460691845718458, 44.378086715257147],
              [-68.478638315683156, 44.378086715257147],
              [-68.478638315683156, 44.433066535455346],
              [-68.564781371513718, 44.38495919278192],
              [-68.546834901549019, 44.354892103611029],
              [-68.568370665506649, 44.317952536915364],
              [-68.518120549605499, 44.260395537645373],
              [-68.528888431584321, 44.221737851568506],
              [-68.611442193421937, 44.155590255392546],
              [-68.579138547485471, 44.146140598795981],
              [-68.622210075400758, 44.119509748387479],
              [-68.582727841478416, 44.071402405714053],
              [-68.600674311443115, 44.01212728706286],
              [-68.658103015330155, 44.003536690156892],
              [-68.668870897308977, 44.076556763857631],
              [-68.647335133351334, 44.079993002620022],
              [-68.658103015330155, 44.12724128560285],
              [-68.719121013210128, 44.165898971679709],
              [-68.733478189181895, 44.220878791877908],
              [-68.679638779287785, 44.264690836098353],
              [-68.747835365153648, 44.299053223722233],
              [-68.726299601196004, 44.321388775677747],
              [-68.762192541125415, 44.329979372583722],
              [-68.826799832998333, 44.311939119081181],
              [-68.823210539005387, 44.409012864118637],
              [-68.783728305083045, 44.446811490504899],
              [-68.780139011090114, 44.48976447503474],
              [-68.805264069040689, 44.501791310703098],
              [-68.830389126991264, 44.46227456493564],
              [-68.880639242892428, 44.427912177311768],
              [-68.920121476814771, 44.456261147101465],
              [-68.945246534765346, 44.428771237002366],
              [-68.999085944659441, 44.425334998239975],
              [-68.948835828758291, 44.355751163301626],
              [-68.959603710737099, 44.314516298152974],
              [-69.002675238652387, 44.294757925269245],
              [-69.038568178581784, 44.233764687236864],
              [-69.052925354553551, 44.171912389513885],
              [-69.09958617646177, 44.104905733647328],
              [-69.031389590595907, 44.079133942929424],
              [-69.078050412504126, 44.055080271592708],
              [-69.042157472574729, 44.006113869228685],
              [-69.078050412504126, 43.973469600986],
              [-69.174961350313495, 43.97690583974839],
              [-69.221622172221714, 43.915053542025412],
              [-69.275461582115824, 43.914194482334814],
              [-69.282640170101701, 43.864369020280193],
              [-69.322122404024043, 43.856637483064823],
              [-69.322122404024043, 43.901308586975865],
              [-69.296997346073454, 43.933093795527945],
              [-69.329300992009919, 43.945979690886901],
              [-69.340068873988741, 43.920207900168997],
              [-69.383140401904015, 43.909040124191236],
              [-69.365193931939316, 43.964879004080032],
              [-69.429801223812234, 43.957147466864662],
              [-69.426211929819303, 43.912476362953619],
              [-69.483640633706329, 43.880691154401539],
              [-69.501587103671042, 43.837738169871692],
              [-69.544658631586316, 43.881550214092137],
              [-69.551837219572192, 43.841174408634082],
              [-69.605676629466288, 43.813684498534975],
              [-69.648748157381576, 43.836020050490497],
              [-69.659516039360398, 43.779322110911103],
              [-69.706176861268617, 43.823134155131541],
              [-69.752837683176836, 43.744100663596626],
              [-69.835391445014452, 43.720906051950514],
              [-69.85333791497915, 43.704583917829169],
              [-69.871284384943849, 43.775885872148713],
              [-69.928713088830889, 43.780181170601701],
              [-69.982552498724985, 43.744100663596626],
              [-70.000498968689683, 43.710597335663351],
              [-70.072284848548477, 43.714033574425734],
              [-70.097409906499067, 43.671939649586491],
              [-70.169195786357861, 43.675375888348874],
              [-70.205088726287258, 43.633281963509631],
              [-70.21585660826608, 43.590328978979784],
              [-70.197910138301381, 43.56541624795247],
              [-70.226624490244902, 43.537926337853371],
              [-70.273285312153121, 43.562839068880685],
              [-70.352249779997805, 43.536208218472176],
              [-70.384553425934257, 43.496691472704718],
              [-70.363017661976613, 43.439134473434727],
              [-70.391732013920134, 43.402194906739062],
              [-70.427624953849531, 43.389309011380107],
              [-70.416857071870723, 43.36096004159041],
              [-70.467107187771873, 43.340342609016083],
              [-70.517357303673037, 43.343778847778474],
              [-70.553250243602434, 43.32230235551355],
              [-70.592732477524777, 43.23811450583505],
              [-70.574786007560078, 43.221792371713711],
              [-70.625036123461228, 43.153067596465959],
              [-70.621446829468297, 43.134168283272828],
              [-70.671696945369447, 43.070597866168654],
              [-70.704000591305913, 43.059430090190894]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '25', name: 'Massachusetts' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.276874606146066, 41.310384560135596],
              [-70.230213784237847, 41.289767127561269],
              [-70.126124258442587, 41.29406242601425],
              [-70.06151696656967, 41.308666440754401],
              [-70.018445438654382, 41.368800619096184],
              [-69.961016734767341, 41.278599351583509],
              [-69.964606028760286, 41.252827560865605],
              [-70.000498968689683, 41.239082605816051],
              [-70.118945670456696, 41.242518844578441],
              [-70.266106724167244, 41.29406242601425],
              [-70.276874606146066, 41.310384560135596]
            ]
          ],
          [
            [
              [-70.833215175051748, 41.353337544665443],
              [-70.768607883178831, 41.353337544665443],
              [-70.700411297312968, 41.430652916819163],
              [-70.603500359503599, 41.482196498254979],
              [-70.564018125581256, 41.469310602896023],
              [-70.538893067630681, 41.409176424554239],
              [-70.485053657736572, 41.385981812908121],
              [-70.449160717807175, 41.420344200532],
              [-70.45275001180012, 41.348183186521858],
              [-70.599911065510653, 41.349042246212456],
              [-70.71117917929179, 41.341310708997085],
              [-70.775786471164707, 41.30093490353903],
              [-70.833215175051748, 41.353337544665443]
            ]
          ],
          [
            [
              [-73.26316720827208, 42.745873303123027],
              [-72.459165353853535, 42.726973989929895],
              [-71.744895849258498, 42.707215617046167],
              [-71.292644806148061, 42.696906900759004],
              [-71.256751866218664, 42.736423646526461],
              [-71.181376692366925, 42.737282706217059],
              [-71.184965986359856, 42.790544407034062],
              [-71.131126576465761, 42.821470555895552],
              [-71.062929990599898, 42.80600748146481],
              [-71.030626344663446, 42.859269182281814],
              [-70.901411760917611, 42.886759092380913],
              [-70.847572351023516, 42.860987301663009],
              [-70.818857999079995, 42.87215507764077],
              [-70.811679411094104, 42.810302779917791],
              [-70.779375765157653, 42.693470661996614],
              [-70.689643415334146, 42.653094856538559],
              [-70.646571887418872, 42.689175363543626],
              [-70.596321771517708, 42.659967334063332],
              [-70.653750475404749, 42.582651961909612],
              [-70.736304237242365, 42.576638544075436],
              [-70.872697408974091, 42.546571454904544],
              [-70.829625881058803, 42.503618470374697],
              [-70.894233172931735, 42.460665485844849],
              [-70.91935823088231, 42.468397023060227],
              [-70.937304700847008, 42.417712501315009],
              [-70.951661876818761, 42.452933948629479],
              [-70.991144110741104, 42.407403785027846],
              [-70.951661876818761, 42.343833367923672],
              [-70.99832269872698, 42.35242396482964],
              [-71.016269168691679, 42.328370293492931],
              [-70.987554816748172, 42.30603474153741],
              [-71.005501286712871, 42.282840129891291],
              [-70.955251170811707, 42.270813294222933],
              [-70.933715406854063, 42.301739443084422],
              [-70.883465290952913, 42.308611920609202],
              [-70.851161645016447, 42.268236115151147],
              [-70.765018589185885, 42.244182443814431],
              [-70.721947061270612, 42.208101936809364],
              [-70.714768473284735, 42.168585191041906],
              [-70.639393299432996, 42.088692639816394],
              [-70.642982593425927, 42.045739655286546],
              [-70.668107651376516, 42.012236327353264],
              [-70.71117917929179, 42.007941028900284],
              [-70.700411297312968, 41.987323596325957],
              [-70.625036123461228, 41.943511552105512],
              [-70.581964595545955, 41.950384029630293],
              [-70.553250243602434, 41.929766597055966],
              [-70.524535891658914, 41.858464642736422],
              [-70.542482361623613, 41.815511658206574],
              [-70.495821539715394, 41.774276793057922],
              [-70.413267777877778, 41.74420970388703],
              [-70.291231782117819, 41.733900987599867],
              [-70.258928136181353, 41.714142614716138],
              [-70.190731550315505, 41.751941241102401],
              [-70.122534964449642, 41.758813718627181],
              [-70.025624026640259, 41.787162688416878],
              [-70.004088262682629, 41.808639180681801],
              [-70.00767755667556, 41.876504896238956],
              [-70.029213320633204, 41.928907537365369],
              [-70.075874142541423, 41.90227668695686],
              [-70.075874142541423, 41.985605476944762],
              [-70.093820612506121, 42.03285375992759],
              [-70.147660022400217, 42.062061789407885],
              [-70.179963668336683, 42.056048371573709],
              [-70.197910138301381, 42.022545043640427],
              [-70.237392372223724, 42.073229565385645],
              [-70.190731550315505, 42.082679221982211],
              [-70.115356376463765, 42.06721614755147],
              [-70.032802614626149, 42.017390685496849],
              [-69.968195322753232, 41.911726343553426],
              [-69.935891676816766, 41.809498240372399],
              [-69.928713088830889, 41.691807062760624],
              [-69.982552498724985, 41.580988362673622],
              [-69.989731086710862, 41.543189736287353],
              [-70.014856144661451, 41.55092127350273],
              [-69.971784616746163, 41.647135958849582],
              [-69.996909674696752, 41.66689433173331],
              [-70.09023131851319, 41.662599033280323],
              [-70.2445709602096, 41.62823664565645],
              [-70.352249779997805, 41.635109123181223],
              [-70.377374837948381, 41.611055451844514],
              [-70.438392835828353, 41.605042034010332],
              [-70.492232245722462, 41.551780333193328],
              [-70.610678947489475, 41.543189736287353],
              [-70.732714943249434, 41.486491796707959],
              [-70.790143647136475, 41.446115991249904],
              [-70.865518820988214, 41.422062319913195],
              [-70.94807258282583, 41.409176424554239],
              [-70.926536818868186, 41.431511976509761],
              [-70.800911529115297, 41.460720005990055],
              [-70.743482825228256, 41.50109581144811],
              [-70.657339769397694, 41.543189736287353],
              [-70.639393299432996, 41.577552123911232],
              [-70.646571887418872, 41.678062107711071],
              [-70.625036123461228, 41.713283555025541],
              [-70.718357767277666, 41.735619106981062],
              [-70.718357767277666, 41.684934585235844],
              [-70.743482825228256, 41.696961420904202],
              [-70.757840001200009, 41.654008436374355],
              [-70.808090117101173, 41.656585615446147],
              [-70.800911529115297, 41.629095705347048],
              [-70.912179642896433, 41.619646048750482],
              [-70.937304700847008, 41.577552123911232],
              [-70.930126112861132, 41.53975349752497],
              [-70.951661876818761, 41.514840766497656],
              [-71.041394226642268, 41.495082393613927],
              [-71.084465754557542, 41.509686408354078],
              [-71.120358694486939, 41.49765957268572],
              [-71.131126576465761, 41.660021854208537],
              [-71.195733868338678, 41.675484928639278],
              [-71.224448220282198, 41.710706375953755],
              [-71.260341160211595, 41.751941241102401],
              [-71.328537746077458, 41.780290210892105],
              [-71.346484216042157, 41.82839755356553],
              [-71.33930562805628, 41.897981388503879],
              [-71.382377155971554, 41.892827030360294],
              [-71.382377155971554, 42.019108804878044],
              [-71.798735259152593, 42.007941028900284],
              [-71.802324553145525, 42.023404103331025],
              [-72.527361939719398, 42.034571879308785],
              [-72.606326407564069, 42.031135640546395],
              [-72.757076755267548, 42.036289998689981],
              [-72.76784463724637, 42.002786670756699],
              [-72.818094753147534, 41.997632312613121],
              [-72.814505459154589, 42.036289998689981],
              [-73.008327334773341, 42.038867177761773],
              [-73.431864025940257, 42.050894013430124],
              [-73.485703435834353, 42.050034953739534],
              [-73.507239199791996, 42.086115460744601],
              [-73.385203204032038, 42.425444038530379],
              [-73.26316720827208, 42.745873303123027]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '27', name: 'Minnesota' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.228883199131985, 49.000686910359093],
            [-96.930971797717973, 48.999827850668495],
            [-95.97621959559595, 48.999827850668495],
            [-95.154271271212707, 48.998968790977905],
            [-95.154271271212707, 49.384686592055914],
            [-95.057360333403324, 49.352901383503827],
            [-94.956860101601009, 49.370082577315763],
            [-94.852770575805749, 49.32455241371413],
            [-94.824056223862229, 49.294485324543238],
            [-94.773806107961079, 49.12095526704266],
            [-94.748681050010489, 49.099478774777737],
            [-94.684073758137572, 48.883854792437916],
            [-94.705609522095216, 48.824579673786729],
            [-94.691252346123463, 48.778190450494499],
            [-94.641002230222298, 48.741250883798827],
            [-94.536912704427039, 48.702593197721967],
            [-94.450769648596477, 48.692284481434804],
            [-94.422055296652957, 48.70860661555615],
            [-94.264126360963601, 48.699156958959584],
            [-94.224644127041273, 48.649331496904964],
            [-94.005697193471931, 48.643318079070781],
            [-93.833411081810809, 48.625277825568247],
            [-93.804696729867288, 48.568579885988854],
            [-93.81546461184611, 48.526485961149604],
            [-93.793928847888481, 48.51617724486244],
            [-93.643178500185002, 48.517895364243635],
            [-93.625232030220303, 48.530781259602591],
            [-93.546267562375618, 48.529063140221396],
            [-93.463713800538002, 48.546244334033332],
            [-93.467303094530948, 48.587479199181985],
            [-93.348856392763921, 48.626995944949442],
            [-93.208873927039264, 48.642459019380183],
            [-93.176570281102812, 48.623559706187052],
            [-92.986337699476991, 48.623559706187052],
            [-92.727908531985321, 48.539371856508559],
            [-92.634586888168883, 48.542808095270942],
            [-92.630997594175938, 48.500714170431699],
            [-92.6991941800418, 48.494700752597517],
            [-92.713551356013554, 48.46291554404543],
            [-92.656122652126513, 48.436284693636928],
            [-92.508961598415979, 48.447452469614689],
            [-92.455122188521884, 48.401063246322458],
            [-92.476657952479528, 48.371855216842164],
            [-92.455122188521884, 48.329761292002914],
            [-92.368979132691322, 48.220660711297107],
            [-92.27924678286783, 48.243855322943219],
            [-92.30796113481135, 48.316016336953361],
            [-92.261300312903131, 48.354674023030221],
            [-92.207460903009022, 48.345224366433655],
            [-92.056710555305543, 48.358969321483208],
            [-91.999281851418516, 48.321170695096946],
            [-92.006460439404393, 48.265331815208143],
            [-91.945442441524406, 48.230110367893673],
            [-91.895192325623256, 48.237841905109043],
            [-91.86288867968679, 48.206915756247554],
            [-91.715727625976257, 48.199184219032183],
            [-91.697781156011558, 48.141627219762192],
            [-91.712138331983311, 48.114996369353683],
            [-91.640352452124517, 48.09695611585115],
            [-91.557798690286901, 48.10812389182891],
            [-91.568566572265723, 48.043694415034146],
            [-91.489602104421039, 48.067748086370855],
            [-91.439351988519874, 48.048848773177724],
            [-91.371155402654026, 48.06946620575205],
            [-91.249119406894067, 48.084070220492194],
            [-91.084011883218835, 48.18114396552965],
            [-91.022993885338849, 48.19231174150741],
            [-90.97633306343063, 48.21980165160651],
            [-90.886600713607137, 48.245573442324414],
            [-90.836350597705973, 48.234405666346653],
            [-90.836350597705973, 48.176848667076662],
            [-90.775332599826001, 48.162244652336518],
            [-90.796868363783631, 48.139909100380997],
            [-90.760975423854234, 48.098674235232345],
            [-90.703546719967193, 48.096097056160552],
            [-90.577921430214303, 48.121009787187866],
            [-90.556385666256659, 48.096097056160552],
            [-90.466653316433167, 48.108982951519508],
            [-90.373331672616729, 48.093519877088767],
            [-90.305135086750866, 48.10468765306652],
            [-90.132848975089743, 48.1115601305913],
            [-90.028759449294483, 48.087506459254584],
            [-89.996455803358032, 48.03080851967519],
            [-89.874419807598073, 47.98527835607355],
            [-89.820580397703978, 48.015345445244442],
            [-89.74879451784517, 48.02307698245982],
            [-89.702133695936951, 48.005895788647877],
            [-89.619579934099335, 48.011050146791462],
            [-89.580097700176992, 47.995587072360713],
            [-89.4903653503535, 48.014486385553845],
            [-89.587276288162883, 47.966379042880419],
            [-89.62316922809228, 47.983560236692362],
            [-89.637526404064033, 47.954352207212061],
            [-89.698544401944019, 47.941466311853112],
            [-89.791866045760457, 47.891640849798492],
            [-89.924669923499238, 47.862432820318197],
            [-89.974920039400388, 47.830647611766111],
            [-90.161563327033264, 47.792848985379848],
            [-90.333849438694386, 47.746459762087611],
            [-90.387688848588482, 47.741305403944033],
            [-90.538439196291961, 47.702647717867173],
            [-90.552796372263714, 47.690620882198814],
            [-90.735850365903659, 47.624473286022855],
            [-90.868654243642439, 47.556607570465694],
            [-91.127083411134109, 47.399399647086462],
            [-91.188101409014081, 47.340124528435275],
            [-91.385512578625779, 47.187211903509031],
            [-91.478834222442217, 47.125359605786052],
            [-91.572155866258655, 47.090138158471575],
            [-91.665477510075092, 47.01454090569905],
            [-91.704959743997435, 47.005091249102485],
            [-91.805459975799749, 46.93378929478294],
            [-91.841352915729146, 46.925198697876972],
            [-92.060299849298488, 46.810084699336983],
            [-92.092603495234954, 46.788608207072066],
            [-92.063889143291433, 46.745655222542219],
            [-92.013639027390269, 46.706138476774761],
            [-92.089014201242009, 46.749091461304609],
            [-92.139264317143173, 46.739641804708036],
            [-92.150032199121981, 46.714729073680729],
            [-92.207460903009022, 46.702702238012371],
            [-92.17515725707257, 46.686380103891032],
            [-92.214639490994912, 46.649440537195368],
            [-92.293603958839583, 46.663185492244914],
            [-92.293603958839583, 46.074729604186032],
            [-92.32949689876898, 46.066139007280064],
            [-92.351032662726624, 46.015454485534846],
            [-92.437175718557185, 46.021467903369029],
            [-92.473068658486582, 45.973360560695596],
            [-92.523318774387747, 45.984528336673357],
            [-92.552033126331253, 45.95188406843068],
            [-92.64176547615476, 45.932125695546951],
            [-92.713551356013554, 45.891749890088896],
            [-92.785337235872348, 45.764609055880548],
            [-92.864301703717032, 45.722515131041305],
            [-92.889426761667607, 45.624582326313252],
            [-92.885837467674676, 45.579052162711619],
            [-92.810462293822937, 45.561011909209086],
            [-92.77456935389354, 45.568743446424456],
            [-92.724319237992376, 45.541253536325357],
            [-92.727908531985321, 45.514622685916855],
            [-92.645354770147691, 45.441602612216116],
            [-92.648944064140636, 45.398649627686268],
            [-92.702783474034732, 45.358273822228213],
            [-92.6991941800418, 45.333361091200906],
            [-92.760212177921773, 45.291267166361656],
            [-92.753033589935896, 45.213092734517339],
            [-92.767390765907649, 45.18560282441824],
            [-92.745855001950019, 45.107428392573915],
            [-92.80328370583706, 45.057602930519295],
            [-92.763801471914718, 45.028394901039],
            [-92.770980059900594, 44.972556021150204],
            [-92.749444295942951, 44.937334573835734],
            [-92.77456935389354, 44.90125406683066],
            [-92.763801471914718, 44.836824590035896],
            [-92.806872999829991, 44.768099814788144],
            [-92.788926529865293, 44.737173665926655],
            [-92.731497825978252, 44.713979054280536],
            [-92.620229712197116, 44.639240861198601],
            [-92.623819006190061, 44.618623428624282],
            [-92.573568890288897, 44.604878473574729],
            [-92.541265244352445, 44.567079847188467],
            [-92.361800544705446, 44.559348309973089],
            [-92.318729016790158, 44.544744295232945],
            [-92.282836076860761, 44.477737639366389],
            [-92.232585960959611, 44.445093371123704],
            [-92.053121261312612, 44.401281326903259],
            [-91.974156793467927, 44.367777998969984],
            [-91.923906677566777, 44.333415611346105],
            [-91.923906677566777, 44.287885447744472],
            [-91.895192325623256, 44.274999552385516],
            [-91.877245855658558, 44.202838538375374],
            [-91.816227857778571, 44.164180852298514],
            [-91.719316919969202, 44.128959404984045],
            [-91.70854903799038, 44.104046673956731],
            [-91.579334454244545, 44.026731301803011],
            [-91.439351988519874, 44.001818570775697],
            [-91.385512578625779, 43.954570287792869],
            [-91.277833758837588, 43.837738169871692],
            [-91.245530112901122, 43.77330869307692],
            [-91.274244464844642, 43.676234948039472],
            [-91.267065876858766, 43.615241710007091],
            [-91.231172936929369, 43.583456501455011],
            [-91.245530112901122, 43.545657875068741],
            [-91.216815760957601, 43.500127711467108],
            [-91.949031735517352, 43.500127711467108],
            [-92.753033589935896, 43.500127711467108],
            [-93.169391693116921, 43.499268651776511],
            [-93.969804253542534, 43.499268651776511],
            [-94.615877172271723, 43.500127711467108],
            [-95.73932619206191, 43.500127711467108],
            [-96.453595696656961, 43.500127711467108],
            [-96.453595696656961, 44.543885235542348],
            [-96.450006402664016, 44.630650264292633],
            [-96.453595696656961, 44.890086290852899],
            [-96.453595696656961, 45.30157588264882],
            [-96.489488636586358, 45.357414762537616],
            [-96.618703220332193, 45.408099284282834],
            [-96.683310512205111, 45.411535523045224],
            [-96.733560628106275, 45.458783806028052],
            [-96.765864274042741, 45.521495163441628],
            [-96.859185917859179, 45.605683013120121],
            [-96.84123944789448, 45.645199758887578],
            [-96.751507098070974, 45.698461459704589],
            [-96.672542630226303, 45.731964787637871],
            [-96.629471102311015, 45.786085548145472],
            [-96.579220986409865, 45.825602293912929],
            [-96.564863810438098, 45.935561934309334],
            [-96.579220986409865, 46.026622261512607],
            [-96.557685222452221, 46.058407470064694],
            [-96.597167456374564, 46.219910691896914],
            [-96.600756750367495, 46.330729391983915],
            [-96.647417572275714, 46.353924003630027],
            [-96.719203452134522, 46.438111853308527],
            [-96.737149922099221, 46.480205778147777],
            [-96.751507098070974, 46.582433881328804],
            [-96.790989331993316, 46.631400283692827],
            [-96.798167919979193, 46.664903611626109],
            [-96.780221450014494, 46.723319670586697],
            [-96.801757213972138, 46.819534355933548],
            [-96.78381074400744, 46.834138370673699],
            [-96.762274980049796, 46.934648354473538],
            [-96.78381074400744, 46.925198697876972],
            [-96.823292977929768, 46.96901074209741],
            [-96.819703683936837, 47.115909949189486],
            [-96.837650153901535, 47.194943440724401],
            [-96.844828741887412, 47.292876245452447],
            [-96.83406085990859, 47.335829229982295],
            [-96.859185917859179, 47.424312378113775],
            [-96.855596623866234, 47.610728330973302],
            [-96.873543093830932, 47.613305510045095],
            [-96.898668151781507, 47.689761822508217],
            [-96.93815038570385, 47.768795314043132],
            [-96.96686473764737, 47.783399328783283],
            [-97.006346971569712, 47.870164357533568],
            [-97.017114853548534, 47.919989819588189],
            [-97.056597087470877, 47.949197849068483],
            [-97.074543557435575, 48.053144071630712],
            [-97.131972261322602, 48.139909100380997],
            [-97.142740143301424, 48.234405666346653],
            [-97.117615085350849, 48.279935829948293],
            [-97.146329437294369, 48.359828381173806],
            [-97.135561555315547, 48.404499485084841],
            [-97.139150849308493, 48.496418871978712],
            [-97.164275907259068, 48.54710339372393],
            [-97.139150849308493, 48.612391930209292],
            [-97.106847203372027, 48.633009362783618],
            [-97.096079321393205, 48.686271063600628],
            [-97.149918731287315, 48.75499583884838],
            [-97.178633083230821, 48.861519240482394],
            [-97.200168847188465, 48.882136673056721],
            [-97.239651081110807, 48.966324522735221],
            [-97.228883199131985, 49.000686910359093]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '34', name: 'New Jersey' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.560315363753631, 39.630063805328049],
            [-75.510065247852481, 39.685902685216845],
            [-75.477761601916015, 39.713392595315945],
            [-75.459815131951316, 39.765795236442358],
            [-75.416743604036043, 39.801875743447425],
            [-75.355725606156057, 39.839674369833695],
            [-75.219332434424345, 39.861150862098611],
            [-75.129600084600838, 39.899808548175479],
            [-75.129600084600838, 39.959083666826665],
            [-75.072171380713812, 39.980560159091581],
            [-75.047046322763222, 40.008909128881285],
            [-74.925010327003264, 40.070761426604257],
            [-74.860403035130346, 40.083647321963213],
            [-74.817331507215073, 40.127459366183658],
            [-74.781438567285676, 40.120586888658877],
            [-74.72042056940569, 40.154090216592159],
            [-74.770670685306854, 40.215083454624541],
            [-74.842456565165648, 40.25030490193901],
            [-74.867581623116223, 40.294976005850053],
            [-74.942956796967962, 40.341365229142284],
            [-74.968081854918552, 40.399781288102872],
            [-75.057814204742044, 40.416103422224218],
            [-75.072171380713812, 40.454761108301078],
            [-75.064992792727921, 40.536371778907785],
            [-75.100885732657318, 40.568156987459865],
            [-75.161903730537304, 40.563861689006885],
            [-75.194207376473756, 40.575888524675243],
            [-75.201385964459647, 40.64719047899478],
            [-75.176260906509057, 40.672962269712691],
            [-75.204975258452578, 40.691861582905823],
            [-75.172671612516126, 40.777767551965511],
            [-75.133189378593784, 40.77347225351253],
            [-75.050635616756168, 40.870545998549979],
            [-75.136778672586729, 40.97363316142161],
            [-75.09011785067851, 41.014868026570262],
            [-75.025510558805593, 41.039780757597569],
            [-74.968081854918552, 41.087888100270995],
            [-74.982439030890305, 41.108505532845321],
            [-74.921421033010333, 41.138572622016213],
            [-74.88193879908799, 41.180666546855463],
            [-74.831688683186826, 41.287189948489477],
            [-74.752724215342155, 41.346465067140663],
            [-74.695295511455114, 41.357632843118424],
            [-74.379437640076404, 41.209015516645159],
            [-73.905650833008323, 40.997686832758319],
            [-73.894882951029501, 40.98136469863698],
            [-73.96666883088831, 40.820720536495358],
            [-74.009740358803583, 40.764881656606562],
            [-74.02409753477535, 40.709042776717759],
            [-74.04563329873298, 40.690143463524628],
            [-74.070758356683569, 40.660935434044333],
            [-74.203562234422336, 40.630868344873441],
            [-74.217919410394103, 40.558707330863299],
            [-74.250223056330555, 40.544962375813753],
            [-74.260990938309376, 40.502009391283906],
            [-74.260990938309376, 40.465069824588241],
            [-74.192794352443528, 40.441016153251525],
            [-74.135365648556487, 40.456479227682273],
            [-74.049222592725926, 40.418680601296003],
            [-73.998972476824761, 40.410949064080633],
            [-73.984615300853008, 40.448747690466895],
            [-73.970258124881241, 40.371432318313175],
            [-73.981026006860063, 40.279512931419305],
            [-74.031276122761227, 40.12316406773067],
            [-74.077936944669446, 39.910976324153232],
            [-74.095883414634145, 39.762358997679968],
            [-74.239455174351747, 39.555325612246115],
            [-74.278937408274075, 39.514090747097462],
            [-74.311241054210541, 39.506359209882092],
            [-74.300473172231719, 39.478869299782993],
            [-74.336366112161116, 39.431621016800165],
            [-74.408151992019924, 39.377500256292556],
            [-74.411741286012855, 39.361178122171218],
            [-74.523009399793992, 39.313929839188383],
            [-74.637866807568074, 39.221151392603922],
            [-74.713241981419813, 39.119782349113486],
            [-74.792206449264484, 38.991782455214548],
            [-74.863992329123292, 38.940238873778732],
            [-74.932188914989155, 38.928212038110374],
            [-74.971671148911483, 38.940238873778732],
            [-74.950135384953853, 39.015836126551257],
            [-74.896295975059743, 39.099164916539159],
            [-74.885528093080936, 39.158440035190345],
            [-74.960903266932661, 39.190225243742432],
            [-75.025510558805593, 39.193661482504815],
            [-75.047046322763222, 39.215137974769739],
            [-75.11165361463614, 39.212560795697954],
            [-75.136778672586729, 39.181634646836464],
            [-75.169082318523181, 39.20225207941079],
            [-75.176260906509057, 39.242627884868845],
            [-75.240868198381975, 39.274413093420925],
            [-75.251636080360797, 39.300184884138837],
            [-75.287529020290208, 39.289876167851673],
            [-75.341368430184303, 39.348292226812262],
            [-75.366493488134878, 39.341419749287489],
            [-75.431100780007796, 39.39124521134211],
            [-75.466993719937193, 39.439352554015535],
            [-75.535190305803056, 39.460829046280459],
            [-75.513654541845412, 39.581097402964026],
            [-75.5567260697607, 39.606010133991333],
            [-75.560315363753631, 39.630063805328049]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '37', name: 'North Carolina' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.725422887428877, 35.936107135761354],
              [-75.66081559555596, 35.919785001640008],
              [-75.617744067640672, 35.856214584535842],
              [-75.614154773647741, 35.815838779077787],
              [-75.667994183541836, 35.823570316293157],
              [-75.66081559555596, 35.862228002370017],
              [-75.725422887428877, 35.936107135761354]
            ]
          ],
          [
            [
              [-76.012566406864067, 35.068456848258478],
              [-75.983852054920547, 35.120000429694294],
              [-75.955137702977026, 35.120000429694294],
              [-75.786440885308849, 35.193879563085623],
              [-75.732601475414754, 35.204188279372786],
              [-75.682351359513589, 35.232537249162483],
              [-75.639279831598316, 35.234255368543678],
              [-75.581851127711275, 35.26432245771457],
              [-75.535190305803056, 35.272913054620538],
              [-75.517243835838357, 35.336483471724712],
              [-75.477761601916015, 35.552966513755131],
              [-75.477761601916015, 35.599355737047361],
              [-75.506475953859535, 35.680966407654068],
              [-75.528011717817179, 35.771167675166744],
              [-75.517243835838357, 35.769449555785549],
              [-75.477761601916015, 35.678389228582283],
              [-75.459815131951316, 35.596778557975576],
              [-75.470583013930138, 35.479946440054391],
              [-75.524422423824234, 35.234255368543678],
              [-75.610565479654795, 35.227382891018905],
              [-75.790030179301795, 35.1724030708207],
              [-75.944369820998205, 35.105396414954143],
              [-76.012566406864067, 35.068456848258478]
            ]
          ],
          [
            [
              [-81.676472327723275, 36.588133440924402],
              [-81.374971632316317, 36.574388485874856],
              [-81.177560462704619, 36.571811306803063],
              [-80.836577533375333, 36.558925411444108],
              [-80.703773655636553, 36.562361650206498],
              [-80.294594140441404, 36.544321396703964],
              [-80.122308028780282, 36.542603277322769],
              [-79.512128049980504, 36.540885157941574],
              [-78.510715025950262, 36.540885157941574],
              [-78.245107270472701, 36.544321396703964],
              [-77.297533656336554, 36.544321396703964],
              [-76.917068493084926, 36.543462337013366],
              [-76.917068493084926, 36.552052933919335],
              [-76.80580037930379, 36.550334814538139],
              [-76.375085100150997, 36.550334814538139],
              [-75.865405353153534, 36.550334814538139],
              [-75.858226765167643, 36.500509352483519],
              [-75.797208767287671, 36.290039728287276],
              [-75.73978006340063, 36.154308297172967],
              [-75.657226301563014, 36.020294985439847],
              [-75.53160101181011, 35.78748980928809],
              [-75.563904657746576, 35.799516644956441],
              [-75.628511949619494, 35.924939359783593],
              [-75.678762065520658, 35.993664135031345],
              [-75.725422887428877, 36.003113791627911],
              [-75.757726533365329, 36.152590177791772],
              [-75.81515523725237, 36.285744429834295],
              [-75.84386958919589, 36.305502802718024],
              [-75.833101707217068, 36.339006130651299],
              [-75.851048177181767, 36.415462443114428],
              [-75.886941117111164, 36.441234233832333],
              [-75.901298293082931, 36.482469098980985],
              [-75.991030642906424, 36.493636874958746],
              [-75.994619936899369, 36.527999262582618],
              [-76.041280758807588, 36.510818068770682],
              [-76.019744994849944, 36.458415427644269],
              [-75.962316290962903, 36.417180562495616],
              [-75.922834057040575, 36.425771159401584],
              [-75.922834057040575, 36.368214160131593],
              [-75.868994647146465, 36.252241101901014],
              [-75.836691001210013, 36.1998384607746],
              [-75.840280295202945, 36.177502908819079],
              [-75.800798061280616, 36.113073432024315],
              [-75.800798061280616, 36.07269762656626],
              [-75.868994647146465, 36.127677446764459],
              [-75.865405353153534, 36.159462655316545],
              [-75.912066175061753, 36.211865296442959],
              [-75.922834057040575, 36.244509564685643],
              [-75.965905584955848, 36.254818280972806],
              [-75.944369820998205, 36.222174012730122],
              [-75.955137702977026, 36.198120341393405],
              [-75.904887587075862, 36.164617013460131],
              [-76.016155700857013, 36.186093505725054],
              [-76.13101310863108, 36.287462549215483],
              [-76.18485251852519, 36.297771265502647],
              [-76.116655932659327, 36.214442475514751],
              [-76.08076299272993, 36.1998384607746],
              [-76.059227228772286, 36.155167356863565],
              [-76.091530874708738, 36.135408983979836],
              [-76.177673930539299, 36.123382148311478],
              [-76.253049104391039, 36.184375386343859],
              [-76.227924046440464, 36.130254625836251],
              [-76.192031106511067, 36.107060014190132],
              [-76.217156164461642, 36.095033178521781],
              [-76.328424278242778, 36.133690864598641],
              [-76.375085100150997, 36.137986163051622],
              [-76.393031570115696, 36.162898894078936],
              [-76.457638861988613, 36.183516326653262],
              [-76.375085100150997, 36.120804969239686],
              [-76.299709926299258, 36.101046596355957],
              [-76.324834984249847, 36.084724462234618],
              [-76.410978040080394, 36.077851984709838],
              [-76.457638861988613, 36.024590283892834],
              [-76.515067565875654, 36.005690970699703],
              [-76.57608556375564, 36.006550030390301],
              [-76.604799915699161, 36.033180880798803],
              [-76.676585795557955, 36.043489597085966],
              [-76.719657323473228, 36.147435819648187],
              [-76.719657323473228, 36.1998384607746],
              [-76.676585795557955, 36.266845116641157],
              [-76.694532265522653, 36.278012892618918],
              [-76.744782381423818, 36.212724356133556],
              [-76.751960969409694, 36.147435819648187],
              [-76.723246617466174, 36.066684208732084],
              [-76.683764383543831, 36.000536612556118],
              [-76.70171085350853, 35.964456105551051],
              [-76.672996501565009, 35.935248076070756],
              [-76.529424741847421, 35.943838672976725],
              [-76.396620864108641, 35.984214478434779],
              [-76.364317218172175, 35.942120553595529],
              [-76.317656396263956, 35.947274911739115],
              [-76.270995574355737, 35.973046702457019],
              [-76.177673930539299, 35.993664135031345],
              [-76.062816522765232, 35.992805075340748],
              [-76.012566406864067, 35.957583628026271],
              [-76.012566406864067, 35.920644061330606],
              [-76.062816522765232, 35.853637405464049],
              [-76.05204864078641, 35.806389122481221],
              [-76.044870052800519, 35.665503333223327],
              [-76.012566406864067, 35.669798631676308],
              [-75.987441348913492, 35.768590496094951],
              [-75.97667346693467, 35.897449449684494],
              [-75.926423351033506, 35.931811837308366],
              [-75.94795911499115, 35.960160807098063],
              [-75.897708999089986, 35.977342000910006],
              [-75.807976649266493, 35.959301747407466],
              [-75.750547945379452, 35.878550136491356],
              [-75.725422887428877, 35.82271125660256],
              [-75.73978006340063, 35.778040152691524],
              [-75.714655005450055, 35.693852303013024],
              [-75.743369357393576, 35.6723758107481],
              [-75.729012181421808, 35.62598658745587],
              [-75.775673003330027, 35.579597364163632],
              [-75.836691001210013, 35.571006767257664],
              [-75.858226765167643, 35.586469841688412],
              [-75.894119705097054, 35.572724886638859],
              [-75.915655469054684, 35.538362499014987],
              [-75.951548408984081, 35.530630961799609],
              [-75.962316290962903, 35.493691395103944],
              [-75.987441348913492, 35.484241738507379],
              [-76.012566406864067, 35.423248500474998],
              [-76.059227228772286, 35.410362605116042],
              [-76.069995110751108, 35.370845859348584],
              [-76.13101310863108, 35.349369367083668],
              [-76.141780990609902, 35.328751934509341],
              [-76.23510263442634, 35.350228426774265],
              [-76.3391921602216, 35.346792188011875],
              [-76.432513804038038, 35.362255262442616],
              [-76.450460274002737, 35.38373175470754],
              [-76.486353213932134, 35.371704919039182],
              [-76.540192623826229, 35.410362605116042],
              [-76.586853445734448, 35.509154469534693],
              [-76.475585331953312, 35.511731648606478],
              [-76.457638861988613, 35.550389334683338],
              [-76.558139093790942, 35.528912842418421],
              [-76.601210621706215, 35.538362499014987],
              [-76.633514267642681, 35.510013529225283],
              [-76.601210621706215, 35.46104712686126],
              [-76.579674857748572, 35.38716799346993],
              [-76.604799915699161, 35.38716799346993],
              [-76.708889441494406, 35.427543798927985],
              [-76.759139557395571, 35.418953202022017],
              [-76.830925437254365, 35.448161231502311],
              [-76.942193551035501, 35.473933022220216],
              [-77.024747312873131, 35.515167887368868],
              [-77.028336606866063, 35.490255156341554],
              [-76.967318608986091, 35.437852515215148],
              [-76.891943435134351, 35.433557216762161],
              [-76.665817913579133, 35.345933128321278],
              [-76.500710389903901, 35.321879456984561],
              [-76.47199603796038, 35.29524860657606],
              [-76.468406743967435, 35.26088621895218],
              [-76.49353180191801, 35.212778876278755],
              [-76.540192623826229, 35.166389652986524],
              [-76.536603329833298, 35.142335981649808],
              [-76.56890697576975, 35.096805818048175],
              [-76.622746385663859, 35.060725311043107],
              [-76.802211085310844, 34.964510625696249],
              [-76.981675784957844, 35.060725311043107],
              [-76.978086490964913, 35.004886431154304],
              [-76.891943435134351, 34.957638148171476],
              [-76.762728851388516, 34.920698581475811],
              [-76.633514267642681, 34.989423356723563],
              [-76.586853445734448, 34.991141476104758],
              [-76.489942507925079, 35.016913266822662],
              [-76.475585331953312, 35.070174967639673],
              [-76.436103098030983, 35.058148131971315],
              [-76.425335216052162, 35.001450192391921],
              [-76.396620864108641, 34.974819341983412],
              [-76.332013572235724, 34.970524043530432],
              [-76.364317218172175, 35.034953520325196],
              [-76.292531338313381, 35.010040789297889],
              [-76.285352750327505, 34.93702071559715],
              [-76.346370748207477, 34.872591238802379],
              [-76.410978040080394, 34.861423462824625],
              [-76.410978040080394, 34.832215433344331],
              [-76.450460274002737, 34.815034239532388],
              [-76.500710389903901, 34.742873225522253],
              [-76.525835447854476, 34.681879987489872],
              [-76.586853445734448, 34.699061181301808],
              [-76.583264151741517, 34.76778595654956],
              [-76.619157091670914, 34.784108090670898],
              [-76.615567797677969, 34.713665196041951],
              [-76.672996501565009, 34.707651778207776],
              [-76.522246153861531, 34.652671958009577],
              [-76.439692392023915, 34.758336299952994],
              [-76.321245690256902, 34.861423462824625],
              [-76.23510263442634, 34.925852939619389],
              [-76.066405816758163, 35.077047445164446],
              [-76.037691464814642, 35.059007191661912],
              [-76.138191696616957, 34.987705237342368],
              [-76.231513340433409, 34.905235507045063],
              [-76.31047780827808, 34.85197380622806],
              [-76.385852982129819, 34.784967150361496],
              [-76.450460274002737, 34.714524255732549],
              [-76.525835447854476, 34.615732391313905],
              [-76.536603329833298, 34.588242481214806],
              [-76.550960505805051, 34.645799480484797],
              [-76.676585795557955, 34.693047763467632],
              [-76.816568261282612, 34.693906823158223],
              [-77.031925900859008, 34.661262554915545],
              [-77.136015426654268, 34.632913585125848],
              [-77.207801306513062, 34.605423675026742],
              [-77.322658714287144, 34.535839840088393],
              [-77.462641180011801, 34.471410363293629],
              [-77.555962823828239, 34.41728960278602],
              [-77.688766701567019, 34.320215857748572],
              [-77.739016817468169, 34.272967574765744],
              [-77.828749167291676, 34.16300793436934],
              [-77.878999283192826, 34.067652308713079],
              [-77.914892223122223, 33.971437623366228],
              [-77.947195869058689, 33.912162504715042],
              [-77.961553045030442, 33.852887386063856],
              [-78.008213866938661, 33.858900803898031],
              [-78.018981748917483, 33.888108833378325],
              [-78.094356922769222, 33.906149086880866],
              [-78.176910684606838, 33.913880624096237],
              [-78.277410916409167, 33.912162504715042],
              [-78.385089736197358, 33.901853788427879],
              [-78.510715025950262, 33.865773281422811],
              [-78.539429377893782, 33.85116926668266],
              [-78.873233719237192, 34.134658964579643],
              [-79.246520294502943, 34.445638572575717],
              [-79.35778840828408, 34.545289496684958],
              [-79.67364627966279, 34.804725523245224],
              [-79.892593213232132, 34.805584582935822],
              [-80.158200968709679, 34.811598000770005],
              [-80.797095299452991, 34.820188597675973],
              [-80.782738123481238, 34.936161655906552],
              [-80.933488471184702, 35.107114534335338],
              [-81.041167290972908, 35.044403176921762],
              [-81.059113760937606, 35.062443430424295],
              [-81.033988702987031, 35.103678295572948],
              [-81.041167290972908, 35.149208459174588],
              [-81.238578460584606, 35.160376235152349],
              [-81.615454329843303, 35.174121190201895],
              [-82.290241600515998, 35.199033921229208],
              [-82.372795362353614, 35.181852727417265],
              [-82.390741832318326, 35.215356055350547],
              [-82.4338133602336, 35.170684951439512],
              [-82.458938418184175, 35.178416488654882],
              [-82.530724298042983, 35.155221877008763],
              [-82.552260062000613, 35.159517175461751],
              [-82.656349587795873, 35.119141370003696],
              [-82.756849819598187, 35.06759778856788],
              [-82.781974877548777, 35.085638042070414],
              [-83.108600630906309, 35.000591132701324],
              [-83.621869671896718, 34.992000535795349],
              [-83.618280377903773, 34.986846177651771],
              [-84.321782000520002, 34.988564297032966],
              [-84.289478354583537, 35.224805711947113],
              [-84.210513886738866, 35.266040577095765],
              [-84.199746004760044, 35.243705025140244],
              [-84.09924577295773, 35.247141263902634],
              [-84.020281305113045, 35.301262024410235],
              [-84.038227775077743, 35.350228426774265],
              [-84.005924129141292, 35.371704919039182],
              [-84.020281305113045, 35.409503545425451],
              [-83.959263307233073, 35.462765246242455],
              [-83.912602485324854, 35.475651141601411],
              [-83.883888133381333, 35.516886006750063],
              [-83.826459429494292, 35.523758484274836],
              [-83.772620019600197, 35.562416170351696],
              [-83.485476500164992, 35.568429588185879],
              [-83.445994266242664, 35.611382572715719],
              [-83.367029798397979, 35.638872482814826],
              [-83.349083328433281, 35.66120803477034],
              [-83.313190388503884, 35.655194616936164],
              [-83.255761684616843, 35.696429482084817],
              [-83.24140450864509, 35.726496571255709],
              [-83.183975804758049, 35.729932810018092],
              [-83.155261452814528, 35.764295197641971],
              [-83.079886278962789, 35.789207928669278],
              [-82.993743223132228, 35.773744854238537],
              [-82.90042157931579, 35.87253671865718],
              [-82.911189461294612, 35.926657479164788],
              [-82.875296521365215, 35.952429269882693],
              [-82.821457111471105, 35.921503121021203],
              [-82.749671231612311, 36.005690970699703],
              [-82.631224529845298, 36.065825149041487],
              [-82.595331589915901, 36.031462761417608],
              [-82.613278059880599, 35.971328583075824],
              [-82.559438649986504, 35.954147389263888],
              [-82.458938418184175, 36.007409090080891],
              [-82.40509900829008, 36.087301641306404],
              [-82.351259598395984, 36.117368730477295],
              [-82.290241600515998, 36.135408983979836],
              [-82.247170072600724, 36.131113685526849],
              [-82.214866426664258, 36.159462655316545],
              [-82.14666984079841, 36.149153939029382],
              [-82.132312664826642, 36.106200954499542],
              [-82.078473254932547, 36.106200954499542],
              [-82.028223139031383, 36.130254625836251],
              [-81.90977643726437, 36.302066563955634],
              [-81.83440126341263, 36.347596727557267],
              [-81.794919029490288, 36.35790544384443],
              [-81.766204677546767, 36.338147070960702],
              [-81.705186679666795, 36.338147070960702],
              [-81.741079619596192, 36.401717488064875],
              [-81.715954561645617, 36.457556367953671],
              [-81.694418797687973, 36.467865084240835],
              [-81.708775973659741, 36.536589859488586],
              [-81.676472327723275, 36.588133440924402]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '38', name: 'North Dakota' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.048541785717845, 48.999827850668495],
            [-103.37375451504515, 48.998968790977905],
            [-102.218001849318483, 48.998968790977905],
            [-101.457071522815227, 48.998968790977905],
            [-101.126856475464749, 48.998968790977905],
            [-100.434122734827341, 48.999827850668495],
            [-99.85983569595696, 48.998968790977905],
            [-99.375281006910058, 48.998968790977905],
            [-98.86919055390554, 48.999827850668495],
            [-97.950331291712914, 49.000686910359093],
            [-97.228883199131985, 49.000686910359093],
            [-97.239651081110807, 48.966324522735221],
            [-97.200168847188465, 48.882136673056721],
            [-97.178633083230821, 48.861519240482394],
            [-97.149918731287315, 48.75499583884838],
            [-97.096079321393205, 48.686271063600628],
            [-97.106847203372027, 48.633009362783618],
            [-97.139150849308493, 48.612391930209292],
            [-97.164275907259068, 48.54710339372393],
            [-97.139150849308493, 48.496418871978712],
            [-97.135561555315547, 48.404499485084841],
            [-97.146329437294369, 48.359828381173806],
            [-97.117615085350849, 48.279935829948293],
            [-97.142740143301424, 48.234405666346653],
            [-97.131972261322602, 48.139909100380997],
            [-97.074543557435575, 48.053144071630712],
            [-97.056597087470877, 47.949197849068483],
            [-97.017114853548534, 47.919989819588189],
            [-97.006346971569712, 47.870164357533568],
            [-96.96686473764737, 47.783399328783283],
            [-96.93815038570385, 47.768795314043132],
            [-96.898668151781507, 47.689761822508217],
            [-96.873543093830932, 47.613305510045095],
            [-96.855596623866234, 47.610728330973302],
            [-96.859185917859179, 47.424312378113775],
            [-96.83406085990859, 47.335829229982295],
            [-96.844828741887412, 47.292876245452447],
            [-96.837650153901535, 47.194943440724401],
            [-96.819703683936837, 47.115909949189486],
            [-96.823292977929768, 46.96901074209741],
            [-96.78381074400744, 46.925198697876972],
            [-96.762274980049796, 46.934648354473538],
            [-96.78381074400744, 46.834138370673699],
            [-96.801757213972138, 46.819534355933548],
            [-96.780221450014494, 46.723319670586697],
            [-96.798167919979193, 46.664903611626109],
            [-96.790989331993316, 46.631400283692827],
            [-96.751507098070974, 46.582433881328804],
            [-96.737149922099221, 46.480205778147777],
            [-96.719203452134522, 46.438111853308527],
            [-96.647417572275714, 46.353924003630027],
            [-96.600756750367495, 46.330729391983915],
            [-96.597167456374564, 46.219910691896914],
            [-96.557685222452221, 46.058407470064694],
            [-96.579220986409865, 46.026622261512607],
            [-96.564863810438098, 45.935561934309334],
            [-97.318615548955478, 45.934702874618736],
            [-97.986224231642311, 45.935561934309334],
            [-98.413350216802158, 45.936420993999931],
            [-99.317852303023031, 45.939857232762321],
            [-100.006996749667493, 45.941575352143516],
            [-100.649480374403737, 45.943293471524704],
            [-101.557571754617541, 45.944152531215302],
            [-101.999054915749156, 45.944152531215302],
            [-103.047128761687617, 45.945011590905899],
            [-103.668076622466216, 45.945011590905899],
            [-104.044952491724914, 45.945011590905899],
            [-104.044952491724914, 48.374432395913949],
            [-104.048541785717845, 48.3890364106541],
            [-104.048541785717845, 48.999827850668495]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '40', name: 'Oklahoma' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.000467939779398, 37.00048209241092],
            [-102.777931712217111, 36.999623032720322],
            [-102.69896724437244, 36.995327734267335],
            [-102.042126443664429, 36.992750555195549],
            [-101.485785874758747, 36.995327734267335],
            [-100.735623430234298, 36.998763973029725],
            [-100.200818625286246, 37.002200211792115],
            [-99.648067350373495, 36.999623032720322],
            [-99.278370069100689, 36.999623032720322],
            [-98.352332218922186, 36.997904913339127],
            [-97.605759068390682, 36.998763973029725],
            [-96.823292977929768, 36.998763973029725],
            [-96.216702293122921, 36.998763973029725],
            [-95.513200670506706, 36.999623032720322],
            [-94.619466466264655, 36.998763973029725],
            [-94.619466466264655, 36.499650292792921],
            [-94.597930702307025, 36.387972533015322],
            [-94.572805644356436, 36.211006236752361],
            [-94.551269880398806, 36.101905656046554],
            [-94.501019764497642, 35.796939465884655],
            [-94.486662588525874, 35.726496571255709],
            [-94.432823178631779, 35.394040470994703],
            [-94.440001766617655, 35.120000429694294],
            [-94.450769648596477, 34.895785850448497],
            [-94.454358942589423, 34.693906823158223],
            [-94.461537530575299, 34.547866675756751],
            [-94.468716118561176, 34.189638784777841],
            [-94.486662588525874, 33.638122463414625],
            [-94.51896623446234, 33.616645971149708],
            [-94.587162820328203, 33.679357328563277],
            [-94.644591524215244, 33.668189552585517],
            [-94.648180818208175, 33.687947925469246],
            [-94.709198816088161, 33.687088865778655],
            [-94.773806107961079, 33.754954581335809],
            [-94.827645517855174, 33.741209626286256],
            [-94.870717045770448, 33.745504924739244],
            [-94.946092219622187, 33.812511580605801],
            [-94.971217277572777, 33.862337042660421],
            [-95.050181745417447, 33.864055162041616],
            [-95.132735507255063, 33.937075235742348],
            [-95.229646445064446, 33.961128907079065],
            [-95.254771503015021, 33.902712848118476],
            [-95.294253736937364, 33.875222938019377],
            [-95.340914558845583, 33.869209520185194],
            [-95.462950554605541, 33.872645758947584],
            [-95.462950554605541, 33.88553165430654],
            [-95.538325728457281, 33.879518236472357],
            [-95.563450786407856, 33.93192087759877],
            [-95.599343726337253, 33.934498056670563],
            [-95.685486782167814, 33.88982695275952],
            [-95.757272662026622, 33.892404131831313],
            [-95.753683368033677, 33.853746445754453],
            [-95.800344189941896, 33.861477982969824],
            [-95.832647835878348, 33.834847132561322],
            [-95.936737361673607, 33.887249773687728],
            [-95.940326655666553, 33.861477982969824],
            [-96.048005475454744, 33.836565251942517],
            [-96.101844885348854, 33.84773302792027],
            [-96.098255591355908, 33.830551834108334],
            [-96.148505707257073, 33.837424311633107],
            [-96.177220059200593, 33.760108939479387],
            [-96.227470175101743, 33.748082103811029],
            [-96.295666760967606, 33.764404237932375],
            [-96.320791818918181, 33.694820402994026],
            [-96.363863346833469, 33.692243223922233],
            [-96.3674526408264, 33.711142537115364],
            [-96.428470638706386, 33.779008252672519],
            [-96.503845812558126, 33.773853894528941],
            [-96.532560164501646, 33.822820296892964],
            [-96.572042398423974, 33.819384058130574],
            [-96.629471102311015, 33.845155848848485],
            [-96.586399574395742, 33.894981310903106],
            [-96.676131924219234, 33.908726265952652],
            [-96.704846276162755, 33.834847132561322],
            [-96.769453568035672, 33.827115595345944],
            [-96.78381074400744, 33.863196102351019],
            [-96.830471565915659, 33.875222938019377],
            [-96.852007329873288, 33.84687396822968],
            [-96.884310975809754, 33.868350460494597],
            [-96.905846739767398, 33.949961131101304],
            [-96.923793209732096, 33.95941078769787],
            [-96.974043325633247, 33.936216176051758],
            [-96.99557908959089, 33.949102071410707],
            [-96.984811207612069, 33.886390713997137],
            [-97.042239911499109, 33.837424311633107],
            [-97.088900733407328, 33.853746445754453],
            [-97.049418499484986, 33.817665938749379],
            [-97.096079321393205, 33.798766625556247],
            [-97.085311439414383, 33.743786805358049],
            [-97.124793673336725, 33.717155954949547],
            [-97.164275907259068, 33.729182790617898],
            [-97.207347435174341, 33.809934401534008],
            [-97.171454495244944, 33.835706192251919],
            [-97.178633083230821, 33.892404131831313],
            [-97.210936729167287, 33.916457803168029],
            [-97.246829669096684, 33.900135669046684],
            [-97.25400825708256, 33.864055162041616],
            [-97.300669078990779, 33.880377296162955],
            [-97.332972724927245, 33.874363878328779],
            [-97.372454958849588, 33.819384058130574],
            [-97.444240838708382, 33.823679356583561],
            [-97.46218730867308, 33.849451147301465],
            [-97.458598014680149, 33.903571907809074],
            [-97.501669542595423, 33.919894041930412],
            [-97.559098246482463, 33.896699430284301],
            [-97.598580480404806, 33.918175922549217],
            [-97.587812598425984, 33.953397369863694],
            [-97.656009184291833, 33.989477876868762],
            [-97.688312830228298, 33.986900697796969],
            [-97.731384358143572, 33.937075235742348],
            [-97.835473883938832, 33.858041744207434],
            [-97.87854541185412, 33.850310206992063],
            [-97.935974115741146, 33.879518236472357],
            [-97.968277761677612, 33.88209541554415],
            [-97.953920585705859, 33.937934295432946],
            [-97.971867055670558, 33.937075235742348],
            [-97.946741997719968, 33.988618817178164],
            [-97.971867055670558, 34.005800010990107],
            [-98.018527877578776, 33.993773175321749],
            [-98.086724463444625, 34.003222831918315],
            [-98.122617403374022, 34.081397263762632],
            [-98.093903051430516, 34.111464352933524],
            [-98.108260227402269, 34.154417337463371],
            [-98.169278225282241, 34.114041532005317],
            [-98.241064105141049, 34.132940845198448],
            [-98.294903515035145, 34.132940845198448],
            [-98.363100100901008, 34.156994516535157],
            [-98.40258233482335, 34.122632128911285],
            [-98.413350216802158, 34.084833502525022],
            [-98.485136096660966, 34.062497950569501],
            [-98.571279152491513, 34.144967680866806],
            [-98.646654326343253, 34.164726053750535],
            [-98.68972585425854, 34.132940845198448],
            [-98.76510102811028, 34.136377083960831],
            [-98.811761850018499, 34.158712635916352],
            [-98.858422671926718, 34.161289814988145],
            [-98.91944066980669, 34.181907247562471],
            [-98.987637255672553, 34.221423993329928],
            [-99.045065959559594, 34.198229381683809],
            [-99.077369605496045, 34.211115277042765],
            [-99.120441133411333, 34.2016656204462],
            [-99.12761972139721, 34.218846814258136],
            [-99.192227013270127, 34.216269635186343],
            [-99.210173483234826, 34.336537991869911],
            [-99.274780775107743, 34.384645334543336],
            [-99.26042359913599, 34.403544647736474],
            [-99.317852303023031, 34.407839946189455],
            [-99.382459594895948, 34.456806348553478],
            [-99.396816770867702, 34.377772857018563],
            [-99.439888298782989, 34.37433661825618],
            [-99.51885276662766, 34.414712423714228],
            [-99.579870764507646, 34.416430543095423],
            [-99.601406528465276, 34.37433661825618],
            [-99.709085348253481, 34.387222513615129],
            [-99.795228404084042, 34.454229169481692],
            [-99.928032281822809, 34.577074705237045],
            [-99.999818161681617, 34.560752571115707],
            [-99.999818161681617, 36.499650292792921],
            [-100.441301322813217, 36.499650292792921],
            [-101.083784947549475, 36.499650292792921],
            [-101.622179046490459, 36.499650292792921],
            [-102.250305495254949, 36.500509352483519],
            [-103.004057233772329, 36.500509352483519],
            [-103.000467939779398, 36.602737455664553],
            [-103.000467939779398, 37.00048209241092]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '42', name: 'Pennsylvania' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.520719661996623, 41.977873939729392],
            [-80.3484335503355, 42.030276580855805],
            [-80.186915320653199, 42.093846997959972],
            [-80.154611674716747, 42.114464430534298],
            [-80.11871873478735, 42.166008011970114],
            [-80.072057912879131, 42.168585191041906],
            [-80.061290030900309, 42.14453151970519],
            [-79.932075447154475, 42.206383817428168],
            [-79.763378629486297, 42.269954234532335],
            [-79.763378629486297, 42.000209491684913],
            [-79.759789335493352, 41.998491372303718],
            [-78.873233719237192, 41.997632312613121],
            [-78.596858081780809, 42.000209491684913],
            [-77.997445984959853, 41.998491372303718],
            [-77.125247544675446, 41.999350431994316],
            [-76.748371675416749, 42.001927611066101],
            [-76.349960042200422, 41.998491372303718],
            [-75.743369357393576, 41.997632312613121],
            [-75.359314900149002, 41.999350431994316],
            [-75.309064784247838, 41.948665910249098],
            [-75.291118314283139, 41.95210214901148],
            [-75.262403962339619, 41.866196179951793],
            [-75.208564552445523, 41.869632418714183],
            [-75.11165361463614, 41.840424389233888],
            [-75.075760674706743, 41.798330464394638],
            [-75.104475026650263, 41.770840554295539],
            [-75.054224910749099, 41.752800300792998],
            [-75.047046322763222, 41.616209809988092],
            [-75.075760674706743, 41.605901093700929],
            [-74.98602832488325, 41.50882734866348],
            [-74.982439030890305, 41.479619319183186],
            [-74.942956796967962, 41.483914617636167],
            [-74.889117387073867, 41.45556564784647],
            [-74.896295975059743, 41.440102573415729],
            [-74.806563625236251, 41.442679752487521],
            [-74.795795743257429, 41.423780439294383],
            [-74.738367039370388, 41.430652916819163],
            [-74.741956333363333, 41.404881126101252],
            [-74.695295511455114, 41.357632843118424],
            [-74.752724215342155, 41.346465067140663],
            [-74.831688683186826, 41.287189948489477],
            [-74.88193879908799, 41.180666546855463],
            [-74.921421033010333, 41.138572622016213],
            [-74.982439030890305, 41.108505532845321],
            [-74.968081854918552, 41.087888100270995],
            [-75.025510558805593, 41.039780757597569],
            [-75.09011785067851, 41.014868026570262],
            [-75.136778672586729, 40.97363316142161],
            [-75.050635616756168, 40.870545998549979],
            [-75.133189378593784, 40.77347225351253],
            [-75.172671612516126, 40.777767551965511],
            [-75.204975258452578, 40.691861582905823],
            [-75.176260906509057, 40.672962269712691],
            [-75.201385964459647, 40.64719047899478],
            [-75.194207376473756, 40.575888524675243],
            [-75.161903730537304, 40.563861689006885],
            [-75.100885732657318, 40.568156987459865],
            [-75.064992792727921, 40.536371778907785],
            [-75.072171380713812, 40.454761108301078],
            [-75.057814204742044, 40.416103422224218],
            [-74.968081854918552, 40.399781288102872],
            [-74.942956796967962, 40.341365229142284],
            [-74.867581623116223, 40.294976005850053],
            [-74.842456565165648, 40.25030490193901],
            [-74.770670685306854, 40.215083454624541],
            [-74.72042056940569, 40.154090216592159],
            [-74.781438567285676, 40.120586888658877],
            [-74.817331507215073, 40.127459366183658],
            [-74.860403035130346, 40.083647321963213],
            [-74.925010327003264, 40.070761426604257],
            [-75.047046322763222, 40.008909128881285],
            [-75.072171380713812, 39.980560159091581],
            [-75.129600084600838, 39.959083666826665],
            [-75.129600084600838, 39.899808548175479],
            [-75.219332434424345, 39.861150862098611],
            [-75.355725606156057, 39.839674369833695],
            [-75.416743604036043, 39.801875743447425],
            [-75.499297365873659, 39.833660951999512],
            [-75.578261833718329, 39.838815310143097],
            [-75.664404889548891, 39.821634116331154],
            [-75.718244299442986, 39.79242608685086],
            [-75.790030179301795, 39.721983192221913],
            [-76.56890697576975, 39.721124132531315],
            [-76.992443666936666, 39.720265072840725],
            [-77.534427059870595, 39.720265072840725],
            [-78.381500442204413, 39.72284225191251],
            [-79.476235110051093, 39.721124132531315],
            [-80.075647206872063, 39.721124132531315],
            [-80.520719661996623, 39.721124132531315],
            [-80.520719661996623, 40.638599882088812],
            [-80.520719661996623, 41.977873939729392]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '46', name: 'South Dakota' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.059309667696667, 44.997468752177511],
            [-104.041363197731968, 45.000904990939901],
            [-104.041363197731968, 45.750005041140405],
            [-104.044952491724914, 45.945011590905899],
            [-103.668076622466216, 45.945011590905899],
            [-103.047128761687617, 45.945011590905899],
            [-101.999054915749156, 45.944152531215302],
            [-101.557571754617541, 45.944152531215302],
            [-100.649480374403737, 45.943293471524704],
            [-100.006996749667493, 45.941575352143516],
            [-99.317852303023031, 45.939857232762321],
            [-98.413350216802158, 45.936420993999931],
            [-97.986224231642311, 45.935561934309334],
            [-97.318615548955478, 45.934702874618736],
            [-96.564863810438098, 45.935561934309334],
            [-96.579220986409865, 45.825602293912929],
            [-96.629471102311015, 45.786085548145472],
            [-96.672542630226303, 45.731964787637871],
            [-96.751507098070974, 45.698461459704589],
            [-96.84123944789448, 45.645199758887578],
            [-96.859185917859179, 45.605683013120121],
            [-96.765864274042741, 45.521495163441628],
            [-96.733560628106275, 45.458783806028052],
            [-96.683310512205111, 45.411535523045224],
            [-96.618703220332193, 45.408099284282834],
            [-96.489488636586358, 45.357414762537616],
            [-96.453595696656961, 45.30157588264882],
            [-96.453595696656961, 44.890086290852899],
            [-96.450006402664016, 44.630650264292633],
            [-96.453595696656961, 44.543885235542348],
            [-96.453595696656961, 43.500127711467108],
            [-96.597167456374564, 43.500127711467108],
            [-96.579220986409865, 43.481228398273977],
            [-96.60434604436044, 43.44944318972189],
            [-96.525381576515755, 43.394463369523685],
            [-96.528970870508701, 43.299966803558029],
            [-96.579220986409865, 43.295671505105041],
            [-96.550506634466345, 43.251000401194005],
            [-96.561274516445167, 43.224369550785504],
            [-96.475131460614605, 43.221792371713711],
            [-96.467952872628729, 43.150490417394167],
            [-96.435649226692263, 43.120423328223275],
            [-96.460774284642838, 43.063725388643881],
            [-96.511024400544002, 43.049980433594328],
            [-96.493077930579304, 43.001873090920903],
            [-96.521792282522824, 42.977819419584186],
            [-96.50025651856518, 42.959779166081653],
            [-96.543328046480454, 42.920262420314195],
            [-96.543328046480454, 42.851537645066443],
            [-96.582810280402796, 42.83779269001689],
            [-96.593578162381618, 42.793121586105855],
            [-96.633060396303961, 42.770786034150333],
            [-96.629471102311015, 42.705497497664972],
            [-96.57563169241692, 42.682302886018853],
            [-96.514613694536948, 42.630759304583037],
            [-96.478720754607536, 42.55602111150111],
            [-96.493077930579304, 42.51736342542425],
            [-96.446417108671085, 42.490732575015741],
            [-96.507435106551057, 42.484719157181566],
            [-96.525381576515755, 42.51049094789947],
            [-96.611524632346317, 42.506195649446489],
            [-96.636649690296892, 42.551725813048122],
            [-96.712024864148631, 42.608423752627516],
            [-96.686899806198056, 42.653094856538559],
            [-96.726382040120399, 42.666839811588112],
            [-96.801757213972138, 42.669416990659897],
            [-96.805346507965069, 42.703779378283777],
            [-96.905846739767398, 42.733846467454669],
            [-96.948918267682672, 42.719242452714518],
            [-96.977632619626192, 42.76047731786317],
            [-97.131972261322602, 42.771645093840931],
            [-97.149918731287315, 42.795698765177647],
            [-97.214526023160232, 42.812879958989583],
            [-97.218115317153163, 42.845524227232268],
            [-97.30784766697667, 42.867000719497184],
            [-97.361687076870766, 42.854973883828833],
            [-97.415526486764861, 42.866141659806594],
            [-97.440651544715436, 42.846383286922865],
            [-97.505258836588368, 42.858410122591216],
            [-97.562687540475395, 42.847242346613456],
            [-97.620116244362436, 42.856692003210028],
            [-97.684723536235353, 42.842087988469878],
            [-97.77445588605886, 42.849819525685248],
            [-97.846241765917654, 42.867859779187782],
            [-97.874956117861174, 42.858410122591216],
            [-97.90725976379764, 42.79483970548705],
            [-97.950331291712914, 42.769926974459736],
            [-98.018527877578776, 42.762195437244365],
            [-98.129795991359913, 42.821470555895552],
            [-98.259010575105748, 42.874732256712562],
            [-98.467189626696268, 42.947752330413294],
            [-98.499493272632719, 42.998436852158513],
            [-98.901494199841991, 42.998436852158513],
            [-99.569102882528824, 42.997577792467915],
            [-100.11826486344863, 42.998436852158513],
            [-100.886373777937777, 42.997577792467915],
            [-101.625768340483404, 42.995859673086727],
            [-101.848304568045677, 42.99929591184911],
            [-102.487198898788989, 42.99929591184911],
            [-103.000467939779398, 43.000154971539708],
            [-104.05213107971079, 43.001014031230305],
            [-104.05213107971079, 43.297389624486236],
            [-104.055720373703736, 43.304262102011016],
            [-104.055720373703736, 44.874623216422158],
            [-104.059309667696667, 44.997468752177511]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '48', name: 'Texas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.004057233772329, 36.500509352483519],
            [-102.250305495254949, 36.500509352483519],
            [-101.622179046490459, 36.499650292792921],
            [-101.083784947549475, 36.499650292792921],
            [-100.441301322813217, 36.499650292792921],
            [-99.999818161681617, 36.499650292792921],
            [-99.999818161681617, 34.560752571115707],
            [-99.928032281822809, 34.577074705237045],
            [-99.795228404084042, 34.454229169481692],
            [-99.709085348253481, 34.387222513615129],
            [-99.601406528465276, 34.37433661825618],
            [-99.579870764507646, 34.416430543095423],
            [-99.51885276662766, 34.414712423714228],
            [-99.439888298782989, 34.37433661825618],
            [-99.396816770867702, 34.377772857018563],
            [-99.382459594895948, 34.456806348553478],
            [-99.317852303023031, 34.407839946189455],
            [-99.26042359913599, 34.403544647736474],
            [-99.274780775107743, 34.384645334543336],
            [-99.210173483234826, 34.336537991869911],
            [-99.192227013270127, 34.216269635186343],
            [-99.12761972139721, 34.218846814258136],
            [-99.120441133411333, 34.2016656204462],
            [-99.077369605496045, 34.211115277042765],
            [-99.045065959559594, 34.198229381683809],
            [-98.987637255672553, 34.221423993329928],
            [-98.91944066980669, 34.181907247562471],
            [-98.858422671926718, 34.161289814988145],
            [-98.811761850018499, 34.158712635916352],
            [-98.76510102811028, 34.136377083960831],
            [-98.68972585425854, 34.132940845198448],
            [-98.646654326343253, 34.164726053750535],
            [-98.571279152491513, 34.144967680866806],
            [-98.485136096660966, 34.062497950569501],
            [-98.413350216802158, 34.084833502525022],
            [-98.40258233482335, 34.122632128911285],
            [-98.363100100901008, 34.156994516535157],
            [-98.294903515035145, 34.132940845198448],
            [-98.241064105141049, 34.132940845198448],
            [-98.169278225282241, 34.114041532005317],
            [-98.108260227402269, 34.154417337463371],
            [-98.093903051430516, 34.111464352933524],
            [-98.122617403374022, 34.081397263762632],
            [-98.086724463444625, 34.003222831918315],
            [-98.018527877578776, 33.993773175321749],
            [-97.971867055670558, 34.005800010990107],
            [-97.946741997719968, 33.988618817178164],
            [-97.971867055670558, 33.937075235742348],
            [-97.953920585705859, 33.937934295432946],
            [-97.968277761677612, 33.88209541554415],
            [-97.935974115741146, 33.879518236472357],
            [-97.87854541185412, 33.850310206992063],
            [-97.835473883938832, 33.858041744207434],
            [-97.731384358143572, 33.937075235742348],
            [-97.688312830228298, 33.986900697796969],
            [-97.656009184291833, 33.989477876868762],
            [-97.587812598425984, 33.953397369863694],
            [-97.598580480404806, 33.918175922549217],
            [-97.559098246482463, 33.896699430284301],
            [-97.501669542595423, 33.919894041930412],
            [-97.458598014680149, 33.903571907809074],
            [-97.46218730867308, 33.849451147301465],
            [-97.444240838708382, 33.823679356583561],
            [-97.372454958849588, 33.819384058130574],
            [-97.332972724927245, 33.874363878328779],
            [-97.300669078990779, 33.880377296162955],
            [-97.25400825708256, 33.864055162041616],
            [-97.246829669096684, 33.900135669046684],
            [-97.210936729167287, 33.916457803168029],
            [-97.178633083230821, 33.892404131831313],
            [-97.171454495244944, 33.835706192251919],
            [-97.207347435174341, 33.809934401534008],
            [-97.164275907259068, 33.729182790617898],
            [-97.124793673336725, 33.717155954949547],
            [-97.085311439414383, 33.743786805358049],
            [-97.096079321393205, 33.798766625556247],
            [-97.049418499484986, 33.817665938749379],
            [-97.088900733407328, 33.853746445754453],
            [-97.042239911499109, 33.837424311633107],
            [-96.984811207612069, 33.886390713997137],
            [-96.99557908959089, 33.949102071410707],
            [-96.974043325633247, 33.936216176051758],
            [-96.923793209732096, 33.95941078769787],
            [-96.905846739767398, 33.949961131101304],
            [-96.884310975809754, 33.868350460494597],
            [-96.852007329873288, 33.84687396822968],
            [-96.830471565915659, 33.875222938019377],
            [-96.78381074400744, 33.863196102351019],
            [-96.769453568035672, 33.827115595345944],
            [-96.704846276162755, 33.834847132561322],
            [-96.676131924219234, 33.908726265952652],
            [-96.586399574395742, 33.894981310903106],
            [-96.629471102311015, 33.845155848848485],
            [-96.572042398423974, 33.819384058130574],
            [-96.532560164501646, 33.822820296892964],
            [-96.503845812558126, 33.773853894528941],
            [-96.428470638706386, 33.779008252672519],
            [-96.3674526408264, 33.711142537115364],
            [-96.363863346833469, 33.692243223922233],
            [-96.320791818918181, 33.694820402994026],
            [-96.295666760967606, 33.764404237932375],
            [-96.227470175101743, 33.748082103811029],
            [-96.177220059200593, 33.760108939479387],
            [-96.148505707257073, 33.837424311633107],
            [-96.098255591355908, 33.830551834108334],
            [-96.101844885348854, 33.84773302792027],
            [-96.048005475454744, 33.836565251942517],
            [-95.940326655666553, 33.861477982969824],
            [-95.936737361673607, 33.887249773687728],
            [-95.832647835878348, 33.834847132561322],
            [-95.800344189941896, 33.861477982969824],
            [-95.753683368033677, 33.853746445754453],
            [-95.757272662026622, 33.892404131831313],
            [-95.685486782167814, 33.88982695275952],
            [-95.599343726337253, 33.934498056670563],
            [-95.563450786407856, 33.93192087759877],
            [-95.538325728457281, 33.879518236472357],
            [-95.462950554605541, 33.88553165430654],
            [-95.462950554605541, 33.872645758947584],
            [-95.340914558845583, 33.869209520185194],
            [-95.294253736937364, 33.875222938019377],
            [-95.254771503015021, 33.902712848118476],
            [-95.229646445064446, 33.961128907079065],
            [-95.132735507255063, 33.937075235742348],
            [-95.050181745417447, 33.864055162041616],
            [-94.971217277572777, 33.862337042660421],
            [-94.946092219622187, 33.812511580605801],
            [-94.870717045770448, 33.745504924739244],
            [-94.827645517855174, 33.741209626286256],
            [-94.773806107961079, 33.754954581335809],
            [-94.709198816088161, 33.687088865778655],
            [-94.648180818208175, 33.687947925469246],
            [-94.644591524215244, 33.668189552585517],
            [-94.587162820328203, 33.679357328563277],
            [-94.51896623446234, 33.616645971149708],
            [-94.486662588525874, 33.638122463414625],
            [-94.457948236582368, 33.644994940939405],
            [-94.457948236582368, 33.598605717647168],
            [-94.418466002660026, 33.577129225382251],
            [-94.38616235672356, 33.582283583525829],
            [-94.393340944709436, 33.55135743466434],
            [-94.357448004780039, 33.543625897448969],
            [-94.339501534815341, 33.566820509095088],
            [-94.31078718287182, 33.55135743466434],
            [-94.300019300892998, 33.576270165691653],
            [-94.224644127041273, 33.553075554045535],
            [-94.185161893118931, 33.592592299812992],
            [-94.142090365203643, 33.577988285072848],
            [-94.124143895238944, 33.552216494354937],
            [-94.102608131281315, 33.570256747857471],
            [-94.045179427394274, 33.55135743466434],
            [-94.045179427394274, 33.55135743466434],
            [-94.041590133401328, 33.541907778067774],
            [-94.041590133401328, 33.019599486184859],
            [-94.041590133401328, 31.992164096230958],
            [-94.012875781457808, 31.981855379943795],
            [-93.973393547535466, 31.920003082220816],
            [-93.908786255662548, 31.893372231812315],
            [-93.876482609726096, 31.82207027749277],
            [-93.822643199832001, 31.774821994509942],
            [-93.829821787817878, 31.745613965029648],
            [-93.801107435874357, 31.697506622356215],
            [-93.826232493824932, 31.661426115351148],
            [-93.81546461184611, 31.622768429274288],
            [-93.840589669796699, 31.598714757937572],
            [-93.819053905839056, 31.554902713717134],
            [-93.78675025990259, 31.527412803618027],
            [-93.740089437994371, 31.522258445474449],
            [-93.729321556015549, 31.492191356303557],
            [-93.750857319973193, 31.468996744657439],
            [-93.700607204072043, 31.438070595795949],
            [-93.704196498064974, 31.41058068569685],
            [-93.675482146121453, 31.397694790337901],
            [-93.664714264142631, 31.355600865498651],
            [-93.686250028100275, 31.310070701897011],
            [-93.643178500185002, 31.269694896438956],
            [-93.621642736227358, 31.271413015820151],
            [-93.600106972269714, 31.174339270782703],
            [-93.531910386403865, 31.184647987069866],
            [-93.553446150361495, 31.097023898628983],
            [-93.524731798417974, 31.070393048220474],
            [-93.517553210432098, 31.024003824928243],
            [-93.564214032340317, 31.00596357142571],
            [-93.567803326333262, 30.977614601636013],
            [-93.524731798417974, 30.930366318653178],
            [-93.55703544435444, 30.911467005460047],
            [-93.567803326333262, 30.878822737217369],
            [-93.553446150361495, 30.824701976709761],
            [-93.585749796297961, 30.796353006920064],
            [-93.618053442234412, 30.742232246412456],
            [-93.628821324213234, 30.679520888998887],
            [-93.671892852128522, 30.658044396733963],
            [-93.679071440114399, 30.599628337773375],
            [-93.729321556015549, 30.573856547055463],
            [-93.740089437994371, 30.539494159431591],
            [-93.714964380043796, 30.518876726857265],
            [-93.697017910079097, 30.440702295012947],
            [-93.743678731987316, 30.396890250792502],
            [-93.76521449594496, 30.333319833688329],
            [-93.707785792057919, 30.288648729777293],
            [-93.704196498064974, 30.243118566175653],
            [-93.722142968029672, 30.209615238242378],
            [-93.689839322093221, 30.141749522685224],
            [-93.732910850008494, 30.08161534434344],
            [-93.700607204072043, 30.065293210222094],
            [-93.740089437994371, 30.021481166001657],
            [-93.790339553895535, 29.987977838068375],
            [-93.81546461184611, 29.920971182201818],
            [-93.862125433754329, 29.857400765097644],
            [-93.923143431634315, 29.818743079020784],
            [-93.930322019620192, 29.79726658675586],
            [-93.898018373683726, 29.771494796037956],
            [-93.837000375803754, 29.690743185121846],
            [-93.837000375803754, 29.678716349453488],
            [-94.002107899478986, 29.681293528525281],
            [-94.134911777217766, 29.646931140901401],
            [-94.594341408314079, 29.468246725257245],
            [-94.694841640116394, 29.415844084130839],
            [-94.730734580045791, 29.369454860838601],
            [-94.784573989939901, 29.375468278672784],
            [-94.705609522095216, 29.436461516705165],
            [-94.673305876158764, 29.476837322163213],
            [-94.626645054250531, 29.475978262472623],
            [-94.594341408314079, 29.492300396593961],
            [-94.565627056370559, 29.531817142361419],
            [-94.533323410434093, 29.518072187311866],
            [-94.493841176511765, 29.524944664836646],
            [-94.54768058640586, 29.572192947819474],
            [-94.741502462024613, 29.525803724527243],
            [-94.770216813968133, 29.548139276482758],
            [-94.709198816088161, 29.625454648636484],
            [-94.694841640116394, 29.694179423884236],
            [-94.694841640116394, 29.757749840988403],
            [-94.737913168031682, 29.762045139441391],
            [-94.75585963799638, 29.781803512325119],
            [-94.816877635876352, 29.756890781297805],
            [-94.852770575805749, 29.721669333983336],
            [-94.867127751777517, 29.678716349453488],
            [-94.920967161671612, 29.658098916879162],
            [-94.942502925629256, 29.697615662646619],
            [-95.007110217502174, 29.658957976569759],
            [-95.01428880548805, 29.628890887398867],
            [-94.981985159551584, 29.601400977299768],
            [-95.017878099480996, 29.558447992769921],
            [-94.981985159551584, 29.511199709787093],
            [-94.91019927969279, 29.496595695046942],
            [-94.931735043650434, 29.450206471754711],
            [-94.892252809728092, 29.433025277942775],
            [-94.885074221742215, 29.366018622076218],
            [-94.892252809728092, 29.30846162280622],
            [-94.920967161671612, 29.281830772397718],
            [-94.953270807608078, 29.290421369303687],
            [-95.021467393473927, 29.229428131271305],
            [-95.100431861318611, 29.173589251382509],
            [-95.111199743297433, 29.195924803338031],
            [-95.157860565205652, 29.18905232581325],
            [-95.165039153191529, 29.113455073040726],
            [-95.143503389233885, 29.091119521085204],
            [-95.096842567325666, 29.128918147471467],
            [-95.003520923509228, 29.18475702736027],
            [-94.992753041530406, 29.201079161481609],
            [-94.877895633756339, 29.286126070850706],
            [-94.824056223862229, 29.305884443734435],
            [-94.824056223862229, 29.344542129811295],
            [-94.730734580045791, 29.331656234452339],
            [-94.802520459904599, 29.279253593325926],
            [-95.025056687466872, 29.147817460664605],
            [-95.111199743297433, 29.088542342013412],
            [-95.125556919269187, 29.067065849748495],
            [-95.240414327043268, 28.988891417904171],
            [-95.383986086760856, 28.866045882148818],
            [-95.437825496654966, 28.859173404624038],
            [-95.685486782167814, 28.733750689796892],
            [-95.811112071920718, 28.66502591454914],
            [-96.001344653546525, 28.588569602086018],
            [-96.220291587115867, 28.49235491673916],
            [-96.342327582875825, 28.417616723657233],
            [-96.388988404784044, 28.381536216652158],
            [-96.442827814678139, 28.317965799547991],
            [-96.633060396303961, 28.222610173891731],
            [-96.719203452134522, 28.164194114931142],
            [-96.887900269802699, 28.031039862888626],
            [-97.002757677576767, 27.908194327133266],
            [-97.042239911499109, 27.836892372813722],
            [-97.092490027400274, 27.786207851068504],
            [-97.210936729167287, 27.596355659446587],
            [-97.297079784997848, 27.427120900398997],
            [-97.336562018920191, 27.31802031969319],
            [-97.372454958849588, 27.153080859098587],
            [-97.379633546835464, 27.060302412514119],
            [-97.376044252842519, 26.971819264382638],
            [-97.347329900898998, 26.795712027810275],
            [-97.297079784997848, 26.617886671856716],
            [-97.25400825708256, 26.502772673316727],
            [-97.196579553195534, 26.306048004170037],
            [-97.157097319273191, 26.082692484614839],
            [-97.182222377223766, 26.123068290072894],
            [-97.200168847188465, 26.249350064590644],
            [-97.243240375103753, 26.411712346113454],
            [-97.282722609026081, 26.542289419084184],
            [-97.368865664856642, 26.556034374133738],
            [-97.304258372983725, 26.579228985779856],
            [-97.336562018920191, 26.650530940099394],
            [-97.35809778287782, 26.725269133181328],
            [-97.390401428814286, 26.800866385953853],
            [-97.401169310793108, 26.891926713157126],
            [-97.397580016800163, 27.010476950459498],
            [-97.401169310793108, 27.115282232712325],
            [-97.361687076870766, 27.352382707317069],
            [-97.304258372983725, 27.507013451624509],
            [-97.236061787117862, 27.634154285832857],
            [-97.185811671216712, 27.69428846417464],
            [-97.149918731287315, 27.769026657256568],
            [-97.121204379343794, 27.799093746427459],
            [-97.128382967329671, 27.839469551885514],
            [-97.081722145421452, 27.913348685276851],
            [-97.017114853548534, 27.945992953519529],
            [-96.984811207612069, 27.984650639596389],
            [-96.920203915739151, 28.093751220302195],
            [-96.819703683936837, 28.143576682356816],
            [-96.790989331993316, 28.189106845958456],
            [-96.733560628106275, 28.190824965339651],
            [-96.607935338353386, 28.281026232852327],
            [-96.525381576515755, 28.323120157691569],
            [-96.439238520685208, 28.342878530575298],
            [-96.453595696656961, 28.37981809727097],
            [-96.421292050720496, 28.404730828298277],
            [-96.453595696656961, 28.41847578334783],
            [-96.511024400544002, 28.383254336033353],
            [-96.582810280402796, 28.353187246862461],
            [-96.622292514325139, 28.318824859238589],
            [-96.683310512205111, 28.314529560785601],
            [-96.704846276162755, 28.348891948409481],
            [-96.704846276162755, 28.400435529845296],
            [-96.769453568035672, 28.41074424613246],
            [-96.790989331993316, 28.384113395723951],
            [-96.790989331993316, 28.311952381713809],
            [-96.808935801958015, 28.290475889448892],
            [-96.787400038000371, 28.255254442134415],
            [-96.801757213972138, 28.224328293272926],
            [-96.873543093830932, 28.1762209505995],
            [-96.934561091710918, 28.123818309473087],
            [-96.963275443654439, 28.12295924978249],
            [-97.027882735527356, 28.148731040500401],
            [-97.038650617506164, 28.185670607196066],
            [-97.153508025280246, 28.133267966069653],
            [-97.214526023160232, 28.075710966799662],
            [-97.139150849308493, 28.05681165360653],
            [-97.053007793477931, 28.105778055970553],
            [-97.024293441534411, 28.042207638866387],
            [-97.049418499484986, 28.022449265982658],
            [-97.185811671216712, 27.830878954979546],
            [-97.228883199131985, 27.829160835598351],
            [-97.250418963089629, 27.876409118581179],
            [-97.325794136941369, 27.867818521675211],
            [-97.340151312913122, 27.883281596105959],
            [-97.455008720687204, 27.873831939509387],
            [-97.494490954609546, 27.88414065579655],
            [-97.516026718567176, 27.851496387553873],
            [-97.472955190651902, 27.824006477454766],
            [-97.419115780757807, 27.823147417764176],
            [-97.379633546835464, 27.837751432504319],
            [-97.393990722807217, 27.782771612306121],
            [-97.368865664856642, 27.741536747157468],
            [-97.315026254962547, 27.712328717677174],
            [-97.25400825708256, 27.696865643246426],
            [-97.322204842948423, 27.57144292841928],
            [-97.368865664856642, 27.412516885658853],
            [-97.41193719277193, 27.32145655845558],
            [-97.483723072630724, 27.298261946809461],
            [-97.508848130581299, 27.275067335163349],
            [-97.548330364503641, 27.290530409594091],
            [-97.498080248602477, 27.308570663096624],
            [-97.487312366623655, 27.359255184841842],
            [-97.516026718567176, 27.361832363913635],
            [-97.53756248252482, 27.335201513505133],
            [-97.641652008320079, 27.270772036710362],
            [-97.627294832348312, 27.243282126611263],
            [-97.541151776517765, 27.229537171561709],
            [-97.451419426694258, 27.262181439804394],
            [-97.422705074750738, 27.263899559185589],
            [-97.440651544715436, 27.164248635076348],
            [-97.429883662736628, 27.160812396313958],
            [-97.444240838708382, 26.987282338813387],
            [-97.465776602666025, 26.809456982859821],
            [-97.480133778637779, 26.806879803788036],
            [-97.444240838708382, 26.609296074950741],
            [-97.415526486764861, 26.553457195061945],
            [-97.41193719277193, 26.481296181051803],
            [-97.379633546835464, 26.480437121361206],
            [-97.297079784997848, 26.295739287882874],
            [-97.27913331503315, 26.27082655685556],
            [-97.311436960969601, 26.230450751397512],
            [-97.297079784997848, 26.201242721917211],
            [-97.300669078990779, 26.147981021100207],
            [-97.271954727047259, 26.086128723377229],
            [-97.200168847188465, 26.076679066780663],
            [-97.196579553195534, 26.046611977609771],
            [-97.153508025280246, 26.062075052040512],
            [-97.146329437294369, 25.952974471334706],
            [-97.228883199131985, 25.958987889168888],
            [-97.275544021040204, 25.952115411644108],
            [-97.275544021040204, 25.93579327752277],
            [-97.350919194891944, 25.925484561235606],
            [-97.372454958849588, 25.840437651866516],
            [-97.444240838708382, 25.849887308463082],
            [-97.469365896658957, 25.884249696086954],
            [-97.523205306553066, 25.886826875158746],
            [-97.544741070510696, 25.934075158141574],
            [-97.584223304433038, 25.937511396903965],
            [-97.648830596305956, 26.021699246582465],
            [-97.760098710087092, 26.032007962869621],
            [-97.799580944009435, 26.060356932659325],
            [-97.860598941889421, 26.052625395443947],
            [-97.885723999839996, 26.0663703504935],
            [-97.968277761677612, 26.051766335753349],
            [-97.982634937649365, 26.067229410184098],
            [-98.029295759557584, 26.0663703504935],
            [-98.040063641536406, 26.041457619466193],
            [-98.075956581465803, 26.068088469874695],
            [-98.147742461324611, 26.056061634206337],
            [-98.176456813268132, 26.074960947399468],
            [-98.197992577225762, 26.056061634206337],
            [-98.248242693126926, 26.073242828018273],
            [-98.276957045070446, 26.099014618736184],
            [-98.266189163091624, 26.120491111001108],
            [-98.323617866978665, 26.121350170691706],
            [-98.334385748957487, 26.159148797077968],
            [-98.388225158851583, 26.158289737387371],
            [-98.463600332703322, 26.222719214182135],
            [-98.495903978639788, 26.213269557585569],
            [-98.585636328463281, 26.254504422734222],
            [-98.653832914329143, 26.23560510954109],
            [-98.700493736237362, 26.265672198711982],
            [-98.754333146131458, 26.324947317363169],
            [-98.790226086060855, 26.331819794887942],
            [-98.797404674046732, 26.360168764677645],
            [-98.826119025990252, 26.370477480964801],
            [-98.890726317863169, 26.357591585605853],
            [-98.966101491714909, 26.397967391063908],
            [-99.009173019630197, 26.395390211992115],
            [-99.037887371573717, 26.412571405804051],
            [-99.080958899488991, 26.396249271682713],
            [-99.113262545425442, 26.434047898068975],
            [-99.091726781467813, 26.477000882598823],
            [-99.12761972139721, 26.525108225272248],
            [-99.167101955319552, 26.536276001250009],
            [-99.177869837298374, 26.620463850928502],
            [-99.199405601256004, 26.656544357933576],
            [-99.210173483234826, 26.724410073490731],
            [-99.238887835178346, 26.745886565755654],
            [-99.242477129171291, 26.787980490594904],
            [-99.267602187121867, 26.842960310793103],
            [-99.328620185001839, 26.879899877488768],
            [-99.321441597015962, 26.906530727897277],
            [-99.368102418924181, 26.928866279852791],
            [-99.39322747687477, 26.960651488404878],
            [-99.375281006910058, 26.977832682216814],
            [-99.4147632408324, 27.017349427984279],
            [-99.447066886768866, 27.023362845818454],
            [-99.450656180761797, 27.06803394972949],
            [-99.429120416804167, 27.094664800137998],
            [-99.439888298782989, 27.151362739717392],
            [-99.425531122811222, 27.176275470744706],
            [-99.439888298782989, 27.250154604136036],
            [-99.497317002670016, 27.271631096400959],
            [-99.493727708677085, 27.303416304953046],
            [-99.536799236592358, 27.312865961549612],
            [-99.504495590655907, 27.338637752267516],
            [-99.486549120691208, 27.412516885658853],
            [-99.497317002670016, 27.439147736067355],
            [-99.482959826698263, 27.49069131750317],
            [-99.529620648606482, 27.499281914409138],
            [-99.511674178641783, 27.564570450894507],
            [-99.554745706557057, 27.614395912949128],
            [-99.579870764507646, 27.60236907728077],
            [-99.604995822458221, 27.641885823048227],
            [-99.637299468394673, 27.626422748617479],
            [-99.669603114331139, 27.659926076550761],
            [-99.705496054260536, 27.654771718407176],
            [-99.770103346133453, 27.732087090560903],
            [-99.802406992069919, 27.741536747157468],
            [-99.813174874048741, 27.774181015400146],
            [-99.841889225992261, 27.766449478184775],
            [-99.877782165921658, 27.799093746427459],
            [-99.877782165921658, 27.842046730957307],
            [-99.902907223872234, 27.864382282912821],
            [-99.892139341893412, 27.899603730227298],
            [-99.938800163801631, 27.940838595375951],
            [-99.931621575815754, 27.981214400834006],
            [-99.992639573695726, 27.994959355883552],
            [-100.017764631646315, 28.064543190821901],
            [-100.053657571575712, 28.08430156370563],
            [-100.082371923519233, 28.144435742047413],
            [-100.207997213272122, 28.189965905649053],
            [-100.22235438924389, 28.235496069250686],
            [-100.247479447194465, 28.233777949869491],
            [-100.294140269102684, 28.28446247161471],
            [-100.286961681116807, 28.317106739857394],
            [-100.347979678996779, 28.403012708917082],
            [-100.337211797017972, 28.443388514375137],
            [-100.369515442954423, 28.476891842308419],
            [-100.337211797017972, 28.501804573335725],
            [-100.380283324933245, 28.511254229932291],
            [-100.412586970869697, 28.551630035390346],
            [-100.398229794897944, 28.585133363323628],
            [-100.448479910799108, 28.609187034660344],
            [-100.444890616806163, 28.637536004450041],
            [-100.498730026700258, 28.66158967578675],
            [-100.513087202672025, 28.705401720007195],
            [-100.505908614686135, 28.740623167321665],
            [-100.534622966629655, 28.762958719277187],
            [-100.548980142601422, 28.825670076690763],
            [-100.570515906559066, 28.82652913638136],
            [-100.602819552495518, 28.902126389153885],
            [-100.631533904439038, 28.902985448844483],
            [-100.653069668396682, 28.943361254302538],
            [-100.649480374403737, 29.008649790787899],
            [-100.674605432354312, 29.099710117991172],
            [-100.739212724227244, 29.13922686375863],
            [-100.775105664156641, 29.173589251382509],
            [-100.764337782177819, 29.185616087050867],
            [-100.79664142811427, 29.246609325083249],
            [-100.879195189951901, 29.281830772397718],
            [-100.886373777937777, 29.307602563115623],
            [-100.947391775817749, 29.34711930888308],
            [-101.011999067690667, 29.368595801148004],
            [-101.058659889598886, 29.440756815158146],
            [-101.058659889598886, 29.458797068660679],
            [-101.151981533415324, 29.476837322163213],
            [-101.173517297372968, 29.514635948549483],
            [-101.256071059210583, 29.520649366383658],
            [-101.245303177231762, 29.569615768747681],
            [-101.252481765217652, 29.625454648636484],
            [-101.270428235182351, 29.629749947089465],
            [-101.291963999139981, 29.572192947819474],
            [-101.313499763097624, 29.595387559465593],
            [-101.306321175111748, 29.640917723067226],
            [-101.36733917299172, 29.664112334713344],
            [-101.413999994899939, 29.756890781297805],
            [-101.442714346843459, 29.753454542535422],
            [-101.475017992779925, 29.780944452634522],
            [-101.521678814688144, 29.759467960369598],
            [-101.546803872638719, 29.79726658675586],
            [-101.57551822458224, 29.774071975109749],
            [-101.70832210232102, 29.762904199131988],
            [-101.776518688186883, 29.78953504954049],
            [-101.798054452144513, 29.782662572015717],
            [-101.851893862038608, 29.801561885208848],
            [-101.930858329883293, 29.78953504954049],
            [-101.973929857798566, 29.810152482114816],
            [-102.034947855678553, 29.804139064280641],
            [-102.049305031650306, 29.785239751087502],
            [-102.142626675466744, 29.803280004590043],
            [-102.182108909389086, 29.846232989119883],
            [-102.228769731297305, 29.843655810048098],
            [-102.300555611156099, 29.87801819767197],
            [-102.350805727057264, 29.862555123241229],
            [-102.386698666986661, 29.767199497584969],
            [-102.508734662746619, 29.783521631706314],
            [-102.541038308683085, 29.751736423154227],
            [-102.56616336663366, 29.761186079750793],
            [-102.645127834478345, 29.733696169651694],
            [-102.67025289242892, 29.742286766557662],
            [-102.69896724437244, 29.695897543265424],
            [-102.691788656386564, 29.676139170381695],
            [-102.738449478294783, 29.641776782757823],
            [-102.738449478294783, 29.598823798227976],
            [-102.763574536245358, 29.598823798227976],
            [-102.770753124231234, 29.548139276482758],
            [-102.810235358153577, 29.522367485764853],
            [-102.806646064160631, 29.494018515975156],
            [-102.831771122111221, 29.444193053920536],
            [-102.82459253412533, 29.399521950009493],
            [-102.842539004090042, 29.358287084860841],
            [-102.878431944019439, 29.35399178640786],
            [-102.889199825998261, 29.292139488684882],
            [-102.90714629596296, 29.260354280132795],
            [-102.871253356033549, 29.241454966939664],
            [-102.889199825998261, 29.208810698696979],
            [-102.953807117871179, 29.176166430454302],
            [-102.989700057800576, 29.183038907979075],
            [-103.014825115751151, 29.125481908709084],
            [-103.075843113631137, 29.088542342013412],
            [-103.100968171581712, 29.057616193151929],
            [-103.115325347553465, 28.985455179141788],
            [-103.16557546345463, 28.978582701617015],
            [-103.226593461334602, 28.991468596975963],
            [-103.280432871228712, 28.982018940379398],
            [-103.330682987129862, 29.021535686146855],
            [-103.427593924939245, 29.042153118721181],
            [-103.524504862748628, 29.137508744377442],
            [-103.610647918579176, 29.165857714167139],
            [-103.646540858508573, 29.158985236642359],
            [-103.725505326353257, 29.191629504885043],
            [-103.790112618226175, 29.257777101061002],
            [-103.836773440134394, 29.278394533635328],
            [-103.976755905859051, 29.296434787137869],
            [-104.037773903739037, 29.320488458474578],
            [-104.105970489604886, 29.372891099600992],
            [-104.145452723527228, 29.383199815888155],
            [-104.213649309393091, 29.452783650826504],
            [-104.213649309393091, 29.47340108340083],
            [-104.263899425294241, 29.513776888858885],
            [-104.33927459914598, 29.519790306693061],
            [-104.382346127061268, 29.54298491833918],
            [-104.400292597025967, 29.572192947819474],
            [-104.507971416814158, 29.639199603686031],
            [-104.540275062750624, 29.676139170381695],
            [-104.565400120701199, 29.770635736347359],
            [-104.608471648616487, 29.818743079020784],
            [-104.633596706567062, 29.8702866604566],
            [-104.680257528475281, 29.924407420964208],
            [-104.680257528475281, 29.975091942709419],
            [-104.705382586425856, 30.024058345073442],
            [-104.683846822468226, 30.085910642796421],
            [-104.694614704447034, 30.132299866088658],
            [-104.687436116461157, 30.179548149071486],
            [-104.712561174411732, 30.237964208032075],
            [-104.752043408334075, 30.263735998749979],
            [-104.762811290312897, 30.301534625136249],
            [-104.809472112221115, 30.335037953069524],
            [-104.813061406214061, 30.360809743787435],
            [-104.85972222812228, 30.390017773267729],
            [-104.852543640136389, 30.418366743057426],
            [-104.899204462044608, 30.57042030829308],
            [-104.924329519995197, 30.604782695916953],
            [-104.967401047910471, 30.608218934679343],
            [-105.006883281832813, 30.68639336652366],
            [-105.060722691726909, 30.68639336652366],
            [-105.110972807628073, 30.743091306103054],
            [-105.154044335543347, 30.751681903009022],
            [-105.164812217522169, 30.772299335583348],
            [-105.218651627416264, 30.801507365063642],
            [-105.261723155331552, 30.798071126301259],
            [-105.286848213282127, 30.822124797637969],
            [-105.398116327063263, 30.855628125571251],
            [-105.398116327063263, 30.889131453504532],
            [-105.556045262752619, 30.990500496994962],
            [-105.602706084660838, 31.082419883888832],
            [-105.645777612576126, 31.114205092440919],
            [-105.710384904449043, 31.13654064439644],
            [-105.742688550385495, 31.164889614186137],
            [-105.774992196321961, 31.166607733567332],
            [-105.782170784307837, 31.197533882428822],
            [-105.868313840138399, 31.288594209632087],
            [-105.940099719997193, 31.318661298802979],
            [-105.95445689596896, 31.365050522095217],
            [-106.00470701187011, 31.392540432194316],
            [-106.080082185721849, 31.398553850028492],
            [-106.205707475474753, 31.465560505895056],
            [-106.245189709397081, 31.54115775866758],
            [-106.281082649326493, 31.561775191241907],
            [-106.302618413284122, 31.620191250202495],
            [-106.349279235192341, 31.696647562665618],
            [-106.381582881128807, 31.731869009980095],
            [-106.44977946699467, 31.764513278222779],
            [-106.489261700916998, 31.748191144101433],
            [-106.528743934839341, 31.78341259141591],
            [-106.546690404804039, 31.807466262752619],
            [-106.60411910869108, 31.824647456564563],
            [-106.600529814698135, 31.844405829448291],
            [-106.636422754627546, 31.865882321713208],
            [-106.647190636606354, 31.898526589955893],
            [-106.622065578655778, 31.913989664386641],
            [-106.618476284662847, 32.000754693136926],
            [-105.430419972999729, 32.000754693136926],
            [-104.533096474764747, 31.999895633446329],
            [-103.721916032360312, 31.999895633446329],
            [-103.065075231652315, 32.000754693136926],
            [-103.065075231652315, 33.041935038140373],
            [-103.057896643666425, 33.260136199551987],
            [-103.057896643666425, 33.388136093450932],
            [-103.050718055680548, 33.746363984429841],
            [-103.043539467694671, 33.945665832648324],
            [-103.043539467694671, 35.249718442974427],
            [-103.039950173701726, 35.62255034869348],
            [-103.039950173701726, 36.317529638386375],
            [-103.043539467694671, 36.500509352483519],
            [-103.004057233772329, 36.500509352483519]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '56', name: 'Wyoming' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.059309667696667, 44.997468752177511],
            [-104.055720373703736, 44.874623216422158],
            [-104.055720373703736, 43.304262102011016],
            [-104.05213107971079, 43.297389624486236],
            [-104.05213107971079, 43.001014031230305],
            [-104.05213107971079, 41.001123071520709],
            [-104.831007876178759, 40.999404952139514],
            [-104.856132934129334, 40.997686832758319],
            [-105.724742080420796, 40.996827773067722],
            [-106.216475357453575, 40.997686832758319],
            [-106.453368760987601, 41.001982131211307],
            [-107.627067896678966, 41.001982131211307],
            [-108.179819171591717, 41.000264011830112],
            [-109.048428317883179, 41.000264011830112],
            [-109.536572300922998, 40.998545892448917],
            [-110.049841341913407, 40.997686832758319],
            [-110.236484629546283, 40.995109653686534],
            [-110.713860730607294, 40.995968713377124],
            [-111.047665071950718, 40.997686832758319],
            [-111.047665071950718, 41.377391216002152],
            [-111.044075777957772, 41.565525288242874],
            [-111.047665071950718, 42.001927611066101],
            [-111.047665071950718, 42.582651961909612],
            [-111.044075777957772, 42.722678691476908],
            [-111.044075777957772, 43.659053754227536],
            [-111.047665071950718, 43.681389306183057],
            [-111.047665071950718, 44.124664106531057],
            [-111.051254365943649, 44.354033043920431],
            [-111.047665071950718, 44.474301400603999],
            [-111.054843659936594, 44.624636846458458],
            [-111.05843295392954, 44.866891679206788],
            [-111.054843659936594, 45.000904990939901],
            [-110.785646610466102, 45.002623110321096],
            [-110.706682142621418, 44.992314394033933],
            [-110.401592153221529, 44.994032513415128],
            [-110.362109919299186, 45.000904990939901],
            [-110.200591689616886, 44.996609692486921],
            [-110.110859339793393, 45.003482170011694],
            [-109.798590762407613, 45.001764050630499],
            [-109.102267727777274, 45.006059349083486],
            [-109.062785493854932, 45.000045931249304],
            [-108.26955152141521, 45.000045931249304],
            [-107.135334619646187, 45.000045931249304],
            [-107.085084503745037, 44.996609692486921],
            [-106.568226168761683, 44.994891573105726],
            [-105.929331838018371, 44.994032513415128],
            [-105.846778076180755, 45.000045931249304],
            [-105.372991269112688, 45.000045931249304],
            [-104.472078476884761, 44.998327811868108],
            [-104.059309667696667, 44.997468752177511]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '09', name: 'Connecticut' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.485703435834353, 42.050034953739534],
            [-73.431864025940257, 42.050894013430124],
            [-73.008327334773341, 42.038867177761773],
            [-72.814505459154589, 42.036289998689981],
            [-72.818094753147534, 41.997632312613121],
            [-72.76784463724637, 42.002786670756699],
            [-72.757076755267548, 42.036289998689981],
            [-72.606326407564069, 42.031135640546395],
            [-72.527361939719398, 42.034571879308785],
            [-71.802324553145525, 42.023404103331025],
            [-71.798735259152593, 42.007941028900284],
            [-71.798735259152593, 41.916021642006413],
            [-71.787967377173771, 41.725310390693899],
            [-71.787967377173771, 41.596451437104363],
            [-71.798735259152593, 41.41690796176961],
            [-71.841806787067867, 41.410035484244837],
            [-71.831038905089045, 41.344746947759468],
            [-71.859753257032565, 41.319834216732161],
            [-71.884878314983155, 41.3361563508535],
            [-71.945896312863127, 41.337874470234695],
            [-72.021271486714866, 41.317257037660369],
            [-72.085878778587784, 41.319834216732161],
            [-72.132539600496003, 41.299216784157835],
            [-72.189968304383044, 41.323270455494551],
            [-72.211504068340687, 41.291485246942464],
            [-72.387379473994741, 41.261418157771573],
            [-72.398147355973563, 41.278599351583509],
            [-72.451986765867659, 41.278599351583509],
            [-72.548897703677028, 41.250250381793812],
            [-72.570433467634672, 41.268290635296346],
            [-72.66375511145111, 41.269149694986943],
            [-72.688880169401699, 41.246814143031422],
            [-72.753487461274617, 41.266572515915151],
            [-72.897059220992205, 41.243377904269039],
            [-72.907827102971027, 41.294921485704847],
            [-72.940130748907492, 41.280317470964704],
            [-73.008327334773341, 41.209874576335757],
            [-73.051398862688629, 41.209874576335757],
            [-73.130363330533299, 41.147163218922181],
            [-73.177024152441518, 41.166921591805909],
            [-73.26316720827208, 41.117096129751289],
            [-73.288292266222655, 41.12826390572905],
            [-73.370846028060285, 41.10421023439234],
            [-73.352899558095572, 41.085310921199202],
            [-73.388792498024984, 41.057821011100103],
            [-73.492882023820243, 41.048371354503537],
            [-73.571846491664914, 41.001982131211307],
            [-73.60415013760138, 41.014868026570262],
            [-73.657989547495475, 40.98480093739937],
            [-73.65440025350253, 41.013149907189067],
            [-73.726186133361338, 41.10077399562995],
            [-73.482114141841421, 41.21245175540755],
            [-73.55031072770727, 41.295780545395445],
            [-73.535953551735517, 41.434089155581546],
            [-73.518007081770818, 41.687511764307636],
            [-73.485703435834353, 42.050034953739534]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '29', name: 'Missouri' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.764451250012499, 40.585338181271808],
            [-95.165039153191529, 40.577606644056431],
            [-94.594341408314079, 40.570734166531658],
            [-94.034411545415452, 40.57331134560345],
            [-93.567803326333262, 40.580183823128223],
            [-93.345267098770989, 40.580183823128223],
            [-92.638176182161814, 40.590492539415386],
            [-92.092603495234954, 40.601660315393147],
            [-91.73008480194801, 40.613687151061505],
            [-91.687013274032736, 40.581042882818821],
            [-91.683423980039791, 40.552693913029124],
            [-91.618816688166874, 40.540667077360766],
            [-91.622405982159819, 40.509740928499276],
            [-91.564977278272778, 40.460774526135253],
            [-91.529084338343381, 40.459056406754058],
            [-91.525495044350436, 40.410949064080633],
            [-91.486012810428093, 40.384318213672131],
            [-91.417816224562245, 40.378304795837948],
            [-91.446530576505765, 40.362841721407207],
            [-91.507548574385737, 40.23913712596125],
            [-91.514727162371614, 40.178143887928876],
            [-91.493191398413984, 40.036399038980385],
            [-91.44294128251282, 39.951352129611287],
            [-91.417816224562245, 39.927298458274578],
            [-91.446530576505765, 39.870600518695177],
            [-91.428584106541066, 39.8379562504525],
            [-91.374744696646957, 39.808748220972205],
            [-91.360387520675204, 39.784694549635489],
            [-91.371155402654026, 39.732291908509076],
            [-91.277833758837588, 39.666144312333117],
            [-91.223994348943492, 39.617177909969094],
            [-91.18451211502115, 39.605151074300736],
            [-91.148619175091753, 39.545875955649549],
            [-91.101958353183534, 39.539003478124776],
            [-91.062476119261191, 39.473714941639408],
            [-91.00504741537415, 39.427325718347177],
            [-90.7932790697907, 39.309634540735402],
            [-90.721493189931891, 39.232319168581682],
            [-90.710725307953069, 39.155003796427955],
            [-90.682010956009563, 39.087997140561399],
            [-90.714314601946015, 39.057070991699909],
            [-90.674832368023672, 38.984050917999177],
            [-90.66406448604485, 38.928212038110374],
            [-90.58510001820018, 38.868936919459188],
            [-90.545617784277837, 38.874950337293363],
            [-90.506135550355495, 38.90244024739247],
            [-90.473831904419043, 38.959138186971863],
            [-90.40563531855318, 38.962574425734253],
            [-90.308724380743797, 38.923916739657393],
            [-90.25129567685677, 38.919621441204406],
            [-90.114902505125045, 38.849178546575459],
            [-90.122081093110921, 38.797634965139643],
            [-90.165152621026209, 38.772722234112337],
            [-90.211813442934428, 38.722037712367118],
            [-90.175920503005031, 38.643004220832204],
            [-90.183099090990908, 38.611219012280117],
            [-90.262063558835578, 38.528749281982812],
            [-90.283599322793222, 38.443702372613721],
            [-90.344617320673208, 38.385286313653133],
            [-90.369742378623783, 38.34061520974209],
            [-90.373331672616729, 38.273608553875533],
            [-90.355385202652016, 38.21347437553375],
            [-90.290777910779099, 38.170521391003902],
            [-90.218992030920305, 38.09406507854078],
            [-90.129259681096812, 38.062279869988693],
            [-90.08977744717447, 38.015890646696462],
            [-90.061063095230949, 38.015890646696462],
            [-90.000045097350963, 37.964347065260647],
            [-89.942616393463936, 37.970360483094822],
            [-89.931848511485114, 37.947165871448711],
            [-89.974920039400388, 37.926548438874384],
            [-89.953384275442744, 37.883595454344537],
            [-89.88159839558395, 37.879300155891549],
            [-89.845705455654553, 37.905931006300058],
            [-89.799044633746334, 37.881877334963342],
            [-89.795455339753389, 37.856964603936035],
            [-89.741615929859293, 37.846655887648872],
            [-89.659062168021677, 37.786521709307088],
            [-89.666240756007554, 37.752159321683209],
            [-89.615990640106403, 37.749582142611423],
            [-89.583686994169938, 37.713501635606349],
            [-89.515490408304075, 37.692884203032023],
            [-89.515490408304075, 37.643917800668],
            [-89.476008174381747, 37.585501741707411],
            [-89.522668996289966, 37.578629264182638],
            [-89.515490408304075, 37.53567627965279],
            [-89.422168764487637, 37.397367669466689],
            [-89.436525940459404, 37.344105968649679],
            [-89.4903653503535, 37.333797252362515],
            [-89.51907970229702, 37.28568990968909],
            [-89.458061704417034, 37.240159746087457],
            [-89.461650998409979, 37.199783940629402],
            [-89.41499017650176, 37.125045747547468],
            [-89.375507942579418, 37.08552900178001],
            [-89.379097236572363, 37.039998838178377],
            [-89.292954180741802, 36.991891495504952],
            [-89.257061240812405, 37.015086107151063],
            [-89.3109006507065, 37.053743793227923],
            [-89.26065053480535, 37.064052509515086],
            [-89.199632536925364, 37.015945166841661],
            [-89.170918184981844, 36.970415003240028],
            [-89.131435951059501, 36.982441838908386],
            [-89.099132305123049, 36.964401585405845],
            [-89.117078775087748, 36.887086213252125],
            [-89.138614539045392, 36.847569467484668],
            [-89.174507478974789, 36.839837930269297],
            [-89.17809677296772, 36.807193662026613],
            [-89.117078775087748, 36.775408453474526],
            [-89.12784665706657, 36.751354782137817],
            [-89.185275360953611, 36.753931961209609],
            [-89.199632536925364, 36.71613333482334],
            [-89.160150303003022, 36.666307872768719],
            [-89.188864654946542, 36.641395141741413],
            [-89.213989712897131, 36.580401903709031],
            [-89.26065053480535, 36.564938829278283],
            [-89.325257826678268, 36.631945485144847],
            [-89.371918648586487, 36.620777709167086],
            [-89.418579470494706, 36.498791233102324],
            [-89.447293822438226, 36.464428845478452],
            [-89.493954644346445, 36.470442263312627],
            [-89.486776056360554, 36.497073113721129],
            [-89.465240292402925, 36.529717381963813],
            [-89.483186762367623, 36.571811306803063],
            [-89.558561936219363, 36.573529426184258],
            [-89.572919112191116, 36.547757635466347],
            [-89.540615466254664, 36.497932173411726],
            [-89.51907970229702, 36.479032860218595],
            [-89.544204760247595, 36.424053040020397],
            [-89.508311820318198, 36.373368518275178],
            [-89.526258290282897, 36.341583309723092],
            [-89.612401346113458, 36.340724250032494],
            [-89.612401346113458, 36.308939041480407],
            [-89.537026172261719, 36.275435713547132],
            [-89.533436878268773, 36.252241101901014],
            [-89.587276288162883, 36.239355206542058],
            [-89.694955107951074, 36.253100161591611],
            [-89.705722989929896, 36.235059908089077],
            [-89.605222758127582, 36.171489490984904],
            [-89.601633464134636, 36.11908684985849],
            [-89.680597931979321, 36.084724462234618],
            [-89.691365813958129, 36.020294985439847],
            [-89.734437341873416, 36.000536612556118],
            [-90.37692096660966, 35.99538225441254],
            [-90.319492262722619, 36.089878820378196],
            [-90.236938500885003, 36.139704282432817],
            [-90.236938500885003, 36.159462655316545],
            [-90.183099090990908, 36.204992818918186],
            [-90.125670387103867, 36.229046490254895],
            [-90.114902505125045, 36.265986056950567],
            [-90.082598859188593, 36.271999474784742],
            [-90.064652389223895, 36.386254413634127],
            [-90.143616857068565, 36.424053040020397],
            [-90.132848975089743, 36.436938935379345],
            [-90.150795445054442, 36.497932173411726],
            [-91.403459048590477, 36.497073113721129],
            [-92.562801008310075, 36.497932173411726],
            [-93.068891461314607, 36.497932173411726],
            [-93.865714727747275, 36.498791233102324],
            [-94.619466466264655, 36.499650292792921],
            [-94.619466466264655, 36.998763973029725],
            [-94.619466466264655, 37.240159746087457],
            [-94.615877172271723, 37.323488536075352],
            [-94.615877172271723, 37.992696035050344],
            [-94.612287878278778, 38.037367138961386],
            [-94.612287878278778, 38.635272683616833],
            [-94.608698584285833, 38.738359846488457],
            [-94.608698584285833, 39.119782349113486],
            [-94.590752114321134, 39.155003796427955],
            [-94.662537994179942, 39.157580975499748],
            [-94.680484464144641, 39.18421182590825],
            [-94.712788110081092, 39.170466870858704],
            [-94.763038225982257, 39.179916527455269],
            [-94.780984695946955, 39.206547377863771],
            [-94.834824105841051, 39.217715153841532],
            [-94.824056223862229, 39.241768825178248],
            [-94.888663515735146, 39.286439929089283],
            [-94.91019927969279, 39.354305644646438],
            [-94.877895633756339, 39.375782136911361],
            [-94.895842103721037, 39.393822390413895],
            [-94.946092219622187, 39.393822390413895],
            [-94.989163747537475, 39.446225031540308],
            [-95.046592451424516, 39.47285588194881],
            [-95.050181745417447, 39.497768612976124],
            [-95.104021155311543, 39.532990060290594],
            [-95.107610449304488, 39.574224925439246],
            [-95.046592451424516, 39.594842358013572],
            [-95.053771039410393, 39.630063805328049],
            [-95.028645981459803, 39.665285252642519],
            [-94.974806571565708, 39.681607386763858],
            [-94.956860101601009, 39.746036863558629],
            [-94.91019927969279, 39.725419430984303],
            [-94.874306339763393, 39.730573789127888],
            [-94.870717045770448, 39.772667713967131],
            [-94.913788573685736, 39.758922758917585],
            [-94.924556455664558, 39.789848907779074],
            [-94.892252809728092, 39.790707967469665],
            [-94.877895633756339, 39.826788474474739],
            [-94.917377867678667, 39.836238131071305],
            [-94.942502925629256, 39.864587100861002],
            [-94.928145749657489, 39.888640772197718],
            [-95.01428880548805, 39.899808548175479],
            [-95.043003157431571, 39.864587100861002],
            [-95.136324801248008, 39.87661393652936],
            [-95.154271271212707, 39.907540085390849],
            [-95.200932093120926, 39.902385727247264],
            [-95.204521387113871, 39.939325293942936],
            [-95.25118220902209, 39.948774950539502],
            [-95.308610912909131, 40.00031853197531],
            [-95.419879026690268, 40.048425874648743],
            [-95.391164674746747, 40.116291590205897],
            [-95.477307730577294, 40.181580126691259],
            [-95.484486318563185, 40.247727722867225],
            [-95.556272198421979, 40.264049856988564],
            [-95.552682904429034, 40.291539767087663],
            [-95.617290196301965, 40.313875319043184],
            [-95.660361724217239, 40.40837188500884],
            [-95.656772430224294, 40.441875212942122],
            [-95.696254664146636, 40.471083242422417],
            [-95.699843958139581, 40.505445630046296],
            [-95.653183136231362, 40.541526137051363],
            [-95.692665370153691, 40.556989211482112],
            [-95.696254664146636, 40.528640241692408],
            [-95.76804054400543, 40.5312174207642],
            [-95.764451250012499, 40.585338181271808]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '54', name: 'West Virginia' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.595331589915901, 38.422225880348797],
            [-82.530724298042983, 38.405044686536861],
            [-82.40509900829008, 38.439407074160734],
            [-82.322545246452464, 38.448856730757299],
            [-82.304598776487765, 38.490950655596549],
            [-82.293830894508943, 38.575138505275049],
            [-82.25075936659367, 38.599192176611758],
            [-82.193330662706629, 38.593178758777583],
            [-82.171794898748985, 38.618950549495487],
            [-82.182562780727807, 38.705715578245773],
            [-82.218455720657204, 38.795916845758448],
            [-82.143080546805464, 38.840587949669491],
            [-82.143080546805464, 38.898144948939482],
            [-82.0928304309043, 38.973742201712014],
            [-82.049758902989026, 38.996936813358126],
            [-82.028223139031383, 39.028722021910212],
            [-81.981562317123164, 38.994359634286333],
            [-81.942080083200835, 38.993500574595743],
            [-81.899008555285548, 38.925634859038581],
            [-81.927722907229068, 38.891272471414709],
            [-81.888240673306726, 38.874091277602773],
            [-81.855937027370274, 38.892990590795904],
            [-81.845169145391452, 38.929071097800971],
            [-81.812865499454986, 38.946252291612907],
            [-81.780561853518535, 38.924775799347984],
            [-81.766204677546767, 39.020131425004244],
            [-81.802097617476178, 39.045044156031551],
            [-81.812865499454986, 39.081983722727223],
            [-81.744668913589138, 39.098305856848562],
            [-81.75543679556796, 39.180775587145867],
            [-81.726722443624439, 39.215997034460337],
            [-81.690829503695028, 39.2263057507475],
            [-81.68006162171622, 39.273554033730328],
            [-81.568793507935069, 39.267540615896152],
            [-81.558025625956262, 39.338842570215697],
            [-81.468293276132755, 39.404131106701058],
            [-81.407275278252783, 39.38780897257972],
            [-81.385739514295139, 39.343137868668677],
            [-81.346257280372797, 39.34571504774047],
            [-81.270882106521057, 39.386090853198525],
            [-81.209864108641085, 39.392963330723298],
            [-81.170381874718743, 39.439352554015535],
            [-81.1308996407964, 39.446225031540308],
            [-81.10218528885288, 39.487459896688961],
            [-81.044756584965853, 39.536426299052984],
            [-80.937077765177648, 39.612882611516106],
            [-80.879649061290607, 39.620614148731484],
            [-80.865291885318854, 39.691916103051021],
            [-80.829398945389457, 39.714251655006542],
            [-80.868881179311785, 39.75720463953639],
            [-80.825809651396511, 39.798439504685042],
            [-80.825809651396511, 39.847405907049065],
            [-80.789916711467114, 39.867164279932794],
            [-80.804273887438868, 39.91870786136861],
            [-80.757613065530649, 39.909258204772044],
            [-80.764791653516539, 39.95392930868308],
            [-80.73966659556595, 39.971110502495016],
            [-80.73966659556595, 40.075915784747842],
            [-80.707362949629498, 40.101687575465746],
            [-80.707362949629498, 40.151513037520367],
            [-80.671470009700087, 40.192747902669019],
            [-80.653523539735389, 40.245150543795432],
            [-80.617630599805992, 40.264908916679161],
            [-80.599684129841293, 40.320747796567957],
            [-80.610452011820115, 40.402358467174665],
            [-80.596094835848362, 40.463351705207046],
            [-80.628398481784814, 40.535512719217188],
            [-80.667880715707156, 40.57331134560345],
            [-80.63557706977069, 40.616264330133298],
            [-80.58532695386954, 40.6154052704427],
            [-80.520719661996623, 40.638599882088812],
            [-80.520719661996623, 39.721124132531315],
            [-80.075647206872063, 39.721124132531315],
            [-79.476235110051093, 39.721124132531315],
            [-79.487002992029915, 39.205688318173173],
            [-79.451110052100518, 39.211701736007356],
            [-79.332663350333505, 39.300184884138837],
            [-79.289591822418217, 39.299325824448239],
            [-79.25369888248882, 39.35688282371823],
            [-79.142430768707683, 39.408426405154046],
            [-79.095769946799464, 39.47285588194881],
            [-79.056287712877122, 39.471137762567622],
            [-78.955787481074807, 39.440211613706133],
            [-78.916305247152465, 39.486600836998363],
            [-78.779912075420754, 39.622332268112672],
            [-78.733251253512535, 39.613741671206704],
            [-78.779912075420754, 39.601714835538345],
            [-78.733251253512535, 39.586251761107604],
            [-78.726072665526658, 39.563916209152083],
            [-78.65428678566785, 39.534708179671789],
            [-78.593268787787878, 39.535567239362386],
            [-78.568143729837303, 39.520104164931645],
            [-78.467643498034974, 39.516667926169255],
            [-78.417393382133824, 39.549312194411939],
            [-78.442518440084399, 39.591406119251182],
            [-78.39585761817618, 39.590547059560592],
            [-78.435339852098522, 39.618036969659691],
            [-78.385089736197358, 39.608587313063126],
            [-78.352786090260906, 39.640372521615213],
            [-78.284589504395043, 39.620614148731484],
            [-78.184089272592729, 39.695352341813411],
            [-78.097946216762168, 39.678171148001475],
            [-78.036928218882181, 39.636077223162225],
            [-78.008213866938661, 39.601714835538345],
            [-77.943606575065743, 39.618896029350289],
            [-77.882588577185771, 39.59913765646656],
            [-77.832338461284607, 39.602573895228943],
            [-77.832338461284607, 39.570788686676863],
            [-77.889767165171648, 39.557902791317908],
            [-77.846695637256374, 39.498627672666721],
            [-77.778499051390511, 39.463406225352244],
            [-77.785677639376388, 39.435057255562548],
            [-77.753373993439936, 39.424748539275384],
            [-77.735427523475238, 39.38780897257972],
            [-77.756963287432868, 39.333688212072111],
            [-77.721070347503471, 39.32166137640376],
            [-77.753373993439936, 39.283003690326893],
            [-77.828749167291676, 39.132668244472441],
            [-78.10871409874099, 39.312211719807195],
            [-78.345607502275016, 39.465983404424037],
            [-78.359964678246783, 39.412721703607026],
            [-78.342018208282084, 39.388668032270317],
            [-78.363553972239714, 39.357741883408828],
            [-78.338428914289139, 39.348292226812262],
            [-78.420982676126755, 39.257231899608989],
            [-78.399446912169125, 39.244346004250033],
            [-78.438929146091454, 39.197956780957803],
            [-78.403036206162056, 39.167030632096314],
            [-78.460464910049097, 39.113768931279303],
            [-78.485589967999672, 39.107755513445127],
            [-78.571733023830234, 39.032158260672603],
            [-78.55019725987259, 39.018413305623049],
            [-78.600447375773754, 38.964292545115441],
            [-78.625572433724329, 38.982332798617982],
            [-78.679411843618439, 38.925634859038581],
            [-78.715304783547836, 38.905017426464255],
            [-78.715304783547836, 38.935943575325744],
            [-78.797858545385452, 38.874950337293363],
            [-78.869644425244246, 38.763272577515771],
            [-78.99526971499715, 38.850037606266056],
            [-79.027573360933602, 38.79076248761487],
            [-79.088591358813588, 38.720319592985923],
            [-79.092180652806519, 38.659326354953542],
            [-79.120895004750039, 38.66018541464414],
            [-79.156787944679451, 38.611219012280117],
            [-79.210627354573546, 38.492668774977744],
            [-79.311127586375861, 38.411917164061634],
            [-79.476235110051093, 38.457447327663267],
            [-79.537253107931079, 38.551084833938333],
            [-79.648521221712215, 38.591460639396388],
            [-79.69877133761338, 38.487514416834159],
            [-79.688003455634558, 38.431675536945363],
            [-79.731074983549831, 38.380131955509547],
            [-79.810039451394516, 38.30539376242762],
            [-79.788503687436872, 38.267595136041351],
            [-79.831575215352146, 38.249554882538817],
            [-79.921307565175653, 38.179971047600468],
            [-79.946432623126228, 38.131863704927042],
            [-79.924896859168584, 38.106950973899735],
            [-79.953611211112104, 38.081179183181824],
            [-79.975146975069748, 38.029635601746008],
            [-80.011039914999145, 37.98582355752557],
            [-80.075647206872063, 37.942011513305125],
            [-80.183326026660268, 37.852669305483047],
            [-80.258701200512007, 37.756454620136196],
            [-80.251522612526117, 37.725528471274707],
            [-80.294594140441404, 37.692025143341425],
            [-80.280236964469637, 37.656803696026955],
            [-80.219218966589665, 37.627595666546661],
            [-80.262290494504938, 37.593233278922781],
            [-80.326897786377856, 37.564025249442487],
            [-80.330487080370801, 37.536535339343388],
            [-80.283826258462582, 37.533099100580998],
            [-80.298183434434335, 37.508186369553691],
            [-80.474058840088404, 37.423139460184593],
            [-80.509951780017801, 37.481555519145182],
            [-80.553023307933074, 37.473823981929812],
            [-80.621219893798937, 37.433448176471757],
            [-80.768380947509471, 37.372454938439375],
            [-80.782738123481238, 37.394790490394897],
            [-80.858113297332977, 37.427434758637581],
            [-80.883238355283552, 37.383622714417136],
            [-80.850934709347086, 37.346683147721471],
            [-80.919131295212949, 37.306307342263416],
            [-80.980149293092921, 37.302012043810429],
            [-81.112953170831702, 37.278817432164317],
            [-81.224221284612838, 37.235005387943872],
            [-81.321132222422222, 37.299434864738643],
            [-81.360614456344564, 37.338092550815503],
            [-81.389328808288084, 37.320052297312969],
            [-81.418043160231605, 37.272804014330134],
            [-81.500596922069221, 37.258199999589991],
            [-81.507775510055097, 37.232428208872079],
            [-81.554436331963316, 37.207515477844773],
            [-81.676472327723275, 37.201502060010597],
            [-81.744668913589138, 37.242736925159242],
            [-81.744668913589138, 37.263354357733569],
            [-81.787740441504411, 37.284830849998492],
            [-81.852347733377329, 37.287408029070285],
            [-81.877472791327904, 37.33207913298132],
            [-81.927722907229068, 37.358709983389829],
            [-81.934901495214945, 37.436025355543549],
            [-81.988740905109054, 37.45492466873668],
            [-81.992330199101985, 37.483273638526377],
            [-81.927722907229068, 37.512481668006671],
            [-81.96720514115141, 37.537394399033985],
            [-82.017455257052561, 37.533958160271595],
            [-82.117955488854889, 37.559729950989507],
            [-82.128723370833711, 37.591515159541586],
            [-82.157437722777232, 37.592374219232184],
            [-82.182562780727807, 37.626736606856063],
            [-82.214866426664258, 37.625018487474868],
            [-82.239991484614848, 37.661098994479936],
            [-82.293830894508943, 37.670548651076501],
            [-82.340491716417162, 37.785662649616491],
            [-82.376384656346559, 37.801984783737829],
            [-82.423045478254778, 37.854387424864242],
            [-82.419456184261833, 37.883595454344537],
            [-82.473295594155942, 37.899917588465875],
            [-82.502009946099463, 37.933420916399157],
            [-82.462527712177121, 37.980669199381985],
            [-82.516367122071216, 37.999568512575117],
            [-82.548670768007682, 38.068293287822868],
            [-82.584563707937079, 38.106950973899735],
            [-82.638403117831174, 38.138736182451815],
            [-82.638403117831174, 38.1713804506945],
            [-82.609688765887654, 38.1713804506945],
            [-82.598920883908832, 38.218628733677328],
            [-82.613278059880599, 38.235809927489271],
            [-82.573795825958257, 38.264158897278968],
            [-82.570206531965312, 38.313984359333588],
            [-82.598920883908832, 38.344910508195078],
            [-82.595331589915901, 38.422225880348797]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '17', name: 'Illinois' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.417816224562245, 40.378304795837948],
            [-91.374744696646957, 40.392049750887502],
            [-91.381923284632848, 40.435002735417349],
            [-91.36756610866108, 40.512318107571069],
            [-91.407048342583423, 40.542385196741961],
            [-91.360387520675204, 40.601660315393147],
            [-91.249119406894067, 40.638599882088812],
            [-91.18451211502115, 40.637740822398214],
            [-91.119904823148232, 40.672962269712691],
            [-91.116315529155287, 40.725364910839104],
            [-91.094779765197643, 40.767458835678347],
            [-91.091190471204712, 40.825015834948346],
            [-91.055297531275315, 40.848210446594457],
            [-91.00504741537415, 40.904908386173858],
            [-90.965565181451808, 40.921230520295197],
            [-90.944029417494164, 41.01229084749847],
            [-90.94761871148711, 41.096478697176963],
            [-90.994279533395328, 41.160908173971734],
            [-91.040940355303547, 41.166062532115312],
            [-91.112726235162341, 41.239082605816051],
            [-91.073244001240013, 41.305230201992011],
            [-91.066065413254123, 41.368800619096184],
            [-91.044529649296493, 41.416048902079012],
            [-90.97633306343063, 41.434089155581546],
            [-90.929672241522411, 41.421203260222597],
            [-90.847118479684795, 41.45556564784647],
            [-90.735850365903659, 41.450411289702892],
            [-90.649707310073097, 41.465015304443035],
            [-90.592278606186056, 41.513122647116461],
            [-90.556385666256659, 41.524290423094222],
            [-90.463064022440221, 41.523431363403624],
            [-90.398456730567304, 41.572397765767654],
            [-90.341028026680263, 41.590438019270188],
            [-90.344617320673208, 41.647135958849582],
            [-90.312313674736743, 41.6978204805948],
            [-90.312313674736743, 41.742491584505835],
            [-90.280010028800291, 41.767404315533149],
            [-90.179509796997962, 41.809498240372399],
            [-90.183099090990908, 41.844719687686869],
            [-90.154384739047387, 41.906571985409848],
            [-90.165152621026209, 41.956397447464468],
            [-90.14002756307562, 42.008800088590881],
            [-90.165152621026209, 42.040585297142961],
            [-90.161563327033264, 42.11704160960609],
            [-90.208224148941483, 42.15226305692056],
            [-90.37692096660966, 42.214974414334137],
            [-90.430760376503756, 42.27854483143831],
            [-90.419992494524948, 42.330088412874119],
            [-90.477421198411975, 42.384209173381727],
            [-90.556385666256659, 42.415994381933814],
            [-90.563564254242536, 42.438329933889335],
            [-90.642528722087221, 42.468397023060227],
            [-90.642528722087221, 42.508772828518275],
            [-90.48101049240492, 42.507054709137087],
            [-89.666240756007554, 42.505336589755892],
            [-89.483186762367623, 42.501041291302904],
            [-88.78686372773727, 42.491591634706339],
            [-88.639702674026736, 42.495027873468729],
            [-88.094129987099862, 42.495886933159326],
            [-87.799807879678795, 42.491591634706339],
            [-87.806986467664672, 42.385068233072325],
            [-87.835700819608192, 42.301739443084422],
            [-87.799807879678795, 42.208101936809364],
            [-87.724432705827056, 42.107591953009525],
            [-87.681361177911782, 42.075806744457438],
            [-87.67059329593296, 42.029417521165207],
            [-87.623932474024741, 41.910008224172238],
            [-87.609575298052974, 41.845578747377466],
            [-87.559325182151824, 41.765686196151954],
            [-87.530610830208303, 41.748505002340018],
            [-87.523432242222412, 41.708129196881963],
            [-87.527021536215358, 41.489928035470349],
            [-87.527021536215358, 40.462492645516448],
            [-87.530610830208303, 40.25030490193901],
            [-87.530610830208303, 39.997741352903525],
            [-87.534200124201234, 39.987432636616361],
            [-87.534200124201234, 39.646385939449388],
            [-87.530610830208303, 39.646385939449388],
            [-87.530610830208303, 39.348292226812262],
            [-87.577271652116522, 39.340560689596892],
            [-87.598807416074152, 39.313070779497785],
            [-87.605986004060043, 39.261527198061977],
            [-87.573682358123577, 39.218574213532129],
            [-87.64187894398944, 39.167030632096314],
            [-87.631111062010618, 39.104319274682737],
            [-87.573682358123577, 39.057070991699909],
            [-87.577271652116522, 38.989205276142755],
            [-87.512664360243605, 38.954842888518876],
            [-87.552146594165933, 38.859487262862622],
            [-87.498307184271837, 38.778735651946512],
            [-87.494717890278906, 38.742655144941445],
            [-87.544968006180056, 38.677366608456076],
            [-87.595218122081221, 38.667057892168913],
            [-87.620343180031796, 38.639567982069813],
            [-87.652646825968262, 38.568266027750269],
            [-87.652646825968262, 38.511568088170876],
            [-87.738789881798809, 38.475487581165808],
            [-87.731611293812932, 38.442843312923124],
            [-87.778272115721151, 38.370682298912982],
            [-87.806986467664672, 38.362950761697611],
            [-87.853647289572891, 38.275326673256728],
            [-87.882361641516411, 38.303675643046425],
            [-87.907486699466986, 38.268454195731948],
            [-87.925433169431685, 38.29852128490284],
            [-87.95773681536815, 38.240105225942251],
            [-87.990040461304616, 38.248695822848219],
            [-87.975683285332849, 38.198011301103001],
            [-87.925433169431685, 38.146467719667193],
            [-88.004397637276369, 38.083756362253617],
            [-87.961326109361096, 38.100078496374955],
            [-87.968504697346972, 38.067434228132278],
            [-88.036701283212835, 38.051112094010932],
            [-88.007986931269315, 38.028776542055411],
            [-88.011576225262246, 37.977232960619602],
            [-88.065415635156342, 37.919675961349604],
            [-88.011576225262246, 37.894763230322297],
            [-88.097719281092807, 37.90163570784707],
            [-88.040290577205766, 37.822602216312156],
            [-88.083362105121054, 37.830333753527526],
            [-88.069004929149287, 37.801125724047232],
            [-88.029522695226945, 37.799407604666037],
            [-88.058237047170465, 37.742709665086643],
            [-88.119255045050451, 37.712642575915751],
            [-88.158737278972779, 37.664535233242326],
            [-88.133612221022204, 37.57433396572965],
            [-88.06182634116341, 37.515917906769062],
            [-88.083362105121054, 37.472964922239214],
            [-88.255648216782163, 37.456642788117875],
            [-88.298719744697436, 37.44719313152131],
            [-88.363327036570368, 37.402522027610267],
            [-88.413577152471518, 37.424857579565789],
            [-88.478184444344436, 37.387058953179526],
            [-88.485363032330326, 37.339810670196698],
            [-88.517666678266778, 37.283971790307895],
            [-88.510488090280901, 37.262495298042971],
            [-88.449470092400915, 37.206656418154175],
            [-88.42434503445034, 37.149958478574781],
            [-88.460237974379737, 37.073502166111652],
            [-88.514077384273833, 37.064911569205684],
            [-88.560738206182052, 37.076079345183445],
            [-88.628934792047914, 37.120750449094487],
            [-88.754560081800818, 37.155112836718359],
            [-88.804810197701968, 37.188616164651641],
            [-88.916078311483105, 37.224696671656709],
            [-88.984274897348968, 37.228991970109696],
            [-89.030935719257187, 37.210951716607163],
            [-89.077596541165406, 37.174871209602088],
            [-89.109900187101871, 37.119032329713292],
            [-89.167328890988912, 37.074361225802249],
            [-89.181686066960665, 37.027112942819421],
            [-89.131435951059501, 36.982441838908386],
            [-89.170918184981844, 36.970415003240028],
            [-89.199632536925364, 37.015945166841661],
            [-89.26065053480535, 37.064052509515086],
            [-89.3109006507065, 37.053743793227923],
            [-89.257061240812405, 37.015086107151063],
            [-89.292954180741802, 36.991891495504952],
            [-89.379097236572363, 37.039998838178377],
            [-89.375507942579418, 37.08552900178001],
            [-89.41499017650176, 37.125045747547468],
            [-89.461650998409979, 37.199783940629402],
            [-89.458061704417034, 37.240159746087457],
            [-89.51907970229702, 37.28568990968909],
            [-89.4903653503535, 37.333797252362515],
            [-89.436525940459404, 37.344105968649679],
            [-89.422168764487637, 37.397367669466689],
            [-89.515490408304075, 37.53567627965279],
            [-89.522668996289966, 37.578629264182638],
            [-89.476008174381747, 37.585501741707411],
            [-89.515490408304075, 37.643917800668],
            [-89.515490408304075, 37.692884203032023],
            [-89.583686994169938, 37.713501635606349],
            [-89.615990640106403, 37.749582142611423],
            [-89.666240756007554, 37.752159321683209],
            [-89.659062168021677, 37.786521709307088],
            [-89.741615929859293, 37.846655887648872],
            [-89.795455339753389, 37.856964603936035],
            [-89.799044633746334, 37.881877334963342],
            [-89.845705455654553, 37.905931006300058],
            [-89.88159839558395, 37.879300155891549],
            [-89.953384275442744, 37.883595454344537],
            [-89.974920039400388, 37.926548438874384],
            [-89.931848511485114, 37.947165871448711],
            [-89.942616393463936, 37.970360483094822],
            [-90.000045097350963, 37.964347065260647],
            [-90.061063095230949, 38.015890646696462],
            [-90.08977744717447, 38.015890646696462],
            [-90.129259681096812, 38.062279869988693],
            [-90.218992030920305, 38.09406507854078],
            [-90.290777910779099, 38.170521391003902],
            [-90.355385202652016, 38.21347437553375],
            [-90.373331672616729, 38.273608553875533],
            [-90.369742378623783, 38.34061520974209],
            [-90.344617320673208, 38.385286313653133],
            [-90.283599322793222, 38.443702372613721],
            [-90.262063558835578, 38.528749281982812],
            [-90.183099090990908, 38.611219012280117],
            [-90.175920503005031, 38.643004220832204],
            [-90.211813442934428, 38.722037712367118],
            [-90.165152621026209, 38.772722234112337],
            [-90.122081093110921, 38.797634965139643],
            [-90.114902505125045, 38.849178546575459],
            [-90.25129567685677, 38.919621441204406],
            [-90.308724380743797, 38.923916739657393],
            [-90.40563531855318, 38.962574425734253],
            [-90.473831904419043, 38.959138186971863],
            [-90.506135550355495, 38.90244024739247],
            [-90.545617784277837, 38.874950337293363],
            [-90.58510001820018, 38.868936919459188],
            [-90.66406448604485, 38.928212038110374],
            [-90.674832368023672, 38.984050917999177],
            [-90.714314601946015, 39.057070991699909],
            [-90.682010956009563, 39.087997140561399],
            [-90.710725307953069, 39.155003796427955],
            [-90.721493189931891, 39.232319168581682],
            [-90.7932790697907, 39.309634540735402],
            [-91.00504741537415, 39.427325718347177],
            [-91.062476119261191, 39.473714941639408],
            [-91.101958353183534, 39.539003478124776],
            [-91.148619175091753, 39.545875955649549],
            [-91.18451211502115, 39.605151074300736],
            [-91.223994348943492, 39.617177909969094],
            [-91.277833758837588, 39.666144312333117],
            [-91.371155402654026, 39.732291908509076],
            [-91.360387520675204, 39.784694549635489],
            [-91.374744696646957, 39.808748220972205],
            [-91.428584106541066, 39.8379562504525],
            [-91.446530576505765, 39.870600518695177],
            [-91.417816224562245, 39.927298458274578],
            [-91.44294128251282, 39.951352129611287],
            [-91.493191398413984, 40.036399038980385],
            [-91.514727162371614, 40.178143887928876],
            [-91.507548574385737, 40.23913712596125],
            [-91.446530576505765, 40.362841721407207],
            [-91.417816224562245, 40.378304795837948]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '35', name: 'New Mexico' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.044839023890233, 36.998763973029725],
            [-108.248015757457566, 36.998763973029725],
            [-107.422478139081392, 36.999623032720322],
            [-106.876905452154517, 37.00048209241092],
            [-106.869726864168641, 36.992750555195549],
            [-106.202118181481808, 36.994468674576737],
            [-105.121740689606895, 36.995327734267335],
            [-104.999704693846937, 36.99360961488614],
            [-104.33927459914598, 36.99360961488614],
            [-103.732683914339134, 36.997904913339127],
            [-103.000467939779398, 37.00048209241092],
            [-103.000467939779398, 36.602737455664553],
            [-103.004057233772329, 36.500509352483519],
            [-103.043539467694671, 36.500509352483519],
            [-103.039950173701726, 36.317529638386375],
            [-103.039950173701726, 35.62255034869348],
            [-103.043539467694671, 35.249718442974427],
            [-103.043539467694671, 33.945665832648324],
            [-103.050718055680548, 33.746363984429841],
            [-103.057896643666425, 33.388136093450932],
            [-103.057896643666425, 33.260136199551987],
            [-103.065075231652315, 33.041935038140373],
            [-103.065075231652315, 32.000754693136926],
            [-103.721916032360312, 31.999895633446329],
            [-104.533096474764747, 31.999895633446329],
            [-105.430419972999729, 32.000754693136926],
            [-106.618476284662847, 32.000754693136926],
            [-106.622065578655778, 31.913989664386641],
            [-106.647190636606354, 31.898526589955893],
            [-106.636422754627546, 31.865882321713208],
            [-106.600529814698135, 31.844405829448291],
            [-106.60411910869108, 31.824647456564563],
            [-106.546690404804039, 31.807466262752619],
            [-106.528743934839341, 31.78341259141591],
            [-107.296852849328488, 31.78341259141591],
            [-108.208533523535223, 31.78341259141591],
            [-108.208533523535223, 31.33326531354313],
            [-109.048428317883179, 31.332406253852533],
            [-109.048428317883179, 33.778149192981921],
            [-109.044839023890233, 33.875222938019377],
            [-109.048428317883179, 33.999786593155925],
            [-109.044839023890233, 34.522094885038847],
            [-109.044839023890233, 35.316725098840983],
            [-109.048428317883179, 35.342496889558888],
            [-109.044839023890233, 35.546094036230357],
            [-109.044839023890233, 36.998763973029725]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '05', name: 'Arkansas' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.619466466264655, 36.499650292792921],
            [-93.865714727747275, 36.498791233102324],
            [-93.068891461314607, 36.497932173411726],
            [-92.562801008310075, 36.497932173411726],
            [-91.403459048590477, 36.497073113721129],
            [-90.150795445054442, 36.497932173411726],
            [-90.132848975089743, 36.436938935379345],
            [-90.143616857068565, 36.424053040020397],
            [-90.064652389223895, 36.386254413634127],
            [-90.082598859188593, 36.271999474784742],
            [-90.114902505125045, 36.265986056950567],
            [-90.125670387103867, 36.229046490254895],
            [-90.183099090990908, 36.204992818918186],
            [-90.236938500885003, 36.159462655316545],
            [-90.236938500885003, 36.139704282432817],
            [-90.319492262722619, 36.089878820378196],
            [-90.37692096660966, 35.99538225441254],
            [-89.734437341873416, 36.000536612556118],
            [-89.720080165901649, 35.967892344313434],
            [-89.655472874028732, 35.925798419474191],
            [-89.655472874028732, 35.887140733397331],
            [-89.741615929859293, 35.90689910628106],
            [-89.773919575795759, 35.86480518144181],
            [-89.702133695936951, 35.83387903258032],
            [-89.741615929859293, 35.805530062790623],
            [-89.781098163781635, 35.804671003100026],
            [-89.813401809718087, 35.759999899188983],
            [-89.874419807598073, 35.741100585995852],
            [-89.906723453534525, 35.759140839498386],
            [-89.960562863428635, 35.723919392183916],
            [-89.939027099470991, 35.665503333223327],
            [-89.906723453534525, 35.650899318483177],
            [-89.863651925619251, 35.670657691366905],
            [-89.852884043640429, 35.638013423124228],
            [-89.946205687456867, 35.601932916119154],
            [-89.942616393463936, 35.556402752517521],
            [-89.91031274752747, 35.547812155611552],
            [-89.921080629506292, 35.513449767987673],
            [-90.000045097350963, 35.561557110661099],
            [-90.032348743287429, 35.553825573445728],
            [-90.050295213252127, 35.504000111391107],
            [-90.017991567315676, 35.46791960438604],
            [-90.057473801238004, 35.403490127591269],
            [-90.086188153181524, 35.479087380363801],
            [-90.16874191501914, 35.421530381093802],
            [-90.179509796997962, 35.382013635326345],
            [-90.136438269082689, 35.376859277182767],
            [-90.129259681096812, 35.413798843878432],
            [-90.075420271202702, 35.38373175470754],
            [-90.107723917139168, 35.343355949249485],
            [-90.107723917139168, 35.304698263172625],
            [-90.165152621026209, 35.296107666266657],
            [-90.150795445054442, 35.255731860808602],
            [-90.096956035160346, 35.249718442974427],
            [-90.075420271202702, 35.224805711947113],
            [-90.11849179911799, 35.187866145251448],
            [-90.064652389223895, 35.138040683196827],
            [-90.100545329153292, 35.116564190931904],
            [-90.143616857068565, 35.134604444434437],
            [-90.175920503005031, 35.112268892478916],
            [-90.208224148941483, 35.026362923419228],
            [-90.297956498764989, 35.037530699396989],
            [-90.308724380743797, 34.995436774557739],
            [-90.247706382863825, 34.948188491574911],
            [-90.247706382863825, 34.909530805498051],
            [-90.312313674736743, 34.871732179111788],
            [-90.423581788517879, 34.833933552725519],
            [-90.437938964489646, 34.884618074470737],
            [-90.477421198411975, 34.886336193851932],
            [-90.484599786397865, 34.861423462824625],
            [-90.455885434454345, 34.823624836438356],
            [-90.473831904419043, 34.802148344173439],
            [-90.452296140461399, 34.74029604645046],
            [-90.502546256362564, 34.723973912329114],
            [-90.520492726327262, 34.753181941809409],
            [-90.513314138341386, 34.802148344173439],
            [-90.549207078270783, 34.77895373252732],
            [-90.542028490284892, 34.748886643356428],
            [-90.567153548235481, 34.73685980768807],
            [-90.545617784277837, 34.702497420064198],
            [-90.473831904419043, 34.700779300683003],
            [-90.466653316433167, 34.674148450274494],
            [-90.516903432334317, 34.631195465744653],
            [-90.556385666256659, 34.646658540175395],
            [-90.552796372263714, 34.688752465014645],
            [-90.588689312193111, 34.670712211512111],
            [-90.588689312193111, 34.615732391313905],
            [-90.545617784277837, 34.563329750187499],
            [-90.542028490284892, 34.54013513854138],
            [-90.588689312193111, 34.496323094320935],
            [-90.567153548235481, 34.425021140001391],
            [-90.656885898058974, 34.376054737637368],
            [-90.656885898058974, 34.321933977129767],
            [-90.692778837988371, 34.322793036820364],
            [-90.682010956009563, 34.369182260112595],
            [-90.750207541875412, 34.368323200421997],
            [-90.76815401184011, 34.345128588775879],
            [-90.73943965989659, 34.306470902699019],
            [-90.764564717847179, 34.280699111981114],
            [-90.832761303713028, 34.267813216622159],
            [-90.847118479684795, 34.206819978589778],
            [-90.904547183571836, 34.24375954528545],
            [-90.936850829508288, 34.218846814258136],
            [-90.886600713607137, 34.181907247562471],
            [-90.814814833748329, 34.182766307253068],
            [-90.807636245762453, 34.161289814988145],
            [-90.854297067670672, 34.137236143651428],
            [-90.911725771557713, 34.165585113441125],
            [-90.954797299472986, 34.138095203342026],
            [-90.922493653536534, 34.094283159121588],
            [-90.883011419614192, 34.096860338193373],
            [-90.87224353763537, 34.076242905619047],
            [-90.900957889578891, 34.023840264492641],
            [-90.987100945409452, 34.018685906349056],
            [-90.961975887458877, 33.980028220272196],
            [-91.001458121381205, 33.96628326522265],
            [-91.019404591345904, 34.003222831918315],
            [-91.076833295232944, 33.983464459034586],
            [-91.087601177211766, 33.958551728007272],
            [-91.008636709367096, 33.929343698526978],
            [-91.073244001240013, 33.862337042660421],
            [-91.048118943289424, 33.815088759677593],
            [-90.990690239402383, 33.792753207722072],
            [-91.026583179331794, 33.763545178241777],
            [-91.055297531275315, 33.779008252672519],
            [-91.141440587105862, 33.777290133291324],
            [-91.145029881098807, 33.726605611546113],
            [-91.10913694116941, 33.704270059590591],
            [-91.062476119261191, 33.716296895258949],
            [-91.033761767317671, 33.673343910729102],
            [-91.076833295232944, 33.657880836298354],
            [-91.134261999119985, 33.676780149491492],
            [-91.15938705707056, 33.706847238662384],
            [-91.220405054950547, 33.693102283612831],
            [-91.227583642936423, 33.669048612276114],
            [-91.137851293112931, 33.625236568055676],
            [-91.130672705127054, 33.59688759826598],
            [-91.231172936929369, 33.561666150951503],
            [-91.18451211502115, 33.507545390443902],
            [-91.2347622309223, 33.438820615196143],
            [-91.170154939049382, 33.452565570245696],
            [-91.173744233042328, 33.496377614466141],
            [-91.123494117141163, 33.473183002820022],
            [-91.137851293112931, 33.426793779527792],
            [-91.198869290992903, 33.418203182621824],
            [-91.209637172971725, 33.401881048500478],
            [-91.173744233042328, 33.381263615926152],
            [-91.112726235162341, 33.39329045159451],
            [-91.076833295232944, 33.456001809008086],
            [-91.058886825268246, 33.428511898908987],
            [-91.141440587105862, 33.35119652675526],
            [-91.141440587105862, 33.296216706557061],
            [-91.098369059190588, 33.237800647596472],
            [-91.087601177211766, 33.27388115460154],
            [-91.051708237282369, 33.2850489305793],
            [-91.044529649296493, 33.265290557695572],
            [-91.091190471204712, 33.220619453784529],
            [-91.084011883218835, 33.156189976989765],
            [-91.094779765197643, 33.136431604106036],
            [-91.145029881098807, 33.129559126581263],
            [-91.18451211502115, 33.141585962249614],
            [-91.202458584985848, 33.108082634316339],
            [-91.119904823148232, 33.054820933499329],
            [-91.166565645056451, 33.004136411754111],
            [-91.952621029510297, 33.007572650516501],
            [-92.505372304423034, 33.011867948969481],
            [-93.068891461314607, 33.017881366803664],
            [-93.377570744707441, 33.017881366803664],
            [-94.041590133401328, 33.019599486184859],
            [-94.041590133401328, 33.541907778067774],
            [-94.045179427394274, 33.55135743466434],
            [-94.045179427394274, 33.55135743466434],
            [-94.102608131281315, 33.570256747857471],
            [-94.124143895238944, 33.552216494354937],
            [-94.142090365203643, 33.577988285072848],
            [-94.185161893118931, 33.592592299812992],
            [-94.224644127041273, 33.553075554045535],
            [-94.300019300892998, 33.576270165691653],
            [-94.31078718287182, 33.55135743466434],
            [-94.339501534815341, 33.566820509095088],
            [-94.357448004780039, 33.543625897448969],
            [-94.393340944709436, 33.55135743466434],
            [-94.38616235672356, 33.582283583525829],
            [-94.418466002660026, 33.577129225382251],
            [-94.457948236582368, 33.598605717647168],
            [-94.457948236582368, 33.644994940939405],
            [-94.486662588525874, 33.638122463414625],
            [-94.468716118561176, 34.189638784777841],
            [-94.461537530575299, 34.547866675756751],
            [-94.454358942589423, 34.693906823158223],
            [-94.450769648596477, 34.895785850448497],
            [-94.440001766617655, 35.120000429694294],
            [-94.432823178631779, 35.394040470994703],
            [-94.486662588525874, 35.726496571255709],
            [-94.501019764497642, 35.796939465884655],
            [-94.551269880398806, 36.101905656046554],
            [-94.572805644356436, 36.211006236752361],
            [-94.597930702307025, 36.387972533015322],
            [-94.619466466264655, 36.499650292792921]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '06', name: 'California' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-118.603128927089273, 33.478337360963607],
              [-118.545700223202232, 33.47404206251062],
              [-118.445199991399903, 33.428511898908987],
              [-118.369824817548164, 33.409612585715848],
              [-118.366235523555218, 33.388136093450932],
              [-118.308806819668192, 33.335733452324519],
              [-118.32675328963289, 33.298793885628854],
              [-118.373414111541109, 33.32027037789377],
              [-118.466735755357547, 33.326283795727953],
              [-118.488271519315191, 33.356350884898845],
              [-118.488271519315191, 33.419921302003011],
              [-118.560057399173985, 33.433666257052565],
              [-118.603128927089273, 33.478337360963607]
            ]
          ],
          [
            [
              [-118.606718221082204, 33.030767262162612],
              [-118.574414575145738, 33.034203500925003],
              [-118.495450107301068, 32.933693517125164],
              [-118.351878347583465, 32.822015757347565],
              [-118.387771287512862, 32.825451996109955],
              [-118.427253521435205, 32.806552682916823],
              [-118.488271519315191, 32.844351309303086],
              [-118.534932341223396, 32.906203607026065],
              [-118.585182457124574, 33.008431710207098],
              [-118.606718221082204, 33.030767262162612]
            ]
          ],
          [
            [
              [-119.058969264192626, 33.491223256322556],
              [-119.03025491224912, 33.497236674156738],
              [-119.026665618256175, 33.462015226842261],
              [-119.066147852178517, 33.468028644676444],
              [-119.058969264192626, 33.491223256322556]
            ]
          ],
          [
            [
              [-119.432255839458378, 34.028994622636219],
              [-119.403541487514872, 34.014390607896075],
              [-119.364059253592529, 34.026417443564426],
              [-119.364059253592529, 34.000645652846522],
              [-119.425077251472516, 33.997209414084132],
              [-119.457380897408967, 34.015249667586673],
              [-119.432255839458378, 34.028994622636219]
            ]
          ],
          [
            [
              [-119.579416893168926, 33.279035512745125],
              [-119.529166777267761, 33.2850489305793],
              [-119.457380897408967, 33.254981841408409],
              [-119.428666545465447, 33.228350990999907],
              [-119.475327367373666, 33.215465095640951],
              [-119.54711324723246, 33.233505349143485],
              [-119.579416893168926, 33.279035512745125]
            ]
          ],
          [
            [
              [-119.920399822498212, 34.077101965309645],
              [-119.855792530625308, 34.071088547475469],
              [-119.809131708717075, 34.052189234282338],
              [-119.75529229882298, 34.056484532735318],
              [-119.687095712957131, 34.019544966039653],
              [-119.618899127091254, 34.01610872727727],
              [-119.593774069140693, 34.049612055210545],
              [-119.521988189281885, 34.032430861398609],
              [-119.561470423204227, 33.995491294702944],
              [-119.661970655006542, 33.986041638106379],
              [-119.722988652886528, 33.95941078769787],
              [-119.873739000590007, 33.980028220272196],
              [-119.877328294582938, 34.032430861398609],
              [-119.920399822498212, 34.077101965309645]
            ]
          ],
          [
            [
              [-120.247025575855758, 34.00150471253712],
              [-120.168061108011074, 34.008377190061893],
              [-120.135757462074622, 34.026417443564426],
              [-120.089096640166389, 34.019544966039653],
              [-120.042435818258184, 34.035867100160999],
              [-120.042435818258184, 33.994632235012347],
              [-119.977828526385252, 33.983464459034586],
              [-119.974239232392307, 33.942229593885934],
              [-120.121400286102855, 33.895840370593703],
              [-120.178828989989896, 33.927625579145783],
              [-120.247025575855758, 34.00150471253712]
            ]
          ],
          [
            [
              [-120.448026039460387, 34.037585219542187],
              [-120.390597335573347, 34.052189234282338],
              [-120.369061571615703, 34.076242905619047],
              [-120.347525807658059, 34.046175816448155],
              [-120.300864985749854, 34.023840264492641],
              [-120.376240159601593, 34.018685906349056],
              [-120.448026039460387, 34.037585219542187]
            ]
          ],
          [
            [
              [-124.21319543805437, 41.998491372303718],
              [-123.656854869148674, 41.995055133541328],
              [-123.624551223212222, 42.000209491684913],
              [-123.348175585755854, 41.999350431994316],
              [-123.147175122151211, 42.009659148281479],
              [-123.046674890348896, 42.002786670756699],
              [-122.633906081160802, 42.004504790137894],
              [-122.501102203422022, 42.008800088590881],
              [-122.160119274092736, 42.007941028900284],
              [-121.998601044410435, 42.003645730447296],
              [-121.445849769497684, 41.996773252922523],
              [-121.036670254302535, 41.993337014160133],
              [-120.32957933769336, 41.993337014160133],
              [-119.999364290342896, 41.995055133541328],
              [-119.999364290342896, 41.99419607385073],
              [-119.999364290342896, 40.750277641866411],
              [-119.995774996349951, 40.721069612386117],
              [-119.995774996349951, 40.071620486294854],
              [-119.999364290342896, 39.734010027890271],
              [-120.002953584335842, 39.72284225191251],
              [-120.002953584335842, 39.330251973309728],
              [-120.006542878328773, 39.225446691056902],
              [-119.999364290342896, 38.999513992429918],
              [-119.906042646526458, 38.933366396253959],
              [-119.493273837338364, 38.649876698356977],
              [-119.084094322143216, 38.361232642316416],
              [-118.857968800587997, 38.204883778627782],
              [-118.502628695286944, 37.948883990829898],
              [-118.053966946169453, 37.625018487474868],
              [-117.83143071860718, 37.465233385023843],
              [-117.375590381503798, 37.126763866928663],
              [-116.252141361713612, 36.27715383292832],
              [-115.412246567365656, 35.625127527765272],
              [-115.160995987859877, 35.424107560165595],
              [-114.633369770897701, 35.001450192391921],
              [-114.636959064890647, 34.875168417874171],
              [-114.579530361003606, 34.826202015510148],
              [-114.554405303053031, 34.766926896858962],
              [-114.471851541215401, 34.712806136351361],
              [-114.378529897398963, 34.507490870298696],
              [-114.385708485384853, 34.457665408244075],
              [-114.339047663476634, 34.4516519904099],
              [-114.227779549695484, 34.365746021350205],
              [-114.177529433794334, 34.349423887228866],
              [-114.138047199871991, 34.303034663936636],
              [-114.134457905879046, 34.260940739097386],
              [-114.224190255702553, 34.20510185920859],
              [-114.227779549695484, 34.188779725087244],
              [-114.28879754757547, 34.17073947158471],
              [-114.324690487504867, 34.136377083960831],
              [-114.407244249342483, 34.111464352933524],
              [-114.435958601286003, 34.087410681596808],
              [-114.435958601286003, 34.028135562945621],
              [-114.536458833088318, 33.92848463883638],
              [-114.511333775137743, 33.911303445024444],
              [-114.504155187151866, 33.864055162041616],
              [-114.52569095110951, 33.858900803898031],
              [-114.529280245102441, 33.815088759677593],
              [-114.504155187151866, 33.760108939479387],
              [-114.49697659916599, 33.696538522375221],
              [-114.52569095110951, 33.686229806088058],
              [-114.540048127081263, 33.591733240122394],
              [-114.52569095110951, 33.552216494354937],
              [-114.557994597045962, 33.531599061780611],
              [-114.658494828848276, 33.413048824478238],
              [-114.726691414714139, 33.405317287262868],
              [-114.697977062770619, 33.361505243042423],
              [-114.730280708707085, 33.305666363153627],
              [-114.676441298812975, 33.27044491583915],
              [-114.683619886798866, 33.148458439774394],
              [-114.708744944749441, 33.090901440504396],
              [-114.658494828848276, 33.032485381543808],
              [-114.647726946869469, 33.047089396283958],
              [-114.60106612496125, 33.025612904019034],
              [-114.57594106701066, 33.036780679996795],
              [-114.518512363123619, 33.029908202472022],
              [-114.464672953229524, 32.913076084550838],
              [-114.468262247222469, 32.845210368993683],
              [-114.532869539095387, 32.791089608486082],
              [-114.52569095110951, 32.756727220862203],
              [-114.615423300933003, 32.734391668906682],
              [-114.701566356763564, 32.745559444884442],
              [-114.719512826728263, 32.71892859447594],
              [-116.212659127791269, 32.610687073460731],
              [-117.124339801998019, 32.534230760997602],
              [-117.135107683976827, 32.618418610676102],
              [-117.167411329913293, 32.671680311493112],
              [-117.196125681856813, 32.688861505305049],
              [-117.246375797757963, 32.66910313242132],
              [-117.257143679736799, 32.725801072000714],
              [-117.253554385743854, 32.786794310033095],
              [-117.28226873768736, 32.822015757347565],
              [-117.249965091750909, 32.874418398473978],
              [-117.28226873768736, 33.012727008660079],
              [-117.328929559595593, 33.121827589365886],
              [-117.361233205532045, 33.168216812658123],
              [-117.504804965249647, 33.334015332943324],
              [-117.594537315073154, 33.386417974069737],
              [-117.630430255002551, 33.430230018290175],
              [-117.684269664896647, 33.462015226842261],
              [-117.716573310833098, 33.460297107461066],
              [-117.784769896698961, 33.541907778067774],
              [-117.878091540515399, 33.592592299812992],
              [-117.928341656416563, 33.605478195171948],
              [-118.000127536275357, 33.654444597535971],
              [-118.08985988609885, 33.730041850308496],
              [-118.176002941929411, 33.763545178241777],
              [-118.183181529915288, 33.723169372783723],
              [-118.258556703767027, 33.703410999899994],
              [-118.323163995639959, 33.715437835568352],
              [-118.359056935569356, 33.736914327833276],
              [-118.412896345463452, 33.742068685976854],
              [-118.427253521435205, 33.774712954219538],
              [-118.394949875498753, 33.803920983699832],
              [-118.391360581505808, 33.840860550395497],
              [-118.441610697406958, 33.940511474504738],
              [-118.4810929313293, 33.995491294702944],
              [-118.542110929209286, 34.038444279232785],
              [-118.667736218962176, 34.039303338923382],
              [-118.746700686806861, 34.032430861398609],
              [-118.804129390693902, 34.00150471253712],
              [-118.854379506595052, 34.034148980779804],
              [-118.954879738397381, 34.04789393582935],
              [-119.087683616136161, 34.098578457574568],
              [-119.130755144051435, 34.100296576955763],
              [-119.216898199881996, 34.145826740557396],
              [-119.277916197761968, 34.266954156931561],
              [-119.313809137691379, 34.275544753837529],
              [-119.374827135571351, 34.321074917439169],
              [-119.389184311543119, 34.318497738367377],
              [-119.460970191401913, 34.37433661825618],
              [-119.536345365253652, 34.395813110521097],
              [-119.557881129211282, 34.41299430433304],
              [-119.672738536985364, 34.416430543095423],
              [-119.730167240872404, 34.395813110521097],
              [-119.794774532745322, 34.41728960278602],
              [-119.873739000590007, 34.408699005880052],
              [-120.006542878328773, 34.460242587315868],
              [-120.089096640166389, 34.460242587315868],
              [-120.142936050060484, 34.473128482674824],
              [-120.300864985749854, 34.467115064840641],
              [-120.473151097410977, 34.44821575164751],
              [-120.476740391403908, 34.474846602056012],
              [-120.523401213312127, 34.531544541635412],
              [-120.580829917199168, 34.557316332353317],
              [-120.623901445114441, 34.553880093590926],
              [-120.645437209072085, 34.581370003690033],
              [-120.598776387163866, 34.704215539445386],
              [-120.638258621086209, 34.755759120881201],
              [-120.609544269142674, 34.857987224062235],
              [-120.67056226702266, 34.904376447354466],
              [-120.638258621086209, 35.003168311773109],
              [-120.634669327093263, 35.123436668456677],
              [-120.699276618966181, 35.171544011130102],
              [-120.749526734867345, 35.177557428964285],
              [-120.760294616846153, 35.159517175461751],
              [-120.857205554655536, 35.206765458444579],
              [-120.896687788577879, 35.248000323593232],
              [-120.860794848648482, 35.360537143061421],
              [-120.867973436634358, 35.403490127591269],
              [-120.907455670556701, 35.449020291192909],
              [-121.00436660836607, 35.46104712686126],
              [-121.101277546175453, 35.54867121530215],
              [-121.16588483804837, 35.635436244052435],
              [-121.252027893878932, 35.656912736317359],
              [-121.313045891758918, 35.713610675896753],
              [-121.330992361723617, 35.783194510835102],
              [-121.388421065610657, 35.823570316293157],
              [-121.413546123561218, 35.855355524845244],
              [-121.463796239462397, 35.885422614016136],
              [-121.485332003420027, 35.970469523385226],
              [-121.503278473384725, 36.000536612556118],
              [-121.575064353243533, 36.025449343583432],
              [-121.628903763137629, 36.11479155140551],
              [-121.718636112961121, 36.19554316232162],
              [-121.837082814728149, 36.250522982519819],
              [-121.876565048650477, 36.289180668596678],
              [-121.905279400593997, 36.35790544384443],
              [-121.901690106601052, 36.393985950849505],
              [-121.941172340523394, 36.48075097959979],
              [-121.933993752537518, 36.559784471134705],
              [-121.97347598645986, 36.573529426184258],
              [-121.937583046530449, 36.637099843288425],
              [-121.872975754657546, 36.604455575045748],
              [-121.84067210872108, 36.630227365763652],
              [-121.815547050770505, 36.682630006890065],
              [-121.78683269882697, 36.80375742326423],
              [-121.862207872678709, 36.931757317163168],
              [-121.905279400593997, 36.968696883858833],
              [-121.941172340523394, 36.978146540455398],
              [-121.97347598645986, 36.954092869118682],
              [-122.106279864198626, 36.955810988499877],
              [-122.206780096000955, 37.014227047460466],
              [-122.28574456384564, 37.101851135901356],
              [-122.33599467974679, 37.117314210332097],
              [-122.343173267732666, 37.143945060740599],
              [-122.404191265612639, 37.195488642176414],
              [-122.418548441584406, 37.248750342993425],
              [-122.400601971619707, 37.359569043080427],
              [-122.443673499534981, 37.436025355543549],
              [-122.450852087520872, 37.480696459454592],
              [-122.493923615436145, 37.492723295122943],
              [-122.515459379393789, 37.52107226491264],
              [-122.51904867338672, 37.576052085110845],
              [-122.501102203422022, 37.599246696756964],
              [-122.493923615436145, 37.665394292932923],
              [-122.515459379393789, 37.780508291472906],
              [-122.465209263492625, 37.804561962809622],
              [-122.407780559605584, 37.811434440334395],
              [-122.38624479564794, 37.790817007760069],
              [-122.361119737697379, 37.715219754987544],
              [-122.393423383633831, 37.707488217772173],
              [-122.353941149711488, 37.615568830878303],
              [-122.361119737697379, 37.592374219232184],
              [-122.264208799887996, 37.572615846348455],
              [-122.167297862078613, 37.503891071100703],
              [-122.138583510135092, 37.508186369553691],
              [-122.088333394233928, 37.454065609046083],
              [-122.045261866318654, 37.456642788117875],
              [-122.066797630276298, 37.491005175741748],
              [-122.109869158191572, 37.507327309863093],
              [-122.145762098120969, 37.582065502945021],
              [-122.170887156071558, 37.675703009220086],
              [-122.246262329923297, 37.722092232512317],
              [-122.253440917909174, 37.761608978279774],
              [-122.332405385753844, 37.783944530235296],
              [-122.300101739817393, 37.847514947339469],
              [-122.325226797767968, 37.905931006300058],
              [-122.379066207662078, 37.905071946609461],
              [-122.429316323563228, 37.963488005570049],
              [-122.400601971619707, 37.955756468354679],
              [-122.368298325683242, 37.9780920203102],
              [-122.368298325683242, 38.012454407934072],
              [-122.300101739817393, 38.010736288552877],
              [-122.264208799887996, 38.044239616486159],
              [-122.300101739817393, 38.10523285451854],
              [-122.393423383633831, 38.143031480904803],
              [-122.44008420554205, 38.117259690186899],
              [-122.490334321443214, 38.112105332043313],
              [-122.483155733457323, 38.071729526585258],
              [-122.501102203422022, 38.032212780817801],
              [-122.447262793527926, 37.988400736597363],
              [-122.490334321443214, 37.966924244332439],
              [-122.504691497414967, 37.92912561794617],
              [-122.436494911549104, 37.881018275272744],
              [-122.461619969499679, 37.868991439604393],
              [-122.501102203422022, 37.8939041706317],
              [-122.475977145471447, 37.832910932599319],
              [-122.529816555365556, 37.819165977549773],
              [-122.562120201302008, 37.85181024579245],
              [-122.655441845118446, 37.904212886918863],
              [-122.702102667026665, 37.8939041706317],
              [-122.784656428864281, 37.951461169901691],
              [-122.820549368793678, 37.996991333503331],
              [-122.881567366673664, 38.025340303293028],
              [-122.938996070560705, 38.032212780817801],
              [-123.010781950419499, 38.003863811028104],
              [-122.960531834518335, 38.112964391733911],
              [-122.949763952539513, 38.154199256882563],
              [-122.992835480454801, 38.233232748417478],
              [-122.967710422504211, 38.250413942229414],
              [-123.025139126391252, 38.310548120571198],
              [-123.064621360313595, 38.30195752366523],
              [-123.06821065430654, 38.359514522935221],
              [-123.129228652186512, 38.450574850138494],
              [-123.20101453204532, 38.494386894358939],
              [-123.251264647946471, 38.510709028480278],
              [-123.330229115791155, 38.565688848678484],
              [-123.463032993529936, 38.716883354223533],
              [-123.513283109431086, 38.741796085250847],
              [-123.541997461374606, 38.777017532565317],
              [-123.653265575155743, 38.854332904719044],
              [-123.739408630986304, 38.953983828828285],
              [-123.68915851508514, 39.020990484694842],
              [-123.692747809078085, 39.057070991699909],
              [-123.74299792497925, 39.165312512715118],
              [-123.80042662886629, 39.270976854658542],
              [-123.825551686816851, 39.361178122171218],
              [-123.814783804838044, 39.446225031540308],
              [-123.768122982929825, 39.559620910699103],
              [-123.786069452894523, 39.604292014610138],
              [-123.786069452894523, 39.660130894498941],
              [-123.836319568795687, 39.739164386033856],
              [-123.850676744767441, 39.831942832618317],
              [-123.908105448654482, 39.862868981479807],
              [-123.929641212612125, 39.910117264462635],
              [-124.055266502365015, 40.024372203312026],
              [-124.080391560315604, 40.029526561455604],
              [-124.080391560315604, 40.066466128151276],
              [-124.10910591225911, 40.103405694846941],
              [-124.188070380103795, 40.130895604946041],
              [-124.363945785757849, 40.260613618226174],
              [-124.349588609786082, 40.314734378733782],
              [-124.363945785757849, 40.374868557075565],
              [-124.410606607666068, 40.438438974179732],
              [-124.378302961729617, 40.522626823858232],
              [-124.328052845828452, 40.616264330133298],
              [-124.177302498124973, 40.843915148141477],
              [-124.137820264202631, 40.925525818748177],
              [-124.112695206252056, 41.027753921929211],
              [-124.155766734167344, 41.059539130481298],
              [-124.159356028160275, 41.1428679204692],
              [-124.144998852188507, 41.144586039850388],
              [-124.105516618266165, 41.229632949219486],
              [-124.062445090350906, 41.439243513725131],
              [-124.080391560315604, 41.54748503474034],
              [-124.1342309702097, 41.657444675136745],
              [-124.162945322153206, 41.73991440543405],
              [-124.195248968089672, 41.73647816667166],
              [-124.256266965969644, 41.78286738996389],
              [-124.220374026040247, 41.846437807068064],
              [-124.202427556075548, 41.94093437303372],
              [-124.21319543805437, 41.998491372303718]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '10', name: 'Delaware' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.790030179301795, 39.721983192221913],
              [-75.718244299442986, 39.79242608685086],
              [-75.664404889548891, 39.821634116331154],
              [-75.578261833718329, 39.838815310143097],
              [-75.499297365873659, 39.833660951999512],
              [-75.416743604036043, 39.801875743447425],
              [-75.459815131951316, 39.765795236442358],
              [-75.477761601916015, 39.713392595315945],
              [-75.510065247852481, 39.685902685216845],
              [-75.53160101181011, 39.691916103051021],
              [-75.610565479654795, 39.622332268112672],
              [-75.603386891668919, 39.588828940179397],
              [-75.563904657746576, 39.562198089770888],
              [-75.560315363753631, 39.520104164931645],
              [-75.589029715697151, 39.496050493594929],
              [-75.589029715697151, 39.460829046280459],
              [-75.538779599796001, 39.416157942369416],
              [-75.513654541845412, 39.366332480314796],
              [-75.438279367993673, 39.313070779497785],
              [-75.402386428064275, 39.254654720537197],
              [-75.391618546085454, 39.204829258482576],
              [-75.409565016050152, 39.174762169311684],
              [-75.402386428064275, 39.066520648296475],
              [-75.344957724177235, 39.025285783147822],
              [-75.31983266622666, 38.988346216452157],
              [-75.312654078240783, 38.924775799347984],
              [-75.190618082480825, 38.807084621736209],
              [-75.133189378593784, 38.782171890708902],
              [-75.097296438664387, 38.802789323283228],
              [-75.082939262692619, 38.771863174421739],
              [-75.064992792727921, 38.661044474334737],
              [-75.050635616756168, 38.451433909829092],
              [-75.427511486014865, 38.452292969519689],
              [-75.693119241492411, 38.46002450673506],
              [-75.700297829478288, 38.521876804458039],
              [-75.790030179301795, 39.648104058830583],
              [-75.790030179301795, 39.721983192221913]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '11', name: 'District of Columbia' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.118068956689569, 38.934225455944556],
            [-77.04269378283783, 38.994359634286333],
            [-76.90988990509905, 38.892990590795904],
            [-77.039104488844885, 38.791621547305468],
            [-77.039104488844885, 38.87151409853098],
            [-77.118068956689569, 38.934225455944556]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '15', name: 'Hawaii' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-156.057411743417418, 19.742831968009675],
              [-156.050233155431556, 19.780630594395937],
              [-155.982036569565679, 19.845919130881306],
              [-155.93896504165042, 19.851932548715482],
              [-155.892304219742186, 19.931825099940994],
              [-155.834875515855146, 19.976496203852037],
              [-155.824107633876338, 20.026321665906657],
              [-155.885125631756296, 20.107073276822767],
              [-155.899482807728077, 20.145730962899627],
              [-155.903072101720994, 20.235932230412303],
              [-155.874357749777488, 20.267717438964382],
              [-155.809750457904556, 20.259985901749012],
              [-155.745143166031653, 20.232495991649913],
              [-155.734375284052817, 20.205006081550813],
              [-155.65541081620816, 20.167207455164551],
              [-155.601571406314065, 20.126831649706496],
              [-155.558499878398777, 20.131986007850074],
              [-155.468767528575285, 20.104496097750975],
              [-155.328785062850613, 20.038348501575015],
              [-155.282124240942409, 20.02202636745367],
              [-155.203159773097724, 19.969623726327256],
              [-155.145731069210683, 19.920657323963233],
              [-155.084713071330697, 19.855368787477872],
              [-155.095480953309533, 19.814992982019817],
              [-155.091891659316587, 19.737677609866097],
              [-155.063177307373053, 19.729087012960129],
              [-155.005748603486012, 19.739395729247292],
              [-154.980623545535451, 19.690429326883262],
              [-154.984212839528368, 19.641462924519239],
              [-154.905248371683712, 19.571020029890292],
              [-154.826283903839027, 19.537516701957017],
              [-154.808337433874328, 19.519476448454483],
              [-154.822694609846081, 19.481677822068214],
              [-154.869355431754315, 19.437865777847776],
              [-154.930373429634272, 19.397489972389721],
              [-154.980623545535451, 19.349382629716295],
              [-155.063177307373053, 19.316738361473611],
              [-155.134963187231847, 19.276362556015556],
              [-155.206749067090669, 19.260899481584815],
              [-155.26417777097771, 19.273785376943763],
              [-155.296481416914162, 19.266053839728393],
              [-155.361088708787065, 19.209355900148999],
              [-155.418517412674106, 19.187879407884076],
              [-155.465178234582339, 19.146644542735423],
              [-155.515428350483489, 19.13289958768587],
              [-155.558499878398777, 19.082215065940659],
              [-155.554910584405832, 19.053866096150955],
              [-155.583624936349338, 19.022080887598875],
              [-155.601571406314065, 18.971396365853657],
              [-155.673357286172859, 18.917275605346049],
              [-155.727196696066954, 18.969678246472462],
              [-155.806161163911639, 19.014349350383497],
              [-155.885125631756296, 19.039262081410811],
              [-155.921018571685693, 19.120872752017519],
              [-155.903072101720994, 19.217946497054967],
              [-155.888714925749241, 19.3476645103351],
              [-155.924607865678638, 19.438724837538373],
              [-155.921018571685693, 19.478241583305831],
              [-155.953322217622173, 19.488550299592994],
              [-155.971268687586871, 19.58648310432104],
              [-155.996393745537432, 19.643181043900434],
              [-156.028697391473912, 19.650053521425214],
              [-156.057411743417418, 19.742831968009675]
            ]
          ],
          [
            [
              [-156.699895368153676, 20.920602803818035],
              [-156.681948898188978, 20.979877922469221],
              [-156.642466664266635, 21.027985265142647],
              [-156.592216548365457, 21.033139623286232],
              [-156.545555726457252, 21.004790653496528],
              [-156.509662786527855, 20.937783997629971],
              [-156.473769846598458, 20.894831013100124],
              [-156.387626790767911, 20.919743744127437],
              [-156.323019498894979, 20.949810833298329],
              [-156.229697855078541, 20.931770579795796],
              [-156.229697855078541, 20.918025624746242],
              [-156.168679857198555, 20.874213580525804],
              [-156.132786917269158, 20.861327685166849],
              [-156.114840447304459, 20.826965297542969],
              [-156.003572333523323, 20.795180088990882],
              [-155.985625863558624, 20.767690178891783],
              [-155.985625863558624, 20.723019074980748],
              [-156.014340215502159, 20.686079508285076],
              [-156.061001037410364, 20.651717120661203],
              [-156.082536801368008, 20.654294299732996],
              [-156.143554799247966, 20.623368150871507],
              [-156.193804915149144, 20.631958747777475],
              [-156.301483734937335, 20.586428584175835],
              [-156.376858908789075, 20.578697046960464],
              [-156.437876906669061, 20.601032598915985],
              [-156.46300196461965, 20.781435133941336],
              [-156.488127022570211, 20.798616327753273],
              [-156.538377138471361, 20.777998895178946],
              [-156.631698782287799, 20.820951879708794],
              [-156.678359604196032, 20.870777341763414],
              [-156.699895368153676, 20.920602803818035]
            ]
          ],
          [
            [
              [-156.703484662146622, 20.532307823668233],
              [-156.678359604196032, 20.55722055469554],
              [-156.577859372393704, 20.606186957059563],
              [-156.541966432464307, 20.580415166341659],
              [-156.55632360843606, 20.542616539955397],
              [-156.538377138471361, 20.528012525215246],
              [-156.585037960379594, 20.511690391093907],
              [-156.602984430344293, 20.524576286452863],
              [-156.667591722217196, 20.504817913569134],
              [-156.703484662146622, 20.532307823668233]
            ]
          ],
          [
            [
              [-157.062414061440592, 20.904280669696696],
              [-157.037289003490031, 20.927475281342808],
              [-156.990628181581798, 20.931770579795796],
              [-156.89730653776536, 20.91544844567445],
              [-156.836288539885402, 20.863904864238641],
              [-156.807574187941867, 20.820092820018196],
              [-156.839877833878319, 20.7642539401294],
              [-156.908074419744196, 20.739341209102086],
              [-156.969092417624154, 20.735045910649106],
              [-156.994217475574743, 20.786589492084914],
              [-157.004985357553551, 20.849300849498491],
              [-157.055235473454729, 20.87679075959759],
              [-157.062414061440592, 20.904280669696696]
            ]
          ],
          [
            [
              [-157.310075346953454, 21.101864398533984],
              [-157.28853958299581, 21.146535502445019],
              [-157.256235937059358, 21.171448233472333],
              [-157.263414525045249, 21.221273695526953],
              [-157.202396527165263, 21.219555576145758],
              [-157.191628645186427, 21.2075287404774],
              [-156.997806769567688, 21.182616009450093],
              [-156.961913829638291, 21.211824038930388],
              [-156.947556653666538, 21.175743531925313],
              [-156.868592185821853, 21.164575755947553],
              [-156.77168124801247, 21.180038830378301],
              [-156.710663250132484, 21.158562338113377],
              [-156.739377602076019, 21.111314055130549],
              [-156.803984893948922, 21.067502010910104],
              [-156.875770773807716, 21.04946175740757],
              [-157.001396063560634, 21.07695166750667],
              [-157.094717707377072, 21.103582517915179],
              [-157.148557117271167, 21.09155568224682],
              [-157.252646643066413, 21.087260383793833],
              [-157.310075346953454, 21.101864398533984]
            ]
          ],
          [
            [
              [-158.27918472504723, 21.578642526815266],
              [-158.124845083350806, 21.586374064030636],
              [-158.078184261442601, 21.628467988869886],
              [-158.063827085470848, 21.658535078040778],
              [-158.017166263562615, 21.69976994318943],
              [-157.966916147661465, 21.712655838548379],
              [-157.945380383703821, 21.689461226902267],
              [-157.923844619746177, 21.629327048560484],
              [-157.852058739887383, 21.557166034550342],
              [-157.83770156391563, 21.512494930639299],
              [-157.852058739887383, 21.499609035280351],
              [-157.841290857908575, 21.459233229822296],
              [-157.776683566035643, 21.411984946839468],
              [-157.765915684056836, 21.460951349203491],
              [-157.722844156141548, 21.459233229822296],
              [-157.737201332113301, 21.40425340962409],
              [-157.708486980169795, 21.383635977049764],
              [-157.71207627416274, 21.358723246022457],
              [-157.651058276282754, 21.298589067680673],
              [-157.694129804198042, 21.266803859128586],
              [-157.733612038120384, 21.282266933559335],
              [-157.808987211972124, 21.257354202532021],
              [-157.830522975929739, 21.279689754487542],
              [-157.891540973809725, 21.306320604896044],
              [-157.981273323633218, 21.315770261492609],
              [-158.088952143421409, 21.298589067680673],
              [-158.114077201371998, 21.302025306443063],
              [-158.139202259322587, 21.374186320453198],
              [-158.17868449324493, 21.403394349933492],
              [-158.182273787237875, 21.430025200342001],
              [-158.232523903139025, 21.487582199611992],
              [-158.232523903139025, 21.539984840738406],
              [-158.27918472504723, 21.578642526815266]
            ]
          ],
          [
            [
              [-159.786688202082019, 22.030507924069234],
              [-159.783098908089073, 22.064870311693113],
              [-159.743616674166731, 22.097514579935797],
              [-159.729259498194978, 22.13960850477504],
              [-159.61081279642795, 22.201460802498019],
              [-159.582098444484444, 22.22379635445354],
              [-159.499544682646814, 22.208333280022799],
              [-159.488776800668006, 22.229809772287716],
              [-159.431348096780965, 22.22036011569115],
              [-159.402633744837431, 22.232386951359508],
              [-159.348794334943335, 22.215205757547572],
              [-159.312901395013938, 22.183420548995485],
              [-159.29495492504924, 22.144762862918626],
              [-159.29495492504924, 22.105246117151168],
              [-159.334437158971582, 22.05112535664356],
              [-159.330847864978637, 21.960065029440287],
              [-159.34520504095039, 21.936011358103578],
              [-159.445705272752718, 21.869004702237021],
              [-159.474419624696225, 21.881890597595969],
              [-159.603634208442088, 21.892199313883133],
              [-159.668241500314991, 21.953192551915514],
              [-159.754384556145538, 21.978105282942828],
              [-159.786688202082019, 22.030507924069234]
            ]
          ],
          [
            [
              [-160.246117833178317, 21.849246329353292],
              [-160.231760657206564, 21.886185896048957],
              [-160.188689129291276, 21.923984522435219],
              [-160.120492543425428, 21.962642208512079],
              [-160.113313955439537, 21.995286476754764],
              [-160.073831721517195, 22.003018013970134],
              [-160.066653133531332, 21.976387163561633],
              [-160.084599603496031, 21.92742076119761],
              [-160.07742101551014, 21.89649461233612],
              [-160.149206895368934, 21.872440940999404],
              [-160.206635599255975, 21.789112151011508],
              [-160.249707127171263, 21.814883941729413],
              [-160.246117833178317, 21.849246329353292]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '19', name: 'Iowa' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.453595696656961, 43.500127711467108],
            [-95.73932619206191, 43.500127711467108],
            [-94.615877172271723, 43.500127711467108],
            [-93.969804253542534, 43.499268651776511],
            [-93.169391693116921, 43.499268651776511],
            [-92.753033589935896, 43.500127711467108],
            [-91.949031735517352, 43.500127711467108],
            [-91.216815760957601, 43.500127711467108],
            [-91.2347622309223, 43.455456607556066],
            [-91.198869290992903, 43.412503623026225],
            [-91.202458584985848, 43.348933205922052],
            [-91.105547647176465, 43.313711758607582],
            [-91.058886825268246, 43.248423222122213],
            [-91.123494117141163, 43.196879640686397],
            [-91.145029881098807, 43.152208536775362],
            [-91.177333527035273, 43.131591104201036],
            [-91.173744233042328, 43.038812657616567],
            [-91.145029881098807, 42.963215404844043],
            [-91.145029881098807, 42.904799345883454],
            [-91.101958353183534, 42.88332285361853],
            [-91.055297531275315, 42.738141765907649],
            [-90.936850829508288, 42.68316194570945],
            [-90.76815401184011, 42.651376737157364],
            [-90.707136013960138, 42.634195543345427],
            [-90.642528722087221, 42.540558037070362],
            [-90.642528722087221, 42.508772828518275],
            [-90.642528722087221, 42.468397023060227],
            [-90.563564254242536, 42.438329933889335],
            [-90.556385666256659, 42.415994381933814],
            [-90.477421198411975, 42.384209173381727],
            [-90.419992494524948, 42.330088412874119],
            [-90.430760376503756, 42.27854483143831],
            [-90.37692096660966, 42.214974414334137],
            [-90.208224148941483, 42.15226305692056],
            [-90.161563327033264, 42.11704160960609],
            [-90.165152621026209, 42.040585297142961],
            [-90.14002756307562, 42.008800088590881],
            [-90.165152621026209, 41.956397447464468],
            [-90.154384739047387, 41.906571985409848],
            [-90.183099090990908, 41.844719687686869],
            [-90.179509796997962, 41.809498240372399],
            [-90.280010028800291, 41.767404315533149],
            [-90.312313674736743, 41.742491584505835],
            [-90.312313674736743, 41.6978204805948],
            [-90.344617320673208, 41.647135958849582],
            [-90.341028026680263, 41.590438019270188],
            [-90.398456730567304, 41.572397765767654],
            [-90.463064022440221, 41.523431363403624],
            [-90.556385666256659, 41.524290423094222],
            [-90.592278606186056, 41.513122647116461],
            [-90.649707310073097, 41.465015304443035],
            [-90.735850365903659, 41.450411289702892],
            [-90.847118479684795, 41.45556564784647],
            [-90.929672241522411, 41.421203260222597],
            [-90.97633306343063, 41.434089155581546],
            [-91.044529649296493, 41.416048902079012],
            [-91.066065413254123, 41.368800619096184],
            [-91.073244001240013, 41.305230201992011],
            [-91.112726235162341, 41.239082605816051],
            [-91.040940355303547, 41.166062532115312],
            [-90.994279533395328, 41.160908173971734],
            [-90.94761871148711, 41.096478697176963],
            [-90.944029417494164, 41.01229084749847],
            [-90.965565181451808, 40.921230520295197],
            [-91.00504741537415, 40.904908386173858],
            [-91.055297531275315, 40.848210446594457],
            [-91.091190471204712, 40.825015834948346],
            [-91.094779765197643, 40.767458835678347],
            [-91.116315529155287, 40.725364910839104],
            [-91.119904823148232, 40.672962269712691],
            [-91.18451211502115, 40.637740822398214],
            [-91.249119406894067, 40.638599882088812],
            [-91.360387520675204, 40.601660315393147],
            [-91.407048342583423, 40.542385196741961],
            [-91.36756610866108, 40.512318107571069],
            [-91.381923284632848, 40.435002735417349],
            [-91.374744696646957, 40.392049750887502],
            [-91.417816224562245, 40.378304795837948],
            [-91.486012810428093, 40.384318213672131],
            [-91.525495044350436, 40.410949064080633],
            [-91.529084338343381, 40.459056406754058],
            [-91.564977278272778, 40.460774526135253],
            [-91.622405982159819, 40.509740928499276],
            [-91.618816688166874, 40.540667077360766],
            [-91.683423980039791, 40.552693913029124],
            [-91.687013274032736, 40.581042882818821],
            [-91.73008480194801, 40.613687151061505],
            [-92.092603495234954, 40.601660315393147],
            [-92.638176182161814, 40.590492539415386],
            [-93.345267098770989, 40.580183823128223],
            [-93.567803326333262, 40.580183823128223],
            [-94.034411545415452, 40.57331134560345],
            [-94.594341408314079, 40.570734166531658],
            [-95.165039153191529, 40.577606644056431],
            [-95.764451250012499, 40.585338181271808],
            [-95.750094074040732, 40.609391852608518],
            [-95.785987013970129, 40.657499195281943],
            [-95.832647835878348, 40.671244150331496],
            [-95.886487245772457, 40.721069612386117],
            [-95.879308657786567, 40.752854820938204],
            [-95.832647835878348, 40.782921910109096],
            [-95.847005011850115, 40.85422386442864],
            [-95.811112071920718, 40.898035908649078],
            [-95.836237129871293, 40.921230520295197],
            [-95.829058541885416, 40.97792845987459],
            [-95.861362187821868, 40.995109653686534],
            [-95.857772893828937, 41.031190160691601],
            [-95.882897951779512, 41.056961951409505],
            [-95.864951481814813, 41.080156563055624],
            [-95.882897951779512, 41.160049114281136],
            [-95.839826423864238, 41.17465312902128],
            [-95.925969479694785, 41.195270561595606],
            [-95.911612303723032, 41.227055770147693],
            [-95.929558773687731, 41.281176530655301],
            [-95.882897951779512, 41.316397977969771],
            [-95.954683831638306, 41.33959258961589],
            [-95.929558773687731, 41.364505320643197],
            [-95.933148067680676, 41.464156244752438],
            [-96.00493394753947, 41.472746841658413],
            [-95.997755359553594, 41.538894437834372],
            [-96.030059005490045, 41.53975349752497],
            [-96.037237593475936, 41.507968288972883],
            [-96.091077003370032, 41.532021960309599],
            [-96.08030912139121, 41.580129302983025],
            [-96.119791355313552, 41.6136326309163],
            [-96.094666297362963, 41.647135958849582],
            [-96.123380649306483, 41.682357406164051],
            [-96.083898415384155, 41.6978204805948],
            [-96.105434179341785, 41.74420970388703],
            [-96.076719827398264, 41.761390897698973],
            [-96.065951945419457, 41.800907643466431],
            [-96.10902347333473, 41.82066601635016],
            [-96.112612767327676, 41.853310284592837],
            [-96.162862883228826, 41.90571292571925],
            [-96.141327119271182, 41.915162582315816],
            [-96.130559237292374, 41.971860521895209],
            [-96.187987941179401, 41.977014880038794],
            [-96.18439864718647, 42.002786670756699],
            [-96.238238057080565, 42.012236327353264],
            [-96.220291587115867, 42.025981282402817],
            [-96.274130997009962, 42.047457774667741],
            [-96.270541703017031, 42.118759728987285],
            [-96.349506170861702, 42.166867071660711],
            [-96.360274052840523, 42.208101936809364],
            [-96.324381112911126, 42.229578429074287],
            [-96.374631228812277, 42.318061577205768],
            [-96.414113462734619, 42.342974308233075],
            [-96.414113462734619, 42.408262844718443],
            [-96.381809816798167, 42.446061471104706],
            [-96.396166992769921, 42.483860097490968],
            [-96.446417108671085, 42.490732575015741],
            [-96.493077930579304, 42.51736342542425],
            [-96.478720754607536, 42.55602111150111],
            [-96.514613694536948, 42.630759304583037],
            [-96.57563169241692, 42.682302886018853],
            [-96.629471102311015, 42.705497497664972],
            [-96.633060396303961, 42.770786034150333],
            [-96.593578162381618, 42.793121586105855],
            [-96.582810280402796, 42.83779269001689],
            [-96.543328046480454, 42.851537645066443],
            [-96.543328046480454, 42.920262420314195],
            [-96.50025651856518, 42.959779166081653],
            [-96.521792282522824, 42.977819419584186],
            [-96.493077930579304, 43.001873090920903],
            [-96.511024400544002, 43.049980433594328],
            [-96.460774284642838, 43.063725388643881],
            [-96.435649226692263, 43.120423328223275],
            [-96.467952872628729, 43.150490417394167],
            [-96.475131460614605, 43.221792371713711],
            [-96.561274516445167, 43.224369550785504],
            [-96.550506634466345, 43.251000401194005],
            [-96.579220986409865, 43.295671505105041],
            [-96.528970870508701, 43.299966803558029],
            [-96.525381576515755, 43.394463369523685],
            [-96.60434604436044, 43.44944318972189],
            [-96.579220986409865, 43.481228398273977],
            [-96.597167456374564, 43.500127711467108],
            [-96.453595696656961, 43.500127711467108]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '21', name: 'Kentucky' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.131435951059501, 36.982441838908386],
              [-89.181686066960665, 37.027112942819421],
              [-89.167328890988912, 37.074361225802249],
              [-89.109900187101871, 37.119032329713292],
              [-89.077596541165406, 37.174871209602088],
              [-89.030935719257187, 37.210951716607163],
              [-88.984274897348968, 37.228991970109696],
              [-88.916078311483105, 37.224696671656709],
              [-88.804810197701968, 37.188616164651641],
              [-88.754560081800818, 37.155112836718359],
              [-88.628934792047914, 37.120750449094487],
              [-88.560738206182052, 37.076079345183445],
              [-88.514077384273833, 37.064911569205684],
              [-88.460237974379737, 37.073502166111652],
              [-88.42434503445034, 37.149958478574781],
              [-88.449470092400915, 37.206656418154175],
              [-88.510488090280901, 37.262495298042971],
              [-88.517666678266778, 37.283971790307895],
              [-88.485363032330326, 37.339810670196698],
              [-88.478184444344436, 37.387058953179526],
              [-88.413577152471518, 37.424857579565789],
              [-88.363327036570368, 37.402522027610267],
              [-88.298719744697436, 37.44719313152131],
              [-88.255648216782163, 37.456642788117875],
              [-88.083362105121054, 37.472964922239214],
              [-88.06182634116341, 37.515917906769062],
              [-88.133612221022204, 37.57433396572965],
              [-88.158737278972779, 37.664535233242326],
              [-88.119255045050451, 37.712642575915751],
              [-88.058237047170465, 37.742709665086643],
              [-88.029522695226945, 37.799407604666037],
              [-87.946968933389329, 37.771917694566937],
              [-87.903897405474055, 37.812293500024992],
              [-87.939790345403452, 37.89046793186931],
              [-87.903897405474055, 37.924830319493189],
              [-87.871593759537589, 37.921394080730799],
              [-87.832111525615247, 37.876722976819764],
              [-87.681361177911782, 37.903353827228266],
              [-87.663414707947069, 37.877582036510361],
              [-87.674182589925891, 37.829474693836929],
              [-87.634700356003563, 37.826897514765143],
              [-87.588039534095344, 37.861259902389016],
              [-87.627521768017672, 37.916239722587221],
              [-87.602396710067097, 37.972937662166615],
              [-87.573682358123577, 37.967783304023037],
              [-87.552146594165933, 37.925689379183787],
              [-87.501896478264783, 37.909367245062441],
              [-87.451646362363618, 37.941152453614528],
              [-87.379860482504824, 37.93599809547095],
              [-87.30089601466014, 37.898199469084688],
              [-87.221931546815469, 37.849233066720657],
              [-87.157324254942552, 37.838065290742904],
              [-87.128609902999031, 37.784803589925893],
              [-87.089127669076689, 37.787380768997686],
              [-87.064002611126114, 37.810575380643797],
              [-87.046056141161415, 37.8939041706317],
              [-87.010163201232004, 37.919675961349604],
              [-86.906073675436744, 37.942870572995723],
              [-86.855823559535594, 37.987541676906766],
              [-86.794805561655608, 37.989259796287953],
              [-86.751734033740334, 37.912803483824831],
              [-86.715841093810937, 37.8939041706317],
              [-86.67994815388154, 37.915380662896624],
              [-86.644055213952143, 37.906790065990656],
              [-86.662001683916841, 37.862978021770211],
              [-86.636876625966252, 37.842360589195884],
              [-86.597394392043924, 37.867273320223198],
              [-86.590215804058033, 37.921394080730799],
              [-86.507662042220417, 37.92912561794617],
              [-86.525608512185116, 37.961769886188854],
              [-86.518429924199239, 38.042521497104964],
              [-86.453822632326322, 38.050253034320335],
              [-86.432286868368678, 38.086333541325409],
              [-86.464590514305144, 38.100937556065553],
              [-86.432286868368678, 38.125850287092867],
              [-86.403572516425157, 38.106091914209138],
              [-86.374858164481637, 38.131004645236445],
              [-86.328197342573418, 38.13272276461764],
              [-86.324608048580487, 38.154199256882563],
              [-86.371268870488706, 38.164507973169727],
              [-86.374858164481637, 38.193716002650021],
              [-86.346143812538116, 38.195434122031216],
              [-86.270768638686377, 38.137877122761218],
              [-86.267179344693446, 38.057125511845115],
              [-86.173857700877008, 38.00987722886228],
              [-86.094893233032323, 38.009018169171682],
              [-86.048232411124104, 37.959192707117062],
              [-86.033875235152351, 37.990118855978551],
              [-85.951321473314735, 38.005581930409299],
              [-85.922607121371215, 38.026199362983625],
              [-85.904660651406516, 38.086333541325409],
              [-85.908249945399447, 38.161071734407336],
              [-85.850821241512406, 38.222924032130315],
              [-85.829285477554777, 38.277044792637923],
              [-85.79339253762538, 38.288212568615677],
              [-85.743142421724215, 38.267595136041351],
              [-85.674945835858352, 38.301098463974633],
              [-85.60674924999249, 38.439407074160734],
              [-85.499070430204299, 38.468615103641028],
              [-85.473945372253723, 38.50641373002729],
              [-85.416516668366683, 38.54077611765117],
              [-85.438052432324326, 38.601769355683551],
              [-85.45240960829608, 38.709151817008163],
              [-85.409338080380806, 38.73750078679786],
              [-85.351909376493765, 38.731487368963684],
              [-85.258587732677327, 38.73750078679786],
              [-85.22269479274793, 38.700561220102195],
              [-85.154498206882067, 38.691970623196227],
              [-85.104248090980903, 38.725473951129501],
              [-84.978622801228013, 38.77959471163711],
              [-84.946319155291548, 38.775299413184122],
              [-84.888890451404507, 38.795057786067851],
              [-84.809925983559836, 38.792480606996065],
              [-84.831461747517466, 38.830279233382328],
              [-84.784800925609247, 38.869795979149785],
              [-84.799158101581014, 38.891272471414709],
              [-84.860176099460986, 38.897285889248884],
              [-84.870943981439808, 38.929071097800971],
              [-84.831461747517466, 38.961715366043656],
              [-84.838640335503356, 38.988346216452157],
              [-84.896069039390397, 39.049339454484539],
              [-84.888890451404507, 39.066520648296475],
              [-84.820693865538658, 39.105178334373335],
              [-84.756086573665741, 39.146413199521987],
              [-84.730961515715151, 39.144695080140792],
              [-84.677122105821056, 39.098305856848562],
              [-84.601746931969316, 39.074252185511845],
              [-84.551496816068152, 39.099164916539159],
              [-84.501246700167002, 39.096587737467367],
              [-84.472532348223481, 39.121500468494681],
              [-84.443817996279961, 39.1146279909699],
              [-84.429460820308194, 39.055352872318714],
              [-84.325371294512948, 39.027862962219615],
              [-84.289478354583537, 38.955701948209473],
              [-84.23204965069651, 38.884399993889936],
              [-84.23204965069651, 38.827702054310535],
              [-84.214103180731797, 38.805366502355014],
              [-84.070531421014209, 38.770145055040544],
              [-83.952084719247196, 38.786467189161883],
              [-83.847995193451936, 38.746950443394425],
              [-83.837227311473114, 38.717742413914131],
              [-83.783387901579019, 38.69540686195861],
              [-83.765441431614306, 38.652453877428769],
              [-83.664941199811992, 38.627541146401455],
              [-83.614691083910841, 38.684239085980856],
              [-83.521369440094404, 38.703138399173987],
              [-83.467530030200294, 38.675648489074881],
              [-83.367029798397979, 38.658467295262945],
              [-83.327547564475637, 38.637849862688618],
              [-83.309601094510938, 38.600910295992954],
              [-83.262940272602719, 38.62152772856728],
              [-83.155261452814528, 38.620668668876682],
              [-83.119368512885131, 38.666198832478315],
              [-83.054761221012214, 38.69540686195861],
              [-83.022457575075748, 38.728910189891891],
              [-82.968618165181653, 38.728910189891891],
              [-82.893242991329913, 38.756400099990991],
              [-82.868117933379324, 38.728051130201294],
              [-82.878885815358146, 38.690252503815032],
              [-82.846582169421694, 38.594896878158778],
              [-82.799921347513475, 38.563111669606691],
              [-82.724546173661736, 38.557957311463106],
              [-82.649170999809996, 38.490091595905952],
              [-82.602510177901777, 38.46002450673506],
              [-82.595331589915901, 38.422225880348797],
              [-82.598920883908832, 38.344910508195078],
              [-82.570206531965312, 38.313984359333588],
              [-82.573795825958257, 38.264158897278968],
              [-82.613278059880599, 38.235809927489271],
              [-82.598920883908832, 38.218628733677328],
              [-82.609688765887654, 38.1713804506945],
              [-82.638403117831174, 38.1713804506945],
              [-82.638403117831174, 38.138736182451815],
              [-82.584563707937079, 38.106950973899735],
              [-82.548670768007682, 38.068293287822868],
              [-82.516367122071216, 37.999568512575117],
              [-82.462527712177121, 37.980669199381985],
              [-82.502009946099463, 37.933420916399157],
              [-82.473295594155942, 37.899917588465875],
              [-82.419456184261833, 37.883595454344537],
              [-82.423045478254778, 37.854387424864242],
              [-82.376384656346559, 37.801984783737829],
              [-82.340491716417162, 37.785662649616491],
              [-82.293830894508943, 37.670548651076501],
              [-82.239991484614848, 37.661098994479936],
              [-82.214866426664258, 37.625018487474868],
              [-82.182562780727807, 37.626736606856063],
              [-82.157437722777232, 37.592374219232184],
              [-82.128723370833711, 37.591515159541586],
              [-82.117955488854889, 37.559729950989507],
              [-82.017455257052561, 37.533958160271595],
              [-81.96720514115141, 37.537394399033985],
              [-82.354848892388915, 37.265072477114764],
              [-82.487652770127696, 37.231569149181489],
              [-82.56661723797238, 37.196347701867012],
              [-82.634813823838229, 37.154253777027762],
              [-82.724546173661736, 37.115596090950902],
              [-82.724546173661736, 37.041716957559572],
              [-82.781974877548777, 37.00821362962629],
              [-82.82504640546405, 37.006495510245095],
              [-82.868117933379324, 36.978146540455398],
              [-82.857350051400516, 36.92746201871018],
              [-82.878885815358146, 36.88880433263332],
              [-82.968618165181653, 36.857878183771831],
              [-83.072707690976912, 36.854441945009441],
              [-83.130136394863939, 36.785717169761689],
              [-83.13731498284983, 36.742764185231849],
              [-83.194743686736871, 36.739327946469459],
              [-83.313190388503884, 36.709260857298567],
              [-83.453172854228541, 36.664589753387524],
              [-83.52854802808028, 36.666307872768719],
              [-83.646994729847293, 36.624213947929476],
              [-83.675709081790814, 36.601019336283358],
              [-83.690066257762581, 36.582979082780824],
              [-83.987977659176593, 36.589851560305597],
              [-84.271531884618838, 36.591569679686792],
              [-84.544318228082275, 36.595864978139772],
              [-84.842229629496288, 36.605314634736338],
              [-85.025283623136232, 36.619059589785891],
              [-85.290891378613779, 36.626791127001262],
              [-85.488302548225477, 36.614764291332904],
              [-85.832874771547708, 36.621636768857684],
              [-86.033875235152351, 36.630227365763652],
              [-86.331786636566363, 36.649126678956783],
              [-86.507662042220417, 36.652562917719173],
              [-86.565090746107458, 36.633663604526042],
              [-86.590215804058033, 36.652562917719173],
              [-87.114252727027264, 36.64225420143201],
              [-87.444467774377742, 36.640536082050815],
              [-87.853647289572891, 36.633663604526042],
              [-87.850057995579945, 36.663730693696934],
              [-88.069004929149287, 36.678334708437077],
              [-88.03311198921989, 36.552052933919335],
              [-88.054647753177534, 36.497073113721129],
              [-88.546381030210298, 36.502227471864714],
              [-88.998632073320735, 36.502227471864714],
              [-89.300132768727678, 36.507381830008292],
              [-89.418579470494706, 36.498791233102324],
              [-89.371918648586487, 36.620777709167086],
              [-89.325257826678268, 36.631945485144847],
              [-89.26065053480535, 36.564938829278283],
              [-89.213989712897131, 36.580401903709031],
              [-89.188864654946542, 36.641395141741413],
              [-89.160150303003022, 36.666307872768719],
              [-89.199632536925364, 36.71613333482334],
              [-89.185275360953611, 36.753931961209609],
              [-89.12784665706657, 36.751354782137817],
              [-89.117078775087748, 36.775408453474526],
              [-89.17809677296772, 36.807193662026613],
              [-89.174507478974789, 36.839837930269297],
              [-89.138614539045392, 36.847569467484668],
              [-89.117078775087748, 36.887086213252125],
              [-89.099132305123049, 36.964401585405845],
              [-89.131435951059501, 36.982441838908386]
            ]
          ],
          [
            [
              [-89.486776056360554, 36.497073113721129],
              [-89.540615466254664, 36.497932173411726],
              [-89.572919112191116, 36.547757635466347],
              [-89.558561936219363, 36.573529426184258],
              [-89.483186762367623, 36.571811306803063],
              [-89.465240292402925, 36.529717381963813],
              [-89.486776056360554, 36.497073113721129]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '24', name: 'Maryland' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.994619936899369, 37.953179289282886],
              [-76.044870052800519, 37.953179289282886],
              [-76.048459346793464, 38.015031587005865],
              [-76.019744994849944, 38.039085258342574],
              [-75.980262760927602, 38.004722870718702],
              [-75.994619936899369, 37.953179289282886]
            ]
          ],
          [
            [
              [-76.095120168701683, 38.124991227402269],
              [-76.087941580715807, 38.192856942959423],
              [-76.048459346793464, 38.204024718937184],
              [-76.023334288842889, 38.178252928219273],
              [-76.005387818878191, 38.076883884728844],
              [-76.059227228772286, 38.094924138231377],
              [-76.044870052800519, 38.118118749877489],
              [-76.095120168701683, 38.124991227402269]
            ]
          ],
          [
            [
              [-76.396620864108641, 38.771004114731141],
              [-76.378674394143943, 38.788185308543078],
              [-76.360727924179244, 38.74866856277562],
              [-76.396620864108641, 38.771004114731141]
            ]
          ],
          [
            [
              [-79.476235110051093, 39.721124132531315],
              [-78.381500442204413, 39.72284225191251],
              [-77.534427059870595, 39.720265072840725],
              [-76.992443666936666, 39.720265072840725],
              [-76.56890697576975, 39.721124132531315],
              [-75.790030179301795, 39.721983192221913],
              [-75.790030179301795, 39.648104058830583],
              [-75.700297829478288, 38.521876804458039],
              [-75.693119241492411, 38.46002450673506],
              [-75.427511486014865, 38.452292969519689],
              [-75.050635616756168, 38.451433909829092],
              [-75.054224910749099, 38.414494343133427],
              [-75.086528556685565, 38.324293075620751],
              [-75.104475026650263, 38.311407180261796],
              [-75.176260906509057, 38.130145585545847],
              [-75.240868198381975, 38.027058422674223],
              [-75.624922655626548, 37.994414154431539],
              [-75.646458419584192, 37.947165871448711],
              [-75.667994183541836, 37.950602110211094],
              [-75.714655005450055, 37.976373900929005],
              [-75.782851591315918, 37.972937662166615],
              [-75.861816059160589, 37.917957841968416],
              [-75.894119705097054, 37.917098782277819],
              [-75.897708999089986, 37.97465578154781],
              [-75.872583941139411, 38.034789959889594],
              [-75.811565943259424, 38.058843631226303],
              [-75.872583941139411, 38.060561750607498],
              [-75.865405353153534, 38.099219436684358],
              [-75.825923119231192, 38.133581824308237],
              [-75.901298293082931, 38.141313361523608],
              [-75.944369820998205, 38.112964391733911],
              [-75.958726996969972, 38.137018063070627],
              [-75.940780527005273, 38.186843525125248],
              [-75.847458883188835, 38.21003813677136],
              [-75.868994647146465, 38.243541464704641],
              [-75.901298293082931, 38.232373688726881],
              [-75.915655469054684, 38.26329983758837],
              [-75.861816059160589, 38.359514522935221],
              [-75.915655469054684, 38.338897090360895],
              [-75.940780527005273, 38.29852128490284],
              [-75.940780527005273, 38.246977703467032],
              [-75.969494878948794, 38.246118643776434],
              [-76.008977112871122, 38.312266239952393],
              [-75.969494878948794, 38.319997777167764],
              [-75.973084172941725, 38.365527940769404],
              [-76.001798524885245, 38.374118537675372],
              [-76.016155700857013, 38.332024612836122],
              [-76.048459346793464, 38.303675643046425],
              [-76.026923582835821, 38.280481031400306],
              [-76.059227228772286, 38.227219330583303],
              [-76.073584404744039, 38.25213206161061],
              [-76.134602402624026, 38.232373688726881],
              [-76.166906048560477, 38.242682405014044],
              [-76.224334752447518, 38.313984359333588],
              [-76.256638398383984, 38.325152135311349],
              [-76.249459810398108, 38.362091702007014],
              [-76.335602866228655, 38.491809715287147],
              [-76.263816986369861, 38.503836550955505],
              [-76.245870516405162, 38.53733987888878],
              [-76.274584868348683, 38.531326461054604],
              [-76.306888514285134, 38.575138505275049],
              [-76.278174162341628, 38.609500892898922],
              [-76.23510263442634, 38.628400206092053],
              [-76.213566870468696, 38.606923713827129],
              [-76.170495342553423, 38.62925926578265],
              [-76.166906048560477, 38.598333116921161],
              [-76.087941580715807, 38.59060157970579],
              [-76.041280758807588, 38.557098251772508],
              [-76.026923582835821, 38.565688848678484],
              [-76.084352286722861, 38.62496396732967],
              [-76.145370284602848, 38.636990802998021],
              [-76.174084636546368, 38.673071310003095],
              [-76.199209694496943, 38.670494130931303],
              [-76.256638398383984, 38.736641727107262],
              [-76.274584868348683, 38.712588055770553],
              [-76.317656396263956, 38.729769249582489],
              [-76.321245690256902, 38.679084727837271],
              [-76.346370748207477, 38.685957205362044],
              [-76.335602866228655, 38.772722234112337],
              [-76.270995574355737, 38.851755725647251],
              [-76.220745458454587, 38.813098039570392],
              [-76.192031106511067, 38.82168863647636],
              [-76.202798988489889, 38.928212038110374],
              [-76.231513340433409, 38.942816052850524],
              [-76.292531338313381, 38.90244024739247],
              [-76.335602866228655, 38.905876486154853],
              [-76.332013572235724, 38.8646416210062],
              [-76.375085100150997, 38.850037606266056],
              [-76.360727924179244, 38.939379814088134],
              [-76.303299220292203, 39.02614484283842],
              [-76.278174162341628, 38.982332798617982],
              [-76.202798988489889, 38.972883142021416],
              [-76.163316754567546, 38.999513992429918],
              [-76.18485251852519, 39.045903215722149],
              [-76.145370284602848, 39.093151498704984],
              [-76.202798988489889, 39.085419961489606],
              [-76.213566870468696, 39.040748857578571],
              [-76.199209694496943, 39.014118007170062],
              [-76.242281222412217, 39.028722021910212],
              [-76.231513340433409, 39.082842782417821],
              [-76.253049104391039, 39.133527304163032],
              [-76.278174162341628, 39.14555413983139],
              [-76.220745458454587, 39.262386257752574],
              [-76.177673930539299, 39.298466764757642],
              [-76.18485251852519, 39.319084197331968],
              [-76.134602402624026, 39.340560689596892],
              [-76.109477344673451, 39.372345898148978],
              [-76.001798524885245, 39.367191540005393],
              [-76.041280758807588, 39.38780897257972],
              [-75.97667346693467, 39.447943150921503],
              [-76.012566406864067, 39.453097509065081],
              [-75.969494878948794, 39.557902791317908],
              [-76.095120168701683, 39.537285358743581],
              [-76.116655932659327, 39.496050493594929],
              [-76.073584404744039, 39.475433061020603],
              [-76.059227228772286, 39.447943150921503],
              [-76.10229875668756, 39.435916315253145],
              [-76.224334752447518, 39.352587525265243],
              [-76.249459810398108, 39.379218375673751],
              [-76.224334752447518, 39.425607598965982],
              [-76.253049104391039, 39.449661270302698],
              [-76.253049104391039, 39.411862643916436],
              [-76.285352750327505, 39.368050599695991],
              [-76.256638398383984, 39.338842570215697],
              [-76.281763456334559, 39.299325824448239],
              [-76.303299220292203, 39.381795554745544],
              [-76.357138630186299, 39.393822390413895],
              [-76.335602866228655, 39.305339242282415],
              [-76.364317218172175, 39.312211719807195],
              [-76.385852982129819, 39.27613121280212],
              [-76.342781454214546, 39.257231899608989],
              [-76.418156628066285, 39.219433273222727],
              [-76.44328168601686, 39.19537960188601],
              [-76.489942507925079, 39.20225207941079],
              [-76.497121095910956, 39.224587631366305],
              [-76.57608556375564, 39.264104377133762],
              [-76.565317681776818, 39.224587631366305],
              [-76.533014035840353, 39.213419855388544],
              [-76.525835447854476, 39.178198408074074],
              [-76.428924510045093, 39.131809184781844],
              [-76.421745922059216, 39.074252185511845],
              [-76.439692392023915, 39.052775693246929],
              [-76.393031570115696, 39.011540828098276],
              [-76.475585331953312, 38.972883142021416],
              [-76.450460274002737, 38.941097933469329],
              [-76.461228155981559, 38.90673554584545],
              [-76.49353180191801, 38.91017178460784],
              [-76.518656859868599, 38.862923501625012],
              [-76.497121095910956, 38.817393338023372],
              [-76.558139093790942, 38.767567875968751],
              [-76.525835447854476, 38.724614891438911],
              [-76.533014035840353, 38.678225668146673],
              [-76.511478271882723, 38.615514310733104],
              [-76.518656859868599, 38.539057998269975],
              [-76.49353180191801, 38.483219118381179],
              [-76.41456733407334, 38.414494343133427],
              [-76.385852982129819, 38.361232642316416],
              [-76.418156628066285, 38.322574956239556],
              [-76.468406743967435, 38.328588374073732],
              [-76.436103098030983, 38.292507867068664],
              [-76.403799452094518, 38.311407180261796],
              [-76.375085100150997, 38.299380344593438],
              [-76.400210158101572, 38.259004539135383],
              [-76.385852982129819, 38.21776967398673],
              [-76.321245690256902, 38.138736182451815],
              [-76.3391921602216, 38.119836869258684],
              [-76.321245690256902, 38.036508079270789],
              [-76.353549336193353, 38.052830213392127],
              [-76.393031570115696, 38.102655675446748],
              [-76.421745922059216, 38.106091914209138],
              [-76.439692392023915, 38.161071734407336],
              [-76.468406743967435, 38.153340197191966],
              [-76.475585331953312, 38.104373794827943],
              [-76.500710389903901, 38.137018063070627],
              [-76.529424741847421, 38.134440883998835],
              [-76.54737121181212, 38.175675749147487],
              [-76.590442739727393, 38.214333435224347],
              [-76.672996501565009, 38.234091808108076],
              [-76.798621791317913, 38.236668987179868],
              [-76.827336143261434, 38.347487687266863],
              [-76.856050495204954, 38.358655463244624],
              [-76.870407671176707, 38.332024612836122],
              [-76.83451473124731, 38.273608553875533],
              [-76.841693319233187, 38.254709240682402],
              [-76.920657787077872, 38.291648807378067],
              [-76.927836375063748, 38.320856836858361],
              [-76.974497196971967, 38.347487687266863],
              [-77.017568724887241, 38.445420491994916],
              [-77.089354604746049, 38.407621865608647],
              [-77.207801306513062, 38.359514522935221],
              [-77.250872834428336, 38.38270913458134],
              [-77.275997892378925, 38.481500998999984],
              [-77.236515658456582, 38.55194389362893],
              [-77.182676248562487, 38.600910295992954],
              [-77.110890368703679, 38.626682086710858],
              [-77.128836838668391, 38.635272683616833],
              [-77.132426132661323, 38.673930369693693],
              [-77.107301074710747, 38.697124981339805],
              [-77.04269378283783, 38.718601473604728],
              [-77.039104488844885, 38.791621547305468],
              [-76.90988990509905, 38.892990590795904],
              [-77.04269378283783, 38.994359634286333],
              [-77.118068956689569, 38.934225455944556],
              [-77.150372602626021, 38.966010664496636],
              [-77.222158482484829, 38.971165022640221],
              [-77.250872834428336, 38.985769037380365],
              [-77.247283540435404, 39.027003902529017],
              [-77.340605184251842, 39.063084409534092],
              [-77.462641180011801, 39.07597030489304],
              [-77.48417694396943, 39.109473632826322],
              [-77.520069883898842, 39.120641408804083],
              [-77.516480589905896, 39.170466870858704],
              [-77.48417694396943, 39.185929945289445],
              [-77.459051886018855, 39.228023870128695],
              [-77.541605647856471, 39.26496343682436],
              [-77.563141411814115, 39.30362112290122],
              [-77.616980821708211, 39.302762063210622],
              [-77.674409525595252, 39.324238555475546],
              [-77.721070347503471, 39.32166137640376],
              [-77.756963287432868, 39.333688212072111],
              [-77.735427523475238, 39.38780897257972],
              [-77.753373993439936, 39.424748539275384],
              [-77.785677639376388, 39.435057255562548],
              [-77.778499051390511, 39.463406225352244],
              [-77.846695637256374, 39.498627672666721],
              [-77.889767165171648, 39.557902791317908],
              [-77.832338461284607, 39.570788686676863],
              [-77.832338461284607, 39.602573895228943],
              [-77.882588577185771, 39.59913765646656],
              [-77.943606575065743, 39.618896029350289],
              [-78.008213866938661, 39.601714835538345],
              [-78.036928218882181, 39.636077223162225],
              [-78.097946216762168, 39.678171148001475],
              [-78.184089272592729, 39.695352341813411],
              [-78.284589504395043, 39.620614148731484],
              [-78.352786090260906, 39.640372521615213],
              [-78.385089736197358, 39.608587313063126],
              [-78.435339852098522, 39.618036969659691],
              [-78.39585761817618, 39.590547059560592],
              [-78.442518440084399, 39.591406119251182],
              [-78.417393382133824, 39.549312194411939],
              [-78.467643498034974, 39.516667926169255],
              [-78.568143729837303, 39.520104164931645],
              [-78.593268787787878, 39.535567239362386],
              [-78.65428678566785, 39.534708179671789],
              [-78.726072665526658, 39.563916209152083],
              [-78.733251253512535, 39.586251761107604],
              [-78.779912075420754, 39.601714835538345],
              [-78.733251253512535, 39.613741671206704],
              [-78.779912075420754, 39.622332268112672],
              [-78.916305247152465, 39.486600836998363],
              [-78.955787481074807, 39.440211613706133],
              [-79.056287712877122, 39.471137762567622],
              [-79.095769946799464, 39.47285588194881],
              [-79.142430768707683, 39.408426405154046],
              [-79.25369888248882, 39.35688282371823],
              [-79.289591822418217, 39.299325824448239],
              [-79.332663350333505, 39.300184884138837],
              [-79.451110052100518, 39.211701736007356],
              [-79.487002992029915, 39.205688318173173],
              [-79.476235110051093, 39.721124132531315]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '26', name: 'Michigan' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.651997047870481, 45.862541860608601],
              [-84.62328269592696, 45.877145875348745],
              [-84.590979049990494, 45.825602293912929],
              [-84.3576749404494, 45.771481533405328],
              [-84.418692938329386, 45.721656071350708],
              [-84.501246700167002, 45.737119145781449],
              [-84.551496816068152, 45.789521786907862],
              [-84.651997047870481, 45.862541860608601]
            ]
          ],
          [
            [
              [-85.394980904409039, 45.778354010930101],
              [-85.37703443444434, 45.812716398553981],
              [-85.351909376493765, 45.795535204742038],
              [-85.394980904409039, 45.778354010930101]
            ]
          ],
          [
            [
              [-85.524195488154874, 45.829897592365917],
              [-85.448820314303134, 45.796394264432635],
              [-85.463177490274902, 45.765468115571146],
              [-85.531374076140764, 45.79811238381383],
              [-85.524195488154874, 45.829897592365917]
            ]
          ],
          [
            [
              [-85.628285013950133, 45.59795147590475],
              [-85.603159955999558, 45.639186341053403],
              [-85.592392074020736, 45.698461459704589],
              [-85.570856310063093, 45.711347355063545],
              [-85.567267016070161, 45.760313757427568],
              [-85.502659724197244, 45.754300339593385],
              [-85.506249018190175, 45.681280265892653],
              [-85.491891842218422, 45.609978311573109],
              [-85.560088428084271, 45.572179685186846],
              [-85.628285013950133, 45.59795147590475]
            ]
          ],
          [
            [
              [-85.700070893808942, 45.736260086090851],
              [-85.653410071900723, 45.743132563615632],
              [-85.671356541865421, 45.696743340323394],
              [-85.696481599815996, 45.697602400013992],
              [-85.700070893808942, 45.736260086090851]
            ]
          ],
          [
            [
              [-85.883124887448872, 45.443320731597311],
              [-85.836464065540653, 45.42871671685716],
              [-85.832874771547708, 45.378032195111942],
              [-85.883124887448872, 45.443320731597311]
            ]
          ],
          [
            [
              [-86.066178881088803, 45.1400726608166],
              [-86.044643117131173, 45.159831033700328],
              [-85.97644653126531, 45.138354541435405],
              [-85.997982295222954, 45.055884811138107],
              [-86.059000293102926, 45.100555915049142],
              [-86.066178881088803, 45.1400726608166]
            ]
          ],
          [
            [
              [-86.155911230912309, 45.010354647536467],
              [-86.116428996989967, 45.048153273922729],
              [-86.094893233032323, 45.007777468464674],
              [-86.155911230912309, 45.010354647536467]
            ]
          ],
          [
            [
              [-86.690716035860362, 45.616850789097882],
              [-86.618930156001554, 45.561870968899683],
              [-86.647644507945074, 45.542971655706552],
              [-86.662001683916841, 45.573897804568041],
              [-86.712251799817992, 45.610837371263706],
              [-86.690716035860362, 45.616850789097882]
            ]
          ],
          [
            [
              [-86.823519913599128, 41.760531838008376],
              [-86.715841093810937, 41.818947896968965],
              [-86.618930156001554, 41.893686090050892],
              [-86.486126278262773, 42.117900669296688],
              [-86.364090282502815, 42.243323384123833],
              [-86.295893696636966, 42.358437382663823],
              [-86.260000756707569, 42.443484292032913],
              [-86.224107816778158, 42.59467879757797],
              [-86.227697110771103, 42.644504259632591],
              [-86.20616134681346, 42.719242452714518],
              [-86.209750640806405, 42.859269182281814],
              [-86.231286404764049, 43.015618045970456],
              [-86.270768638686377, 43.11870520884208],
              [-86.396393928439281, 43.316288937679367],
              [-86.435876162361623, 43.397040548595477],
              [-86.478947690276897, 43.515590785897849],
              [-86.539965688156883, 43.617818889078883],
              [-86.539965688156883, 43.659053754227536],
              [-86.461001220312198, 43.746677842668419],
              [-86.435876162361623, 43.789630827198266],
              [-86.432286868368678, 43.855778423374225],
              [-86.464590514305144, 43.970892421914215],
              [-86.514840630206294, 44.048207794067935],
              [-86.500483454234541, 44.075697704167034],
              [-86.428697574375747, 44.119509748387479],
              [-86.342554518545185, 44.249227761667612],
              [-86.267179344693446, 44.345442447014463],
              [-86.252822168721679, 44.451965848648477],
              [-86.220518522785227, 44.567079847188467],
              [-86.260000756707569, 44.663294532535318],
              [-86.256411462714624, 44.691643502325014],
              [-86.159500524905241, 44.728583069020679],
              [-86.087714645046447, 44.741468964379635],
              [-86.066178881088803, 44.821361515605147],
              [-86.073357469074693, 44.884931932709321],
              [-86.037464529145282, 44.915858081570811],
              [-85.990803707237063, 44.900395007140062],
              [-85.933375003350037, 44.969119782387814],
              [-85.868767711477105, 44.939052693216922],
              [-85.807749713597133, 44.950220469194683],
              [-85.779035361653612, 44.977710379293782],
              [-85.746731715717161, 45.051589512685119],
              [-85.710838775787749, 45.065334467734672],
              [-85.656999365893654, 45.146086078650782],
              [-85.617517131971312, 45.186461884108837],
              [-85.58521348603486, 45.180448466274655],
              [-85.542141958119572, 45.210515555445546],
              [-85.531374076140764, 45.177012227512265],
              [-85.599570662006613, 45.149522317413165],
              [-85.610338543985435, 45.113441810408098],
              [-85.567267016070161, 45.043857975469749],
              [-85.649820777907777, 44.974274140531399],
              [-85.603159955999558, 44.990596274652738],
              [-85.603159955999558, 44.927025857548571],
              [-85.624695719957202, 44.921012439714389],
              [-85.653410071900723, 44.848851425704247],
              [-85.628285013950133, 44.767240755097546],
              [-85.599570662006613, 44.765522635716351],
              [-85.531374076140764, 44.890086290852899],
              [-85.520606194161942, 44.974274140531399],
              [-85.473945372253723, 44.991455334343335],
              [-85.502659724197244, 44.855723903229027],
              [-85.556499134091339, 44.817925276842765],
              [-85.578034898048983, 44.760368277572766],
              [-85.527784782147819, 44.748341441904415],
              [-85.499070430204299, 44.804180321793211],
              [-85.473945372253723, 44.815348097770972],
              [-85.394980904409039, 44.931321156001552],
              [-85.37703443444434, 44.998327811868108],
              [-85.380623728437286, 45.046435154541541],
              [-85.366266552465518, 45.116878049170481],
              [-85.387802316423162, 45.18989812287122],
              [-85.369855846458464, 45.27064973378733],
              [-85.308837848578477, 45.31274365862658],
              [-85.27294490864908, 45.315320837698373],
              [-85.208337616776163, 45.356555702847018],
              [-85.143730324903245, 45.370300657896571],
              [-85.053997975079753, 45.364287240062396],
              [-84.960676331263315, 45.376314075730747],
              [-84.917604803348027, 45.39349526954269],
              [-84.921194097340972, 45.421844239332387],
              [-85.039640799107985, 45.436448254072531],
              [-85.08989091500915, 45.476824059530585],
              [-85.11860526695267, 45.574756864258632],
              [-85.061176563065629, 45.639186341053403],
              [-85.01451574115741, 45.651213176721761],
              [-84.942729861298616, 45.710488295372947],
              [-84.949908449284493, 45.737119145781449],
              [-85.01451574115741, 45.760313757427568],
              [-84.799158101581014, 45.747427862068612],
              [-84.774033043630439, 45.789521786907862],
              [-84.555086110061097, 45.701897698466979],
              [-84.461764466244659, 45.652072236412359],
              [-84.41510364433644, 45.669253430224295],
              [-84.375621410414098, 45.655508475174742],
              [-84.328960588505879, 45.664099072080717],
              [-84.214103180731797, 45.634891042600415],
              [-84.12796012490125, 45.561870968899683],
              [-84.117192242922428, 45.51290456653566],
              [-84.056174245042442, 45.489709954889541],
              [-83.937727543275429, 45.493146193651931],
              [-83.909013191331908, 45.485414656436554],
              [-83.804923665536649, 45.419267060260594],
              [-83.722369903699033, 45.413253642426419],
              [-83.600333907939074, 45.352260404394038],
              [-83.496244382143814, 45.357414762537616],
              [-83.420869208292075, 45.290408106671059],
              [-83.381386974369747, 45.268931614406135],
              [-83.413690620306198, 45.245737002760023],
              [-83.359851210412103, 45.163267272462718],
              [-83.32036897648976, 45.137495481744807],
              [-83.270118860588596, 45.023240542895422],
              [-83.341904740447404, 45.041280796397956],
              [-83.399333444334445, 45.07048882587825],
              [-83.453172854228541, 45.03526737856378],
              [-83.438815678256773, 44.940770812598117],
              [-83.3957441503415, 44.902972186211855],
              [-83.32036897648976, 44.880636634256334],
              [-83.302422506525062, 44.829952112511116],
              [-83.298833212532116, 44.745764262832623],
              [-83.273708154581541, 44.713979054280536],
              [-83.288065330553309, 44.652985816248155],
              [-83.316779682496815, 44.595428816978163],
              [-83.309601094510938, 44.53958993708936],
              [-83.327547564475637, 44.444234311433107],
              [-83.331136858468582, 44.340288088870878],
              [-83.374208386383856, 44.327402193511929],
              [-83.42445850228502, 44.272422373313724],
              [-83.510601558115582, 44.274140492694919],
              [-83.539315910059102, 44.248368701977014],
              [-83.568030262002623, 44.155590255392546],
              [-83.582387437974376, 44.056798390973903],
              [-83.650584023840239, 44.052503092520915],
              [-83.679298375783759, 44.036180958399576],
              [-83.679298375783759, 43.994087033560326],
              [-83.830048723487238, 43.988932675416748],
              [-83.876709545395457, 43.958865586245857],
              [-83.909013191331908, 43.893577049760488],
              [-83.926959661296607, 43.787053648126474],
              [-83.955674013240127, 43.750973141121406],
              [-83.909013191331908, 43.672798709277089],
              [-83.851584487444867, 43.645308799177982],
              [-83.819280841508416, 43.673657768967679],
              [-83.801334371543717, 43.641013500725002],
              [-83.729548491684909, 43.622973247222468],
              [-83.68288766977669, 43.590328978979784],
              [-83.646994729847293, 43.60407393402933],
              [-83.546494498044979, 43.707161096900961],
              [-83.496244382143814, 43.707161096900961],
              [-83.471119324193239, 43.728637589165885],
              [-83.496244382143814, 43.77244963338633],
              [-83.463940736207363, 43.810248259772592],
              [-83.431637090270897, 43.88498645285452],
              [-83.417279914299144, 43.842033468324679],
              [-83.349083328433281, 43.868664318733181],
              [-83.331136858468582, 43.893577049760488],
              [-83.406512032320322, 43.920207900168997],
              [-83.32036897648976, 43.917630721097204],
              [-83.280886742567418, 43.93824815367153],
              [-83.262940272602719, 43.973469600986],
              [-83.180386510765103, 43.982060197891968],
              [-83.058350515005145, 44.006113869228685],
              [-83.026046869068693, 44.044771555305545],
              [-82.914778755287543, 44.070543346023456],
              [-82.875296521365215, 44.044771555305545],
              [-82.792742759527599, 44.023295063040621],
              [-82.738903349633489, 43.989791735107346],
              [-82.677885351753517, 43.884127393163922],
              [-82.64199241182412, 43.852342184611842],
              [-82.616867353873531, 43.76901339462394],
              [-82.598920883908832, 43.590328978979784],
              [-82.53790288602886, 43.437416354053532],
              [-82.523545710057093, 43.225228610476094],
              [-82.487652770127696, 43.102383074720741],
              [-82.423045478254778, 43.007886508755078],
              [-82.415866890268902, 42.966651643606426],
              [-82.455349124191244, 42.927134897838968],
              [-82.480474182141819, 42.80257124270242],
              [-82.466117006170052, 42.762195437244365],
              [-82.509188534085339, 42.665121692206917],
              [-82.523545710057093, 42.607564692936926],
              [-82.58815300193001, 42.550866753357525],
              [-82.64199241182412, 42.554302992119915],
              [-82.685063939739393, 42.518222485114848],
              [-82.681474645746448, 42.574920424694241],
              [-82.713778291682914, 42.598115036340353],
              [-82.677885351753517, 42.611859991389906],
              [-82.624045941859421, 42.665121692206917],
              [-82.674296057760571, 42.687457244162438],
              [-82.728135467654667, 42.68316194570945],
              [-82.79633205352053, 42.653953916229156],
              [-82.821457111471105, 42.626464006130057],
              [-82.789153465534653, 42.603269394483938],
              [-82.781974877548777, 42.564611708407078],
              [-82.835814287442872, 42.568047947169468],
              [-82.875296521365215, 42.523376843258426],
              [-82.871707227372269, 42.451215829248284],
              [-82.896832285322844, 42.385068233072325],
              [-82.957850283202831, 42.339538069470684],
              [-83.065529102991022, 42.318061577205768],
              [-83.130136394863939, 42.234732787217865],
              [-83.122957806878063, 42.125632206512059],
              [-83.133725688856885, 42.087833580125796],
              [-83.18756509875098, 42.066357087860872],
              [-83.191154392743925, 42.006222909519089],
              [-83.248583096630966, 41.972719581585807],
              [-83.270118860588596, 41.939216253652532],
              [-83.316779682496815, 41.935780014890142],
              [-83.341904740447404, 41.879941135001346],
              [-83.381386974369747, 41.87049147840478],
              [-83.442404972249719, 41.808639180681801],
              [-83.42445850228502, 41.740773465124647],
              [-83.453172854228541, 41.733041927909269],
              [-83.761852137621375, 41.723592271312704],
              [-84.361264234442345, 41.706411077500768],
              [-84.806336689566891, 41.696102361213605],
              [-84.806336689566891, 41.760531838008376],
              [-85.11860526695267, 41.759672778317778],
              [-85.789803243632434, 41.758813718627181],
              [-86.500483454234541, 41.759672778317778],
              [-86.823519913599128, 41.760531838008376]
            ]
          ],
          [
            [
              [-89.25347194681946, 47.876177775367744],
              [-89.17809677296772, 47.93545289401893],
              [-88.837113843638434, 48.056580310393095],
              [-88.811988785687859, 48.0548621910119],
              [-88.657649143991435, 48.1390500406904],
              [-88.546381030210298, 48.175130547695467],
              [-88.524845266252655, 48.165680891098901],
              [-88.42434503445034, 48.190593622126215],
              [-88.427934328443285, 48.166539950789499],
              [-88.471005856358559, 48.152794995739953],
              [-88.578684676146764, 48.084070220492194],
              [-88.578684676146764, 48.05829842977429],
              [-88.668417025970257, 48.011050146791462],
              [-88.851471019610187, 47.965519983189822],
              [-89.002221367313666, 47.908822043610428],
              [-88.99504277932779, 47.891640849798492],
              [-88.912489017490174, 47.891640849798492],
              [-89.124257363073625, 47.828929492384916],
              [-89.188864654946542, 47.831506671456708],
              [-89.25347194681946, 47.876177775367744]
            ]
          ],
          [
            [
              [-90.416403200532002, 46.566111747207465],
              [-90.34820661466614, 46.600474134831337],
              [-90.305135086750866, 46.60305131390313],
              [-90.165152621026209, 46.64514523874238],
              [-90.028759449294483, 46.674353268222674],
              [-89.917491335513347, 46.740500864398633],
              [-89.849294749647498, 46.795480684596839],
              [-89.791866045760457, 46.818675296242958],
              [-89.67341934399343, 46.833279310983102],
              [-89.619579934099335, 46.818675296242958],
              [-89.515490408304075, 46.84186990788907],
              [-89.436525940459404, 46.839292728817277],
              [-89.249882652826528, 46.903722205612048],
              [-89.142203833038323, 46.984473816528158],
              [-89.063239365193652, 46.988769114981139],
              [-88.959149839398393, 47.008527487864868],
              [-88.923256899468996, 47.042030815798149],
              [-88.905310429504297, 47.085842860018595],
              [-88.81557807968079, 47.141681739907391],
              [-88.76532796377964, 47.155426694956944],
              [-88.657649143991435, 47.225869589585891],
              [-88.575095382153819, 47.245627962469619],
              [-88.49972020830208, 47.293735305143045],
              [-88.460237974379737, 47.340124528435275],
              [-88.388452094520943, 47.384795632346318],
              [-88.180273042930423, 47.45781570604705],
              [-87.979272579325794, 47.479292198311974],
              [-87.799807879678795, 47.473278780477798],
              [-87.717254117841179, 47.439775452544517],
              [-87.713664823848234, 47.401117766467657],
              [-87.753147057770576, 47.405413064920644],
              [-87.814165055650548, 47.384795632346318],
              [-87.882361641516411, 47.395963408324079],
              [-87.95773681536815, 47.387372811418103],
              [-87.939790345403452, 47.346997005960048],
              [-88.054647753177534, 47.298030603596025],
              [-88.162326572965725, 47.216419932989325],
              [-88.230523158831588, 47.199238739177382],
              [-88.248469628796286, 47.135668322073215],
              [-88.273594686746861, 47.143399859288586],
              [-88.298719744697436, 47.098728755377543],
              [-88.348969860598601, 47.076393203422029],
              [-88.366916330563299, 47.018836204152031],
              [-88.409987858478587, 46.977601339003385],
              [-88.442291504415039, 46.9724469808598],
              [-88.485363032330326, 46.831561191601907],
              [-88.463827268372683, 46.786890087690871],
              [-88.496130914309134, 46.756822998519979],
              [-88.456648680386806, 46.759400177591772],
              [-88.384862800527998, 46.834138370673699],
              [-88.374094918549176, 46.871936997059962],
              [-88.348969860598601, 46.860769221082201],
              [-88.244880334803341, 46.929493996329953],
              [-88.144380103001026, 46.966433563025625],
              [-88.1874516309163, 46.91918528004279],
              [-88.230523158831588, 46.89856784746847],
              [-88.284362568725683, 46.832420251292504],
              [-88.176683748937492, 46.904581265302646],
              [-88.083362105121054, 46.920044339733387],
              [-88.03311198921989, 46.908876563755626],
              [-87.90030811148111, 46.909735623446224],
              [-87.846468701587014, 46.88396383272832],
              [-87.817754349643494, 46.890836310253093],
              [-87.756736351763507, 46.860769221082201],
              [-87.728021999820001, 46.827265893148926],
              [-87.674182589925891, 46.836715549745492],
              [-87.595218122081221, 46.782594789237884],
              [-87.584450240102399, 46.73019214811147],
              [-87.523432242222412, 46.688098223272227],
              [-87.501896478264783, 46.647722417814173],
              [-87.451646362363618, 46.605628492974922],
              [-87.383449776497756, 46.592742597615967],
              [-87.390628364483646, 46.524876882058813],
              [-87.351146130561304, 46.500823210722096],
              [-87.257824486744866, 46.487937315363148],
              [-87.107074139041387, 46.496527912269116],
              [-87.017341789217895, 46.533467478964781],
              [-86.977859555295552, 46.526595001440008],
              [-86.927609439394388, 46.464742703717029],
              [-86.884537911479114, 46.44154809207091],
              [-86.816341325613251, 46.438111853308527],
              [-86.787626973669731, 46.477628599075985],
              [-86.73019826978269, 46.471615181241802],
              [-86.697894623846238, 46.438970912999125],
              [-86.683537447874471, 46.498246031650311],
              [-86.70866250582506, 46.543776195251944],
              [-86.651233801938019, 46.56095738906388],
              [-86.62610874398743, 46.533467478964781],
              [-86.647644507945074, 46.485360136291355],
              [-86.586626510065102, 46.463024584335834],
              [-86.493304866248664, 46.524876882058813],
              [-86.349733106531062, 46.578138582875823],
              [-86.137964760947611, 46.672635148841479],
              [-86.098482527025268, 46.654594895338946],
              [-85.879535593455927, 46.690675402344013],
              [-85.714428069780695, 46.676930447294467],
              [-85.588802780027791, 46.674353268222674],
              [-85.4811239602396, 46.68036668605685],
              [-85.258587732677327, 46.753386759757589],
              [-85.172444676846766, 46.763695476044752],
              [-85.064765857058561, 46.757682058210577],
              [-84.949908449284493, 46.769708893878928],
              [-85.028872917129164, 46.697547879868793],
              [-85.036051505115054, 46.581574821638206],
              [-85.028872917129164, 46.542917135561346],
              [-85.057587269072684, 46.526595001440008],
              [-85.025283623136232, 46.482782957219563],
              [-84.967854919249191, 46.47591047969479],
              [-84.935551273312726, 46.489655434744343],
              [-84.914015509355096, 46.467319882788821],
              [-84.860176099460986, 46.469897061860614],
              [-84.799158101581014, 46.445843390523898],
              [-84.677122105821056, 46.487937315363148],
              [-84.630461283912837, 46.484501076600758],
              [-84.590979049990494, 46.4449843308333],
              [-84.59456834398344, 46.414917241662408],
              [-84.504835994159933, 46.438970912999125],
              [-84.479710936209358, 46.432957495164942],
              [-84.443817996279961, 46.488796375053745],
              [-84.375621410414098, 46.509413807628071],
              [-84.293067648576482, 46.493091673506726],
              [-84.25358541465414, 46.500823210722096],
              [-84.224871062710619, 46.534326538655378],
              [-84.12796012490125, 46.530031240202398],
              [-84.110013654936552, 46.504259449484486],
              [-84.145906594865949, 46.418353480424798],
              [-84.120781536915359, 46.315266317553167],
              [-84.117192242922428, 46.23365564694646],
              [-84.221281768717688, 46.231937527565265],
              [-84.249996120661208, 46.176098647676469],
              [-84.196156710767099, 46.165789931389305],
              [-84.1782102408024, 46.183830184891839],
              [-84.124370830908305, 46.180393946129456],
              [-84.095656478964784, 46.147749677886772],
              [-84.027459893098921, 46.131427543765433],
              [-84.07412071500714, 46.097065156141554],
              [-83.987977659176593, 46.032635679346789],
              [-83.944906131261305, 46.031776619656192],
              [-83.873120251402511, 45.993118933579325],
              [-83.844405899458991, 46.025763201822009],
              [-83.794155783557827, 45.995696112651117],
              [-83.765441431614306, 46.018031664606639],
              [-83.822870135501347, 46.103937633666327],
              [-83.772620019600197, 46.091051738307378],
              [-83.625458965889649, 46.102219514285139],
              [-83.582387437974376, 46.089333618926183],
              [-83.532137322073211, 46.011159187081866],
              [-83.474708618186185, 45.984528336673357],
              [-83.510601558115582, 45.929548516475158],
              [-83.560851674016732, 45.912367322663222],
              [-83.657762611826115, 45.945870650596497],
              [-83.769030725607251, 45.932125695546951],
              [-83.880298839388388, 45.968206202552018],
              [-83.91978107331073, 45.957897486264855],
              [-84.016692011120114, 45.958756545955453],
              [-84.113602948929483, 45.978514918839181],
              [-84.328960588505879, 45.95617936688366],
              [-84.375621410414098, 45.932125695546951],
              [-84.508425288152878, 45.991400814198137],
              [-84.562264698046974, 46.032635679346789],
              [-84.608925519955193, 46.026622261512607],
              [-84.655586341863412, 46.052394052230518],
              [-84.691479281792809, 46.017172604916041],
              [-84.684300693806932, 45.973360560695596],
              [-84.738140103701028, 45.945870650596497],
              [-84.734550809708097, 45.907212964519637],
              [-84.702247163771631, 45.853092204012036],
              [-84.745318691686919, 45.835911010200093],
              [-84.831461747517466, 45.871991517205167],
              [-84.842229629496288, 45.898622367613669],
              [-84.917604803348027, 45.930407576165756],
              [-84.935551273312726, 45.95617936688366],
              [-85.01451574115741, 46.011159187081866],
              [-85.150908912889122, 46.050675932849323],
              [-85.197569734797341, 46.044662515015141],
              [-85.316016436564368, 46.086756439854391],
              [-85.380623728437286, 46.08246114140141],
              [-85.427284550345504, 46.102219514285139],
              [-85.445231020310203, 46.085038320473195],
              [-85.513427606176052, 46.094487977069761],
              [-85.603159955999558, 46.030058500274997],
              [-85.66417795387953, 45.967347142861421],
              [-85.696481599815996, 45.960474665336648],
              [-85.811339007590078, 45.980233038220376],
              [-85.893892769427694, 45.967347142861421],
              [-85.922607121371215, 45.948447829668289],
              [-85.915428533385324, 45.919239800187995],
              [-85.997982295222954, 45.951025008740082],
              [-86.073357469074693, 45.965629023480226],
              [-86.159500524905241, 45.953602187811867],
              [-86.195393464834652, 45.963051844408433],
              [-86.277947226672268, 45.942434411834114],
              [-86.324608048580487, 45.906353904829039],
              [-86.331786636566363, 45.852233144321438],
              [-86.364090282502815, 45.79038084659846],
              [-86.414340398403979, 45.793817085360843],
              [-86.439465456354554, 45.760313757427568],
              [-86.518429924199239, 45.747427862068612],
              [-86.539965688156883, 45.707911116301155],
              [-86.586626510065102, 45.705333937229362],
              [-86.586626510065102, 45.666676251152502],
              [-86.629698037980376, 45.658944713937132],
              [-86.615340862008622, 45.606542072810718],
              [-86.687126741867417, 45.634031982909825],
              [-86.719430387803868, 45.668394370533697],
              [-86.676358859888595, 45.691588982179816],
              [-86.672769565895649, 45.72079701166011],
              [-86.633287331973321, 45.747427862068612],
              [-86.629698037980376, 45.781790249692492],
              [-86.583037216072157, 45.778354010930101],
              [-86.529197806178061, 45.853092204012036],
              [-86.539965688156883, 45.890031770707701],
              [-86.583037216072157, 45.898622367613669],
              [-86.647644507945074, 45.834192890818898],
              [-86.7840376796768, 45.854810323393224],
              [-86.773269797697978, 45.810998279172786],
              [-86.819930619606197, 45.770622473714731],
              [-86.837877089570895, 45.722515131041305],
              [-86.945555909359086, 45.695884280632797],
              [-86.963502379323785, 45.672689668986685],
              [-86.985038143281429, 45.70619299691996],
              [-86.974270261302607, 45.753441279902795],
              [-86.988627437274374, 45.810998279172786],
              [-87.017341789217895, 45.838488189271885],
              [-87.053234729147292, 45.822166055150547],
              [-87.07118119911199, 45.719078892278915],
              [-87.060413317133168, 45.708770175991752],
              [-87.171681430914305, 45.661521893008924],
              [-87.265003074730743, 45.550703192921922],
              [-87.326021072610729, 45.425280478094777],
              [-87.394217658476578, 45.369441598205974],
              [-87.466003538335386, 45.273226912859123],
              [-87.613164592045919, 45.123750526695261],
              [-87.591628828088275, 45.095401556905564],
              [-87.659825413954138, 45.107428392573915],
              [-87.688539765897659, 45.14780419803197],
              [-87.735200587805878, 45.171857869368687],
              [-87.742379175791754, 45.196770600395993],
              [-87.688539765897659, 45.298139643886429],
              [-87.649057531975316, 45.339374509035082],
              [-87.656236119961193, 45.368582538515376],
              [-87.695718353883535, 45.3900590307803],
              [-87.753147057770576, 45.349683225322245],
              [-87.850057995579945, 45.341092628416277],
              [-87.850057995579945, 45.341092628416277],
              [-87.875183053530534, 45.381468433874332],
              [-87.850057995579945, 45.402085866448658],
              [-87.860825877558767, 45.434730134691343],
              [-87.814165055650548, 45.463938164171637],
              [-87.792629291692919, 45.498300551795509],
              [-87.803397173671726, 45.538676357253564],
              [-87.77468282172822, 45.59795147590475],
              [-87.82493293762937, 45.662380952699522],
              [-87.781861409714097, 45.673548728677282],
              [-87.810575761657617, 45.711347355063545],
              [-87.857236583565836, 45.726810429494286],
              [-87.878772347523466, 45.755159399283983],
              [-87.964915403354027, 45.758595638046373],
              [-87.997219049290493, 45.795535204742038],
              [-88.072594223142232, 45.780072130311297],
              [-88.13720151501515, 45.818729816388156],
              [-88.076183517135163, 45.863400920299199],
              [-88.101308575085753, 45.881441173801733],
              [-88.101308575085753, 45.921816979259788],
              [-88.244880334803341, 45.963051844408433],
              [-88.295130450704505, 45.951025008740082],
              [-88.381273506535067, 45.991400814198137],
              [-88.42434503445034, 45.978514918839181],
              [-88.49972020830208, 45.996555172341715],
              [-88.506898796287956, 46.018031664606639],
              [-88.603809734097339, 46.016313545225444],
              [-88.614577616076161, 45.988823635126344],
              [-88.664827731977311, 45.988823635126344],
              [-88.679184907949079, 46.013736366153651],
              [-88.740202905829051, 46.027481321203204],
              [-88.783274433744339, 46.016313545225444],
              [-89.091953717137173, 46.138300021290206],
              [-90.122081093110921, 46.336742809818091],
              [-90.11849179911799, 46.35993742146421],
              [-90.218992030920305, 46.503400389793889],
              [-90.315902968729688, 46.517145344843442],
              [-90.351795908659085, 46.537762777417768],
              [-90.351795908659085, 46.537762777417768],
              [-90.394867436574359, 46.532608419274183],
              [-90.416403200532002, 46.566111747207465]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '28', name: 'Mississippi' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.510488090280901, 30.217346775457749],
              [-88.402809270492696, 30.206178999479988],
              [-88.453059386393861, 30.188997805668052],
              [-88.496130914309134, 30.196729342883422],
              [-88.510488090280901, 30.217346775457749]
            ]
          ],
          [
            [
              [-88.772506551765517, 30.245695745247446],
              [-88.715077847878476, 30.256863521225206],
              [-88.607399028090271, 30.231950790197899],
              [-88.585863264132641, 30.219064894838944],
              [-88.672006319963202, 30.223360193291924],
              [-88.772506551765517, 30.245695745247446]
            ]
          ],
          [
            [
              [-88.987864191341913, 30.216487715767151],
              [-88.934024781447818, 30.222501133601334],
              [-88.880185371553708, 30.249131984009836],
              [-88.894542547525475, 30.224219252982522],
              [-88.969917721377215, 30.20102464133641],
              [-88.987864191341913, 30.216487715767151]
            ]
          ],
          [
            [
              [-89.160150303003022, 30.238823267722672],
              [-89.084775129151282, 30.237964208032075],
              [-89.056060777207762, 30.249131984009836],
              [-89.074007247172474, 30.212192417314171],
              [-89.109900187101871, 30.206178999479988],
              [-89.170918184981844, 30.228514551435509],
              [-89.160150303003022, 30.238823267722672]
            ]
          ],
          [
            [
              [-91.166565645056451, 33.004136411754111],
              [-91.119904823148232, 33.054820933499329],
              [-91.202458584985848, 33.108082634316339],
              [-91.18451211502115, 33.141585962249614],
              [-91.145029881098807, 33.129559126581263],
              [-91.094779765197643, 33.136431604106036],
              [-91.084011883218835, 33.156189976989765],
              [-91.091190471204712, 33.220619453784529],
              [-91.044529649296493, 33.265290557695572],
              [-91.051708237282369, 33.2850489305793],
              [-91.087601177211766, 33.27388115460154],
              [-91.098369059190588, 33.237800647596472],
              [-91.141440587105862, 33.296216706557061],
              [-91.141440587105862, 33.35119652675526],
              [-91.058886825268246, 33.428511898908987],
              [-91.076833295232944, 33.456001809008086],
              [-91.112726235162341, 33.39329045159451],
              [-91.173744233042328, 33.381263615926152],
              [-91.209637172971725, 33.401881048500478],
              [-91.198869290992903, 33.418203182621824],
              [-91.137851293112931, 33.426793779527792],
              [-91.123494117141163, 33.473183002820022],
              [-91.173744233042328, 33.496377614466141],
              [-91.170154939049382, 33.452565570245696],
              [-91.2347622309223, 33.438820615196143],
              [-91.18451211502115, 33.507545390443902],
              [-91.231172936929369, 33.561666150951503],
              [-91.130672705127054, 33.59688759826598],
              [-91.137851293112931, 33.625236568055676],
              [-91.227583642936423, 33.669048612276114],
              [-91.220405054950547, 33.693102283612831],
              [-91.15938705707056, 33.706847238662384],
              [-91.134261999119985, 33.676780149491492],
              [-91.076833295232944, 33.657880836298354],
              [-91.033761767317671, 33.673343910729102],
              [-91.062476119261191, 33.716296895258949],
              [-91.10913694116941, 33.704270059590591],
              [-91.145029881098807, 33.726605611546113],
              [-91.141440587105862, 33.777290133291324],
              [-91.055297531275315, 33.779008252672519],
              [-91.026583179331794, 33.763545178241777],
              [-90.990690239402383, 33.792753207722072],
              [-91.048118943289424, 33.815088759677593],
              [-91.073244001240013, 33.862337042660421],
              [-91.008636709367096, 33.929343698526978],
              [-91.087601177211766, 33.958551728007272],
              [-91.076833295232944, 33.983464459034586],
              [-91.019404591345904, 34.003222831918315],
              [-91.001458121381205, 33.96628326522265],
              [-90.961975887458877, 33.980028220272196],
              [-90.987100945409452, 34.018685906349056],
              [-90.900957889578891, 34.023840264492641],
              [-90.87224353763537, 34.076242905619047],
              [-90.883011419614192, 34.096860338193373],
              [-90.922493653536534, 34.094283159121588],
              [-90.954797299472986, 34.138095203342026],
              [-90.911725771557713, 34.165585113441125],
              [-90.854297067670672, 34.137236143651428],
              [-90.807636245762453, 34.161289814988145],
              [-90.814814833748329, 34.182766307253068],
              [-90.886600713607137, 34.181907247562471],
              [-90.936850829508288, 34.218846814258136],
              [-90.904547183571836, 34.24375954528545],
              [-90.847118479684795, 34.206819978589778],
              [-90.832761303713028, 34.267813216622159],
              [-90.764564717847179, 34.280699111981114],
              [-90.73943965989659, 34.306470902699019],
              [-90.76815401184011, 34.345128588775879],
              [-90.750207541875412, 34.368323200421997],
              [-90.682010956009563, 34.369182260112595],
              [-90.692778837988371, 34.322793036820364],
              [-90.656885898058974, 34.321933977129767],
              [-90.656885898058974, 34.376054737637368],
              [-90.567153548235481, 34.425021140001391],
              [-90.588689312193111, 34.496323094320935],
              [-90.542028490284892, 34.54013513854138],
              [-90.545617784277837, 34.563329750187499],
              [-90.588689312193111, 34.615732391313905],
              [-90.588689312193111, 34.670712211512111],
              [-90.552796372263714, 34.688752465014645],
              [-90.556385666256659, 34.646658540175395],
              [-90.516903432334317, 34.631195465744653],
              [-90.466653316433167, 34.674148450274494],
              [-90.473831904419043, 34.700779300683003],
              [-90.545617784277837, 34.702497420064198],
              [-90.567153548235481, 34.73685980768807],
              [-90.542028490284892, 34.748886643356428],
              [-90.549207078270783, 34.77895373252732],
              [-90.513314138341386, 34.802148344173439],
              [-90.520492726327262, 34.753181941809409],
              [-90.502546256362564, 34.723973912329114],
              [-90.452296140461399, 34.74029604645046],
              [-90.473831904419043, 34.802148344173439],
              [-90.455885434454345, 34.823624836438356],
              [-90.484599786397865, 34.861423462824625],
              [-90.477421198411975, 34.886336193851932],
              [-90.437938964489646, 34.884618074470737],
              [-90.423581788517879, 34.833933552725519],
              [-90.312313674736743, 34.871732179111788],
              [-90.247706382863825, 34.909530805498051],
              [-90.247706382863825, 34.948188491574911],
              [-90.308724380743797, 34.995436774557739],
              [-89.486776056360554, 34.993718655176544],
              [-88.926846193461927, 34.994577714867141],
              [-88.201808806888067, 34.995436774557739],
              [-88.155147984979848, 34.922416700856999],
              [-88.097719281092807, 34.892349611686107],
              [-88.140790809008081, 34.581370003690033],
              [-88.208987394873944, 34.028994622636219],
              [-88.230523158831588, 33.891545072140715],
              [-88.348969860598601, 32.929398218672183],
              [-88.366916330563299, 32.747277564265637],
              [-88.442291504415039, 32.137345183941832],
              [-88.474595150351504, 31.894231291502912],
              [-88.438702210422107, 31.230178150671499],
              [-88.417166446464464, 30.866795901549011],
              [-88.406398564485642, 30.560111592005917],
              [-88.39563068250682, 30.369400340693403],
              [-88.409987858478587, 30.341910430594304],
              [-88.435112916429162, 30.354796325953252],
              [-88.471005856358559, 30.32043393832938],
              [-88.535613148231477, 30.343628549975492],
              [-88.582273970139696, 30.330742654616543],
              [-88.600220440104394, 30.356514445334447],
              [-88.664827731977311, 30.362527863168623],
              [-88.729435023850229, 30.342769490284894],
              [-88.811988785687859, 30.384863415124144],
              [-88.819167373673736, 30.360809743787435],
              [-88.894542547525475, 30.393454012030112],
              [-88.973507015370146, 30.390876832958327],
              [-89.084775129151282, 30.367682221312208],
              [-89.185275360953611, 30.331601714307141],
              [-89.292954180741802, 30.303252744517437],
              [-89.278597004770049, 30.349641967809674],
              [-89.314489944699446, 30.375413758527579],
              [-89.364740060600596, 30.352219146881467],
              [-89.332436414664144, 30.337615132141316],
              [-89.328847120671199, 30.303252744517437],
              [-89.418579470494706, 30.254286342153414],
              [-89.447293822438226, 30.185561566905662],
              [-89.479597468374678, 30.19415216381163],
              [-89.526258290282897, 30.180407208762084],
              [-89.569329818198185, 30.180407208762084],
              [-89.615990640106403, 30.223360193291924],
              [-89.630347816078157, 30.280058132871325],
              [-89.630347816078157, 30.339333251522511],
              [-89.684187225972252, 30.40548084769847],
              [-89.684187225972252, 30.451870070990708],
              [-89.720080165901649, 30.488809637686373],
              [-89.759562399823992, 30.505131771807712],
              [-89.773919575795759, 30.538635099740993],
              [-89.809812515725156, 30.567843129221288],
              [-89.824169691696909, 30.649453799827995],
              [-89.852884043640429, 30.661480635496346],
              [-89.816991103711032, 30.737077888268878],
              [-89.8313482796828, 30.768004037130368],
              [-89.806223221732211, 30.789480529395291],
              [-89.773919575795759, 30.896862990719903],
              [-89.74879451784517, 30.913185124841242],
              [-89.752383811838115, 30.951842810918102],
              [-89.72725875388754, 30.970742124111233],
              [-89.752383811838115, 31.001668272972722],
              [-90.344617320673208, 30.999950153591527],
              [-90.825582715727151, 30.999091093900937],
              [-91.636763158131572, 30.999091093900937],
              [-91.590102336223353, 31.01713134740347],
              [-91.564977278272778, 31.066956809458091],
              [-91.622405982159819, 31.109909793987931],
              [-91.625995276152764, 31.133963465324648],
              [-91.590102336223353, 31.173480211092105],
              [-91.600870218202175, 31.208701658406582],
              [-91.643941746117463, 31.234473449124486],
              [-91.640352452124517, 31.267117717367171],
              [-91.5757451602516, 31.261104299532988],
              [-91.514727162371614, 31.278285493344931],
              [-91.507548574385737, 31.315225060040596],
              [-91.550620102301025, 31.347010268592683],
              [-91.554209396293956, 31.385667954669543],
              [-91.579334454244545, 31.399412909719089],
              [-91.547030808308079, 31.432916237652371],
              [-91.532673632336326, 31.389963253122524],
              [-91.503959280392806, 31.365050522095217],
              [-91.47165563445634, 31.371063939929392],
              [-91.47165563445634, 31.395976670956706],
              [-91.518316456364559, 31.46040614775147],
              [-91.521905750357504, 31.523976564855644],
              [-91.450119870498696, 31.539439639286385],
              [-91.407048342583423, 31.569506728457277],
              [-91.421405518555176, 31.596996638556377],
              [-91.486012810428093, 31.585828862578623],
              [-91.518316456364559, 31.611600653296527],
              [-91.500369986399861, 31.644244921539212],
              [-91.464477046470464, 31.620191250202495],
              [-91.399869754597546, 31.620191250202495],
              [-91.396280460604601, 31.711251577405768],
              [-91.356798226682258, 31.74131866657666],
              [-91.274244464844642, 31.745613965029648],
              [-91.259887288872889, 31.761077039460389],
              [-91.363976814668149, 31.770526696056955],
              [-91.33885175671756, 31.851278306973065],
              [-91.295780228802286, 31.86072796356963],
              [-91.28860164081641, 31.823788396873965],
              [-91.26347658286582, 31.809184382133814],
              [-91.245530112901122, 31.833238053470531],
              [-91.267065876858766, 31.851278306973065],
              [-91.180922821028204, 31.918284962839621],
              [-91.188101409014081, 31.961237947369469],
              [-91.162976351063506, 31.982714439634393],
              [-91.094779765197643, 31.994741275302751],
              [-91.094779765197643, 32.037694259832591],
              [-91.162976351063506, 32.060029811788112],
              [-91.145029881098807, 32.081506304053036],
              [-91.098369059190588, 32.048002976119754],
              [-91.08042258922589, 32.078929124981244],
              [-91.037351061310602, 32.097828438174375],
              [-91.00504741537415, 32.157103556825561],
              [-91.058886825268246, 32.177720989399887],
              [-91.051708237282369, 32.124459288582884],
              [-91.166565645056451, 32.133908945179449],
              [-91.15938705707056, 32.201774660736604],
              [-91.062476119261191, 32.21895585454854],
              [-91.037351061310602, 32.242150466194659],
              [-90.987100945409452, 32.21551961578615],
              [-90.969154475444753, 32.251600122791224],
              [-90.97633306343063, 32.297130286392857],
              [-90.933261535515356, 32.290257808868084],
              [-90.875832831628315, 32.358123524425238],
              [-90.890190007600069, 32.373586598855979],
              [-90.911725771557713, 32.339224211232107],
              [-90.994279533395328, 32.353828225972251],
              [-90.994279533395328, 32.403653688026871],
              [-90.965565181451808, 32.420834881838815],
              [-90.994279533395328, 32.450901971009706],
              [-91.030172473324726, 32.433720777197763],
              [-91.112726235162341, 32.475814702037013],
              [-91.101958353183534, 32.525640164091634],
              [-91.084011883218835, 32.526499223782231],
              [-91.022993885338849, 32.485264358633579],
              [-90.987100945409452, 32.491277776467761],
              [-91.00504741537415, 32.513613328423276],
              [-91.08042258922589, 32.556566312953123],
              [-91.030172473324726, 32.583197163361625],
              [-91.001458121381205, 32.614982371913712],
              [-91.026583179331794, 32.646767580465799],
              [-91.048118943289424, 32.607250834698341],
              [-91.119904823148232, 32.58491528274282],
              [-91.141440587105862, 32.596942118411178],
              [-91.152208469084684, 32.641613222322221],
              [-91.119904823148232, 32.674257490564898],
              [-91.062476119261191, 32.702606460354595],
              [-91.055297531275315, 32.725801072000714],
              [-91.166565645056451, 32.751572862718625],
              [-91.162976351063506, 32.812566100750999],
              [-91.145029881098807, 32.844351309303086],
              [-91.069654707247068, 32.889022413214128],
              [-91.062476119261191, 32.922525741147403],
              [-91.094779765197643, 32.984378038870382],
              [-91.134261999119985, 32.980941800107992],
              [-91.130672705127054, 32.923384800838001],
              [-91.180922821028204, 32.901049248882487],
              [-91.21322646696467, 32.919948562075618],
              [-91.202458584985848, 32.961183427224263],
              [-91.166565645056451, 33.004136411754111]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '30', name: 'Montana' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.047551604116038, 49.000686910359093],
            [-115.207656809768082, 48.998968790977905],
            [-114.374940603406031, 49.00154597004969],
            [-113.692974744747445, 48.99725067159671],
            [-113.01100888608886, 48.998968790977905],
            [-111.761934576545755, 48.99725067159671],
            [-111.00459354403543, 48.99725067159671],
            [-110.171877337673365, 48.998968790977905],
            [-108.994588907989069, 48.998968790977905],
            [-108.488498454984537, 48.998968790977905],
            [-107.44042460904609, 48.998968790977905],
            [-106.625654872648724, 48.999827850668495],
            [-106.112385831658315, 48.998968790977905],
            [-105.057133397733978, 48.998968790977905],
            [-104.647953882538815, 48.998968790977905],
            [-104.048541785717845, 48.999827850668495],
            [-104.048541785717845, 48.3890364106541],
            [-104.044952491724914, 48.374432395913949],
            [-104.044952491724914, 45.945011590905899],
            [-104.041363197731968, 45.750005041140405],
            [-104.041363197731968, 45.000904990939901],
            [-104.059309667696667, 44.997468752177511],
            [-104.472078476884761, 44.998327811868108],
            [-105.372991269112688, 45.000045931249304],
            [-105.846778076180755, 45.000045931249304],
            [-105.929331838018371, 44.994032513415128],
            [-106.568226168761683, 44.994891573105726],
            [-107.085084503745037, 44.996609692486921],
            [-107.135334619646187, 45.000045931249304],
            [-108.26955152141521, 45.000045931249304],
            [-109.062785493854932, 45.000045931249304],
            [-109.102267727777274, 45.006059349083486],
            [-109.798590762407613, 45.001764050630499],
            [-110.110859339793393, 45.003482170011694],
            [-110.200591689616886, 44.996609692486921],
            [-110.362109919299186, 45.000904990939901],
            [-110.401592153221529, 44.994032513415128],
            [-110.706682142621418, 44.992314394033933],
            [-110.785646610466102, 45.002623110321096],
            [-111.054843659936594, 45.000904990939901],
            [-111.05843295392954, 44.866891679206788],
            [-111.054843659936594, 44.624636846458458],
            [-111.047665071950718, 44.474301400603999],
            [-111.123040245802457, 44.494059773487727],
            [-111.144576009760087, 44.536153698326977],
            [-111.230719065590648, 44.586838220072195],
            [-111.223540477604772, 44.62377778676786],
            [-111.324040709407086, 44.724287770567699],
            [-111.385058707287072, 44.755213919429188],
            [-111.413773059230593, 44.710542815518146],
            [-111.485558939089387, 44.70796563644636],
            [-111.467612469124688, 44.679616666656656],
            [-111.525041173011729, 44.595428816978163],
            [-111.47120176311762, 44.540448996779958],
            [-111.586059170891701, 44.562784548735479],
            [-111.618362816828167, 44.549039593685933],
            [-111.704505872658714, 44.560207369663686],
            [-111.715273754637536, 44.543885235542348],
            [-111.822952574425742, 44.509522847918468],
            [-111.869613396333961, 44.563643608426077],
            [-111.948577864178631, 44.556771130901303],
            [-111.99523868608685, 44.53529463863638],
            [-112.031131626016247, 44.54646241461414],
            [-112.106506799867987, 44.520690623896229],
            [-112.135221151811507, 44.53958993708936],
            [-112.185471267712671, 44.532717459564587],
            [-112.228542795627945, 44.562784548735479],
            [-112.285971499514986, 44.568797966569662],
            [-112.318275145451452, 44.53873087739877],
            [-112.350578791387903, 44.53873087739877],
            [-112.357757379373794, 44.486328236272357],
            [-112.3864717313173, 44.447670550195497],
            [-112.472614787147862, 44.480314818438174],
            [-112.501329139091382, 44.463133624626238],
            [-112.540811373013725, 44.483751057200564],
            [-112.659258074780738, 44.485469176581759],
            [-112.720276072660724, 44.50436848977489],
            [-112.781294070540696, 44.484610116891162],
            [-112.835133480434791, 44.422757819168183],
            [-112.813597716477162, 44.37722765556655],
            [-112.845901362413613, 44.358328342373419],
            [-112.885383596335956, 44.396126968759681],
            [-112.949990888208873, 44.416744401334007],
            [-113.003830298102969, 44.45110678895788],
            [-113.025366062060613, 44.49663695255952],
            [-113.007419592095914, 44.525844982039814],
            [-113.082794765947654, 44.595428816978163],
            [-113.050491120011188, 44.636663682126816],
            [-113.068437589975886, 44.679616666656656],
            [-113.133044881848818, 44.773254172931722],
            [-113.247902289622886, 44.823079634986343],
            [-113.341223933439323, 44.78528100860008],
            [-113.355581109411091, 44.819643396223952],
            [-113.456081341213405, 44.865173559825593],
            [-113.495563575135748, 44.948502349813488],
            [-113.445313459234583, 44.959670125791249],
            [-113.438134871248707, 45.006918408774084],
            [-113.45249204722046, 45.05932104990049],
            [-113.520688633086323, 45.092824377833772],
            [-113.513510045100446, 45.115159929789293],
            [-113.574528042980418, 45.128045825148241],
            [-113.599653100931008, 45.190757182561818],
            [-113.685796156761555, 45.253468539975394],
            [-113.6893854507545, 45.283535629146286],
            [-113.739635566655664, 45.329924852438516],
            [-113.732456978669774, 45.3900590307803],
            [-113.761171330613294, 45.406381164901639],
            [-113.782707094570938, 45.455347567265662],
            [-113.761171330613294, 45.481119357983573],
            [-113.76476062460624, 45.520636103751031],
            [-113.832957210472102, 45.520636103751031],
            [-113.807832152521513, 45.602246774357738],
            [-113.861671562415623, 45.623723266622662],
            [-113.904743090330896, 45.622005147241467],
            [-113.89756450234502, 45.644340699196988],
            [-113.933457442274417, 45.68213932558325],
            [-113.987296852168512, 45.704474877538765],
            [-114.019600498104978, 45.693307101561011],
            [-114.012421910119087, 45.658085654246534],
            [-114.066261320013197, 45.628018565075642],
            [-114.134457905879046, 45.557575670446695],
            [-114.202654491744909, 45.535240118491174],
            [-114.249315313653128, 45.545548834778337],
            [-114.26008319563195, 45.495723372723717],
            [-114.346226251462511, 45.459642865718649],
            [-114.364172721427209, 45.490569014580139],
            [-114.41442283732836, 45.50946832777327],
            [-114.461083659236579, 45.561011909209086],
            [-114.550816009060085, 45.561011909209086],
            [-114.536458833088318, 45.606542072810718],
            [-114.565173185031838, 45.637468221672208],
            [-114.500565893158921, 45.669253430224295],
            [-114.49697659916599, 45.710488295372947],
            [-114.565173185031838, 45.774058712477114],
            [-114.500565893158921, 45.850515024940243],
            [-114.410833543335428, 45.851374084630841],
            [-114.385708485384853, 45.889172711017103],
            [-114.432369307293072, 45.935561934309334],
            [-114.410833543335428, 45.977655859148584],
            [-114.479030129201291, 46.000850470794703],
            [-114.493387305173044, 46.047239694086933],
            [-114.461083659236579, 46.097065156141554],
            [-114.522101657116565, 46.12541412593125],
            [-114.514923069130688, 46.1675080507705],
            [-114.446726483264825, 46.173521468604676],
            [-114.450315777257771, 46.241387184161837],
            [-114.471851541215401, 46.267158974879742],
            [-114.425190719307182, 46.287776407454068],
            [-114.42160142531425, 46.387427331563309],
            [-114.374940603406031, 46.443266211452105],
            [-114.400065661356606, 46.502541330103291],
            [-114.356994133441333, 46.505118509175084],
            [-114.321101193511922, 46.610782851118501],
            [-114.331869075490744, 46.660608313173121],
            [-114.360583427434264, 46.669198910079096],
            [-114.453905071250702, 46.649440537195368],
            [-114.468262247222469, 46.631400283692827],
            [-114.54722671506714, 46.644286179051782],
            [-114.615423300933003, 46.639990880598802],
            [-114.644137652876523, 46.673494208532077],
            [-114.622601888918879, 46.706997536465359],
            [-114.647726946869469, 46.732769327183263],
            [-114.697977062770619, 46.740500864398633],
            [-114.712334238742386, 46.714729073680729],
            [-114.766173648636482, 46.696688820178196],
            [-114.787709412594126, 46.711292834918339],
            [-114.766173648636482, 46.758541117901174],
            [-114.88820964439644, 46.808366579955795],
            [-114.945638348283481, 46.859051101701006],
            [-114.931281172311714, 46.920044339733387],
            [-115.003067052170522, 46.971587921169203],
            [-115.049727874078741, 46.970728861478605],
            [-115.07126363803637, 47.022272442914421],
            [-115.139460223902233, 47.092715337543368],
            [-115.20047822178222, 47.139104560835598],
            [-115.243549749697493, 47.150272336813359],
            [-115.261496219662192, 47.182057545365446],
            [-115.30097845358452, 47.188070963199621],
            [-115.293799865598658, 47.220715231442306],
            [-115.326103511535109, 47.255936678756782],
            [-115.412246567365656, 47.264527275662751],
            [-115.527103975139738, 47.303184961739611],
            [-115.552229033090327, 47.349574185031841],
            [-115.720925850758505, 47.424312378113775],
            [-115.760408084680847, 47.42259425873258],
            [-115.717336556765559, 47.45352040759407],
            [-115.638372088920875, 47.460392885118843],
            [-115.627604206942067, 47.479292198311974],
            [-115.685032910829108, 47.485305616146157],
            [-115.702979380793806, 47.53427201851018],
            [-115.742461614716149, 47.53856731696316],
            [-115.735283026730258, 47.567775346443455],
            [-115.688622204822039, 47.595265256542561],
            [-115.731693732737313, 47.642513539525389],
            [-115.72451514475145, 47.69663430003299],
            [-115.771175966659655, 47.717251732607316],
            [-115.796301024610244, 47.757627538065371],
            [-115.82501537655375, 47.752473179921793],
            [-115.853729728497285, 47.828070432694318],
            [-115.907569138391381, 47.846110686196852],
            [-116.047551604116038, 47.976687759167582],
            [-116.051140898108969, 48.216365412844119],
            [-116.047551604116038, 48.310002919119185],
            [-116.047551604116038, 49.000686910359093]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '33', name: 'New Hampshire' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.500823857738581, 45.013790886298857],
            [-71.490055975759759, 45.072206945259445],
            [-71.425448683886842, 45.127186765457644],
            [-71.436216565865664, 45.1400726608166],
            [-71.396734331943321, 45.203643077920773],
            [-71.44339515385154, 45.234569226782263],
            [-71.378787861978623, 45.244018883378828],
            [-71.364430686006855, 45.266354435334343],
            [-71.285466218162185, 45.302434942339417],
            [-71.231626808268075, 45.250032301213004],
            [-71.131126576465761, 45.245737002760023],
            [-71.084465754557542, 45.305871181101807],
            [-71.030626344663446, 44.655562995319947],
            [-70.991144110741104, 43.915912601716009],
            [-70.991144110741104, 43.839456289252887],
            [-70.973197640776405, 43.570570606096055],
            [-70.951661876818761, 43.550812233212326],
            [-70.973197640776405, 43.475214980439794],
            [-70.958840464804652, 43.466624383533826],
            [-70.987554816748172, 43.379859354783541],
            [-70.955251170811707, 43.334329191181908],
            [-70.808090117101173, 43.224369550785504],
            [-70.829625881058803, 43.188289043780429],
            [-70.829625881058803, 43.129013925129243],
            [-70.704000591305913, 43.059430090190894],
            [-70.811679411094104, 42.909953704027032],
            [-70.818857999079995, 42.87215507764077],
            [-70.847572351023516, 42.860987301663009],
            [-70.901411760917611, 42.886759092380913],
            [-71.030626344663446, 42.859269182281814],
            [-71.062929990599898, 42.80600748146481],
            [-71.131126576465761, 42.821470555895552],
            [-71.184965986359856, 42.790544407034062],
            [-71.181376692366925, 42.737282706217059],
            [-71.256751866218664, 42.736423646526461],
            [-71.292644806148061, 42.696906900759004],
            [-71.744895849258498, 42.707215617046167],
            [-72.459165353853535, 42.726973989929895],
            [-72.491468999790001, 42.772504153531528],
            [-72.513004763747631, 42.76391355662556],
            [-72.556076291662919, 42.851537645066443],
            [-72.523772645726453, 42.91424900248002],
            [-72.520183351733522, 42.963215404844043],
            [-72.473522529825303, 42.971806001750011],
            [-72.444808177881782, 43.010463687826871],
            [-72.466343941839412, 43.055134791737913],
            [-72.43404029590296, 43.08348376152761],
            [-72.451986765867659, 43.139322641416406],
            [-72.437629589895892, 43.257872878718779],
            [-72.394558061980618, 43.312852698916984],
            [-72.416093825938262, 43.3643962803528],
            [-72.380200886008865, 43.48036933858338],
            [-72.398147355973563, 43.510436427754271],
            [-72.380200886008865, 43.574006844858438],
            [-72.326361476114755, 43.602355814648142],
            [-72.304825712157125, 43.695993320923201],
            [-72.27252206622066, 43.733791947309463],
            [-72.204325480354797, 43.770731514005135],
            [-72.182789716397167, 43.808530140391397],
            [-72.186379010390098, 43.863509960589596],
            [-72.11818242452425, 43.921066959859594],
            [-72.11818242452425, 43.994087033560326],
            [-72.075110896608962, 44.031885659946589],
            [-72.032039368693688, 44.091160778597782],
            [-72.032039368693688, 44.13153658405583],
            [-72.067932308623085, 44.189952643016426],
            [-72.046396544665441, 44.238919045380449],
            [-72.067932308623085, 44.268127074860743],
            [-72.032039368693688, 44.320529715987149],
            [-71.906414078940784, 44.348019626086256],
            [-71.870521139011387, 44.336851850108495],
            [-71.813092435124346, 44.354892103611029],
            [-71.795145965159648, 44.399563207522071],
            [-71.709002909329087, 44.411590043190422],
            [-71.658752793427936, 44.445093371123704],
            [-71.633627735477347, 44.483751057200564],
            [-71.57619903159032, 44.502650370393695],
            [-71.59773479554795, 44.553334892138913],
            [-71.558252561625608, 44.564502668116674],
            [-71.551073973639731, 44.62721402553025],
            [-71.586966913569128, 44.659858293772928],
            [-71.626449147491471, 44.747482382213818],
            [-71.572609737597375, 44.792153486124853],
            [-71.558252561625608, 44.847133306323059],
            [-71.49364526975269, 44.911562783117823],
            [-71.540306091660909, 44.988019095580945],
            [-71.500823857738581, 45.013790886298857]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '36', name: 'New York' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-72.035628662686619, 41.25540473993739],
              [-72.024860780707812, 41.270867814368138],
              [-71.927949842898428, 41.289767127561269],
              [-72.003325016750168, 41.252827560865605],
              [-72.035628662686619, 41.25540473993739]
            ]
          ],
          [
            [
              [-72.143307482474825, 41.098196816558158],
              [-72.125361012510126, 41.115378010370094],
              [-72.085878778587784, 41.101633055320548],
              [-72.085878778587784, 41.0586800707907],
              [-72.107414542545428, 41.088747159961592],
              [-72.143307482474825, 41.098196816558158]
            ]
          ],
          [
            [
              [-72.215093362333619, 41.183243725927255],
              [-72.157664658446578, 41.196988680976801],
              [-72.200736186361866, 41.160049114281136],
              [-72.215093362333619, 41.183243725927255]
            ]
          ],
          [
            [
              [-74.070758356683569, 40.660935434044333],
              [-74.052811886718871, 40.603378434774342],
              [-74.085115532655323, 40.56987510684106],
              [-74.221508704387048, 40.502868450974503],
              [-74.260990938309376, 40.502009391283906],
              [-74.250223056330555, 40.544962375813753],
              [-74.217919410394103, 40.558707330863299],
              [-74.203562234422336, 40.630868344873441],
              [-74.070758356683569, 40.660935434044333]
            ]
          ],
          [
            [
              [-76.375085100150997, 43.874677736567357],
              [-76.317656396263956, 43.917630721097204],
              [-76.335602866228655, 43.878973035020344],
              [-76.375085100150997, 43.874677736567357]
            ]
          ],
          [
            [
              [-76.454049567995682, 43.890999870688702],
              [-76.410978040080394, 43.925362258312575],
              [-76.378674394143943, 43.921066959859594],
              [-76.454049567995682, 43.890999870688702]
            ]
          ],
          [
            [
              [-79.763378629486297, 42.269954234532335],
              [-79.64493192771927, 42.315484398133975],
              [-79.454699346093463, 42.410840023790229],
              [-79.350609820298203, 42.489014455634546],
              [-79.242931000509998, 42.531967440164394],
              [-79.14960935669356, 42.553443932429317],
              [-79.110127122771232, 42.613578110771101],
              [-79.063466300863013, 42.644504259632591],
              [-79.049109124891245, 42.689175363543626],
              [-78.91989454114541, 42.737282706217059],
              [-78.851697955279548, 42.783671929509289],
              [-78.866055131251315, 42.852396704757041],
              [-78.912715953159534, 42.886759092380913],
              [-78.905537365173643, 42.923698659076585],
              [-78.930662423124232, 42.955483867628672],
              [-79.013216184961848, 42.985550956799564],
              [-79.009626890968903, 43.064584448334479],
              [-79.07423418284182, 43.077470343693427],
              [-79.041930536905369, 43.143617939869394],
              [-79.059877006870067, 43.251000401194005],
              [-79.070644888848889, 43.262168177171766],
              [-78.970144657046575, 43.281067490364897],
              [-78.833751485314849, 43.317147997369965],
              [-78.63275102171022, 43.35752380282802],
              [-78.489179261992618, 43.374704996639956],
              [-78.370732560225605, 43.376423116021151],
              [-78.234339388493879, 43.36869157880578],
              [-78.105124804748044, 43.375564056330553],
              [-77.79644552135521, 43.339483549325486],
              [-77.713891759517594, 43.323161415204147],
              [-77.660052349623498, 43.282785609746092],
              [-77.577498587785882, 43.243268863978635],
              [-77.534427059870595, 43.234678267072667],
              [-77.390855300152992, 43.27677219191191],
              [-77.265230010400103, 43.277631251602507],
              [-77.110890368703679, 43.28793996788967],
              [-77.035515194851939, 43.271617833768332],
              [-76.952961433014323, 43.270758774077734],
              [-76.902711317113173, 43.292235266342658],
              [-76.795032497324968, 43.309416460154594],
              [-76.73042520545205, 43.343778847778474],
              [-76.698121559515599, 43.344637907469071],
              [-76.629924973649736, 43.413362682716823],
              [-76.522246153861531, 43.468342502915021],
              [-76.486353213932134, 43.475214980439794],
              [-76.418156628066285, 43.521604203732032],
              [-76.367906512165121, 43.525899502185013],
              [-76.296120632306327, 43.513013606826064],
              [-76.23510263442634, 43.529335740947403],
              [-76.202798988489889, 43.574865904549036],
              [-76.195620400503998, 43.64960409763097],
              [-76.227924046440464, 43.80423484193841],
              [-76.299709926299258, 43.838597229562289],
              [-76.260227692376915, 43.881550214092137],
              [-76.209977576475765, 43.890999870688702],
              [-76.202798988489889, 43.862650900898998],
              [-76.127423814638149, 43.897872348213475],
              [-76.138191696616957, 43.93481191490914],
              [-76.20638828248282, 43.975187720367195],
              [-76.285352750327505, 43.96230182500824],
              [-76.267406280362806, 43.987214556035553],
              [-76.321245690256902, 44.031026600255998],
              [-76.375085100150997, 44.031885659946589],
              [-76.371495806158066, 44.100610435194348],
              [-76.314067102271025, 44.199402299612991],
              [-76.245870516405162, 44.203697598065972],
              [-76.163316754567546, 44.239778105071046],
              [-76.163316754567546, 44.281012970219692],
              [-76.098709462694629, 44.29991228341283],
              [-76.001798524885245, 44.347160566395658],
              [-75.969494878948794, 44.34286526794267],
              [-75.912066175061753, 44.367777998969984],
              [-75.822333825238246, 44.432207475764748],
              [-75.807976649266493, 44.471724221532206],
              [-75.764905121351205, 44.515536265752651],
              [-75.617744067640672, 44.619482488314873],
              [-75.506475953859535, 44.705388457374568],
              [-75.305475490254906, 44.826515873748733],
              [-75.309064784247838, 44.836824590035896],
              [-75.064992792727921, 44.930462096310954],
              [-74.971671148911483, 44.983723797127965],
              [-74.907063857038565, 44.983723797127965],
              [-74.828099389193895, 45.015509005680052],
              [-74.731188451384511, 44.990596274652738],
              [-74.662991865518649, 44.999186871558706],
              [-74.336366112161116, 44.992314394033933],
              [-74.146133530535309, 44.991455334343335],
              [-73.762079073290735, 45.003482170011694],
              [-73.675936017460174, 45.002623110321096],
              [-73.342131676116765, 45.011213707227064],
              [-73.338542382123819, 44.917576200952006],
              [-73.363667440074394, 44.891804410234094],
              [-73.378024616046162, 44.837683649726493],
              [-73.331363794137943, 44.788717247362463],
              [-73.36725673406734, 44.741468964379635],
              [-73.374435322053216, 44.66243547284472],
              [-73.388792498024984, 44.635804622436218],
              [-73.374435322053216, 44.575670444094435],
              [-73.306238736187353, 44.500073191321903],
              [-73.2918815602156, 44.440798072670717],
              [-73.334953088130874, 44.372073297422965],
              [-73.309828030180299, 44.274140492694919],
              [-73.324185206152066, 44.243214343833429],
              [-73.392381792017915, 44.189093583325828],
              [-73.410328261982613, 44.112637270862699],
              [-73.439042613926134, 44.044771555305545],
              [-73.406738967989682, 44.021576943659433],
              [-73.410328261982613, 43.933093795527945],
              [-73.374435322053216, 43.875536796257954],
              [-73.392381792017915, 43.820556976059756],
              [-73.349310264102641, 43.771590573695732],
              [-73.406738967989682, 43.68826178370783],
              [-73.431864025940257, 43.590328978979784],
              [-73.39597108601086, 43.567993427024263],
              [-73.370846028060285, 43.624691366603656],
              [-73.302649442194422, 43.624691366603656],
              [-73.295470854208546, 43.579161203002023],
              [-73.248810032300327, 43.554248471974709],
              [-73.255988620286203, 43.259590998099974],
              [-73.277524384243847, 42.897067808668076],
              [-73.277524384243847, 42.83349739156391],
              [-73.2918815602156, 42.801712183011823],
              [-73.26316720827208, 42.745873303123027],
              [-73.385203204032038, 42.425444038530379],
              [-73.507239199791996, 42.086115460744601],
              [-73.485703435834353, 42.050034953739534],
              [-73.518007081770818, 41.687511764307636],
              [-73.535953551735517, 41.434089155581546],
              [-73.55031072770727, 41.295780545395445],
              [-73.482114141841421, 41.21245175540755],
              [-73.726186133361338, 41.10077399562995],
              [-73.65440025350253, 41.013149907189067],
              [-73.657989547495475, 40.98480093739937],
              [-73.68311460544605, 40.948720430394296],
              [-73.75848977929779, 40.912639923389229],
              [-73.765668367283666, 40.844774207832074],
              [-73.812329189191885, 40.846492327213262],
              [-73.780025543255434, 40.794948745777454],
              [-73.715418251382516, 40.869686938859381],
              [-73.675936017460174, 40.856801043500425],
              [-73.640043077530777, 40.8928815505055],
              [-73.564667903679037, 40.916076162151612],
              [-73.50006061180612, 40.917794281532807],
              [-73.485703435834353, 40.946143251322503],
              [-73.406738967989682, 40.920371460604599],
              [-73.399560380003791, 40.955592907919069],
              [-73.36725673406734, 40.93153923658236],
              [-73.295470854208546, 40.924666759057587],
              [-73.230863562335628, 40.904908386173858],
              [-73.148309800497998, 40.928962057510567],
              [-73.141131212512121, 40.965901624206232],
              [-73.080113214632149, 40.972774101731012],
              [-73.044220274702752, 40.962465385443849],
              [-72.775023225232246, 40.965042564515642],
              [-72.635040759507589, 40.982223758327578],
              [-72.584790643606439, 40.997686832758319],
              [-72.477111823818234, 41.051807593265927],
              [-72.444808177881782, 41.0861699808898],
              [-72.401736649966494, 41.09733775686756],
              [-72.355075828058276, 41.140290741397408],
              [-72.279700654206536, 41.158330994899941],
              [-72.236629126291263, 41.156612875518746],
              [-72.30123641816418, 41.111941771607711],
              [-72.333540064100646, 41.106787413464126],
              [-72.279700654206536, 41.080156563055624],
              [-72.261754184241838, 41.042357936669362],
              [-72.200736186361866, 41.032049220382198],
              [-72.161253952439523, 41.053525712647122],
              [-72.103825248552482, 40.991673414924144],
              [-72.049985838658387, 41.006277429664287],
              [-71.967432076820771, 41.04751229481294],
              [-71.96025348883488, 41.071565966149656],
              [-71.899235490954908, 41.081015622746222],
              [-71.856163963039634, 41.070706906459058],
              [-71.874110433004333, 41.052666652956525],
              [-72.114593130531304, 40.971915042040415],
              [-72.394558061980618, 40.866250700096991],
              [-72.574022761627617, 40.812988999279987],
              [-72.864755575055753, 40.733096448054475],
              [-73.05498815668156, 40.666089792187918],
              [-73.209327798377984, 40.630868344873441],
              [-73.306238736187353, 40.620559628586278],
              [-73.327774500144997, 40.633445523945234],
              [-73.557489315693161, 40.581901942509418],
              [-73.776436249262488, 40.590492539415386],
              [-73.941543772937735, 40.543244256432558],
              [-73.930775890958913, 40.575888524675243],
              [-74.013329652796529, 40.574170405294048],
              [-74.002561770817707, 40.595646897558971],
              [-74.042044004740049, 40.629150225492246],
              [-74.01691894678946, 40.659217314663138],
              [-74.04563329873298, 40.690143463524628],
              [-74.02409753477535, 40.709042776717759],
              [-74.009740358803583, 40.764881656606562],
              [-73.96666883088831, 40.820720536495358],
              [-73.894882951029501, 40.98136469863698],
              [-73.905650833008323, 40.997686832758319],
              [-74.379437640076404, 41.209015516645159],
              [-74.695295511455114, 41.357632843118424],
              [-74.741956333363333, 41.404881126101252],
              [-74.738367039370388, 41.430652916819163],
              [-74.795795743257429, 41.423780439294383],
              [-74.806563625236251, 41.442679752487521],
              [-74.896295975059743, 41.440102573415729],
              [-74.889117387073867, 41.45556564784647],
              [-74.942956796967962, 41.483914617636167],
              [-74.982439030890305, 41.479619319183186],
              [-74.98602832488325, 41.50882734866348],
              [-75.075760674706743, 41.605901093700929],
              [-75.047046322763222, 41.616209809988092],
              [-75.054224910749099, 41.752800300792998],
              [-75.104475026650263, 41.770840554295539],
              [-75.075760674706743, 41.798330464394638],
              [-75.11165361463614, 41.840424389233888],
              [-75.208564552445523, 41.869632418714183],
              [-75.262403962339619, 41.866196179951793],
              [-75.291118314283139, 41.95210214901148],
              [-75.309064784247838, 41.948665910249098],
              [-75.359314900149002, 41.999350431994316],
              [-75.743369357393576, 41.997632312613121],
              [-76.349960042200422, 41.998491372303718],
              [-76.748371675416749, 42.001927611066101],
              [-77.125247544675446, 41.999350431994316],
              [-77.997445984959853, 41.998491372303718],
              [-78.596858081780809, 42.000209491684913],
              [-78.873233719237192, 41.997632312613121],
              [-79.759789335493352, 41.998491372303718],
              [-79.763378629486297, 42.000209491684913],
              [-79.763378629486297, 42.269954234532335]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '39', name: 'Ohio' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.735314055640558, 41.603323914629136],
              [-82.677885351753517, 41.617927929369287],
              [-82.685063939739393, 41.587001780507798],
              [-82.735314055640558, 41.603323914629136]
            ]
          ],
          [
            [
              [-82.842992875428749, 41.647135958849582],
              [-82.821457111471105, 41.724451331003301],
              [-82.781974877548777, 41.69438424183241],
              [-82.792742759527599, 41.664317152661518],
              [-82.842992875428749, 41.62823664565645],
              [-82.842992875428749, 41.647135958849582]
            ]
          ],
          [
            [
              [-84.806336689566891, 41.696102361213605],
              [-84.361264234442345, 41.706411077500768],
              [-83.761852137621375, 41.723592271312704],
              [-83.453172854228541, 41.733041927909269],
              [-83.449583560235595, 41.710706375953755],
              [-83.374208386383856, 41.686652704617039],
              [-83.327547564475637, 41.70125671935719],
              [-83.194743686736871, 41.630813824728243],
              [-83.105011336913364, 41.6136326309163],
              [-83.065529102991022, 41.595592377413766],
              [-83.000921811118104, 41.538894437834372],
              [-82.932725225252256, 41.513981706807058],
              [-82.868117933379324, 41.533740079690787],
              [-82.860939345393447, 41.576693064220635],
              [-82.835814287442872, 41.587860840198395],
              [-82.785564171541708, 41.540612557215567],
              [-82.717367585675859, 41.542330676596762],
              [-82.72095687966879, 41.510545468044675],
              [-82.616867353873531, 41.428075737747371],
              [-82.559438649986504, 41.399726767957674],
              [-82.480474182141819, 41.38168651445514],
              [-82.333313128431286, 41.430652916819163],
              [-82.254348660586601, 41.434089155581546],
              [-82.193330662706629, 41.464156244752438],
              [-82.010276669066684, 41.515699826188253],
              [-81.877472791327904, 41.483055557945569],
              [-81.809276205462055, 41.495941453304525],
              [-81.737490325603247, 41.488209916089154],
              [-81.633400799808001, 41.540612557215567],
              [-81.529311274012741, 41.614491690606897],
              [-81.500596922069221, 41.623082287512865],
              [-81.44316821818218, 41.672907749567486],
              [-81.285239282492824, 41.760531838008376],
              [-81.249346342563427, 41.761390897698973],
              [-81.008863645036442, 41.854169344283434],
              [-80.901184825248251, 41.868773359023585],
              [-80.815041769417689, 41.897981388503879],
              [-80.581737659876595, 41.957256507155066],
              [-80.520719661996623, 41.977873939729392],
              [-80.520719661996623, 40.638599882088812],
              [-80.58532695386954, 40.6154052704427],
              [-80.63557706977069, 40.616264330133298],
              [-80.667880715707156, 40.57331134560345],
              [-80.628398481784814, 40.535512719217188],
              [-80.596094835848362, 40.463351705207046],
              [-80.610452011820115, 40.402358467174665],
              [-80.599684129841293, 40.320747796567957],
              [-80.617630599805992, 40.264908916679161],
              [-80.653523539735389, 40.245150543795432],
              [-80.671470009700087, 40.192747902669019],
              [-80.707362949629498, 40.151513037520367],
              [-80.707362949629498, 40.101687575465746],
              [-80.73966659556595, 40.075915784747842],
              [-80.73966659556595, 39.971110502495016],
              [-80.764791653516539, 39.95392930868308],
              [-80.757613065530649, 39.909258204772044],
              [-80.804273887438868, 39.91870786136861],
              [-80.789916711467114, 39.867164279932794],
              [-80.825809651396511, 39.847405907049065],
              [-80.825809651396511, 39.798439504685042],
              [-80.868881179311785, 39.75720463953639],
              [-80.829398945389457, 39.714251655006542],
              [-80.865291885318854, 39.691916103051021],
              [-80.879649061290607, 39.620614148731484],
              [-80.937077765177648, 39.612882611516106],
              [-81.044756584965853, 39.536426299052984],
              [-81.10218528885288, 39.487459896688961],
              [-81.1308996407964, 39.446225031540308],
              [-81.170381874718743, 39.439352554015535],
              [-81.209864108641085, 39.392963330723298],
              [-81.270882106521057, 39.386090853198525],
              [-81.346257280372797, 39.34571504774047],
              [-81.385739514295139, 39.343137868668677],
              [-81.407275278252783, 39.38780897257972],
              [-81.468293276132755, 39.404131106701058],
              [-81.558025625956262, 39.338842570215697],
              [-81.568793507935069, 39.267540615896152],
              [-81.68006162171622, 39.273554033730328],
              [-81.690829503695028, 39.2263057507475],
              [-81.726722443624439, 39.215997034460337],
              [-81.75543679556796, 39.180775587145867],
              [-81.744668913589138, 39.098305856848562],
              [-81.812865499454986, 39.081983722727223],
              [-81.802097617476178, 39.045044156031551],
              [-81.766204677546767, 39.020131425004244],
              [-81.780561853518535, 38.924775799347984],
              [-81.812865499454986, 38.946252291612907],
              [-81.845169145391452, 38.929071097800971],
              [-81.855937027370274, 38.892990590795904],
              [-81.888240673306726, 38.874091277602773],
              [-81.927722907229068, 38.891272471414709],
              [-81.899008555285548, 38.925634859038581],
              [-81.942080083200835, 38.993500574595743],
              [-81.981562317123164, 38.994359634286333],
              [-82.028223139031383, 39.028722021910212],
              [-82.049758902989026, 38.996936813358126],
              [-82.0928304309043, 38.973742201712014],
              [-82.143080546805464, 38.898144948939482],
              [-82.143080546805464, 38.840587949669491],
              [-82.218455720657204, 38.795916845758448],
              [-82.182562780727807, 38.705715578245773],
              [-82.171794898748985, 38.618950549495487],
              [-82.193330662706629, 38.593178758777583],
              [-82.25075936659367, 38.599192176611758],
              [-82.293830894508943, 38.575138505275049],
              [-82.304598776487765, 38.490950655596549],
              [-82.322545246452464, 38.448856730757299],
              [-82.40509900829008, 38.439407074160734],
              [-82.530724298042983, 38.405044686536861],
              [-82.595331589915901, 38.422225880348797],
              [-82.602510177901777, 38.46002450673506],
              [-82.649170999809996, 38.490091595905952],
              [-82.724546173661736, 38.557957311463106],
              [-82.799921347513475, 38.563111669606691],
              [-82.846582169421694, 38.594896878158778],
              [-82.878885815358146, 38.690252503815032],
              [-82.868117933379324, 38.728051130201294],
              [-82.893242991329913, 38.756400099990991],
              [-82.968618165181653, 38.728910189891891],
              [-83.022457575075748, 38.728910189891891],
              [-83.054761221012214, 38.69540686195861],
              [-83.119368512885131, 38.666198832478315],
              [-83.155261452814528, 38.620668668876682],
              [-83.262940272602719, 38.62152772856728],
              [-83.309601094510938, 38.600910295992954],
              [-83.327547564475637, 38.637849862688618],
              [-83.367029798397979, 38.658467295262945],
              [-83.467530030200294, 38.675648489074881],
              [-83.521369440094404, 38.703138399173987],
              [-83.614691083910841, 38.684239085980856],
              [-83.664941199811992, 38.627541146401455],
              [-83.765441431614306, 38.652453877428769],
              [-83.783387901579019, 38.69540686195861],
              [-83.837227311473114, 38.717742413914131],
              [-83.847995193451936, 38.746950443394425],
              [-83.952084719247196, 38.786467189161883],
              [-84.070531421014209, 38.770145055040544],
              [-84.214103180731797, 38.805366502355014],
              [-84.23204965069651, 38.827702054310535],
              [-84.23204965069651, 38.884399993889936],
              [-84.289478354583537, 38.955701948209473],
              [-84.325371294512948, 39.027862962219615],
              [-84.429460820308194, 39.055352872318714],
              [-84.443817996279961, 39.1146279909699],
              [-84.472532348223481, 39.121500468494681],
              [-84.501246700167002, 39.096587737467367],
              [-84.551496816068152, 39.099164916539159],
              [-84.601746931969316, 39.074252185511845],
              [-84.677122105821056, 39.098305856848562],
              [-84.730961515715151, 39.144695080140792],
              [-84.756086573665741, 39.146413199521987],
              [-84.820693865538658, 39.105178334373335],
              [-84.820693865538658, 39.309634540735402],
              [-84.813515277552767, 39.548453134721342],
              [-84.813515277552767, 39.927298458274578],
              [-84.802747395573959, 40.302707543065424],
              [-84.802747395573959, 41.488209916089154],
              [-84.806336689566891, 41.696102361213605]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '41', name: 'Oregon' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.549176049360483, 46.259427437664371],
            [-123.473800875508744, 46.268018034570339],
            [-123.427140053600539, 46.229360348493479],
            [-123.43072934759347, 46.182112065510644],
            [-123.369711349713498, 46.146031558505577],
            [-123.279978999889991, 46.145172498814979],
            [-123.165121592115923, 46.188984543035424],
            [-123.114871476214745, 46.185548304273034],
            [-122.903103130631308, 46.083320201092],
            [-122.877978072680719, 46.030917559965594],
            [-122.813370780807801, 45.961333725027245],
            [-122.80978148681487, 45.912367322663222],
            [-122.784656428864281, 45.86769621875218],
            [-122.795424310843103, 45.810139219482188],
            [-122.759531370913692, 45.734541966709656],
            [-122.773888546885459, 45.699320519395187],
            [-122.763120664906637, 45.656367534865339],
            [-122.644673963139624, 45.609978311573109],
            [-122.479566439464378, 45.579911222402217],
            [-122.44008420554205, 45.563589088280878],
            [-122.379066207662078, 45.575615923949229],
            [-122.332405385753844, 45.54812601385013],
            [-122.267798093880941, 45.543830715397149],
            [-122.185244332043311, 45.577334043330424],
            [-122.102690570205695, 45.583347461164607],
            [-121.908868694586943, 45.654649415484144],
            [-121.865797166671655, 45.693307101561011],
            [-121.811957756777559, 45.707052056610557],
            [-121.73658258292582, 45.694166161251609],
            [-121.632493057130574, 45.704474877538765],
            [-121.531992825328246, 45.726810429494286],
            [-121.463796239462397, 45.701038638776382],
            [-121.402778241582411, 45.693307101561011],
            [-121.338170949709479, 45.705333937229362],
            [-121.216134953949535, 45.67097154960549],
            [-121.183831308013083, 45.606542072810718],
            [-121.133581192111905, 45.609978311573109],
            [-121.065384606246056, 45.652931296102956],
            [-120.943348610486098, 45.656367534865339],
            [-120.896687788577879, 45.642622579815793],
            [-120.857205554655536, 45.671830609296087],
            [-120.688508736987359, 45.715642653516525],
            [-120.634669327093263, 45.745709742687417],
            [-120.559294153241524, 45.737978205472047],
            [-120.483918979389784, 45.694166161251609],
            [-120.4049545115451, 45.699320519395187],
            [-120.282918515785155, 45.721656071350708],
            [-120.211132635926361, 45.725951369803688],
            [-120.171650402004019, 45.762031876808763],
            [-120.07115017020169, 45.785226488454875],
            [-119.967060644406445, 45.824743234222332],
            [-119.801953120731199, 45.847937845868451],
            [-119.669149242992432, 45.856528442774419],
            [-119.622488421084199, 45.905494845138442],
            [-119.572238305183049, 45.925253218022171],
            [-119.486095249352488, 45.906353904829039],
            [-119.256380433804338, 45.939857232762321],
            [-119.195362435924352, 45.927830397093963],
            [-119.127165850058503, 45.932984755237548],
            [-119.026665618256175, 45.969065262242616],
            [-118.987183384333832, 45.999991411104105],
            [-118.40212846348463, 46.000850470794703],
            [-117.504804965249647, 45.99827329172291],
            [-116.916160750407499, 45.995696112651117],
            [-116.858732046520458, 45.903776725757247],
            [-116.819249812598116, 45.880582114111135],
            [-116.761821108711075, 45.816152637316364],
            [-116.697213816838172, 45.820447935769351],
            [-116.661320876908761, 45.780072130311297],
            [-116.593124291042898, 45.778354010930101],
            [-116.535695587155857, 45.737119145781449],
            [-116.535695587155857, 45.691588982179816],
            [-116.489034765247652, 45.649495057340566],
            [-116.463909707297063, 45.603105834048336],
            [-116.550052763127624, 45.510327387463867],
            [-116.553642057120555, 45.463079104481039],
            [-116.589534997049967, 45.443320731597311],
            [-116.675678052880528, 45.314461778007775],
            [-116.672088758887583, 45.283535629146286],
            [-116.704392404824034, 45.239723584925841],
            [-116.729517462774623, 45.1400726608166],
            [-116.776178284682842, 45.105710273192727],
            [-116.797714048640472, 45.060180109591087],
            [-116.847964164541636, 45.022381483204825],
            [-116.858732046520458, 44.97856943898438],
            [-116.833606988569869, 44.928743976929759],
            [-116.865910634506335, 44.870327917969171],
            [-116.930517926379252, 44.805039381483809],
            [-116.930517926379252, 44.786999127981275],
            [-117.045375334153334, 44.744905203142025],
            [-117.095625450054484, 44.665012651916513],
            [-117.124339801998019, 44.58168386192861],
            [-117.160232741927416, 44.524985922349217],
            [-117.224840033800334, 44.483751057200564],
            [-117.214072151821512, 44.42705311762117],
            [-117.242786503765032, 44.396126968759681],
            [-117.188947093870922, 44.336851850108495],
            [-117.217661445814457, 44.288744507435069],
            [-117.171000623906224, 44.258677418264178],
            [-117.102804038040375, 44.280153910529101],
            [-117.045375334153334, 44.229469388783883],
            [-116.97358945429454, 44.239778105071046],
            [-116.966410866308649, 44.194247941469406],
            [-116.901803574435746, 44.179643926729263],
            [-116.898214280442801, 44.153013076320754],
            [-116.934107220372198, 44.09975137550375],
            [-116.977178748287486, 44.0851473607636],
            [-116.97358945429454, 44.049066853758532],
            [-116.934107220372198, 44.021576943659433],
            [-116.937696514365143, 43.984637376963761],
            [-116.980768042280417, 43.915053542025412],
            [-116.980768042280417, 43.868664318733181],
            [-117.020250276202745, 43.859214662136615],
            [-117.027428864188636, 43.808530140391397],
            [-117.027428864188636, 42.000209491684913],
            [-117.62325166701666, 41.998491372303718],
            [-118.603128927089273, 41.99419607385073],
            [-118.696450570905711, 41.991618894778938],
            [-119.209719611896105, 41.993337014160133],
            [-119.999364290342896, 41.995055133541328],
            [-119.999364290342896, 41.99419607385073],
            [-119.999364290342896, 41.995055133541328],
            [-120.32957933769336, 41.993337014160133],
            [-121.036670254302535, 41.993337014160133],
            [-121.445849769497684, 41.996773252922523],
            [-121.998601044410435, 42.003645730447296],
            [-122.160119274092736, 42.007941028900284],
            [-122.501102203422022, 42.008800088590881],
            [-122.633906081160802, 42.004504790137894],
            [-123.046674890348896, 42.002786670756699],
            [-123.147175122151211, 42.009659148281479],
            [-123.348175585755854, 41.999350431994316],
            [-123.624551223212222, 42.000209491684913],
            [-123.656854869148674, 41.995055133541328],
            [-124.21319543805437, 41.998491372303718],
            [-124.28857061190611, 42.045739655286546],
            [-124.34241002180022, 42.092987938269374],
            [-124.36753507975078, 42.15226305692056],
            [-124.360356491764918, 42.180612026710257],
            [-124.410606607666068, 42.250195861648606],
            [-124.403428019680192, 42.277685771747713],
            [-124.428553077630767, 42.331806532255314],
            [-124.435731665616657, 42.440048053270523],
            [-124.389070843708424, 42.566329827788273],
            [-124.414195901659014, 42.658249214682137],
            [-124.475213899538986, 42.732987407764071],
            [-124.514696133461328, 42.734705527145266],
            [-124.554178367383656, 42.840369869088683],
            [-124.500338957489561, 42.917685241242403],
            [-124.457267429574287, 43.000154971539708],
            [-124.435731665616657, 43.071456925859252],
            [-124.435731665616657, 43.116128029770287],
            [-124.403428019680192, 43.184852805018046],
            [-124.381892255722548, 43.269899714387137],
            [-124.403428019680192, 43.305980221392204],
            [-124.34241002180022, 43.351510384993844],
            [-124.313695669856685, 43.388449951689509],
            [-124.256266965969644, 43.501845830848303],
            [-124.202427556075548, 43.667644351133504],
            [-124.148588146181453, 43.910758243572431],
            [-124.123463088230878, 44.104046673956731],
            [-124.10910591225911, 44.265549895788951],
            [-124.116284500245001, 44.272422373313724],
            [-124.080391560315604, 44.441657132361314],
            [-124.083980854308535, 44.5009322510125],
            [-124.05885579635796, 44.65899923408233],
            [-124.05885579635796, 44.738032725617252],
            [-124.076802266322659, 44.771536053550527],
            [-124.048087914379138, 44.849710485394844],
            [-124.005016386463865, 45.044717035160346],
            [-123.97630203452033, 45.145227018960185],
            [-123.961944858548577, 45.280099390383896],
            [-123.972712740527399, 45.336797329963289],
            [-123.961944858548577, 45.430434836238355],
            [-123.97630203452033, 45.489709954889541],
            [-123.958355564555632, 45.510327387463867],
            [-123.940409094590933, 45.661521893008924],
            [-123.943998388583879, 45.726810429494286],
            [-123.983480622506221, 45.762031876808763],
            [-123.961944858548577, 45.83677006989069],
            [-123.969123446534468, 45.908072024210234],
            [-123.994248504485029, 45.946729710287094],
            [-123.936819800597988, 45.977655859148584],
            [-123.933230506605057, 46.071293365423649],
            [-124.001427092470919, 46.23709188570885],
            [-123.904516154661536, 46.169226170151695],
            [-123.854266038760386, 46.157199334483337],
            [-123.865033920739194, 46.189843602726022],
            [-123.782480158901578, 46.19843419963199],
            [-123.757355100951003, 46.213038214372133],
            [-123.71787286702866, 46.188984543035424],
            [-123.674801339113387, 46.215615393443926],
            [-123.620961929219277, 46.215615393443926],
            [-123.549176049360483, 46.259427437664371]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '47', name: 'Tennessee' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.734437341873416, 36.000536612556118],
            [-89.691365813958129, 36.020294985439847],
            [-89.680597931979321, 36.084724462234618],
            [-89.601633464134636, 36.11908684985849],
            [-89.605222758127582, 36.171489490984904],
            [-89.705722989929896, 36.235059908089077],
            [-89.694955107951074, 36.253100161591611],
            [-89.587276288162883, 36.239355206542058],
            [-89.533436878268773, 36.252241101901014],
            [-89.537026172261719, 36.275435713547132],
            [-89.612401346113458, 36.308939041480407],
            [-89.612401346113458, 36.340724250032494],
            [-89.526258290282897, 36.341583309723092],
            [-89.508311820318198, 36.373368518275178],
            [-89.544204760247595, 36.424053040020397],
            [-89.51907970229702, 36.479032860218595],
            [-89.540615466254664, 36.497932173411726],
            [-89.486776056360554, 36.497073113721129],
            [-89.493954644346445, 36.470442263312627],
            [-89.447293822438226, 36.464428845478452],
            [-89.418579470494706, 36.498791233102324],
            [-89.300132768727678, 36.507381830008292],
            [-88.998632073320735, 36.502227471864714],
            [-88.546381030210298, 36.502227471864714],
            [-88.054647753177534, 36.497073113721129],
            [-88.03311198921989, 36.552052933919335],
            [-88.069004929149287, 36.678334708437077],
            [-87.850057995579945, 36.663730693696934],
            [-87.853647289572891, 36.633663604526042],
            [-87.444467774377742, 36.640536082050815],
            [-87.114252727027264, 36.64225420143201],
            [-86.590215804058033, 36.652562917719173],
            [-86.565090746107458, 36.633663604526042],
            [-86.507662042220417, 36.652562917719173],
            [-86.331786636566363, 36.649126678956783],
            [-86.033875235152351, 36.630227365763652],
            [-85.832874771547708, 36.621636768857684],
            [-85.488302548225477, 36.614764291332904],
            [-85.290891378613779, 36.626791127001262],
            [-85.025283623136232, 36.619059589785891],
            [-84.842229629496288, 36.605314634736338],
            [-84.544318228082275, 36.595864978139772],
            [-84.271531884618838, 36.591569679686792],
            [-83.987977659176593, 36.589851560305597],
            [-83.690066257762581, 36.582979082780824],
            [-83.675709081790814, 36.601019336283358],
            [-83.557262380023801, 36.597583097520967],
            [-83.277297448574487, 36.598442157211565],
            [-83.259350978609788, 36.594146858758585],
            [-82.695831821718215, 36.593287799067987],
            [-82.476884888148874, 36.595864978139772],
            [-81.934901495214945, 36.594146858758585],
            [-81.924133613236123, 36.616482410714099],
            [-81.647757975779754, 36.612187112261118],
            [-81.676472327723275, 36.588133440924402],
            [-81.708775973659741, 36.536589859488586],
            [-81.694418797687973, 36.467865084240835],
            [-81.715954561645617, 36.457556367953671],
            [-81.741079619596192, 36.401717488064875],
            [-81.705186679666795, 36.338147070960702],
            [-81.766204677546767, 36.338147070960702],
            [-81.794919029490288, 36.35790544384443],
            [-81.83440126341263, 36.347596727557267],
            [-81.90977643726437, 36.302066563955634],
            [-82.028223139031383, 36.130254625836251],
            [-82.078473254932547, 36.106200954499542],
            [-82.132312664826642, 36.106200954499542],
            [-82.14666984079841, 36.149153939029382],
            [-82.214866426664258, 36.159462655316545],
            [-82.247170072600724, 36.131113685526849],
            [-82.290241600515998, 36.135408983979836],
            [-82.351259598395984, 36.117368730477295],
            [-82.40509900829008, 36.087301641306404],
            [-82.458938418184175, 36.007409090080891],
            [-82.559438649986504, 35.954147389263888],
            [-82.613278059880599, 35.971328583075824],
            [-82.595331589915901, 36.031462761417608],
            [-82.631224529845298, 36.065825149041487],
            [-82.749671231612311, 36.005690970699703],
            [-82.821457111471105, 35.921503121021203],
            [-82.875296521365215, 35.952429269882693],
            [-82.911189461294612, 35.926657479164788],
            [-82.90042157931579, 35.87253671865718],
            [-82.993743223132228, 35.773744854238537],
            [-83.079886278962789, 35.789207928669278],
            [-83.155261452814528, 35.764295197641971],
            [-83.183975804758049, 35.729932810018092],
            [-83.24140450864509, 35.726496571255709],
            [-83.255761684616843, 35.696429482084817],
            [-83.313190388503884, 35.655194616936164],
            [-83.349083328433281, 35.66120803477034],
            [-83.367029798397979, 35.638872482814826],
            [-83.445994266242664, 35.611382572715719],
            [-83.485476500164992, 35.568429588185879],
            [-83.772620019600197, 35.562416170351696],
            [-83.826459429494292, 35.523758484274836],
            [-83.883888133381333, 35.516886006750063],
            [-83.912602485324854, 35.475651141601411],
            [-83.959263307233073, 35.462765246242455],
            [-84.020281305113045, 35.409503545425451],
            [-84.005924129141292, 35.371704919039182],
            [-84.038227775077743, 35.350228426774265],
            [-84.020281305113045, 35.301262024410235],
            [-84.09924577295773, 35.247141263902634],
            [-84.199746004760044, 35.243705025140244],
            [-84.210513886738866, 35.266040577095765],
            [-84.289478354583537, 35.224805711947113],
            [-84.321782000520002, 34.988564297032966],
            [-84.978622801228013, 34.987705237342368],
            [-85.466766784267833, 34.982550879198783],
            [-85.60674924999249, 34.984268998579978],
            [-85.825696183561831, 34.988564297032966],
            [-86.396393928439281, 34.992000535795349],
            [-86.529197806178061, 34.990282416414161],
            [-86.86300214752147, 34.992000535795349],
            [-87.437289186391865, 35.003168311773109],
            [-87.663414707947069, 35.003168311773109],
            [-88.201808806888067, 35.008322669916694],
            [-88.201808806888067, 34.995436774557739],
            [-88.926846193461927, 34.994577714867141],
            [-89.486776056360554, 34.993718655176544],
            [-90.308724380743797, 34.995436774557739],
            [-90.297956498764989, 35.037530699396989],
            [-90.208224148941483, 35.026362923419228],
            [-90.175920503005031, 35.112268892478916],
            [-90.143616857068565, 35.134604444434437],
            [-90.100545329153292, 35.116564190931904],
            [-90.064652389223895, 35.138040683196827],
            [-90.11849179911799, 35.187866145251448],
            [-90.075420271202702, 35.224805711947113],
            [-90.096956035160346, 35.249718442974427],
            [-90.150795445054442, 35.255731860808602],
            [-90.165152621026209, 35.296107666266657],
            [-90.107723917139168, 35.304698263172625],
            [-90.107723917139168, 35.343355949249485],
            [-90.075420271202702, 35.38373175470754],
            [-90.129259681096812, 35.413798843878432],
            [-90.136438269082689, 35.376859277182767],
            [-90.179509796997962, 35.382013635326345],
            [-90.16874191501914, 35.421530381093802],
            [-90.086188153181524, 35.479087380363801],
            [-90.057473801238004, 35.403490127591269],
            [-90.017991567315676, 35.46791960438604],
            [-90.050295213252127, 35.504000111391107],
            [-90.032348743287429, 35.553825573445728],
            [-90.000045097350963, 35.561557110661099],
            [-89.921080629506292, 35.513449767987673],
            [-89.91031274752747, 35.547812155611552],
            [-89.942616393463936, 35.556402752517521],
            [-89.946205687456867, 35.601932916119154],
            [-89.852884043640429, 35.638013423124228],
            [-89.863651925619251, 35.670657691366905],
            [-89.906723453534525, 35.650899318483177],
            [-89.939027099470991, 35.665503333223327],
            [-89.960562863428635, 35.723919392183916],
            [-89.906723453534525, 35.759140839498386],
            [-89.874419807598073, 35.741100585995852],
            [-89.813401809718087, 35.759999899188983],
            [-89.781098163781635, 35.804671003100026],
            [-89.741615929859293, 35.805530062790623],
            [-89.702133695936951, 35.83387903258032],
            [-89.773919575795759, 35.86480518144181],
            [-89.741615929859293, 35.90689910628106],
            [-89.655472874028732, 35.887140733397331],
            [-89.655472874028732, 35.925798419474191],
            [-89.720080165901649, 35.967892344313434],
            [-89.734437341873416, 36.000536612556118]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '49', name: 'Utah' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.041136262062608, 41.993337014160133],
            [-113.82218932849328, 41.988182656016555],
            [-112.88179430234301, 41.999350431994316],
            [-112.192649855698548, 42.001068551375504],
            [-112.174703385733849, 41.996773252922523],
            [-111.47120176311762, 41.999350431994316],
            [-111.047665071950718, 42.001927611066101],
            [-111.044075777957772, 41.565525288242874],
            [-111.047665071950718, 41.377391216002152],
            [-111.047665071950718, 40.997686832758319],
            [-110.713860730607294, 40.995968713377124],
            [-110.236484629546283, 40.995109653686534],
            [-110.049841341913407, 40.997686832758319],
            [-109.536572300922998, 40.998545892448917],
            [-109.048428317883179, 41.000264011830112],
            [-109.048428317883179, 40.539808017670168],
            [-109.05201761187611, 40.500291271902711],
            [-109.05201761187611, 38.905017426464255],
            [-109.059196199861987, 38.719460533295326],
            [-109.059196199861987, 38.275326673256728],
            [-109.041249729897288, 38.164507973169727],
            [-109.041249729897288, 37.530521921509212],
            [-109.044839023890233, 37.499595772647723],
            [-109.044839023890233, 36.998763973029725],
            [-109.626304650746505, 36.997904913339127],
            [-110.469788739087392, 36.997904913339127],
            [-110.491324503045021, 37.003918331173303],
            [-111.065611541915416, 37.002200211792115],
            [-111.413773059230593, 37.001341152101517],
            [-112.537222079020779, 37.00048209241092],
            [-113.334045345453447, 37.00048209241092],
            [-114.05190414404143, 37.00048209241092],
            [-114.05190414404143, 37.756454620136196],
            [-114.048314850048499, 37.765904276732762],
            [-114.05190414404143, 37.999568512575117],
            [-114.048314850048499, 38.148185839048381],
            [-114.05190414404143, 38.404185626846264],
            [-114.048314850048499, 38.677366608456076],
            [-114.048314850048499, 40.104264754537539],
            [-114.044725556055553, 40.232264648436477],
            [-114.044725556055553, 40.771754134131335],
            [-114.041136262062608, 41.000264011830112],
            [-114.041136262062608, 41.993337014160133]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '51', name: 'Virginia' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.9982092308923, 37.848374007030067],
              [-75.973084172941725, 37.835488111671111],
              [-75.9982092308923, 37.812293500024992],
              [-75.9982092308923, 37.848374007030067]
            ]
          ],
          [
            [
              [-75.667994183541836, 37.950602110211094],
              [-75.646458419584192, 37.947165871448711],
              [-75.624922655626548, 37.994414154431539],
              [-75.240868198381975, 38.027058422674223],
              [-75.298296902269016, 37.959192707117062],
              [-75.352136312163125, 37.875004857438569],
              [-75.380850664106646, 37.85181024579245],
              [-75.398797134071344, 37.869850499294984],
              [-75.45263654396544, 37.863837081460808],
              [-75.520833129831303, 37.796830425594251],
              [-75.563904657746576, 37.736696247252468],
              [-75.603386891668919, 37.628454726237258],
              [-75.599797597675973, 37.573474906039053],
              [-75.682351359513589, 37.463515265642648],
              [-75.657226301563014, 37.45148842997429],
              [-75.721833593435932, 37.373313998129973],
              [-75.764905121351205, 37.305448282572819],
              [-75.797208767287671, 37.295998625976253],
              [-75.793619473294726, 37.24703222361223],
              [-75.833101707217068, 37.173153090220893],
              [-75.897708999089986, 37.118173270022695],
              [-75.955137702977026, 37.11989138940389],
              [-75.940780527005273, 37.089824300232998],
              [-75.973084172941725, 37.08552900178001],
              [-75.97667346693467, 37.157690015790152],
              [-76.012566406864067, 37.20493829877298],
              [-76.026923582835821, 37.257340939899393],
              [-76.019744994849944, 37.317475118241177],
              [-75.987441348913492, 37.368159639986395],
              [-75.97667346693467, 37.444615952449517],
              [-75.940780527005273, 37.534817219962193],
              [-75.926423351033506, 37.600105756447562],
              [-75.868994647146465, 37.667971472004716],
              [-75.804387355273548, 37.762468037970372],
              [-75.818744531245315, 37.791676067450666],
              [-75.743369357393576, 37.806280082190817],
              [-75.689529947499466, 37.862118962079613],
              [-75.685940653506535, 37.886172633416329],
              [-75.757726533365329, 37.904212886918863],
              [-75.667994183541836, 37.950602110211094]
            ]
          ],
          [
            [
              [-75.994619936899369, 37.953179289282886],
              [-76.034102170821711, 37.915380662896624],
              [-76.044870052800519, 37.953179289282886],
              [-75.994619936899369, 37.953179289282886]
            ]
          ],
          [
            [
              [-81.96720514115141, 37.537394399033985],
              [-81.927722907229068, 37.512481668006671],
              [-81.992330199101985, 37.483273638526377],
              [-81.988740905109054, 37.45492466873668],
              [-81.934901495214945, 37.436025355543549],
              [-81.927722907229068, 37.358709983389829],
              [-81.877472791327904, 37.33207913298132],
              [-81.852347733377329, 37.287408029070285],
              [-81.787740441504411, 37.284830849998492],
              [-81.744668913589138, 37.263354357733569],
              [-81.744668913589138, 37.242736925159242],
              [-81.676472327723275, 37.201502060010597],
              [-81.554436331963316, 37.207515477844773],
              [-81.507775510055097, 37.232428208872079],
              [-81.500596922069221, 37.258199999589991],
              [-81.418043160231605, 37.272804014330134],
              [-81.389328808288084, 37.320052297312969],
              [-81.360614456344564, 37.338092550815503],
              [-81.321132222422222, 37.299434864738643],
              [-81.224221284612838, 37.235005387943872],
              [-81.112953170831702, 37.278817432164317],
              [-80.980149293092921, 37.302012043810429],
              [-80.919131295212949, 37.306307342263416],
              [-80.850934709347086, 37.346683147721471],
              [-80.883238355283552, 37.383622714417136],
              [-80.858113297332977, 37.427434758637581],
              [-80.782738123481238, 37.394790490394897],
              [-80.768380947509471, 37.372454938439375],
              [-80.621219893798937, 37.433448176471757],
              [-80.553023307933074, 37.473823981929812],
              [-80.509951780017801, 37.481555519145182],
              [-80.474058840088404, 37.423139460184593],
              [-80.298183434434335, 37.508186369553691],
              [-80.283826258462582, 37.533099100580998],
              [-80.330487080370801, 37.536535339343388],
              [-80.326897786377856, 37.564025249442487],
              [-80.262290494504938, 37.593233278922781],
              [-80.219218966589665, 37.627595666546661],
              [-80.280236964469637, 37.656803696026955],
              [-80.294594140441404, 37.692025143341425],
              [-80.251522612526117, 37.725528471274707],
              [-80.258701200512007, 37.756454620136196],
              [-80.183326026660268, 37.852669305483047],
              [-80.075647206872063, 37.942011513305125],
              [-80.011039914999145, 37.98582355752557],
              [-79.975146975069748, 38.029635601746008],
              [-79.953611211112104, 38.081179183181824],
              [-79.924896859168584, 38.106950973899735],
              [-79.946432623126228, 38.131863704927042],
              [-79.921307565175653, 38.179971047600468],
              [-79.831575215352146, 38.249554882538817],
              [-79.788503687436872, 38.267595136041351],
              [-79.810039451394516, 38.30539376242762],
              [-79.731074983549831, 38.380131955509547],
              [-79.688003455634558, 38.431675536945363],
              [-79.69877133761338, 38.487514416834159],
              [-79.648521221712215, 38.591460639396388],
              [-79.537253107931079, 38.551084833938333],
              [-79.476235110051093, 38.457447327663267],
              [-79.311127586375861, 38.411917164061634],
              [-79.210627354573546, 38.492668774977744],
              [-79.156787944679451, 38.611219012280117],
              [-79.120895004750039, 38.66018541464414],
              [-79.092180652806519, 38.659326354953542],
              [-79.088591358813588, 38.720319592985923],
              [-79.027573360933602, 38.79076248761487],
              [-78.99526971499715, 38.850037606266056],
              [-78.869644425244246, 38.763272577515771],
              [-78.797858545385452, 38.874950337293363],
              [-78.715304783547836, 38.935943575325744],
              [-78.715304783547836, 38.905017426464255],
              [-78.679411843618439, 38.925634859038581],
              [-78.625572433724329, 38.982332798617982],
              [-78.600447375773754, 38.964292545115441],
              [-78.55019725987259, 39.018413305623049],
              [-78.571733023830234, 39.032158260672603],
              [-78.485589967999672, 39.107755513445127],
              [-78.460464910049097, 39.113768931279303],
              [-78.403036206162056, 39.167030632096314],
              [-78.438929146091454, 39.197956780957803],
              [-78.399446912169125, 39.244346004250033],
              [-78.420982676126755, 39.257231899608989],
              [-78.338428914289139, 39.348292226812262],
              [-78.363553972239714, 39.357741883408828],
              [-78.342018208282084, 39.388668032270317],
              [-78.359964678246783, 39.412721703607026],
              [-78.345607502275016, 39.465983404424037],
              [-78.10871409874099, 39.312211719807195],
              [-77.828749167291676, 39.132668244472441],
              [-77.753373993439936, 39.283003690326893],
              [-77.721070347503471, 39.32166137640376],
              [-77.674409525595252, 39.324238555475546],
              [-77.616980821708211, 39.302762063210622],
              [-77.563141411814115, 39.30362112290122],
              [-77.541605647856471, 39.26496343682436],
              [-77.459051886018855, 39.228023870128695],
              [-77.48417694396943, 39.185929945289445],
              [-77.516480589905896, 39.170466870858704],
              [-77.520069883898842, 39.120641408804083],
              [-77.48417694396943, 39.109473632826322],
              [-77.462641180011801, 39.07597030489304],
              [-77.340605184251842, 39.063084409534092],
              [-77.247283540435404, 39.027003902529017],
              [-77.250872834428336, 38.985769037380365],
              [-77.222158482484829, 38.971165022640221],
              [-77.150372602626021, 38.966010664496636],
              [-77.118068956689569, 38.934225455944556],
              [-77.039104488844885, 38.87151409853098],
              [-77.039104488844885, 38.791621547305468],
              [-77.04269378283783, 38.718601473604728],
              [-77.107301074710747, 38.697124981339805],
              [-77.132426132661323, 38.673930369693693],
              [-77.128836838668391, 38.635272683616833],
              [-77.200622718527185, 38.617232430114292],
              [-77.247283540435404, 38.635272683616833],
              [-77.247283540435404, 38.59060157970579],
              [-77.311890832308322, 38.493527834668342],
              [-77.326248008280075, 38.448856730757299],
              [-77.319069420294198, 38.383568194271938],
              [-77.265230010400103, 38.332883672526719],
              [-77.161140484604843, 38.345769567885675],
              [-77.139604720647199, 38.368105119841189],
              [-77.085765310753104, 38.368105119841189],
              [-77.04269378283783, 38.400749388083874],
              [-77.010390136901364, 38.374977597365969],
              [-77.031925900859008, 38.311407180261796],
              [-76.963729314993145, 38.256427360063597],
              [-76.963729314993145, 38.214333435224347],
              [-76.838104025240256, 38.163648913479129],
              [-76.748371675416749, 38.161930794097934],
              [-76.723246617466174, 38.137877122761218],
              [-76.70171085350853, 38.155917376263758],
              [-76.615567797677969, 38.148185839048381],
              [-76.601210621706215, 38.110387212662118],
              [-76.543781917819175, 38.076883884728844],
              [-76.515067565875654, 38.026199362983625],
              [-76.468406743967435, 38.01331346762467],
              [-76.41456733407334, 37.966924244332439],
              [-76.342781454214546, 37.947165871448711],
              [-76.23510263442634, 37.888749812488122],
              [-76.253049104391039, 37.832910932599319],
              [-76.306888514285134, 37.812293500024992],
              [-76.314067102271025, 37.720374113131122],
              [-76.299709926299258, 37.695461382103815],
              [-76.3391921602216, 37.655944636336358],
              [-76.292531338313381, 37.636186263452629],
              [-76.360727924179244, 37.610414472734718],
              [-76.47199603796038, 37.665394292932923],
              [-76.511478271882723, 37.642199681286804],
              [-76.536603329833298, 37.663676173551728],
              [-76.536603329833298, 37.698897620866205],
              [-76.583264151741517, 37.768481455804555],
              [-76.65146073760738, 37.795971365903654],
              [-76.6801750895509, 37.826038455074546],
              [-76.723246617466174, 37.836347171361709],
              [-76.798621791317913, 37.924830319493189],
              [-76.838104025240256, 37.934279976089755],
              [-76.852461201212009, 37.972937662166615],
              [-76.899122023120228, 37.998709452884519],
              [-76.906300611106104, 37.972937662166615],
              [-76.80580037930379, 37.896481349703492],
              [-76.734014499444996, 37.798548544975446],
              [-76.6801750895509, 37.77793111240112],
              [-76.676585795557955, 37.756454620136196],
              [-76.619157091670914, 37.744427784467838],
              [-76.583264151741517, 37.661958054170533],
              [-76.543781917819175, 37.6164278905689],
              [-76.436103098030983, 37.61299165180651],
              [-76.410978040080394, 37.582065502945021],
              [-76.299709926299258, 37.561448070370695],
              [-76.349960042200422, 37.536535339343388],
              [-76.353549336193353, 37.504750130791301],
              [-76.306888514285134, 37.497877653266528],
              [-76.292531338313381, 37.516776966459659],
              [-76.263816986369861, 37.481555519145182],
              [-76.245870516405162, 37.387058953179526],
              [-76.274584868348683, 37.309743581025806],
              [-76.3391921602216, 37.363864341533407],
              [-76.41456733407334, 37.402522027610267],
              [-76.446870980009791, 37.3664415206052],
              [-76.407388746087463, 37.332938192671918],
              [-76.382263688136874, 37.28568990968909],
              [-76.349960042200422, 37.273663074020732],
              [-76.428924510045093, 37.253045641446406],
              [-76.49353180191801, 37.249609402684023],
              [-76.47199603796038, 37.216106074750741],
              [-76.393031570115696, 37.225555731347306],
              [-76.400210158101572, 37.160267194861945],
              [-76.349960042200422, 37.170575911149108],
              [-76.335602866228655, 37.143945060740599],
              [-76.292531338313381, 37.126763866928663],
              [-76.270995574355737, 37.084669942089413],
              [-76.303299220292203, 37.001341152101517],
              [-76.317656396263956, 37.014227047460466],
              [-76.375085100150997, 36.998763973029725],
              [-76.428924510045093, 36.96955594354943],
              [-76.464817449974504, 37.027972002510019],
              [-76.56890697576975, 37.080374643636432],
              [-76.619157091670914, 37.119032329713292],
              [-76.604799915699161, 37.160267194861945],
              [-76.65146073760738, 37.221260432894326],
              [-76.73042520545205, 37.213528895678948],
              [-76.759139557395571, 37.192052403414031],
              [-76.795032497324968, 37.231569149181489],
              [-76.859639789197885, 37.241018805778054],
              [-76.873996965169653, 37.260777178661783],
              [-76.949372139021392, 37.230710089490891],
              [-76.90988990509905, 37.202361119701187],
              [-76.866818377183776, 37.209233597225968],
              [-76.802211085310844, 37.198065821248207],
              [-76.748371675416749, 37.150817538265379],
              [-76.716068029480297, 37.149099418884184],
              [-76.687353677536777, 37.198924880938804],
              [-76.658639325593256, 37.096696777757771],
              [-76.655050031600311, 37.03913977848778],
              [-76.57608556375564, 37.022817644366441],
              [-76.486353213932134, 36.952374749737494],
              [-76.482763919939202, 36.896535869848691],
              [-76.385852982129819, 36.899972108611081],
              [-76.353549336193353, 36.9231667202572],
              [-76.349960042200422, 36.894817750467496],
              [-76.317656396263956, 36.88536809387093],
              [-76.328424278242778, 36.959247227262267],
              [-76.267406280362806, 36.964401585405845],
              [-76.220745458454587, 36.939488854378538],
              [-76.095120168701683, 36.908562705517049],
              [-76.034102170821711, 36.931757317163168],
              [-75.994619936899369, 36.922307660566602],
              [-75.965905584955848, 36.812348020170198],
              [-75.922834057040575, 36.692079663486631],
              [-75.890530411104109, 36.631086425454249],
              [-75.865405353153534, 36.550334814538139],
              [-76.375085100150997, 36.550334814538139],
              [-76.80580037930379, 36.550334814538139],
              [-76.917068493084926, 36.552052933919335],
              [-76.917068493084926, 36.543462337013366],
              [-77.297533656336554, 36.544321396703964],
              [-78.245107270472701, 36.544321396703964],
              [-78.510715025950262, 36.540885157941574],
              [-79.512128049980504, 36.540885157941574],
              [-80.122308028780282, 36.542603277322769],
              [-80.294594140441404, 36.544321396703964],
              [-80.703773655636553, 36.562361650206498],
              [-80.836577533375333, 36.558925411444108],
              [-81.177560462704619, 36.571811306803063],
              [-81.374971632316317, 36.574388485874856],
              [-81.676472327723275, 36.588133440924402],
              [-81.647757975779754, 36.612187112261118],
              [-81.924133613236123, 36.616482410714099],
              [-81.934901495214945, 36.594146858758585],
              [-82.476884888148874, 36.595864978139772],
              [-82.695831821718215, 36.593287799067987],
              [-83.259350978609788, 36.594146858758585],
              [-83.277297448574487, 36.598442157211565],
              [-83.557262380023801, 36.597583097520967],
              [-83.675709081790814, 36.601019336283358],
              [-83.646994729847293, 36.624213947929476],
              [-83.52854802808028, 36.666307872768719],
              [-83.453172854228541, 36.664589753387524],
              [-83.313190388503884, 36.709260857298567],
              [-83.194743686736871, 36.739327946469459],
              [-83.13731498284983, 36.742764185231849],
              [-83.130136394863939, 36.785717169761689],
              [-83.072707690976912, 36.854441945009441],
              [-82.968618165181653, 36.857878183771831],
              [-82.878885815358146, 36.88880433263332],
              [-82.857350051400516, 36.92746201871018],
              [-82.868117933379324, 36.978146540455398],
              [-82.82504640546405, 37.006495510245095],
              [-82.781974877548777, 37.00821362962629],
              [-82.724546173661736, 37.041716957559572],
              [-82.724546173661736, 37.115596090950902],
              [-82.634813823838229, 37.154253777027762],
              [-82.56661723797238, 37.196347701867012],
              [-82.487652770127696, 37.231569149181489],
              [-82.354848892388915, 37.265072477114764],
              [-81.96720514115141, 37.537394399033985]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '53', name: 'Washington' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.526227261372611, 47.359023841628407],
              [-122.511870085400844, 47.449225109141082],
              [-122.475977145471447, 47.511077406864061],
              [-122.432905617556173, 47.466406302953018],
              [-122.44008420554205, 47.416580840898398],
              [-122.371887619676187, 47.389090930799298],
              [-122.436494911549104, 47.365896319153187],
              [-122.454441381513817, 47.343560767197665],
              [-122.51904867338672, 47.333252050910502],
              [-122.526227261372611, 47.359023841628407]
            ]
          ],
          [
            [
              [-122.651852551125501, 48.548821513105125],
              [-122.651852551125501, 48.583183900728997],
              [-122.580066671266707, 48.547962453414527],
              [-122.641084669146693, 48.525626901459006],
              [-122.651852551125501, 48.548821513105125]
            ]
          ],
          [
            [
              [-122.723638430984295, 48.731801227202261],
              [-122.702102667026665, 48.74382806287062],
              [-122.608781023210227, 48.645036198451976],
              [-122.673388315083145, 48.681116705457043],
              [-122.723638430984295, 48.731801227202261]
            ]
          ],
          [
            [
              [-122.741584900948993, 48.584042960419595],
              [-122.712870549005487, 48.608955691446909],
              [-122.669799021090199, 48.568579885988854],
              [-122.723638430984295, 48.540230916199157],
              [-122.741584900948993, 48.584042960419595]
            ]
          ],
          [
            [
              [-122.770299252892528, 48.22753318882188],
              [-122.723638430984295, 48.303989501285002],
              [-122.673388315083145, 48.354674023030221],
              [-122.666209727097254, 48.401922306013056],
              [-122.608781023210227, 48.411371962609621],
              [-122.583655965259652, 48.395049828488276],
              [-122.583655965259652, 48.352955903649026],
              [-122.515459379393789, 48.320311635406348],
              [-122.504691497414967, 48.297976083450827],
              [-122.558530907309063, 48.281653949329488],
              [-122.619548905189049, 48.294539844688437],
              [-122.623138199181994, 48.26962711366113],
              [-122.669799021090199, 48.240419084180836],
              [-122.720049136991364, 48.234405666346653],
              [-122.709281255012542, 48.213788233772327],
              [-122.626727493174926, 48.222378830678302],
              [-122.587245259252583, 48.186298323673228],
              [-122.558530907309063, 48.114137309663086],
              [-122.572888083280816, 48.102110473994735],
              [-122.536995143351419, 48.01620450493504],
              [-122.508280791407913, 48.039399116581158],
              [-122.526227261372611, 48.09695611585115],
              [-122.490334321443214, 48.094378936779357],
              [-122.432905617556173, 48.045412534415334],
              [-122.375476913669132, 48.034244758437573],
              [-122.350351855718543, 47.953493147521471],
              [-122.379066207662078, 47.90366768546685],
              [-122.429316323563228, 47.914835461444603],
              [-122.472387851478516, 47.98871459483594],
              [-122.547763025330255, 47.967238102571017],
              [-122.540584437344364, 47.993009893288928],
              [-122.608781023210227, 48.03080851967519],
              [-122.598013141231405, 48.110701070900703],
              [-122.615959611196104, 48.158808413574128],
              [-122.680566903069021, 48.155372174811738],
              [-122.691334785047843, 48.18114396552965],
              [-122.770299252892528, 48.22753318882188]
            ]
          ],
          [
            [
              [-122.978478304483048, 48.79365352492524],
              [-122.938996070560705, 48.790217286162857],
              [-122.816960074800733, 48.744687122561217],
              [-122.845674426744267, 48.737814645036444],
              [-122.924638894588938, 48.75499583884838],
              [-122.978478304483048, 48.79365352492524]
            ]
          ],
          [
            [
              [-123.071799948299486, 48.700016018650182],
              [-123.010781950419499, 48.722351570605696],
              [-123.003603362433608, 48.694002600815999],
              [-123.071799948299486, 48.700016018650182]
            ]
          ],
          [
            [
              [-123.089746418264184, 49.002405029740288],
              [-123.035907008370074, 49.002405029740288],
              [-123.021549832398307, 48.977492298712981],
              [-123.082567830278293, 48.975774179331786],
              [-123.089746418264184, 49.002405029740288]
            ]
          ],
          [
            [
              [-123.204603826038252, 48.596069796087953],
              [-123.179478768087677, 48.621841586805857],
              [-123.107692888228883, 48.622700646496455],
              [-123.100514300242992, 48.597787915469148],
              [-123.046674890348896, 48.569438945679451],
              [-122.98565689246891, 48.561707408464073],
              [-123.050264184341842, 48.620982527115267],
              [-122.917460306603061, 48.713760973699728],
              [-122.874388778687774, 48.712042854318533],
              [-122.741584900948993, 48.662217392263912],
              [-122.80978148681487, 48.619264407734072],
              [-122.770299252892528, 48.562566468154671],
              [-122.777477840878404, 48.509304767337667],
              [-122.816960074800733, 48.487828275072744],
              [-122.80260289882898, 48.428553156421557],
              [-122.874388778687774, 48.418244440134394],
              [-122.928228188581869, 48.461197424664242],
              [-122.960531834518335, 48.450888708377079],
              [-123.039496302363005, 48.460338364973644],
              [-123.150764416144156, 48.513600065790648],
              [-123.172300180101786, 48.578888602276017],
              [-123.204603826038252, 48.596069796087953]
            ]
          ],
          [
            [
              [-123.236907471974718, 48.688848242672421],
              [-123.172300180101786, 48.680257645766453],
              [-123.107692888228883, 48.633868422474215],
              [-123.215371708017074, 48.669089869788692],
              [-123.236907471974718, 48.688848242672421]
            ]
          ],
          [
            [
              [-117.031018158181581, 48.998968790977905],
              [-117.034607452174512, 48.749841480704802],
              [-117.034607452174512, 48.370996157151566],
              [-117.041786040160389, 48.125305085640846],
              [-117.041786040160389, 47.522245182841822],
              [-117.038196746167458, 47.477574078930779],
              [-117.038196746167458, 46.40632664475644],
              [-117.063321804118033, 46.365091779607788],
              [-116.991535924259239, 46.299803243122426],
              [-116.962821572315718, 46.253414019830188],
              [-116.962821572315718, 46.199293259322587],
              [-116.919750044400445, 46.164930871698708],
              [-116.980768042280417, 46.085038320473195],
              [-116.955642984329842, 46.07558866387663],
              [-116.916160750407499, 45.995696112651117],
              [-117.504804965249647, 45.99827329172291],
              [-118.40212846348463, 46.000850470794703],
              [-118.987183384333832, 45.999991411104105],
              [-119.026665618256175, 45.969065262242616],
              [-119.127165850058503, 45.932984755237548],
              [-119.195362435924352, 45.927830397093963],
              [-119.256380433804338, 45.939857232762321],
              [-119.486095249352488, 45.906353904829039],
              [-119.572238305183049, 45.925253218022171],
              [-119.622488421084199, 45.905494845138442],
              [-119.669149242992432, 45.856528442774419],
              [-119.801953120731199, 45.847937845868451],
              [-119.967060644406445, 45.824743234222332],
              [-120.07115017020169, 45.785226488454875],
              [-120.171650402004019, 45.762031876808763],
              [-120.211132635926361, 45.725951369803688],
              [-120.282918515785155, 45.721656071350708],
              [-120.4049545115451, 45.699320519395187],
              [-120.483918979389784, 45.694166161251609],
              [-120.559294153241524, 45.737978205472047],
              [-120.634669327093263, 45.745709742687417],
              [-120.688508736987359, 45.715642653516525],
              [-120.857205554655536, 45.671830609296087],
              [-120.896687788577879, 45.642622579815793],
              [-120.943348610486098, 45.656367534865339],
              [-121.065384606246056, 45.652931296102956],
              [-121.133581192111905, 45.609978311573109],
              [-121.183831308013083, 45.606542072810718],
              [-121.216134953949535, 45.67097154960549],
              [-121.338170949709479, 45.705333937229362],
              [-121.402778241582411, 45.693307101561011],
              [-121.463796239462397, 45.701038638776382],
              [-121.531992825328246, 45.726810429494286],
              [-121.632493057130574, 45.704474877538765],
              [-121.73658258292582, 45.694166161251609],
              [-121.811957756777559, 45.707052056610557],
              [-121.865797166671655, 45.693307101561011],
              [-121.908868694586943, 45.654649415484144],
              [-122.102690570205695, 45.583347461164607],
              [-122.185244332043311, 45.577334043330424],
              [-122.267798093880941, 45.543830715397149],
              [-122.332405385753844, 45.54812601385013],
              [-122.379066207662078, 45.575615923949229],
              [-122.44008420554205, 45.563589088280878],
              [-122.479566439464378, 45.579911222402217],
              [-122.644673963139624, 45.609978311573109],
              [-122.763120664906637, 45.656367534865339],
              [-122.773888546885459, 45.699320519395187],
              [-122.759531370913692, 45.734541966709656],
              [-122.795424310843103, 45.810139219482188],
              [-122.784656428864281, 45.86769621875218],
              [-122.80978148681487, 45.912367322663222],
              [-122.813370780807801, 45.961333725027245],
              [-122.877978072680719, 46.030917559965594],
              [-122.903103130631308, 46.083320201092],
              [-123.114871476214745, 46.185548304273034],
              [-123.165121592115923, 46.188984543035424],
              [-123.279978999889991, 46.145172498814979],
              [-123.369711349713498, 46.146031558505577],
              [-123.43072934759347, 46.182112065510644],
              [-123.427140053600539, 46.229360348493479],
              [-123.473800875508744, 46.268018034570339],
              [-123.549176049360483, 46.259427437664371],
              [-123.671212045120441, 46.267158974879742],
              [-123.681979927099263, 46.296367004360036],
              [-123.725051455014551, 46.301521362503614],
              [-123.760944394943948, 46.274890512095112],
              [-123.807605216852153, 46.28348110900108],
              [-123.87580180271803, 46.239669064780642],
              [-123.908105448654482, 46.245682482614818],
              [-123.983480622506221, 46.309252899718992],
              [-124.019373562435618, 46.316125377243765],
              [-124.044498620386193, 46.27574957178571],
              [-124.080391560315604, 46.267158974879742],
              [-124.05885579635796, 46.386568271872711],
              [-124.055266502365015, 46.493091673506726],
              [-124.069623678336768, 46.634836522455217],
              [-124.022962856428563, 46.582433881328804],
              [-124.030141444414426, 46.496527912269116],
              [-124.015784268442673, 46.378836734657341],
              [-123.9547662705627, 46.378836734657341],
              [-123.951176976569755, 46.410621943209428],
              [-123.987069916499166, 46.4449843308333],
              [-123.987069916499166, 46.497386971959713],
              [-123.943998388583879, 46.465601763407626],
              [-123.893748272682728, 46.536903717727171],
              [-123.961944858548577, 46.636554641836412],
              [-123.922462624626235, 46.672635148841479],
              [-123.832730274802742, 46.718165312443119],
              [-123.893748272682728, 46.749950520995199],
              [-123.915284036640358, 46.726755909349087],
              [-123.979891328513276, 46.725037789967892],
              [-123.965534152541522, 46.705279417084164],
              [-124.022962856428563, 46.708715655846554],
              [-124.094748736287357, 46.746514282232816],
              [-124.098338030280303, 46.793762565215644],
              [-124.137820264202631, 46.905440324993243],
              [-124.10910591225911, 46.912312802518016],
              [-124.087570148301467, 46.867641698606981],
              [-124.048087914379138, 46.894272549015483],
              [-123.861444626746263, 46.948393309523091],
              [-123.89733756667566, 46.971587921169203],
              [-124.019373562435618, 46.991346294052931],
              [-124.026552150421495, 47.030003980129791],
              [-124.123463088230878, 47.042030815798149],
              [-124.152177440174398, 47.021413383223823],
              [-124.123463088230878, 46.944098011070103],
              [-124.180891792117905, 46.92605775756757],
              [-124.170123910139097, 46.958702025810247],
              [-124.18448108611085, 47.135668322073215],
              [-124.234731202012014, 47.286862827618272],
              [-124.320874257842576, 47.355587602866024],
              [-124.353177903779027, 47.533412958819582],
              [-124.371124373743726, 47.599560554995541],
              [-124.424963783637821, 47.73872822487224],
              [-124.475213899538986, 47.769654373733729],
              [-124.489571075510753, 47.816902656716557],
              [-124.539821191411903, 47.836661029600286],
              [-124.557767661376602, 47.863291880008795],
              [-124.625964247242464, 47.888204611036102],
              [-124.669035775157738, 47.982701177001765],
              [-124.697750127101273, 48.06946620575205],
              [-124.694160833108327, 48.114996369353683],
              [-124.73364306703067, 48.163103712027116],
              [-124.690571539115382, 48.212929174081737],
              [-124.65826789317893, 48.331479411384109],
              [-124.726464479044779, 48.370996157151566],
              [-124.715696597065971, 48.389895470344698],
              [-124.654678599185985, 48.390754530035295],
              [-124.511106839468397, 48.34350624705246],
              [-124.396249431694315, 48.288526426854261],
              [-124.360356491764918, 48.287667367163664],
              [-124.270624141941411, 48.254164039230382],
              [-124.249088377983782, 48.264472755517545],
              [-124.101927324273234, 48.217224472534717],
              [-124.105516618266165, 48.200043278722781],
              [-123.979891328513276, 48.164821831408304],
              [-123.778890864908647, 48.155372174811738],
              [-123.703515691056907, 48.166539950789499],
              [-123.592247577275771, 48.134754742237412],
              [-123.559943931339305, 48.151076876358758],
              [-123.524050991409908, 48.13561380192801],
              [-123.437907935579346, 48.141627219762192],
              [-123.394836407664059, 48.114137309663086],
              [-123.247675353953525, 48.115855429044281],
              [-123.132817946179443, 48.176848667076662],
              [-123.14358582815828, 48.156231234502336],
              [-123.064621360313595, 48.120150727497268],
              [-123.039496302363005, 48.081493041420408],
              [-122.978478304483048, 48.096097056160552],
              [-122.917460306603061, 48.091801757707572],
              [-122.928228188581869, 48.064311847608465],
              [-122.877978072680719, 48.047130653796529],
              [-122.874388778687774, 47.996446132051311],
              [-122.838495838758377, 48.001600490194896],
              [-122.827727956779569, 48.047130653796529],
              [-122.877978072680719, 48.076338683276823],
              [-122.877978072680719, 48.110701070900703],
              [-122.834906544765431, 48.134754742237412],
              [-122.759531370913692, 48.14334533914338],
              [-122.748763488934884, 48.116714488734878],
              [-122.80260289882898, 48.087506459254584],
              [-122.766709958899582, 48.044553474724736],
              [-122.741584900948993, 48.049707832868322],
              [-122.734406312963131, 48.090942698016974],
              [-122.69851337303372, 48.102969533685332],
              [-122.669799021090199, 48.017063564625637],
              [-122.723638430984295, 48.008472967719669],
              [-122.684156197061952, 47.972392460714602],
              [-122.655441845118446, 47.905385804848038],
              [-122.608781023210227, 47.887345551345504],
              [-122.637495375153748, 47.86586905908058],
              [-122.694924079040788, 47.867587178461775],
              [-122.687745491054898, 47.831506671456708],
              [-122.748763488934884, 47.800580522595219],
              [-122.781067134871336, 47.70350677755777],
              [-122.8313172507725, 47.695775240342392],
              [-122.791835016850158, 47.792848985379848],
              [-122.813370780807801, 47.807453000119992],
              [-122.881567366673664, 47.720687971369706],
              [-122.895924542645417, 47.675157807768073],
              [-122.971299716497157, 47.61502362942629],
              [-123.107692888228883, 47.45781570604705],
              [-123.154353710137087, 47.355587602866024],
              [-123.111282182221814, 47.362460080390797],
              [-123.028728420384198, 47.351292304413036],
              [-122.98565689246891, 47.374486916059155],
              [-122.910281718617171, 47.389090930799298],
              [-122.899513836638363, 47.421735199041983],
              [-123.057442772327718, 47.368473498224972],
              [-123.11846077020769, 47.391668109871091],
              [-122.967710422504211, 47.585815599945988],
              [-122.856442308723075, 47.649386017050162],
              [-122.752352782927829, 47.673439688386878],
              [-122.741584900948993, 47.736151045800447],
              [-122.684156197061952, 47.798862403214024],
              [-122.572888083280816, 47.857278462174612],
              [-122.605191729217282, 47.940607252162515],
              [-122.526227261372611, 47.906244864538635],
              [-122.475977145471447, 47.745600702397013],
              [-122.554941613316117, 47.745600702397013],
              [-122.54417373133731, 47.711238314773141],
              [-122.504691497414967, 47.699211479104783],
              [-122.51904867338672, 47.651104136431357],
              [-122.493923615436145, 47.634782002310018],
              [-122.493923615436145, 47.589251838708378],
              [-122.54417373133731, 47.556607570465694],
              [-122.547763025330255, 47.523963302223017],
              [-122.493923615436145, 47.510218347173463],
              [-122.529816555365556, 47.468983482024811],
              [-122.551352319323186, 47.394245288942884],
              [-122.536995143351419, 47.37620503544035],
              [-122.572888083280816, 47.32723863307632],
              [-122.547763025330255, 47.285144708237077],
              [-122.601602435224351, 47.217278992679923],
              [-122.69851337303372, 47.284285648546479],
              [-122.669799021090199, 47.366755378843784],
              [-122.72722772497724, 47.33067487183871],
              [-122.748763488934884, 47.276554111331109],
              [-122.641084669146693, 47.205252157011564],
              [-122.67697760907609, 47.191507201962011],
              [-122.673388315083145, 47.150272336813359],
              [-122.712870549005487, 47.127936784857837],
              [-122.770299252892528, 47.167453530625302],
              [-122.842085132751322, 47.25765479813797],
              [-122.799013604836034, 47.289440006690057],
              [-122.795424310843103, 47.34184264781647],
              [-122.820549368793678, 47.363319140081394],
              [-122.820549368793678, 47.319507095860949],
              [-122.863620896708966, 47.270540693496926],
              [-122.838495838758377, 47.208688395773947],
              [-122.86003160271602, 47.167453530625302],
              [-122.813370780807801, 47.179480366293653],
              [-122.773888546885459, 47.122782426714259],
              [-122.67697760907609, 47.103883113521128],
              [-122.637495375153748, 47.135668322073215],
              [-122.637495375153748, 47.164017291862912],
              [-122.590834553245514, 47.177762246912458],
              [-122.562120201302008, 47.245627962469619],
              [-122.526227261372611, 47.291158126071252],
              [-122.533405849358488, 47.316929916789164],
              [-122.443673499534981, 47.267104454734543],
              [-122.407780559605584, 47.28858094699946],
              [-122.422137735577351, 47.319507095860949],
              [-122.325226797767968, 47.348715125341243],
              [-122.325226797767968, 47.391668109871091],
              [-122.353941149711488, 47.441493571925712],
              [-122.382655501655009, 47.450943228522277],
              [-122.361119737697379, 47.481010317693169],
              [-122.397012677626776, 47.515372705317048],
              [-122.422137735577351, 47.576365943349423],
              [-122.343173267732666, 47.609010211592107],
              [-122.429316323563228, 47.658835673646728],
              [-122.371887619676187, 47.729278568275674],
              [-122.393423383633831, 47.807453000119992],
              [-122.339583973739735, 47.846969745887449],
              [-122.307280327803269, 47.949197849068483],
              [-122.228315859958599, 47.970674341333407],
              [-122.23190515395153, 48.029949459984593],
              [-122.282155269852694, 48.049707832868322],
              [-122.36470903169031, 48.123586966259651],
              [-122.361119737697379, 48.187157383363825],
              [-122.397012677626776, 48.229251308203075],
              [-122.450852087520872, 48.232687546965465],
              [-122.44008420554205, 48.212070114391139],
              [-122.479566439464378, 48.188016443054423],
              [-122.443673499534981, 48.130459443784432],
              [-122.379066207662078, 48.087506459254584],
              [-122.375476913669132, 48.057439370083692],
              [-122.46879855748557, 48.130459443784432],
              [-122.511870085400844, 48.133895682546814],
              [-122.536995143351419, 48.183721144601435],
              [-122.529816555365556, 48.249868740777401],
              [-122.465209263492625, 48.270486173351728],
              [-122.407780559605584, 48.251586860158596],
              [-122.371887619676187, 48.287667367163664],
              [-122.407780559605584, 48.326325053240524],
              [-122.508280791407913, 48.364982739317384],
              [-122.554941613316117, 48.406217604466036],
              [-122.551352319323186, 48.439720932399318],
              [-122.655441845118446, 48.411371962609621],
              [-122.680566903069021, 48.439720932399318],
              [-122.666209727097254, 48.478378618476178],
              [-122.712870549005487, 48.463774603736027],
              [-122.702102667026665, 48.497277931669309],
              [-122.615959611196104, 48.521331603006026],
              [-122.569298789287885, 48.50844570764707],
              [-122.536995143351419, 48.46635178280782],
              [-122.46879855748557, 48.472365200641995],
              [-122.504691497414967, 48.565143647226463],
              [-122.443673499534981, 48.570298005370049],
              [-122.425727029570282, 48.599506034850343],
              [-122.501102203422022, 48.656203974429737],
              [-122.51904867338672, 48.71290191400913],
              [-122.490334321443214, 48.750700540395393],
              [-122.536995143351419, 48.776472331113304],
              [-122.598013141231405, 48.771317972969719],
              [-122.637495375153748, 48.736096525655249],
              [-122.605191729217282, 48.698297899268987],
              [-122.673388315083145, 48.732660286892859],
              [-122.644673963139624, 48.781626689256882],
              [-122.680566903069021, 48.803103181521806],
              [-122.712870549005487, 48.791076345853448],
              [-122.716459842998432, 48.846915225742251],
              [-122.791835016850158, 48.893304449034481],
              [-122.752352782927829, 48.911344702537015],
              [-122.770299252892528, 48.942270851398504],
              [-122.816960074800733, 48.956015806448058],
              [-122.759531370913692, 49.002405029740288],
              [-122.099101276212764, 49.002405029740288],
              [-121.750939758897573, 48.99725067159671],
              [-121.230492129921288, 49.00154597004969],
              [-120.717223088930879, 48.999827850668495],
              [-120.035257230272293, 48.999827850668495],
              [-119.428666545465447, 48.999827850668495],
              [-118.836433036630353, 49.000686910359093],
              [-118.197538705887041, 49.000686910359093],
              [-117.031018158181581, 48.998968790977905]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '55', name: 'Wisconsin' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.956323791337908, 45.352260404394038],
              [-86.934788027380264, 45.420985179641789],
              [-86.823519913599128, 45.406381164901639],
              [-86.906073675436744, 45.296421524505234],
              [-86.956323791337908, 45.352260404394038]
            ]
          ],
          [
            [
              [-87.376271188511879, 45.199347779467786],
              [-87.333199660596605, 45.211374615136144],
              [-87.326021072610729, 45.157253854628536],
              [-87.376271188511879, 45.177012227512265],
              [-87.376271188511879, 45.199347779467786]
            ]
          ],
          [
            [
              [-90.466653316433167, 47.002514070030692],
              [-90.437938964489646, 47.073816024350236],
              [-90.394867436574359, 47.077252263112626],
              [-90.412813906539057, 47.012822786317855],
              [-90.466653316433167, 47.002514070030692]
            ]
          ],
          [
            [
              [-90.524082020320193, 46.876232295512949],
              [-90.448706846468454, 46.904581265302646],
              [-90.498956962369618, 46.864205459844591],
              [-90.524082020320193, 46.876232295512949]
            ]
          ],
          [
            [
              [-90.653296604066043, 46.925198697876972],
              [-90.635350134101344, 46.943238951379506],
              [-90.527671314313139, 46.96815168240682],
              [-90.50972484434844, 46.956983906429059],
              [-90.549207078270783, 46.915749041280407],
              [-90.635350134101344, 46.906299384683841],
              [-90.653296604066043, 46.925198697876972]
            ]
          ],
          [
            [
              [-90.760975423854234, 46.839292728817277],
              [-90.750207541875412, 46.862487340463396],
              [-90.678421662016618, 46.897708787777873],
              [-90.674832368023672, 46.881386653656527],
              [-90.760975423854234, 46.839292728817277]
            ]
          ],
          [
            [
              [-90.73943965989659, 46.963856383953832],
              [-90.68918954399544, 46.917467160661602],
              [-90.735850365903659, 46.914889981589809],
              [-90.764564717847179, 46.946675190141896],
              [-90.73943965989659, 46.963856383953832]
            ]
          ],
          [
            [
              [-90.775332599826001, 47.023990562295616],
              [-90.649707310073097, 47.054916711157105],
              [-90.610225076150755, 47.007668428174277],
              [-90.559974960249605, 47.036876457654571],
              [-90.552796372263714, 46.999077831268302],
              [-90.610225076150755, 46.991346294052931],
              [-90.671243074030741, 46.949252369213681],
              [-90.710725307953069, 46.985332876218756],
              [-90.76815401184011, 47.002514070030692],
              [-90.775332599826001, 47.023990562295616]
            ]
          ],
          [
            [
              [-90.7932790697907, 46.785171968309676],
              [-90.732261071910713, 46.800635042740417],
              [-90.617403664136631, 46.874514176131754],
              [-90.567153548235481, 46.847024266032655],
              [-90.674832368023672, 46.818675296242958],
              [-90.656885898058974, 46.788608207072066],
              [-90.71790389593896, 46.785171968309676],
              [-90.789689775797754, 46.753386759757589],
              [-90.7932790697907, 46.785171968309676]
            ]
          ],
          [
            [
              [-90.983511651416507, 46.986191935909353],
              [-90.929672241522411, 47.000795950649497],
              [-90.933261535515356, 46.962997324263235],
              [-90.983511651416507, 46.986191935909353]
            ]
          ],
          [
            [
              [-92.013639027390269, 46.706138476774761],
              [-91.941853147531475, 46.68036668605685],
              [-91.819817151771517, 46.689816342653415],
              [-91.643941746117463, 46.734487446564458],
              [-91.5757451602516, 46.757682058210577],
              [-91.511137868378682, 46.757682058210577],
              [-91.33885175671756, 46.81781623655236],
              [-91.249119406894067, 46.841010848198472],
              [-91.227583642936423, 46.863346400153993],
              [-91.166565645056451, 46.844447086960862],
              [-91.141440587105862, 46.872796056750559],
              [-91.051708237282369, 46.881386653656527],
              [-90.972743769437685, 46.941520831998311],
              [-90.922493653536534, 46.931212115711148],
              [-90.87224353763537, 46.96127920488204],
              [-90.836350597705973, 46.957842966119657],
              [-90.750207541875412, 46.888259131181307],
              [-90.800457657776576, 46.822970594695938],
              [-90.886600713607137, 46.755963938829382],
              [-90.854297067670672, 46.693252581415805],
              [-90.911725771557713, 46.663185492244914],
              [-90.951208005480055, 46.597037896068954],
              [-90.904547183571836, 46.583292941019401],
              [-90.753796835868357, 46.646004298432977],
              [-90.735850365903659, 46.692393521725208],
              [-90.58510001820018, 46.599615075140747],
              [-90.538439196291961, 46.580715761947609],
              [-90.506135550355495, 46.589306358853584],
              [-90.416403200532002, 46.566111747207465],
              [-90.394867436574359, 46.532608419274183],
              [-90.351795908659085, 46.537762777417768],
              [-90.351795908659085, 46.537762777417768],
              [-90.315902968729688, 46.517145344843442],
              [-90.218992030920305, 46.503400389793889],
              [-90.11849179911799, 46.35993742146421],
              [-90.122081093110921, 46.336742809818091],
              [-89.091953717137173, 46.138300021290206],
              [-88.783274433744339, 46.016313545225444],
              [-88.740202905829051, 46.027481321203204],
              [-88.679184907949079, 46.013736366153651],
              [-88.664827731977311, 45.988823635126344],
              [-88.614577616076161, 45.988823635126344],
              [-88.603809734097339, 46.016313545225444],
              [-88.506898796287956, 46.018031664606639],
              [-88.49972020830208, 45.996555172341715],
              [-88.42434503445034, 45.978514918839181],
              [-88.381273506535067, 45.991400814198137],
              [-88.295130450704505, 45.951025008740082],
              [-88.244880334803341, 45.963051844408433],
              [-88.101308575085753, 45.921816979259788],
              [-88.101308575085753, 45.881441173801733],
              [-88.076183517135163, 45.863400920299199],
              [-88.13720151501515, 45.818729816388156],
              [-88.072594223142232, 45.780072130311297],
              [-87.997219049290493, 45.795535204742038],
              [-87.964915403354027, 45.758595638046373],
              [-87.878772347523466, 45.755159399283983],
              [-87.857236583565836, 45.726810429494286],
              [-87.810575761657617, 45.711347355063545],
              [-87.781861409714097, 45.673548728677282],
              [-87.82493293762937, 45.662380952699522],
              [-87.77468282172822, 45.59795147590475],
              [-87.803397173671726, 45.538676357253564],
              [-87.792629291692919, 45.498300551795509],
              [-87.814165055650548, 45.463938164171637],
              [-87.860825877558767, 45.434730134691343],
              [-87.850057995579945, 45.402085866448658],
              [-87.875183053530534, 45.381468433874332],
              [-87.850057995579945, 45.341092628416277],
              [-87.850057995579945, 45.341092628416277],
              [-87.753147057770576, 45.349683225322245],
              [-87.695718353883535, 45.3900590307803],
              [-87.656236119961193, 45.368582538515376],
              [-87.649057531975316, 45.339374509035082],
              [-87.688539765897659, 45.298139643886429],
              [-87.742379175791754, 45.196770600395993],
              [-87.735200587805878, 45.171857869368687],
              [-87.688539765897659, 45.14780419803197],
              [-87.659825413954138, 45.107428392573915],
              [-87.591628828088275, 45.095401556905564],
              [-87.627521768017672, 45.045576094850944],
              [-87.631111062010618, 44.976851319603192],
              [-87.763914939749398, 44.965683543625431],
              [-87.839290113601137, 44.931321156001552],
              [-87.828522231622316, 44.890945350543497],
              [-87.868004465544658, 44.840260828798279],
              [-87.90030811148111, 44.82737493343933],
              [-87.939790345403452, 44.756072979119786],
              [-87.982861873318726, 44.719992472114711],
              [-88.007986931269315, 44.630650264292633],
              [-87.997219049290493, 44.60917377202771],
              [-88.043879871198712, 44.566220787497869],
              [-87.972093991339904, 44.530140280492795],
              [-87.92902246342463, 44.536153698326977],
              [-87.903897405474055, 44.58168386192861],
              [-87.810575761657617, 44.636663682126816],
              [-87.77468282172822, 44.639240861198601],
              [-87.72084341183411, 44.69336162170621],
              [-87.72084341183411, 44.724287770567699],
              [-87.609575298052974, 44.838542709417084],
              [-87.516253654236536, 44.869468858278573],
              [-87.476771420314194, 44.863455440444397],
              [-87.437289186391865, 44.892663469924692],
              [-87.383449776497756, 44.865173559825593],
              [-87.404985540455399, 44.90469030559305],
              [-87.361914012540126, 44.988019095580945],
              [-87.265003074730743, 45.081656601856011],
              [-87.239878016780168, 45.167562570915699],
              [-87.19680648886488, 45.163267272462718],
              [-87.12143131501314, 45.190757182561818],
              [-87.117842021020209, 45.241441704307036],
              [-87.056824023140223, 45.292985285742851],
              [-86.977859555295552, 45.290408106671059],
              [-86.985038143281429, 45.215669913589132],
              [-87.04246684716847, 45.211374615136144],
              [-87.049645435154346, 45.089388139071382],
              [-87.092716963069634, 45.055025751447509],
              [-87.12143131501314, 45.058461990209892],
              [-87.139377784977853, 45.012931826608259],
              [-87.189627900879003, 44.968260722697217],
              [-87.171681430914305, 44.931321156001552],
              [-87.218342252822524, 44.89781782806827],
              [-87.20398507685077, 44.875482276112756],
              [-87.268592368723688, 44.847133306323059],
              [-87.315253190631907, 44.793871605506048],
              [-87.343967542575427, 44.719133412424114],
              [-87.404985540455399, 44.62721402553025],
              [-87.437289186391865, 44.604878473574729],
              [-87.469592832328317, 44.551616772757718],
              [-87.544968006180056, 44.321388775677747],
              [-87.512664360243605, 44.240637164761644],
              [-87.519842948229481, 44.179643926729263],
              [-87.562914476144755, 44.144422479414786],
              [-87.645468237982371, 44.104905733647328],
              [-87.656236119961193, 44.051644032830318],
              [-87.684950471904713, 44.019858824278238],
              [-87.735200587805878, 43.880691154401539],
              [-87.728021999820001, 43.810248259772592],
              [-87.699307647876481, 43.767295275242745],
              [-87.706486235862357, 43.679671186801862],
              [-87.781861409714097, 43.578302143311426],
              [-87.806986467664672, 43.461470025390248],
              [-87.878772347523466, 43.369550638496378],
              [-87.911075993459932, 43.235537326763257],
              [-87.882361641516411, 43.170248790277896],
              [-87.90030811148111, 43.137604522035211],
              [-87.871593759537589, 43.064584448334479],
              [-87.896718817488178, 43.015618045970456],
              [-87.846468701587014, 42.962356345153445],
              [-87.846468701587014, 42.889336271452706],
              [-87.82493293762937, 42.835215510945105],
              [-87.767504233742329, 42.784530989199887],
              [-87.781861409714097, 42.708074676736757],
              [-87.821343643636439, 42.616155289842894],
              [-87.799807879678795, 42.491591634706339],
              [-88.094129987099862, 42.495886933159326],
              [-88.639702674026736, 42.495027873468729],
              [-88.78686372773727, 42.491591634706339],
              [-89.483186762367623, 42.501041291302904],
              [-89.666240756007554, 42.505336589755892],
              [-90.48101049240492, 42.507054709137087],
              [-90.642528722087221, 42.508772828518275],
              [-90.642528722087221, 42.540558037070362],
              [-90.707136013960138, 42.634195543345427],
              [-90.76815401184011, 42.651376737157364],
              [-90.936850829508288, 42.68316194570945],
              [-91.055297531275315, 42.738141765907649],
              [-91.101958353183534, 42.88332285361853],
              [-91.145029881098807, 42.904799345883454],
              [-91.145029881098807, 42.963215404844043],
              [-91.173744233042328, 43.038812657616567],
              [-91.177333527035273, 43.131591104201036],
              [-91.145029881098807, 43.152208536775362],
              [-91.123494117141163, 43.196879640686397],
              [-91.058886825268246, 43.248423222122213],
              [-91.105547647176465, 43.313711758607582],
              [-91.202458584985848, 43.348933205922052],
              [-91.198869290992903, 43.412503623026225],
              [-91.2347622309223, 43.455456607556066],
              [-91.216815760957601, 43.500127711467108],
              [-91.245530112901122, 43.545657875068741],
              [-91.231172936929369, 43.583456501455011],
              [-91.267065876858766, 43.615241710007091],
              [-91.274244464844642, 43.676234948039472],
              [-91.245530112901122, 43.77330869307692],
              [-91.277833758837588, 43.837738169871692],
              [-91.385512578625779, 43.954570287792869],
              [-91.439351988519874, 44.001818570775697],
              [-91.579334454244545, 44.026731301803011],
              [-91.70854903799038, 44.104046673956731],
              [-91.719316919969202, 44.128959404984045],
              [-91.816227857778571, 44.164180852298514],
              [-91.877245855658558, 44.202838538375374],
              [-91.895192325623256, 44.274999552385516],
              [-91.923906677566777, 44.287885447744472],
              [-91.923906677566777, 44.333415611346105],
              [-91.974156793467927, 44.367777998969984],
              [-92.053121261312612, 44.401281326903259],
              [-92.232585960959611, 44.445093371123704],
              [-92.282836076860761, 44.477737639366389],
              [-92.318729016790158, 44.544744295232945],
              [-92.361800544705446, 44.559348309973089],
              [-92.541265244352445, 44.567079847188467],
              [-92.573568890288897, 44.604878473574729],
              [-92.623819006190061, 44.618623428624282],
              [-92.620229712197116, 44.639240861198601],
              [-92.731497825978252, 44.713979054280536],
              [-92.788926529865293, 44.737173665926655],
              [-92.806872999829991, 44.768099814788144],
              [-92.763801471914718, 44.836824590035896],
              [-92.77456935389354, 44.90125406683066],
              [-92.749444295942951, 44.937334573835734],
              [-92.770980059900594, 44.972556021150204],
              [-92.763801471914718, 45.028394901039],
              [-92.80328370583706, 45.057602930519295],
              [-92.745855001950019, 45.107428392573915],
              [-92.767390765907649, 45.18560282441824],
              [-92.753033589935896, 45.213092734517339],
              [-92.760212177921773, 45.291267166361656],
              [-92.6991941800418, 45.333361091200906],
              [-92.702783474034732, 45.358273822228213],
              [-92.648944064140636, 45.398649627686268],
              [-92.645354770147691, 45.441602612216116],
              [-92.727908531985321, 45.514622685916855],
              [-92.724319237992376, 45.541253536325357],
              [-92.77456935389354, 45.568743446424456],
              [-92.810462293822937, 45.561011909209086],
              [-92.885837467674676, 45.579052162711619],
              [-92.889426761667607, 45.624582326313252],
              [-92.864301703717032, 45.722515131041305],
              [-92.785337235872348, 45.764609055880548],
              [-92.713551356013554, 45.891749890088896],
              [-92.64176547615476, 45.932125695546951],
              [-92.552033126331253, 45.95188406843068],
              [-92.523318774387747, 45.984528336673357],
              [-92.473068658486582, 45.973360560695596],
              [-92.437175718557185, 46.021467903369029],
              [-92.351032662726624, 46.015454485534846],
              [-92.32949689876898, 46.066139007280064],
              [-92.293603958839583, 46.074729604186032],
              [-92.293603958839583, 46.663185492244914],
              [-92.214639490994912, 46.649440537195368],
              [-92.17515725707257, 46.686380103891032],
              [-92.207460903009022, 46.702702238012371],
              [-92.150032199121981, 46.714729073680729],
              [-92.139264317143173, 46.739641804708036],
              [-92.089014201242009, 46.749091461304609],
              [-92.013639027390269, 46.706138476774761]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '60', name: 'American Samoa' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-169.520853510935098, -14.220951959429593],
              [-169.42753186711866, -14.211502302833027],
              [-169.423942573125714, -14.255314347053469],
              [-169.456246219062166, -14.248441869528694],
              [-169.484960571005701, -14.272495540865409],
              [-169.520853510935098, -14.220951959429593]
            ]
          ],
          [
            [
              [-169.68596103461033, -14.164254019850198],
              [-169.61058586075859, -14.157381542325423],
              [-169.624943036730343, -14.175421795827958],
              [-169.671603858638576, -14.18573051211512],
              [-169.68596103461033, -14.164254019850198]
            ]
          ],
          [
            [
              [-170.845302994329927, -14.319743823848238],
              [-170.798642172421722, -14.289676734677347],
              [-170.741213468534681, -14.287958615296152],
              [-170.673016882668804, -14.229542556335563],
              [-170.658659706697051, -14.246723750147501],
              [-170.561748768887668, -14.245005630766308],
              [-170.547391592915915, -14.28280425715257],
              [-170.572516650866504, -14.271636481174811],
              [-170.67660617666175, -14.297408271892717],
              [-170.75915993849938, -14.373864584355843],
              [-170.798642172421722, -14.333488778897788],
              [-170.845302994329927, -14.319743823848238]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '66', name: 'Guam' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [144.621335339153347, 13.451937853768534],
              [144.639281809118074, 13.46482374912749],
              [144.761317804878047, 13.478568704177039],
              [144.836692978729786, 13.596259881788814],
              [144.858228742687402, 13.652098761677614],
              [144.883353800637963, 13.642649105081048],
              [144.90847885858858, 13.60570953838538],
              [144.958728974489702, 13.596259881788814],
              [144.922836034560305, 13.520662629016286],
              [144.807978626786223, 13.427025122741224],
              [144.775674980849772, 13.389226496354961],
              [144.768496392863881, 13.293870870698704],
              [144.72542486494865, 13.245763528025279],
              [144.66081757307569, 13.271535318743183],
              [144.635692515125129, 13.358300347493472],
              [144.664406867068635, 13.401253332023316],
              [144.621335339153347, 13.451937853768534]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        id: '69',
        name: 'Commonwealth of the Northern Mariana Islands'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [146.031927878378781, 16.00420419453194],
              [146.067820818308178, 16.041143761227609],
              [146.082177994279903, 16.027398806178059],
              [146.053463642336396, 15.988741120101199],
              [146.031927878378781, 16.00420419453194]
            ]
          ],
          [
            [
              [145.816570238802342, 17.60377333842338],
              [145.834516708767069, 17.620954532235316],
              [145.838106002760014, 17.578860607396074],
              [145.816570238802342, 17.60377333842338]
            ]
          ],
          [
            [
              [145.708891419014151, 18.068524631036304],
              [145.759141534915329, 18.114054794637944],
              [145.759141534915329, 18.164739316383162],
              [145.798623768837672, 18.164739316383162],
              [145.798623768837672, 18.108041376803762],
              [145.76632012290122, 18.096873600826008],
              [145.730427182971823, 18.049625317843173],
              [145.708891419014151, 18.068524631036304]
            ]
          ],
          [
            [
              [145.687355655056535, 15.132258608576084],
              [145.70171283102826, 15.156312279912797],
              [145.708891419014151, 15.243077308663082],
              [145.741195064950603, 15.232768592375919],
              [145.812980944809397, 15.290325591645914],
              [145.830927414774123, 15.271426278452783],
              [145.780677298872945, 15.202701503205027],
              [145.795034474844726, 15.148580742697423],
              [145.748373652936493, 15.154594160531602],
              [145.748373652936493, 15.091882803118029],
              [145.705302125021205, 15.107345877548774],
              [145.687355655056535, 15.132258608576084]
            ]
          ],
          [
            [
              [145.637105539155357, 18.778966995159948],
              [145.658641303113029, 18.81247032309323],
              [145.698123537035372, 18.788416651756513],
              [145.694534243042426, 18.744604607536068],
              [145.665819891098863, 18.72484623465234],
              [145.640694833148302, 18.746322726917263],
              [145.637105539155357, 18.778966995159948]
            ]
          ],
          [
            [
              [145.633516245162411, 16.367586443654432],
              [145.705302125021205, 16.369304563035627],
              [145.716070007000042, 16.338378414174137],
              [145.647873421134193, 16.331505936649364],
              [145.633516245162411, 16.367586443654432]
            ]
          ],
          [
            [
              [145.58326612926129, 15.016285550345501],
              [145.597623305233014, 15.054943236422361],
              [145.644284127141248, 15.101332459714595],
              [145.662230597105975, 15.054943236422361],
              [145.647873421134193, 15.034325803848034],
              [145.672998479084754, 14.999963416224158],
              [145.665819891098863, 14.947560775097749],
              [145.633516245162411, 14.922648044070439],
              [145.58326612926129, 15.016285550345501]
            ]
          ],
          [
            [
              [145.120247204172017, 14.124581591505912],
              [145.159729438094359, 14.168393635726353],
              [145.235104611946099, 14.198460724897245],
              [145.288944021840166, 14.190729187681875],
              [145.281765433854332, 14.160662098510983],
              [145.238693905939044, 14.149494322533222],
              [145.209979553995538, 14.115990994599944],
              [145.170497320073196, 14.110836636456362],
              [145.152550850108469, 14.130595009340091],
              [145.120247204172017, 14.124581591505912]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '31', name: 'Nebraska' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.05213107971079, 43.001014031230305],
            [-103.000467939779398, 43.000154971539708],
            [-102.487198898788989, 42.99929591184911],
            [-101.848304568045677, 42.99929591184911],
            [-101.625768340483404, 42.995859673086727],
            [-100.886373777937777, 42.997577792467915],
            [-100.11826486344863, 42.998436852158513],
            [-99.569102882528824, 42.997577792467915],
            [-98.901494199841991, 42.998436852158513],
            [-98.499493272632719, 42.998436852158513],
            [-98.467189626696268, 42.947752330413294],
            [-98.259010575105748, 42.874732256712562],
            [-98.129795991359913, 42.821470555895552],
            [-98.018527877578776, 42.762195437244365],
            [-97.950331291712914, 42.769926974459736],
            [-97.90725976379764, 42.79483970548705],
            [-97.874956117861174, 42.858410122591216],
            [-97.846241765917654, 42.867859779187782],
            [-97.77445588605886, 42.849819525685248],
            [-97.684723536235353, 42.842087988469878],
            [-97.620116244362436, 42.856692003210028],
            [-97.562687540475395, 42.847242346613456],
            [-97.505258836588368, 42.858410122591216],
            [-97.440651544715436, 42.846383286922865],
            [-97.415526486764861, 42.866141659806594],
            [-97.361687076870766, 42.854973883828833],
            [-97.30784766697667, 42.867000719497184],
            [-97.218115317153163, 42.845524227232268],
            [-97.214526023160232, 42.812879958989583],
            [-97.149918731287315, 42.795698765177647],
            [-97.131972261322602, 42.771645093840931],
            [-96.977632619626192, 42.76047731786317],
            [-96.948918267682672, 42.719242452714518],
            [-96.905846739767398, 42.733846467454669],
            [-96.805346507965069, 42.703779378283777],
            [-96.801757213972138, 42.669416990659897],
            [-96.726382040120399, 42.666839811588112],
            [-96.686899806198056, 42.653094856538559],
            [-96.712024864148631, 42.608423752627516],
            [-96.636649690296892, 42.551725813048122],
            [-96.611524632346317, 42.506195649446489],
            [-96.525381576515755, 42.51049094789947],
            [-96.507435106551057, 42.484719157181566],
            [-96.446417108671085, 42.490732575015741],
            [-96.396166992769921, 42.483860097490968],
            [-96.381809816798167, 42.446061471104706],
            [-96.414113462734619, 42.408262844718443],
            [-96.414113462734619, 42.342974308233075],
            [-96.374631228812277, 42.318061577205768],
            [-96.324381112911126, 42.229578429074287],
            [-96.360274052840523, 42.208101936809364],
            [-96.349506170861702, 42.166867071660711],
            [-96.270541703017031, 42.118759728987285],
            [-96.274130997009962, 42.047457774667741],
            [-96.220291587115867, 42.025981282402817],
            [-96.238238057080565, 42.012236327353264],
            [-96.18439864718647, 42.002786670756699],
            [-96.187987941179401, 41.977014880038794],
            [-96.130559237292374, 41.971860521895209],
            [-96.141327119271182, 41.915162582315816],
            [-96.162862883228826, 41.90571292571925],
            [-96.112612767327676, 41.853310284592837],
            [-96.10902347333473, 41.82066601635016],
            [-96.065951945419457, 41.800907643466431],
            [-96.076719827398264, 41.761390897698973],
            [-96.105434179341785, 41.74420970388703],
            [-96.083898415384155, 41.6978204805948],
            [-96.123380649306483, 41.682357406164051],
            [-96.094666297362963, 41.647135958849582],
            [-96.119791355313552, 41.6136326309163],
            [-96.08030912139121, 41.580129302983025],
            [-96.091077003370032, 41.532021960309599],
            [-96.037237593475936, 41.507968288972883],
            [-96.030059005490045, 41.53975349752497],
            [-95.997755359553594, 41.538894437834372],
            [-96.00493394753947, 41.472746841658413],
            [-95.933148067680676, 41.464156244752438],
            [-95.929558773687731, 41.364505320643197],
            [-95.954683831638306, 41.33959258961589],
            [-95.882897951779512, 41.316397977969771],
            [-95.929558773687731, 41.281176530655301],
            [-95.911612303723032, 41.227055770147693],
            [-95.925969479694785, 41.195270561595606],
            [-95.839826423864238, 41.17465312902128],
            [-95.882897951779512, 41.160049114281136],
            [-95.864951481814813, 41.080156563055624],
            [-95.882897951779512, 41.056961951409505],
            [-95.857772893828937, 41.031190160691601],
            [-95.861362187821868, 40.995109653686534],
            [-95.829058541885416, 40.97792845987459],
            [-95.836237129871293, 40.921230520295197],
            [-95.811112071920718, 40.898035908649078],
            [-95.847005011850115, 40.85422386442864],
            [-95.832647835878348, 40.782921910109096],
            [-95.879308657786567, 40.752854820938204],
            [-95.886487245772457, 40.721069612386117],
            [-95.832647835878348, 40.671244150331496],
            [-95.785987013970129, 40.657499195281943],
            [-95.750094074040732, 40.609391852608518],
            [-95.764451250012499, 40.585338181271808],
            [-95.76804054400543, 40.5312174207642],
            [-95.696254664146636, 40.528640241692408],
            [-95.692665370153691, 40.556989211482112],
            [-95.653183136231362, 40.541526137051363],
            [-95.699843958139581, 40.505445630046296],
            [-95.696254664146636, 40.471083242422417],
            [-95.656772430224294, 40.441875212942122],
            [-95.660361724217239, 40.40837188500884],
            [-95.617290196301965, 40.313875319043184],
            [-95.552682904429034, 40.291539767087663],
            [-95.556272198421979, 40.264049856988564],
            [-95.484486318563185, 40.247727722867225],
            [-95.477307730577294, 40.181580126691259],
            [-95.391164674746747, 40.116291590205897],
            [-95.419879026690268, 40.048425874648743],
            [-95.308610912909131, 40.00031853197531],
            [-95.785987013970129, 40.00031853197531],
            [-96.302845348953483, 40.00031853197531],
            [-97.246829669096684, 40.001177591665908],
            [-98.248242693126926, 40.002036651356505],
            [-98.833297613976129, 40.002036651356505],
            [-99.813174874048741, 40.001177591665908],
            [-100.757159194191942, 40.002036651356505],
            [-101.130445769457694, 40.002036651356505],
            [-102.052894325643251, 40.002895711047103],
            [-102.052894325643251, 41.001982131211307],
            [-102.555395484654838, 41.001982131211307],
            [-103.039950173701726, 41.001982131211307],
            [-104.05213107971079, 41.001123071520709],
            [-104.05213107971079, 43.001014031230305]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '45', name: 'South Carolina' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.108600630906309, 35.000591132701324],
            [-82.781974877548777, 35.085638042070414],
            [-82.756849819598187, 35.06759778856788],
            [-82.656349587795873, 35.119141370003696],
            [-82.552260062000613, 35.159517175461751],
            [-82.530724298042983, 35.155221877008763],
            [-82.458938418184175, 35.178416488654882],
            [-82.4338133602336, 35.170684951439512],
            [-82.390741832318326, 35.215356055350547],
            [-82.372795362353614, 35.181852727417265],
            [-82.290241600515998, 35.199033921229208],
            [-81.615454329843303, 35.174121190201895],
            [-81.238578460584606, 35.160376235152349],
            [-81.041167290972908, 35.149208459174588],
            [-81.033988702987031, 35.103678295572948],
            [-81.059113760937606, 35.062443430424295],
            [-81.041167290972908, 35.044403176921762],
            [-80.933488471184702, 35.107114534335338],
            [-80.782738123481238, 34.936161655906552],
            [-80.797095299452991, 34.820188597675973],
            [-80.158200968709679, 34.811598000770005],
            [-79.892593213232132, 34.805584582935822],
            [-79.67364627966279, 34.804725523245224],
            [-79.35778840828408, 34.545289496684958],
            [-79.246520294502943, 34.445638572575717],
            [-78.873233719237192, 34.134658964579643],
            [-78.539429377893782, 33.85116926668266],
            [-78.586090199802001, 33.844296789157887],
            [-78.715304783547836, 33.800484744937442],
            [-78.812215721357205, 33.743786805358049],
            [-78.937841011110109, 33.63984058279582],
            [-79.002448302983026, 33.571974867238666],
            [-79.027573360933602, 33.533317181161806],
            [-79.085002064820642, 33.483491719107185],
            [-79.135252180721807, 33.403599167881673],
            [-79.181913002630026, 33.254122781717811],
            [-79.171145120651204, 33.206874498734983],
            [-79.196270178601779, 33.165639633586331],
            [-79.246520294502943, 33.125263828128276],
            [-79.329074056340559, 33.090042380813806],
            [-79.361377702277025, 33.006713590825903],
            [-79.422395700156997, 33.015304187731871],
            [-79.483413698036983, 33.001559232682318],
            [-79.522895931959312, 33.0350625606156],
            [-79.580324635846353, 33.006713590825903],
            [-79.61621757577575, 32.952592830318295],
            [-79.605449693796942, 32.925961979909793],
            [-79.576735341853421, 32.934552576815761],
            [-79.576735341853421, 32.906203607026065],
            [-79.630574751747517, 32.889022413214128],
            [-79.695182043620434, 32.850364727137269],
            [-79.727485689556886, 32.805693623226226],
            [-79.849521685316844, 32.755009101481008],
            [-79.885414625246256, 32.787653369723692],
            [-79.924896859168584, 32.781639951889517],
            [-79.928486153161529, 32.75415004179041],
            [-79.867468155281557, 32.735250728597279],
            [-79.885414625246256, 32.684566206852061],
            [-79.975146975069748, 32.639895102941026],
            [-80.000272033020323, 32.605532715317146],
            [-80.03616497294972, 32.609828013770134],
            [-80.122308028780282, 32.590928700577003],
            [-80.183326026660268, 32.540244178831784],
            [-80.204861790617898, 32.555707253262526],
            [-80.334076374363747, 32.478391881108806],
            [-80.362790726307267, 32.496432134611339],
            [-80.416630136201363, 32.47237846327463],
            [-80.423808724187239, 32.498150253992534],
            [-80.470469546095458, 32.497291194301937],
            [-80.484826722067211, 32.46121068729687],
            [-80.430987312173116, 32.389908732977325],
            [-80.456112370123705, 32.326338315873151],
            [-80.570969777897773, 32.273076615056148],
            [-80.639166363763636, 32.255895421244205],
            [-80.646344951749512, 32.291116868558682],
            [-80.714541537615375, 32.325479256182554],
            [-80.754023771537717, 32.306579942989423],
            [-80.761202359523594, 32.279949092580921],
            [-80.71813083160832, 32.267063197221965],
            [-80.667880715707156, 32.216378675476747],
            [-80.721720125601252, 32.160539795587951],
            [-80.811452475424758, 32.109855273842733],
            [-80.84375612136121, 32.109855273842733],
            [-80.904774119241196, 32.052298274572742],
            [-80.886827649276483, 32.034258021070208],
            [-80.919131295212949, 32.037694259832591],
            [-80.998095763057634, 32.098687497864972],
            [-81.037577996979962, 32.084083483124829],
            [-81.112953170831702, 32.113291512605123],
            [-81.1308996407964, 32.166553213422127],
            [-81.116542464824647, 32.188888765377648],
            [-81.15602469874699, 32.246445764647639],
            [-81.120131758817578, 32.284244391033901],
            [-81.141667522775222, 32.34953292751927],
            [-81.15602469874699, 32.34609668875688],
            [-81.209864108641085, 32.436297956269556],
            [-81.195506932669318, 32.46550598574985],
            [-81.278060694506948, 32.535089820688199],
            [-81.278060694506948, 32.554848193571928],
            [-81.36779304433044, 32.577183745527449],
            [-81.410864572245714, 32.618418610676102],
            [-81.392918102281016, 32.653640057990572],
            [-81.407275278252783, 32.694874923139224],
            [-81.428811042210413, 32.701747400664004],
            [-81.410864572245714, 32.740405086740864],
            [-81.425221748217481, 32.767894996839964],
            [-81.425221748217481, 32.840915070540703],
            [-81.457525394153933, 32.853800965899651],
            [-81.464703982139824, 32.895894890738901],
            [-81.507775510055097, 32.950874710937107],
            [-81.489829040090399, 33.008431710207098],
            [-81.558025625956262, 33.045371276902763],
            [-81.615454329843303, 33.095196738957384],
            [-81.647757975779754, 33.094337679266786],
            [-81.744668913589138, 33.141585962249614],
            [-81.773383265532658, 33.180243648326474],
            [-81.766204677546767, 33.215465095640951],
            [-81.80568691146911, 33.211169797187964],
            [-81.852347733377329, 33.247250304193038],
            [-81.837990557405575, 33.273022094910942],
            [-81.93849078920789, 33.343464989539889],
            [-81.924133613236123, 33.370954899638988],
            [-81.93849078920789, 33.40445822757227],
            [-81.913365731257315, 33.441397794267935],
            [-81.927722907229068, 33.462015226842261],
            [-81.981562317123164, 33.484350778797783],
            [-82.046169608996081, 33.564243330023295],
            [-82.107187606876067, 33.596028538575382],
            [-82.150259134791341, 33.597746657956577],
            [-82.19691995669956, 33.630390926199254],
            [-82.200509250692505, 33.664753313823134],
            [-82.232812896628957, 33.699974761137604],
            [-82.247170072600724, 33.752377402264017],
            [-82.351259598395984, 33.836565251942517],
            [-82.455349124191244, 33.881236355853552],
            [-82.523545710057093, 33.943088653576531],
            [-82.555849355993558, 33.943947713267129],
            [-82.595331589915901, 34.030712742017414],
            [-82.645581705817051, 34.071947607166067],
            [-82.674296057760571, 34.129504606436058],
            [-82.717367585675859, 34.150122039010384],
            [-82.742492643626434, 34.208538097970973],
            [-82.742492643626434, 34.252350142191418],
            [-82.781974877548777, 34.297021246102453],
            [-82.792742759527599, 34.339974230632301],
            [-82.835814287442872, 34.365746021350205],
            [-82.846582169421694, 34.420725841548411],
            [-82.875296521365215, 34.475705661746609],
            [-82.904010873308735, 34.486014378033772],
            [-83.00451110511105, 34.472269422984226],
            [-83.087064866948666, 34.516081467204664],
            [-83.169618628786282, 34.605423675026742],
            [-83.230636626666268, 34.611437092860925],
            [-83.338315446454459, 34.681879987489872],
            [-83.349083328433281, 34.73685980768807],
            [-83.32036897648976, 34.759195359643591],
            [-83.323958270482706, 34.790121508505081],
            [-83.230636626666268, 34.880322776017756],
            [-83.119368512885131, 34.938738834978345],
            [-83.108600630906309, 35.000591132701324]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '72', name: 'Puerto Rico' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.338006071860718, 18.348578090170896],
              [-65.298523837938376, 18.330537836668363],
              [-65.255452310023102, 18.341705612646123],
              [-65.223148664086636, 18.310779463784634],
              [-65.284166661966623, 18.279853314923145],
              [-65.327238189881896, 18.29617544904449],
              [-65.338006071860718, 18.348578090170896]
            ]
          ],
          [
            [
              [-65.574899475394758, 18.115772914019139],
              [-65.39902406974069, 18.16216213731137],
              [-65.273398779987801, 18.13467222721227],
              [-65.338006071860718, 18.112336675256749],
              [-65.413381245712458, 18.106323257422574],
              [-65.4528634796348, 18.085705824848247],
              [-65.481577831578321, 18.096014541135411],
              [-65.542595829458293, 18.08141052639526],
              [-65.574899475394758, 18.115772914019139]
            ]
          ],
          [
            [
              [-65.592845945359457, 18.392390134391341],
              [-65.567720887408868, 18.380363298722983],
              [-65.567720887408868, 18.342564672336721],
              [-65.592845945359457, 18.392390134391341]
            ]
          ],
          [
            [
              [-67.269046240062394, 18.36661834367343],
              [-67.215206830168299, 18.382081418104178],
              [-67.154188832288327, 18.424175342943428],
              [-67.16854600826008, 18.478296103451029],
              [-67.143420950309505, 18.505786013550129],
              [-67.09317083440834, 18.516094729837292],
              [-67.021384954549546, 18.510940371693714],
              [-66.96036695666956, 18.48946387942879],
              [-66.780902257022575, 18.491181998809985],
              [-66.709116377163767, 18.472282685616854],
              [-66.622973321333205, 18.494618237572375],
              [-66.558366029460288, 18.490322939119388],
              [-66.468633679636795, 18.468846446854464],
              [-66.439919327693275, 18.4860276406664],
              [-66.33941909589096, 18.4860276406664],
              [-66.242508158081577, 18.468846446854464],
              [-66.185079454194536, 18.469705506545061],
              [-66.159954396243961, 18.453383372423723],
              [-66.138418632286317, 18.474000804998049],
              [-66.005614754547551, 18.443933715827157],
              [-65.991257578575784, 18.460255849948496],
              [-65.926650286702866, 18.444792775517755],
              [-65.905114522745222, 18.45080619335193],
              [-65.793846408964086, 18.422457223562233],
              [-65.743596293062936, 18.380363298722983],
              [-65.718471235112347, 18.391531074700744],
              [-65.700524765147648, 18.368336463054625],
              [-65.664631825218251, 18.363182104911047],
              [-65.614381709317087, 18.382081418104178],
              [-65.632328179281785, 18.347719030480299],
              [-65.621560297302977, 18.275558016470164],
              [-65.574899475394758, 18.249786225752253],
              [-65.600024533345334, 18.212846659056588],
              [-65.639506767267676, 18.229168793177926],
              [-65.628738885288854, 18.205115121841217],
              [-65.664631825218251, 18.206833241222405],
              [-65.689756883168826, 18.179343331123306],
              [-65.736417705077045, 18.180202390813903],
              [-65.775899938999387, 18.129517869068685],
              [-65.801024996949963, 18.058215914749141],
              [-65.829739348893483, 18.053061556605563],
              [-65.833328642886428, 18.015262930219301],
              [-65.923060992709921, 17.975746184451843],
              [-65.976900402604031, 17.968014647236465],
              [-66.019971930519304, 17.978323363523629],
              [-66.045096988469879, 17.955128751877517],
              [-66.080989928399276, 17.966296527855278],
              [-66.156365102251016, 17.929356961159606],
              [-66.192258042180427, 17.937088498374983],
              [-66.224561688116879, 17.912175767347669],
              [-66.253276040060399, 17.91990730456304],
              [-66.317883331933317, 17.976605244142441],
              [-66.360954859848604, 17.968014647236465],
              [-66.386079917799179, 17.938806617756171],
              [-66.461455091650919, 17.990350199191987],
              [-66.490169443594439, 17.990350199191987],
              [-66.579901793417932, 17.96200122940229],
              [-66.630151909319096, 17.982618661976616],
              [-66.67322343723437, 17.966296527855278],
              [-66.762955787057876, 18.006672333313333],
              [-66.788080845008452, 17.973169005380051],
              [-66.838330960909602, 17.949974393733932],
              [-66.913706134761341, 17.947397214662146],
              [-66.956777662676629, 17.931934140231398],
              [-66.985492014620149, 17.967155587545875],
              [-67.060867188471889, 17.974028065070648],
              [-67.100349422394217, 17.946538154971549],
              [-67.16854600826008, 17.962860289092887],
              [-67.211617536175368, 17.952551572805724],
              [-67.208028242182422, 17.998081736407357],
              [-67.172135302253025, 18.024712586815866],
              [-67.211617536175368, 18.035021303103029],
              [-67.200849654196546, 18.090860182991825],
              [-67.182903184231847, 18.103746078350781],
              [-67.179313890238902, 18.168175555145545],
              [-67.154188832288327, 18.194806405554054],
              [-67.190081772217724, 18.28157143430434],
              [-67.236742594125943, 18.299611687806873],
              [-67.269046240062394, 18.36661834367343]
            ]
          ],
          [
            [
              [-67.933065628756282, 18.111477615566152],
              [-67.861279748897488, 18.121786331853315],
              [-67.843333278932789, 18.08484676515765],
              [-67.897172688826885, 18.052202496914965],
              [-67.940244216742173, 18.079692407014065],
              [-67.933065628756282, 18.111477615566152]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '78', name: 'United States Virgin Islands' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-64.799611972919735, 18.337410314193136],
              [-64.752951151011516, 18.370913642126418],
              [-64.713468917089173, 18.367477403364028],
              [-64.659629507195078, 18.343423732027318],
              [-64.706290329103297, 18.339987493264928],
              [-64.702701035110351, 18.297893568425678],
              [-64.735004681046803, 18.315074762237622],
              [-64.781665502955022, 18.310779463784634],
              [-64.799611972919735, 18.337410314193136]
            ]
          ],
          [
            [
              [-64.907290792707926, 17.6810887105771],
              [-64.882165734757351, 17.71201485943859],
              [-64.896522910729104, 17.746377247062469],
              [-64.871397852778529, 17.772149037780373],
              [-64.835504912849132, 17.760981261802613],
              [-64.806790560905611, 17.779880574995744],
              [-64.745772563025625, 17.782457754067536],
              [-64.724236799067995, 17.75754502304023],
              [-64.584254333343338, 17.76184032149321],
              [-64.577075745357448, 17.746377247062469],
              [-64.691933153131529, 17.705142381913817],
              [-64.839094206842063, 17.679370591195905],
              [-64.907290792707926, 17.6810887105771]
            ]
          ],
          [
            [
              [-65.08316619836198, 18.339128433574331],
              [-65.06163043440435, 18.382081418104178],
              [-65.025737494474939, 18.36232304522045],
              [-64.982665966559665, 18.384658597175971],
              [-64.914469380693802, 18.36661834367343],
              [-64.796022678926789, 18.371772701817015],
              [-64.803201266912666, 18.352014328933286],
              [-64.85345138281383, 18.348578090170896],
              [-64.821147736877364, 18.312497583165829],
              [-64.878576440764405, 18.303047926569263],
              [-64.932415850658501, 18.322806299452992],
              [-64.95754090860909, 18.305625105641056],
              [-65.000612436524364, 18.347719030480299],
              [-65.08316619836198, 18.339128433574331]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '16', name: 'Idaho' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.916160750407499, 45.995696112651117],
            [-116.955642984329842, 46.07558866387663],
            [-116.980768042280417, 46.085038320473195],
            [-116.919750044400445, 46.164930871698708],
            [-116.962821572315718, 46.199293259322587],
            [-116.962821572315718, 46.253414019830188],
            [-116.991535924259239, 46.299803243122426],
            [-117.063321804118033, 46.365091779607788],
            [-117.038196746167458, 46.40632664475644],
            [-117.038196746167458, 47.477574078930779],
            [-117.041786040160389, 47.522245182841822],
            [-117.041786040160389, 48.125305085640846],
            [-117.034607452174512, 48.370996157151566],
            [-117.034607452174512, 48.749841480704802],
            [-117.031018158181581, 48.998968790977905],
            [-116.417248885388844, 48.999827850668495],
            [-116.047551604116038, 49.000686910359093],
            [-116.047551604116038, 48.310002919119185],
            [-116.051140898108969, 48.216365412844119],
            [-116.047551604116038, 47.976687759167582],
            [-115.907569138391381, 47.846110686196852],
            [-115.853729728497285, 47.828070432694318],
            [-115.82501537655375, 47.752473179921793],
            [-115.796301024610244, 47.757627538065371],
            [-115.771175966659655, 47.717251732607316],
            [-115.72451514475145, 47.69663430003299],
            [-115.731693732737313, 47.642513539525389],
            [-115.688622204822039, 47.595265256542561],
            [-115.735283026730258, 47.567775346443455],
            [-115.742461614716149, 47.53856731696316],
            [-115.702979380793806, 47.53427201851018],
            [-115.685032910829108, 47.485305616146157],
            [-115.627604206942067, 47.479292198311974],
            [-115.638372088920875, 47.460392885118843],
            [-115.717336556765559, 47.45352040759407],
            [-115.760408084680847, 47.42259425873258],
            [-115.720925850758505, 47.424312378113775],
            [-115.552229033090327, 47.349574185031841],
            [-115.527103975139738, 47.303184961739611],
            [-115.412246567365656, 47.264527275662751],
            [-115.326103511535109, 47.255936678756782],
            [-115.293799865598658, 47.220715231442306],
            [-115.30097845358452, 47.188070963199621],
            [-115.261496219662192, 47.182057545365446],
            [-115.243549749697493, 47.150272336813359],
            [-115.20047822178222, 47.139104560835598],
            [-115.139460223902233, 47.092715337543368],
            [-115.07126363803637, 47.022272442914421],
            [-115.049727874078741, 46.970728861478605],
            [-115.003067052170522, 46.971587921169203],
            [-114.931281172311714, 46.920044339733387],
            [-114.945638348283481, 46.859051101701006],
            [-114.88820964439644, 46.808366579955795],
            [-114.766173648636482, 46.758541117901174],
            [-114.787709412594126, 46.711292834918339],
            [-114.766173648636482, 46.696688820178196],
            [-114.712334238742386, 46.714729073680729],
            [-114.697977062770619, 46.740500864398633],
            [-114.647726946869469, 46.732769327183263],
            [-114.622601888918879, 46.706997536465359],
            [-114.644137652876523, 46.673494208532077],
            [-114.615423300933003, 46.639990880598802],
            [-114.54722671506714, 46.644286179051782],
            [-114.468262247222469, 46.631400283692827],
            [-114.453905071250702, 46.649440537195368],
            [-114.360583427434264, 46.669198910079096],
            [-114.331869075490744, 46.660608313173121],
            [-114.321101193511922, 46.610782851118501],
            [-114.356994133441333, 46.505118509175084],
            [-114.400065661356606, 46.502541330103291],
            [-114.374940603406031, 46.443266211452105],
            [-114.42160142531425, 46.387427331563309],
            [-114.425190719307182, 46.287776407454068],
            [-114.471851541215401, 46.267158974879742],
            [-114.450315777257771, 46.241387184161837],
            [-114.446726483264825, 46.173521468604676],
            [-114.514923069130688, 46.1675080507705],
            [-114.522101657116565, 46.12541412593125],
            [-114.461083659236579, 46.097065156141554],
            [-114.493387305173044, 46.047239694086933],
            [-114.479030129201291, 46.000850470794703],
            [-114.410833543335428, 45.977655859148584],
            [-114.432369307293072, 45.935561934309334],
            [-114.385708485384853, 45.889172711017103],
            [-114.410833543335428, 45.851374084630841],
            [-114.500565893158921, 45.850515024940243],
            [-114.565173185031838, 45.774058712477114],
            [-114.49697659916599, 45.710488295372947],
            [-114.500565893158921, 45.669253430224295],
            [-114.565173185031838, 45.637468221672208],
            [-114.536458833088318, 45.606542072810718],
            [-114.550816009060085, 45.561011909209086],
            [-114.461083659236579, 45.561011909209086],
            [-114.41442283732836, 45.50946832777327],
            [-114.364172721427209, 45.490569014580139],
            [-114.346226251462511, 45.459642865718649],
            [-114.26008319563195, 45.495723372723717],
            [-114.249315313653128, 45.545548834778337],
            [-114.202654491744909, 45.535240118491174],
            [-114.134457905879046, 45.557575670446695],
            [-114.066261320013197, 45.628018565075642],
            [-114.012421910119087, 45.658085654246534],
            [-114.019600498104978, 45.693307101561011],
            [-113.987296852168512, 45.704474877538765],
            [-113.933457442274417, 45.68213932558325],
            [-113.89756450234502, 45.644340699196988],
            [-113.904743090330896, 45.622005147241467],
            [-113.861671562415623, 45.623723266622662],
            [-113.807832152521513, 45.602246774357738],
            [-113.832957210472102, 45.520636103751031],
            [-113.76476062460624, 45.520636103751031],
            [-113.761171330613294, 45.481119357983573],
            [-113.782707094570938, 45.455347567265662],
            [-113.761171330613294, 45.406381164901639],
            [-113.732456978669774, 45.3900590307803],
            [-113.739635566655664, 45.329924852438516],
            [-113.6893854507545, 45.283535629146286],
            [-113.685796156761555, 45.253468539975394],
            [-113.599653100931008, 45.190757182561818],
            [-113.574528042980418, 45.128045825148241],
            [-113.513510045100446, 45.115159929789293],
            [-113.520688633086323, 45.092824377833772],
            [-113.45249204722046, 45.05932104990049],
            [-113.438134871248707, 45.006918408774084],
            [-113.445313459234583, 44.959670125791249],
            [-113.495563575135748, 44.948502349813488],
            [-113.456081341213405, 44.865173559825593],
            [-113.355581109411091, 44.819643396223952],
            [-113.341223933439323, 44.78528100860008],
            [-113.247902289622886, 44.823079634986343],
            [-113.133044881848818, 44.773254172931722],
            [-113.068437589975886, 44.679616666656656],
            [-113.050491120011188, 44.636663682126816],
            [-113.082794765947654, 44.595428816978163],
            [-113.007419592095914, 44.525844982039814],
            [-113.025366062060613, 44.49663695255952],
            [-113.003830298102969, 44.45110678895788],
            [-112.949990888208873, 44.416744401334007],
            [-112.885383596335956, 44.396126968759681],
            [-112.845901362413613, 44.358328342373419],
            [-112.813597716477162, 44.37722765556655],
            [-112.835133480434791, 44.422757819168183],
            [-112.781294070540696, 44.484610116891162],
            [-112.720276072660724, 44.50436848977489],
            [-112.659258074780738, 44.485469176581759],
            [-112.540811373013725, 44.483751057200564],
            [-112.501329139091382, 44.463133624626238],
            [-112.472614787147862, 44.480314818438174],
            [-112.3864717313173, 44.447670550195497],
            [-112.357757379373794, 44.486328236272357],
            [-112.350578791387903, 44.53873087739877],
            [-112.318275145451452, 44.53873087739877],
            [-112.285971499514986, 44.568797966569662],
            [-112.228542795627945, 44.562784548735479],
            [-112.185471267712671, 44.532717459564587],
            [-112.135221151811507, 44.53958993708936],
            [-112.106506799867987, 44.520690623896229],
            [-112.031131626016247, 44.54646241461414],
            [-111.99523868608685, 44.53529463863638],
            [-111.948577864178631, 44.556771130901303],
            [-111.869613396333961, 44.563643608426077],
            [-111.822952574425742, 44.509522847918468],
            [-111.715273754637536, 44.543885235542348],
            [-111.704505872658714, 44.560207369663686],
            [-111.618362816828167, 44.549039593685933],
            [-111.586059170891701, 44.562784548735479],
            [-111.47120176311762, 44.540448996779958],
            [-111.525041173011729, 44.595428816978163],
            [-111.467612469124688, 44.679616666656656],
            [-111.485558939089387, 44.70796563644636],
            [-111.413773059230593, 44.710542815518146],
            [-111.385058707287072, 44.755213919429188],
            [-111.324040709407086, 44.724287770567699],
            [-111.223540477604772, 44.62377778676786],
            [-111.230719065590648, 44.586838220072195],
            [-111.144576009760087, 44.536153698326977],
            [-111.123040245802457, 44.494059773487727],
            [-111.047665071950718, 44.474301400603999],
            [-111.051254365943649, 44.354033043920431],
            [-111.047665071950718, 44.124664106531057],
            [-111.047665071950718, 43.681389306183057],
            [-111.044075777957772, 43.659053754227536],
            [-111.044075777957772, 42.722678691476908],
            [-111.047665071950718, 42.582651961909612],
            [-111.047665071950718, 42.001927611066101],
            [-111.47120176311762, 41.999350431994316],
            [-112.174703385733849, 41.996773252922523],
            [-112.192649855698548, 42.001068551375504],
            [-112.88179430234301, 41.999350431994316],
            [-113.82218932849328, 41.988182656016555],
            [-114.041136262062608, 41.993337014160133],
            [-114.468262247222469, 41.995914193231926],
            [-114.597476830968304, 41.99419607385073],
            [-114.805655882558824, 42.001927611066101],
            [-115.031781404114028, 41.995914193231926],
            [-115.315335629556287, 41.995914193231926],
            [-116.36699876948768, 41.995914193231926],
            [-117.027428864188636, 42.000209491684913],
            [-117.027428864188636, 43.808530140391397],
            [-117.020250276202745, 43.859214662136615],
            [-116.980768042280417, 43.868664318733181],
            [-116.980768042280417, 43.915053542025412],
            [-116.937696514365143, 43.984637376963761],
            [-116.934107220372198, 44.021576943659433],
            [-116.97358945429454, 44.049066853758532],
            [-116.977178748287486, 44.0851473607636],
            [-116.934107220372198, 44.09975137550375],
            [-116.898214280442801, 44.153013076320754],
            [-116.901803574435746, 44.179643926729263],
            [-116.966410866308649, 44.194247941469406],
            [-116.97358945429454, 44.239778105071046],
            [-117.045375334153334, 44.229469388783883],
            [-117.102804038040375, 44.280153910529101],
            [-117.171000623906224, 44.258677418264178],
            [-117.217661445814457, 44.288744507435069],
            [-117.188947093870922, 44.336851850108495],
            [-117.242786503765032, 44.396126968759681],
            [-117.214072151821512, 44.42705311762117],
            [-117.224840033800334, 44.483751057200564],
            [-117.160232741927416, 44.524985922349217],
            [-117.124339801998019, 44.58168386192861],
            [-117.095625450054484, 44.665012651916513],
            [-117.045375334153334, 44.744905203142025],
            [-116.930517926379252, 44.786999127981275],
            [-116.930517926379252, 44.805039381483809],
            [-116.865910634506335, 44.870327917969171],
            [-116.833606988569869, 44.928743976929759],
            [-116.858732046520458, 44.97856943898438],
            [-116.847964164541636, 45.022381483204825],
            [-116.797714048640472, 45.060180109591087],
            [-116.776178284682842, 45.105710273192727],
            [-116.729517462774623, 45.1400726608166],
            [-116.704392404824034, 45.239723584925841],
            [-116.672088758887583, 45.283535629146286],
            [-116.675678052880528, 45.314461778007775],
            [-116.589534997049967, 45.443320731597311],
            [-116.553642057120555, 45.463079104481039],
            [-116.550052763127624, 45.510327387463867],
            [-116.463909707297063, 45.603105834048336],
            [-116.489034765247652, 45.649495057340566],
            [-116.535695587155857, 45.691588982179816],
            [-116.535695587155857, 45.737119145781449],
            [-116.593124291042898, 45.778354010930101],
            [-116.661320876908761, 45.780072130311297],
            [-116.697213816838172, 45.820447935769351],
            [-116.761821108711075, 45.816152637316364],
            [-116.819249812598116, 45.880582114111135],
            [-116.858732046520458, 45.903776725757247],
            [-116.916160750407499, 45.995696112651117]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '32', name: 'Nevada' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.999364290342896, 41.99419607385073],
            [-119.999364290342896, 41.995055133541328],
            [-119.209719611896105, 41.993337014160133],
            [-118.696450570905711, 41.991618894778938],
            [-118.603128927089273, 41.99419607385073],
            [-117.62325166701666, 41.998491372303718],
            [-117.027428864188636, 42.000209491684913],
            [-116.36699876948768, 41.995914193231926],
            [-115.315335629556287, 41.995914193231926],
            [-115.031781404114028, 41.995914193231926],
            [-114.805655882558824, 42.001927611066101],
            [-114.597476830968304, 41.99419607385073],
            [-114.468262247222469, 41.995914193231926],
            [-114.041136262062608, 41.993337014160133],
            [-114.041136262062608, 41.000264011830112],
            [-114.044725556055553, 40.771754134131335],
            [-114.044725556055553, 40.232264648436477],
            [-114.048314850048499, 40.104264754537539],
            [-114.048314850048499, 38.677366608456076],
            [-114.05190414404143, 38.404185626846264],
            [-114.048314850048499, 38.148185839048381],
            [-114.05190414404143, 37.999568512575117],
            [-114.048314850048499, 37.765904276732762],
            [-114.05190414404143, 37.756454620136196],
            [-114.05190414404143, 37.00048209241092],
            [-114.05190414404143, 36.625073007620067],
            [-114.044725556055553, 36.391408771777712],
            [-114.048314850048499, 36.193825042940425],
            [-114.066261320013197, 36.180939147581469],
            [-114.152404375843759, 36.023731224202237],
            [-114.238547431674306, 36.014281567605671],
            [-114.313922605526045, 36.058093611826109],
            [-114.306744017540169, 36.082147283162826],
            [-114.371351309413086, 36.143140521195207],
            [-114.418012131321305, 36.145717700266999],
            [-114.504155187151866, 36.129395566145654],
            [-114.511333775137743, 36.150872058410577],
            [-114.572351773017729, 36.151731118101175],
            [-114.615423300933003, 36.130254625836251],
            [-114.633369770897701, 36.142281461504609],
            [-114.665673416834167, 36.117368730477295],
            [-114.75540576665766, 36.090737880068794],
            [-114.730280708707085, 36.022013104821042],
            [-114.744637884678838, 35.985073538125377],
            [-114.701566356763564, 35.901744748137475],
            [-114.665673416834167, 35.875113897728973],
            [-114.697977062770619, 35.854496465154647],
            [-114.712334238742386, 35.806389122481221],
            [-114.694387768777688, 35.755704600736003],
            [-114.705155650756495, 35.711892556515558],
            [-114.68003059280592, 35.685261706107056],
            [-114.690798474784742, 35.651758378173774],
            [-114.658494828848276, 35.61911410993109],
            [-114.658494828848276, 35.530630961799609],
            [-114.68003059280592, 35.49970481293812],
            [-114.665673416834167, 35.449879350883499],
            [-114.626191182911825, 35.409503545425451],
            [-114.604655418954181, 35.353664665536648],
            [-114.572351773017729, 35.200752040610404],
            [-114.572351773017729, 35.138899742887425],
            [-114.647726946869469, 35.101960176191753],
            [-114.611834006940057, 35.083060862998622],
            [-114.633369770897701, 35.001450192391921],
            [-115.160995987859877, 35.424107560165595],
            [-115.412246567365656, 35.625127527765272],
            [-116.252141361713612, 36.27715383292832],
            [-117.375590381503798, 37.126763866928663],
            [-117.83143071860718, 37.465233385023843],
            [-118.053966946169453, 37.625018487474868],
            [-118.502628695286944, 37.948883990829898],
            [-118.857968800587997, 38.204883778627782],
            [-119.084094322143216, 38.361232642316416],
            [-119.493273837338364, 38.649876698356977],
            [-119.906042646526458, 38.933366396253959],
            [-119.999364290342896, 38.999513992429918],
            [-120.006542878328773, 39.225446691056902],
            [-120.002953584335842, 39.330251973309728],
            [-120.002953584335842, 39.72284225191251],
            [-119.999364290342896, 39.734010027890271],
            [-119.995774996349951, 40.071620486294854],
            [-119.995774996349951, 40.721069612386117],
            [-119.999364290342896, 40.750277641866411],
            [-119.999364290342896, 41.99419607385073]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '50', name: 'Vermont' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.342131676116765, 45.011213707227064],
            [-73.087291802618026, 45.015509005680052],
            [-72.674522993429932, 45.015509005680052],
            [-72.530951233712329, 45.007777468464674],
            [-72.308415006150057, 45.003482170011694],
            [-72.103825248552482, 45.005200289392889],
            [-71.500823857738581, 45.013790886298857],
            [-71.540306091660909, 44.988019095580945],
            [-71.49364526975269, 44.911562783117823],
            [-71.558252561625608, 44.847133306323059],
            [-71.572609737597375, 44.792153486124853],
            [-71.626449147491471, 44.747482382213818],
            [-71.586966913569128, 44.659858293772928],
            [-71.551073973639731, 44.62721402553025],
            [-71.558252561625608, 44.564502668116674],
            [-71.59773479554795, 44.553334892138913],
            [-71.57619903159032, 44.502650370393695],
            [-71.633627735477347, 44.483751057200564],
            [-71.658752793427936, 44.445093371123704],
            [-71.709002909329087, 44.411590043190422],
            [-71.795145965159648, 44.399563207522071],
            [-71.813092435124346, 44.354892103611029],
            [-71.870521139011387, 44.336851850108495],
            [-71.906414078940784, 44.348019626086256],
            [-72.032039368693688, 44.320529715987149],
            [-72.067932308623085, 44.268127074860743],
            [-72.046396544665441, 44.238919045380449],
            [-72.067932308623085, 44.189952643016426],
            [-72.032039368693688, 44.13153658405583],
            [-72.032039368693688, 44.091160778597782],
            [-72.075110896608962, 44.031885659946589],
            [-72.11818242452425, 43.994087033560326],
            [-72.11818242452425, 43.921066959859594],
            [-72.186379010390098, 43.863509960589596],
            [-72.182789716397167, 43.808530140391397],
            [-72.204325480354797, 43.770731514005135],
            [-72.27252206622066, 43.733791947309463],
            [-72.304825712157125, 43.695993320923201],
            [-72.326361476114755, 43.602355814648142],
            [-72.380200886008865, 43.574006844858438],
            [-72.398147355973563, 43.510436427754271],
            [-72.380200886008865, 43.48036933858338],
            [-72.416093825938262, 43.3643962803528],
            [-72.394558061980618, 43.312852698916984],
            [-72.437629589895892, 43.257872878718779],
            [-72.451986765867659, 43.139322641416406],
            [-72.43404029590296, 43.08348376152761],
            [-72.466343941839412, 43.055134791737913],
            [-72.444808177881782, 43.010463687826871],
            [-72.473522529825303, 42.971806001750011],
            [-72.520183351733522, 42.963215404844043],
            [-72.523772645726453, 42.91424900248002],
            [-72.556076291662919, 42.851537645066443],
            [-72.513004763747631, 42.76391355662556],
            [-72.491468999790001, 42.772504153531528],
            [-72.459165353853535, 42.726973989929895],
            [-73.26316720827208, 42.745873303123027],
            [-73.2918815602156, 42.801712183011823],
            [-73.277524384243847, 42.83349739156391],
            [-73.277524384243847, 42.897067808668076],
            [-73.255988620286203, 43.259590998099974],
            [-73.248810032300327, 43.554248471974709],
            [-73.295470854208546, 43.579161203002023],
            [-73.302649442194422, 43.624691366603656],
            [-73.370846028060285, 43.624691366603656],
            [-73.39597108601086, 43.567993427024263],
            [-73.431864025940257, 43.590328978979784],
            [-73.406738967989682, 43.68826178370783],
            [-73.349310264102641, 43.771590573695732],
            [-73.392381792017915, 43.820556976059756],
            [-73.374435322053216, 43.875536796257954],
            [-73.410328261982613, 43.933093795527945],
            [-73.406738967989682, 44.021576943659433],
            [-73.439042613926134, 44.044771555305545],
            [-73.410328261982613, 44.112637270862699],
            [-73.392381792017915, 44.189093583325828],
            [-73.324185206152066, 44.243214343833429],
            [-73.309828030180299, 44.274140492694919],
            [-73.334953088130874, 44.372073297422965],
            [-73.2918815602156, 44.440798072670717],
            [-73.306238736187353, 44.500073191321903],
            [-73.374435322053216, 44.575670444094435],
            [-73.388792498024984, 44.635804622436218],
            [-73.374435322053216, 44.66243547284472],
            [-73.36725673406734, 44.741468964379635],
            [-73.331363794137943, 44.788717247362463],
            [-73.378024616046162, 44.837683649726493],
            [-73.363667440074394, 44.891804410234094],
            [-73.338542382123819, 44.917576200952006],
            [-73.342131676116765, 45.011213707227064]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '22', name: 'Louisiana' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.880185371553708, 30.053266374553743],
              [-88.833524549645489, 29.999145614046135],
              [-88.81557807968079, 29.933857077560774],
              [-88.826345961659612, 29.847092048810481],
              [-88.862238901589009, 29.756890781297805],
              [-88.876596077560777, 29.759467960369598],
              [-88.847881725617256, 29.836783332523318],
              [-88.833524549645489, 29.922689301583013],
              [-88.837113843638434, 29.96220604735047],
              [-88.880185371553708, 30.053266374553743]
            ]
          ],
          [
            [
              [-89.343204296642966, 30.059279792387919],
              [-89.303722062720624, 30.091924060630603],
              [-89.188864654946542, 30.161507895568953],
              [-89.181686066960665, 30.149481059900594],
              [-89.228346888868884, 30.095360299392986],
              [-89.221168300883008, 30.071306628056277],
              [-89.174507478974789, 30.04295765826658],
              [-89.228346888868884, 30.000004673736733],
              [-89.239114770847706, 30.040380479194788],
              [-89.275007710777103, 30.03178988228882],
              [-89.33602570865709, 30.040380479194788],
              [-89.343204296642966, 30.059279792387919]
            ]
          ],
          [
            [
              [-89.626758522085211, 29.535253381123809],
              [-89.565740524205239, 29.543843978029777],
              [-89.598044170141691, 29.507763471024703],
              [-89.626758522085211, 29.535253381123809]
            ]
          ],
          [
            [
              [-90.37692096660966, 29.10830071489714],
              [-90.351795908659085, 29.131495326543259],
              [-90.305135086750866, 29.075656446654463],
              [-90.333849438694386, 29.063629610986105],
              [-90.37692096660966, 29.10830071489714]
            ]
          ],
          [
            [
              [-90.559974960249605, 29.093696700156997],
              [-90.419992494524948, 29.0653477303673],
              [-90.488189080390796, 29.05847525284252],
              [-90.559974960249605, 29.093696700156997]
            ]
          ],
          [
            [
              [-90.750207541875412, 29.055898073770734],
              [-90.660475192051919, 29.072220207892073],
              [-90.653296604066043, 29.057616193151929],
              [-90.746618247882481, 29.040434999339986],
              [-90.750207541875412, 29.055898073770734]
            ]
          ],
          [
            [
              [-92.017228321383215, 29.591092261012605],
              [-91.981335381453817, 29.614286872658724],
              [-91.93826385353853, 29.609991574205736],
              [-91.927495971559708, 29.632327126161258],
              [-91.848531503715037, 29.627172768017672],
              [-91.765977741877421, 29.574770126891266],
              [-91.733674095940955, 29.580783544725442],
              [-91.712138331983311, 29.555870813698135],
              [-91.765977741877421, 29.524944664836646],
              [-91.769567035870352, 29.490582277212766],
              [-91.819817151771517, 29.474260143091428],
              [-92.031585497354968, 29.573052007510071],
              [-92.017228321383215, 29.591092261012605]
            ]
          ],
          [
            [
              [-94.041590133401328, 33.019599486184859],
              [-93.377570744707441, 33.017881366803664],
              [-93.068891461314607, 33.017881366803664],
              [-92.505372304423034, 33.011867948969481],
              [-91.952621029510297, 33.007572650516501],
              [-91.166565645056451, 33.004136411754111],
              [-91.202458584985848, 32.961183427224263],
              [-91.21322646696467, 32.919948562075618],
              [-91.180922821028204, 32.901049248882487],
              [-91.130672705127054, 32.923384800838001],
              [-91.134261999119985, 32.980941800107992],
              [-91.094779765197643, 32.984378038870382],
              [-91.062476119261191, 32.922525741147403],
              [-91.069654707247068, 32.889022413214128],
              [-91.145029881098807, 32.844351309303086],
              [-91.162976351063506, 32.812566100750999],
              [-91.166565645056451, 32.751572862718625],
              [-91.055297531275315, 32.725801072000714],
              [-91.062476119261191, 32.702606460354595],
              [-91.119904823148232, 32.674257490564898],
              [-91.152208469084684, 32.641613222322221],
              [-91.141440587105862, 32.596942118411178],
              [-91.119904823148232, 32.58491528274282],
              [-91.048118943289424, 32.607250834698341],
              [-91.026583179331794, 32.646767580465799],
              [-91.001458121381205, 32.614982371913712],
              [-91.030172473324726, 32.583197163361625],
              [-91.08042258922589, 32.556566312953123],
              [-91.00504741537415, 32.513613328423276],
              [-90.987100945409452, 32.491277776467761],
              [-91.022993885338849, 32.485264358633579],
              [-91.084011883218835, 32.526499223782231],
              [-91.101958353183534, 32.525640164091634],
              [-91.112726235162341, 32.475814702037013],
              [-91.030172473324726, 32.433720777197763],
              [-90.994279533395328, 32.450901971009706],
              [-90.965565181451808, 32.420834881838815],
              [-90.994279533395328, 32.403653688026871],
              [-90.994279533395328, 32.353828225972251],
              [-90.911725771557713, 32.339224211232107],
              [-90.890190007600069, 32.373586598855979],
              [-90.875832831628315, 32.358123524425238],
              [-90.933261535515356, 32.290257808868084],
              [-90.97633306343063, 32.297130286392857],
              [-90.969154475444753, 32.251600122791224],
              [-90.987100945409452, 32.21551961578615],
              [-91.037351061310602, 32.242150466194659],
              [-91.062476119261191, 32.21895585454854],
              [-91.15938705707056, 32.201774660736604],
              [-91.166565645056451, 32.133908945179449],
              [-91.051708237282369, 32.124459288582884],
              [-91.058886825268246, 32.177720989399887],
              [-91.00504741537415, 32.157103556825561],
              [-91.037351061310602, 32.097828438174375],
              [-91.08042258922589, 32.078929124981244],
              [-91.098369059190588, 32.048002976119754],
              [-91.145029881098807, 32.081506304053036],
              [-91.162976351063506, 32.060029811788112],
              [-91.094779765197643, 32.037694259832591],
              [-91.094779765197643, 31.994741275302751],
              [-91.162976351063506, 31.982714439634393],
              [-91.188101409014081, 31.961237947369469],
              [-91.180922821028204, 31.918284962839621],
              [-91.267065876858766, 31.851278306973065],
              [-91.245530112901122, 31.833238053470531],
              [-91.26347658286582, 31.809184382133814],
              [-91.28860164081641, 31.823788396873965],
              [-91.295780228802286, 31.86072796356963],
              [-91.33885175671756, 31.851278306973065],
              [-91.363976814668149, 31.770526696056955],
              [-91.259887288872889, 31.761077039460389],
              [-91.274244464844642, 31.745613965029648],
              [-91.356798226682258, 31.74131866657666],
              [-91.396280460604601, 31.711251577405768],
              [-91.399869754597546, 31.620191250202495],
              [-91.464477046470464, 31.620191250202495],
              [-91.500369986399861, 31.644244921539212],
              [-91.518316456364559, 31.611600653296527],
              [-91.486012810428093, 31.585828862578623],
              [-91.421405518555176, 31.596996638556377],
              [-91.407048342583423, 31.569506728457277],
              [-91.450119870498696, 31.539439639286385],
              [-91.521905750357504, 31.523976564855644],
              [-91.518316456364559, 31.46040614775147],
              [-91.47165563445634, 31.395976670956706],
              [-91.47165563445634, 31.371063939929392],
              [-91.503959280392806, 31.365050522095217],
              [-91.532673632336326, 31.389963253122524],
              [-91.547030808308079, 31.432916237652371],
              [-91.579334454244545, 31.399412909719089],
              [-91.554209396293956, 31.385667954669543],
              [-91.550620102301025, 31.347010268592683],
              [-91.507548574385737, 31.315225060040596],
              [-91.514727162371614, 31.278285493344931],
              [-91.5757451602516, 31.261104299532988],
              [-91.640352452124517, 31.267117717367171],
              [-91.643941746117463, 31.234473449124486],
              [-91.600870218202175, 31.208701658406582],
              [-91.590102336223353, 31.173480211092105],
              [-91.625995276152764, 31.133963465324648],
              [-91.622405982159819, 31.109909793987931],
              [-91.564977278272778, 31.066956809458091],
              [-91.590102336223353, 31.01713134740347],
              [-91.636763158131572, 30.999091093900937],
              [-90.825582715727151, 30.999091093900937],
              [-90.344617320673208, 30.999950153591527],
              [-89.752383811838115, 31.001668272972722],
              [-89.72725875388754, 30.970742124111233],
              [-89.752383811838115, 30.951842810918102],
              [-89.74879451784517, 30.913185124841242],
              [-89.773919575795759, 30.896862990719903],
              [-89.806223221732211, 30.789480529395291],
              [-89.8313482796828, 30.768004037130368],
              [-89.816991103711032, 30.737077888268878],
              [-89.852884043640429, 30.661480635496346],
              [-89.824169691696909, 30.649453799827995],
              [-89.809812515725156, 30.567843129221288],
              [-89.773919575795759, 30.538635099740993],
              [-89.759562399823992, 30.505131771807712],
              [-89.720080165901649, 30.488809637686373],
              [-89.684187225972252, 30.451870070990708],
              [-89.684187225972252, 30.40548084769847],
              [-89.630347816078157, 30.339333251522511],
              [-89.630347816078157, 30.280058132871325],
              [-89.615990640106403, 30.223360193291924],
              [-89.569329818198185, 30.180407208762084],
              [-89.526258290282897, 30.180407208762084],
              [-89.587276288162883, 30.150340119591192],
              [-89.615990640106403, 30.156353537425368],
              [-89.677008637986376, 30.108246194751942],
              [-89.684187225972252, 30.075601926509258],
              [-89.72725875388754, 30.062716031150309],
              [-89.716490871908718, 30.02835364352643],
              [-89.816991103711032, 30.04295765826658],
              [-89.856473337633375, 30.004299972189713],
              [-89.838526867668676, 29.945883913229125],
              [-89.773919575795759, 29.937293316323156],
              [-89.72725875388754, 29.95876980858808],
              [-89.741615929859293, 29.908085286842862],
              [-89.712901577915773, 29.878877257362568],
              [-89.659062168021677, 29.862555123241229],
              [-89.612401346113458, 29.872004779837795],
              [-89.590865582155814, 29.896917510865102],
              [-89.572919112191116, 29.983682539615394],
              [-89.580097700176992, 29.994850315593148],
              [-89.493954644346445, 30.041239538885385],
              [-89.483186762367623, 30.079038165271648],
              [-89.418579470494706, 30.049830135791353],
              [-89.371918648586487, 30.054984493934931],
              [-89.371918648586487, 30.036944240432398],
              [-89.432936646466459, 30.007736210952103],
              [-89.432936646466459, 29.978528181471809],
              [-89.379097236572363, 29.963924166731665],
              [-89.368329354593541, 29.911521525605252],
              [-89.314489944699446, 29.92354836127361],
              [-89.275007710777103, 29.993991255902557],
              [-89.217579006890062, 29.972514763637633],
              [-89.23193618286183, 29.925266480654798],
              [-89.278597004770049, 29.925266480654798],
              [-89.321668532685322, 29.887467854268536],
              [-89.242704064840638, 29.889185973649731],
              [-89.25347194681946, 29.864273242622424],
              [-89.364740060600596, 29.845373929429286],
              [-89.318079238692377, 29.788675989849892],
              [-89.278597004770049, 29.807575303043023],
              [-89.303722062720624, 29.756890781297805],
              [-89.33602570865709, 29.779226333253327],
              [-89.38627582455824, 29.788675989849892],
              [-89.429347352473528, 29.741427706867064],
              [-89.432936646466459, 29.656380797497967],
              [-89.465240292402925, 29.652085499044986],
              [-89.486776056360554, 29.725964632436316],
              [-89.526258290282897, 29.726823692126914],
              [-89.533436878268773, 29.67012575254752],
              [-89.486776056360554, 29.620300290492899],
              [-89.533436878268773, 29.648649260282596],
              [-89.583686994169938, 29.652944558735584],
              [-89.612401346113458, 29.638340543995433],
              [-89.666240756007554, 29.643494902139018],
              [-89.601633464134636, 29.609991574205736],
              [-89.601633464134636, 29.582501664106637],
              [-89.684187225972252, 29.624595588945887],
              [-89.684187225972252, 29.603119096680963],
              [-89.641115698056979, 29.575629186581864],
              [-89.684187225972252, 29.563602350913506],
              [-89.680597931979321, 29.534394321433211],
              [-89.641115698056979, 29.503468172571722],
              [-89.569329818198185, 29.494018515975156],
              [-89.572919112191116, 29.475119202782025],
              [-89.529847584275842, 29.454501770207699],
              [-89.533436878268773, 29.425293740727405],
              [-89.508311820318198, 29.385776994959947],
              [-89.476008174381747, 29.411548785677851],
              [-89.458061704417034, 29.393508532175318],
              [-89.379097236572363, 29.391790412794123],
              [-89.321668532685322, 29.362582383313828],
              [-89.3109006507065, 29.388354174031733],
              [-89.239114770847706, 29.310179742187415],
              [-89.199632536925364, 29.347978368573678],
              [-89.113489481094803, 29.252622742917424],
              [-89.113489481094803, 29.201938221172206],
              [-89.091953717137173, 29.190770445194445],
              [-89.027346425264255, 29.214824116531162],
              [-89.002221367313666, 29.179602669216685],
              [-89.088364423144228, 29.166716773857736],
              [-89.095543011130104, 29.138367804068032],
              [-89.038114307243063, 29.135790624996247],
              [-89.066828659186584, 29.091119521085204],
              [-89.106310893108926, 29.111736953659531],
              [-89.1493824210242, 29.029267223362226],
              [-89.142203833038323, 28.991468596975963],
              [-89.217579006890062, 29.022394745837452],
              [-89.224757594875939, 29.069643028820281],
              [-89.25347194681946, 29.083387983869834],
              [-89.364740060600596, 28.964837746567461],
              [-89.418579470494706, 28.929616299252984],
              [-89.393454412544116, 28.987173298522983],
              [-89.361150766607665, 29.012086029550289],
              [-89.343204296642966, 29.053320894698942],
              [-89.3109006507065, 29.071361148201476],
              [-89.3109006507065, 29.117750371493713],
              [-89.278597004770049, 29.137508744377442],
              [-89.28218629876298, 29.18132078859788],
              [-89.318079238692377, 29.201938221172206],
              [-89.332436414664144, 29.148676520355195],
              [-89.389865118551185, 29.123763789327889],
              [-89.432936646466459, 29.148676520355195],
              [-89.483186762367623, 29.214824116531162],
              [-89.605222758127582, 29.251763683226827],
              [-89.641115698056979, 29.290421369303687],
              [-89.842116161661608, 29.318770339093383],
              [-89.885187689576895, 29.306743503425032],
              [-90.057473801238004, 29.183897967669672],
              [-90.22258132491325, 29.085106103251029],
              [-90.247706382863825, 29.093696700156997],
              [-90.233349206892072, 29.128918147471467],
              [-90.276420734807346, 29.14266310252102],
              [-90.283599322793222, 29.17788454983549],
              [-90.262063558835578, 29.180461728907282],
              [-90.305135086750866, 29.266367697966977],
              [-90.344617320673208, 29.289562309613089],
              [-90.37692096660966, 29.288703249922492],
              [-90.373331672616729, 29.264649578585782],
              [-90.402046024560235, 29.233723429724293],
              [-90.394867436574359, 29.299011966209655],
              [-90.430760376503756, 29.34711930888308],
              [-90.477421198411975, 29.292139488684882],
              [-90.527671314313139, 29.30416632435324],
              [-90.552796372263714, 29.285267011160109],
              [-90.577921430214303, 29.31189786156861],
              [-90.599457194171933, 29.288703249922492],
              [-90.563564254242536, 29.23458248941489],
              [-90.606635782157824, 29.238018728177273],
              [-90.631760840108399, 29.214824116531162],
              [-90.646118016080152, 29.168434893238924],
              [-90.674832368023672, 29.174448311073107],
              [-90.692778837988371, 29.125481908709084],
              [-90.721493189931891, 29.135790624996247],
              [-90.789689775797754, 29.10744165520655],
              [-90.811225539755398, 29.042153118721181],
              [-90.868654243642439, 29.055898073770734],
              [-90.850707773677726, 29.073938327273268],
              [-90.897368595585945, 29.131495326543259],
              [-90.961975887458877, 29.180461728907282],
              [-91.001458121381205, 29.169293952929522],
              [-91.094779765197643, 29.187334206432062],
              [-91.130672705127054, 29.215683176221759],
              [-91.220405054950547, 29.225991892508922],
              [-91.277833758837588, 29.247468384773839],
              [-91.335262462724629, 29.299011966209655],
              [-91.292190934809341, 29.311038801878013],
              [-91.267065876858766, 29.360864263932633],
              [-91.238351524915245, 29.35055554764547],
              [-91.195279996999972, 29.305884443734435],
              [-91.170154939049382, 29.324783756927566],
              [-91.173744233042328, 29.266367697966977],
              [-91.119904823148232, 29.251763683226827],
              [-91.134261999119985, 29.341105891048905],
              [-91.216815760957601, 29.39608571124711],
              [-91.216815760957601, 29.432166218252178],
              [-91.256297994879944, 29.438179636086353],
              [-91.28860164081641, 29.402958188771883],
              [-91.335262462724629, 29.391790412794123],
              [-91.328083874738738, 29.427011860108593],
              [-91.363976814668149, 29.420998442274417],
              [-91.353208932689327, 29.454501770207699],
              [-91.320905286752861, 29.478555441544408],
              [-91.356798226682258, 29.512917829168288],
              [-91.460887752477518, 29.46996484463844],
              [-91.496780692406915, 29.538689619886192],
              [-91.532673632336326, 29.531817142361419],
              [-91.554209396293956, 29.633186185851855],
              [-91.647531040110394, 29.634045245542453],
              [-91.625995276152764, 29.662394215332149],
              [-91.622405982159819, 29.735414289032882],
              [-91.737263389933901, 29.749159244082435],
              [-91.78392421184212, 29.740568647176467],
              [-91.855710091700914, 29.707924378933782],
              [-91.880835149651489, 29.756890781297805],
              [-91.852120797707968, 29.792112228612282],
              [-91.823406445764448, 29.78953504954049],
              [-91.830585033750339, 29.829051795307947],
              [-91.888013737637365, 29.83592427283272],
              [-91.93826385353853, 29.817024959639589],
              [-91.970567499474996, 29.834206153451532],
              [-91.977746087460872, 29.799843765827653],
              [-92.063889143291433, 29.768917616966164],
              [-92.114139259192584, 29.739709587485869],
              [-92.135675023150228, 29.767199497584969],
              [-92.200282315023145, 29.753454542535422],
              [-92.200282315023145, 29.725964632436316],
              [-92.167978669086693, 29.700192841718412],
              [-92.103371377213762, 29.693320364193639],
              [-92.135675023150228, 29.667548573475727],
              [-92.106960671206707, 29.613427812968126],
              [-92.035174791347913, 29.63146806647066],
              [-92.017228321383215, 29.616004992039912],
              [-92.063889143291433, 29.603978156371561],
              [-92.04235337933379, 29.586796962559617],
              [-92.157210787107871, 29.581642604416039],
              [-92.250532430924309, 29.539548679576789],
              [-92.322318310783103, 29.531817142361419],
              [-92.616640418204184, 29.58593790286902],
              [-92.742265707957074, 29.622018409874094],
              [-92.946855465554648, 29.70878343862438],
              [-93.029409227392264, 29.736273348723479],
              [-93.176570281102812, 29.770635736347359],
              [-93.295016982869825, 29.774931034800346],
              [-93.345267098770989, 29.762904199131988],
              [-93.474481682516824, 29.768917616966164],
              [-93.68266073410733, 29.74744112470124],
              [-93.743678731987316, 29.736273348723479],
              [-93.837000375803754, 29.690743185121846],
              [-93.898018373683726, 29.771494796037956],
              [-93.930322019620192, 29.79726658675586],
              [-93.923143431634315, 29.818743079020784],
              [-93.862125433754329, 29.857400765097644],
              [-93.81546461184611, 29.920971182201818],
              [-93.790339553895535, 29.987977838068375],
              [-93.740089437994371, 30.021481166001657],
              [-93.700607204072043, 30.065293210222094],
              [-93.732910850008494, 30.08161534434344],
              [-93.689839322093221, 30.141749522685224],
              [-93.722142968029672, 30.209615238242378],
              [-93.704196498064974, 30.243118566175653],
              [-93.707785792057919, 30.288648729777293],
              [-93.76521449594496, 30.333319833688329],
              [-93.743678731987316, 30.396890250792502],
              [-93.697017910079097, 30.440702295012947],
              [-93.714964380043796, 30.518876726857265],
              [-93.740089437994371, 30.539494159431591],
              [-93.729321556015549, 30.573856547055463],
              [-93.679071440114399, 30.599628337773375],
              [-93.671892852128522, 30.658044396733963],
              [-93.628821324213234, 30.679520888998887],
              [-93.618053442234412, 30.742232246412456],
              [-93.585749796297961, 30.796353006920064],
              [-93.553446150361495, 30.824701976709761],
              [-93.567803326333262, 30.878822737217369],
              [-93.55703544435444, 30.911467005460047],
              [-93.524731798417974, 30.930366318653178],
              [-93.567803326333262, 30.977614601636013],
              [-93.564214032340317, 31.00596357142571],
              [-93.517553210432098, 31.024003824928243],
              [-93.524731798417974, 31.070393048220474],
              [-93.553446150361495, 31.097023898628983],
              [-93.531910386403865, 31.184647987069866],
              [-93.600106972269714, 31.174339270782703],
              [-93.621642736227358, 31.271413015820151],
              [-93.643178500185002, 31.269694896438956],
              [-93.686250028100275, 31.310070701897011],
              [-93.664714264142631, 31.355600865498651],
              [-93.675482146121453, 31.397694790337901],
              [-93.704196498064974, 31.41058068569685],
              [-93.700607204072043, 31.438070595795949],
              [-93.750857319973193, 31.468996744657439],
              [-93.729321556015549, 31.492191356303557],
              [-93.740089437994371, 31.522258445474449],
              [-93.78675025990259, 31.527412803618027],
              [-93.819053905839056, 31.554902713717134],
              [-93.840589669796699, 31.598714757937572],
              [-93.81546461184611, 31.622768429274288],
              [-93.826232493824932, 31.661426115351148],
              [-93.801107435874357, 31.697506622356215],
              [-93.829821787817878, 31.745613965029648],
              [-93.822643199832001, 31.774821994509942],
              [-93.876482609726096, 31.82207027749277],
              [-93.908786255662548, 31.893372231812315],
              [-93.973393547535466, 31.920003082220816],
              [-94.012875781457808, 31.981855379943795],
              [-94.041590133401328, 31.992164096230958],
              [-94.041590133401328, 33.019599486184859]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: { id: '44', name: 'Rhode Island' },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.195733868338678, 41.675484928639278],
              [-71.131126576465761, 41.660021854208537],
              [-71.120358694486939, 41.49765957268572],
              [-71.192144574345747, 41.458142826918262],
              [-71.213680338303377, 41.545766915359145],
              [-71.2065017503175, 41.600746735557351],
              [-71.242394690246897, 41.619646048750482],
              [-71.23521610226102, 41.483055557945569],
              [-71.296234100141007, 41.484773677326764],
              [-71.303412688126883, 41.454706588155872],
              [-71.33930562805628, 41.448693170321697],
              [-71.342894922049226, 41.495941453304525],
              [-71.285466218162185, 41.577552123911232],
              [-71.256751866218664, 41.635968182871821],
              [-71.195733868338678, 41.675484928639278]
            ]
          ],
          [
            [
              [-71.364430686006855, 41.660880913899135],
              [-71.346484216042157, 41.66689433173331],
              [-71.307001982119814, 41.622223227822275],
              [-71.324948452084513, 41.616209809988092],
              [-71.364430686006855, 41.660880913899135]
            ]
          ],
          [
            [
              [-71.400323625936252, 41.460720005990055],
              [-71.378787861978623, 41.504532050210493],
              [-71.393145037950376, 41.524290423094222],
              [-71.375198567985677, 41.573256825458245],
              [-71.360841392013924, 41.556075631646308],
              [-71.360841392013924, 41.483055557945569],
              [-71.400323625936252, 41.460720005990055]
            ]
          ],
          [
            [
              [-71.612091971519718, 41.160049114281136],
              [-71.572609737597375, 41.228773889528888],
              [-71.554663267632677, 41.21674705386053],
              [-71.551073973639731, 41.151458517375168],
              [-71.612091971519718, 41.160049114281136]
            ]
          ],
          [
            [
              [-71.859753257032565, 41.319834216732161],
              [-71.831038905089045, 41.344746947759468],
              [-71.841806787067867, 41.410035484244837],
              [-71.798735259152593, 41.41690796176961],
              [-71.787967377173771, 41.596451437104363],
              [-71.787967377173771, 41.725310390693899],
              [-71.798735259152593, 41.916021642006413],
              [-71.798735259152593, 42.007941028900284],
              [-71.382377155971554, 42.019108804878044],
              [-71.382377155971554, 41.892827030360294],
              [-71.33930562805628, 41.897981388503879],
              [-71.346484216042157, 41.82839755356553],
              [-71.328537746077458, 41.780290210892105],
              [-71.260341160211595, 41.751941241102401],
              [-71.224448220282198, 41.710706375953755],
              [-71.23521610226102, 41.672048689876895],
              [-71.285466218162185, 41.636827242562418],
              [-71.307001982119814, 41.672907749567486],
              [-71.292644806148061, 41.702974838738378],
              [-71.360841392013924, 41.749364062030615],
              [-71.389555743957445, 41.783726449654488],
              [-71.371609273992732, 41.672907749567486],
              [-71.450573741837417, 41.687511764307636],
              [-71.407502213922143, 41.654008436374355],
              [-71.403912919929198, 41.58957895957959],
              [-71.446984447844471, 41.580129302983025],
              [-71.418270095900951, 41.534599139381385],
              [-71.418270095900951, 41.472746841658413],
              [-71.454163035830362, 41.434089155581546],
              [-71.482877387773883, 41.371377798167977],
              [-71.525948915689156, 41.376532156311555],
              [-71.622859853498539, 41.361069081880814],
              [-71.719770791307909, 41.331861052400519],
              [-71.856163963039634, 41.306089261682608],
              [-71.859753257032565, 41.319834216732161]
            ]
          ]
        ]
      }
    }
  ]
};

<div class="narrative-choice d-flex flex-column" role="listbox" #container>
  <h1
    class="narrative-choice-header"
    *ngIf="header === undefined; else customHeader"
  >
    Pick one.
  </h1>
  <ng-template #customHeader>
    <h1 class="narrative-choice-header">{{ header }}</h1>
  </ng-template>
  <ng-container *ngFor="let choiceObs of choiceData$">
    <button
      role="option"
      *ngrxLet="choiceObs as choice"
      (click)="applyChoice(choice)"
    >
      <ng-container *ngIf="choice.type === 'generic'; else inlineChoice">
        <div class="d-flex flex-row align-items-center">
          <img
            *ngIf="choice.image"
            class="me-4 credit-img"
            [alt]="choice.text[0].content"
            [src]="choice.image"
          />
          <div class="ms-2 d-flex flex-column justify-content-between">
            <p
              class="text-start"
              *ngFor="let text of choice.text"
              [ngClass]="text.classOverride || ''"
            >
              {{ text.content }}
            </p>
          </div>
        </div>
      </ng-container>
      <ng-template #inlineChoice>
        <div
          class="d-flex flex-row justify-content-between w-100 align-items-center gap-4"
        >
          <span
            class="text flex-grow-1 text-start"
            [ngClass]="choice.text[0].classOverride || ''"
            >{{ choice.text[0].content }}</span
          >
          <div
            [ngClass]="{ retirement: choice.type === 'retirement' }"
            class="value-container d-flex flex-row align-items-center"
            *ngIf="choice.type !== 'plaintext'"
          >
            <img class="me-1" src="/assets/images/coin.svg" alt="" />
            <span class="wonky">{{
              choice.type === "retirement"
                ? "10 - 150"
                : (choice.value | number: "1.0-0")
            }}</span>
            <span *ngIf="choice.unit">{{ "/" + choice.unit }}</span>
          </div>
        </div>
      </ng-template>
    </button>
  </ng-container>
</div>

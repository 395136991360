<app-tutorial
  description="This tool will help you understand how credit card debt works.\nTry different options to see how your spending and payments affect the amount of money you owe and the rewards you earn."
>
  <div
    #scroll
    #modalContainer
    class="modal-container d-flex flex-column align-items-center pb-5 px-3"
    appCoverOnDelayed
  >
    <div class="budget-header d-flex justify-content-center align-items-center">
      <img src="/assets/images/coin.svg" alt="" />
      <h1 id="title" class="ms-3 mb-0 pt-3 wonky">Credit Cards</h1>
    </div>
    <div
      class="card-options d-flex justify-content-center align-items-center p-2 mt-4"
    >
      <span id="dropdown-label" class="me-4 fs-4 fw-bold">Card Options</span>
      <select
        class="option-select cursor-pointer"
        (change)="handleCardSelect($event)"
        appTutorialTip
        text="Select a credit card using the dropdown below."
        aria-labelledby="dropdown-label"
      >
        <option
          *ngFor="let card of cards | keyvalue"
          [value]="card.key"
          [selected]="credit.card === card.key"
        >
          {{ cards[card.key].name }}
        </option>
      </select>
    </div>
    <span class="text-uppercase w-50 fw-bold text-center mt-4 mb-3 fs-6">{{
      cards[credit.card].description
    }}</span>
    <span class="text-center mb-2 fs-6 w-50" *ngIf="credit.card === 'store'"
      >You can only use this card at this store, but you can buy groceries,
      clothes, home goods, basic needs, and more</span
    >
    <div class="modal-body d-flex flex-column w-100 align-items-center gap-5">
      <div class="credit-row gap-4">
        <span class="item d-flex">
          <span>Balance Owed</span>
          <app-tooltip-bubble
            [forAttribute]="'balance owed'"
            text="Your balance owed is the amount of money you spent using your credit card and need to pay back."
          >
          </app-tooltip-bubble>
        </span>
        <span
          class="custom-slider field"
          appTutorialTip
          text="Drag or click on slider to adjust the amount."
        >
          <ngx-slider
            [attr.aria-valuenow]="balance"
            role="slider"
            [(value)]="balance"
            (keydown.arrowleft)="balance = balance - 1"
            (keydown.arrowright)="balance = balance + 1"
            tabindex="0"
            [options]="{
              keyboardSupport: false,
              floor: 0,
              ceil: cards[credit.card].limit,
              hideLimitLabels: true,
              translate: translate
            }"
          >
          </ngx-slider>
        </span>
      </div>
      <div class="credit-row gap-4">
        <span class="item d-flex">
          <span>Interest Rate (APR)</span>
          <app-tooltip-bubble
            [forAttribute]="'interest rate'"
            text="The Annual Percentage Rate, or APR, combines the monthly interest rate and any annual fees to show you the total cost of using that card."
          >
          </app-tooltip-bubble>
        </span>
        <span
          class="field blue-pill"
          appTutorialTip
          text="Annual Percentage Rate is set by the credit card company and cannot be changed."
        >
          {{ cards[credit.card].apr * 100 + "%" }}
        </span>
      </div>
      <div
        class="gap-4 d-flex w-100 flex-column justify-content-center align-items-center"
      >
        <span class="payoff-text fw-bold gap-4 mb-0">Pay Off Options</span>
        <div class="d-flex justify-content-center align-items-center">
          <button
            (click)="calculateMethod = 'payment'"
            class="me-3 black-btn"
            [ngClass]="{ selected: calculateMethod === 'payment' }"
            appTutorialTip
            text="Choose either Amount or Number of Months to determine if you’d like to pay off your balance with a certain amount each month or over a certain amount of time."
          >
            AMOUNT
          </button>
          <span class="payoff-text me-3">OR</span>
          <button
            (click)="calculateMethod = 'months'"
            class="black-btn"
            [ngClass]="{ selected: calculateMethod === 'months' }"
          >
            NUMBER OF MONTHS
          </button>
        </div>
        <div *ngIf="calculateMethod === 'payment'" class="credit-row gap-4">
          <span class="item d-flex">
            <span>Monthly Payment Amount</span>
            <app-tooltip-bubble
              [forAttribute]="'monthly payment amount'"
              text="Your monthly payment amount is the amount of money you would like to put towards paying off your debt each month. The minimum payment will either be a fixed dollar amount or a percentage of your balance. For this example, your minimum payment is $25."
            >
            </app-tooltip-bubble>
          </span>
          <div
            class="click-options"
            appTutorialTip
            text="Click on the toggle bar to select an option."
            buttonLabel="DONE"
          >
            <div
              role="button"
              (click)="credit.payment = 25"
              (keydown.enter)="credit.payment = 25"
              tabindex="0"
              class="click-option"
              [ngClass]="{ selected: credit.payment === 25 }"
            >
              Minimum
            </div>
            <div
              role="button"
              (click)="credit.payment = 0.5"
              (keydown.enter)="credit.payment = 0.5"
              tabindex="0"
              class="click-option"
              [ngClass]="{ selected: credit.payment === 0.5 }"
            >
              Half
            </div>
            <div
              role="button"
              (click)="credit.payment = 1"
              (keydown.enter)="credit.payment = 1"
              tabindex="0"
              class="click-option"
              [ngClass]="{ selected: credit.payment === 1 }"
            >
              Full
            </div>
          </div>
        </div>
        <div *ngIf="calculateMethod === 'months'" class="credit-row gap-4">
          <span class="item d-flex">
            <span>Number of Months to Pay Off</span>
            <app-tooltip-bubble
              [forAttribute]="'number of months to pay off'"
              text="The number of months to pay off is the amount of time you would like to take to fully pay back your credit card debt."
            >
            </app-tooltip-bubble>
          </span>
          <div
            class="click-options"
            appTutorialTip
            text="Click on the toggle bar to select an option."
            buttonLabel="DONE"
          >
            <div
              role="button"
              (click)="credit.months = 6"
              (keydown.enter)="credit.months = 6"
              tabindex="0"
              class="click-option"
              [ngClass]="{ selected: credit.months === 6 }"
            >
              6 Months
            </div>
            <div
              role="button"
              (click)="credit.months = 12"
              (keydown.enter)="credit.months = 12"
              tabindex="0"
              class="click-option"
              [ngClass]="{ selected: credit.months === 12 }"
            >
              12 Months
            </div>
            <div
              role="button"
              (click)="credit.months = 24"
              (keydown.enter)="credit.months = 24"
              tabindex="0"
              class="click-option"
              [ngClass]="{ selected: credit.months === 24 }"
            >
              24 Months
            </div>
          </div>
        </div>
      </div>
      <button
        (click)="showCalculations = true; calculate()"
        class="teal-btn fs-3 mb-4"
      >
        {{ showCalculations ? "Recalculate" : "Calculate" }}
      </button>
    </div>
    <div
      *ngIf="showCalculations"
      class="calculations p-3 d-flex flex-column w-100 align-items-center gap-5 mb-4"
    >
      <div class="line"></div>
      <div class="credit-row gap-4">
        <span class="item d-flex">
          <span>Total Interest Paid</span>
          <app-tooltip-bubble
            [forAttribute]="'total interest paid'"
            text="Based on your card’s Annual Percentage Rate, this is how much money you owe in interest on top of your monthly purchases."
          >
          </app-tooltip-bubble>
        </span>
        <span class="field blue-pill">
          {{ "$ " + (totalInterest | currency: "" : "") }}
        </span>
      </div>
      <div class="credit-row gap-4">
        <span class="item d-flex">
          <span>Monthly Payment</span>
        </span>
        <span class="field blue-pill">
          {{ "$ " + (monthlyPayment | currency: "" : "") }}
        </span>
      </div>
      <div class="credit-row gap-4">
        <span class="item d-flex">
          <span>Months to Pay Off Debt</span>
        </span>
        <span class="field blue-pill">
          {{ balance === 0 ? "0" : monthsToPayoff }}
        </span>
      </div>
      <div class="credit-row gap-4">
        <span class="item d-flex">
          <span>Rewards Earned</span>
        </span>
        <span class="field blue-pill">
          {{ "$ " + (rewardsEarned | currency: "" : "") }}
        </span>
      </div>
      <button (click)="scrollModal(false)" class="fs-3 medium-teal-btn">
        Try Again
      </button>
      <button
        *ngIf="showCalculations"
        class="teal-btn fs-3"
        (click)="saveCredit()"
      >
        DONE
      </button>
    </div>
  </div>
</app-tutorial>

<div #printDiv>
  <div id="print-div">
    <div class="print-banner align-items-center">
      <img
        class="me-3 ms-5"
        [src]="'/assets/images/logo.svg'"
        alt="dollars and decisions logo"
      />
      <span class="fs-2 fw-bold">Assessment Results </span>
    </div>
    <div class="summary-section">
      <div class="summary-top">
        <div class="path no-print">
          <button
            aria-label="Go to dashboard"
            class="dashboard-text bg-transparent border-0"
            (click)="changeView.emit(teacherDashboardView.home)"
          >
            Dashboard
          </button>
          <img
            class="arrow-right"
            src="../../assets/icons/arrow-right.svg"
            alt="right-arrow-icon"
          />
          <span class="fw-bolder">Assessment Results</span>
        </div>

        <div class="view-toggle">
          <button
            class="view-btn text-uppercase"
            [class.selected]="assessmentType === 'pre-game'"
            (click)="assessmentType = 'pre-game'"
          >
            Pre-Game Assessment View
          </button>
          <button
            class="view-btn text-uppercase"
            [class.selected]="assessmentType === 'post-game'"
            (click)="assessmentType = 'post-game'"
          >
            Post-Game Assessment View
          </button>
        </div>

        <div class="icons no-print">
          <div class="download" (click)="downloadAsPDF()">
            <img src="../../../assets/icons/download.svg" alt="Download Icon" />
            <span>Download</span>
          </div>
          <div class="print" (click)="print()">
            <img src="../../../assets/icons/print.svg" alt="Print Icon" />
            <span>Print</span>
          </div>
        </div>
      </div>
      <div class="summary-body">
        <div class="class-average">
          <div class="class-score-text">
            <span>Class</span>
            <span>Average</span>
            <span>Score</span>
          </div>
          <app-circle-graph
            *ngIf="assessmentType === 'pre-game'"
            [notApplicable]="
              selectedClass?.assessmentResults?.preGameAverage === null
            "
            aria-label="circle graph for pre-game assessment average score"
            [size]="200"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#D8D8DD'"
            [percentComplete]="
              (selectedClass?.assessmentResults?.preGameAverage || 0) * 10
            "
            [average]="selectedClass?.assessmentResults?.preGameAverage || 0"
          ></app-circle-graph>

          <app-circle-graph
            *ngIf="assessmentType === 'post-game'"
            [notApplicable]="
              selectedClass?.assessmentResults?.postGameAverage === null
            "
            aria-label="circle graph for post-game assessment average score"
            [size]="200"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#D8D8DD'"
            [percentComplete]="
              (selectedClass?.assessmentResults?.postGameAverage || 0) * 10
            "
            [average]="selectedClass?.assessmentResults?.postGameAverage || 0"
          ></app-circle-graph>
        </div>

        <div class="missed-questions">
          <span class="missed-questions-title">Most Missed Questions</span>

          <div
            class="question-box"
            *ngFor="let missedQuestion of getMostMissedQuestions()"
          >
            <div class="question">
              <span class="question-number">{{
                "Q" + missedQuestion.qNum
              }}</span>
              <span class="question-text">{{
                getQuestionText(+missedQuestion.qNum)
              }}</span>

              <div class="performance">
                <span class="correct"
                  ><span class="percent fw-bolder"
                    >{{ missedQuestion.percentCorrect }}%</span
                  >
                  Correct</span
                >
                <span class="incorrect"
                  ><span class="percent fw-bolder"
                    >{{ 100 - missedQuestion.percentCorrect }}%</span
                  >
                  Incorrect</span
                >
              </div>
            </div>
            <hr class="question-separator" />
          </div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="view-by-options">
        <span>View By</span>
        <span
          class="clickable-text"
          [class.selected-text]="viewBy === 'STUDENTS'"
          (click)="viewBy = 'STUDENTS'"
        >
          STUDENTS
        </span>
        <span
          class="clickable-text"
          [class.selected-text]="viewBy === 'QUESTIONS'"
          (click)="viewBy = 'QUESTIONS'"
        >
          QUESTIONS
        </span>
      </div>

      <hr class="horizontal-separator" />

      <div *ngIf="viewBy === 'QUESTIONS'">
        <div class="assessment-selection">
          <button
            class="view-by-btn text-uppercase"
            [class.selected]="questionsType === 'pre'"
            (click)="questionsType = 'pre'"
          >
            Pre-Game Assessment
          </button>
          <button
            class="view-by-btn text-uppercase"
            [class.selected]="questionsType === 'post'"
            (click)="questionsType = 'post'"
          >
            Post-Game Assessment
          </button>
        </div>
        <div class="questions-selection">
          <span class="question-title">Question: </span>
          <span
            *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            class="question-circle"
            [class.selected-circle]="selectedQuestion === number"
            (click)="selectedQuestion = number"
          >
            {{ number }}
          </span>
        </div>

        <div *ngIf="selectedQuestion" class="selected-question-text">
          {{ getQuestionText(selectedQuestion) }}
        </div>
      </div>

      <div *ngIf="viewBy === 'STUDENTS'" class="students-table-container">
        <table
          aria-describedby="students table"
          [class.view-by-students]="viewBy === 'STUDENTS'"
        >
          <thead>
            <tr>
              <th scope="col">Student Name</th>
              <th scope="col">Pre-Game Assessment</th>
              <th scope="col">Post-Game Assessment</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let student of selectedClass?.assessmentResults?.byStudent
              "
            >
              <td>{{ student.name }}</td>
              <td>{{ student.pre ? student.pre + " / 10" : "N/A" }}</td>
              <td>{{ student.post ? student.post + " / 10" : "N/A" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="viewBy === 'QUESTIONS'" class="students-table-container">
        <table
          aria-describedby="questions table"
          [class.view-by-questions]="viewBy === 'QUESTIONS'"
        >
          <thead>
            <tr>
              <th scope="col">Student Name</th>
              <th scope="col">Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let studentAnswer of getStudentAnswers()">
              <td>{{ studentAnswer.name }}</td>
              <td>
                <div class="answer-content">
                  <div class="icon-container">
                    <img
                      *ngIf="studentAnswer.isCorrect"
                      src="../../../assets/icons/check.svg"
                      alt="Correct"
                    />
                    <img
                      *ngIf="!studentAnswer.isCorrect"
                      src="../../../assets/icons/x.svg"
                      alt="Incorrect"
                    />
                  </div>
                  <span [innerHTML]="studentAnswer.answer"></span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="position-relative sort-container">
  <img
    role="button"
    [attr.aria-expanded]="showDropdown"
    (click)="toggleDropdown()"
    class="sort-toggle"
    [ngClass]="{ open: showDropdown }"
    src="/assets/icons/arrow-down.svg"
    alt=""
  />

  <div
    role="listbox"
    *ngIf="showDropdown"
    class="sort-options position-absolute"
  >
    <ng-container *ngIf="columnType === 'string'">
      <span
        [attr.aria-selected]="selectedSortMethod === 'AtoZ'"
        role="option"
        tabindex="0"
        (keydown.enter)="onSortChange('AtoZ')"
        (click)="onSortChange('AtoZ')"
        [ngClass]="{ active: selectedSortMethod === 'AtoZ' }"
        >A to Z</span
      >
      <span
        [attr.aria-selected]="selectedSortMethod === 'ZtoA'"
        role="option"
        tabindex="0"
        (keydown.enter)="onSortChange('ZtoA')"
        (click)="onSortChange('ZtoA')"
        [ngClass]="{ active: selectedSortMethod === 'ZtoA' }"
        >Z to A</span
      >
    </ng-container>

    <ng-container *ngIf="columnType === 'number'">
      <span
        [attr.aria-selected]="selectedSortMethod === 'ASC'"
        role="option"
        tabindex="0"
        (keydown.enter)="onSortChange('ASC')"
        (click)="onSortChange('ASC')"
        [ngClass]="{ active: selectedSortMethod === 'ASC' }"
        >Ascending</span
      >
      <span
        [attr.aria-selected]="selectedSortMethod === 'DESC'"
        role="option"
        tabindex="0"
        (keydown.enter)="onSortChange('DESC')"
        (click)="onSortChange('DESC')"
        [ngClass]="{ active: selectedSortMethod === 'DESC' }"
        >Descending</span
      >
    </ng-container>
  </div>
</div>

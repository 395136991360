<app-tutorial
  description="Your Retirement Savings helps you see how much money you can save and grow over a long period of time.\nExperiment with the sliders below to observe how different factors can impact your future savings."
>
  <div
    #modalContainer
    class="modal-container d-flex flex-column p-3 align-items-center"
    *ngrxLet="job$ as job"
  >
    <section class="modal-header w-100 d-flex flex-column">
      <div class="d-flex flex-row align-content-center mb-5">
        <img src="/assets/images/coin.svg" alt="" class="me-4" />
        <h1 id="title" class="fw-bold text-uppercase wonky">
          your retirement savings
        </h1>
      </div>
      <span class="result darker-blue-pill"
        >Total Future Savings<span class="badge blue-pill text-dark ms-5">{{
          futureValue | currency
        }}</span></span
      >
      <span class="result"
        >Your Contribution<span
          class="badge blue-pill text-dark ms-5"
          appTutorialTip
          text="Your contribution is calculated for you based on the amount of money you’ve contributed to your retirement savings over time."
          >{{ totalContributed | currency }}</span
        ></span
      >
    </section>
    <div
      #content
      class="modal-body d-flex flex-column w-100 align-items-center gap-5"
    >
      <div class="retirement-row gap-5">
        <span class="item d-flex">
          <span>Pre-Tax Monthly Income</span>
          <app-tooltip-bubble
            [forAttribute]="'pre-tax monthly income'"
            text="How much pre-tax income you take home in 12 months."
          ></app-tooltip-bubble>
        </span>
        <span class="field blue-pill">
          {{ job.salary / 12 | currency }}
        </span>
      </div>
      <div class="retirement-row gap-5">
        <span class="item d-flex">
          <span>Percentage Contributed</span>
          <app-tooltip-bubble
            [forAttribute]="'percentage contributed'"
            text="How much of your pre-tax income you’re placing into your retirement account."
          >
          </app-tooltip-bubble>
        </span>
        <span
          class="custom-slider field position-relative"
          appTutorialTip
          text="Drag or click on slider to change the value."
          buttonLabel="DONE"
        >
          <div
            [style.left]="contribution * 9.1 + '%'"
            class="upper-label minor position-absolute"
          >
            {{ "$" + ((salary / 12) * (contribution / 100)).toFixed(0) }}
          </div>
          <ngx-slider
            [attr.aria-valuenow]="contribution"
            role="slider"
            [(value)]="contribution"
            (userChange)="updateFinalValue()"
            (keydown.arrowleft)="
              contribution = contribution - 1; updateFinalValue()
            "
            (keydown.arrowright)="
              contribution = contribution + 1; updateFinalValue()
            "
            tabindex="0"
            [options]="{
              keyboardSupport: false,
              floor: 0,
              ceil: 10,
              hideLimitLabels: true,
              translate: dualValueLabel.bind(this)
            }"
          >
          </ngx-slider>
        </span>
      </div>
      <div class="retirement-row gap-5">
        <span class="item d-flex">
          <span>Average Annual Growth Rate</span>
          <app-tooltip-bubble
            [forAttribute]="'average annual growth rate'"
            text="The AAGR is an annualized measure of the return that investors receive from their investments, portfolio, or cash flow over time."
          >
          </app-tooltip-bubble>
        </span>
        <span class="field blue-pill">{{ growthRate * 100 }}%</span>
      </div>
      <div class="retirement-row gap-5">
        <span class="item d-flex">
          <span [ngClass]="{ disabled: job.key !== 'coder' }"
            >Employer Matching</span
          >
          <app-tooltip-bubble
            [disabled]="job.key !== 'coder'"
            [forAttribute]="'employer matching'"
            text="Whether or not your employer will contribute an equal amount of money into your retirement account (up to a certain limit)."
          >
          </app-tooltip-bubble>
        </span>
        <span class="field custom-slider position-relative">
          <div
            [style.left]="(matching / 6) * 91 + '%'"
            class="upper-label minor position-absolute"
          >
            {{ "$" + ((salary / 12) * (matching / 100)).toFixed(0) }}
          </div>
          <ngx-slider
            [attr.aria-valuenow]="matching"
            role="slider"
            [(value)]="matching"
            (userChange)="updateFinalValue()"
            (keydown.arrowleft)="
              job.key === 'coder' && (matching = matching - 1);
              updateFinalValue()
            "
            (keydown.arrowright)="
              job.key === 'coder' && (matching = matching + 1);
              updateFinalValue()
            "
            [tabindex]="job.key === 'coder' ? '0' : '-1'"
            [options]="{
              keyboardSupport: false,
              floor: 0,
              ceil: 6,
              hideLimitLabels: true,
              hidePointerLabels: job.key !== 'coder',
              translate: dualValueLabel.bind(this),
              disabled: job.key !== 'coder'
            }"
          >
          </ngx-slider>
        </span>
      </div>
      <div class="retirement-row gap-5" *ngIf="job.key !== 'coder'">
        <span class="item"></span>
        <span class="field fst-italic matching-disabled"
          >Your employer does not offer matching contributions to retirement
          savings.</span
        >
      </div>
      <div class="retirement-row gap-5">
        <span class="item d-flex">
          <span>Years Until Retirement</span>
          <app-tooltip-bubble
            [forAttribute]="'years until retirement'"
            text="At what age you’re ready to stop working."
          ></app-tooltip-bubble>
        </span>
        <span class="field custom-slider">
          <ngx-slider
            [attr.aria-valuenow]="timeTillRetirement"
            role="slider"
            [(value)]="timeTillRetirement"
            (userChange)="updateCompoundValue(); updateFinalValue()"
            (keydown.arrowleft)="
              timeTillRetirement = timeTillRetirement - 1;
              updateCompoundValue();
              updateFinalValue()
            "
            (keydown.arrowright)="
              timeTillRetirement = timeTillRetirement + 1;
              updateCompoundValue();
              updateFinalValue()
            "
            tabindex="0"
            [options]="{
              keyboardSupport: false,
              floor: 10,
              ceil: 50,
              hideLimitLabels: true,
              translate: yearsLabel
            }"
          ></ngx-slider>
        </span>
      </div>
      <button class="teal-btn my-4 fs-2" (click)="handleClose()">DONE</button>
    </div>
  </div>
</app-tutorial>

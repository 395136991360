import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sorting-dropdown',
  templateUrl: './sorting-dropdown.component.html',
  styleUrls: ['./sorting-dropdown.component.scss']
})
export class SortingDropdownComponent {
  @Input() columnType!: 'number' | 'string';
  @Output() sortEvent = new EventEmitter<string>();
  showDropdown: boolean = false;
  selectedSortMethod: string = '';

  constructor() {}

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  onSortChange(sortType: 'ASC' | 'DESC' | 'AtoZ' | 'ZtoA') {
    this.selectedSortMethod = sortType;
    this.sortEvent.emit(sortType);
    this.toggleDropdown();
  }

  public openDropdown(): void {
    this.showDropdown = true;
  }
}

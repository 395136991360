import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticsType } from './interfaces';
import { firstValueFrom, from, switchMap } from 'rxjs';
import { UsersService } from './users.service';
import { ApiClient } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private usersService: UsersService,
    private deviceService: DeviceDetectorService,
    private apiClient: ApiClient
  ) {}

  generateTimestamp() {
    return new Date(new Date().setHours(0, 0, 0, 0));
  }

  fetchAnalytics() {
    return from(this.usersService.getCurrentUser()).pipe(
      switchMap((user) => (user as any).getIdToken(true)),
      switchMap((token) => {
        return this.apiClient.fetchAnalytics(token as string);
      })
    );
  }

  async sendAnalytics(type: AnalyticsType, stats: any = {}, idToken = '') {
    let body = {};

    if (type === AnalyticsType.COURSE_STARTED) {
      const locationStr = await this.fetchLocation();
      body = {
        locationStr,
        deviceStr:
          this.deviceService.userAgent &&
          this.deviceService.device === 'Unknown'
            ? 'Desktop'
            : this.deviceService.device,
        browserStr: this.deviceService.browser
      };
    } else if (
      type === AnalyticsType.PRE_ASSESSMENT ||
      type === AnalyticsType.COURSE_FINISHED ||
      type === AnalyticsType.MODULE_COMPLETED
    ) {
      body = { stats };
    }

    this.apiClient.sendAnalytics(type, body, idToken).subscribe();
  }

  async fetchLocation() {
    const locationData = await (firstValueFrom(
      this.apiClient.fetchLocation()
    ) as any);

    if (!locationData?.state || locationData?.countryName === 'Not found') {
      return 'Not Found';
    } else if (locationData.country_code !== 'US') {
      return 'Outside of U.S.';
    } else {
      return locationData.state;
    }
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import {
  selectBudget,
  selectProgress,
  selectJob,
  selectShowBudgetButton
} from '../shared/ngrx/selectors/progress.selectors';
import { ModifyBudget } from '../shared/ngrx/actions/progress.actions';
import { ModalClosed } from '../shared/ngrx/actions/modals/modals.actions';
import { ScrollToRetirement } from '../shared/ngrx/actions/overlays.actions';
import { combineLatest, Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { TutorialComponent } from '../shared/tutorial/tutorial.component';

@Component({
  selector: 'app-budget-modal',
  templateUrl: './budget-modal.component.html',
  styleUrls: ['./budget-modal.component.scss']
})
export class BudgetModalComponent implements OnInit, AfterViewInit {
  job = this.store.select(selectJob);
  enableRetirement = false;
  budget: any = {};
  monthlyIncome!: number;
  @HostBinding('role') role = 'main';
  @ViewChild('modalContainer') container!: ElementRef<HTMLDivElement>;
  @ViewChild('done') done!: ElementRef<HTMLDivElement>;
  @ViewChild('tutorial') tutorial!: TutorialComponent;
  AfterViewInit$ = new Subject<void>();
  updateBalance = true;
  skipNotification = false;
  firstTime = true;
  private scrollRetirementIntoView = combineLatest([
    this.AfterViewInit$,
    this.actions.pipe(ofType(ScrollToRetirement))
  ])
    .pipe(
      take(1),
      tap(() => {
        this.updateBalance = false;
        this.skipNotification = true;
        let difference =
          this.done.nativeElement.getBoundingClientRect().bottom -
          this.container.nativeElement.getBoundingClientRect().bottom;
        if (difference > 0)
          this.container.nativeElement.scrollBy(0, difference);
      })
    )
    .subscribe();
  constructor(
    public store: Store,
    private actions: Actions
  ) {}

  ngAfterViewInit(): void {
    this.AfterViewInit$.next();
  }

  ngOnInit(): void {
    this.store
      .select(selectBudget)
      .pipe(take(1))
      .subscribe((b) => {
        this.budget = { ...b };
      });

    this.store
      .select(selectProgress)
      .pipe(take(1))
      .subscribe((p) => {
        this.enableRetirement = p.enableRetirementInBudget;
      });

    this.store
      .select(selectShowBudgetButton)
      .pipe(take(1))
      .subscribe((s) => {
        this.firstTime = !s;
      });
    this.job
      .pipe(take(1))
      .subscribe(({ salary }) => (this.monthlyIncome = (salary / 12) * 0.7));
  }

  saveBudget(): void {
    this.store.dispatch(
      ModalClosed({
        result: ModifyBudget({
          budget: this.budget,
          updateBalance: this.updateBalance,
          skipNotification: this.skipNotification
        }),
        resumeAnimation: true
      })
    );
    this.updateBalance = true;
    this.skipNotification = false;
  }

  dollarLabel(value: number): string {
    return `$${value}`;
  }
  dualValueLabel(value: number): string {
    let percentage = ((value / this.monthlyIncome) * 100).toFixed(0);
    return `${percentage === '0' && value !== 0 ? '<1' : percentage}%`;
  }

  showTutorial() {
    if (this.firstTime) this.tutorial.showPrompt = true;
  }
}

import { Injectable, EventEmitter } from '@angular/core';
import { TeacherDashboardView } from '../teacher-dashboard/teacher-dashboard.component';

@Injectable({
  providedIn: 'root',
})
export class TeacherViewService {
  viewChanged: EventEmitter<TeacherDashboardView> = new EventEmitter();

  changeView(view: TeacherDashboardView) {
    this.viewChanged.emit(view);
  }
}
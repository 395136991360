import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectJob } from '../ngrx/selectors/progress.selectors';

@Component({
  selector: 'app-title-screen',
  templateUrl: './title-screen.component.html',
  styleUrls: ['./title-screen.component.scss']
})
export class TitleScreenComponent implements OnChanges, OnDestroy {
  @Input() title?: string = '';
  @Input() chapterTitle?: string = '';
  @Input() backgroundImage!: string | ((key: string) => string);
  @Input() buttonActive: boolean = false;
  @Input() autoStart?: number;
  @Output() continue = new EventEmitter<void>();
  timeout?: any;
  timeoutComplete = false;
  jobKey!: string;
  jobSub = this.store
    .select(selectJob)
    .subscribe(({ key }) => (this.jobKey = key));
  bgImage!: string;

  constructor(private store: Store) {}
  ngOnDestroy(): void {
    this.jobSub.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['backgroundImage']) {
      let currentValue = changes['backgroundImage'].currentValue;
      this.bgImage =
        typeof currentValue === 'function'
          ? currentValue(this.jobKey)
          : currentValue;
    }
    if (changes['autoStart']) {
      let delay = changes['autoStart'].currentValue;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
        this.timeoutComplete = false;
      }
      if (delay) {
        this.timeout = setTimeout(() => {
          this.timeoutComplete = true;
          if (this.buttonActive) {
            this.continue.emit();
          }
        }, delay);
      }
    } else if (this.timeoutComplete && this.buttonActive) {
      this.continue.emit();
    }
  }
}

<button
  class="skip-main"
  (click)="skipToMain()"
  (keyboard.enter)="skipToMain()"
>
  Skip to main content
</button>

<div class="navbar-wrapper d-flex justify-content-center align-items-center">
  <ul class="navbar d-flex justify-content-between">
    <li
      role="link"
      tabindex="0"
      (keydown.enter)="scroll('views-header')"
      (click)="scroll('views-header')"
    >
      Views and Sessions
    </li>
    <li
      role="link"
      tabindex="0"
      (keydown.enter)="scroll('assessment-header')"
      (click)="scroll('assessment-header')"
    >
      Assessment Results
    </li>
    <li
      role="link"
      tabindex="0"
      (keydown.enter)="scroll('state-header')"
      (click)="scroll('state-header')"
    >
      Student Participants
    </li>
    <li
      role="link"
      tabindex="0"
      (keydown.enter)="scroll('browser-header')"
      (click)="scroll('browser-header')"
    >
      Usage By Browsers and Device
    </li>
  </ul>
</div>
<div style="min-height: &quot;100vh&quot;">
  <div
    *ngIf="analytics"
    (window:scroll)="checkReportsScroll()"
    class="reports-container d-flex flex-column"
  >
    <div class="section-header d-flex">
      <span tabindex="0" class="section-header-text" id="views-header"
        >Views and Sessions</span
      >
      <app-tooltip-bubble
        id="main"
        [forAttribute]="'views and sessions'"
        [icon]="'question'"
        [text]="
          'Number of views shows anyone who visited the game’s website.\nNumber of sessions shows users who have started the game. The ones who finished the game and the post-game assessment are marked as complete. Others are marked as incomplete.'
        "
      ></app-tooltip-bubble>
    </div>
    <div class="views-and-sessions justify-content-between w-100 mb-4">
      <div class="d-flex mb-4 justify-content-between">
        <div class="pageviews px-5 justify-content-between align-items-center">
          <div class="d-flex mt-5 mb-4">
            <p class="fw-bolder h5 me-1 lh-sm">PAGEVIEWS</p>
            <app-tooltip-bubble
              id="main"
              [forAttribute]="'views and sessions'"
              [icon]="'question'"
              [text]="
                'Pageviews indicates how many times the landing page has been visited.'
              "
            ></app-tooltip-bubble>
          </div>
          <span class="display-3 fw-bolder me-4 lh-lg">{{
            analytics.pageviews.toLocaleString()
          }}</span>
        </div>
        <div class="pageviews px-4 justify-content-between align-items-center">
          <div class="d-flex mt-5 mb-4">
            <p class="fw-bolder h5 me-1 lh-sm">CLASSROOMS REGISTERED</p>
            <app-tooltip-bubble
              id="main"
              [forAttribute]="'views and sessions'"
              [icon]="'question'"
              [text]="
                'Classrooms registered defines how many classrooms have registered for Dollars & Decisions using a class code.'
              "
            ></app-tooltip-bubble>
          </div>
          <span class="display-3 fw-bolder me-4 lh-lg">{{
            analytics.classroomsRegistered.toLocaleString()
          }}</span>
        </div>
        <div class="pageviews px-5 justify-content-between align-items-center">
          <div class="d-flex mt-5 mb-4">
            <p class="fw-bolder h5 me-1 lh-sm">TEACHER DASHBOARD SESSIONS</p>
            <app-tooltip-bubble
              id="main"
              [forAttribute]="'views and sessions'"
              [icon]="'question'"
              [text]="
                'Teacher Dashboard Sessions indicates the amount of times a teacher has viewed their Dashboard/Account page.'
              "
            ></app-tooltip-bubble>
          </div>
          <span class="display-3 fw-bolder me-4 mt-1">{{
            analytics.teacherDashboardSessions.toLocaleString()
          }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div
          class="sessions d-flex px-6 py-5 justify-content-between align-items-center"
        >
          <div class="d-flex flex-column justify-content-between ms-3">
            <div
              class="d-flex justify-content-between align-items-center mb-3 mt-0"
            >
              <p class="fw-bolder h5 ms-3 mt-2 me-2 lh-lg">
                STUDENT PARTICIPANTS
              </p>
              <app-tooltip-bubble
                id="main"
                [forAttribute]="'views and sessions'"
                [icon]="'question'"
                [text]="
                  'Student Participants indicates how many users have started playing the game, both with a class code and without.'
                "
              ></app-tooltip-bubble>
            </div>
            <div class="display-3 fw-bolder me-3 mt-0 mb-4 ml-3 ms-3">
              {{
                (
                  analytics.studentParticipants.withClassCode +
                  analytics.studentParticipants.withoutClassCode
                ).toLocaleString()
              }}
            </div>
            <div class="d-flex flex-column ms-3 legend">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <div class="d-flex align-items-center">
                  <span class="dot" [style.backgroundColor]="'#14B1BD'"></span>
                  <span class="fs-6 me-5">With class code</span>
                </div>
                <span class="fs-6 fw-bold">{{
                  analytics.studentParticipants.withClassCode.toLocaleString()
                }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="dot" [style.backgroundColor]="'#002F33'"></span>
                  <span class="fs-6 me-3">Without class code</span>
                </div>
                <span class="fs-6 fw-bold">{{
                  analytics.studentParticipants.withoutClassCode.toLocaleString()
                }}</span>
              </div>
            </div>
          </div>
          <app-circle-graph
            aria-label="circle graph for sessions"
            class="me-3"
            [size]="200"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#002F33'"
            [isPercentage]="true"
            [percentComplete]="
              (analytics.studentParticipants.withClassCode /
                (analytics.studentParticipants.withoutClassCode +
                  analytics.studentParticipants.withClassCode)) *
              100
            "
            [average]="
              analytics.studentParticipants.withClassCode
                ? (analytics.studentParticipants.withClassCode /
                    (analytics.studentParticipants.withoutClassCode +
                      analytics.studentParticipants.withClassCode)) *
                  100
                : 0
            "
          >
          </app-circle-graph>
        </div>

        <div
          class="sessions d-flex px-6 py-5 justify-content-between align-items-center"
        >
          <div class="d-flex flex-column justify-content-between ms-3">
            <div
              class="d-flex justify-content-between align-items-center mb-3 mt-0"
            >
              <p class="fw-bolder h5 ms-3 mt-2 me-2 lh-lg">GAME SESSIONS</p>
              <app-tooltip-bubble
                id="main"
                [forAttribute]="'views and sessions'"
                [icon]="'question'"
                [text]="
                  'Game Sessions refers to the number of times a user has begun playing Dollars & Decisions.'
                "
              ></app-tooltip-bubble>
            </div>
            <div class="display-3 fw-bolder me-3 mt-0 mb-4 ml-3 ms-3">
              {{ analytics.courseStarted.toLocaleString() }}
            </div>
            <div class="d-flex flex-column ms-3 legend">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <div class="d-flex align-items-center">
                  <span class="dot" [style.backgroundColor]="'#14B1BD'"></span>
                  <span class="fs-6 me-5">Complete</span>
                </div>
                <span class="fs-6 fw-bold">{{
                  analytics.courseFinished.toLocaleString()
                }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="dot" [style.backgroundColor]="'#002F33'"></span>
                  <span class="fs-6 me-3">Incomplete</span>
                </div>
                <span class="fs-6 fw-bold">{{
                  (
                    analytics.courseStarted - analytics.courseFinished
                  ).toLocaleString()
                }}</span>
              </div>
            </div>
          </div>
          <app-circle-graph
            aria-label="circle graph for sessions"
            class="me-3"
            [size]="200"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#002F33'"
            [isPercentage]="true"
            [percentComplete]="
              (analytics.courseFinished / analytics.courseStarted) * 100
            "
            [average]="
              (analytics.courseFinished / analytics.courseStarted) * 100
            "
          >
          </app-circle-graph>
        </div>
      </div>

      <div class="sessions last-module-section py-3 mb-4 mt-4">
        <div
          class="d-flex header justify-content-between align-items-center mt-4"
        >
          <div class="d-flex align-items-center">
            <p class="fw-bolder h5 m-0 me-2 lh-sm">
              LAST MODULE COMPLETED BY STUDENTS
            </p>
            <app-tooltip-bubble
              id="main"
              [forAttribute]="'views and sessions'"
              [icon]="'question'"
              [text]="
                'The numbers in this section represent how many students stopped playing Dollars & Decisions within the outlined module.'
              "
            ></app-tooltip-bubble>
          </div>
          <div class="d-flex align-items-center pr-3">
            <span class="h5 fw-bolder m-0 me-4">SHOW AS</span>
            <span
              class="clickable-text m-0 me-4 fs-6"
              (click)="viewMode = 'visual'"
            >
              <img
                src="../../assets/icons/visual-bar-graph.svg"
                alt="Visual Icon"
                class="icon me-1"
              />
              Visual
            </span>
            <span
              class="clickable-text m-0 me-5 fs-6"
              (click)="viewMode = 'list'"
            >
              <img
                src="../../assets/icons/ul-list.svg"
                alt="List Icon"
                class="icon me-1"
              />
              List
            </span>
          </div>
        </div>

        <app-horizontal-bar-chart
          *ngIf="viewMode === 'visual'"
          [data]="moduleData"
        ></app-horizontal-bar-chart>

        <div
          class="table-container align-items-center"
          *ngIf="viewMode === 'list'"
        >
          <table>
            <caption class="fs-8">
              Last Module Completed By Students
            </caption>
            <thead class="table-header">
              <tr>
                <th scope="col" class="px-5">Module</th>
                <th scope="col">Number of Students</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let module of modulesArray; let i = index"
                [ngClass]="{ 'highlighted-row': i === 2 || i === 4 }"
              >
                <td class="fs-5 px-5">{{ module.name }}</td>
                <td class="centered fw-bolder fs-4">{{ module.students }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="section-header d-flex">
      <span class="section-header-text" id="assessment-header">Assessment</span>
      <app-tooltip-bubble
        [forAttribute]="'assessment'"
        [icon]="'question'"
        [text]="
          'Compare users’ average score before playing the game versus after completing it.\nSee percentage of correct and incorrect answers for each assessment question. Use the button to switch between pre-game and post-game questions.'
        "
      ></app-tooltip-bubble>
    </div>
    <div class="assessment d-flex flex-column align-items-center py-3 mb-4">
      <div class="graphs d-flex justify-content-center mb-4">
        <div class="d-flex flex-column align-items-center me-5">
          <app-circle-graph
            aria-label="circle graph for pre-game assessment"
            class="mb-3"
            [size]="284"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#D8D8DD'"
            [isPercentage]="false"
            [percentComplete]="(preAssessmentAverage / 10) * 100 || 40"
            [average]="preAssessmentAverage || 4"
          >
          </app-circle-graph>
          <p class="fw-bold mt-0 mb-0">AVERAGE PRE-GAME ASSESSMENT SCORE</p>
        </div>
        <div class="d-flex flex-column align-items-center ms-5">
          <app-circle-graph
            aria-label="circle graph for post-game assessment"
            class="mb-3"
            [size]="284"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#D8D8DD'"
            [isPercentage]="false"
            [percentComplete]="(postAssessmentAverage / 10) * 100 || 80"
            [average]="postAssessmentAverage || 8"
          >
          </app-circle-graph>
          <p class="fw-bold mt-0 mb-0">AVERAGE POST-ASSESSMENT SCORE</p>
        </div>
      </div>
      <div class="question-stats d-flex flex-column align-items-center">
        <div
          class="view-buttons d-flex justify-content-center align-items-center my-4"
        >
          <span
            role="button"
            [attr.aria-pressed]="questionView === 'pre'"
            (keydown.enter)="questionView = 'pre'"
            tabindex="0"
            (click)="questionView = 'pre'"
            class="view-button fs-7 me-4"
            [ngClass]="{ selected: questionView === 'pre' }"
            >PRE-GAME ASSESSMENT VIEW</span
          >
          <span
            role="button"
            [attr.aria-pressed]="questionView === 'post'"
            (keydown.enter)="questionView = 'post'"
            tabindex="0"
            (click)="questionView = 'post'"
            class="view-button fs-7"
            [ngClass]="{ selected: questionView === 'post' }"
            >POST-GAME ASSESSMENT VIEW</span
          >
        </div>
        <div class="question-table my-5">
          <div
            class="table-header d-flex justify-content-between px-5 w-100 pb-2"
          >
            <span>Question</span>
            <span>Score</span>
          </div>
          <div class="questions d-flex flex-column">
            <div
              class="question-row d-flex"
              *ngFor="let question of questions[questionView]; let i = index"
            >
              <div class="question d-flex justify-content-between p-3">
                <span class="fw-bold">{{ i + 1 + ". " }}</span>
                <p>{{ question.query }}</p>
              </div>
              <div
                class="stats d-flex flex-column justify-content-between py-3"
              >
                <div *ngIf="questionView === 'pre'">
                  <div class="d-flex align-items-center green">
                    <span class="section-header-text" class="fw-bold mb-0 me-2">
                      {{
                        preAssessmentQuestionCorrectPercentage(i)
                          | number: "1.0-1"
                      }}%
                    </span>
                    <span>Correct</span>
                  </div>
                  <div class="d-flex align-items-center red">
                    <span class="section-header-text" class="fw-bold mb-0 me-2">
                      {{
                        (analytics.preAssessmentTotal || 0) > 0
                          ? (100 - preAssessmentQuestionCorrectPercentage(i)
                            | number: "1.0-1")
                          : 0
                      }}%
                    </span>
                    <span>Incorrect</span>
                  </div>
                </div>
                <div *ngIf="questionView === 'post'">
                  <div class="d-flex align-items-center green">
                    <span class="section-header-text" class="fw-bold mb-0 me-2">
                      {{
                        postAssessmentQuestionCorrectPercentage(i)
                          | number: "1.0-1"
                      }}%
                    </span>
                    <span>Correct</span>
                  </div>
                  <div class="d-flex align-items-center red">
                    <span class="section-header-text" class="fw-bold mb-0 me-2">
                      {{
                        (analytics.preAssessmentTotal || 0) > 0
                          ? (100 - postAssessmentQuestionCorrectPercentage(i)
                            | number: "1.0-1")
                          : 0
                      }}%
                    </span>
                    <span>Incorrect</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-header d-flex">
      <span class="section-header-text" id="state-header"
        >Student Participants</span
      >
      <app-tooltip-bubble
        [forAttribute]="'students by state'"
        [icon]="'question'"
        [text]="
          'Number of students in each state shows number of users who have started the game.\nYou can utilize the map or the list of states to find the number of students in each state.'
        "
      ></app-tooltip-bubble>
    </div>
    <div class="sessions student-district-section py-3 mb-4 mt-4">
      <div class="table-container align-items-center px-5">
        <div class="d-flex align-items-center mt-3 header">
          <span class="fw-bolder h5 m-0 me-2 pl-3 lh-sm"
            >STUDENTS BY SCHOOL DISTRICT</span
          >
          <app-tooltip-bubble
            [forAttribute]="'student by school district'"
            [icon]="'question'"
            [text]="
              'Students by school district shows number of users who have started the game.'
            "
          ></app-tooltip-bubble>
        </div>
        <div class="search-bar-container">
          <img
            class="search-icon"
            src="../../assets/icons/search.svg"
            alt="search-icon"
          />
          <input
            class="search-bar"
            type="text"
            placeholder="Search"
            (input)="onSearch($event)"
          />
        </div>
        <table>
          <caption class="fs-8">
            Students by School Districts Table
          </caption>
          <thead class="table-header">
            <tr>
              <th scope="col">
                <span class="column-label px-3"
                  >School District
                  <app-sorting-dropdown
                    [columnType]="'string'"
                    (sortEvent)="handleSort('districtName', $event)"
                  ></app-sorting-dropdown>
                </span>
              </th>
              <th scope="col">
                <span class="column-label"
                  >State
                  <app-sorting-dropdown
                    [columnType]="'string'"
                    (sortEvent)="handleSort('state', $event)"
                  ></app-sorting-dropdown>
                </span>
              </th>
              <th scope="col">
                <span class="column-label"
                  >City
                  <app-sorting-dropdown
                    [columnType]="'string'"
                    (sortEvent)="handleSort('city', $event)"
                  ></app-sorting-dropdown>
                </span>
              </th>
              <th scope="col">
                <span class="column-label"
                  >Number of Students
                  <app-sorting-dropdown
                    [columnType]="'number'"
                    (sortEvent)="handleSort('numStudents', $event)"
                  ></app-sorting-dropdown>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let school of filteredSchoolData; let i = index"
              [ngClass]="{ 'highlighted-row': i === 2 || i === 4 }"
            >
              <td class="fs-5 px-3">{{ school.districtName }}</td>
              <td class="fs-5">{{ school.state }}</td>
              <td class="fs-5">{{ school.city }}</td>
              <td class="fs-5 fw-bolder">{{ school.numStudents }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <app-state-map
      class="mb-4"
      [locationAnalytics]="analytics.location"
    ></app-state-map>
    <div class="section-header d-flex">
      <span class="section-header-text" id="browser-header"
        >Browsers and Devices</span
      >
      <app-tooltip-bubble
        [forAttribute]="'browsers and devices'"
        [icon]="'question'"
        [text]="
          'Number of different browsers and devices is determined by number of users who have started the game.'
        "
      ></app-tooltip-bubble>
    </div>
    <div class="browser-and-devices d-flex justify-content-between mb-4">
      <div
        class="browser d-flex flex-column justify-content-between align-items-center px-3 py-4"
      >
        <span class="align-self-start ms-2 fs-5 fw-bold">BROWSERS</span>
        <app-bar-chart [data]="analytics.browser"></app-bar-chart>
      </div>
      <div
        class="devices d-flex flex-column justify-content-between align-items-center px-3 py-4"
      >
        <span class="align-self-start ms-2 fs-5 fw-bold">DEVICES</span>
        <app-bar-chart [data]="analytics.device"></app-bar-chart>
      </div>
    </div>
  </div>
</div>

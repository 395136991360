<div class="path">
  <span
    class="dashboard-text"
    (click)="changeView.emit(teacherDashboardView.home)"
    >Dashboard</span
  >
  <img
    class="arrow-right"
    src="../../assets/icons/arrow-right.svg"
    alt="right-arrow-icon"
  />
  <span class="fw-bolder">Students</span>
</div>

<div class="main-content">
  <div class="class-name-container">
    <span class="class-name">{{ selectedClass?.className }}</span>
    <div class="d-flex">

    <div class="delete-section-container">
      <div class="delete-section mt-2" (click)="showConfirmationPopup() ">
        <img src="../../assets/icons/delete.svg" alt="Delete" class="delete-icon" />
        <span class="text-uppercase">Delete Class</span>
      </div>

      <div *ngIf="confirmingDeleteClass" (click)="toggleDeleteClassMenu()" class="menu-popup">
        <p>Are you sure you want to delete {{ selectedClass?.className }}?</p>
        <button [disabled]="isSubmitting" (click)="confirmClassDelete()" class="fw-bolder">
          YES
        </button>
        <button (click)="cancelDeleteClass()">Cancel</button>
      </div>
    </div>

    <div
      class="edit-section mt-2"
      (click)="changeView.emit(teacherDashboardView.editClass)"
    >
      <img src="../../assets/icons/edit.svg" alt="Edit" class="edit-icon" />
      <span class="text-uppercase">Edit Class</span>
    </div>
    </div>
  </div>
  <hr class="separator-line" />

  <div class="code-section">
    <span>Class Code: </span>
    <span class="code">{{ selectedClass?.classCode }}</span>
    <img
      src="../../assets/icons/copy.svg"
      alt="Copy Icon"
      class="copy-icon"
      (click)="copyToClipboard('classCode')"
    />
  </div>
  <div class="students-table-container">
    <table aria-describedby="students table" class="bordered">
      <thead>
        <tr>
          <th scope="col">Student Name</th>
          <th scope="col">Student Username</th>
          <th scope="col" class="icon-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let student of selectedClass?.students">
          <td>{{ student.name }}</td>
          <td>{{ student.username }}</td>
          <td class="icon-cell">
            <img
              src="../../assets/icons/dot-menu.svg"
              alt="Menu"
              class="dots-menu-icon"
              (click)="toggleMenu(student)"
            />

            <div
              #menuPopup
              *ngIf="visibleMenuStudent === student"
              class="menu-popup student-popup"
            >
              <div
                *ngIf="student.registered"
                (click)="openResetModal(student)"
                class="option"
              >
                Reset Password
              </div>
              <div (click)="deleteStudent(student)" class="option">
                Delete Account
              </div>
            </div>

            <div *ngIf="confirmingDeleteStudent === student" class="menu-popup">
              <p>Are you sure you want to delete {{ student.name }}?</p>
              <button
                [disabled]="isSubmitting"
                (click)="confirmStudentDelete(student)"
              >
                Yes
              </button>
              <button (click)="cancelDelete()">Cancel Changes</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="resetModalOpen" class="reset-password-modal">
      <img
        src="../../assets/icons/close.svg"
        alt="Close"
        class="close-icon"
        (click)="closeModal()"
      />
      <div *ngIf="passwordChanged">
        <p class="title">Password Changed!</p>
        <p class="new-pw-text">Their New Password Is:</p>
        <div class="password-display">{{ newPassword }}</div>
        <div class="icon-container">
          <div class="icon-item">
            <img
              src="../../../assets/icons/copy.svg"
              alt="Copy Icon"
              class="icon"
              (click)="copyToClipboard('password')"
            />
            <span (click)="copyToClipboard('password')">COPY TO CLIPBOARD</span>
          </div>
          <div class="icon-item">
            <img
              src="../../../assets/icons/print.svg"
              alt="Print Icon"
              class="icon"
              (click)="printPassword()"
            />
            <span (click)="printPassword()">PRINT PASSWORD</span>
          </div>
        </div>
        <button
          (click)="closeModal()"
          class="create-pw-btn teal-btn text-uppercase mt-4"
        >
          Done
        </button>
      </div>

      <div *ngIf="!passwordChanged">
        <p class="title" (click)="resetPasswordMethod = 'auto'">
          Reset Password
        </p>
        <p class="select-method-text">Select a method to reset password</p>
        <div class="reset-type-btn">
          <button
            [ngClass]="{ 'active-option': resetPasswordMethod === 'auto' }"
            (click)="resetPasswordMethod = 'auto'; displayError = false"
          >
            AUTO-GENERATE PASSWORD
          </button>
          <span class="fw-bolder">Or</span>
          <button
            [ngClass]="{ 'active-option': resetPasswordMethod === 'custom' }"
            (click)="resetPasswordMethod = 'custom'; displayError = false"
          >
            CUSTOMIZE PASSWORD
          </button>
        </div>

        <div
          *ngIf="resetPasswordMethod === 'auto'"
          class="auto-screen position-relative"
        >
          <p class="autogen-text">
            Would you like to receive an autogenerated password?
          </p>
          <p
            *ngIf="displayError"
            aria-live="polite"
            class="karla instructions fst-italic text-danger autogen-error"
          >
            Failed to update password.
          </p>
          <button
            (click)="resetPassword()"
            class="create-pw-btn teal-btn text-uppercase"
          >
            Create Password
          </button>
        </div>

        <div *ngIf="resetPasswordMethod === 'custom'" class="custom-screen">
          <form
            #resetForm="ngForm"
            (ngSubmit)="resetPassword()"
            class="reset-form position-relative"
          >
            <div class="mb-3 mt-5 password-control">
              <div class="error-container">
                <div
                  *ngIf="
                    newPassword !== confirmPassword &&
                    confirmPasswordModel.touched
                  "
                  class="karla fst-italic text-danger error-text"
                >
                  Your password and confirmation password do not match.
                </div>
                <div
                  *ngIf="newPasswordModel.invalid && newPasswordModel.touched"
                  class="karla fst-italic text-danger error-text"
                >
                  Your password must be at least 6 characters long.
                </div>
              </div>

              <label for="newPassword" class="form-label text-uppercase">
                <img
                  *ngIf="newPasswordModel.invalid && newPasswordModel.touched"
                  src="../../assets/icons/alert.svg"
                  alt="Alert"
                  class="alert-icon"
                />
                New Password
              </label>
              <input
                type="password"
                placeholder="Enter new password"
                minlength="6"
                required
                [(ngModel)]="newPassword"
                id="newPassword"
                name="newPassword"
                class="form-control fst-italic"
                #newPasswordModel="ngModel"
                #newPasswordInput
                [ngClass]="{
                  'border-danger':
                    newPasswordModel.invalid && newPasswordModel.touched
                }"
              />

              <button
                class="visibility-toggle"
                type="button"
                (click)="
                  newPasswordInput.type =
                    newPasswordInput.type === 'password' ? 'text' : 'password'
                "
                [ngSwitch]="newPasswordInput.type"
              >
                <img
                  *ngSwitchCase="'password'"
                  src="/assets/icons/visibility_24px.svg"
                  alt="show password"
                />
                <img
                  *ngSwitchCase="'text'"
                  src="/assets/icons/visibility_off_24px.svg"
                  alt="hide password"
                />
              </button>
            </div>

            <div class="mb-3 password-control">
              <label for="confirmPassword" class="form-label text-uppercase">
                <img
                  *ngIf="
                    (newPassword !== confirmPassword &&
                      confirmPasswordModel.touched) ||
                    (confirmPasswordModel.invalid &&
                      confirmPasswordModel.touched)
                  "
                  src="../../assets/icons/alert.svg"
                  alt="Alert"
                  class="alert-icon"
                />
                Confirm New Password
              </label>
              <input
                type="password"
                placeholder="Confirm new password"
                minlength="6"
                required
                [(ngModel)]="confirmPassword"
                id="confirmPassword"
                name="confirmPassword"
                class="form-control fst-italic"
                #confirmPasswordModel="ngModel"
                #confirmPasswordInput
                [ngClass]="{
                  'border-danger':
                    (newPassword !== confirmPassword &&
                      confirmPasswordModel.touched) ||
                    (confirmPasswordModel.invalid &&
                      confirmPasswordModel.touched)
                }"
              />

              <button
                class="visibility-toggle"
                type="button"
                (click)="
                  confirmPasswordInput.type =
                    confirmPasswordInput.type === 'password'
                      ? 'text'
                      : 'password'
                "
                [ngSwitch]="confirmPasswordInput.type"
              >
                <img
                  *ngSwitchCase="'password'"
                  src="/assets/icons/visibility_24px.svg"
                  alt="show password"
                />
                <img
                  *ngSwitchCase="'text'"
                  src="/assets/icons/visibility_off_24px.svg"
                  alt="hide password"
                />
              </button>
            </div>
            <p
              *ngIf="displayError"
              aria-live="polite"
              class="karla instructions fst-italic text-danger custom-password-error"
            >
              Failed to update password.
            </p>
            <button
              type="submit"
              [disabled]="
                !resetForm.valid ||
                newPassword !== confirmPassword ||
                isSubmitting
              "
              class="create-pw-btn teal-btn text-uppercase mt-4"
            >
              Create Password
            </button>
          </form>
        </div>
        <p class="cancel-text text-uppercase" (click)="closeModal()">Cancel</p>
      </div>
    </div>
    <div *ngIf="resetModalOpen" class="backdrop"></div>
  </div>
</div>

import { Screen } from '../interfaces';
import { ShowToolTip, PlaySound } from '../ngrx/actions/overlays.actions';
import {
  selectCharacter,
  selectJob
} from '../ngrx/selectors/progress.selectors';
import { firstValueFrom } from 'rxjs';
import { AdvanceScreen } from '../ngrx/actions/progress.actions';
export const startingJob: Screen = {
  component: 'game',
  module: {
    title: {
      chapter: 'Module 1',
      text: 'Starting your job and taxes',
      titleBackgroundImage: (key) =>
        `/assets/images/${
          key === 'electrician' ? 'electrician' : 'office'
        }-bg.png`
    },
    animation: async (store, assetManager) => {
      const job = await firstValueFrom(store.select(selectJob));
      const character = await firstValueFrom(store.select(selectCharacter));
      let depositFrame = job.key === 'coder' ? 750 : 770;
      const sounds = [
        `/assets/sounds/scene3/${job.key}/S3L1.mp3`,
        `/assets/sounds/scene3/${job.key}/S3L2${character.soundAlias}.mp3`
      ];
      await assetManager.loadAssets(sounds);
      return {
        layerPaths: [`/assets/lottie/scene3/${job.key}.json`],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...PlaySound({ src: `/assets/sounds/scene3/${job.key}/S3L1.mp3` }),
            segment: [0, 0],
            pause: false
          },
          {
            ...ShowToolTip({
              options: {
                text: "<b>Sign up for direct deposit.</b> That way, your paychecks from work will automatically appear in your checking account on payday. You'll learn more about your checking account later.",
                type: 'large',
                mood: 'neutral'
              }
            }),
            segment: [0, depositFrame],
            pause: true
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene3/${job.key}/S3L2${character.soundAlias}.mp3`
            }),
            segment: [depositFrame, 1272],
            pause: false
          },
          {
            ...AdvanceScreen({ noSave: true }),
            segment: [1272, 1440],
            pause: false
          }
        ]
      };
    },
    infoScreens: {}
  }
};

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SortMethods } from './sort-states.pipe';
import { statesData } from './states';
import { geoAlbersUsaTerritories } from 'geo-albers-usa-territories';
import * as d3 from 'd3';

@Component({
  selector: 'app-state-map',
  templateUrl: './state-map.component.html',
  styleUrls: ['./state-map.component.scss']
})
export class StateMapComponent implements AfterViewInit, OnChanges {
  @Input() locationAnalytics?: { [location: string]: number } = {};
  @ViewChild('mapContainer') map!: ElementRef;
  stateData: { [location: string]: number } = {};
  currentState: string = '';
  selectedSortMethod$ = new BehaviorSubject<SortMethods>('Alphabetical');
  dropdownOpen!: boolean;
  generatedMap?: SVGSVGElement;
  @ViewChild('toggle') toggleRef!: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    this.generateMapData(this.locationAnalytics || {});
    this.renderMap();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      this.generateMapData(changes['locationAnalytics'].currentValue || {});
      this.renderMap();
    }
  }
  toggleDropdown() {
    if (this.dropdownOpen) {
      this.toggleRef.nativeElement.focus();
      this.dropdownOpen = false;
    } else {
      this.dropdownOpen = true;
    }
  }
  generateMapData(locationData: { [key: string]: number }) {
    let allStates = new Set(
      statesData.features.map(({ properties }) => properties.name)
    );
    //For each location metric if the location is not a state,
    //remove it from location data and add it's value to outside us metric
    Object.keys(locationData).forEach((state) => {
      if (!allStates.delete(state)) {
        locationData['Outside the US'] =
          locationData[state] + (locationData['Outside the US'] || 0);
        delete locationData[state];
      }
    });
    this.stateData = Object.fromEntries(
      Object.entries(locationData).concat(
        Array.from(allStates.values()).map((state) => [state, 0])
      )
    );
  }
  renderMap() {
    // Define reference to the angular component for d3 event listeners
    let component = this;
    let tooltip = d3.select('.report-tooltip');
    let map = d3.select(this.map.nativeElement);
    if (this.generatedMap) map.select('svg').remove();
    let svg = map.append('svg').style('width', '100%').style('height', '100%');
    this.generatedMap = svg.node() as any;
    let computedWidth = map.node().getBoundingClientRect().width || 960;
    let computedHeight = map.node().getBoundingClientRect().height || 500;
    let scaleFactor = (computedWidth / 960) * 1000;
    // D3 Projection
    let projection = geoAlbersUsaTerritories()
      .scale(scaleFactor)
      .translate([computedWidth / 2, computedHeight / 2]);

    // Define path generator
    let path = d3.geoPath(projection);

    svg
      .selectAll('path')
      .data(statesData.features)
      .enter()
      .append('path')
      .attr('d', path as any)
      .style('stroke', '#000000')
      .style('fill', '#B4F1F6');

    svg.selectAll('path').on('mouseenter', function (_, datum) {
      let { name } = (datum as any).properties;
      let currentElement = d3.select(this);
      currentElement.style('fill', '#002F33');
      let bounds = (currentElement.node() as Element)?.getBoundingClientRect();
      component.currentState = name;
      tooltip
        .style('display', 'block')
        .style('top', `${bounds.bottom}px`)
        .style('left', `${bounds.left}px`);
    });
    svg.selectAll('path').on('mouseleave', function () {
      let currentElement = d3.select(this);
      currentElement.style('fill', '#B4F1F6');
      tooltip.style('display', 'none');
    });
  }
}

<div *ngIf="options.avatar" class="me-4 job-img">
  <img
    [src]="'/assets/images/tooltip-avatar-' + options.avatar + '.svg'"
    alt="Boss teaching a financial tip"
  />
</div>
<div
  #container
  class="tooltip-container d-flex flex-column"
  [ngClass]="toolTipClass"
>
  <div
    *ngIf="options.type === 'large' && !options.avatar"
    class="tooltip-header"
  >
    <img src="/assets/icons/tips.svg" alt="" />
    <h1>Quick Money Tips</h1>
  </div>
  <span class="tooltip-text" [innerHtml]="options.text | safe"></span>
  <div
    *ngIf="options.type === 'large'"
    class="w-100 mt-4 d-flex justify-content-end"
  >
    <span
      role="button"
      (click)="handleFinish()"
      (keydown.enter)="handleFinish()"
      tabindex="0"
      class="tooltip-confirm fw-bold cursor-pointer"
      >GOT IT!</span
    >
  </div>
</div>

import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { catchError, from, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsersService } from './shared/users.service';
import { User } from 'firebase/auth';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private usersService: UsersService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return from(this.usersService.getCurrentUser()).pipe(
      switchMap((user) => from(this.usersService.getUserRole(user as User))),
      catchError(() => of(false)),
      switchMap((role) => {
        if (role === route.data['permittedRole']) {
          return of(true);
        } else {
          this.router.navigate(['/']);
          return of(false);
        }
      })
    );
  }
}

import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  from,
  merge,
  Observable,
  pluck,
  repeat,
  skip,
  Subject,
  Subscription,
  takeUntil,
  tap,
  zip
} from 'rxjs';
import { selectToolTip } from '../ngrx/selectors/overlays.selectors';
import { TutorialTipDirective } from './tutorial-tip.directive';
//@ts-ignore
import focusLock from 'dom-focus-lock';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements AfterViewInit, OnDestroy {
  @Input() description!: string;
  @Input() showAfterInit: boolean = false;
  @ContentChildren(TutorialTipDirective, {
    descendants: true,
    read: TutorialTipDirective
  })
  discoveredTips!: QueryList<TutorialTipDirective>;
  @ContentChild('modalContainer') container!: ElementRef<HTMLElement>;
  @ViewChild('tutorialButton') tutorialButton!: ElementRef<HTMLElement>;
  @ViewChild('tutorialPrompt') tutorialPrompt!: ElementRef<HTMLElement>;
  @ViewChild('currentTutorialTip')
  currentTutorialTipRef!: ElementRef<HTMLElement>;
  startTutorial: boolean = false;
  canStartTutorial: boolean = true;
  showPrompt: boolean = false;
  proceed$ = new Subject<void>();
  exit$ = new Subject<void>();
  tutorialSequence$!: Observable<TutorialTipDirective>;
  destroyScrollListener: any;
  showAfterInitSub!: Subscription;
  tipChanges!: Subscription;

  @HostListener('document:click', ['$event'])
  clickout(e: Event) {
    if (e.target instanceof HTMLButtonElement) return;

    if (
      !this.tutorialButton.nativeElement.contains(e.target as Node) &&
      ((this.tutorialPrompt &&
        !this.tutorialPrompt.nativeElement.contains(e.target as Node)) ||
        (this.currentTutorialTipRef &&
          !this.currentTutorialTipRef.nativeElement.contains(e.target as Node)))
    ) {
      this.showPrompt = false;
      this.exit$.next();
    }
  }

  @HostListener('window:keydown.escape')
  escape() {
    this.showPrompt = false;
    this.exit$.next();
  }

  constructor(
    private renderer: Renderer2,
    private store: Store,
    private elRef: ElementRef
  ) {}
  ngOnDestroy(): void {
    this.destroyScrollListener();
    this.showAfterInitSub.unsubscribe();
    this.tipChanges.unsubscribe();
    focusLock.off(this.elRef.nativeElement);
  }
  ngAfterViewInit(): void {
    focusLock.on(this.elRef.nativeElement);
    this.tipChanges = this.discoveredTips.changes.subscribe(
      this.buildTutorial.bind(this)
    );
    this.buildTutorial();
    this.destroyScrollListener = this.renderer.listen(
      this.container.nativeElement,
      'scroll',
      (event) => {
        let pos = 112 - event.target.scrollTop;
        this.renderer.setStyle(
          this.tutorialButton.nativeElement,
          'top',
          `${pos}px`
        );
        if (pos <= 96) {
          let clipY = 96 - pos;
          this.renderer.setStyle(
            this.tutorialButton.nativeElement,
            'clip-path',
            `polygon(0px ${clipY}px, 100% ${clipY}px, 100% calc(100% + 5px), 0px calc(100% + 5px))`
          );
        } else {
          this.renderer.setStyle(
            this.tutorialButton.nativeElement,
            'clip-path',
            'none'
          );
        }
      }
    );
    this.showAfterInitSub = this.store
      .select(selectToolTip('large'))
      .pipe(
        skip(1),
        tap((value) => {
          if (!value) this.showPrompt = this.showAfterInit;
        })
      )
      .subscribe();
  }
  buildTutorial() {
    this.tutorialSequence$ = merge(
      this.exit$,
      zip(from([...this.discoveredTips, undefined]), this.proceed$).pipe(
        takeUntil(this.exit$),
        pluck(0),
        tap((tip) => tip?.scrollIntoView(this.container.nativeElement)),
        repeat()
      )
    ).pipe(tap((value: any) => (this.canStartTutorial = !value)));
  }
}

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AnalyticsType,
  AppRole,
  BinaryQuestion,
  MultiChoiceQuestion
} from '../shared/interfaces';
import { Store } from '@ngrx/store';
import { firstValueFrom, tap } from 'rxjs';
import {
  AdvanceScreen,
  MarkGameComplete,
  RestartCourse,
  SetPreAssessmentScore
} from '../shared/ngrx/actions/progress.actions';
import { AnalyticsService } from '../shared/analytics.service';
import { questions } from '../shared/questions';
import {
  selectPreAssessmentScore,
  selectProgress
} from '../shared/ngrx/selectors/progress.selectors';
import { Actions, ofType } from '@ngrx/effects';
//@ts-ignore
import focusLock from 'dom-focus-lock';
import { UsersService } from '../shared/users.service';
import { User } from 'firebase/auth';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnChanges, OnDestroy, OnInit {
  @Input() type: string = '';
  @ViewChild('questionContainer') questionContainer!: ElementRef;
  @ViewChild('nextButton') nextButton!: ElementRef;
  currentQuestion = -1;
  currentQuestionSelection: boolean | number | undefined = undefined;
  selections: (boolean | number | undefined)[] = [];
  preAssessmentScore = this.store.select(selectPreAssessmentScore);
  readonly questions = questions;

  private restartEffect = this.actions
    .pipe(
      ofType(RestartCourse),
      tap(() => this.ngOnChanges())
    )
    .subscribe();

  constructor(
    private store: Store,
    private analyticsService: AnalyticsService,
    private usersService: UsersService,
    private actions: Actions,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.prepareSelections();
    focusLock.on(this.elRef.nativeElement);
  }

  ngOnChanges() {
    this.currentQuestion = -1;
    this.currentQuestionSelection = undefined;
    this.prepareSelections();
  }

  ngOnDestroy() {
    this.restartEffect.unsubscribe();
  }

  prepareSelections() {
    this.selections = new Array(this.questions[this.type].length);
  }

  isMultipleChoice(
    question: BinaryQuestion | MultiChoiceQuestion
  ): question is MultiChoiceQuestion {
    return (question as MultiChoiceQuestion).choices !== undefined;
  }

  updateSelection(value: any) {
    this.currentQuestionSelection = value;
  }

  submitSelection(index: number) {
    this.selections[index] = this.currentQuestionSelection;
    this.currentQuestionSelection = undefined;
    setTimeout(() => this.nextButton.nativeElement.focus(), 50);
  }

  async moveToNextQuestion() {
    this.currentQuestion++;

    if (this.currentQuestion >= this.questions[this.type].length) {
      focusLock.off(this.elRef.nativeElement);
      const savedProgress = await firstValueFrom(
        this.store.select(selectProgress)
      );

      if (!savedProgress.gameCompleted) {
        const stats = this.getStats();
        if (this.type === 'post') {
          this.analyticsService.sendAnalytics(
            AnalyticsType.COURSE_FINISHED,
            stats
          );
          await this.usersService.saveStudentAssessmentData('post', stats);
        } else {
          this.store.dispatch(
            SetPreAssessmentScore({ score: stats.numCorrect })
          );
          this.analyticsService.sendAnalytics(
            AnalyticsType.PRE_ASSESSMENT,
            stats
          );
          await this.usersService.saveStudentAssessmentData('pre', stats);
        }
      }
    } else {
      setTimeout(() => this.questionContainer.nativeElement.focus(), 50);
    }
  }

  advanceScreen() {
    this.store.dispatch(AdvanceScreen({}));
  }

  restartCourse(toAssessment: boolean) {
    if (!toAssessment) {
      this.store.dispatch(MarkGameComplete());
    }

    this.store.dispatch(RestartCourse({ toAssessment }));
  }

  async sendAnalytics() {
    const savedProgress = await firstValueFrom(
      this.store.select(selectProgress)
    );
    let idToken = '';
    const user = await this.usersService.getCurrentUser();
    if (user) {
      const role = await this.usersService.getUserRole(user as User);
      if (role === AppRole.STUDENT) {
        idToken = await user.getIdToken();
      }
    }

    if (!savedProgress.gameCompleted) {
      this.analyticsService.sendAnalytics(
        AnalyticsType.COURSE_STARTED,
        {},
        idToken
      );
    }
  }
  getStats() {
    let numCorrect = 0;
    const assessmentResults: { [q: number]: { answer: any; correct: number } } =
      {};

    this.selections.forEach((s, index) => {
      const isCorrect = this.questions[this.type][index].correctChoice === s;
      if (isCorrect) {
        numCorrect += 1;
      }
      assessmentResults[index + 1] = { answer: s, correct: isCorrect ? 1 : 0 };
    });

    return { numCorrect, assessmentResults };
  }
}

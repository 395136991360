<div
  class="game-container d-flex h-100 w-100 position-relative"
  *ngrxLet="animationData$; let animationData; let loaded = $complete"
>
  <div
    *ngIf="showTitleScreen"
    class="title-screen position-absolute bg-white w-100 h-100 front"
  >
    <app-title-screen
      [title]="gameData.title.text"
      [chapterTitle]="gameData.title.chapter"
      [autoStart]="gameData.title.autoStart"
      [backgroundImage]="gameData.title.titleBackgroundImage"
      [buttonActive]="loaded && buttonActive"
      (continue)="startChapter()"
      aria-live="polite"
    ></app-title-screen>
  </div>

  <app-animation
    [attr.aria-hidden]="
      showTitleScreen ||
      (showNarrativeChoice | async) ||
      (showToolTip | async) ||
      showFullScreenInfo
    "
    class="w-100 h-100"
    (finishAnimationPreload)="handlePreload()"
    [animationData]="animationData"
  >
  </app-animation>

  <div
    *ngIf="showNarrativeChoice | async as key"
    class="w-100 h-100 position-absolute d-flex justify-content-center align-items-center"
  >
    <app-narrative-choice
      [choices]="choices[key].choices"
      [header]="choices[key].header"
      (finish)="resumeAnimation()"
      aria-live="polite"
    >
    </app-narrative-choice>
  </div>

  <app-tooltip
    class="w-100 h-100 position-absolute d-flex justify-content-center align-items-center front"
    *ngIf="showToolTip | async as options"
    [options]="options"
    (finish)="!options.delayed ? resumeAnimation() : ''"
    aria-live="polite"
  ></app-tooltip>

  <div *ngIf="showFullScreenInfo" class="position-absolute w-100 h-100">
    <app-full-screen-info
      aria-live="polite"
      [info]="gameData.infoScreens[showFullScreenInfo]"
    ></app-full-screen-info>
  </div>
</div>

import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { OverlaysReducer, OverlaysState } from './overlays.reducer';
import { ProgressReducer, ProgressState } from './progress.reducer';

export interface AppState {
  progress: ProgressState;
  overlays: OverlaysState;
}

export const reducers: ActionReducerMap<AppState> = {
  progress: ProgressReducer,
  overlays: OverlaysReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [
      (reducer) =>
        function (state, action) {
          console.log('state', state);
          console.log('action', action);

          return reducer(state, action);
        }
    ]
  : [];

import { Directive } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[appMatOptionHeight]'
})
export class MatOptionHeightDirective {
  constructor(public matSelect: MatSelect) {
    (<any>this.matSelect)._getItemHeight = () =>
      this.matSelect._triggerFontSize * 3.5;
  }
}

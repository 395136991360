import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  CloseBubbleTooltips,
  CheckReportsScroll
} from '../ngrx/actions/overlays.actions';

@Component({
  selector: 'app-tooltip-bubble',
  templateUrl: './tooltip-bubble.component.html',
  styleUrls: ['./tooltip-bubble.component.scss']
})
export class TooltipBubbleComponent implements OnDestroy {
  HEADER_POSITION = 130;
  @Input() icon = 'info';
  @Input() disabled = false;
  @Input() forAttribute = '';
  @Input() showBeneath = false;
  @ViewChild('button') button!: ElementRef;
  @ViewChild('closeButton') closeButton!: ElementRef;
  @HostListener('document:click', ['$event'])
  clickout(e: Event) {
    if (this.button && this.button.nativeElement.contains(e.target as Node))
      return;
    if (!this.elRef.nativeElement.contains(e.target as Node))
      this.active = false;
  }

  private closeTooltipsEffect = this.actions
    .pipe(
      ofType(CloseBubbleTooltips),
      tap(() => (this.active = false))
    )
    .subscribe();

  private checkReportsScrollEffect = this.actions
    .pipe(
      ofType(CheckReportsScroll),
      tap(() => {
        const rect = this.button.nativeElement.getBoundingClientRect();

        if (rect.top <= this.HEADER_POSITION) {
          this.active = false;
        }
      })
    )
    .subscribe();

  @Input() text: string = '';
  active: boolean = false;

  constructor(
    private store: Store,
    private actions: Actions,
    private elRef: ElementRef
  ) {}

  ngOnDestroy() {
    this.closeTooltipsEffect.unsubscribe();
    this.checkReportsScrollEffect.unsubscribe();
  }

  @HostListener('window:keydown.escape', ['$event'])
  activateTooltip(event?: any) {
    if (this.active) {
      this.active = false;
    } else if (!event) {
      this.store.dispatch(CloseBubbleTooltips());
      this.active = true;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { from, map } from 'rxjs';
import { AppRole } from '../shared/interfaces';
import { UsersService } from '../shared/users.service';
import { User } from 'firebase/auth';

enum signInFlow {
  signIn,
  request,
  resetInvalid,
  reset
}
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  email = '';
  password = '';
  passwordConfirmation = '';
  displayError: boolean = false;
  signInFlow = signInFlow;
  private _currentFlow = signInFlow.signIn;
  initialLogin = false;
  resetCode = '';
  resetSent: boolean = false;

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    let { mode, oobCode } = this.route.snapshot.queryParams;
    this.initialLogin = mode && mode == 'welcome';
    if (mode && oobCode) {
      from(this.auth.verifyPasswordResetCode(oobCode))
        .pipe(map((res) => [mode, oobCode, res]))
        .subscribe({
          next: ([mode, code, res]) => {
            //if params mode, code, and res is an email, then the code is valid. Set flow to reset screen.
            if (mode && code && res && /^\S+@\S+\.\S+$/.test(res)) {
              this.currentFlow = signInFlow.reset;
              this.resetCode = code;
            } else if (res) {
              this.displayError = false;
            }
          },
          error: () => {
            this.currentFlow = signInFlow.resetInvalid;
          }
        });
    }
  }

  async signIn(): Promise<void> {
    this.auth
      .signInWithEmailAndPassword(this.email, this.password)
      .then(async (loginResult) => {
        if (
          loginResult.user &&
          (await this.usersService.getUserRole(loginResult.user as User)) ===
            AppRole.ADMIN
        ) {
          this.router.navigate(['admin']);
        } else {
          await this.usersService.logout(false);
          this.displayError = true;
        }
      })
      .catch(() => (this.displayError = true));
  }
  requestReset(): void {
    this.auth.sendPasswordResetEmail(this.email);
    this.resetSent = true;
  }
  completeReset(): void {
    if (this.password === this.passwordConfirmation) {
      this.auth
        .confirmPasswordReset(this.resetCode, this.password)
        .then(() => (this.currentFlow = signInFlow.signIn))
        .catch(() => (this.displayError = true));
    } else {
      this.displayError = true;
    }
  }
  set currentFlow(targetState: signInFlow) {
    this._currentFlow = targetState;
    this.resetSent = false;
    this.displayError = false;
    this.password = '';
    this.passwordConfirmation = '';
    this.email = '';
  }
  get currentFlow() {
    return this._currentFlow;
  }
}

import {
  OnChanges,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

export interface ChartData {
  text: string;
  count: number;
  size: string;
}

@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent implements OnChanges {
  @ViewChild('barChart') private chartContainer!: ElementRef;
  @Input() data!: { [item: string]: number };
  sortedData!: ChartData[];

  constructor() {}

  ngOnChanges(): void {
    this.generateChart();
  }

  generateChart() {
    const maxValue = Math.max(...Object.values(this.data));
    this.sortedData = Object.keys(this.data).map((key) => {
      return {
        text: key,
        count: this.data[key],
        size: `${(this.data[key] * 100) / maxValue}%`
      };
    });
  }
}

<div #printDiv>
  <div id="print-div">
    <div class="print-banner align-items-center">
      <img
        class="me-3 ms-5"
        [src]="'/assets/images/logo.svg'"
        alt="dollars and decisions logo"
      />
      <span class="fs-6 fw-bold">Course Completion</span>
    </div>
    <div class="banner d-flex flex-column align-items-center">
      <div
        class="banner-header d-flex justify-content-between align-items-start"
      >
        <div class="path no-print">
          <button
            aria-label="Go to dashboard"
            class="dashboard-text bg-transparent border-0"
            (click)="changeView.emit(teacherDashboardView.home)"
          >
            Dashboard
          </button>
          <img
            class="arrow-right"
            src="../../assets/icons/arrow-right.svg"
            alt="right-arrow-icon"
          />
          <span class="fw-bolder">Course Completion</span>
        </div>
        <div class="icons d-flex align-items-center">
          <button class="icon-container me-4" (click)="downloadAsPDF()">
            <img src="/assets/icons/download.svg" alt="download" />
            <span>Download</span>
          </button>
          <button class="icon-container" (click)="print()" aria-label="print">
            <img src="/assets/icons/print.svg" alt="print" />
            <span>Print</span>
          </button>
        </div>
      </div>
      <div
        class="banner-content d-flex justify-content-between align-items-center"
      >
        <div class="banner-course-completion d-flex align-items-center">
          <span class="fs-4 fw-bold">Course<br />Completion<br />Status</span>
          <app-circle-graph
            [isPercentage]="true"
            aria-label="circle graph for sessions"
            class=""
            [size]="200"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#D8D8DD'"
            [text]="'Complete'"
            [percentComplete]="
              ((selectedClass.courseCompletion.completed.length || 0) /
                (selectedClass.students.length || 0)) *
              100
            "
            [average]="
              ((selectedClass.courseCompletion.completed.length || 0) /
                (selectedClass.students.length || 0)) *
                100 || 0
            "
          ></app-circle-graph>
        </div>
        <div class="banner-completion-breakdown d-flex">
          <div class="subsection">
            <div class="completion-box d-flex flex-column align-items-center">
              <h2 class="completion-number">
                {{ selectedClass.courseCompletion.completed.length }}
              </h2>
              <p class="completion-status">Completed</p>
              <hr />
              <div
                class="view-students"
                (click)="toggleStudentDropdown('completed')"
              >
                View Students
              </div>
              <div
                class="student-dropdown"
                *ngIf="showStudentDropdowns['completed']"
              >
                <img
                  class="close-icon"
                  (click)="toggleStudentDropdown('completed')"
                  alt="close"
                  src="/assets/icons/close.svg"
                />
                <div
                  class="student-option"
                  *ngFor="
                    let student of selectedClass.courseCompletion.completed
                  "
                >
                  {{ student }}
                </div>
              </div>
            </div>
          </div>
          <div class="subsection">
            <div class="completion-box">
              <h2 class="completion-number">
                {{ selectedClass.courseCompletion.inProgress.length }}
              </h2>
              <p class="completion-status">In Progress</p>
              <hr />
              <div
                class="view-students"
                (click)="toggleStudentDropdown('inProgress')"
              >
                View Students
              </div>
              <div
                class="student-dropdown"
                *ngIf="showStudentDropdowns['inProgress']"
              >
                <img
                  class="close-icon"
                  (click)="toggleStudentDropdown('inProgress')"
                  alt="close"
                  src="/assets/icons/close.svg"
                />
                <div
                  class="student-option"
                  *ngFor="
                    let student of selectedClass.courseCompletion.inProgress
                  "
                >
                  {{ student }}
                </div>
              </div>
            </div>
          </div>
          <div class="subsection">
            <div class="completion-box">
              <h2 class="completion-number">
                {{ selectedClass.courseCompletion.notStarted.length }}
              </h2>
              <p class="completion-status">Not Started</p>
              <hr />
              <div
                class="view-students"
                (click)="toggleStudentDropdown('notStarted')"
              >
                View Students
              </div>
              <div
                class="student-dropdown"
                *ngIf="showStudentDropdowns['notStarted']"
              >
                <img
                  class="close-icon"
                  (click)="toggleStudentDropdown('notStarted')"
                  alt="close"
                  src="/assets/icons/close.svg"
                />
                <div
                  class="student-option"
                  *ngFor="
                    let student of selectedClass.courseCompletion.notStarted
                  "
                >
                  {{ student }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="main-container py-5 bg-white d-flex flex-column align-items-center w-100"
    >
      <div class="main-content d-flex flex-column align-items-center">
        <div
          class="main-header w-100 fw-bolder fs-5 d-flex justify-content-between align-items-center"
        >
          <span>COURSE COMPLETION BY STUDENT</span>
          <div
            class="position-relative sort-container"
            *ngrxLet="selectedSortMethod$ as sortMethod"
          >
            <button
              role="button"
              [attr.aria-expanded]="sortDropdownOpen"
              #toggle
              (click)="sortDropdownOpen = !sortDropdownOpen"
              (keydown.escape)="sortDropdownOpen && (sortDropdownOpen = false)"
              class="sort-toggle"
            >
              <span>sort by</span>
              <img
                [ngClass]="{ open: sortDropdownOpen }"
                src="/assets/icons/arrow-down.svg"
                alt=""
              />
            </button>
            <div
              role="listbox"
              *ngIf="sortDropdownOpen"
              class="sort-options position-absolute"
            >
              <span
                [attr.aria-selected]="sortMethod === 'studentNameAsc'"
                role="option"
                tabindex="0"
                (keydown.enter)="applySort('studentNameAsc')"
                (click)="applySort('studentNameAsc')"
                [ngClass]="{ active: sortMethod === 'studentNameAsc' }"
              >
                Student Name: A-Z
              </span>
              <span
                [attr.aria-selected]="sortMethod === 'studentNameDesc'"
                role="option"
                tabindex="0"
                (keydown.enter)="applySort('studentNameDesc')"
                (click)="applySort('studentNameDesc')"
                [ngClass]="{ active: sortMethod === 'studentNameDesc' }"
              >
                Student Name: Z-A
              </span>
              <span
                [attr.aria-selected]="sortMethod === 'completionStatusAsc'"
                role="option"
                tabindex="0"
                (keydown.enter)="applySort('completionStatusAsc')"
                (click)="applySort('completionStatusAsc')"
                [ngClass]="{ active: sortMethod === 'completionStatusAsc' }"
              >
                Completion Status: Low to High
              </span>
              <span
                [attr.aria-selected]="sortMethod === 'completionStatusDesc'"
                role="option"
                tabindex="0"
                (keydown.enter)="applySort('completionStatusDesc')"
                (click)="applySort('completionStatusDesc')"
                [ngClass]="{ active: sortMethod === 'completionStatusDesc' }"
              >
                Completion Status: High to Low
              </span>
            </div>
          </div>
        </div>

        <div class="students-table-container">
          <table aria-describedby="students table">
            <thead>
              <tr>
                <th scope="col">Student Name</th>
                <th class="text-center" scope="col">Completion Status</th>
                <th scope="col">Current Module</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let student of sortedStudents">
                <td>{{ student.name }}</td>
                <td class="fs-5 fw-bold text-center">
                  {{ student.percentageComplete }}%
                </td>
                <td>
                  <span
                    *ngIf="!student.currentModule.includes(':')"
                    class="fw-bold"
                    >{{ student.currentModule }}</span
                  >
                  <span
                    *ngIf="student.currentModule.includes(':')"
                    class="fw-bold"
                    >{{ student.currentModule.split(": ")[0] }}:</span
                  >
                  <span *ngIf="student.currentModule.includes(':')" class="fs-7"
                    ><br />{{ student.currentModule.split(": ")[1] }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

export const sortOptions = ['Alphabetical', 'Descending', 'Ascending'] as const;
export type SortMethods = (typeof sortOptions)[number];
@Pipe({
  name: 'sortStates',
  pure: true
})
export class SortStatesPipe implements PipeTransform {
  transform(
    value: { [state: string]: number },
    method: SortMethods,
    defaultValue = 0
  ): [string, number][] {
    let comparator;
    if (method === 'Alphabetical') comparator = this.sortAlphabetically;
    else if (method === 'Ascending') comparator = this.sortAscendingNumerically;
    else if (method === 'Descending')
      comparator = this.sortDescendingNumerically;
    else
      throw Error(
        `Invalid argument exception, expected: 'Alphabetical' | 'Descending' | 'Ascending' received: ${method}`
      );
    return Object.entries(value).sort(comparator(defaultValue));
  }

  sortAlphabetically =
    () =>
    ([a]: [string, number], [b]: [string, number]) =>
      a.localeCompare(b);
  sortAscendingNumerically =
    (defaultValue = 0) =>
    ([, a]: [string, number], [, b]: [string, number]) =>
      (a ?? defaultValue) - (b ?? defaultValue);
  sortDescendingNumerically =
    (defaultValue = 0) =>
    ([, a]: [string, number], [, b]: [string, number]) =>
      (b ?? defaultValue) - (a ?? defaultValue);
}

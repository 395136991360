import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Howl } from 'howler';
import { Subtitle } from './interfaces';
import srtParser2 from 'srt-parser-2';

@Injectable({
  providedIn: 'root'
})
export class AssetManagerService {
  constructor(private http: HttpClient) {}
  public sounds: { [src: string]: Howl } = {};
  public subtitles: { [src: string]: Subtitle[] } = {};
  private loadAudio(sources: string[]): Promise<Howl[]> {
    return Promise.all(
      sources.map((src) => {
        let sound = (this.sounds[src] ??= new Howl({
          src
        }).orientation(1, 0, 0));

        return new Promise((res, reject) => {
          if (sound.state() === 'loaded') {
            res(sound);
            return;
          }
          sound.once('load', () => res(sound));
          sound.once('loaderror', (_id, error) => reject(error));
          sound.once('playerror', (_id, error) => reject(error));
        }) as Promise<Howl>;
      })
    );
  }

  private loadSubtitles(keys: string[]): Promise<void[]> {
    const mappedSrc = keys.map((src) =>
      src.replace('sounds', 'subtitles').replace('mp3', 'srt')
    );
    const parser = new srtParser2();

    return Promise.all(
      mappedSrc.map((src) => {
        let promise = new Promise<void>((resolve) => {
          this.http
            .get(src, { responseType: 'text' })
            .toPromise()
            .then((res) => {
              let parsedSubs = parser.fromSrt(res as string);
              this.subtitles[src] = parsedSubs;
              resolve();
            });
        });

        return promise;
      })
    );
  }

  public disposeAudio(keys: string[]): void {
    for (let url of keys) {
      let audio = this.sounds[url];
      if (audio && audio.state() !== 'unloaded') {
        audio.unload();
        delete this.sounds[url];
      }
    }

    this.subtitles = {};
  }

  public async loadAssets(keys: string[]) {
    await this.loadAudio(keys);
    await this.loadSubtitles(keys);
  }
}

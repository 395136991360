<app-tutorial
  description="Your Savings Plan helps you figure out how much you’ll have to save each month to reach a savings goal.\nAdjust settings using the dropdown and toggle below."
>
  <div
    #modalContainer
    class="modal-container d-flex flex-column align-items-center pb-5 px-3"
  >
    <div
      class="savings-header d-flex justify-content-center align-items-center"
    >
      <img alt="" src="/assets/images/coin.svg" />
      <h1 id="title" class="ms-3 mb-0 pt-3 wonky">Your Savings Plan</h1>
    </div>
    <div
      class="savings-options d-flex justify-content-center align-items-center p-2 mt-4"
    >
      <span id="dropdown-label" class="me-4 fs-4 fw-bold">Your Goal</span>
      <select
        class="option-select cursor-pointer"
        (change)="handleSelectingGoal($event)"
        appTutorialTip
        text="Change your savings goal using the dropdown below."
        aria-labelledby="dropdown-label"
      >
        <option
          *ngFor="let goal of goals | keyvalue"
          [value]="goal.key"
          [selected]="goal.key === selectedGoal"
        >
          A {{ goal.key }} (${{ goal.value | number: "1.0-0" }})
        </option>
      </select>
    </div>
    <div
      class="modal-body mt-4 d-flex flex-column w-100 align-items-center gap-5"
    >
      <div class="savings-row gap-4">
        <span class="item d-flex">
          <span>Savings period</span>
        </span>
        <div
          class="click-options"
          appTutorialTip
          text="Select the amount of time you’ll be saving for your goal by clicking on the toggle bar."
        >
          <div
            role="button"
            (click)="setSavingsTime(3)"
            (keydown.enter)="setSavingsTime(3)"
            tabindex="0"
            class="click-option"
            [ngClass]="{ selected: duration === 3 }"
          >
            3 Months
          </div>
          <div
            role="button"
            (click)="setSavingsTime(6)"
            (keydown.enter)="setSavingsTime(6)"
            tabindex="0"
            class="click-option"
            [ngClass]="{ selected: duration === 6 }"
          >
            6 Months
          </div>
          <div
            role="button"
            (click)="setSavingsTime(12)"
            (keydown.enter)="setSavingsTime(12)"
            tabindex="0"
            class="click-option"
            [ngClass]="{ selected: duration === 12 }"
          >
            12 Months
          </div>
        </div>
      </div>
      <div class="savings-row gap-4">
        <span class="item d-flex">
          <span>Amount per Month</span>
        </span>
        <span
          class="field blue-pill"
          appTutorialTip
          text="This is calculated for you based on your savings goal and how long you want to take to reach it."
          buttonLabel="DONE"
        >
          {{ "$ " + (monthlyCost | currency: "" : "") }}
        </span>
      </div>
      <button class="teal-btn fs-3 mb-5" (click)="submit()">Done</button>
    </div>
  </div>
</app-tutorial>

import { Component, HostBinding, OnDestroy } from '@angular/core';
import { ScreenSequence } from '../shared/screens';
import { Screen } from '../shared/interfaces';
import { isDevMode } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  AdvanceScreen,
  JumpToScreen
} from '../shared/ngrx/actions/progress.actions';
import { selectCurrentScreen } from '../shared/ngrx/selectors/progress.selectors';
import { SkipAnimationEvent } from '../shared/ngrx/actions/overlays.actions';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnDestroy {
  @HostBinding('role') role = 'main';
  screenSequence: Screen[] = ScreenSequence;
  currentScreen$ = this.store.select(selectCurrentScreen);
  subs: Subscription[] = [];

  constructor(private store: Store) {}

  moveToNextScreen() {
    this.store.dispatch(AdvanceScreen({}));
  }

  isDevMode() {
    return isDevMode();
  }

  updateCheatMenu(e: Event) {
    this.store.dispatch(
      JumpToScreen({ screen: parseInt((<HTMLInputElement>e.target).value) })
    );
  }

  nextEvent() {
    this.store.dispatch(SkipAnimationEvent());
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}

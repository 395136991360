import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { Screen } from '../interfaces';
import { DelayAnimation, PlaySound } from '../ngrx/actions/overlays.actions';
import { UpdateBalance, AdvanceScreen } from '../ngrx/actions/progress.actions';
import {
  selectCharacter,
  selectJob
} from '../ngrx/selectors/progress.selectors';

export const paycheck: Screen = {
  component: 'game',
  module: {
    title: {
      autoStart: 3000,
      text: 'Two weeks later',
      titleBackgroundImage: (key) =>
        `/assets/images/${
          key === 'electrician' ? 'electrician' : 'office'
        }-bg.png`
    },
    infoScreens: {},
    animation: async (store: Store, assetManager) => {
      const job = await firstValueFrom(store.select(selectJob));
      const character = await firstValueFrom(store.select(selectCharacter));
      let soundUrl = `/assets/sounds/scene4/${job.key}`;
      let sounds = [
        `${soundUrl}/S4L1.mp3`,
        `${soundUrl}/S4L2${character.soundAlias}.mp3`,
        `${soundUrl}/S4L3.mp3`,
        `${soundUrl}/S4L4${character.soundAlias}.mp3`,
        `${soundUrl}/S4L5.mp3`
      ];
      await assetManager.loadAssets(sounds);
      return {
        layerPaths: [`/assets/lottie/scene4/${job.key}.json`],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...PlaySound({ src: `${soundUrl}/S4L1.mp3` }),
            segment: [0, 0],
            pause: false
          },
          {
            ...PlaySound({
              src: `${soundUrl}/S4L2${character.soundAlias}.mp3`
            }),
            segment: [0, 168],
            pause: false
          },
          {
            ...UpdateBalance({ value: (job.salary / 24) * 0.7 }),
            segment: [168, 168],
            pause: false
          },
          {
            ...DelayAnimation({ delayTime: 1000 }),
            segment: [168, 264],
            pause: true
          },
          {
            ...PlaySound({ src: `${soundUrl}/S4L3.mp3` }),
            segment: [264, 264],
            pause: false
          },
          {
            ...PlaySound({
              src: `${soundUrl}/S4L4${character.soundAlias}.mp3`
            }),
            segment: [264, 1224],
            pause: false
          },
          {
            ...PlaySound({ src: `${soundUrl}/S4L5.mp3` }),
            segment: [1224, 1320],
            pause: false
          },
          {
            ...AdvanceScreen({ moduleCompleted: 1 }),
            segment: [1320, 1475],
            pause: false
          }
        ]
      };
    }
  }
};

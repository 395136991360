import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ToolTipType } from '../../interfaces';
import { OverlaysState } from '../reducers/overlays.reducer';

export const selectOverlays = createFeatureSelector<OverlaysState>('overlays');
export const selectToolTip = (size: ToolTipType) =>
  createSelector(
    selectOverlays,
    (overlays) => overlays[size === 'large' ? 'ToolTip' : 'SmallToolTip']
  );
export const selectSubtitles = createSelector(
  selectOverlays,
  (overlays) => overlays.SubtitleText
);
export const selectNarrativeChoiceKey = createSelector(
  selectOverlays,
  (overlays) => overlays.NarrativeChoiceKey
);
export const selectFullScreenInfoKey = createSelector(
  selectOverlays,
  (overlays) => overlays.FullScreenInfoKey
);

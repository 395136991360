import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClassDataDto } from '../../shared/interfaces';
import { TeacherDashboardView } from '../teacher-dashboard.component';

const INITIAL_STUDENT_DROPDOWN_STATE = {
  loggedIn: false,
  notLoggedIn: false,
  completed: false,
  inProgress: false,
  notStarted: false
};

@Component({
  selector: 'app-teacher-dashboard-home',
  templateUrl: './teacher-dashboard-home.component.html',
  styleUrls: ['./teacher-dashboard-home.component.scss']
})
export class TeacherDashboardHomeComponent {
  showDropdown: boolean = false;
  showStudentDropdowns: { [key: string]: boolean } =
    INITIAL_STUDENT_DROPDOWN_STATE;
  teacherDashboardView = TeacherDashboardView;

  @Input() selectedClass!: ClassDataDto;
  @Input() classes: ClassDataDto[] = [];
  @Input() todayDate!: string;
  @Output() changeView = new EventEmitter<TeacherDashboardView>();
  @Output() switchClass = new EventEmitter<string>();

  constructor() {}

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  toggleStudentDropdown(key: string) {
    this.showStudentDropdowns = {
      ...INITIAL_STUDENT_DROPDOWN_STATE,
      [key]: !this.showStudentDropdowns[key]
    };
  }

  getClassName(c: ClassDataDto) {
    return c.className;
  }

  getClassCode(c: ClassDataDto) {
    return c.classCode;
  }
}

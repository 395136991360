import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, from, map } from 'rxjs';
import { UsersService } from '../shared/users.service';
import { AnalyticsType } from '../shared/interfaces';
import { User, UserCredential } from 'firebase/auth';
import { AppRole } from 'functions/src/shared';
import { AnalyticsService } from '../shared/analytics.service';

export enum signInFlow {
  signIn,
  request,
  resetInvalid,
  reset,
  register,
  alreadyRegistered
}

@Component({
  selector: 'app-teacher-login',
  templateUrl: './teacher-login.component.html',
  styleUrls: ['./teacher-login.component.scss']
})
export class TeacherLoginComponent implements OnInit {
  email = '';
  password = '';
  passwordConfirmation = '';
  displayError: boolean = false;
  displayGoogleError: boolean = false;
  signInFlow = signInFlow;
  private _currentFlow = signInFlow.signIn;
  initialLogin = false;
  resetCode = '';
  resetSent: boolean = false;
  isEmailValid: boolean = false;

  showErrorMessage = false;
  isSubmitting = false;

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    let { mode, oobCode } = this.route.snapshot.queryParams;
    this.initialLogin = mode && mode == 'welcome';
    if (mode && oobCode) {
      from(this.auth.verifyPasswordResetCode(oobCode))
        .pipe(map((res) => [mode, oobCode, res]))
        .subscribe({
          next: ([mode, code, res]) => {
            //if params mode, code, and res is an email, then the code is valid. Set flow to reset screen.
            if (mode && code && res && /^\S+@\S+\.\S+$/.test(res)) {
              this.currentFlow = signInFlow.reset;
              this.resetCode = code;
            } else if (res) {
              this.displayError = false;
            }
          },
          error: () => {
            this.currentFlow = signInFlow.resetInvalid;
          }
        });
    }
  }

  requestReset(): void {
    this.auth.sendPasswordResetEmail(this.email);
    this.resetSent = true;
  }

  completeReset(): void {
    if (this.password === this.passwordConfirmation) {
      this.auth
        .confirmPasswordReset(this.resetCode, this.password)
        .then(() => (this.currentFlow = signInFlow.signIn))
        .catch(() => (this.displayError = true));
    } else {
      this.displayError = true;
    }
  }
  checkEmailValidity(emailField: any) {
    this.isEmailValid = emailField.valid;
  }
  switchToForgotPassword() {
    this.email = '';
    this.isEmailValid = false;
    this.currentFlow = this.signInFlow.request;
  }
  set currentFlow(targetState: signInFlow) {
    this._currentFlow = targetState;
    this.resetSent = false;
    this.displayError = false;
    this.password = '';
    this.passwordConfirmation = '';
    this.email = '';
  }
  get currentFlow() {
    return this._currentFlow;
  }

  async submitLoginForm() {
    this.isSubmitting = true;
    this.displayError = false;

    this.usersService
      .signInUser(this.email, this.password)
      .then(
        async (loginResult) =>
          await this.checkRoleAndNavigateToDashboard(
            loginResult as unknown as UserCredential
          )
      )
      .catch(() => {
        this.displayError = true;
        this.isSubmitting = false;
      });
  }

  handleGoogleLogin() {
    this.usersService.openGoogleLogin().then((result) => {
      this.usersService.logout(false);

      if (result.user) {
        firstValueFrom(this.usersService.getTeacher(result.user?.uid)).then(
          ({ id }) => {
            if (id) {
              this.usersService
                .signInWithCredential(result.credential!)
                .then(
                  async (loginResult) =>
                    await this.checkRoleAndNavigateToDashboard(
                      loginResult as unknown as UserCredential
                    )
                )
                .catch(() => {
                  this.displayGoogleError = true;
                });
            } else {
              this.displayGoogleError = true;
            }
          }
        );
      }
    });
  }

  async checkRoleAndNavigateToDashboard(loginResult: UserCredential) {
    if (
      loginResult.user &&
      (await this.usersService.getUserRole(loginResult.user as User)) ===
        AppRole.TEACHER
    ) {
      this.analyticsService.sendAnalytics(
        AnalyticsType.TEACHER_DASHBOARD_SESSION
      );
      this.router.navigate(['/teacher']);
    } else {
      await this.usersService.logout(false);
      this.displayGoogleError = true;
    }
  }
}

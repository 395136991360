import { Screen } from '../interfaces';
import {
  DelayAnimation,
  PlaySound,
  ShowNarrativeChoice
} from '../ngrx/actions/overlays.actions';
import { AdvanceScreen } from '../ngrx/actions/progress.actions';
import { firstValueFrom } from 'rxjs';
import { selectCharacter } from '../ngrx/selectors/progress.selectors';

export const banking: Screen = {
  component: 'game',
  module: {
    title: {
      chapter: 'Module 3',
      text: 'Banking Basics',
      titleBackgroundImage: '/assets/images/apartment-bg.png'
    },
    animation: async (store, assetManager) => {
      const character = await firstValueFrom(store.select(selectCharacter));
      const sounds = [
        `/assets/sounds/scene6/Scene6${character.soundAlias}.mp3`
      ];
      await assetManager.loadAssets(sounds);
      return {
        layerPaths: ['/assets/lottie/scene6.json'],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...PlaySound({
              src: `/assets/sounds/scene6/Scene6${character.soundAlias}.mp3`
            }),
            segment: [0, 45],
            pause: false
          },
          {
            ...ShowNarrativeChoice({ key: 'table' }),
            segment: [45, 480],
            pause: true
          },
          {
            ...DelayAnimation({ delayTime: 3000 }),
            segment: [480, 563],
            pause: true
          },
          {
            ...AdvanceScreen({ noSave: true }),
            segment: [563, 563],
            pause: false
          }
        ]
      };
    },
    infoScreens: {}
  }
};

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  defer,
  delayWhen,
  Observable,
  of,
  Subscription,
  tap,
  timer
} from 'rxjs';
import {
  AnimationData,
  GameModule,
  NarrativeChoice,
  TOOLTIPDELAY
} from '../shared/interfaces';
import { MINI_CHOICES } from '../shared/mini-choices';
import { ProgressService } from '../shared/progress.service';
import { AnimationComponent } from '../animation/animation.component';
import { Store } from '@ngrx/store';
import { ChapterRestarted } from '../shared/ngrx/actions/progress.actions';
import { UpdateSubtitle } from '../shared/ngrx/actions/overlays.actions';
import { Actions, ofType } from '@ngrx/effects';
import {
  selectFullScreenInfoKey,
  selectNarrativeChoiceKey,
  selectToolTip
} from '../shared/ngrx/selectors/overlays.selectors';
import { AssetManagerService } from '../shared/asset-manager.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild(AnimationComponent) animation!: AnimationComponent;
  @Input() gameData!: GameModule;
  private subscriptions: Subscription[] = [];

  animationData$!: Observable<AnimationData>;
  showTitleScreen = true;
  showFullScreenInfo = '';
  showNarrativeChoice = this.store.select(selectNarrativeChoiceKey);
  showToolTip = this.store
    .select(selectToolTip('large'))
    .pipe(delayWhen((options) => timer(options?.delayed ? TOOLTIPDELAY : 0)));
  choices: { [key: string]: NarrativeChoice } = MINI_CHOICES;
  buttonActive = false;
  private restartEffect = this.actions
    .pipe(
      ofType(ChapterRestarted),
      tap(() => this.reset())
    )
    .subscribe();

  constructor(
    private actions: Actions,
    public progressService: ProgressService,
    public store: Store,
    private assetManager: AssetManagerService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectFullScreenInfoKey)
        .subscribe((key) => (this.showFullScreenInfo = key))
    );
  }

  ngOnChanges() {
    this.reset();
    this.animationData$ = defer(() =>
      typeof this.gameData.animation === 'function'
        ? this.gameData.animation(this.store, this.assetManager)
        : of(this.gameData.animation)
    );
  }

  reset() {
    this.showTitleScreen = true;
    this.buttonActive = false;
    this.store.dispatch(UpdateSubtitle({ lines: [] }));
  }

  handlePreload() {
    this.buttonActive = true;
    this.changeDetector.detectChanges();
  }
  resumeAnimation() {
    this.animation.resumeAnimation();
  }

  startChapter() {
    this.showTitleScreen = false;
    this.animation.resumeAnimation();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.restartEffect.unsubscribe();
  }
}

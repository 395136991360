import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import {
  ChooseCharacter,
  ChooseJob
} from '../shared/ngrx/actions/progress.actions';
import { Store } from '@ngrx/store';
import { AdvanceScreen } from '../shared/ngrx/actions/progress.actions';
//@ts-ignore
import focusLock from 'dom-focus-lock';
export interface Character {
  name: string;
  soundAlias: string;
}

export interface Job {
  name: string;
  key: string;
  salaryText: string;
  description: string;
  salary: number;
}

@Component({
  selector: 'app-profile-select',
  templateUrl: './profile-select.component.html',
  styleUrls: ['./profile-select.component.scss']
})
export class ProfileSelectComponent implements OnChanges, AfterViewInit {
  @Input() type = 'character';
  @ViewChild('container') container!: ElementRef;

  characters: Character[] = [
    {
      name: 'aya',
      soundAlias: 'MC1'
    },
    {
      name: 'grey',
      soundAlias: 'MC2'
    },
    {
      name: 'river',
      soundAlias: 'MC3'
    }
  ];
  currentSelection: number = 0;
  jobs: Job[] = [
    {
      name: 'Electrician Apprentice',
      key: 'electrician',
      salaryText: '$36,000 per year',
      salary: 36000,
      description:
        "You’ll be working on the city's latest ultra-tall skyscraper, learning how to weld and wire parts. This job has the longest commute, but you get to work on something that can be seen from all corners of the city, and you get full healthcare and dental! Don’t eat your lunch on a steel beam, though …"
    },
    {
      name: 'Entry-level Coder',
      key: 'coder',
      salaryText: '$42,000 per year with a retirement plan',
      salary: 42000,
      description:
        'This one popped up on a job board, and you had to check it out. You’ll be learning the basics of designing and playtesting games - along with free healthcare, dental, and (most importantly) snacks. Maybe you’ll create the next big game!'
    },
    {
      name: 'Sneaker Design Intern',
      key: 'designer',
      salaryText: '$30,000 per year with a performance bonus',
      salary: 30000,
      description:
        'You emailed the hiring manager yourself. You’ll be designing the next big kicks with the hottest designer on the market … or at least helping out. It’s an up-and-coming company, but they’re getting a lot of internet buzz. Since you’re an intern, you don’t get healthcare, but you do get a free pair of shoes occasionally.'
    }
  ];
  selectionFinished: boolean = false;
  showCourseIntro: boolean = true;
  showCharacterChoiceIntro: boolean = false;

  options: AnimationOptions = {
    path: '/assets/lottie/walking.json'
  };

  constructor(
    public store: Store,
    private elRef: ElementRef
  ) {}

  ngOnChanges() {
    this.currentSelection = 0;
    this.selectionFinished = false;
    this.showCourseIntro = this.type === 'character';
    this.showCharacterChoiceIntro = false;
    if (!this.showCourseIntro) focusLock.on(this.elRef.nativeElement);
  }

  ngAfterViewInit(): void {
    focusLock.on(this.elRef.nativeElement);
  }

  handleImageClick(i: number) {
    this.currentSelection = i;
  }

  handleOptionSelect() {
    if (this.type === 'job') {
      this.store.dispatch(ChooseJob({ job: this.jobs[this.currentSelection] }));
    } else if (this.type === 'character') {
      this.store.dispatch(
        ChooseCharacter({ character: this.characters[this.currentSelection] })
      );
    }
    focusLock.off(this.elRef.nativeElement);
    this.selectionFinished = true;
  }

  handleFinish() {
    this.store.dispatch(AdvanceScreen({}));
  }
  handleCharacterCycle(direction: -1 | 1) {
    if (direction === -1) {
      this.currentSelection =
        this.currentSelection === 0
          ? this.characters.length - 1
          : this.currentSelection - 1;
    } else {
      this.currentSelection =
        this.currentSelection === this.characters.length - 1
          ? 0
          : this.currentSelection + 1;
    }
  }
}

import { Screen } from '../interfaces';
import { preassessment } from './assessment.screen';
import { CharacterSelect, JobSelect } from './profile-select.screen';
import { roommateIntroScreen } from './roommate-intro.screen';
import { startingJob } from './starting-job.screen';
import { budgeting } from './budgeting.screen';
import { retirementScreen } from './retirement.screen';
import { banking } from './banking.screen';
import { bankingTwo } from './banking-two.screen';
import { bankingThree } from './banking-three.screen';
import { credit } from './credit.screen';
import { finalSummary } from './final-summary.screen';
import { postassessment } from './post-assessment.screen';
import { paycheck } from './paycheck.screen';

export const ScreenSequence: Screen[] = [
  preassessment,
  CharacterSelect,
  JobSelect,
  roommateIntroScreen,
  startingJob,
  paycheck,
  budgeting,
  banking,
  bankingTwo,
  bankingThree,
  retirementScreen,
  credit,
  finalSummary,
  postassessment
];

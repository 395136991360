import { createReducer, on } from '@ngrx/store';
import {
  AdvanceScreen,
  JumpToScreen,
  ModifyBudget,
  ChooseCharacter,
  ChooseJob,
  ModifyCredit,
  RestoreProgress,
  UpdateBalance,
  UpdateSavings,
  SaveTableChoice,
  SaveSavingsGoal,
  ShowSavings,
  ShowBudgetButton,
  SetPreAssessmentScore,
  EnableRetirementInBudget,
  MarkGameComplete,
  SetLastModule
} from '../actions/progress.actions';

export interface ProgressState {
  currentScreen: number;
  character: any;
  job: any;
  balance: number;
  savings: number;
  budget: {
    [key: string]: number;
  };
  credit: {
    card: string;
    payment: number;
    amountOnCard: number;
    months: number;
    monthlyPayment: number;
  };
  enableRetirementInBudget: boolean;
  tableChoice: string;
  savingsGoal: string;
  showBudgetButton: boolean;
  showSavings: boolean;
  preAssessmentScore: number;
  gameCompleted: boolean;
  lastModule: number;
}
export const initialProgress: ProgressState = {
  currentScreen: 0,
  character: { name: 'aya', soundAlias: 'MC1' },
  job: { salary: 42000, key: 'electrician' },
  balance: 1000,
  savings: 0,
  budget: {
    groceries: 0,
    transportation: 25,
    laundry: 0,
    discretionary: 0,
    retirement: 0
  },
  credit: {
    card: 'store',
    months: 6,
    amountOnCard: 15,
    payment: 25,
    monthlyPayment: 0
  },
  enableRetirementInBudget: false,
  tableChoice: '',
  savingsGoal: 'Vacation',
  showBudgetButton: false,
  showSavings: false,
  preAssessmentScore: 0,
  gameCompleted: false,
  lastModule: 0
};
export const ProgressReducer = createReducer(
  initialProgress,
  on(
    RestoreProgress,
    (state, { newState }): ProgressState => ({ ...state, ...newState })
  ),
  on(
    AdvanceScreen,
    (state): ProgressState => ({
      ...state,
      currentScreen: state.currentScreen + 1
    })
  ),
  on(
    JumpToScreen,
    (state, { screen }): ProgressState => ({ ...state, currentScreen: screen })
  ),
  on(
    ChooseCharacter,
    (state, { character }): ProgressState => ({ ...state, character })
  ),
  on(ChooseJob, (state, { job }): ProgressState => ({ ...state, job })),
  on(ModifyCredit, (state, { credit }): ProgressState => {
    return { ...state, credit: Object.assign({ ...state.credit }, credit) };
  }),
  on(
    UpdateBalance,
    (state, { value }): ProgressState => ({
      ...state,
      balance: state.balance + value
    })
  ),
  on(
    UpdateSavings,
    (state, { value }): ProgressState => ({
      ...state,
      savings: state.savings + value,
      balance: state.balance - value
    })
  ),
  on(
    SetPreAssessmentScore,
    (state, { score }): ProgressState => ({
      ...state,
      preAssessmentScore: score
    })
  ),
  on(ModifyBudget, (state, { budget, updateBalance }): ProgressState => {
    return {
      ...state,
      budget: Object.assign({ ...state.budget }, budget),
      balance: updateBalance
        ? state.balance + getBudgetDifference(state.budget, budget)
        : state.balance
    };
  }),
  on(
    SaveTableChoice,
    (state, { choice }): ProgressState => ({ ...state, tableChoice: choice })
  ),
  on(
    SaveSavingsGoal,
    (state, { goal }): ProgressState => ({ ...state, savingsGoal: goal })
  ),
  on(ShowSavings, (state): ProgressState => ({ ...state, showSavings: true })),
  on(
    ShowBudgetButton,
    (state): ProgressState => ({ ...state, showBudgetButton: true })
  ),
  on(
    MarkGameComplete,
    (state): ProgressState => ({ ...state, gameCompleted: true })
  ),
  on(
    EnableRetirementInBudget,
    (state): ProgressState => ({ ...state, enableRetirementInBudget: true })
  ),
  on(
    SetLastModule,
    (state, { lastModule }): ProgressState => ({ ...state, lastModule })
  )
);

function getBudgetDifference(oldBudget: any, newBudget: any) {
  let difference = 0;
  Object.keys(newBudget).forEach((key) => {
    difference += oldBudget[key] - newBudget[key];
  });
  return difference;
}

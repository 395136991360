<div
  class="summary-container d-flex justify-content-center align-items-center img-bg h-100 w-100"
>
  <div
    class="content-window d-flex justify-content-center align-items-center bg-white"
    [ngSwitch]="currentPart"
  >
    <div class="inner-content-window" *ngSwitchCase="'avatar'">
      <h1 class="wonky">You did it!</h1>
      <img
        class="character-image"
        [src]="'/assets/images/' + character.name + '-avatar-finish.svg'"
        [alt]="character.name + ' is proud of their accomplishment'"
      />
      <p class="fw-bold">Let's take a look at your summary.</p>
      <button (click)="currentPart = 'score'" class="teal-btn fs-2">
        View Summary
      </button>
    </div>
    <div class="inner-content-window final-score" *ngSwitchCase="'score'">
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="balance < 0">
          <img
            class="wallet-img"
            alt="wallet score 1"
            src="/assets/images/wallet-1.svg"
          />
          <p class="summary-text" *ngSwitchCase="balance < 0">
            Boy, your paycheck burns a hole in your pocket, huh? Effective money
            management will help you keep your bank account healthy next time.
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="balance >= 0 && balance <= 250">
          <img
            class="wallet-img"
            alt="wallet score 2"
            src="/assets/images/wallet-2.svg"
          />
          <p class="summary-text">
            Your wallet might feel a little light after the financial choices
            you’ve made, but there’s always time to improve your money
            management and work toward larger savings goals.
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="balance > 250 && balance <= 750">
          <img
            class="wallet-img"
            alt="wallet score 3"
            src="/assets/images/wallet-3.svg"
          />
          <p class="summary-text">
            You managed to make some good financial choices, but you could
            achieve those savings goals more efficiently by using some of the
            financial tooltips along the way.
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="balance > 750 && balance <= 1250">
          <img
            class="wallet-img"
            alt="wallet score 4"
            src="/assets/images/wallet-4.svg"
          />
          <p class="summary-text">
            You’re getting the hang of personal finance and goal-setting!
            There’s always room to improve, but you’ve taken the right steps
            towards financial independence.
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="balance > 1250">
          <img
            class="wallet-img"
            alt="wallet score 5"
            src="/assets/images/wallet-5.svg"
          />
          <p class="summary-text">
            Wow, you’re a budgeting and savings pro! You’ve got a handle on all
            the tools you need for financial independence.
          </p>
        </ng-container>
      </ng-container>
      <button (click)="currentPart = 'congrats'" class="teal-btn fs-2">
        Next
      </button>
    </div>
    <div class="inner-content-window congrats" *ngSwitchCase="'congrats'">
      <h1 class="wonky">Congratulations!</h1>
      <img alt="congrats" src="/assets/images/congrats.svg" />
      <p>
        You completed the game! Based on how you played, here are some tips and
        suggestions for financial success in the future.
      </p>
      <button (click)="currentPart = 'suggestions'" class="teal-btn fs-2">
        Next
      </button>
    </div>
    <div class="inner-content-window suggestions" *ngSwitchCase="'suggestions'">
      <h1 class="wonky">How to Up Your Game</h1>
      <img
        [src]="suggestions[suggestionIndex].image"
        [alt]="suggestions[suggestionIndex].altText"
      />
      <p class="summary-text">{{ suggestions[suggestionIndex].text }}</p>
      <button (click)="advanceSuggestion()" class="teal-btn fs-2">Next</button>
    </div>
  </div>
</div>

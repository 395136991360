import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectToolTip } from '../shared/ngrx/selectors/overlays.selectors';
import {
  selectBalance,
  selectSavings,
  selectCharacter,
  selectShowBudgetButton,
  selectShowSavings
} from '../shared/ngrx/selectors/progress.selectors';
import { ProgressService } from '../shared/progress.service';
import { BudgetModalLaunched } from '../shared/ngrx/actions/modals/modals.actions';
import { ChapterRestarted } from '../shared/ngrx/actions/progress.actions';
import {
  FreezeAnimation,
  UnfreezeAnimation
} from '../shared/ngrx/actions/overlays.actions';

@Component({
  selector: 'app-status-panel',
  templateUrl: './status-panel.component.html',
  styleUrls: ['./status-panel.component.scss']
})
export class StatusPanelComponent implements OnDestroy {
  balance = this.store.select(selectBalance);
  savings = this.store.select(selectSavings);
  character = this.store.select(selectCharacter);
  showBudgetButton = this.store.select(selectShowBudgetButton);
  showSavings = this.store.select(selectShowSavings);
  private subscriptions: Subscription[] = [];
  showToolTip = this.store.select(selectToolTip('small'));
  buttonDisplay = 'pause';

  constructor(
    private progressService: ProgressService,
    private store: Store
  ) {}

  openBudget() {
    if (!this.progressService.getManualPauseFlag()) {
      this.progressService.setManualPauseFlag('budget');
    }
    this.store.dispatch(BudgetModalLaunched({ fromButton: true }));
  }

  restartChapter() {
    this.buttonDisplay = 'pause';
    this.store.dispatch(ChapterRestarted());
  }
  togglePause() {
    if (this.buttonDisplay === 'pause') this.pause();
    else this.unpause();
  }
  pause() {
    this.progressService.setManualPauseFlag('button');
    this.buttonDisplay = 'play';
    this.store.dispatch(FreezeAnimation());
  }

  unpause() {
    this.progressService.setManualPauseFlag(null);
    this.buttonDisplay = 'pause';
    this.store.dispatch(UnfreezeAnimation());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { TeacherRole } from 'src/app/shared/interfaces';
import { default as schoolData } from '../../../assets/data/schoolData.json';
import { signInFlow } from '../teacher-login.component';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/users.service';
import { AuthCredential } from 'firebase/auth';
import { firstValueFrom } from 'rxjs';

interface SchoolData {
  [state: string]: {
    [city: string]: {
      [district: string]: string[];
    };
  };
}

@Component({
  selector: 'app-teacher-registration-form',
  templateUrl: './teacher-registration-form.component.html',
  styleUrls: ['../teacher-login.component.scss']
})
export class TeacherRegistrationFormComponent {
  @Input() emailContainer!: TemplateRef<any>;
  @Output() changeView = new EventEmitter<signInFlow>();
  SCHOOL_DATA: SchoolData = schoolData as SchoolData;
  signInFlow = signInFlow;
  firstName: string = '';
  lastName: string = '';
  email = '';
  password = '';
  selectedState: string | null = null;
  selectedCity: string | null = null;
  selectedDistrict: string | null = null;
  selectedSchool: string | null = null;
  selectedRole: TeacherRole | null = null;
  googleToken!: AuthCredential | null;

  registerPassword = '';
  confirmRegisterPassword = '';

  showErrorMessage = false;
  isSubmitting = false;
  isEmailValid = true;

  states: string[] = Object.keys(schoolData).sort();
  cities: string[] = [];
  districts: string[] = [];
  schools: string[] = [];
  roles: TeacherRole[] = Object.values(TeacherRole);

  showDropdown: any = {
    state: false,
    city: false,
    district: false,
    school: false,
    role: false
  };

  constructor(
    private router: Router,
    private usersService: UsersService
  ) {}

  handleGoogleRegistration(e: Event) {
    console.log(e);
    this.usersService.openGoogleLogin().then((result) => {
      this.usersService.logout(false);

      if (result.user) {
        firstValueFrom(this.usersService.getTeacher(result.user?.uid)).then(
          ({ id }) => {
            if (id) {
              this.changeView.emit(signInFlow.alreadyRegistered);
            } else {
              this.googleToken = result.credential;
              const splitName = result.user?.displayName?.split(' ');
              this.firstName = splitName?.[0] ?? '';
              this.lastName = splitName?.[1] ?? '';
              this.email = result.user?.email ?? '';
            }
          }
        );
      }
    });
  }

  checkEmailValidity(emailField: any) {
    this.isEmailValid = emailField.valid;
  }

  toggleDropdown(type: string) {
    this.showDropdown[type] = !this.showDropdown[type];
  }

  onSelect(type: string, value: string | TeacherRole) {
    switch (type) {
      case 'state':
        this.selectedState = value;
        this.selectedCity = null;
        this.cities = Object.keys(this.SCHOOL_DATA[this.selectedState]).sort();
        this.selectedDistrict = null;
        this.districts = [];
        this.selectedSchool = null;
        this.schools = [];
        break;
      case 'city':
        this.selectedCity = value;
        this.selectedDistrict = null;
        this.districts = Object.keys(
          this.SCHOOL_DATA[this.selectedState!][this.selectedCity]
        ).sort();
        this.selectedSchool = null;
        this.schools = [];
        break;
      case 'district':
        this.selectedDistrict = value;
        this.selectedSchool = null;
        this.schools =
          this.SCHOOL_DATA[this.selectedState!][this.selectedCity!][
            this.selectedDistrict
          ].sort();
        break;
      case 'school':
        this.selectedSchool = value;
        break;
      case 'role':
        this.selectedRole = value as TeacherRole;
        break;
      default:
        console.error('Invalid dropdown type:', type);
    }
    this.toggleDropdown(type);
  }

  isFormValid() {
    const basicValidation =
      this.firstName &&
      this.lastName &&
      this.selectedState &&
      this.selectedCity &&
      this.selectedDistrict &&
      this.selectedSchool &&
      this.selectedRole &&
      this.email;

    if (this.googleToken) {
      return basicValidation;
    }

    return (
      basicValidation &&
      this.registerPassword &&
      this.confirmRegisterPassword &&
      this.registerPassword === this.confirmRegisterPassword
    );
  }

  submitForm() {
    if (this.googleToken) {
      this.submitGoogleRegistrationForm();
    } else {
      this.submitRegistrationForm();
    }
  }

  submitRegistrationForm() {
    this.isSubmitting = true;
    this.isFormValid() &&
      this.usersService
        .registerTeacher({
          firstName: this.firstName,
          lastName: this.lastName,
          state: this.selectedState!,
          city: this.selectedCity!,
          district: this.selectedDistrict!,
          school: this.selectedSchool!,
          role: this.selectedRole!,
          email: this.email,
          password: this.registerPassword
        })
        .subscribe({
          complete: async () => {
            await this.usersService.signInUser(
              this.email,
              this.registerPassword
            );
            this.router.navigate(['/teacher']);
          },
          error: () => {
            this.showErrorMessage = true;
            this.isSubmitting = false;
          }
        });
  }

  async submitGoogleRegistrationForm() {
    this.isSubmitting = true;
    await this.usersService.signInWithCredential(this.googleToken!);
    this.isFormValid() &&
      (
        await this.usersService.registerGoogleTeacher({
          firstName: this.firstName,
          lastName: this.lastName,
          state: this.selectedState!,
          city: this.selectedCity!,
          district: this.selectedDistrict!,
          school: this.selectedSchool!,
          role: this.selectedRole!,
          email: this.email,
          uid: (await this.usersService.getCurrentUser())?.uid || ''
        })
      ).subscribe({
        complete: async () => {
          await this.usersService.signInWithCredential(this.googleToken!);
          this.router.navigate(['/teacher']);
        },
        error: async () => {
          await (await this.usersService.getCurrentUser())?.delete();
          this.showErrorMessage = true;
          this.isSubmitting = false;
        }
      });
  }

  getDropdownValue(item: string) {
    return item;
  }
}

import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, EMPTY, filter, mergeMap, of, tap } from 'rxjs';
import {
  AnimationResumed,
  FreezeAnimation,
  UnfreezeAnimation
} from '../actions/overlays.actions';
import { RestartCourse } from '../actions/progress.actions';
import { SavingsModalComponent } from '../../../savings-modal/savings-modal.component';
import { BudgetModalComponent } from '../../../budget-modal/budget-modal.component';
import { CreditCardModalComponent } from '../../../credit-card-modal/credit-card-modal.component';
import {
  BudgetModalLaunched,
  CreditCardModalLaunched,
  ModalClosed,
  RetirementModalLaunched,
  SavingsModalLaunched
} from '../actions/modals/modals.actions';
import { RetirementModalComponent } from 'src/app/retirement-modal/retirement-modal.component';
import { ProgressService } from '../../progress.service';

@Injectable()
export class ModalEffects {
  modalRef!: NgbModalRef;

  openRetirementModal = createEffect(
    () => {
      return this.actions.pipe(
        ofType(RetirementModalLaunched),
        tap(
          () =>
            (this.modalRef = this.modalsService.open(RetirementModalComponent, {
              size: 'lg',
              windowClass: 'notepad',
              backdrop: 'static',
              animation: false,
              keyboard: false,
              ariaLabelledBy: 'title'
            }))
        )
      );
    },
    { dispatch: false }
  );

  openSavingsModal = createEffect(
    () => {
      return this.actions.pipe(
        ofType(SavingsModalLaunched),
        tap(
          () =>
            (this.modalRef = this.modalsService.open(SavingsModalComponent, {
              size: 'lg',
              windowClass: 'notepad',
              backdrop: 'static',
              animation: false,
              keyboard: false,
              ariaLabelledBy: 'title'
            }))
        )
      );
    },
    { dispatch: false }
  );

  openCreditCardModal = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CreditCardModalLaunched),
        tap(
          () =>
            (this.modalRef = this.modalsService.open(CreditCardModalComponent, {
              size: 'lg',
              windowClass: 'notepad',
              backdrop: 'static',
              animation: false,
              keyboard: false,
              ariaLabelledBy: 'title'
            }))
        )
      );
    },
    { dispatch: false }
  );

  openBudgetModal = createEffect(
    () => {
      return this.actions.pipe(
        ofType(BudgetModalLaunched),
        tap(
          () =>
            (this.modalRef = this.modalsService.open(BudgetModalComponent, {
              size: 'lg',
              windowClass: 'notepad',
              backdrop: 'static',
              animation: false,
              keyboard: false,
              ariaLabelledBy: 'title'
            }))
        )
      );
    },
    { dispatch: false }
  );

  freezeForManualBudgetOpen = createEffect(() => {
    return this.actions.pipe(
      ofType(BudgetModalLaunched),
      filter(({ fromButton }) => !!fromButton),
      mergeMap(() => of(FreezeAnimation()))
    );
  });

  closeModal = createEffect(() => {
    return this.actions.pipe(
      ofType(ModalClosed),
      tap(() => {
        document.querySelector('.modal-content')!.classList.add('close');
        setTimeout(() => this.modalsService.dismissAll(), 500);
      }),
      mergeMap(({ result }) => (result ? of(result) : EMPTY))
    );
  });

  closeModalFromRestart = createEffect(
    () => {
      return this.actions.pipe(
        ofType(RestartCourse),
        tap(() => this.modalsService.dismissAll())
      );
    },
    { dispatch: false }
  );

  closeModalAndResumeAnimation = createEffect(() => {
    return this.actions.pipe(
      ofType(ModalClosed),
      filter(({ resumeAnimation }) => !!resumeAnimation),
      filter(() => !this.progressService.getManualPauseFlag()),
      debounceTime(500),
      mergeMap(() => of(AnimationResumed()))
    );
  });

  // This is distinct from resume animation, which triggers the loading of the next segment.
  // This action will unpause the animation that's been frozen by the manual opening of the budget modal.
  closeModalAndUnfreezeAnimation = createEffect(() => {
    return this.actions.pipe(
      ofType(ModalClosed),
      filter(() => this.progressService.getManualPauseFlag() === 'budget'),
      mergeMap(() => of(UnfreezeAnimation()))
    );
  });

  constructor(
    private actions: Actions,
    private modalsService: NgbModal,
    private progressService: ProgressService
  ) {}
}

import { Screen } from '../interfaces';
import {
  selectBalance,
  selectBudget,
  selectCharacter,
  selectJob
} from '../ngrx/selectors/progress.selectors';
import { firstValueFrom } from 'rxjs';
import { BudgetModalLaunched } from '../ngrx/actions/modals/modals.actions';
import {
  DelayAnimation,
  PlaySound,
  JumpToAnimationEvent,
  ShowToolTip,
  ShowFullScreenInfo,
  ShowNarrativeChoice
} from '../ngrx/actions/overlays.actions';
import {
  UpdateBalance,
  ShowBudgetButton,
  AdvanceScreen
} from '../ngrx/actions/progress.actions';

export const budgeting: Screen = {
  component: 'game',
  module: {
    title: {
      chapter: 'Module 2',
      text: 'Budgeting',
      titleBackgroundImage: '/assets/images/apartment-bg.png'
    },
    animation: async (store, assetManager) => {
      let job = await firstValueFrom(store.select(selectJob));
      let character = await firstValueFrom(store.select(selectCharacter));
      let possibleExpenses = [
        {
          key: 'dinnerExpense',
          cost: -25
        },
        {
          key: 'phoneExpense',
          cost: -50
        },
        {
          key:
            job.key === 'designer'
              ? 'hospitalExpenseDesigner'
              : 'hospitalExpense',
          cost: job.key === 'designer' ? -550 : -30
        },
        {
          key: 'laptopExpense',
          cost: -150
        },
        {
          key: 'tripExpense',
          cost: -350
        }
      ];
      const sounds = [
        `/assets/sounds/scene5/S5L1${character.soundAlias}.mp3`,
        `/assets/sounds/scene5/S5L2.mp3`,
        `/assets/sounds/scene5/S5L3${character.soundAlias}.mp3`,
        `/assets/sounds/scene5/S5L4.mp3`,
        `/assets/sounds/scene5/S5L5${character.soundAlias}.mp3`,
        `/assets/sounds/scene5/S5L7${character.soundAlias}.mp3`,
        `/assets/sounds/scene5/S5L8.mp3`,
        `/assets/sounds/scene5/S5L9${character.soundAlias}.mp3`,
        `/assets/sounds/scene5/S5L10.mp3`,
        `/assets/sounds/scene5/S5L11${character.soundAlias}.mp3`,
        `/assets/sounds/scene5/S5L12.mp3`,
        `/assets/sounds/scene5/S5L13${character.soundAlias}.mp3`,
        `/assets/sounds/scene5/S5L14.mp3`,
        `/assets/sounds/scene5/S5L15${character.soundAlias}.mp3`
      ];
      await assetManager.loadAssets(sounds);
      return {
        layerPaths: ['/assets/lottie/scene5.json'],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...PlaySound({
              src: `/assets/sounds/scene5/S5L1${character.soundAlias}.mp3`
            }),
            segment: [0, 0],
            pause: false
          },
          {
            ...UpdateBalance({ value: -600 }),
            segment: [0, 160],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene5/S5L2.mp3` }),
            segment: [160, 175],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene5/S5L3${character.soundAlias}.mp3`
            }),
            segment: [175, 280],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene5/S5L4.mp3` }),
            segment: [280, 410],
            pause: false
          },
          {
            ...ShowToolTip({
              options: {
                text: '<b>Keep a journal to keep track of your spending.</b> That means saving your receipts and writing down the items and amounts for everything you spend. Check to see if your financial institution or credit card company offers a free tool to help you track your spending.',
                type: 'large',
                mood: 'neutral'
              }
            }),
            segment: [410, 660],
            pause: true
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene5/S5L5${character.soundAlias}.mp3`
            }),
            segment: [660, 660],
            pause: false
          },
          {
            ...UpdateBalance({ value: -25 }),
            segment: [660, 730],
            pause: false
          },
          async () => {
            let budget = await firstValueFrom(store.select(selectBudget));
            return {
              ...UpdateBalance({ value: -budget['groceries'] }),
              segment: [730, 780],
              pause: false
            };
          },
          {
            ...UpdateBalance({ value: -25 }),
            segment: [780, 830],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene5/S5L7${character.soundAlias}.mp3`
            }),
            segment: [830, 870],
            pause: false
          },
          {
            ...ShowNarrativeChoice({ key: 'extra-expense' }),
            segment: [870, 1010],
            pause: true
          },
          async () => {
            let budget = await firstValueFrom(store.select(selectBudget));
            return {
              ...UpdateBalance({ value: -budget['discretionary'] }),
              segment: [1100, 1100],
              pause: false,
              noAudio: true
            };
          },
          {
            ...DelayAnimation({ delayTime: 3000 }),
            segment: [1100, 1100],
            pause: true,
            noAudio: true
          },
          async () => {
            let balance = await firstValueFrom(store.select(selectBalance));
            let filteredExpenses = possibleExpenses.filter(
              (e) => balance + e.cost >= 0
            );
            let expense =
              filteredExpenses[
                Math.floor(Math.random() * filteredExpenses.length)
              ];
            return {
              ...ShowFullScreenInfo({ key: expense.key }),
              segment: [1060, 1100],
              pause: true,
              noAudio: true
            };
          },
          {
            ...DelayAnimation({ delayTime: 3000 }),
            segment: [1100, 1100],
            pause: true,
            noAudio: true
          },
          async () => {
            let balance = await firstValueFrom(store.select(selectBalance));
            return {
              ...ShowFullScreenInfo({
                key: balance >= 500 ? 'balanceSuccess' : 'balanceFailure'
              }),
              segment: [1100, 1100],
              pause: true,
              noAudio: true
            };
          },
          {
            ...ShowToolTip({
              options: {
                text: '<b>Create an emergency savings account.</b> This should be 3 to 6 months of your expenses. This “rainy day” fund can help reduce your stress and avoid alternatives that cost you more in the long run, like loans or credit cards with high interest rates.',
                type: 'large',
                mood: 'neutral'
              }
            }),
            segment: [1100, 1100],
            pause: true,
            noAudio: true
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene5/S5L8.mp3` }),
            segment: [1100, 1105],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene5/S5L9${character.soundAlias}.mp3`
            }),
            segment: [1105, 1290],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene5/S5L10.mp3` }),
            segment: [1290, 1390],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene5/S5L11${character.soundAlias}.mp3`
            }),
            segment: [1390, 2070],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene5/S5L12.mp3` }),
            segment: [2070, 2260],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene5/S5L13${character.soundAlias}.mp3`
            }),
            segment: [2260, 2320],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene5/S5L14.mp3` }),
            segment: [2320, 2370],
            pause: false
          },
          {
            ...ShowToolTip({
              options: {
                type: 'large',
                mood: 'neutral',
                text: 'Remember! How you spend, and how much you spend, is really up to you. Try to be realistic and flexible, though. The goal is to spend less than you make so you still have some money left over for other things you want to do.',
                avatar: job.key,
                delayed: true
              }
            }),
            segment: [2370, 2930],
            pause: false
          },
          {
            ...BudgetModalLaunched({}),
            segment: [2930, 2930],
            pause: true
          },
          {
            ...ShowBudgetButton(),
            segment: [2930, 2930],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene5/S5L15${character.soundAlias}.mp3`
            }),
            segment: [2930, 2930],
            pause: false
          },
          {
            ...ShowNarrativeChoice({ key: 'budget-savings' }),
            segment: [2910, 3080],
            pause: true
          },
          {
            ...DelayAnimation({ delayTime: 3000 }),
            segment: [3080, 3080],
            pause: true,
            noAudio: true
          },
          async () => {
            const balance = await firstValueFrom(store.select(selectBalance));

            return {
              ...ShowFullScreenInfo({
                key: balance > 0 ? 'budgetSuccess' : 'budgetFailure'
              }),
              segment: [3080, 3096],
              pause: true
            };
          },
          {
            ...BudgetModalLaunched({}),
            segment: [3080, 3096],
            pause: true,
            noAudio: true
          },
          {
            ...DelayAnimation({ delayTime: 3000 }),
            segment: [3080, 3080],
            pause: true,
            noAudio: true
          },
          async () => {
            const balance = await firstValueFrom(store.select(selectBalance));

            return {
              ...ShowFullScreenInfo({
                key: balance > 0 ? 'budgetSuccess' : 'budgetFailure'
              }),
              segment: [3080, 3096],
              pause: true,
              noAudio: true
            };
          }
        ]
      };
    },
    infoScreens: {
      dinnerExpense: {
        header: 'Hold on!',
        text: 'Sometimes life throws you a curveball, and you need to plan for the unexpected. The week after you went shopping, you had to split the check at Brody’s birthday dinner.',
        buttonText: 'Okay',
        action: UpdateBalance({ value: -25 }),
        resumeAnimation: true,
        useCoinImage: true
      },
      phoneExpense: {
        header: 'Hold on!',
        text: 'Sometimes life throws you a curveball, and you need to plan for the unexpected. The week after you went shopping, you had to replace your cracked phone screen.',
        buttonText: 'Okay',
        action: UpdateBalance({ value: -50 }),
        resumeAnimation: true,
        useCoinImage: true
      },
      hospitalExpense: {
        header: 'Hold on!',
        text: 'Sometimes life throws you a curveball, and you need to plan for the unexpected. The week after you went shopping, you tripped and sprained your ankle. A hospital visit later,  you have a medical bill to pay.',
        extraHtml:
          '<span class="fst-italic hospital">(*Note: If the job you chose offers insurance, you only have to pay a $30 copay).</span>',
        buttonText: 'Okay',
        action: UpdateBalance({ value: -30 }),
        resumeAnimation: true,
        useCoinImage: true
      },
      hospitalExpenseDesigner: {
        header: 'Hold on!',
        text: 'Sometimes life throws you a curveball, and you need to plan for the unexpected. The week after you went shopping, you tripped and sprained your ankle. A hospital visit later,  you have a medical bill to pay.',
        extraHtml:
          '<span class="fst-italic hospital">(*Note: If the job you chose offers insurance, you only have to pay a $30 copay).</span>',
        buttonText: 'Okay',
        action: UpdateBalance({ value: -550 }),
        resumeAnimation: true,
        useCoinImage: true
      },
      laptopExpense: {
        header: 'Hold on!',
        text: 'Sometimes life throws you a curveball, and you need to plan for the unexpected. The week after you went shopping, you spilled water over your laptop and need to have the keyboard replaced.',
        buttonText: 'Okay',
        action: UpdateBalance({ value: -150 }),
        resumeAnimation: true,
        useCoinImage: true
      },
      tripExpense: {
        header: 'Hold on!',
        text: 'Sometimes life throws you a curveball, and you need to plan for the unexpected. The week after you went shopping, you bought a last-minute flight home to visit your grandma for a weekend.',
        buttonText: 'Okay',
        action: UpdateBalance({ value: -350 }),
        resumeAnimation: true,
        useCoinImage: true
      },
      balanceSuccess: {
        header: 'Nice job!',
        text: "You’ve got the basics of creating a month-to-month budget. Now let's start looking toward the future.",
        buttonText: "Let's go!",
        action: DelayAnimation({ delayTime: 3000 })
      },
      balanceFailure: {
        header: 'Whoops...',
        text: 'Your cash-flow is looking awfully tight. You never know what other unexpected expenses life will throw at you–consider lessening the number of purchases you make.',
        buttonText: 'Got it',
        action: DelayAnimation({ delayTime: 3000 })
      },
      budgetSuccess: {
        header: 'Wow!',
        text: 'You’re well on your way to hitting your goal—and you’re keeping up with life expenses at the same time! Now let’s see if there are other tools to help you maintain your budget…',
        buttonText: 'Next Chapter',
        action: AdvanceScreen({ moduleCompleted: 2 })
      },
      budgetFailure: {
        header: 'Mmm...',
        text: 'While you work towards a larger budget, balancing larger goals with all of your life expenses can be a challenge. Let’s try again, and consider alternative ways to reach your budget goals. As a suggestion, consider paying for things in this order:',
        buttonText: 'Go Back',
        extraHtml:
          '<ul class="mb-5 align-self-start"><li class="list">Paying your monthly bills</li><li class="list">Setting aside money you’ll need for your weekly and day-to-day expenses</li><li class="list">Building your emergency savings</li><li class="list">Setting aside money for larger expenses</li><li class="list">Setting aside money for your major future goals</li>',
        action: JumpToAnimationEvent({ index: -3 })
      }
    }
  }
};

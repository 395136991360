<div
  (window:resize)="resize()"
  [style.height]="animationHeight"
  [style.width]="animationWidth"
  class="animation-container position-relative d-flex justify-content-center align-items-center"
>
  <ng-lottie
    class="position-absolute"
    [options]="options"
    (complete)="onComplete()"
    (animationCreated)="animationCreated($event)"
  ></ng-lottie>
  <div
    class="cc-icon-container cursor-pointer"
    (click)="showSubtitles = !showSubtitles"
    (keydown.enter)="showSubtitles = !showSubtitles"
    tabindex="0"
    role="button"
    [attr.aria-label]="
      showSubtitles ? 'mute closed captions' : 'enable closed captions'
    "
  >
    <img
      aria-hidden="true"
      *ngIf="!showSubtitles"
      src="/assets/icons/cc.svg"
      alt="enable closed captions"
    />
    <img
      aria-hidden="true"
      *ngIf="showSubtitles"
      src="/assets/icons/cc-mute.svg"
      alt="mute closed captions"
    />
  </div>
  <div
    *ngIf="showSubtitles"
    class="text-box-container position-absolute w-100 d-flex justify-content-center"
  >
    <app-textbox [lines]="subtitle | async"></app-textbox>
  </div>
  <div class="status-panel-container position-absolute">
    <app-status-panel></app-status-panel>
  </div>
</div>

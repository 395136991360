import { Screen } from '../interfaces';
import {
  DelayAnimation,
  PlaySound,
  JumpToAnimationEvent,
  ShowToolTip,
  ShowFullScreenInfo
} from '../ngrx/actions/overlays.actions';
import {
  ShowSavings,
  UpdateSavings,
  AdvanceScreen
} from '../ngrx/actions/progress.actions';
import {
  BudgetModalLaunched,
  SavingsModalLaunched
} from '../ngrx/actions/modals/modals.actions';
import { firstValueFrom } from 'rxjs';
import {
  selectBalance,
  selectCharacter,
  selectBudget
} from '../ngrx/selectors/progress.selectors';

export const bankingThree: Screen = {
  component: 'game',
  module: {
    title: {
      autoStart: 3000,
      titleBackgroundImage: '/assets/images/apartment-bg.png'
    },
    animation: async (store, assetManager) => {
      const character = await firstValueFrom(store.select(selectCharacter));
      const sounds = [`/assets/sounds/scene8/S8${character.soundAlias}.mp3`];
      const endingFrame = { MC1: 2327, MC2: 2314, MC3: 2301 }[
        character.soundAlias as string
      ] as number;
      const tooltipFrame = character.soundAlias === 'MC1' ? 1040 : 1020;
      await assetManager.loadAssets(sounds);
      return {
        layerPaths: [
          `/assets/lottie/scene8/scene8${character.soundAlias}.json`
        ],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...PlaySound({
              src: `/assets/sounds/scene8/S8${character.soundAlias}.mp3`
            }),
            segment: [0, 0],
            pause: false
          },
          {
            ...ShowToolTip({
              options: {
                text: '<b>You can make purchases online and by phone with checks or a debit card.</b> Your debit card allows you to access your money at ATMs or by using a digital wallet. Watch out, though—you may be charged a fee for using a foreign ATM or another bank’s ATM.',
                type: 'large',
                mood: 'neutral'
              }
            }),
            segment: [0, tooltipFrame],
            pause: true
          },
          {
            ...ShowSavings(),
            segment: [tooltipFrame, endingFrame],
            pause: false
          },
          {
            ...DelayAnimation({ delayTime: 500 }),
            segment: [endingFrame, endingFrame],
            pause: true,
            noAudio: true
          },
          {
            ...SavingsModalLaunched(),
            segment: [endingFrame, endingFrame],
            pause: true,
            noAudio: true
          },
          async () => {
            let budget = await firstValueFrom(store.select(selectBudget));
            let savingsCost = budget['savingsCost'];
            return {
              ...UpdateSavings({ value: savingsCost }),
              segment: [endingFrame - 10, endingFrame],
              pause: false,
              noAudio: true
            };
          },
          async () => {
            let budget = await firstValueFrom(store.select(selectBudget));
            let savingsCost = budget['savingsCost'];
            return {
              ...ShowToolTip({
                options: {
                  text: `<span class="fw-bold value">$${Math.abs(
                    savingsCost
                  )}</span><br> has been deducted from your balance and added to your savings.`,
                  type: 'small',
                  mood: 'negative'
                }
              }),
              segment: [endingFrame, endingFrame],
              pause: false,
              noAudio: true
            };
          },
          {
            ...DelayAnimation({ delayTime: 2000 }),
            segment: [endingFrame, endingFrame],
            pause: true,
            noAudio: true
          },
          async () => {
            const balance = await firstValueFrom(store.select(selectBalance));

            return {
              ...ShowFullScreenInfo({
                key: balance >= 0 ? 'savingsSuccess' : 'savingsFailure'
              }),
              segment: [endingFrame, endingFrame],
              pause: true,
              noAudio: true
            };
          },
          // Creating a little loop here so that the user can alter their budget first if needed.
          async () => {
            let budget = await firstValueFrom(store.select(selectBudget));
            let savingsCost = budget['savingsCost'];
            return {
              ...UpdateSavings({ value: -savingsCost }),
              segment: [endingFrame, endingFrame],
              pause: false,
              noAudio: true
            };
          },
          {
            ...BudgetModalLaunched({}),
            segment: [endingFrame, endingFrame],
            pause: true,
            noAudio: true
          },
          {
            ...DelayAnimation({ delayTime: 2000 }),
            segment: [endingFrame, endingFrame],
            pause: true,
            noAudio: true
          },
          {
            ...JumpToAnimationEvent({ index: 3 }),
            segment: [endingFrame, endingFrame],
            pause: false,
            noAudio: true
          }
        ]
      };
    },
    infoScreens: {
      savingsSuccess: {
        header: 'Great!',
        text: 'You’re getting more comfortable adjusting your spending and saving as your income becomes more secure. There’s another tool you can use to help maximize your financial potential, though…',
        buttonText: "Let's Go",
        action: AdvanceScreen({ moduleCompleted: 3 })
      },
      savingsFailure: {
        header: 'Overspending',
        text: 'Even with a bonus and long-term interest on savings accounts, it’s important to keep an eye on your finances to avoid overspending.',
        buttonText: 'Go Back',
        action: JumpToAnimationEvent({ index: 9 })
      }
    }
  }
};

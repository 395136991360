import { createAction, props } from '@ngrx/store';
import { NarrativeChoiceOption, ToolTip, ToolTipType } from '../../interfaces';

export const ShowNarrativeChoice = createAction(
  '[Game Screen] Show Narrative Choice',
  props<{ key: string | null }>()
);
export const SubmitNarrativeChoice = createAction(
  '[Narrative Choice] Submitted Narrative Choice',
  props<{ choice: NarrativeChoiceOption }>()
);
export const HideNarrativeChoice = createAction(
  '[Narrative Choice] Hide Narrative Choice'
);
export const ShowFullScreenInfo = createAction(
  '[Full Screen Info] Show Full Screen Info',
  props<{ key: string }>()
);
export const HideFullScreenInfo = createAction(
  '[Full Screen Info] Hide Full Screen Info'
);
export const HideFullScreenInfoWithAction = createAction(
  '[Full Screen Info] Hide Full Screen Info With Action',
  props<{ action: any; resumeAnimation?: boolean }>()
);
export const UpdateSubtitle = createAction(
  '[Animation] Update Subtitle',
  props<{ lines: string[] }>()
);
export const ShowToolTip = createAction(
  '[Game Screen] Show ToolTip',
  props<{ options: ToolTip }>()
);
export const HideToolTip = createAction(
  '[Game Screen] Hide tool tip',
  props<{ size: ToolTipType }>()
);
export const AnimationResumed = createAction('[Animation] Animation Resumed');
export const JumpToAnimationEvent = createAction(
  '[Animation] Jump to Animation Event',
  props<{ index: number }>()
);
export const SkipAnimationEvent = createAction('[Animation] Skip event');
export const FreezeAnimation = createAction('[Animation] Freeze Animation');
export const UnfreezeAnimation = createAction('[Animation] Unfreeze Animation');
export const PlaySound = createAction(
  '[Animation] Play Sound',
  props<{ src: string }>()
);
export const DelayAnimation = createAction(
  '[Animation] Delay Animation',
  props<{ delayTime: number }>()
);
export const CloseBubbleTooltips = createAction(
  '[Tooltips] Close Bubble Tooltips'
);
export const CheckReportsScroll = createAction(
  '[Tooltips] Check Reports Scroll'
);
export const ScrollToRetirement = createAction(
  '[Budget Modal] Scroll to retirement row'
);

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { RestoreProgress } from '../shared/ngrx/actions/progress.actions';
import { initialProgress } from '../shared/ngrx/reducers/progress.reducer';
import { selectProgress } from '../shared/ngrx/selectors/progress.selectors';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from './users.service';
import { User } from 'firebase/auth';
import { AppRole } from './interfaces';
import { ApiClient } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private manualPauseFlag: string | null = null;

  constructor(
    public store: Store,
    private cookieService: CookieService,
    private apiClient: ApiClient,
    private usersService: UsersService
  ) {}

  async saveProgress() {
    const savedProgress = await firstValueFrom(
      this.store.select(selectProgress)
    );
    const user = await this.usersService.getCurrentUser();
    const role = user && (await this.usersService.getUserRole(user as User));

    if (role === AppRole.STUDENT) {
      const token = await user?.getIdToken();
      this.apiClient
        .saveStudentProgress(token as string, savedProgress)
        .subscribe();
    } else {
      this.cookieService.set('dollars-progress', JSON.stringify(savedProgress));
    }
  }

  async restoreProgress() {
    const user = await this.usersService.getCurrentUser();
    const role = user && (await this.usersService.getUserRole(user as User));

    if (role === AppRole.STUDENT) {
      const token = await user?.getIdToken();
      this.apiClient.fetchStudentProgress(token as string).subscribe((res) => {
        if (res) {
          this.store.dispatch(RestoreProgress({ newState: res }));
        }
      });
    } else {
      if (this.cookieService.get('dollars-progress')) {
        this.store.dispatch(
          RestoreProgress({
            newState: JSON.parse(this.cookieService.get('dollars-progress'))
          })
        );
      }
    }
  }

  restartCourse(toAssessment: boolean) {
    const oldProgress = this.cookieService.get('dollars-progress');

    this.cookieService.delete('dollars-progress');
    const newProgress = toAssessment
      ? initialProgress
      : {
          ...initialProgress,
          currentScreen: 1,
          gameCompleted: oldProgress
            ? JSON.parse(oldProgress).gameCompleted
            : false,
          preAssessmentScore: oldProgress
            ? JSON.parse(oldProgress)['preAssessmentScore']
            : 0
        };
    this.store.dispatch(RestoreProgress({ newState: newProgress }));
  }

  getManualPauseFlag() {
    return this.manualPauseFlag;
  }

  setManualPauseFlag(flag: string | null) {
    this.manualPauseFlag = flag;
  }
}

<div
  class="img-bg main-display d-flex justify-content-center align-items-center"
>
  <ng-container [ngSwitch]="currentFlow">
    <form *ngSwitchCase="signInFlow.signIn" (ngSubmit)="signIn()">
      <h1 class="wonky text-uppercase">welcome!</h1>
      <span class="karla sign-in-text">Please Sign In</span>
      <div class="input-section">
        <p
          aria-live="polite"
          *ngIf="initialLogin && !displayError"
          class="karla instructions fst-italic"
        >
          Enter your temporary password, or reset your password below.
        </p>
        <p
          *ngIf="displayError"
          aria-live="polite"
          class="karla instructions fst-italic text-danger"
        >
          The email or password you entered is incorrect.
        </p>
        <ng-template [ngTemplateOutlet]="emailContainer"></ng-template>
        <div class="mb-3 password-control">
          <label for="password" class="form-label text-uppercase"
            >Password</label
          >
          <input
            type="password"
            placeholder="Enter password"
            minlength="6"
            required
            [(ngModel)]="password"
            id="password"
            class="form-control"
            name="password"
            #passwordModel="ngModel"
            #passwordInput
            [ngClass]="{
              'border-danger':
                displayError ||
                (!passwordModel.pristine && !passwordModel.valid)
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              passwordInput.type =
                passwordInput.type === 'password' ? 'text' : 'password'
            "
            [ngSwitch]="passwordInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
      </div>
      <button type="submit" class="teal-btn wonky">Sign-in</button>
      <a
        class="karla fst-italic text-center"
        tabindex="0"
        (keydown.enter)="currentFlow = signInFlow.request"
        (click)="currentFlow = signInFlow.request"
        >Forgot your password?</a
      >
    </form>
    <form *ngSwitchCase="signInFlow.resetInvalid" (ngSubmit)="requestReset()">
      <h1 class="wonky text-uppercase">oops ...</h1>

      <div class="input-section">
        <p
          *ngIf="!resetSent"
          aria-live="polite"
          class="text-danger fst-italic instructions"
        >
          Looks like your link was expired. Enter your email address to receive
          a new link.
        </p>
        <p
          *ngIf="resetSent"
          aria-live="polite"
          class="text-success fst-italic instructions"
        >
          An email has been sent to reset your password.
        </p>
        <ng-template [ngTemplateOutlet]="emailContainer"></ng-template>
      </div>
      <button type="submit" class="teal-btn wonky">reset password</button>
      <a
        class="karla fst-italic text-center"
        tabindex="0"
        (keydown.enter)="currentFlow = signInFlow.signIn"
        (click)="currentFlow = signInFlow.signIn"
        >Sign In Here</a
      >
    </form>
    <form *ngSwitchCase="signInFlow.request" (ngSubmit)="requestReset()">
      <h1 class="wonky text-uppercase">forgot password?</h1>
      <div class="input-section">
        <p
          *ngIf="resetSent"
          aria-live="polite"
          class="fst-italic instructions text-success"
        >
          An email has been sent to reset your password.
        </p>
        <ng-template [ngTemplateOutlet]="emailContainer"></ng-template>
      </div>
      <button type="submit" class="teal-btn wonky">reset password</button>
    </form>
    <form *ngSwitchCase="signInFlow.reset" (ngSubmit)="completeReset()">
      <h1 class="wonky text-uppercase">Reset password</h1>
      <div class="input-section">
        <p
          *ngIf="displayError"
          aria-live="polite"
          class="karla instructions text-danger"
        >
          Passwords don't match
        </p>
        <div class="mb-3 password-control">
          <label for="password" class="form-label text-uppercase"
            >new password</label
          >
          <input
            type="password"
            placeholder="Enter new password"
            minlength="6"
            required
            [(ngModel)]="password"
            id="password"
            class="form-control"
            name="password"
            #passwordModel="ngModel"
            #passwordInput
            [ngClass]="{
              'border-danger':
                displayError ||
                (!passwordModel.pristine && !passwordModel.valid)
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              passwordInput.type =
                passwordInput.type === 'password' ? 'text' : 'password'
            "
            [ngSwitch]="passwordInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
        <div class="mb-3 password-control">
          <label for="passwordConfirmation" class="form-label text-uppercase"
            >confirm new password</label
          >
          <input
            type="password"
            placeholder="Enter new password"
            minlength="6"
            required
            [(ngModel)]="passwordConfirmation"
            id="passwordConfirmation"
            class="form-control"
            name="password"
            #passwordConfirmationModel="ngModel"
            #passwordConfirmationInput
            [ngClass]="{
              'border-danger':
                displayError ||
                (!passwordConfirmationModel.pristine &&
                  (!passwordConfirmationModel.valid ||
                    passwordConfirmation !== password))
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              passwordConfirmationInput.type =
                passwordConfirmationInput.type === 'password'
                  ? 'text'
                  : 'password'
            "
            [ngSwitch]="passwordConfirmationInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
      </div>
      <button type="submit" class="teal-btn wonky">Done</button>
    </form>
  </ng-container>
</div>
<ng-template #emailContainer>
  <div class="mb-3">
    <label for="email" class="form-label text-uppercase">email</label>
    <input
      type="email"
      required
      [(ngModel)]="email"
      placeholder="Enter email address"
      id="email"
      class="form-control"
      name="email"
      #emailInput="ngModel"
      [ngClass]="{
        'border-danger':
          displayError || (!emailInput.pristine && !emailInput.valid)
      }"
    />
  </div>
</ng-template>

<div class="position-relative bubble-container">
  <div #button *ngIf="!disabled">
    <img
      tabindex="0"
      class="cursor-pointer bubble-image"
      (click)="activateTooltip()"
      (keydown.enter)="activateTooltip()"
      [src]="
        icon === 'info'
          ? '/assets/icons/info.svg'
          : '/assets/icons/question.svg'
      "
      [alt]="'toggle tooltip for ' + forAttribute"
      role="button"
      [attr.aria-expanded]="active"
    />
  </div>
  <div #button *ngIf="disabled">
    <img
      class="disabled"
      src="/assets/icons/info-disabled.svg"
      alt="disabled button"
    />
  </div>
  <div
    aria-live="assertive"
    *ngIf="active && !disabled"
    [ngClass]="{ down: showBeneath }"
    class="modal-bubble position-absolute d-flex flex-column justify-content-between"
  >
    <p *ngFor="let paragraph of text.split('\n')">{{ paragraph }}</p>
    <button
      tabindex="0"
      #closeButton
      (keydown.enter)="activateTooltip()"
      class="position-absolute"
      (click)="activateTooltip()"
    >
      <img alt="close" src="/assets/icons/close.svg" />
    </button>
  </div>
</div>

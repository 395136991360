<div class="chart d-flex align-items-center justify-content-center flex-column">
  <div class="content">
    <div class="bars" *ngFor="let item of sortedData">
      <div class="bar" [style.height]="item.size"></div>
    </div>
  </div>
  <div class="line"></div>
  <div class="legends">
    <div class="d-flex flex-column legend" *ngFor="let item of sortedData">
      <span class="fs-6 fw-bold">{{ item.count }}</span>
      <span class="fs-6">{{ item.text }}</span>
    </div>
  </div>
</div>

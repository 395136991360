<form (submit)="submitForm()" #registerForm="ngForm" class="register-form">
  <p class="small-text mb-0">Teacher Account</p>
  <hr />
  <p class="fs-2 fw-bolder mb-0">Create An Account</p>
  <button
    type="button"
    *ngIf="!googleToken"
    class="google-box mx-5 bg-white"
    (click)="handleGoogleRegistration($event)"
  >
    <img src="../../assets/images/google-logo.svg" alt="Google Logo" />
    <span class="mt-0">Sign up with Google</span>
  </button>

  <div *ngIf="!googleToken" class="or-separator">
    <div class="line"></div>
    <p>OR</p>
    <div class="line"></div>
  </div>

  <div class="row input-section" [ngClass]="{ 'mt-5': googleToken }">
    <!-- First Name -->
    <div class="col-6 mb-3">
      <label class="form-label text-uppercase">First Name</label>
      <input
        type="text"
        placeholder="Enter first name"
        required
        [(ngModel)]="firstName"
        name="firstName"
        class="form-control fst-italic"
      />
    </div>
    <!-- Last Name -->
    <div class="col-6 mb-3">
      <label class="form-label text-uppercase">Last Name</label>
      <input
        type="text"
        placeholder="Enter last name"
        required
        [(ngModel)]="lastName"
        name="lastName"
        class="form-control fst-italic"
      />
    </div>
    <!-- State Dropdown -->
    <div class="col-6 mb-3 dropdown">
      <label class="form-label text-uppercase">State</label>
      <app-dropdown
        [dropdownItems]="states"
        [selectedItem]="selectedState!"
        [displayFunction]="getDropdownValue"
        [valueFunction]="getDropdownValue"
        [formStyles]="true"
        [width]="'100%'"
        [placeholder]="'Select a state'"
        (dropdownChange)="onSelect('state', $event)"
      ></app-dropdown>
    </div>
    <!-- City Dropdown -->
    <div class="col-6 mb-3 dropdown">
      <label class="form-label text-uppercase">City or Town</label>
      <app-dropdown
        [dropdownItems]="cities"
        [selectedItem]="selectedCity!"
        [displayFunction]="getDropdownValue"
        [valueFunction]="getDropdownValue"
        [formStyles]="true"
        [width]="'100%'"
        [disabled]="!selectedState"
        [placeholder]="'Select a city or town'"
        (dropdownChange)="onSelect('city', $event)"
      ></app-dropdown>
    </div>
    <!-- District Dropdown -->
    <div class="col-12 mb-3 dropdown">
      <label class="form-label text-uppercase">District</label>
      <app-dropdown
        [dropdownItems]="districts"
        [selectedItem]="selectedDistrict!"
        [displayFunction]="getDropdownValue"
        [valueFunction]="getDropdownValue"
        [formStyles]="true"
        [width]="'100%'"
        [disabled]="!selectedCity"
        [placeholder]="'Select a district'"
        (dropdownChange)="onSelect('district', $event)"
      ></app-dropdown>
    </div>
    <!-- School Dropdown -->
    <div class="col-12 mb-3 dropdown">
      <label class="form-label text-uppercase">School</label>
      <app-dropdown
        [dropdownItems]="schools"
        [selectedItem]="selectedSchool!"
        [displayFunction]="getDropdownValue"
        [valueFunction]="getDropdownValue"
        [formStyles]="true"
        [width]="'100%'"
        [disabled]="!selectedDistrict"
        [placeholder]="'Select a school'"
        (dropdownChange)="onSelect('school', $event)"
      ></app-dropdown>
    </div>
    <!-- Role Dropdown -->
    <div class="col-12 mb-3 dropdown">
      <label class="form-label text-uppercase">Role</label>
      <app-dropdown
        [dropdownItems]="roles"
        [selectedItem]="selectedRole!"
        [displayFunction]="getDropdownValue"
        [valueFunction]="getDropdownValue"
        [formStyles]="true"
        [width]="'100%'"
        [placeholder]="'Select a role'"
        (dropdownChange)="onSelect('role', $event)"
      ></app-dropdown>
    </div>
  </div>

  <div class="input-section">
    <div class="mb-3">
      <label for="email" class="form-label text-uppercase">
        <app-alert-icon
          [condition]="emailField.invalid && emailField.touched"
        ></app-alert-icon>
        email</label
      >
      <input
        type="email"
        required
        [disabled]="!!googleToken"
        [(ngModel)]="email"
        placeholder="Enter email address"
        id="email"
        class="form-control fst-italic"
        name="email"
        (ngModelChange)="checkEmailValidity(emailField)"
        #emailField="ngModel"
        [ngClass]="{
          'border-danger': !emailField.pristine && !emailField.valid
        }"
      />
    </div>
    <div *ngIf="!googleToken" class="mb-3 password-control">
      <label for="registerPassword" class="form-label text-uppercase">
        <app-alert-icon
          [condition]="
            registerPasswordModel.invalid && registerPasswordModel.touched
          "
        ></app-alert-icon>
        Password</label
      >
      <input
        type="password"
        placeholder="Create password"
        [minlength]="googleToken ? 0 : 6"
        [required]="!googleToken"
        [(ngModel)]="registerPassword"
        id="registerPassword"
        class="form-control fst-italic"
        name="registerPassword"
        #registerPasswordModel="ngModel"
        #registerPasswordInput
        [ngClass]="{
          'border-danger':
            !registerPasswordModel.pristine && !registerPasswordModel.valid
        }"
      />
      <button
        class="visibility-toggle"
        type="button"
        (click)="
          registerPasswordInput.type =
            registerPasswordInput.type === 'password' ? 'text' : 'password'
        "
        [ngSwitch]="registerPasswordInput.type"
      >
        <img
          *ngSwitchCase="'password'"
          src="/assets/icons/visibility_24px.svg"
          alt="show password"
        />
        <img
          *ngSwitchCase="'text'"
          src="/assets/icons/visibility_off_24px.svg"
          alt="hide password"
        />
      </button>
    </div>
    <div *ngIf="!googleToken" class="mb-3 password-control">
      <label for="confirmRegisterPassword" class="form-label text-uppercase">
        <app-alert-icon
          [condition]="
            (confirmRegisterPasswordModel.invalid &&
            confirmRegisterPasswordModel.touched) || 
            registerPassword !== confirmRegisterPassword
          "
        ></app-alert-icon>
        Confirm Password</label
      >
      <input
        type="password"
        placeholder="Re-enter password"
        [minlength]="googleToken ? 0 : 6"
        [required]="!googleToken"
        [(ngModel)]="confirmRegisterPassword"
        id="confirmRegisterPassword"
        class="form-control fst-italic"
        name="confirmRegisterPassword"
        #confirmRegisterPasswordModel="ngModel"
        #confirmRegisterPasswordInput
        [ngClass]="{
          'border-danger':
            (!confirmRegisterPasswordModel.pristine &&
            !confirmRegisterPasswordModel.valid) ||
            registerPassword !== confirmRegisterPassword
        }"
      />
      <button
        class="visibility-toggle"
        type="button"
        (click)="
          confirmRegisterPasswordInput.type =
            confirmRegisterPasswordInput.type === 'password'
              ? 'text'
              : 'password'
        "
        [ngSwitch]="confirmRegisterPasswordInput.type"
      >
        <img
          *ngSwitchCase="'password'"
          src="/assets/icons/visibility_24px.svg"
          alt="show password"
        />
        <img
          *ngSwitchCase="'text'"
          src="/assets/icons/visibility_off_24px.svg"
          alt="hide password"
        />
      </button>
    </div>
    <p
      *ngIf="showErrorMessage"
      aria-live="polite"
      class="karla instructions fst-italic text-danger"
    >
      Registration error.
    </p>
  </div>
  <button
    [disabled]="!isFormValid() || !registerForm.valid"
    type="submit"
    class="teal-btn karla"
  >
    SIGN UP
  </button>

  <span>
    Have an account?
    <a
      class="karla fst-italic"
      tabindex="0"
      (keydown.enter)="changeView.emit(signInFlow.signIn)"
      (click)="changeView.emit(signInFlow.signIn)"
      >Sign In</a
    ></span
  >
</form>

import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  NgZone
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import {
  RefetchOptions,
  TeacherDashboardView
} from '../teacher-dashboard.component';
import { ClassesService } from 'src/app/shared/classes.service';
import { ClassDataDto } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.scss']
})
export class CreateClassComponent implements OnInit {
  @Output() changeView = new EventEmitter<TeacherDashboardView>();
  @Output() refetchClasses = new EventEmitter<RefetchOptions>();

  @Input() editMode = false;
  @Input() classData?: ClassDataDto;

  classForm: FormGroup;
  showConfirmationScreen: boolean = false;
  classCode!: string;
  teacherDashboardView = TeacherDashboardView;
  isSubmitting = false;
  displayError = false;
  showErrorMessages = false;

  constructor(
    private fb: FormBuilder,
    private classesService: ClassesService,
    private ngZone: NgZone
  ) {
    this.classForm = this.fb.group({
      className: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]
      ],
      students: this.fb.array([this.createStudent()], [Validators.minLength(1)])
    });
  }

  ngOnInit(): void {
    if (this.editMode && this.classData) {
      this.populateFormData(this.classData);
    }
  }

  populateFormData(data: any): void {
    this.classForm.patchValue({
      className: data.className
    });

    const studentsArray = this.classForm.get('students') as FormArray;
    studentsArray.clear();

    data.students.forEach((student: any) => {
      studentsArray.push(
        this.fb.group({
          name: [
            student.name,
            [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]
          ],
          id: [student.id]
        })
      );
    });
  }

  get students() {
    return this.classForm.get('students') as FormArray;
  }

  createStudent(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]]
    });
  }

  addStudent() {
    this.students.push(this.createStudent());
  }

  removeStudent(index: number) {
    this.students.removeAt(index);
  }

  async onSubmit() {
    if (this.classForm.valid) {
      this.isSubmitting = true;
      if (this.editMode && this.classData) {
        (
          await this.classesService.editClass(
            this.classForm.value,
            this.classData.classCode
          )
        ).subscribe({
          complete: () => {
            this.isSubmitting = false;
            this.refetchClasses.emit({
              classCode: this.classData?.classCode,
              redirect: TeacherDashboardView.students
            });
          },
          error: () => {
            this.isSubmitting = false;
            this.displayError = true;
          }
        });
      } else {
        (await this.classesService.createClass(this.classForm.value)).subscribe(
          {
            next: ({ classCode }) => {
              this.refetchClasses.emit({ classCode });
              this.classCode = classCode;
              this.showConfirmationScreen = true;
            },
            error: () => {
              this.isSubmitting = false;
              this.displayError = true;
            }
          }
        );
      }
    } else {
      console.error('Form is not valid');
    }
  }

  async copyToClipboard() {
    try {
      await navigator.clipboard.writeText(this.classCode);
      console.log('Class code copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy class code: ', err);
    }
  }
  displayErrors() {
    if (this.classForm.invalid) {
      this.classForm.markAllAsTouched();
      this.showErrorMessages = true;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AdvanceScreen } from '../shared/ngrx/actions/progress.actions';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { selectProgress } from '../shared/ngrx/selectors/progress.selectors';

export interface Suggestion {
  text: string;
  image: string;
  altText: string;
}

@Component({
  selector: 'app-final-summary',
  templateUrl: './final-summary.component.html',
  styleUrls: ['./final-summary.component.scss']
})
export class FinalSummaryComponent implements OnInit {
  currentPart = 'avatar';
  character: any = {};
  balance: number = 0;
  suggestions: Suggestion[] = [];
  suggestionIndex: number = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectProgress)
      .pipe(take(1))
      .subscribe((p) => {
        this.character = p.character;
        this.balance = p.balance;
        if (p.budget['retirement'] === 0)
          this.suggestions.push({
            text: 'It’s easy to forego longer-term investments like retirement. Remember, even a small monthly contribution into a retirement account will pay off in the future.',
            image: '/assets/images/retirement.svg',
            altText: 'pile of money'
          });
        if (p.tableChoice === 'build')
          this.suggestions.push({
            text: 'While the up-front cost can be higher, investing in better quality-furniture and other living expenses will avoid headaches in the future.',
            image: '/assets/images/table.svg',
            altText: 'broken table'
          });
        if (
          [
            p.budget['groceries'] <= 300,
            p.budget['transportation'] <= 25,
            p.budget['laundry'] <= 10,
            p.budget['discretionary'] <= 50
          ].filter((budgetBoolean) => budgetBoolean).length >= 2
        )
          this.suggestions.push({
            text: 'You did a great job saving! Remember, it’s okay to treat yourself every once in a while within your means.',
            image: '/assets/images/treat.svg',
            altText: 'treat'
          });
        if (p.balance <= 0)
          this.suggestions.push({
            text: 'Budgeting can be difficult sometimes – at the end of the day, sometimes you need to consider some sacrifices to your level of comfort to make sure you’re taking care of the necessities.',
            image: '/assets/images/budgeting.svg',
            altText: 'coin with wings'
          });
        if (p.credit.payment === 0.5 || p.credit.payment === 25)
          this.suggestions.push({
            text: 'Remember, debt can sneak up on you when you pay with credit. Consider reducing the number of purchases on your credit card next time until you can be sure you’ll be able to make the full monthly payments.',
            image: '/assets/images/bank-card-lg.svg',
            altText: 'bank card'
          });
        if (p.credit.card === 'none')
          this.suggestions.push({
            text: "Keep in mind that establishing credit by using a credit card responsibly can help you in the long run. Then if you need to borrow to cover an emergency, or you want a loan for a big purchase like a car, you're more likely to be approved. Consider opening a credit card down the road and using it for purchases you can pay off right away so you can build your credit.",
            image: '/assets/images/no-card-lg.svg',
            altText: 'no card'
          });
      });
  }

  advanceSuggestion(): void {
    if (this.suggestionIndex < this.suggestions.length - 1) {
      this.suggestionIndex++;
    } else {
      this.store.dispatch(AdvanceScreen({}));
    }
  }
}

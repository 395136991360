<div
  class="img-bg main-display d-flex justify-content-center align-items-center"
>
  <ng-container [ngSwitch]="currentFlow">
    <form
      (submit)="submitLoginForm()"
      #loginForm="ngForm"
      *ngSwitchCase="signInFlow.signIn"
    >
      <p class="small-text mb-0">Student Account</p>
      <hr />
      <h1 class="wonky text-uppercase welcome-text">welcome!</h1>
      <span class="prompt fst-italic"
        >Enter your username and password to sign in.</span
      >
      <div class="input-section">
        <p
          *ngIf="displayError"
          aria-live="polite"
          class="karla instructions fst-italic text-danger"
        >
          The username or password you entered is incorrect.
        </p>
        <div class="mb-3">
          <label for="username" class="form-label text-uppercase">
            <app-alert-icon
              [condition]="usernameField.invalid && usernameField.touched"
            ></app-alert-icon>
            username</label
          >
          <input
            type="username"
            required
            [(ngModel)]="username"
            placeholder="Enter username"
            id="username"
            class="form-control fst-italic"
            name="username"
            #usernameField="ngModel"
            [ngClass]="{
              'border-danger':
                displayError ||
                (!usernameField.pristine && !usernameField.valid)
            }"
          />
        </div>
        <div class="mb-3 password-control">
          <label for="password" class="form-label text-uppercase">
            <app-alert-icon
              [condition]="passwordModel.invalid && passwordModel.touched"
            ></app-alert-icon>
            Password</label
          >
          <input
            type="password"
            placeholder="Enter password"
            minlength="6"
            required
            [(ngModel)]="password"
            id="password"
            class="form-control fst-italic"
            name="password"
            #passwordModel="ngModel"
            #passwordInput
            [ngClass]="{
              'border-danger':
                displayError ||
                (!passwordModel.pristine && !passwordModel.valid)
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              passwordInput.type =
                passwordInput.type === 'password' ? 'text' : 'password'
            "
            [ngSwitch]="passwordInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
      </div>
      <button
        type="submit"
        class="teal-btn wonky"
        [class.disabled]="!loginForm.valid || !username"
        [disabled]="!loginForm.valid || !username"
      >
        Sign in
      </button>
      <span class="karla forgot-password" tabindex="0"
        >Forgot your password?
        <span class="fst-italic">
          Contact your teacher to reset your password</span
        ></span
      >
      <span>
        Don't have an account?
        <a
          class="karla fst-italic"
          tabindex="0"
          (keydown.enter)="currentFlow = signInFlow.classCode"
          (click)="currentFlow = signInFlow.classCode"
          >Sign Up</a
        ></span
      >
    </form>
    <form
      (submit)="submitClassCodeForm()"
      #classCodeForm="ngForm"
      *ngSwitchCase="signInFlow.classCode"
    >
      <p class="small-text mb-0">Student Account</p>
      <hr />
      <h1 class="wonky text-uppercase welcome-text">create an account</h1>
      <p
        *ngIf="displayError"
        aria-live="polite"
        class="karla instructions fst-italic text-danger"
      >
        The class code you entered is incorrect.
      </p>
      <div class="input-section mt-5">
        <div class="mb-3">
          <label for="username" class="form-label text-uppercase"
            >class code</label
          >
          <input
            type="classCode"
            required
            [(ngModel)]="classCode"
            placeholder="Enter class code"
            id="classCode"
            class="form-control fst-italic"
            name="classCode"
            #classCodeField="ngModel"
            [ngClass]="{
              'border-danger':
                displayError ||
                (!classCodeField.pristine && !classCodeField.valid)
            }"
          />
        </div>
      </div>
      <button
        type="submit"
        class="teal-btn wonky"
        [class.disabled]="!classCodeForm.valid"
        [disabled]="!classCodeForm.valid || isSubmitting"
      >
        Done
      </button>
      <span>
        Have an account?
        <a
          class="karla fst-italic"
          tabindex="0"
          (keydown.enter)="currentFlow = signInFlow.signIn"
          (click)="currentFlow = signInFlow.signIn"
          >Sign In</a
        ></span
      >
    </form>
    <form
      (submit)="submitEnrollForm()"
      #enrollForm="ngForm"
      *ngSwitchCase="signInFlow.enroll"
    >
      <p class="small-text mb-0">Student Account</p>
      <hr />
      <h1 class="wonky text-uppercase welcome-text mb-5">Create an Account</h1>
      <div class="input-section">
        <div class="position-relative mb-3">
          <label for="enrollPassword" class="form-label text-uppercase"
            >student</label
          >
          <div
            role="listbox"
            [attr.aria-expanded]="showStudentDropdown"
            class="student-dropdown-toggle fw-bolder"
            (click)="toggleStudentDropdown()"
          >
            <span
              class="selected-student-text"
              [class.placeholder]="!selectedStudent"
            >
              {{ selectedStudent?.name || "Select your name" }}
            </span>
            <img
              src="/assets/icons/arrow-down.svg"
              alt="Dropdown Arrow"
              class="dropdown-arrow"
            />
          </div>
          <div
            role="listbox"
            *ngIf="showStudentDropdown"
            class="student-dropdown-menu"
          >
            <div
              role="option"
              *ngFor="let student of students; let i = index"
              [attr.aria-selected]="selectedStudent?.id === student.id"
              tabindex="0"
              (keydown.enter)="onStudentSelect(i)"
              (click)="onStudentSelect(i)"
              [ngClass]="{ active: selectedStudent?.id === student.id }"
            >
              {{ student.name }}
            </div>
          </div>
        </div>
        <div class="mb-3 password-control">
          <label for="enrollPassword" class="form-label text-uppercase">
            <app-alert-icon
              [condition]="
                enrollPasswordModel.invalid && enrollPasswordModel.touched
              "
            ></app-alert-icon>
            Password</label
          >
          <input
            type="password"
            placeholder="Create password"
            minlength="6"
            required
            [(ngModel)]="enrollPassword"
            id="enrollPassword"
            class="form-control fst-italic"
            name="enrollPassword"
            #enrollPasswordModel="ngModel"
            #enrollPasswordInput
            [ngClass]="{
              'border-danger':
                !enrollPasswordModel.pristine && !enrollPasswordModel.valid
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              enrollPasswordInput.type =
                enrollPasswordInput.type === 'password' ? 'text' : 'password'
            "
            [ngSwitch]="enrollPasswordInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
        <div class="mb-3 password-control">
          <label for="confirmEnrollPassword" class="form-label text-uppercase">
            <app-alert-icon
              [condition]="
                (confirmEnrollPasswordModel.invalid &&
                confirmEnrollPasswordModel.touched) ||
                enrollPassword !== confirmEnrollPassword
              "
            ></app-alert-icon>
            Confirm Password</label
          >
          <input
            type="password"
            placeholder="Confirm password"
            minlength="6"
            required
            [(ngModel)]="confirmEnrollPassword"
            id="confirmEnrollPassword"
            class="form-control fst-italic"
            name="confirmEnrollPassword"
            #confirmEnrollPasswordModel="ngModel"
            #confirmEnrollPasswordInput
            [ngClass]="{
              'border-danger':
                (!confirmEnrollPasswordModel.pristine &&
                  !confirmEnrollPasswordModel.valid) ||
                enrollPassword !== confirmEnrollPassword
            }"
          />
          <button
            class="visibility-toggle"
            type="button"
            (click)="
              confirmEnrollPasswordInput.type =
                confirmEnrollPasswordInput.type === 'password'
                  ? 'text'
                  : 'password'
            "
            [ngSwitch]="confirmEnrollPasswordInput.type"
          >
            <img
              *ngSwitchCase="'password'"
              src="/assets/icons/visibility_24px.svg"
              alt="show password"
            />
            <img
              *ngSwitchCase="'text'"
              src="/assets/icons/visibility_off_24px.svg"
              alt="hide password"
            />
          </button>
        </div>
        <hr />
        <label class="form-label text-uppercase">Your Unique Username</label>

        <div class="mb-3 username-box">
          {{
            selectedStudent
              ? selectedStudent.username
              : "Select your name to generate your username"
          }}
        </div>
      </div>
      <p
        *ngIf="displayError"
        aria-live="polite"
        class="karla instructions fst-italic text-danger"
      >
        Error registering student.
      </p>
      <button
        type="submit"
        class="teal-btn wonky"
        [class.disabled]="
          !enrollForm.valid || enrollPassword !== confirmEnrollPassword
        "
        [disabled]="
          !enrollForm.valid ||
          enrollPassword !== confirmEnrollPassword ||
          isSubmitting
        "
      >
        Done
      </button>
      <span
        >Have an account?
        <a
          class="karla fst-italic"
          tabindex="0"
          (keydown.enter)="currentFlow = signInFlow.signIn"
          (click)="currentFlow = signInFlow.signIn"
          >Sign In</a
        ></span
      >
    </form>
  </ng-container>
</div>

<div
  class="title-container h-100 d-flex justify-content-center align-items-center"
  [ngStyle]="{ 'background-image': ' url(' + bgImage + ')' }"
>
  <div
    *ngIf="title"
    class="title-screen p-5 bg-white d-flex flex-column justify-content-between align-items-center"
  >
    <div
      class="title-screen-headers d-flex flex-column align-items-center mb-5"
    >
      <span class="fw-bold mb-2 chapter-title">{{ chapterTitle }}</span>
      <h1 class="fw-bold wonky text-align-center">{{ title }}</h1>
    </div>
    <button
      *ngIf="!autoStart || autoStart <= 0"
      [disabled]="!buttonActive"
      class="teal-btn fs-3"
      (click)="continue.emit()"
    >
      {{ buttonActive ? "LET'S GO" : "LOADING..." }}
    </button>
  </div>
</div>

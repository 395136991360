import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private remaining!: number | undefined;
  private callback!: Function | undefined;
  private timerId!: number | undefined;
  private start!: number | undefined;
  timerActive = false;

  startTimer(callback: Function, delay: number) {
    this.timerActive = true;
    this.remaining = delay;
    this.callback = callback;
    this.resume();
  }

  pause() {
    if (!this.timerActive || !this.remaining || !this.start) {
      return;
    }
    window.clearTimeout(this.timerId);
    this.timerId = undefined;
    this.remaining -= Date.now() - this.start;
  }

  resume() {
    this.start = Date.now();

    this.timerId = window.setTimeout(() => {
      if (this.callback) {
        this.callback();
      }
      this.timerActive = false;
    }, this.remaining);
  }
}

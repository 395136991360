import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent<T> {
  @Input() dropdownItems: T[] = [];
  @Input() selectedItem!: T;
  @Input() displayFunction!: (item: T) => string;
  @Input() valueFunction!: (item: T) => string;
  @Input() disabled = false;
  @Input() width = '278px';
  @Input() formStyles = false;
  @Input() placeholder = '';
  @Output() dropdownChange = new EventEmitter<string>();

  showDropdown = false;
}

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, filter, map, mergeMap, of } from 'rxjs';
import {
  AnimationResumed,
  HideFullScreenInfo,
  HideFullScreenInfoWithAction,
  HideNarrativeChoice,
  HideToolTip,
  ShowToolTip,
  SubmitNarrativeChoice
} from '../actions/overlays.actions';
import {
  RestartCourse,
  ModifyBudget,
  UpdateBalance
} from '../actions/progress.actions';
import { ToolTipType } from '../../interfaces';

@Injectable()
export class OverlayEffects {
  balanceNotification = createEffect(() => {
    return this.actions.pipe(
      ofType(UpdateBalance),
      filter(({ skipNotification }) => !skipNotification),
      map(({ value, customMessage }) => ({
        value,
        message: customMessage
          ? customMessage.replace(
              `$${value}`,
              `<span class="fw-bold">$${Math.abs(value).toString()}</span>`
            )
          : `<span class="fw-bold value">$${Math.abs(value)}</span><br> was ${
              value > 0 ? 'added to' : 'deducted from'
            } your balance`,
        type: <ToolTipType>(customMessage ? 'medium' : 'small')
      })),
      map(({ value, message, type }) =>
        ShowToolTip({
          options: {
            text: message,
            type,
            mood: value > 0 ? 'positive' : 'negative'
          }
        })
      )
    );
  });
  budgetNotification = createEffect(() => {
    return this.actions.pipe(
      ofType(ModifyBudget),
      filter(({ skipNotification }) => !skipNotification),
      map(() =>
        ShowToolTip({
          options: {
            type: 'small',
            mood: 'neutral',
            text: 'Your budget is updated'
          }
        })
      )
    );
  });
  decayNotifications = createEffect(() => {
    return this.actions.pipe(
      ofType(ModifyBudget, UpdateBalance),
      debounceTime(7000),
      map(() => HideToolTip({ size: 'small' }))
    );
  });
  applyNarrativeChoice = createEffect(() => {
    return this.actions.pipe(
      ofType(SubmitNarrativeChoice),
      map((payload) => payload.choice.effect)
    );
  });
  hideNarrativeChoice = createEffect(() => {
    return this.actions.pipe(
      ofType(SubmitNarrativeChoice, RestartCourse),
      mergeMap(() => of(HideNarrativeChoice()))
    );
  });
  hideFullScreenInfo = createEffect(() => {
    return this.actions.pipe(
      ofType(HideFullScreenInfoWithAction, RestartCourse),
      mergeMap(() => of(HideFullScreenInfo()))
    );
  });
  hideToolTipFromRestart = createEffect(() => {
    return this.actions.pipe(
      ofType(RestartCourse),
      mergeMap(() => of(HideToolTip({ size: 'large' })))
    );
  });
  hideFullScreenInfoAction = createEffect(() => {
    return this.actions.pipe(
      ofType(HideFullScreenInfoWithAction),
      mergeMap(({ action }) => of(action))
    );
  });
  hideFullScreenInfoResumeAnimation = createEffect(() => {
    return this.actions.pipe(
      ofType(HideFullScreenInfoWithAction),
      filter(({ resumeAnimation }) => !!resumeAnimation),
      mergeMap(() => of(AnimationResumed()))
    );
  });
  constructor(
    private actions: Actions,
    private modalsService: NgbModal
  ) {}
}

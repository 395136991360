import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectToolTip } from '../ngrx/selectors/overlays.selectors';

@Directive({
  selector: '[appCoverOnDelayed]'
})
export class CoverOnDelayedDirective implements OnDestroy {
  @Output() showTutorial = new EventEmitter();
  destroyed$ = new Subject<void>();
  constructor(
    private store: Store,
    private renderer2: Renderer2,
    private target: ElementRef
  ) {
    this.store
      .select(selectToolTip('large'))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((options) => {
        if (options && options.delayed) {
          this.renderer2.addClass(this.target.nativeElement, 'covered');
        } else {
          this.renderer2.removeClass(this.target.nativeElement, 'covered');
          setTimeout(() => this.showTutorial.emit(), 500);
        }
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}

import { ClassDataDto, ClassDataDtoStudent } from '../../shared/interfaces';
import {
  RefetchOptions,
  TeacherDashboardView
} from '../teacher-dashboard.component';
import { UsersService } from 'src/app/shared/users.service';
import { ClassesService } from 'src/app/shared/classes.service';
import { TeacherViewService } from 'src/app/shared/teacher-view.service';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements AfterViewInit, OnDestroy {
  @Input() selectedClass: ClassDataDto | undefined;
  @Output() changeView = new EventEmitter<TeacherDashboardView>();
  @Output() refetchClasses = new EventEmitter<RefetchOptions>();

  teacherDashboardView = TeacherDashboardView;
  visibleMenuStudent: ClassDataDtoStudent | null = null;
  confirmingDeleteStudent: ClassDataDtoStudent | null = null;
  confirmingDeleteClass: boolean = false;
  isSubmitting = false;
  editMode: boolean = false;
  resetModalOpen = false;
  selectedStudentForReset: ClassDataDtoStudent | null = null;
  resetPasswordMethod: 'auto' | 'custom' | null = 'auto';
  passwordChanged: boolean = false;
  newPassword: string = '';
  confirmPassword: string = '';
  displayError = false;

  private clickListener!: Function;

  constructor(
    private http: HttpClient,
    private el: ElementRef,
    private renderer: Renderer2,
    private usersService: UsersService,
    private classesService: ClassesService,
    private teacherViewService: TeacherViewService
  ) {}

  ngAfterViewInit(): void {
    this.clickListener = this.renderer.listen('document', 'click', (event) => {
      const menuIcons = this.el.nativeElement.querySelectorAll('.dots-menu-icon');
      let clickedInside = false;
  
      menuIcons.forEach((icon: any) => {
        if (icon.contains(event.target)) {
          clickedInside = true;
        }
      });
  
      if (!clickedInside) {
        this.visibleMenuStudent = null;
      }
  
      if (this.confirmingDeleteClass) {
        const deleteConfirmation = this.el.nativeElement.querySelector('.delete-section');
        if (deleteConfirmation && !deleteConfirmation.contains(event.target)) {
          this.confirmingDeleteClass = false;
        }
      }
    });
  }
  

  ngOnDestroy(): void {
    this.clickListener();
  }

  async copyToClipboard(type: 'classCode' | 'password'): Promise<void> {
    let textToCopy = '';
    if (type === 'classCode') {
      textToCopy = this.selectedClass?.classCode || '';
    } else if (type === 'password') {
      textToCopy = this.newPassword;
    }
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  toggleMenu(student: ClassDataDtoStudent): void {
    this.visibleMenuStudent =
      this.visibleMenuStudent === student ? null : student;
  }
  toggleDeleteClassMenu(){
    this.confirmingDeleteClass = !this.confirmingDeleteClass;
  }

  printPassword(): void {
    this.http
      .get('assets/print-password.html', { responseType: 'text' })
      .subscribe((template) => {
        const content = template.replace('{{newPassword}}', this.newPassword);

        const printWindow = window.open('', '_blank');
        if (printWindow) {
          printWindow.document.write(content);
          printWindow.document.close();
          printWindow.print();
        }
      });
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  openResetModal(student: ClassDataDtoStudent) {
    this.selectedStudentForReset = student;
    this.resetModalOpen = true;
  }

  closeModal(): void {
    this.resetModalOpen = false;
    this.passwordChanged = false;
    this.newPassword = '';
    this.confirmPassword = '';
    this.resetPasswordMethod = 'auto';
    this.displayError = false;
    this.isSubmitting = false;
  }

  async resetPassword() {
    this.isSubmitting = true;

    if (this.selectedStudentForReset?.id) {
      if (this.resetPasswordMethod === 'auto') {
        this.newPassword = this.generatePassword();
      }

      (
        await this.usersService.updateStudentPassword(
          this.selectedStudentForReset.id,
          this.newPassword
        )
      ).subscribe({
        complete: () => {
          this.isSubmitting = false;
          this.passwordChanged = true;
        },
        error: () => {
          this.isSubmitting = false;
          this.displayError = true;
        }
      });
    }
  }

  deleteStudent(student: ClassDataDtoStudent): void {
    this.confirmingDeleteStudent = student;
    this.visibleMenuStudent = null;
  }

  async confirmStudentDelete(student: ClassDataDtoStudent) {
    if (this.confirmingDeleteStudent) {
      this.isSubmitting = true;

      (await this.usersService.deleteStudent(student.id)).subscribe({
        complete: () => {
          this.refetchClasses.emit({
            classCode: this.selectedClass?.classCode
          });
          this.isSubmitting = false;
          this.confirmingDeleteStudent = null;
        },
        error: () => {
          this.isSubmitting = false;
        }
      });
    }
  }

  cancelDelete() {
    this.confirmingDeleteStudent = null;
  }

  generatePassword() {
    return Math.random().toString(36).slice(2).toUpperCase();
  }

  showConfirmationPopup() {
    this.confirmingDeleteClass = true;
  }
  cancelDeleteClass(){
    this.confirmingDeleteClass = false;
  }
  async confirmClassDelete() {
    if (this.selectedClass && this.confirmingDeleteClass) {
      this.isSubmitting = true;
      (await this.classesService.deleteClass(this.selectedClass.classCode)).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.confirmingDeleteClass = false; 
          this.refetchClasses.emit();
          this.teacherViewService.changeView(TeacherDashboardView.home)
        },
        error: (err) => {
          this.isSubmitting = false;
          console.log(err);
        }
      });
    }
  }
}
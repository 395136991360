import { Request, Response } from 'express';
import { Send } from 'express-serve-static-core';

export const NUM_OF_SHARDS = 10;

export enum AppRole {
  TEACHER = 'teacher',
  STUDENT = 'student',
  ADMIN = 'admin'
}

export interface TypedRequestBody<T> extends Request {
  body: T;
}

export interface TypedResponse<T> extends Response {
  send: Send<T, this>;
}

export function setOriginHeader(req: Request, res: Response) {
  const WHITELISTED_ORIGINS = [
    'http://localhost:4201',
    'https://dev.dollarsdecisions.com',
    'https://dollarsdecisions.com',
    'http://127.0.0.1:4201'
  ];

  const origin = WHITELISTED_ORIGINS.find(
    (origin) => origin === req.header('Origin')
  );
  res.setHeader(
    'Access-Control-Allow-Origin',
    origin || WHITELISTED_ORIGINS[2]
  );
}

export function createNewData() {
  return {
    classroomsRegistered: 0,
    teacherDashboardSessions: 0,
    studentParticipants: {
      withClassCode: 0,
      withoutClassCode: 0
    },
    studentsBySchoolDistrict: {},
    moduleCompletion: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0
    },
    preAssessmentTotal: 0,
    preAssessmentNumCorrect: {},
    preAssessmentQuestionsCorrect: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0
    },
    postAssessmentTotal: 0,
    postAssessmentNumCorrect: {},
    postAssessmentQuestionsCorrect: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0
    },
    browser: {},
    courseFinished: 0,
    courseStarted: 0,
    device: {},
    location: {},
    pageviews: 0
  };
}

export function mergeObjects(obj1: any, obj2: any) {
  const result: any = {};

  [obj1, obj2].forEach((obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (result[key]) {
        result[key] += value;
      } else {
        result[key] = value;
      }
    }
  });
  return result;
}

// MAKE SURE YOU UPDATE THIS IN THE FRONT-END CODE IF YOU DO IT HERE.
// TODO: FIGURE OUT WHY IMPORTS DON'T WORK FROM OUTSIDE OF THE FUNCTIONS FOLDER.
export interface SchoolDistrict {
  districtName: string;
  state: string;
  city: string;
  numStudents: number;
}

export type AnalyticsDoc = {
  classroomsRegistered: number;
  teacherDashboardSessions: number;
  studentParticipants: { withClassCode: number; withoutClassCode: number };
  studentsBySchoolDistrict: { [key: string]: SchoolDistrict };
  moduleCompletion: { [module: string]: number };
  preAssessmentTotal: number;
  preAssessmentNumCorrect: { [numCorrect: number]: number };
  preAssessmentQuestionsCorrect: { [question: number]: number };
  postAssessmentTotal: number;
  postAssessmentNumCorrect: { [numCorrect: number]: number };
  postAssessmentQuestionsCorrect: { [question: number]: number };
  browser: { [browser: string]: number };
  courseFinished: number;
  courseStarted: number;
  device: { [device: string]: number };
  location: { [location: string]: number };
  pageviews: number;
  timestamp?: string;
};

type AssessmentStats = {
  numCorrect: number;
  assessmentResults: {
    [q: number]: {
      answer: any;
      correct: boolean;
    };
  };
};

export type ClassStudent = {
  name: string;
  gameCompleted?: boolean;
  username?: string;
  progress?: any;
  registered?: boolean;
  preAssessmentStats?: AssessmentStats;
  postAssessmentStats?: AssessmentStats;
};

export type TeacherClass = {
  className: string;
  created: number;
  students: { [id: string]: ClassStudent };
};

export interface ClassDataDto {
  classCode: string;
  className: string;
  students: {
    name: string;
    username?: string;
    id: string;
    registered: boolean;
  }[];
  studentLogins: {
    loggedIn: string[];
    notLoggedIn: string[];
  };
  assessmentResults: {
    preGameAverage: number | null;
    postGameAverage: number | null;
    byStudent: { name: string; pre: number; post?: number }[];
    byQuestion: {
      pre: {
        [qNumber: string]: { name: string; answer: string; correct: boolean }[];
      };
      post: {
        [qNumber: string]: { name: string; answer: string; correct: boolean }[];
      };
    };
    mostMissed: {
      pre: { qNum: string; percentCorrect: number }[];
      post: { qNum: string; percentCorrect: number }[];
    };
  };
  courseCompletion: {
    completed: string[];
    inProgress: string[];
    notStarted: string[];
    byStudent: {
      name: string;
      percentageComplete: number;
      currentModule: string;
    }[];
  };
}

<div
  [ngClass]="{ opaque: !showCourseIntro && !showCharacterChoiceIntro }"
  class="component-container img-bg h-100 w-100 d-flex justify-content-center align-items-center"
>
  <div
    *ngIf="type === 'character' && showCourseIntro"
    class="intro-window d-flex flex-column justify-content-between align-items-center"
  >
    <h1 class="wonky">Welcome to dollars & decisions!</h1>
    <p class="fw-bold">
      In this game, you’ll choose a character and a job that comes with an
      income. You’ll dive in from there, making financial decisions in a series
      of scenarios you might face in real life.
    </p>
    <p class="fw-bold">
      Throughout the game, you’ll learn practical tips for budgeting, spending,
      saving, and investing to help build your confidence for the real thing. Be
      sure to spend and save wisely—you don’t want to run out of money! When the
      game is over, you’ll receive helpful feedback based on how you played.
    </p>
    <button
      (click)="showCourseIntro = false; showCharacterChoiceIntro = true"
      class="teal-btn fs-2"
    >
      Get started
    </button>
  </div>

  <div
    *ngIf="type === 'character' && showCharacterChoiceIntro"
    class="intro-window character d-flex flex-column justify-content-between align-items-center"
  >
    <p class="mt-5 fw-bold">
      You've worked hard to prepare for real life, learning all you can and
      dreaming of a bright future. Now you're ready to start your life as an
      adult! You bought a train ticket to the city and you have $1,000 saved up
      in your checking account.
    </p>
    <button (click)="showCharacterChoiceIntro = false" class="teal-btn fs-2">
      Pick Your Character
    </button>
  </div>

  <div
    *ngIf="!selectionFinished && !showCourseIntro && !showCharacterChoiceIntro"
    class="select-container"
    [ngClass]="{ job: type === 'job' }"
  >
    <div class="h-100 w-100 d-flex justify-content-start align-items-center">
      <div
        class="options d-flex flex-column justify-content-between align-items-center"
        role="listbox"
        [attr.aria-label]="type + ' options'"
      >
        <ng-template [ngIf]="type === 'character'">
          <div
            class="option d-flex flex-column align-items-center cursor-pointer"
            *ngFor="let character of characters; let i = index"
            [attr.aria-label]="'character ' + character.name + ' option'"
            (click)="handleImageClick(i)"
            (keydown.enter)="handleImageClick(i)"
            role="option"
            [attr.aria-selected]="currentSelection === i"
            tabindex="0"
          >
            <img
              class="character"
              [src]="'/assets/images/' + character.name + '-avatar-yellow.svg'"
              [ngClass]="currentSelection === i ? 'circle selected' : 'circle'"
              alt=""
            />
          </div>
        </ng-template>
        <ng-template [ngIf]="type === 'job'">
          <div
            class="option job d-flex justify-content-center align-items-center cursor-pointer"
            *ngFor="let job of jobs; let i = index"
            [ngClass]="{ selected: currentSelection === i }"
            (click)="handleImageClick(i)"
            (keydown.enter)="handleImageClick(i)"
            role="option"
            [attr.aria-label]="'job ' + job.name + ' option'"
            tabindex="0"
            [attr.aria-selected]="currentSelection === i"
          >
            <img
              class="character"
              [src]="'/assets/images/' + job.key + '-avatar.svg'"
              [ngClass]="currentSelection === i ? 'circle selected' : 'circle'"
              alt=""
            />
          </div>
        </ng-template>
      </div>
      <div
        class="arrow-container left cursor-pointer"
        (click)="handleCharacterCycle(-1)"
        (keydown.enter)="handleCharacterCycle(-1)"
        tabindex="0"
        role="button"
        aria-label="cycle characters left"
      >
        <img
          class="arrow-icon"
          src="assets/icons/arrow-left.svg"
          alt="previous option"
        />
      </div>
      <div
        class="arrow-container right cursor-pointer order-last"
        (click)="handleCharacterCycle(1)"
        (keydown.enter)="handleCharacterCycle(1)"
        tabindex="0"
        role="button"
        aria-label="cycle characters right"
      >
        <img
          class="arrow-icon"
          src="assets/icons/arrow-right.svg"
          alt="next option"
        />
      </div>
      <div
        class="info-display h-100 d-flex flex-column justify-content-between align-items-center"
      >
        <h1>
          {{
            type === "character"
              ? "Choose a Character"
              : "Which Job Do You Want?"
          }}
        </h1>
        <img
          [alt]="characters[currentSelection].name"
          *ngIf="type === 'character'"
          [src]="'/assets/images/' + characters[currentSelection].name + '.svg'"
          class="full-image"
        />
        <img
          [alt]="jobs[currentSelection].key"
          *ngIf="type === 'job'"
          [src]="'/assets/images/' + jobs[currentSelection].key + '.svg'"
          class="info-image"
        />
        <div
          *ngIf="type === 'character'"
          class="name-details d-flex w-auto justify-content-center align-items-center"
        >
          <span class="mb-0 wonky name">{{
            characters[currentSelection].name
          }}</span>
        </div>
        <div
          *ngIf="type === 'job'"
          class="name-details d-flex flex-column align-items-start w-100"
        >
          <span class="mb-0 wonky job-name">{{
            jobs[currentSelection].name
          }}</span>
          <span class="fs-7 fst-italic salary">
            <span class="fw-bold">{{
              jobs[currentSelection].salaryText.split(" ")[0]
            }}</span>
            {{
              jobs[currentSelection].salaryText.split(" ").slice(1).join(" ")
            }}
          </span>
          <p>{{ jobs[currentSelection].description }}</p>
        </div>
        <button
          type="button"
          class="teal-btn fs-2"
          (click)="handleOptionSelect()"
        >
          SELECT
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="selectionFinished"
    class="select-container finish d-flex flex-column align-items-center justify-content-between py-5"
  >
    <img
      class="full-image"
      [alt]="characters[currentSelection].name"
      *ngIf="type === 'character'"
      [src]="'/assets/images/' + characters[currentSelection].name + '.svg'"
    />
    <img
      class="full-image"
      [alt]="jobs[currentSelection].key"
      *ngIf="type === 'job'"
      [src]="'/assets/images/' + jobs[currentSelection].key + '.svg'"
      class="info-image-confirm"
    />
    <span class="text-center fw-bold">{{
      type === "character"
        ? "Great choice! First step now that you've arrived is finding a job."
        : "Oh, you're going to love that one. With your job secured, it's time to meet your roommates."
    }}</span>
    <button class="teal-btn fs-2" (click)="handleFinish()">Let's Go!</button>
  </div>
</div>

import { Screen } from '../interfaces';
import {
  ShowNarrativeChoice,
  PlaySound,
  DelayAnimation
} from '../ngrx/actions/overlays.actions';
import { AdvanceScreen } from '../ngrx/actions/progress.actions';
import { selectCharacter } from '../ngrx/selectors/progress.selectors';
import { firstValueFrom } from 'rxjs';
export const roommateIntroScreen: Screen = {
  component: 'game',
  module: {
    title: {
      text: 'Meet your roommates',
      titleBackgroundImage: '/assets/images/bg.png'
    },
    animation: async (store, assetManager) => {
      const character = await firstValueFrom(store.select(selectCharacter));
      const sounds = [
        `/assets/sounds/scene2/S2L1${character.soundAlias}.mp3`,
        '/assets/sounds/scene2/S2L2.mp3',
        `/assets/sounds/scene2/S2L3${character.soundAlias}.mp3`
      ];
      await assetManager.loadAssets(sounds);
      return {
        layerPaths: ['/assets/lottie/scene2.json'],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...PlaySound({
              src: `/assets/sounds/scene2/S2L1${character.soundAlias}.mp3`
            }),
            segment: [0, 0],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene2/S2L2.mp3` }),
            segment: [0, 95],
            pause: false
          },
          {
            ...ShowNarrativeChoice({ key: 'groceries' }),
            segment: [95, 1170],
            pause: true
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene2/S2L3${character.soundAlias}.mp3`
            }),
            segment: [1170, 1170],
            pause: false
          },
          {
            ...DelayAnimation({ delayTime: 1000 }),
            segment: [1170, 1170],
            pause: true
          },
          {
            ...AdvanceScreen({}),
            segment: [1170, 1310],
            pause: false
          }
        ]
      };
    },
    infoScreens: {
      success: {
        header: 'Nice job!',
        text: 'You’ve got the basics of creating a month-to-month budget. Now to start looking towards the future…',
        buttonText: "Let's Go!",
        action: AdvanceScreen({})
      }
    }
  }
};

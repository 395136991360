<div
  class="display-panel d-flex flex-column justify-content-end align-items-center"
>
  <app-tooltip
    *ngIf="showToolTip | async as options"
    [options]="options"
    class="mb-4 align-self-end"
    aria-live="polite"
  >
  </app-tooltip>
  <img
    class="mb-3"
    [src]="'/assets/images/' + (character | async).name + '-avatar-teal.svg'"
    alt="avatar"
  />
  <div class="balance">
    <span class="box-header text-center">Account Balance</span>
    <span class="box-balance fw-bold wonky"
      ><span class="me-1">$</span>{{ balance | async | number: "1.0-0" }}</span
    >
  </div>
  <div *ngIf="showSavings | async" class="savings">
    <span class="box-header">My Savings</span>
    <span class="fs-4 fw-bold wonky"
      ><span class="me-1">$</span>{{ savings | async | number: "1.0-0" }}</span
    >
  </div>
  <img
    *ngIf="showBudgetButton | async"
    role="button"
    alt="launch budget"
    src="/assets/images/budget-button.svg"
    (click)="openBudget()"
    (keydown.enter)="openBudget()"
    class="budget cursor-pointer"
    tabindex="0"
  />
  <div
    class="controls w-100 mb-4 px-2 py-2 d-flex justify-content-between align-items-center"
  >
    <div
      class="control-button"
      tabindex="0"
      (keydown.enter)="togglePause()"
      (click)="togglePause()"
      role="button"
      [attr.aria-label]="buttonDisplay === 'pause' ? 'pause' : 'play'"
    >
      <img
        aria-hidden="true"
        *ngIf="buttonDisplay === 'pause'"
        class="mb-1 pause-icon"
        src="/assets/icons/pause.svg"
        alt="pause"
      />
      <img
        aria-hidden="true"
        *ngIf="buttonDisplay === 'play'"
        class="mb-1 play-icon"
        src="/assets/icons/play.svg"
        alt="pause"
      />
    </div>
    <div
      #restartButton
      class="control-button"
      (click)="restartChapter(); restartButton.blur()"
      (keydown.enter)="restartChapter(); restartButton.blur()"
      tabindex="0"
      role="button"
      aria-label="skip back"
    >
      <img
        aria-hidden="true"
        class="mb-1 arrow-icon"
        src="/assets/icons/skip-back.svg"
        alt="skip back"
      />
    </div>
  </div>
</div>

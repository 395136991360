import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { LottieModule } from 'ngx-lottie';
import { AppRoutingModule } from './app-routing.module';
import player from 'lottie-web';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { ProfileSelectComponent } from './profile-select/profile-select.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AssessmentComponent } from './assessment/assessment.component';
import { StatusPanelComponent } from './status-panel/status-panel.component';
import { ProgressService } from './shared/progress.service';
import { NarrativeChoiceComponent } from './shared/narrative-choice/narrative-choice.component';
import { GameComponent } from './game/game.component';
import { FormsModule } from '@angular/forms';
import { AnimationComponent } from './animation/animation.component';
import { TextboxComponent } from './shared/textbox/textbox.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './shared/ngrx/reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { ModalEffects } from './shared/ngrx/effects/modals.effect';
import { ProgressEffects } from './shared/ngrx/effects/progress.effect';
import { SafePipe } from './safe.pipe';
import { ToolTipComponent } from './shared/tooltip/tooltip.component';
import { OverlayEffects } from './shared/ngrx/effects/overlays.effect';
import { TitleScreenComponent } from './shared/title-screen/title-screen.component';
import { BudgetModalComponent } from './budget-modal/budget-modal.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SavingsModalComponent } from './savings-modal/savings-modal.component';
import { CreditCardModalComponent } from './credit-card-modal/credit-card-modal.component';
import { FullScreenInfoComponent } from './full-screen-info/full-screen-info.component';
import { ReactiveComponentModule } from '@ngrx/component';
import { TooltipBubbleComponent } from './shared/tooltip-bubble/tooltip-bubble.component';
import { FinalSummaryComponent } from './final-summary/final-summary.component';
import { RetirementModalComponent } from './retirement-modal/retirement-modal.component';
import { HeaderComponent } from './shared/header/header.component';
import { CoverOnDelayedDirective } from './shared/tooltip/cover-on-delayed.directive';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { CookieService } from 'ngx-cookie-service';
import {
  AngularFireAuth,
  AngularFireAuthModule,
  USE_EMULATOR as USE_AUTH_EMULATOR
} from '@angular/fire/compat/auth';
import {
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR
} from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { SignInComponent } from './sign-in/sign-in.component';
import { ReportsComponent } from './reports/reports.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { SortStatesPipe } from './reports/state-map/sort-states.pipe';
import { CircleGraphComponent } from './shared/circle-graph/circle-graph.component';
import { BarChartComponent } from './shared/bar-chart/bar-chart.component';
import { FooterComponent } from './shared/footer/footer.component';
import { StateMapComponent } from './reports/state-map/state-map.component';
import { TutorialTipDirective } from './shared/tutorial/tutorial-tip.directive';
import { TutorialComponent } from './shared/tutorial/tutorial.component';
import { SortingDropdownComponent } from './shared/sorting-dropdown/sorting-dropdown.component';
import { HorizontalBarChartComponent } from './shared/horizontal-bar-chart/horizontal-bar-chart.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UsersService } from './shared/users.service';
import { TeacherLoginComponent } from './teacher-login/teacher-login.component';
import { StudentLoginComponent } from './student-login/student-login.component';
import { AppRole } from './shared/interfaces';
import { CreateClassComponent } from './teacher-dashboard/create-class/create-class.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TeacherDashboardHomeComponent } from './teacher-dashboard/teacher-dashboard-home/teacher-dashboard-home.component';
import { ClassesService } from './shared/classes.service';
import { StudentsComponent } from './teacher-dashboard/students/students.component';
import { TeacherRegistrationFormComponent } from './teacher-login/teacher-registration-form/teacher-registration-form.component';
import { CourseCompletionComponent } from './course-completion/course-completion.component';
import { DropdownComponent } from './shared/dropdown/dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AssessmentResultsComponent } from './teacher-dashboard/assessment-results/assessment-results.component';
import { MatOptionHeightDirective } from './shared/material.directive';
import { AlertIconComponent } from './shared/alert-icon/alert-icon.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ProfileSelectComponent,
    AssessmentComponent,
    StatusPanelComponent,
    NarrativeChoiceComponent,
    GameComponent,
    AnimationComponent,
    SafePipe,
    TextboxComponent,
    ToolTipComponent,
    TitleScreenComponent,
    SavingsModalComponent,
    BudgetModalComponent,
    CreditCardModalComponent,
    FullScreenInfoComponent,
    TooltipBubbleComponent,
    FinalSummaryComponent,
    RetirementModalComponent,
    HeaderComponent,
    CoverOnDelayedDirective,
    MatOptionHeightDirective,
    SignInComponent,
    ReportsComponent,
    TeacherDashboardComponent,
    TeacherDashboardHomeComponent,
    SortStatesPipe,
    CircleGraphComponent,
    BarChartComponent,
    FooterComponent,
    StateMapComponent,
    TutorialTipDirective,
    TutorialComponent,
    SortingDropdownComponent,
    HorizontalBarChartComponent,
    LandingPageComponent,
    TeacherLoginComponent,
    StudentLoginComponent,
    CreateClassComponent,
    StudentsComponent,
    TeacherRegistrationFormComponent,
    CourseCompletionComponent,
    DropdownComponent,
    AssessmentResultsComponent,
    AlertIconComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    LottieModule.forRoot({ player: playerFactory }),
    FormsModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([
      AppEffects,
      ModalEffects,
      OverlayEffects,
      ProgressEffects
    ]),
    NgxSliderModule,
    ReactiveComponentModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    ReactiveFormsModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [AngularFireAuth, ProgressService],
      useFactory: (auth: AngularFireAuth, progressService: ProgressService) => {
        return async () => {
          await new Promise<void>((resolve) => {
            auth.onAuthStateChanged(async (user) => {
              if (user) {
                const idTokenResult = await user.getIdTokenResult();
                const role = idTokenResult.claims['appRole'];
                if (role === AppRole.STUDENT) {
                  await progressService.restoreProgress();
                  resolve();
                } else {
                  resolve();
                }
              } else {
                resolve();
              }
            });
          });
        };
      },
      multi: true
    },
    ProgressService,
    CookieService,
    UsersService,
    ClassesService,
    {
      provide: USE_AUTH_EMULATOR,
      useValue:
        environment.useEmulators === 'true'
          ? ['http://localhost:9099']
          : undefined
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue:
        environment.useEmulators === 'true' ? ['localhost', 9098] : undefined
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue:
        environment.useEmulators === 'true' ? ['localhost', 5001] : undefined
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

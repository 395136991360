import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { AssetManagerService } from './asset-manager.service';

export enum AnalyticsType {
  PAGEVIEW = 'pageview',
  COURSE_STARTED = 'courseStarted',
  COURSE_FINISHED = 'courseFinished',
  PRE_ASSESSMENT = 'preAssessment',
  MODULE_COMPLETED = 'moduleCompleted',
  TEACHER_DASHBOARD_SESSION = 'teacherDashboardSession'
}

export enum AppRole {
  TEACHER = 'teacher',
  STUDENT = 'student',
  ADMIN = 'admin'
}

export enum TeacherRole {
  CLASSROOM_TEACHER = 'Classroom Teacher',
  SUPPORT_STAFF = 'Support Staff',
  INSTRUCTIONAL_COACH = 'Instructional Coach',
  ADMINISTRATOR = 'Administrator',
  OTHER = 'Other'
}

export interface ProfileSelection {
  type: string;
  selection: string;
}

export interface Question {
  query: string;
  explanation?: string;
}

export interface MultiChoiceQuestion extends Question {
  choices: Array<string>;
  correctChoice?: number;
}

export interface BinaryQuestion extends Question {
  correctChoice?: boolean;
}

export type NarrativeChoiceType =
  | 'budget'
  | 'balance'
  | 'plaintext'
  | 'generic'
  | 'retirement';
export type AsyncNarrativeChoiceOption = (
  store: Store
) => Promise<NarrativeChoiceOption>;
export interface NarrativeChoiceOption {
  type: NarrativeChoiceType;
  text: { content: string; classOverride?: string }[];
  value?: number;
  unit?: string;
  image?: string;
  effect: TypedAction<string>;
}

export interface NarrativeChoice {
  header?: string;
  choices: (NarrativeChoiceOption | AsyncNarrativeChoiceOption)[];
}

export type SectionType = 'assessment' | 'profile-select' | 'game' | 'summary';
export interface Screen {
  component: SectionType;
  module?: GameModule;
  key?: string;
}
export interface GameModule {
  title: {
    autoStart?: number;
    chapter?: string;
    text?: string;
    titleBackgroundImage: string | ((key: string) => string);
  };
  animation: AnimationData | AnimationAsyncData;
  infoScreens: {
    [key: string]: InfoScreen;
  };
}
export type AnimationAsyncData = (
  store: Store,
  assetManager: AssetManagerService
) => Promise<AnimationData>;
export interface AnimationData {
  layerPaths: string[];
  events: (AnimationEvent | AnimationAsyncEvent)[];
  dispose(): void;
}
export type AnimationAsyncEvent = () => Promise<AnimationEvent>;
export interface AnimationEvent {
  type: string;
  segment: [number, number];
  pause: boolean;
  noAudio?: boolean;
}
export interface InfoScreen {
  header: string;
  text: string;
  buttonText: string;
  action: { type: string };
  resumeAnimation?: boolean;
  extraHtml?: string;
  useCoinImage?: true;
  textCenter?: boolean;
}

export type ToolTip = {
  type: ToolTipType;
  mood: ToolTipMood;
  text: string;
  avatar?: string;
  delayed?: boolean;
};

export interface SchoolDistrict {
  districtName: string;
  state: string;
  city: string;
  numStudents: number;
}

export interface AssessmentStats {
  numCorrect: number;
  assessmentResults: {
    [q: number]: {
      answer: any;
      correct: number;
    };
  };
}

export type ClassDataDtoStudent = {
  name: string;
  username: string;
  id: string;
  registered: boolean;
};

export interface ClassDataDto {
  classCode: string;
  className: string;
  students: ClassDataDtoStudent[];
  studentLogins: {
    loggedIn: string[];
    notLoggedIn: string[];
  };
  assessmentResults: {
    preGameAverage: number | null;
    postGameAverage: number | null;
    byStudent: { name: string; pre: number; post?: number }[];
    byQuestion: {
      pre: {
        [qNumber: string]: { name: string; answer: string; correct: boolean }[];
      };
      post: {
        [qNumber: string]: { name: string; answer: string; correct: boolean }[];
      };
    };
    mostMissed: {
      pre: { qNum: string; percentCorrect: number }[];
      post: { qNum: string; percentCorrect: number }[];
    };
  };
  courseCompletion: {
    completed: string[];
    inProgress: string[];
    notStarted: string[];
    byStudent: {
      name: string;
      percentageComplete: number;
      currentModule: string;
    }[];
  };
}

export interface ClassCodeStudent {
  id: string;
  name: string;
  username: string;
}

// MAKE SURE YOU UPDATE THIS IN THE FUNCTIONS CODE IF YOU DO IT HERE.
// TODO: FIGURE OUT WHY IMPORTS DON'T WORK FROM OUTSIDE OF THE FUNCTIONS FOLDER.

export type AnalyticsDoc = {
  classroomsRegistered: number;
  teacherDashboardSessions: number;
  studentParticipants: { withClassCode: number; withoutClassCode: number };
  studentsBySchoolDistrict: { [key: string]: SchoolDistrict };
  moduleCompletion: { [module: string]: number };
  preAssessmentTotal: number;
  preAssessmentNumCorrect: { [numCorrect: number]: number };
  preAssessmentQuestionsCorrect: { [question: number]: number };
  postAssessmentTotal: number;
  postAssessmentNumCorrect: { [numCorrect: number]: number };
  postAssessmentQuestionsCorrect: { [question: number]: number };
  browser: { [browser: string]: number };
  courseFinished: number;
  courseStarted: number;
  device: { [device: string]: number };
  location: { [location: string]: number };
  pageviews: number;
  timestamp?: string;
};

export type Subtitle = {
  id: string;
  startTime: string;
  startSeconds: number;
  endTime: string;
  endSeconds: number;
  text: string;
};

export const TOOLTIPDELAY = 1000;
export type ToolTipType = 'large' | 'medium' | 'small';
export type ToolTipMood = 'positive' | 'neutral' | 'negative';

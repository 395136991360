import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AnalyticsDoc, SchoolDistrict } from '../shared/interfaces';
import { AnalyticsService } from '../shared/analytics.service';
import { Subscription } from 'rxjs';
import { questions } from '../shared/questions';
import { Store } from '@ngrx/store';
import { CheckReportsScroll } from '../shared/ngrx/actions/overlays.actions';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  analytics!: AnalyticsDoc;
  analyticsSub!: Subscription;
  preAssessmentAverage!: number;
  postAssessmentAverage!: number;
  modulesArray!: { name: string; students: number }[];
  moduleData: { [key: string]: number } = {};
  questionView = 'pre';
  readonly questions = questions;
  destroyed$ = new Subject<void>();
  searchTerm: string = '';
  sortOption: string = 'AtoZ';
  columnName: string = 'districtName';

  viewMode: 'visual' | 'list' = 'visual';

  moduleNames: { [key: string]: string } = {
    1: 'Module 1: Starting Your Job and Taxes',
    2: 'Module 2: Budgeting',
    3: 'Module 3: Banking Basics',
    4: 'Module 4: Saving for Goals and Retirement Planning',
    5: 'Module 5: Credit'
  };

  constructor(
    private analyticsService: AnalyticsService,
    private store: Store
  ) {}

  filteredSchoolData: SchoolDistrict[] = [];

  handleSort(columnName: string, sortOption: string) {
    this.columnName = columnName;
    this.sortOption = sortOption;
    this.sortAndSearch();
  }

  onSearch(event: any) {
    this.searchTerm = event.target.value.toLowerCase();
    this.sortAndSearch();
  }

  sortAndSearch() {
    this.filteredSchoolData = Object.values(
      this.analytics.studentsBySchoolDistrict
    ).filter((district) => {
      return (
        district.districtName.toLowerCase().startsWith(this.searchTerm) ||
        district.state.toLowerCase().startsWith(this.searchTerm) ||
        district.city.toLowerCase().startsWith(this.searchTerm)
      );
    });

    if (['AtoZ', 'ZtoA'].includes(this.sortOption)) {
      this.filteredSchoolData = (this.filteredSchoolData as any[]).sort(
        (a: any, b: any) =>
          this.sortOption === 'AtoZ'
            ? (a[this.columnName] as string).localeCompare(
                b[this.columnName] as string
              )
            : (b[this.columnName] as string).localeCompare(
                a[this.columnName] as string
              )
      );
    } else {
      this.filteredSchoolData = (this.filteredSchoolData as any[]).sort(
        (a: any, b: any) =>
          this.sortOption === 'ASC'
            ? (a[this.columnName] as number) - (b[this.columnName] as number)
            : (b[this.columnName] as number) - (a[this.columnName] as number)
      );
    }
  }

  async ngOnInit() {
    this.analyticsService.fetchAnalytics().subscribe((a: any) => {
      this.analytics = a;

      this.preAssessmentAverage =
        Object.keys(this.analytics.preAssessmentNumCorrect).reduce(
          (a: number, b: string) =>
            a + this.analytics.preAssessmentNumCorrect[<any>b] * parseInt(b),
          0
        ) / this.analytics.preAssessmentTotal;

      this.postAssessmentAverage =
        Object.keys(this.analytics.postAssessmentNumCorrect).reduce(
          (a: number, b: string) =>
            a + this.analytics.postAssessmentNumCorrect[<any>b] * parseInt(b),
          0
        ) / this.analytics.postAssessmentTotal;

      this.modulesArray = Object.keys(this.analytics.moduleCompletion).map(
        (key) => ({
          name: this.moduleNames[key],
          students: this.analytics.moduleCompletion[key]
        })
      );

      this.moduleData = Object.keys(this.analytics.moduleCompletion).reduce(
        (newObj: { [key: string]: number }, key) => {
          newObj[this.moduleNames[key]] = this.analytics.moduleCompletion[key];
          return newObj;
        },
        {}
      );

      this.filteredSchoolData = [
        ...Object.values(this.analytics.studentsBySchoolDistrict)
      ].sort((a, b) => (a.districtName > b.districtName ? 1 : -1));
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  preAssessmentQuestionCorrectPercentage(i: number) {
    const percentage =
      (this.analytics?.preAssessmentQuestionsCorrect[i + 1] /
        this.analytics?.preAssessmentTotal) *
        100 || 0;
    return percentage % 1 === 0 ? percentage : Number(percentage.toFixed(1));
  }

  postAssessmentQuestionCorrectPercentage(i: number) {
    const percentage =
      (this.analytics?.postAssessmentQuestionsCorrect[i + 1] /
        this.analytics?.postAssessmentTotal) *
        100 || 0;
    return percentage % 1 === 0 ? percentage : Number(percentage.toFixed(1));
  }

  scroll(id: string) {
    const el = document.getElementById(id);
    (el as HTMLElement).scrollIntoView();
  }

  checkReportsScroll() {
    this.store.dispatch(CheckReportsScroll());
  }

  skipToMain() {
    const main = document.getElementsByClassName('bubble-image')[0];
    (main as HTMLElement)!.focus();
  }
}

import {
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ModifyCredit } from '../shared/ngrx/actions/progress.actions';
import { ModalClosed } from '../shared/ngrx/actions/modals/modals.actions';
import { TranslateFunction } from '@angular-slider/ngx-slider';

import {
  selectBudget,
  selectCredit
} from '../shared/ngrx/selectors/progress.selectors';

@Component({
  selector: 'app-credit-card-modal',
  templateUrl: './credit-card-modal.component.html',
  styleUrls: ['./credit-card-modal.component.scss']
})
export class CreditCardModalComponent implements OnInit {
  @HostBinding('role') role = 'main';
  cards: { [key: string]: any } = {
    bank: {
      key: 'bank',
      description:
        '$0 annual fee, APR of 18%, $1,000 credit limit, 2% cash back rewards on all purchases',
      limit: 1000,
      apr: 0.18,
      rewards: 0.02,
      name: 'Bank Basic Cash-Back Card'
    },
    store: {
      key: 'store',
      description:
        '$0 annual fee, APR of 23%, $700 credit limit, 5% discount on purchases at this store',
      limit: 700,
      apr: 0.23,
      rewards: 0,
      name: 'Big-Box Store Card'
    }
  };
  balance = 0;
  credit: any = {};
  budget = this.store.select(selectBudget);
  calculateMethod = 'payment';
  showCalculations = false;
  monthsToPayoff!: number;
  totalInterest!: number;
  rewardsEarned!: number;
  monthlyPayment!: number;
  translate: TranslateFunction = (value) => `$${value.toLocaleString('en')}`;
  @ViewChild('scroll') private scrollContainer!: ElementRef;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectCredit)
      .pipe(take(1))
      .subscribe((c) => {
        this.credit = { ...c };
      });
  }

  handleCardSelect(e: Event): void {
    this.credit.card = (<HTMLSelectElement>e.target).value;
  }

  saveCredit() {
    this.credit.monthlyPayment = this.monthlyPayment;
    this.store.dispatch(
      ModalClosed({
        result: ModifyCredit({ credit: this.credit }),
        resumeAnimation: true
      })
    );
  }

  calculate(): void {
    if (this.credit.payment === 25 && this.calculateMethod === 'payment') {
      this.totalInterest =
        this.balance <= 25 ? 0 : this.calculateMinimumPaymentInterest();
      this.monthsToPayoff = Math.ceil((this.totalInterest + this.balance) / 25);
    } else {
      this.monthsToPayoff =
        this.calculateMethod === 'months'
          ? this.credit.months
          : 100 / (this.credit.payment * 100);
      this.totalInterest = this.calculateInterest(this.monthsToPayoff - 1);
    }

    this.monthlyPayment =
      this.credit.payment === 25 && this.calculateMethod === 'payment'
        ? this.balance >= 25
          ? 25
          : this.balance
        : (this.balance + this.totalInterest) / this.monthsToPayoff;
    this.rewardsEarned = this.cards[this.credit.card]['rewards'] * this.balance;
    this.scrollModal(true);
  }

  calculateInterest(months: number): number {
    const monthlyRate = this.cards[this.credit.card]['apr'] / 12;
    const averageBalance = this.balance / 2;

    return monthlyRate * averageBalance * months;
  }

  calculateMinimumPaymentInterest(): number {
    let months = 0;
    let startingBalance = this.balance;
    const monthlyRate = this.cards[this.credit.card]['apr'] / 12;
    const averageBalance = this.balance / 2;

    while (startingBalance > 0) {
      startingBalance -= 25;
      months += 1;
      startingBalance += startingBalance > 0 ? monthlyRate * averageBalance : 0;
    }

    return monthlyRate * averageBalance * months;
  }

  scrollModal(scrollToBottom: boolean) {
    setTimeout(
      () =>
        this.scrollContainer.nativeElement.scroll({
          top: scrollToBottom
            ? this.scrollContainer.nativeElement.scrollHeight
            : 0,
          left: 0,
          behavior: 'smooth'
        }),
      100
    );
  }
}

import { Screen, AnimationEvent } from '../interfaces';
import { Job, Character } from '../../profile-select/profile-select.component';
import {
  PlaySound,
  ShowToolTip,
  ShowNarrativeChoice
} from '../ngrx/actions/overlays.actions';
import { UpdateBalance, AdvanceScreen } from '../ngrx/actions/progress.actions';
import { firstValueFrom } from 'rxjs';
import {
  selectCharacter,
  selectJob
} from '../ngrx/selectors/progress.selectors';

export const bankingTwo: Screen = {
  component: 'game',
  module: {
    title: {
      autoStart: 3000,
      titleBackgroundImage: (key) =>
        `/assets/images/${
          key === 'electrician' ? 'electrician' : 'office'
        }-bg.png`
    },
    animation: async (store, assetManager) => {
      const job = await firstValueFrom(store.select(selectJob));
      const character = await firstValueFrom(store.select(selectCharacter));
      const sounds = [
        `/assets/sounds/scene7/${job.key}/S7L1.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L2${character.soundAlias}.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L3.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L4${character.soundAlias}.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L5.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L6${character.soundAlias}.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L7.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L8${character.soundAlias}.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L9.mp3`,
        `/assets/sounds/scene7/${job.key}/S7L10${character.soundAlias}.mp3`
      ];
      await assetManager.loadAssets(sounds);
      return {
        layerPaths: [`/assets/lottie/scene7/${job.key}.json`],
        dispose: () => assetManager.disposeAudio(sounds),
        events: [
          {
            ...PlaySound({ src: `/assets/sounds/scene7/${job.key}/S7L1.mp3` }),
            segment: [0, 0],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene7/${job.key}/S7L2${character.soundAlias}.mp3`
            }),
            segment: [0, 90],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene7/${job.key}/S7L3.mp3` }),
            segment: [90, 175],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene7/${job.key}/S7L4${character.soundAlias}.mp3`
            }),
            segment: [175, 415],
            pause: false
          },
          {
            ...PlaySound({ src: `/assets/sounds/scene7/${job.key}/S7L5.mp3` }),
            segment: [415, 480],
            pause: false
          },
          {
            ...UpdateBalance({ value: job.key === 'designer' ? 700 : 500 }),
            segment: [480, 600],
            pause: false
          },
          ...generateEvents(job, character),
          {
            ...PlaySound({ src: `/assets/sounds/scene7/${job.key}/S7L9.mp3` }),
            segment: [1220, 1290],
            pause: false
          },
          {
            ...PlaySound({
              src: `/assets/sounds/scene7/${job.key}/S7L10${character.soundAlias}.mp3`
            }),
            segment: [1290, 1480],
            pause: false
          },
          {
            ...ShowToolTip({
              options: {
                text: '<b>Keep your money in a financial institution.</b> You’ll have the convenience of withdrawing money whenever you need it from a bank branch or ATM, a debit card to use for purchases, and the peace of mind knowing your funds are safe. Plus, you can save money you’d otherwise spend at check-cashing stores and build a financial future with the help of knowledgeable experts.',
                type: 'large',
                mood: 'neutral'
              }
            }),
            segment: [1480, 1545],
            pause: true
          },
          {
            ...AdvanceScreen({ noSave: true }),
            segment: [1545, 1545],
            pause: false
          }
        ]
      };
    },
    infoScreens: {}
  }
};

function generateEvents(job: Job, character: Character): AnimationEvent[] {
  if (job.key === 'electrician') {
    return [
      {
        ...PlaySound({
          src: `/assets/sounds/scene7/${job.key}/S7L6${character.soundAlias}.mp3`
        }),
        segment: [600, 880],
        pause: false
      },
      {
        ...ShowNarrativeChoice({ key: 'savings-goal' }),
        segment: [880, character.soundAlias === 'MC1' ? 1040 : 1020],
        pause: true
      },
      {
        ...PlaySound({ src: `/assets/sounds/scene7/${job.key}/S7L7.mp3` }),
        segment: [character.soundAlias === 'MC1' ? 1040 : 1020, 1175],
        pause: false
      },
      {
        ...PlaySound({
          src: `/assets/sounds/scene7/${job.key}/S7L8${character.soundAlias}.mp3`
        }),
        segment: [1175, 1220],
        pause: false
      }
    ];
  } else if (job.key === 'coder') {
    return [
      {
        ...PlaySound({
          src: `/assets/sounds/scene7/${job.key}/S7L6${character.soundAlias}.mp3`
        }),
        segment: [600, 890],
        pause: false
      },
      {
        ...ShowNarrativeChoice({ key: 'savings-goal' }),
        segment: [890, character.soundAlias === 'MC1' ? 1040 : 1035],
        pause: true
      },
      {
        ...PlaySound({ src: `/assets/sounds/scene7/${job.key}/S7L7.mp3` }),
        segment: [character.soundAlias === 'MC1' ? 1040 : 1035, 1165],
        pause: false
      },
      {
        ...PlaySound({
          src: `/assets/sounds/scene7/${job.key}/S7L8${character.soundAlias}.mp3`
        }),
        segment: [1165, 1215],
        pause: false
      }
    ];
  } else {
    return [
      {
        ...PlaySound({
          src: `/assets/sounds/scene7/${job.key}/S7L6${character.soundAlias}.mp3`
        }),
        segment: [600, 880],
        pause: false
      },
      {
        ...ShowNarrativeChoice({ key: 'savings-goal' }),
        segment: [880, character.soundAlias === 'MC3' ? 1030 : 1040],
        pause: true
      },
      {
        ...PlaySound({ src: `/assets/sounds/scene7/${job.key}/S7L7.mp3` }),
        segment: [character.soundAlias === 'MC3' ? 1030 : 1040, 1165],
        pause: false
      },
      {
        ...PlaySound({
          src: `/assets/sounds/scene7/${job.key}/S7L8${character.soundAlias}.mp3`
        }),
        segment: [1165, 1215],
        pause: false
      }
    ];
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';
import { HideFullScreenInfoWithAction } from '../shared/ngrx/actions/overlays.actions';
import { Store } from '@ngrx/store';
import { InfoScreen } from '../shared/interfaces';
//@ts-ignore
import focusLock from 'dom-focus-lock';

@Component({
  selector: 'app-full-screen-info',
  templateUrl: './full-screen-info.component.html',
  styleUrls: ['./full-screen-info.component.scss']
})
export class FullScreenInfoComponent implements AfterViewInit {
  @Input() info!: InfoScreen;

  constructor(
    private store: Store,
    private elRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    focusLock.on(this.elRef.nativeElement);
  }

  @HostListener('keydown.Escape')
  performAction() {
    focusLock.off(this.elRef.nativeElement);
    this.store.dispatch(
      HideFullScreenInfoWithAction({
        action: this.info.action,
        resumeAnimation: this.info.resumeAnimation
      })
    );
  }
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { TeacherDashboardView } from '../teacher-dashboard.component';
import { ClassDataDto } from '../../shared/interfaces';
import { questions } from 'src/app/shared/questions';
import html2PDF from 'jspdf-html2canvas';

@Component({
  selector: 'app-assessment-results',
  templateUrl: './assessment-results.component.html',
  styleUrls: ['./assessment-results.component.scss']
})
export class AssessmentResultsComponent {
  @Output() changeView = new EventEmitter<TeacherDashboardView>();
  @Output() switchClass = new EventEmitter<number>();
  @Input() selectedClass!: ClassDataDto;
  @Input() classes: ClassDataDto[] = [];
  questions = questions;

  teacherDashboardView = TeacherDashboardView;
  showDropdown: boolean = false;
  assessmentType: 'pre-game' | 'post-game' = 'pre-game';
  viewBy: 'STUDENTS' | 'QUESTIONS' = 'STUDENTS';
  questionsType: 'pre' | 'post' = 'pre';
  selectedQuestion: number = 1;
  @ViewChild('printDiv', { static: false }) printDiv!: ElementRef;

  getMostMissedQuestions(): { qNum: string; percentCorrect: number }[] {
    if (!this.selectedClass?.assessmentResults?.mostMissed) {
      return [];
    }
    switch (this.assessmentType) {
      case 'pre-game':
        return this.selectedClass.assessmentResults.mostMissed.pre;
      case 'post-game':
        return this.selectedClass.assessmentResults.mostMissed.post;
      default:
        return [];
    }
  }

  getQuestionText(questionNumber: number): string {
    const questionData = this.questions[this.questionsType][questionNumber - 1];
    return questionData ? questionData.query : 'Question not found';
  }

  getStudentAnswers(): { name: string; answer: string; isCorrect: boolean }[] {
    if (
      !this.selectedClass ||
      this.selectedQuestion === null ||
      !this.questionsType
    ) {
      return [];
    }
    const questionData =
      this.selectedClass.assessmentResults.byQuestion[this.questionsType][
        this.selectedQuestion
      ];
    if (!questionData) {
      return [];
    }
    const currentQuestion =
      this.questions[this.questionsType][this.selectedQuestion - 1];
    const answerPrefixes = ['a. ', 'b. ', 'c. ', 'd. '];

    return questionData.map((student) => {
      let answerText = '';

      if (typeof student.answer === 'boolean') {
        answerText = student.answer ? 'True' : 'False';
      } else if (
        Array.isArray(currentQuestion.choices) &&
        typeof student.answer === 'number'
      ) {
        answerText =
          '<strong>' +
          answerPrefixes[student.answer] +
          '</strong>' +
          currentQuestion.choices[student.answer];
      }
      return {
        name: student.name,
        answer: answerText,
        isCorrect: student.correct
      };
    });
  }

  print() {
    window.print();
  }

  public downloadAsPDF() {
    const printDivHTML = this.printDiv.nativeElement;
    printDivHTML.querySelector('#print-div').classList.add('print');

    html2PDF(printDivHTML, {
      jsPDF: { format: 'letter', unit: 'pt' },
      success: (pdf) => {
        pdf.save(`${this.selectedClass.className}: Assessment Results.pdf`);
      }
    });

    printDivHTML.querySelector('#print-div').classList.remove('print');
  }
}

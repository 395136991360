import { Injectable } from '@angular/core';
import { ProgressService } from '../../progress.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FreezeAnimation } from '../actions/overlays.actions';
import {
  SaveTableChoice,
  UpdateBalance,
  AdvanceScreen,
  ChapterRestarted,
  RestartCourse,
  SetLastModule
} from '../actions/progress.actions';
import { firstValueFrom, map, tap } from 'rxjs';
import { AnalyticsService } from '../../analytics.service';
import { AnalyticsType } from '../../interfaces';
import {
  selectGameCompleted,
  selectLastModule
} from '../selectors/progress.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class ProgressEffects {
  constructor(
    private store: Store,
    private progressService: ProgressService,
    private analyticsService: AnalyticsService,
    private actions: Actions
  ) {}

  restoreProgress = createEffect(
    () => {
      return this.actions.pipe(
        ofType(ChapterRestarted),
        tap(async () => await this.progressService.restoreProgress())
      );
    },
    { dispatch: false }
  );

  saveProgress = createEffect(
    () => {
      return this.actions.pipe(
        ofType(AdvanceScreen),
        tap(async ({ noSave, moduleCompleted }) => {
          if (!noSave) {
            await this.progressService.saveProgress();
          }

          const gameCompleted = await firstValueFrom(
            this.store.select(selectGameCompleted)
          );

          const lastModule = await firstValueFrom(
            this.store.select(selectLastModule)
          );

          if (moduleCompleted && !gameCompleted) {
            this.analyticsService
              .sendAnalytics(AnalyticsType.MODULE_COMPLETED, {
                moduleCompleted,
                lastModule
              })
              .then(() =>
                this.store.dispatch(
                  SetLastModule({ lastModule: moduleCompleted })
                )
              );
          }
        })
      );
    },
    { dispatch: false }
  );

  restartCourse = createEffect(() => {
    return this.actions.pipe(
      ofType(RestartCourse),
      tap(({ toAssessment }) =>
        this.progressService.restartCourse(toAssessment)
      ),
      map(() => FreezeAnimation())
    );
  });

  tableNotification = createEffect(() => {
    return this.actions.pipe(
      ofType(SaveTableChoice),
      map(({ choice }) => {
        const balanceValue = choice === 'new' ? -200 : -50;
        return UpdateBalance({ value: balanceValue });
      })
    );
  });
}

import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-circle-graph',
  templateUrl: './circle-graph.component.html',
  styleUrls: ['./circle-graph.component.scss']
})
export class CircleGraphComponent implements OnChanges {
  @Input() size: number = 0;
  @Input() bgColor: string = '';
  @Input() fgColor: string = '';
  @Input() percentComplete: number = 0;
  @Input() average!: number;
  @Input() isPercentage!: boolean;
  @Input() notApplicable: boolean = false;
  @Input() text?: string;

  sizeString: string = '';
  circumference: number = 0;
  strokePercent: number = 0;
  strokeDashoffset: number = 0;

  ngOnChanges(): void {
    this.sizeString = String(this.size) + 'px';
    this.circumference = 2 * Math.PI * this.size * 0.5 * 0.7;
    this.strokePercent =
      ((100 - this.percentComplete) * this.circumference) / 100;
    this.strokeDashoffset = this.percentComplete ? this.strokePercent : 0;
  }

  displayValueSuffix() {
    return this.isPercentage ? '%' : 'N/A';
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from '../shared/users.service';
import { ClassDataDto } from '../shared/interfaces';
import { ClassesService } from '../shared/classes.service';
import { TeacherViewService } from '../shared/teacher-view.service';
import { Subscription } from 'rxjs';

const EMPTY_CLASS: ClassDataDto = {
  classCode: '',
  className: 'No classes assigned',
  students: [],
  studentLogins: {
    loggedIn: [],
    notLoggedIn: []
  },
  assessmentResults: {
    preGameAverage: null,
    postGameAverage: null,
    byStudent: [],
    byQuestion: {
      pre: {},
      post: {}
    },
    mostMissed: {
      pre: [],
      post: []
    }
  },
  courseCompletion: {
    completed: [],
    inProgress: [],
    notStarted: [],
    byStudent: []
  }
};

export enum TeacherDashboardView {
  home,
  createClass,
  students,
  courseCompletion,
  editClass,
  assessmentResults
}

export interface RefetchOptions {
  classCode?: string;
  redirect?: TeacherDashboardView;
}

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.scss']
})
export class TeacherDashboardComponent implements OnInit, OnDestroy {
  selectedClass: ClassDataDto = EMPTY_CLASS;
  teacherDashboardView = TeacherDashboardView;
  currentView: TeacherDashboardView = TeacherDashboardView.home;

  classes: ClassDataDto[] = [];
  teacherName: string = '';
  todayDate: string;
  showDropdown: boolean = false;
  private viewSubscription: Subscription;

  constructor(
    private usersService: UsersService,
    private classesService: ClassesService,
    private teacherViewService: TeacherViewService
  ) {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const year = now.getFullYear().toString();
    this.todayDate = `${month}/${day}/${year}`;

    this.usersService.getCurrentUser().then((user) => {
      if (!user?.displayName) {
        this.teacherName = '';
      } else {
        const splitName = user?.displayName?.split(' ');

        if (splitName)
          splitName[splitName.length - 1] = `${
            splitName[splitName.length - 1][0]
          }.`;
        this.teacherName = splitName.join(' ');
      }
    });

    this.viewSubscription = this.teacherViewService.viewChanged.subscribe(
      (view: TeacherDashboardView) => {
          this.onViewChange(view);
      }
    );
  }

  async ngOnInit() {
    this.fetchClasses();
  }
  ngOnDestroy() {
    this.viewSubscription.unsubscribe();
  }

  async fetchClasses(options?: RefetchOptions) {
    (await this.classesService.fetchTeacherClasses()).subscribe((classes) => {
      if (!classes.length) {
        this.selectedClass = EMPTY_CLASS;
        this.classes = [this.selectedClass];
        return;
      }

      this.classes = classes;

      if (options?.classCode) {
        this.selectedClass =
          this.classes.find((c) => c.classCode === options.classCode) ||
          this.classes[0] ||
          EMPTY_CLASS;
      } else {
        this.selectedClass = this.classes[0];
      }

      if (options?.redirect) {
        this.onViewChange(options.redirect);
      }
    });
  }

  onViewChange(view: TeacherDashboardView) {
    this.currentView = view;
  }

  onClassSelect(classCode: string): void {
    this.selectedClass =
      this.classes.find(
        (existingClasses) => existingClasses.classCode === classCode
      ) || EMPTY_CLASS;
  }

  getClassName(c: ClassDataDto) {
    return c.className;
  }

  getClassCode(c: ClassDataDto) {
    return c.classCode;
  }
}

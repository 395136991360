<div class="container mt-5 main-container">
  <div class="row mt-4 top-row">
    <div class="col-md-6">
      <div class="d-flex align-items-left">
        <h5 class="section-title me-2 fw-bolder px-2">CLASS</h5>
      </div>
      <div class="section section-top class-section">
        <div class="left-section mt-4">
          <p class="h5">You're seeing</p>
          <h2 class="h2 mt-1 fw-bolder no-wrap class-name">
            {{ selectedClass.className }}
          </h2>
          <u>
            <span
              tabindex="0"
              (click)="changeView.emit(teacherDashboardView.students)"
              (keyup.enter)="changeView.emit(teacherDashboardView.students)"
              class="student-count h4 no-wrap"
            >
              {{ selectedClass.students.length }} Students
            </span>
          </u>
        </div>
        <div class="right-section">
          <p class="select-class-text fw-bolder">SELECT CLASS</p>
          <app-dropdown
            [dropdownItems]="classes"
            [selectedItem]="selectedClass"
            [displayFunction]="getClassName"
            [valueFunction]="getClassCode"
            [disabled]="!classes.length || !classes[0].classCode"
            (dropdownChange)="switchClass.emit($event)"
            [placeholder]="'No classes assigned'"
            [width]="'100%'"
          ></app-dropdown>
          <hr class="my-3" />
          <button
            (click)="changeView.emit(teacherDashboardView.createClass)"
            class="btn btn-primary create-class-button w-100 p-2 fw-bolder"
          >
            CREATE A NEW CLASS
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6 student-login-container">
      <div class="d-flex justify-content-between align-items-center px-2">
        <div class="d-flex">
          <h5 class="section-title me-2 fw-bolder text-center">
            STUDENT LOGINS
          </h5>
          <app-tooltip-bubble
            id="main"
            [forAttribute]="'student logins'"
            [icon]="'question'"
            [text]="
              'The Logins section indicates how many of your students have or have not logged in. Students appearing in the Logged In section have logged into Dollars & Decisions at least one time. Students appearing in the Have Not Logged In section have never logged into Dollars & Decisions.'
            "
          ></app-tooltip-bubble>
        </div>
        <span class="timestamp text-end mb-2 fs-6"
          >As of <span class="fw-bolder">{{ todayDate }}</span></span
        >
      </div>
      <div class="student-login-section">
        <div class="subsection logged-in">
          <div class="login-box">
            <h2 class="login-number">
              {{ selectedClass.studentLogins.loggedIn.length }}
            </h2>
            <p class="login-status">Logged In</p>
            <hr />
            <div
              tabindex="0"
              class="view-students"
              (click)="toggleStudentDropdown('loggedIn')"
              (keyup.enter)="toggleStudentDropdown('loggedIn')"
            >
              View Students
            </div>
            <div
              class="student-dropdown"
              *ngIf="showStudentDropdowns['loggedIn']"
            >
              <img
                class="close-icon"
                (click)="toggleStudentDropdown('loggedIn')"
                alt="close"
                src="/assets/icons/close.svg"
              />
              <div
                class="student-option"
                *ngFor="let student of selectedClass?.studentLogins?.loggedIn"
              >
                {{ student }}
              </div>
            </div>
          </div>
        </div>
        <div class="subsection not-logged-in">
          <div class="login-box">
            <h2 class="login-number">
              {{ selectedClass.studentLogins.notLoggedIn.length }}
            </h2>
            <p class="login-status">HAVE NOT Logged In</p>
            <hr />
            <div
              tabindex="0"
              class="view-students"
              (click)="toggleStudentDropdown('notLoggedIn')"
              (keyup.enter)="toggleStudentDropdown('notLoggedIn')"
            >
              View Students
            </div>
            <div
              class="student-dropdown"
              *ngIf="showStudentDropdowns['notLoggedIn']"
            >
              <img
                class="close-icon"
                (click)="toggleStudentDropdown('notLoggedIn')"
                alt="close"
                src="/assets/icons/close.svg"
              />
              <div
                class="student-option"
                *ngFor="
                  let student of selectedClass?.studentLogins?.notLoggedIn
                "
              >
                {{ student }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-6 bottom-section">
      <div
        class="section-header d-flex justify-content-between align-items-center px-2"
      >
        <div class="d-flex">
          <h5 class="section-title me-2 fw-bolder text-center">
            COURSE COMPLETION
          </h5>
          <app-tooltip-bubble
            id="main"
            [forAttribute]="'course completion'"
            [icon]="'question'"
            [text]="
              'The Completion section displays how many of your students have completed Dollars & Decisions, how many are in progress, and how many have not started. The percent displayed indicates the percentage of your students that have successfully completed Dollars & Decisions.'
            "
          ></app-tooltip-bubble>
        </div>
        <button
          aria-label="Go to course completion"
          (click)="changeView.emit(teacherDashboardView.courseCompletion)"
          class="text-end fs-6 fw-bolder view-all-container align-items-center border-0 bg-white mb-1"
        >
          <span class="view-all-text mx-2">VIEW ALL</span>
          <img
            class="forward-arrow"
            src="/assets/icons/arrow-forward.svg"
            alt="Forward Arrow"
          />
        </button>
      </div>
      <div class="section course-completion-section align-items-center">
        <div
          class="left-content text-center d-flex flex-column align-items-center"
        >
          <app-circle-graph
            [isPercentage]="true"
            aria-label="circle graph for sessions"
            class=""
            [size]="200"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#D8D8DD'"
            [percentComplete]="
              ((selectedClass.courseCompletion.completed.length || 0) /
                (selectedClass.students.length || 0)) *
              100
            "
            [average]="
              ((selectedClass.courseCompletion.completed.length || 0) /
                (selectedClass.students.length || 0)) *
                100 || 0
            "
          ></app-circle-graph>
          <p class="fw-bolder fs-6">Completion Status</p>
        </div>
        <div class="right-section">
          <div
            class="d-flex align-items-center justify-content-between position-relative mb-1"
          >
            <span class="category fw-bolder">Completed</span>
            <span class="category-num fw-bolder">{{
              selectedClass.courseCompletion.completed.length
            }}</span>
            <div
              tabindex="0"
              class="view-students"
              (click)="toggleStudentDropdown('completed')"
              (keyup.enter)="toggleStudentDropdown('completed')"
            >
              View Students
            </div>
            <div
              class="student-dropdown"
              *ngIf="showStudentDropdowns['completed']"
            >
              <img
                class="close-icon"
                (click)="toggleStudentDropdown('completed')"
                alt="close"
                src="/assets/icons/close.svg"
              />
              <div
                class="student-option"
                *ngFor="
                  let student of selectedClass?.courseCompletion?.completed
                "
              >
                {{ student }}
              </div>
            </div>
          </div>
          <hr class="my-1" />
          <div
            class="d-flex align-items-center justify-content-between position-relative mb-1"
          >
            <span class="category fw-bolder">In Progress</span>
            <span class="category-num fw-bolder">{{
              selectedClass.courseCompletion.inProgress.length
            }}</span>
            <div
              tabindex="0"
              class="view-students"
              (click)="toggleStudentDropdown('inProgress')"
              (keyup.enter)="toggleStudentDropdown('inProgress')"
            >
              View Students
            </div>
            <div
              class="student-dropdown"
              *ngIf="showStudentDropdowns['inProgress']"
            >
              <img
                class="close-icon"
                (click)="toggleStudentDropdown('inProgress')"
                alt="close"
                src="/assets/icons/close.svg"
              />
              <div
                class="student-option"
                *ngFor="
                  let student of selectedClass?.courseCompletion?.inProgress
                "
              >
                {{ student }}
              </div>
            </div>
          </div>
          <hr class="my-1" />
          <div
            class="d-flex align-items-center justify-content-between position-relative"
          >
            <span class="category fw-bolder">Not Started</span>
            <span class="category-num fw-bolder">{{
              selectedClass.courseCompletion.notStarted.length
            }}</span>
            <div
              tabindex="0"
              class="view-students"
              (click)="toggleStudentDropdown('notStarted')"
              (keyup.enter)="toggleStudentDropdown('notStarted')"
            >
              View Students
            </div>
            <div
              class="student-dropdown"
              *ngIf="showStudentDropdowns['notStarted']"
            >
              <img
                class="close-icon"
                (click)="toggleStudentDropdown('notStarted')"
                alt="close"
                src="/assets/icons/close.svg"
              />
              <div
                class="student-option"
                *ngFor="
                  let student of selectedClass?.courseCompletion?.notStarted
                "
              >
                {{ student }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 bottom-section assessment-container">
      <div
        class="section-header d-flex justify-content-between align-items-center px-2"
      >
        <div class="d-flex">
          <h5 class="section-title me-2 fw-bolder text-center">
            ASSESSMENT RESULTS
          </h5>
          <app-tooltip-bubble
            id="main"
            [forAttribute]="'assessment results'"
            [icon]="'question'"
            [text]="
              'The Assessment section displays your students average score for both Pre-Game Assessment and Post-Game Assessment.'
            "
          ></app-tooltip-bubble>
        </div>
        <button
          aria-label="Go to assessment results"
          (click)="changeView.emit(teacherDashboardView.assessmentResults)"
          class="text-end fs-6 fw-bolder view-all-container align-items-center border-0 bg-white mb-1"
        >
          <span class="view-all-text mx-2">VIEW ALL</span>
          <img
            class="forward-arrow"
            src="/assets/icons/arrow-forward.svg"
            alt="Forward Arrow"
          />
        </button>
      </div>
      <div
        class="section assessment-results-section d-flex justify-content-between"
      >
        <div class="left-content text-center">
          <app-circle-graph
            aria-label="circle graph for pre-game assessment average score"
            [notApplicable]="
              selectedClass.assessmentResults.preGameAverage === null
            "
            [size]="200"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#D8D8DD'"
            [percentComplete]="
              (selectedClass.assessmentResults.preGameAverage || 0) * 10
            "
            [average]="selectedClass.assessmentResults.preGameAverage || 0"
          ></app-circle-graph>
          <p class="average-score-text fw-bolder">
            Pre-Game Assessment<br />Average Score
          </p>
        </div>
        <div class="right-content text-center">
          <app-circle-graph
            [notApplicable]="
              selectedClass.assessmentResults.postGameAverage === null
            "
            aria-label="circle graph for post-game assessment average score"
            [size]="200"
            [bgColor]="'#14B1BD'"
            [fgColor]="'#D8D8DD'"
            [percentComplete]="
              (selectedClass.assessmentResults.postGameAverage || 0) * 10
            "
            [average]="selectedClass.assessmentResults.postGameAverage || 0"
          ></app-circle-graph>
          <p class="average-score-text fw-bolder">
            Post-Game Assessment<br />Average Score
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

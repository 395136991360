import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgressState } from '../reducers/progress.reducer';

export const selectProgress = createFeatureSelector<ProgressState>('progress');
export const selectGameCompleted = createSelector(
  selectProgress,
  (progress) => progress.gameCompleted
);
export const selectLastModule = createSelector(
  selectProgress,
  (progress) => progress.lastModule
);
export const selectCurrentScreen = createSelector(
  selectProgress,
  (progress) => progress.currentScreen
);
export const selectJob = createSelector(
  selectProgress,
  (progress) => progress.job
);
export const selectCredit = createSelector(
  selectProgress,
  (progress) => progress.credit
);
export const selectCharacter = createSelector(
  selectProgress,
  (progress) => progress.character
);
export const selectBalance = createSelector(
  selectProgress,
  (progress: ProgressState) => progress.balance
);
export const selectSavings = createSelector(
  selectProgress,
  (progress) => progress.savings
);
export const selectBudget = createSelector(
  selectProgress,
  (progress) => progress.budget
);
export const selectBudgetValue = (key: string) =>
  createSelector(selectBudget, (budget) => budget[key]);
export const selectSavingsGoal = createSelector(
  selectProgress,
  (progress) => progress.savingsGoal
);
export const selectShowBudgetButton = createSelector(
  selectProgress,
  (progress) => progress.showBudgetButton
);
export const selectShowSavings = createSelector(
  selectProgress,
  (progress) => progress.showSavings
);
export const selectPreAssessmentScore = createSelector(
  selectProgress,
  (progress) => progress.preAssessmentScore
);

<div
  class="main-display position-relative"
  *ngIf="screenSequence[(currentScreen$ | async)!] as currentScreen"
  [ngSwitch]="currentScreen.component"
>
  <app-assessment
    *ngSwitchCase="'assessment'"
    [type]="currentScreen.key!"
    (finish)="moveToNextScreen()"
  >
  </app-assessment>

  <app-profile-select
    *ngSwitchCase="'profile-select'"
    (finish)="moveToNextScreen()"
    [type]="currentScreen.key!"
  >
  </app-profile-select>

  <app-game
    *ngSwitchCase="'game'"
    [gameData]="currentScreen.module!"
    (finish)="moveToNextScreen()"
  >
  </app-game>

  <app-final-summary *ngSwitchCase="'summary'" (finish)="moveToNextScreen()">
  </app-final-summary>

  <div
    aria-hidden="true"
    class="position-absolute cheat-menu d-flex flex-row gap-2"
    *ngIf="isDevMode()"
  >
    <input
      class="form-control"
      type="number"
      [value]="currentScreen$ | async"
      (change)="updateCheatMenu($event)"
    />
    <button class="btn btn-danger" (click)="nextEvent()">
      <img src="/assets/icons/arrow-right.svg" alt="next event" />
    </button>
  </div>
</div>

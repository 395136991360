import { createAction, props } from '@ngrx/store';
import { ProgressState } from '../reducers/progress.reducer';

export const ModifyCredit = createAction(
  '[Progress] Modify Credit',
  props<{ credit: any }>()
);
export const AdvanceScreen = createAction(
  '[Progress] Advance Screen',
  props<{ noSave?: boolean; moduleCompleted?: number }>()
);
export const JumpToScreen = createAction(
  '[Progress] Jump to Screen',
  props<{ screen: number }>()
);
export const ChooseCharacter = createAction(
  '[Progress] Choose Character',
  props<{ character: any }>()
);
export const ChooseJob = createAction(
  '[Progress] Choose Job',
  props<{ job: any }>()
);
export const UpdateBalance = createAction(
  '[Progress] Update Balance',
  props<{
    value: number;
    customMessage?: string;
    skipNotification?: boolean;
  }>()
);
export const UpdateSavings = createAction(
  '[Progress] Update Savings',
  props<{ value: number }>()
);
export const SetPreAssessmentScore = createAction(
  '[Progress] Set Pre-Assessment Score',
  props<{ score: number }>()
);
export const ModifyBudget = createAction(
  '[Progress] Modify Budget',
  props<{
    budget: { [key: string]: number };
    updateBalance?: boolean;
    skipNotification?: boolean;
  }>()
);
export const SaveSavingsGoal = createAction(
  '[Progress] Save Savings Choice',
  props<{ goal: string }>()
);
export const SaveTableChoice = createAction(
  '[Progress] Save Table Choice',
  props<{ choice: string }>()
);
export const ChapterRestarted = createAction('[Progress] Chapter Restarted');
export const ShowBudgetButton = createAction('[Progress] Show Budget Button');
export const MarkGameComplete = createAction('[Progress] Mark Game Complete');
export const SetLastModule = createAction(
  '[Progress] Set Last Module',
  props<{ lastModule: number }>()
);
export const ShowSavings = createAction('[Progress] Show Savings');
export const EnableRetirementInBudget = createAction(
  '[Progress] Enable Retirement in Budget'
);
export const RestoreProgress = createAction(
  '[Progress] Restore Progress',
  props<{ newState: ProgressState }>()
);
export const RestartCourse = createAction(
  '[Progress] Restart Course',
  props<{ toAssessment: boolean }>()
);
export const Logout = createAction('[Admin] Logout');

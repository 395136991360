import {
  EnableRetirementInBudget,
  SaveTableChoice,
  SaveSavingsGoal,
  ModifyBudget,
  ModifyCredit,
  UpdateBalance
} from '../shared/ngrx/actions/progress.actions';
import { NarrativeChoice } from './interfaces';
import { JumpToAnimationEvent } from './ngrx/actions/overlays.actions';

export const MINI_CHOICES: { [key: string]: NarrativeChoice } = {
  groceries: {
    choices: [
      {
        type: 'budget',
        text: [{ content: 'I’m not fancy, cheap groceries will do.' }],
        value: 300,
        unit: 'month',
        effect: ModifyBudget({
          budget: {
            groceries: 300
          }
        })
      },
      {
        type: 'budget',
        text: [
          {
            content:
              'My stomach will hate me if I eat one more pack of ramen. Let’s go organic!'
          }
        ],
        value: 450,
        unit: 'month',
        effect: ModifyBudget({
          budget: {
            groceries: 450
          }
        })
      }
    ]
  },
  'extra-expense': {
    header: 'What was it I was looking to spend some money on?',
    choices: [
      {
        type: 'balance',
        text: [{ content: 'Eating out some nights with friends' }],
        value: 300,
        unit: 'month',
        effect: ModifyBudget({ budget: { discretionary: 300 } })
      },
      {
        type: 'balance',
        text: [{ content: 'New headphones' }],
        value: 70,
        unit: 'month',
        effect: ModifyBudget({ budget: { discretionary: 70 } })
      },
      {
        type: 'balance',
        text: [{ content: 'New clothes' }],
        value: 150,
        unit: 'month',
        effect: ModifyBudget({ budget: { discretionary: 150 } })
      }
    ]
  },
  'budget-savings': {
    header: 'Where can you cut back?',
    choices: [
      {
        type: 'budget',
        text: [{ content: 'Eating fancy dinners' }],
        unit: 'month',
        value: 150,
        effect: UpdateBalance({ value: 150 })
      },
      {
        type: 'budget',
        text: [{ content: 'Taking an occasional ride share home' }],
        unit: 'month',
        value: 50,
        effect: UpdateBalance({ value: 50 })
      },
      {
        type: 'budget',
        text: [{ content: 'Going to the bowling alley' }],
        unit: 'month',
        value: 20,
        effect: UpdateBalance({ value: 20 })
      }
    ]
  },
  retirement: {
    choices: [
      {
        type: 'retirement',
        text: [{ content: 'That sounds like a great idea.' }],
        unit: 'month',
        effect: EnableRetirementInBudget()
      },
      {
        type: 'plaintext',
        text: [{ content: 'I think I can spend my money better elsewhere.' }],
        effect: JumpToAnimationEvent({ index: -2 })
      }
    ]
  },
  table: {
    choices: [
      {
        type: 'balance',
        text: [
          {
            content:
              'Buy a new table. It’s more expensive, but it’s a longer-term solution.'
          }
        ],
        value: 200,
        unit: 'person',
        effect: SaveTableChoice({ choice: 'new' })
      },
      {
        type: 'balance',
        text: [
          {
            content:
              'Buy a sheet of plywood and some bars and build one for cheap!'
          }
        ],
        value: 50,
        unit: 'person',
        effect: SaveTableChoice({ choice: 'build' })
      }
    ]
  },
  'savings-goal': {
    choices: [
      {
        type: 'balance',
        text: [{ content: 'A vacation to Ft. Lauderdale' }],
        value: 1500,
        effect: SaveSavingsGoal({ goal: 'Vacation' })
      },
      {
        type: 'balance',
        text: [{ content: 'A tablet' }],
        value: 750,
        effect: SaveSavingsGoal({ goal: 'Tablet' })
      },
      {
        type: 'balance',
        text: [{ content: 'A bicycle' }],
        value: 500,
        effect: SaveSavingsGoal({ goal: 'Bicycle' })
      }
    ]
  },
  'credit-card': {
    choices: [
      {
        type: 'generic',
        image: '/assets/images/bank-card.svg',
        text: [
          {
            content: 'Bank Basic Cash-Back Card',
            classOverride: 'credit-header'
          },
          {
            content:
              '$0 annual fee, APR of 18%, $1,000 credit limit, 2% cash-back rewards on all purchases.'
          }
        ],
        effect: ModifyCredit({ credit: { card: 'bank' } })
      },
      {
        type: 'generic',
        image: '/assets/images/store-card.svg',
        text: [
          { content: 'Big-Box Store Card', classOverride: 'credit-header' },
          {
            content:
              'You can only use this card at this store, but you can buy  groceries, clothes, home goods, basic needs, and more.',
            classOverride: 'credit-italics'
          },
          {
            content:
              '$0 annual fee, APR of 23%, $700 credit limit, 5% discount on purchases at this store.'
          }
        ],
        effect: ModifyCredit({ credit: { card: 'store' } })
      },
      {
        type: 'generic',
        image: '/assets/images/no-card.svg',
        text: [
          { content: 'No Card', classOverride: 'credit-header' },
          {
            content:
              "I'll skip a credit card for now and plan on paying all expenses in cash or from my checking account."
          }
        ],
        effect: ModifyCredit({ credit: { card: 'none' } })
      }
    ]
  }
};

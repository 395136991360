<div class="container d-flex justify-content-center align-items-center vh-100">
  <div class="box p-5 text-center">
    <h2 class="fw-bolder welcome-text mb-5">Welcome to Dollars & Decisions!</h2>
    <p class="fw-bold select-role-text mb-4">
      Please select your role to get started.
    </p>
    <div class="roles d-flex justify-content-center mb-4">
      <a routerLink="student-login" class="role-link">
        <div
          class="role me-3 p-4 d-flex align-items-center justify-content-center"
        >
          <span class="fw-bolder login-link student-text mb-0"
            >I’M A STUDENT</span
          >
        </div>
      </a>
      <a routerLink="teacher-login" class="role-link">
        <div
          class="role ms-3 p-4 d-flex align-items-center justify-content-center"
        >
          <span class="fw-bolder login-link teacher-text mb-0"
            >I’M A TEACHER</span
          >
        </div>
      </a>
    </div>
    <div class="separator d-flex align-items-center mb-3">
      <hr class="flex-grow-1 me-2" />
      <span>OR</span>
      <hr class="flex-grow-1 ms-2" />
    </div>
    <a routerLink="main" class="guest fw-bolder mb-0">PLAY AS A GUEST</a>
  </div>
</div>
